import React, {Component} from 'react'
import * as d3 from 'd3'
import cn from 'classnames'

import { WORLD_MAP } from '../../constants/world_map.js'
import { COLOUR_SCALE, BLANK_COLOUR, REF_HEIGHT, REF_WIDTH } from '../../utils/choropleth_map_utils.js'
import { calculate_golden_ratio_height } from '../../utils/treemap_utils.js'
import { get_key_items_sorted_by_value, get_keys_to_value, get_value_column_idx } from '../../utils/column_data_utils.js'
import { KEY_COLUMN_IDX_1D } from '../../model/column_data.js'
import { format_integer, get_as_map } from '../../utils/utils.js'
import { CC2_TO_CC3_MAP } from '../../constants/cc2_to_cc3_map.js'
import { WorldMapTooltip } from '../widgets/WorldMapTooltip.js'
import { get_key_from_spec } from '../../utils/spec_utils.js'
import { THUMBNAIL_MAX_SERIES } from '../../utils/main_items_utils.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import TabularView from './TabularView.js'

import s from './ChoroplethMap.module.scss'

class ChoroplethMap extends Component {
  constructor(props) {
    super(props)

    this.update_wrapper_dimensions = this.update_wrapper_dimensions.bind(this)
    this.show_tooltip              = this.show_tooltip.bind(this)
    this.hide_tooltip              = this.hide_tooltip.bind(this)
    this.on_click                  = this.on_click.bind(this)
    this.draw_map                  = this.draw_map.bind(this)

    this.state = {
      items: null,
      container_height: 0,
      container_width: 0,
      tooltip: null
    }
  }

  componentDidMount() {
    this.update_wrapper_dimensions()
    this.setState({items: this.get_processed_data()})
    window.addEventListener('resize', this.update_wrapper_dimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update_wrapper_dimensions)
  }

  update_wrapper_dimensions() {
    const container = this.map_wrapper_ref

    if (!container) {
      return
    }

    const current_height = container.clientHeight
    const current_width = Math.min(container.clientWidth, 1200)
    const { container_width, container_height } = this.state

    if ((current_height !== container_height) || (current_width !== container_width)){
      this.setState({ container_height: current_height, container_width: current_width }, this.draw_map)
    }
  }

  get_processed_data() {
    const { data, key_dims, is_thumbnail } = this.props
    const y_items = key_dims[KEY_COLUMN_IDX_1D]

    const value_column_idx = get_value_column_idx(data)
    const values = data.data[value_column_idx]

    const total = d3.sum(values)

    const key_idxs = [KEY_COLUMN_IDX_1D]
    const keys_to_value = get_keys_to_value(data, key_idxs, value_column_idx)

    const y_items_sorted = get_key_items_sorted_by_value(y_items, keys_to_value)

    const y_items_filtered = (is_thumbnail) ? y_items_sorted.filter(item => item.country_code !== 'EP').slice(0, THUMBNAIL_MAX_SERIES) : y_items_sorted

    const items = []

    y_items_filtered.forEach(y_item => {
      if (!y_item.is_next_agglom) {
        const {country_code} = y_item
        const size = keys_to_value[y_item.id] || 0
        const value = size / total

        const cca3 = CC2_TO_CC3_MAP[country_code]

        if (value > 0.001) {
          items.push({country_code, cca3, size, value})
        }
      }
    })

    return items
  }

  get_dimensions() {
    const { is_thumbnail } = this.props

    const { container_width, container_height } = this.state

    //this corresponds with settings in the stylesheet: padding-left: 10px
    const container_with_minus_padding = container_width - 10

    const scale_w =  container_with_minus_padding / REF_WIDTH
    const scale_h =  container_height / REF_HEIGHT

    const scale = is_thumbnail ? Math.min(scale_w, scale_h) : scale_w

    const width = is_thumbnail ? REF_WIDTH * scale : container_with_minus_padding
    const height = is_thumbnail ?  REF_HEIGHT * scale : calculate_golden_ratio_height(container_with_minus_padding, 10) - 60 //60 so the label is closer to the map

    return {scale, width, height}
  }

  show_tooltip(d) {
    const { container_width, tooltip, items } = this.state
    const { id } = tooltip || {}
    const updated_id = d.id
    if (id !== updated_id) {
      const items_by_cca3 = get_as_map(items, 'cca3')
      const size = (items_by_cca3[updated_id] || {}).size
      const value = (items_by_cca3[updated_id] || {}).value
      const percent_value = (value) ? `${format_integer(value * 100)}%` : null
      const name = d.properties.name
      const updated_content = { name, size, percent_value }
      const tooltip_width = [name, size, percent_value].join(': ').length * 6.5

      const page_x = d3.event.pageX
      const page_y = d3.event.pageY
      const scroll_x = window.pageXOffset // Internet explorer does not have window.scrollX, so use pageXOffset instead

      const is_far_right = page_x - scroll_x > container_width - tooltip_width - 25
      const left = page_x + (is_far_right ? -(tooltip_width + 5) : 5)
      const top = page_y + 5

      const updated_tooltip = { content: updated_content, id: updated_id, position: { left, top } }

      this.setState({ tooltip: updated_tooltip })
    }
  }

  hide_tooltip() {
    this.setState({tooltip: null})
  }

  on_click(d) {
    const { is_thumbnail, set_families_subselections, deref_data, spec, selections } = this.props
    if (is_thumbnail) {
      return
    }

    const { items } = this.state
    const items_by_cca3 = get_as_map(items, 'cca3')
    const cca3 = d.id
    const item = items_by_cca3[cca3]

    if (!item) return

    const {report_region_column} = selections
    const key = get_key_from_spec(spec, report_region_column)
    const query_key = key[0] // query i.e. 'PFTP.portfolio_id'
    const { selected_geos } = deref_data
    const selected_geos_by_country_code = get_as_map(selected_geos, 'country_code')

    const { country_code, size } = item

    const selected_geo = selected_geos_by_country_code[country_code]

    set_families_subselections(
      [{ key: query_key, items: [selected_geo] }], // subselections
      size                                         // value (can be useful occasionally)
    )
  }

  draw_map() {
    const {items} = this.state

    const max_val = Math.max((items[0] || {}).value, 0.1)

    const projection = d3.geoMercator()
    const path = d3.geoPath().projection(projection)

    const { is_thumbnail } = this.props

    const {width, height, scale} = this.get_dimensions()

    const wrapper = d3.select(this.map_wrapper_ref)

    wrapper.selectAll('*').remove()
    const container = wrapper
      .append('div').style('width', width+'px').style('height', height+'px').attr('class', 'm-auto')
      .append('svg').attr('height', height).attr('width', width)

    const g = container.append('g')
      .attr('transform', `scale(${scale}) translate(-20, 20)`)

    const items_by_cca3 = get_as_map(items, 'cca3')

    g.selectAll('.region')
      .data(WORLD_MAP.features)
      .enter()
      .append('path')
      .attr('class', 'region')
      .attr('d', path)
      .on('mouseover', this.show_tooltip)
      .on('mouseout', this.hide_tooltip)
      .on('click', this.on_click)
      .style('fill', function(d){
        if (d.id === 'ATA') {
          return 'none'
        } else {
          if (d.id in items_by_cca3) {
            return COLOUR_SCALE(items_by_cca3[d.id].value / max_val)
          }
          return BLANK_COLOUR
        }
      })
      .style('stroke', 'white')
      .style('stroke-width', '0.25')
      .style('cursor', function(d){
        return (d.id in items_by_cca3) ? 'pointer' : 'initial'
      })

    if (!is_thumbnail) {
    const map_desc = this.get_map_description(items)

    container.append('text')
      .attr('class', 'legend')
      .attr('x', width / 2)
      .attr('y', height - 5)
      .attr('text-anchor', 'middle')
      .style('font-size', `${height / 25}px`)
      .text(map_desc)
    }
  }

  get_map_description(items) {
    const top_items = (items || []).slice(0, 5)
    const item_desc = []
    top_items.forEach(item => {
      const {value, cca3} = item

      switch (true) {
        case (value > 0.005): item_desc.push(`${cca3}: ${format_integer(value * 100)}%`); break;
        case (value > 0.001): item_desc.push(`${cca3}: <1%`); break;
        default:
      }
    })

    return item_desc.join(', ')
  }

  render() {
    const { is_thumbnail } = this.props
    const { tooltip } = this.state

    return (
      <div>
        <div className={cn('d-flex overflow-auto', {[s.block]: !is_thumbnail }, { [s.block__thumbnail]: is_thumbnail })} ref={ (map_wrapper) => this.map_wrapper_ref = map_wrapper }></div>
        {!is_thumbnail && tooltip &&
          <WorldMapTooltip tooltip={tooltip}/>
        }
      </div>
    )
  }
}

ChoroplethMap.get_plotly_data = (props) => {
  const {spec} = props || {}
  const data = TabularView.get_csv_value_rows({ ...props, spec: {...spec, hide_totals: true}})
  const export_data = get_csv_string(data)
  return export_data
}

ChoroplethMap.get_csv_value_rows = (props) => {
  const {spec} = props || {}
  return TabularView.get_csv_value_rows({ ...props, spec: {...spec, hide_totals: true}})
}

export default ChoroplethMap