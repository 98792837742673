import React from 'react'

import Spinner from '../widgets/Spinner.js'
import { BENCHMARKING_REPORT_ID, TREND_SPOTTER_ID } from '../../model/hyperscripts.js'

const HyperscriptSpinner = ({hyperscript}) => {
  const { id } = hyperscript || {}

  const is_trend_spotter = (id === TREND_SPOTTER_ID)
  const is_benchmarking_report = (id === BENCHMARKING_REPORT_ID)

  const content_delivery_text = is_trend_spotter ? 'report will start' : 'file should be ready'

  if (is_benchmarking_report) {
    return (
      <>
        <div>Writing benchmarking data for your report.</div>
        <Spinner/>
      </>
    )
  }

  return (
    <div>
      <div>Fetching data to export, your {content_delivery_text} soon.</div>
      <Spinner/>
    </div>
  )
}

export default HyperscriptSpinner