import _ from 'underscore'

import { is_number } from './utils.js'
import { ORGANISATION_COLUMN_NAME } from '../model/organisation.js'
import { TECHNOLOGY_COLUMN_NAME } from '../model/technologies.js'

export function in_org_lists_constraint(org_list_ids) {
  return `IN PTCL.company_list_id (${(org_list_ids || []).join(',')})`
}

export function in_tech_areas_constraint(tech_area_ids) {
  return `IN TTTA.technology_area_id (${(tech_area_ids || []).join(',')})`
}

export function constrain_to_portfolio_if_not_target_org(clickthrough_item) {
  return (!clickthrough_item || clickthrough_item.is_target_org) ? {} : {portfolio: clickthrough_item.items.map(item => item.id)}
}

export function get_portfolios_excluding_server_rollup_and_private_owner(portfolios=[]) {
  return portfolios.filter(portfolio => {
    const {name=''} = portfolio || {}
    const name_chunks = name.split(' ')
    const is_rollup = (name_chunks.length === 2) && (name_chunks[0].toLowerCase() === 'next') && ((is_number(name_chunks[1]) || name_chunks[1] === 'xx'))
    const name_lower_case = name.toLowerCase()
    const is_private_owner = (name_lower_case === 'private owner') || (name_lower_case === 'individual owners')
    return !(is_rollup || is_private_owner)
  })
}

export function sort_deck_timeseries_legend({legend_items, ref_data, selections, column}) {
  // We don't show full legends for report deck charts, but the order of items in the invisible legend determines the order in which
  // timeseries lines are drawn. Sorting target/ 'My Org' and spotlighted items to the end means they will be drawn last, 'on top' of everything else.
  const {spotlighted_orgs, spotlighted_tech_areas} = selections
  switch (column) {
    case ORGANISATION_COLUMN_NAME: {
      const {portfolio_to_company_list} = ref_data
      const [target_org, not_target_org] = _.partition(legend_items, item => is_company_list_target_list(portfolio_to_company_list[item.id]))
      const [spotlighted, other_orgs] = _.partition(not_target_org, item => _.contains(spotlighted_orgs, item.id))
      return [...other_orgs, ...spotlighted, ...target_org]
    }
    case TECHNOLOGY_COLUMN_NAME: {
      const [spotlighted, other_techs] = _.partition(legend_items, item => _.contains(spotlighted_tech_areas, item.id))
      return [...other_techs, ...spotlighted]
    }
    default:
      return legend_items
  }
}

export function is_company_list_target_list(company_list) {
  return company_list.is_target_org
}
