import { format_integer_with_comma } from './utils.js'
import { get_short_currency_value_formatter, USD_ITEM } from '../model/currencies.js'

export function get_tabular_view_total({data, formatter, label}) {
  const total_row = (data || []).filter(item => item[0].is_total)[0]

  if (total_row && total_row.length > 0) {
    return `${formatter(total_row[total_row.length - 1].value)} ${label || 'families'}`
  }

  return ''
}

export function get_tabular_view_pvix_score_total({data}) {
  return get_tabular_view_total({data, label: 'total', formatter: format_integer_with_comma})
}

export function get_tabular_view_cost_total({data}) {
  return get_tabular_view_total({data, label: 'total', formatter: get_short_currency_value_formatter(USD_ITEM)})
}

export function get_tabular_view_litigations_total({data}) {
  return get_tabular_view_total({data, label: 'litigations', formatter: format_integer_with_comma})
}

export function get_tabular_view_patents_total({data}) {
  return get_tabular_view_total({data, label: 'patents', formatter: format_integer_with_comma})
}

export function get_tabular_view_citations_total({data}) {
  return get_tabular_view_total({data, label: 'citations', formatter: format_integer_with_comma})
}