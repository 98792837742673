import qs from 'query-string'
import _ from 'underscore'

import { remove_double_spaces, replace_tabs_with_spaces } from './utils.js'
import { FAMILY_SUBPATH, PATENT_NUMBER_SUBPATH } from '../constants/viewer_paths.js'

export function normalise_search_phrase(phrase) {
  if (!phrase) return ''
  return remove_double_spaces(replace_tabs_with_spaces(phrase.trim()))
}

export function update_url_with_search_phrase(search_phrase, param_name, location, history) {
  if (location && history) {
    const search_phrase_url_encoded = encodeURIComponent(normalise_search_phrase(search_phrase))
    const path = location.pathname
    const query_params = qs.parse(location.search)
    const new_query = search_phrase && search_phrase_url_encoded !== '' ? {...query_params, [param_name]: search_phrase_url_encoded} : _.omit(query_params, param_name)
    history.replace({pathname: path, search: `?${qs.stringify(new_query)}`})
  }
}

export function add_family_subpath(base_path, family_id, patent_id) {
  return `${base_path}/${FAMILY_SUBPATH}/${family_id}${patent_id ? `/${PATENT_NUMBER_SUBPATH}/${patent_id}` : ''}`
}