import axios from 'axios'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'

import { REFRESH_JWT_ERROR_MESSAGE, force_refresh_jwt_token, save_to_local_storage, on_update_token_error } from './auth_utils.js'
import { is_app4_cdn_environment, is_app3_cdn_environment, is_prod_cdn_environment, is_tech_discovery_cdn_environment } from './utils.js'

import { APP3_API_HOST, APP4_API_HOST, CDN_API_HOST, TECH_DISCOVERY_API_HOST } from '../constants/urls.js'

export const axios_save_instance = axios.create()

const all_axios_instances = [axios /* default */, axios_save_instance /* optional instance for saving etc */]

let __num_save_reqs_pending = 0 // Keeps track of number of save requests that are pending.

export const JSON_POST_HEADER = {'Content-Type': 'application/json'}
export const FORM_POST_HEADER = {'Content-Type': 'x-www-form-urlencoded'}
export const MULTIPART_POST_HEADER = {'Content-Type': 'multipart/form-data'}

/**
 * @param  {number} [retryNumber=0]
 * @return {number} - delay in milliseconds
 */
function exponential_delay(retry_number = 0) {
  // See https://developers.google.com/analytics/devguides/reporting/core/v3/errors#backoff
  const delay = Math.pow(2, retry_number) * 1000
  const random_sum = delay * 0.2 * Math.random() // 0-20% of the delay
  return delay + random_sum
}

function check_is_retriable_grpc_error(error) {
  if (!error || !error.response || !error.response.data) {
    return false
  }

  // See https://grpc.github.io/grpc/core/md_doc_statuscodes.html
  const data = error.response.data
  if (typeof data !== 'string') {
    return false
  }
  if ((data.indexOf('14 UNAVAILABLE') === 0) || (data.indexOf('4 DEADLINE_EXCEEDED') === 0)) {
    return true
  }

  return false
}

function is_ok_to_retry(kc, error) {
  if (error && error.config && error.response && error.response.status === 401 && error.response.data === 'jwt expired') {
    // JWT token expired
    force_refresh_jwt_token(kc) // force refresh (async)
    return true
  }

  if (error && error.response && error.response.status === 429) {
    // "Too many requests" - currently IFI (patent images) rate limit is set very low, so it's worth retrying.
    return true
  }

  // gRPC calls are proxied over POST, so check these here explicitly
  const is_retriable_grpc_error = check_is_retriable_grpc_error(error)
  if (is_retriable_grpc_error) {
    return true
  }

  // Otherwise, only retry if either:
  // a) network call (i.e. with some caveats - see implementation)
  // b) idempotent http method (i.e. GET/PUT/DELETE but not a POST)
  return isNetworkOrIdempotentRequestError(error)
}

export const PROPERTIES_NO_CACHE = { Pragma: 'no-cache', 'Cache-Control': 'no-cache' }

export const CONFIG_NO_CACHE = { headers: PROPERTIES_NO_CACHE }

export function init_axios_middlewares(kc, refresh_token) {
  // TODO: for axios_save_instance... how to deal with fails/retries?
  // a) retry (controls enabled during retries) - possibility of retries clobbering user data
  // b) retry, but disable controls during retries
  // c) no retries (fails shown instantly)
  all_axios_instances.forEach((axios_instance) => {
    init_axios_middleware(kc, axios_instance, refresh_token)}
  )
}

function init_axios_middleware(kc, axios_instance, refresh_token) {

  // On prod CDN, use separate domain for API calls
  if (is_prod_cdn_environment()) {
    // app.cipher.ai -> api.aws.cipher.ai
    axios_instance.defaults.baseURL = CDN_API_HOST
  } else if (is_app3_cdn_environment()) {
    // app3.cipher.ai -> api3.aws.cipher.ai
    axios_instance.defaults.baseURL = APP3_API_HOST
  } else if (is_app4_cdn_environment()) {
    // app4.cipher.ai -> api4.aws.cipher.ai
    axios_instance.defaults.baseURL = APP4_API_HOST
  } else if (is_tech_discovery_cdn_environment()) {
    // techdiscovery.cipher.ai -> api-techdiscovery.aws.cipher.ai
    axios_instance.defaults.baseURL = TECH_DISCOVERY_API_HOST
  }

  if (refresh_token) {
    // Use jwt token in requests
    axios_instance.interceptors.request.use(config => {
      return kc.updateToken(5) // refreshes token if within 5 seconds of expiry
        .catch(() => {
          // Refresh error
          on_update_token_error(kc) // forces login (asynchronous)
          throw new Error(REFRESH_JWT_ERROR_MESSAGE) // will bubble to caller, get wrapped in caller error, and sent to sentry (where we explicitly ignore it)
        })
        .then(refreshed => {
          if (refreshed) {
            save_to_local_storage(kc)
          }
          config.headers.Authorization = 'Bearer ' + kc.token
          return config
        })
    })
  }

  // Retries
  axiosRetry(axios_instance, {
    retries: 2,                                   // maximum 2 retries
    retryDelay: exponential_delay,                // exponential delay i.e. wait 1s, then 2s, then 4s etc...
    retryCondition: is_ok_to_retry.bind(null, kc) // retry 5xx errors, and also 401 jwt expired (tries to refresh token in background)
  })
}

export function add_source_err_to_target_err(source_err, target_err, message_prefix) {
  // Copies status/message over to target error.

  const status = source_err.response ? source_err.response.status : source_err.status

  const response_data = source_err.response && source_err.response.data ? ` (response.data: ${JSON.stringify(source_err.response.data)})` : ''
  const message_body = source_err.message || `(no message available)`
  const message = message_prefix + ' ' + message_body + response_data

  target_err.status = status
  target_err.message = message
  target_err.request = source_err.request
  target_err.response = source_err.response

  return target_err
}

export function is_forbidden(error) {
  return error && error.response && error.response.status && error.response.status === 403
}

export function is_not_found(error) {
  return error && error.response && error.response.status && error.response.status === 404
}

export function is_4xx_error(error) {
  return error && error.response && error.response.status && error.response.status >= 400 && error.response.status < 500
}

export function is_bad_request(error) {
  return error.response && error.response.status && error.response.status === 400
}

/**
 * Adds handlers to the special "saving" axios object.
 * This can be used to notify users when saves are in progress.
 * NOTE: currently this mechanism assumes that only a single client will be registered at any time.
 * If concurrent clients are required, it will need some modification.
 * @param {} on_saving This handler is called when the saving axios object transitions to a "is_saving" state (i.e. network responses are pending)
 * @param {} on_not_saving This is handler is called when the saving axios object transitions to a "is_not_saving" state (i.e. no network responses are pending)
 * @returns { req_intercptor, res_interceptor } These interceptors are needed for unregistering the handlers later.
 */
export function register_save_status_handlers(on_saving, on_not_saving) {
  const req_interceptor = axios_save_instance.interceptors.request.use(config => {
    __num_save_reqs_pending++
    if (__num_save_reqs_pending === 1) {
      on_saving()
    }
    return config
  }, error => {
    return Promise.reject(error)
  })

  const res_interceptor = axios_save_instance.interceptors.response.use(response => {
    __num_save_reqs_pending--
    if (__num_save_reqs_pending === 0) {
      on_not_saving()
    }
    return response
  }, error => {
    __num_save_reqs_pending--
    if (__num_save_reqs_pending === 0) {
      on_not_saving()
    }
    return Promise.reject(error)
  })

  return { req_interceptor, res_interceptor }
}

/**
 * Unregisters handlers from the special "saving" axios object.
 * Handlers should be unregistered on componentWillUnmount
 * @param {} req_interceptor
 * @param {} res_interceptor
 */
export function unregister_save_status_handlers(req_interceptor, res_interceptor) {
  axios_save_instance.interceptors.request.eject(req_interceptor)
  axios_save_instance.interceptors.response.eject(res_interceptor)
}


export function is_400_error(err) {
  if (!err || !(err || {}).status) return false

  return err.status === 400
}

export function is_404_error(err) {
  const {status, response} = err || {}
  const {status: response_status} = response || {}

  if (!err) return false

  return status === 404 || response_status === 404
}
