import qs from 'query-string'
import _ from 'underscore'

const USER_STATE_STRING_PARAM = 'user_state_string'

export function save_report_user_state_to_window(user_state) {
  // Slightly hacky. We do this so that the user state is available for inclusion in Sentry alerts and
  // feedback messages. Report user state in the DB is used for report rendering in ReportContainer
  window.report_user_state = user_state
}

export function read_report_user_state_from_window() {
  // Used for Sentry alerting and feedback debugging
  //
  // NOTE:
  // Stored state may become invalid.
  // For example, certain datasets may be removed from the source code,
  // or parameters may change.
  // So...
  // We probably need to validate the state here.
  // Maybe use json_schema for this?
  // If schema is invalid, perhaps notify user and ask
  // if ok to remove invalid nodes?
  // Or perhaps just remove them silently?
  return window.report_user_state
}

export function create_user_state_url(user_state) {
  if (!user_state) {
    return null
  }
  const query_params = qs.parse(window.location.search)
  const new_query_params = {...query_params, [USER_STATE_STRING_PARAM]: JSON.stringify(user_state)}
  return window.location.origin + window.location.pathname + '?' +  qs.stringify(new_query_params)
}

export function get_user_state_from_url() {
  const query_params = qs.parse(window.location.search)
  const user_state_string = query_params[USER_STATE_STRING_PARAM]
  if (!user_state_string) {
    return null
  }
  return JSON.parse(user_state_string)
}

export function remove_user_state_from_url() {
  const {search, origin, pathname} = window.location
  const query_params = qs.parse(search)
  const params_without_user_state = _.omit(query_params, USER_STATE_STRING_PARAM)
  const param_string_without_user_state = qs.stringify(params_without_user_state)
  const param_string = param_string_without_user_state && param_string_without_user_state !== '' ? `?${param_string_without_user_state}` : ''
  const clean_url = origin + pathname + param_string
  return clean_url
}
