import React from 'react'
import cn from 'classnames'

import s from './ReportBuilderInfoPanel.module.scss'

export const ReportBuilderInfoPanel = ({className, children}) => {
  return (
    <div className={cn(s.single_column_container, className)}>
      {children}
    </div>
  )
}