import React from 'react'

const TileTitle = ({title}) => {
  return (
    <div className='d-flex'>
      <span>{title}</span>
    </div>
  )
}

export default TileTitle