import { get_as_map } from '../utils/utils.js'

export const DEFAULT = 'DEFAULT'
export const ALPHABETICALLY = 'ALPHABETICALLY'

export const DEFAULT_ITEM        = { id: DEFAULT,        name: 'default', title: 'use default sort order' }
export const ALPHABETICALLY_ITEM = { id: ALPHABETICALLY, name: 'abc',     title: 'sort alphabetically' }

export const SIMPLE_SORT_ITEMS = [
  DEFAULT_ITEM,
  ALPHABETICALLY_ITEM
]

export const ID_TO_SIMPLE_SORT_ITEM = get_as_map(SIMPLE_SORT_ITEMS, 'id')
