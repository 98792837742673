import axios from 'axios'
import _ from 'underscore'

import {
  FAMILY_CLASS_SUGGESTION_FETCH_URL,
  FAMILY_CLASS_SUGGESTION_SAVE_URL,
  FAMILY_CLASS_SUGGESTION_STATS_URL
} from '../constants/urls.js'
import { add_source_err_to_target_err, JSON_POST_HEADER } from './axios_utils.js'
import { UTT_GROUP_NAME } from '../constants/utt.js'

export function get_family_class_suggestions(family_id) {
  return axios.get(`${FAMILY_CLASS_SUGGESTION_FETCH_URL}/${family_id}`)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch family class suggestion: ')
      throw wrapped_err
    })
}

export function save_family_class_suggestion({family_id, current_class, suggested_class, comment}) {
  return axios.post(FAMILY_CLASS_SUGGESTION_SAVE_URL, {family_id, current_class, suggested_class, comment}, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to add family class suggestion: ')
      throw wrapped_err
    })
}

export function get_family_class_suggestion_stats() {
  return axios.get(FAMILY_CLASS_SUGGESTION_STATS_URL)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch family class suggestions stats: ')
      throw wrapped_err
    })
}

export function transform_classes_to_groups(id_to_classifier) {
  // Transform to a 2-level tree of nodes.
  // We infer the group (parent) from the name
  // i.e. 'Semiconductors/Processors' => group='Semiconductors', name='Processors'

  // Each resulting node has properties 'id' and 'name', and optionally 'scope' and 'children'.

  const group_to_classifiers = _.pairs(id_to_classifier).reduce((group_to_classifiers, [id, classifier]) => {
    const { name: full_name } = classifier
    const [ group, leaf_name ] = full_name.split('/')

    const classifier_clean = {
      ...classifier,
      id,                            // the classifier id in the backend (i.e. '5279eac6-f1a1-4737-9ec1-c38bb338bc4e')
      simple_id: classifier.id,      // i.e. 124
      group,                         // i.e. Semiconductors
      name: leaf_name,               // i.e. Processors
      full_name                      // i.e. Semiconductors/Processors
    }

    const classifiers = group_to_classifiers[group] || []
    const new_classifiers = [...classifiers, classifier_clean]
    return { ...group_to_classifiers, [group]: new_classifiers }
  }, {})

  const group_names_sorted = _.chain(group_to_classifiers).keys().sort().value()

  const classifier_groups = group_names_sorted.map(group => {
    const classifiers = group_to_classifiers[group]
    const classifiers_sorted = _.sortBy(classifiers, (classifier) => classifier.name)

    return {
      id: group,
      name: group,
      classifiers: classifiers_sorted
    }
  })

  return classifier_groups
}

export function transform_classes_to_classifier_tree(id_to_classifier) {
  const classifier_groups = transform_classes_to_groups(id_to_classifier)

  return {name: UTT_GROUP_NAME, children: classifier_groups.map(group => {
    const {name: group_name, classifiers=[]} = group || {}

    return {
      name: group_name,
      parent: [UTT_GROUP_NAME],
      children: classifiers.map(item => {
        const {name, id: classifier_id, scope: description} = item || {}

        return {
          name, classifier_id, description, parent: [UTT_GROUP_NAME, group_name]
        }
      })}

  })}
}

export function filter_classes_by_search_phrase(classifier_groups, search_input) {
  const search_input_clean = search_input.toLowerCase().trim()
  const classifier_groups_filtered = !search_input ? classifier_groups : classifier_groups.map(group => {
    const { classifiers } = group
    const classifiers_filtered = classifiers.filter(classifier => classifier.full_name.toLowerCase().indexOf(search_input_clean) !== -1)
    return { ...group, classifiers: classifiers_filtered }
  })
    .filter(group => group.classifiers.length > 0) // remove empty groups

  return classifier_groups_filtered
}

export function get_default_utt_landscape_report_name(classifier) {
  const { group: group_name='', name='' } = classifier || {}
  return `${group_name}: ${name} (UTT Landscape)`
}
