import React from 'react'
import cn from 'classnames'

import s from './BasePlotlyXYChartWrapper.module.scss'
// NOTE: adds global css to plotly.override.scss

const BasePlotlyXYChartWrapper = ({is_thumbnail, no_clickthrough, chart_wrapper_height, children}) => {
  const inline_styles = {
    ...chart_wrapper_height ? {height: chart_wrapper_height} : {}
  }

  return (
    <div className={cn(
      'mx-auto',
        s.block,
        {
          [s.block__thumbnail]: is_thumbnail,
          baseplotlyxychart_default_cursor: (no_clickthrough && !is_thumbnail) // Plotly standard cursor is "pointer", so we need to override this to "default" when no clickthroughs are available
        }
      )}
      style={inline_styles}
    >
      {children}
    </div>
  )
}

export default BasePlotlyXYChartWrapper