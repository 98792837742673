import React from 'react'
import { closestCenter, DndContext } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'

import { is_IE11 } from '../../utils/browser_utils.js'

const BROWSER_IS_IE_11 = is_IE11()

const DragAndDropSortArea = (
  {
    container_id,
    sortable_items,
    on_drag_start,
    on_drag_end,
    on_drag_over,

    children,
  }) => {

  function handle_drag_start(event) {
    const {active} = event

    on_drag_start(active.id)
  }

  function handle_drag_over(event) {
    const {over} = event
    on_drag_over(over.id)
  }

  function handle_drag_end(event) {
    const {active, over} = event
    on_drag_end(active.id, over.id)
  }


  if (BROWSER_IS_IE_11) {
    return (
      <div>
        {children}
      </div>
    )
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handle_drag_start}
      onDragEnd={handle_drag_end}
      onDragOver={handle_drag_over}
    >
      <SortableContext items={sortable_items} containerId={container_id}>
        {children}
      </SortableContext>
    </DndContext>
  )
}

export default DragAndDropSortArea