import { can_view_tagged_families, has_pvix_enabled, is_view_only_user } from './user_permissions.js'

const REPORT_WITH_PVIX_SCHEMA_VERSION = 12

export function has_pvix_scores({schema_version, user}) {
  return schema_version && check_if_schema_version_equal_or_above(schema_version, REPORT_WITH_PVIX_SCHEMA_VERSION) && has_pvix_enabled(user)
}

export function can_view_dispute_data({user}) {
  return !is_view_only_user(user)
}

export function has_classifier_scores({report_has_scores}) {
  return report_has_scores === true
}

export function has_tags({user}) {
  return can_view_tagged_families(user)
}

export function is_schema_version_15_or_above({ is_keyword_search, schema_version}) {
  if (is_keyword_search) return true

  return schema_version && check_if_schema_version_equal_or_above(schema_version, 15)
}

function check_if_schema_version_equal_or_above(schema_version, schema_version_threshold) {
  return schema_version >= schema_version_threshold
}

export function not_available() {
  return false
}