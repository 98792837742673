import React from 'react'

import BasePlotlyXYChart from './BasePlotlyXYChart.js'
import { BAR_CHART_TYPE, BAR_ORIENTATION_HORIZONTAL, BARMODE_CLUSTER } from './base_plotly_props.js'


const BarClusteredChart = (props) => {
  return (
    <BasePlotlyXYChart
      {...props}
      chart_type={BAR_CHART_TYPE}
      barmode={BARMODE_CLUSTER}
      orientation={BAR_ORIENTATION_HORIZONTAL}
    />
  )
}

BarClusteredChart.get_plotly_data = (props) => {
  return BasePlotlyXYChart.get_plotly_data({ ...props, chart_type: BAR_CHART_TYPE })
}

BarClusteredChart.get_axis_titles = BasePlotlyXYChart.get_axis_titles

BarClusteredChart.get_csv_value_rows = (props) => {
  return BasePlotlyXYChart.get_csv_value_rows({ ...props, chart_type: BAR_CHART_TYPE })
}

export default BarClusteredChart
