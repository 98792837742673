import axios from 'axios'

import { SERVER_VERSION_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err } from './axios_utils.js'

export function get_server_version() {
  return axios.get(SERVER_VERSION_BASE_URL)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get version: ')}
    )
}