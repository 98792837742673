import React from 'react'

import { format_integer_with_comma } from '../../utils/utils.js'
import { should_report_show_ungrouped_families } from '../../utils/user_settings_utils.js'

const FamiliesCountDisplay = ({ families_count, user_state, user_settings, className }) => {
  const {total_families_count, grouped_families_count} = families_count || {}
  const show_ungrouped_families = user_state ? should_report_show_ungrouped_families(user_state || {}, user_settings || {}) : false
  const families_count_to_display = show_ungrouped_families ? total_families_count : grouped_families_count

  return (
    <div className={className}>
      {`${families_count_to_display ? `${format_integer_with_comma(families_count_to_display)} families`: ''} `}
    </div>
  )
}

export default FamiliesCountDisplay