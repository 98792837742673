import _ from 'underscore'

import { BENCHMARKING_REPORT_TYPE } from '../constants/constants.js'
import { BENCHMARKING, REPORT, PROJECT } from '../constants/paths.js'

const EXTERNAL_REPORT_ID_REGEX = /^.*\/(report|benchmarking)\/([a-zA-Z0-9]+).*$/
const EXTERNAL_OR_INTERNAL_REPORT_ID_REGEX = /^.*\/(report|benchmarking)\/([a-zA-Z0-9-.]{8,}).*$/
const REPORT_STATE_ID_REGEX = /^.*\/(goto\/([a-zA-Z0-9]+).*$)/
const PROJECT_PAGE_ID_REGEX = /^.*\/project\/([a-zA-Z0-9]+).*$/
/**
 * Returns true if url has form ..../report/<externalReportId> or /benchmarking/<externalReportId....
 */
export function is_report_url(url) {
  return EXTERNAL_REPORT_ID_REGEX.test(url)
}

/**
 * Returns true if url has form ..../project/123
 */
export function is_project_url(url) {
  return PROJECT_PAGE_ID_REGEX.test(url)
}

/**
 * Returns external report id from url that has form ..../report/<externalReportId> or /benchmarking/<externalReportId>....
 */
export function extract_external_report_id(url) {
  return extract_id_from_report_url(url, EXTERNAL_REPORT_ID_REGEX)
}

export function extract_internal_or_external_report_id(url) {
  return extract_id_from_report_url(url, EXTERNAL_OR_INTERNAL_REPORT_ID_REGEX)
}

export function extract_report_state_id(url) {
  return extract_id_from_report_url(url, REPORT_STATE_ID_REGEX)
}

export function extract_project_id(url) {
 return extract_project_id_from_url(url, PROJECT_PAGE_ID_REGEX)
}

function extract_id_from_report_url(url, regex) {
  const matches = url.match(regex)
  if (matches && matches.length >= 3) {
    return matches[2]
  }
  return null
}

function extract_project_id_from_url(url, regex) {
  const matches = url.match(regex)
  if (matches && matches.length === 2) {
    return matches[1]
  }
  return null
}

export function report_has_bespoke_viewer(report_type) {
  return _.contains([BENCHMARKING_REPORT_TYPE], report_type)
}

export function get_report_viewer_url(report_type, external_report_id) {
  switch (report_type) {
    case BENCHMARKING_REPORT_TYPE:
      return `${BENCHMARKING}/${external_report_id}`
    default:
      return `${REPORT}/${external_report_id}`
  }
}

export function get_project_viewer_url(project_id) {
  return `${PROJECT}/${project_id}`
}