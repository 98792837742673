import _ from 'underscore'
import { extent } from 'd3'

import {PAT_FAM_STATUS_COLOURS, STATUS_TO_STATUS_LABEL} from '../constants/family_status.js'
import {DESCENDING} from '../model/sort_directions.js'
import { COST_BUCKET_SIZE, PVIX_BUCKET_SIZE } from '../constants/specs_params.js'
import { is_array_non_empty_non_null, sum } from './utils.js'
import { get_short_currency_value_formatter, USD_ITEM } from '../model/currencies.js'

/**
 * Takes input array [from_year, to_year].
 * The to_year is exclusive.
 * So [2012, 2015] will give items for (2012, 2013, 2014).
 */
export function get_year_items([ from, to ]) {
  if ((from == null) || (to == null)) return []
  return _.range(from, to).map(year => {
    return {
      id: year,
      name: year,
      short_name: year
    }
  })
}

export function filter_expiry_ages_out_of_range(values) {
  return filter_values_out_of_range(values, 1, 25)
}

export function filter_values_out_of_range(values, from, to) {
  return values.filter(value => value >= from && value <= to)
}

/**
 * Takes input array eg: ["China", "USA", "Global"]
 * and returns an array of item objects, eg:
 * [{"id": "China", "value": "China"},{"id": "USA", "value": "USA"},{"id": "Global", "value": "Global"}]
 * ready for use in SelectDropdown menus etc
 * @param values
 */
export function values_to_item_objects(values) {
  return values.map(value => ({"id": value, short_name: value, "name": value}))
}

export function get_status_items(statuses) {
  const status_items = statuses.map((status_id, i) => {
    const status_label = STATUS_TO_STATUS_LABEL[status_id]
    const status_colour = PAT_FAM_STATUS_COLOURS[status_id]

    return {id: status_id, name: status_label, short_name: status_label, idx: i, color: status_colour}
  })

  return status_items
}

export function get_default_classifier_score_bucket_items() {
  // Returns buckets of width 0.1

  return _.range(0, 10).map(i => {
    const from_score = i / 10
    const to_score   = (i + 1) / 10

    const label = `${from_score.toFixed(1)} - ${to_score.toFixed(1)}`

    return {
      id: from_score + "", // must be string for react table
      name: label,
      short_name: label
    }

  })
}

/**
 * Returns the countries for which we've got data for (value > 0)
 * @returns {*}
 */
export function get_jurisdiction_data(territories, data) {
  return territories.filter(territory => {
    const index = data[0].indexOf(territory.id)
    return (index !== -1 && data[1][index] > 0)
  })
}

/**
 * Concatenates items_a and items_b, only adding b items not yet in a.
 */
export function concat_unique(items_a, items_b) {
  const a_ids = items_a.map(item => item.id)

  return items_b.reduce((acc, item) => {
    const already_added = a_ids[item.id] != null
    return already_added ? acc : [...acc, item]
  }, items_a)
}

/**
 * Sort table rows by values in a chosen field
 */
export function sort_table_data(data, sort_field_id, sort_direction_id) {
  const sorted_data = _.sortBy(data, (row) => {
    const value = row[sort_field_id]
    if (value == null) {
      return ''
    }
    return _.isString(value) ? value.toLowerCase() : value
  })

  return (sort_direction_id === DESCENDING) ? sorted_data.slice().reverse() : sorted_data
}

function get_distribution_buckets(query_data, bucket_size, value_formatter) {
  const { data } = query_data
  const [values_column,,] = data

  const min_value = _.min(values_column)
  const max_value = _.max(values_column)

  const range_min = Math.floor(min_value / bucket_size) * bucket_size
  const range_max = Math.ceil(max_value / bucket_size) * bucket_size

  return _.range(range_min, (range_max + 1), bucket_size).map(min_score => bucket_min_to_item(min_score, bucket_size, value_formatter))
}

export function get_cost_buckets(query_data) {
  const value_formatter = get_short_currency_value_formatter(USD_ITEM)
  return get_distribution_buckets(query_data, COST_BUCKET_SIZE, value_formatter)
}

export function get_pvix_score_buckets(query_data) {
  return get_distribution_buckets(query_data, PVIX_BUCKET_SIZE)
}

export function get_pvix_scatter_axis_range(y_min, y_max) {
  const axis_min = y_min < 10 ? 0 : (Math.round(y_min) - 1)
  return [axis_min, Math.round(y_max) + 1]
}

function bucket_min_to_item(min_value, bucket_size, value_formatter) {
  const label = value_formatter ? `${value_formatter(min_value)} - ${value_formatter(min_value + bucket_size)}` :`${min_value} - ${min_value + bucket_size}`
  return {
    id: `${min_value}`,
    name: label,
    short_name: label
  }
}

function transform_histogram_data(query_data, bucket_size) {
  if (!query_data) {
    return query_data
  }

  const { data } = query_data
  const [values_column,,] = data
  const value_buckets_column = values_column.map(item => {
    return item - (item % bucket_size)
  })

  const updated_data = [...data]
  updated_data[0] = value_buckets_column

  return {
    ...query_data, data: updated_data
  }
}

export function transform_pvix_histogram_data(query_data) {
  return transform_histogram_data(query_data, PVIX_BUCKET_SIZE)
}

export function transform_cost_histogram_data(query_data) {
  return transform_histogram_data(query_data, COST_BUCKET_SIZE)
}

export function get_count_items(query_data, idx){
  const { data } = query_data
  const counts = _.uniq(data[idx])

  const count_items = counts.map(count =>{
    return {
      id: count,
      name: `${count}`,
      short_name: `${count}`
    }
  })

  return count_items
}

/**
 * Transforms the dataset values to percentages considering totals from the whole dataset returned.
 * It assumes the following:
 *    data[1]: contains the filter to group by the values
 *    data[2]: are the values
 * @param query_data
 * @returns {*}
 */
export function to_percentages(query_data) {
  const { data } = query_data
  const [ column_key_1, column_key_2, column_values ] = data
  const totals_by_key_2 = get_totals_by_key(column_key_2, column_values)
  const percentages = column_values.map((value, idx) => {
    return ((value / totals_by_key_2[column_key_2[idx]]) * 100).toFixed(2) * 1
  })
  return {...query_data, data: [column_key_1, column_key_2, percentages]}
}

function get_totals_by_key(key_column, values_column) {
  return key_column.reduce((acc, key_2_id, idx) => {
    acc[key_2_id] = values_column[idx] + (acc[key_2_id] || 0)
    return acc
  }, {})
}

export function to_cumulative_percentages(query_data) {
  const { data } = query_data
  const [ column_key_1, column_key_2, column_values ] = data

  if (!is_array_non_empty_non_null(column_key_1)) {
    return query_data
  }

  const totals_by_key_2 = get_totals_by_key(column_key_2, column_values)

  const new_col_key_1 = []
  const new_col_key_2 = []
  const percentages = []

  const all_key_2_values = _.uniq(column_key_2)
  const [min_col_1, max_col_1] = extent(column_key_1)

  // use the full range of integers so we don't have any gaps
  _.range(min_col_1, max_col_1)
    .forEach(key_1 => {
      all_key_2_values.forEach(key_2 => {

        let cumulative_total = 0

        column_values.forEach((value, idx) => {
          if (column_key_2[idx] === key_2 && column_key_1[idx] <= key_1) {
            cumulative_total += value
          }
        })

        const cumulative_percentage = ((cumulative_total / totals_by_key_2[key_2]) * 100).toFixed(2) * 1

        new_col_key_1.push(key_1)
        new_col_key_2.push(key_2)
        percentages.push(cumulative_percentage)
      })
    })

  return {...query_data, data: [new_col_key_1, new_col_key_2, percentages]}
}

export function transform_granted_families_age_data(query_data) {
  const {columns, data, types} = query_data

  const KEY_SEPARATOR = '_'

  const portfolio = data[0]
  const tech = data[1]
  const time_difference_object = data[3] //lapsed days calculated for every family

  const portfolio_and_tech_to_years = {}

  portfolio.forEach((portfolio_id, i) => {
    const tech_id = tech[i]
    const {days=0} = time_difference_object[i] || {}

    const key = `${portfolio_id}${KEY_SEPARATOR}${tech_id}`
    portfolio_and_tech_to_years[key] = [...portfolio_and_tech_to_years[key] || [], days / 365]
  })

  const portfolio_column = []
  const technology_column = []
  const avg_age_column = []

  Object.keys(portfolio_and_tech_to_years).forEach(key => {
    const [portfolio_id, tech_id] = key.split(KEY_SEPARATOR)

    const all_ages = portfolio_and_tech_to_years[key] || []
    const avg_age = sum(all_ages) / all_ages.length

    portfolio_column.push(portfolio_id * 1) //restore id format to integer
    technology_column.push(tech_id * 1)
    avg_age_column.push(avg_age)
  })

  return {
    types: [types[0], types[1], 'DOUBLE'],
    columns: [columns[0], columns[1], 'age'],
    data: [portfolio_column, technology_column, avg_age_column]
  }
}