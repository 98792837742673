import React  from 'react'
import _ from 'underscore'

import { Pane } from '../widgets/Block.js'
import Spinner from '../widgets/Spinner.js'
import { withReportBasket } from '../builder/ReportBasketContext.js'
import Organisation from './Organisation.js'
import { get_org_id, get_parent_details } from '../../utils/organisation_utils.js'

const OrgSearchResultsDisplay = (
  {
    results,
    show_spinner,
    search_phrase,
    on_result_check_in,
    on_show_similar_organisations,
    selected_organisations,
    similar_orgs_mode,
    org_ungroup_handler,
    add_child_handler,
    edit_handler,
    context,
    freeze_grouping_actions,
    show_org_ids,
    filter_empty
  }) => {

  const has_results = (results && results.length > 0)
  const searched_but_nothing_found = (Array.isArray(results)) && (results.length === 0) && (search_phrase !== '') && !show_spinner
  const selected_parents = (selected_organisations || []).map(item => (item.parents))
  const selected_parents_tags = (selected_organisations || []).map(item => (item.parent_tags))
  const selected_parents_set =  [...new Set(_.flatten(selected_parents))]
  const selected_parents_tags_set =  [...new Set(_.flatten(selected_parents_tags))]

  const selected_org_ids = (selected_organisations || []).map(item => {
    return get_org_id(item)
  })

  return (
    <div>
      {show_spinner &&
        <Pane className='text-center'>
          <Spinner />
          <p>Fetching results ...</p>
        </Pane>
      }

      {!show_spinner && has_results &&
        <div>
          {results.map( (org, i) => (
            <Organisation
              context={context}
              org={org}
              key={i}
              result_reference={[i]}
              similar_orgs_mode={similar_orgs_mode}

              on_result_check_in={on_result_check_in}
              selected_organisations={selected_org_ids}
              selected_parents={selected_parents_set}
              selected_parents_tags={selected_parents_tags_set}
              org_ungroup_handler={org_ungroup_handler}
              add_child_handler={add_child_handler}
              edit_handler={edit_handler}
              show_similar_handler={on_show_similar_organisations}
              freeze_grouping_actions={freeze_grouping_actions}
              get_parent_details={org => get_parent_details(org, results)}
              show_org_ids={show_org_ids}
              filter_empty={filter_empty != null ? filter_empty : false}
            />
          ))}
        </div>
      }

      {!show_spinner && searched_but_nothing_found &&
        <Pane>
          <p>No results found for <strong>{search_phrase}</strong></p>
          <p>Suggestions:</p>
          <ul className='list-unstyled my-2'>
            <li>Make sure that all words are spelled correctly.</li>
            <li>Try different words.</li>
          </ul>
        </Pane>
      }

    </div>
  )

}

export default withReportBasket(OrgSearchResultsDisplay)