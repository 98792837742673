import React from 'react'
import cn from 'classnames'

import s from './DashboardTileDesc.module.scss'

export const DashboardTileDesc = ({className, children}) => {
  return (
    <div className={cn('my-3 w-100', s.tile_desc, className)}>
      {children}
    </div>
  )
}