import axios from 'axios'

import { CHOREO_BASE_URL } from '../constants/urls.js'
import { CONFIG_NO_CACHE, add_source_err_to_target_err, is_bad_request } from '../utils/axios_utils.js'
import { get_data_version_from_report_id, get_timestamp_from_report_id } from './utils.js'
import { STATUS_COMPLETED, STATUS_FAILED, RUNNING_CLASSIFIERS_TASK_DESC } from '../model/report_tasks_and_statuses.js'
import { fetch_server_config } from './config_utils.js'
import { is_classifier_landscape_report_type } from './report_utils.js'
import { PATENT_FAMILIES_S3_PORTFOLIO_TYPE } from '../model/portfolios.js'

const DEFAULT_POLL_INTERVAL = 10 * 1000
const STATUS = 'status'

export const JSON_HEADER_CONFIG = { headers: {"Content-Type": "application/json" }}

export function poll(req_fn, is_done_fn, is_fail_fn, is_error_fn, progress_fn, is_interrupt_fn, interval) {
  interval = interval || DEFAULT_POLL_INTERVAL

  function _poll(resolve, reject) {
    req_fn()
      .then(response => {
        if (is_done_fn(response) || (is_fail_fn && is_fail_fn(response))) {
          // Finished or failed
          resolve(response)
          return
        }

        if (is_error_fn && is_error_fn(response)) {
          // Error
          reject(new Error(`error from polling. Response is:  ${JSON.stringify(response)}`))
          return
        }

        // In progress (or not yet started)

        if (is_interrupt_fn && is_interrupt_fn()) {
          // User interrupted, so just return the response as-is (with an extra flag)
          resolve({ ...response, is_interrupted: true })
          return
        }

        if (progress_fn) {
          // Let progress callback know how things are going
          progress_fn(response)
        }

        // Wait and re-poll
        setTimeout(_poll, interval, resolve, reject)
      })
      .catch(error => {
        reject(error)
      })
  }

  return new Promise(_poll)
}

function is_report_ready(choreo_status) {
  return choreo_status[STATUS] === STATUS_COMPLETED
}

export function is_report_fail(choreo_status) {
  return choreo_status[STATUS] === STATUS_FAILED
}

function get_s3_bucket_and_key(s3_url) {
  const parts = s3_url.split('/')
  const bucket = parts.slice(2, 3)[0]
  const key = parts.slice(3).join('/')
  return [bucket, key]
}

function transform_s3_all_families_input(portfolio, s3_urls) {
  const { type, custom_families_id } = portfolio
  const s3_url = s3_urls[custom_families_id]
  if (!s3_url) {
    return portfolio
  }
  const [bucket, key] = get_s3_bucket_and_key(s3_url)
  return {type, bucket, key}
}

/**
 * Fetches values configured server-side which need to be added to the report input
 * before it can be sent to the choreo /report and /report/existing routes
 */
export function transform_report_input_for_choreo(report_input) {
  return fetch_server_config()
    .then(config => {
      const {input_schema_version, data_source_id, s3_urls} = config
      const {report_type} = report_input

      const input_with_config = {
        ...report_input,
        input_schema_version,
        data_source_id
      }

      if (is_classifier_landscape_report_type(report_type)) {
        const {portfolios} = report_input
        const portfolios_with_config = (portfolios || [])
          .map(portfolio => {
            if (portfolio.type === PATENT_FAMILIES_S3_PORTFOLIO_TYPE) {
              return transform_s3_all_families_input(portfolio, s3_urls)
            }
            return portfolio
          })
        return {
          ...input_with_config,
          portfolios: portfolios_with_config
        }
      }
      return input_with_config
    })
}

export function create_report_on_choreo(report_input) {
  return transform_report_input_for_choreo(report_input)
    .then(full_report_input => {
      return axios.post(`${CHOREO_BASE_URL}/report`, full_report_input, JSON_HEADER_CONFIG)
        .then(response => response.data)
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to create report on choreo: ')
      throw wrapped_err
    })
}

function fetch_choreo_existing_reports(report_input) {
  return transform_report_input_for_choreo(report_input)
    .then(full_report_input => {
      return axios.post(`${CHOREO_BASE_URL}/report/existing`, full_report_input, JSON_HEADER_CONFIG)
        .then(response => response.data)
    })
}

export function check_for_existing_and_in_progress_reports(report_input) {
  return fetch_choreo_existing_reports(report_input)
    .then(({running_report_id, completed_report_id}) => {
      return {
        running_report: get_report_with_timestamp_from_report_id(running_report_id),
        completed_report: get_report_with_timestamp_from_report_id(completed_report_id)
      }
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to check for existing reports on choreo: ')
      throw wrapped_err
    })
}

export function report_input_is_valid(report_input) {
  return transform_report_input_for_choreo(report_input)
    .then(full_report_input => axios.post(`${CHOREO_BASE_URL}/report/validate_input`, full_report_input, JSON_HEADER_CONFIG))
    .then(() => ({is_valid: true}))
    .catch(err => {
      if (is_bad_request(err)) {
        const {data} = err.response || {}
        return {is_valid: false, message: data}
      }
      throw err
    })
}

function get_report_with_timestamp_from_report_id(internal_report_id) {
  if (!internal_report_id) {
    return null
  }
  return {
    report_id: internal_report_id,
    timestamp: get_timestamp_from_report_id(internal_report_id),
    data_version: get_data_version_from_report_id(internal_report_id)
  }
}

export function fetch_choreo_status(report_id) {
  return axios.get(`${CHOREO_BASE_URL}/report/${report_id}/progress`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch choreo status: ')
      throw wrapped_err
    })
}

export function fetch_choreo_recent_reports_summary() {
  return axios.get(`${CHOREO_BASE_URL}/report`)
    .then(response => response.data)
    .then(reports => reports.filter(r => r.status !== STATUS_COMPLETED))
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch choreo summary: ')
      throw wrapped_err
    })
}

export function poll_choreo_until_report_is_done(report_id, progress_fn, is_interupt_fn) {

  return poll(
    fetch_choreo_status.bind(null, report_id),
    is_report_ready,
    is_report_fail,
    null,
    progress_fn,
    is_interupt_fn,
    DEFAULT_POLL_INTERVAL,
  )
}

export function get_report_classifier_task_details(choreo_status) {
  const { tasks } = choreo_status || {}

  const classifier_task = (tasks || []).find(task => task.task_description === RUNNING_CLASSIFIERS_TASK_DESC)

  const { details } = classifier_task || {}

  return details || {}
}
