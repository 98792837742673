import React from 'react'

import { PaneHeader } from '../widgets/PaneHeader.js'

const PanelOptions = ({title, menu }) => {
  return (
    <div className='d-flex justify-content-between'>
      <PaneHeader text={title}/>
      {menu}
    </div>
  )
}

export default PanelOptions