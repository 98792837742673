import { AMBIGUOUS, FOUND, ID_TO_STATUS, UNKNOWN_NUMBER, UNRECOGNISED_FORMAT } from './matched_patents_table_model.js'
import { get_key_by_value } from '../../../utils/utils.js'

export function is_status_not_found(status, should_find_status_id) {
  status = should_find_status_id ? get_status_id_by_name(status) : status
  return (status !== undefined && (status === UNKNOWN_NUMBER.id || status === UNRECOGNISED_FORMAT.id))
}

export function is_status_ambiguous(status, should_find_status_id) {
  status = should_find_status_id ? get_status_id_by_name(status) : status
  return (status !== undefined && (status === AMBIGUOUS.id))
}
export function is_status_found(status, should_find_status_id) {
  status = should_find_status_id ? get_status_id_by_name(status) : status
  return (status !== undefined && (status === FOUND.id))
}
export function to_csv(table) {
  const table_with_no_quotes = table.map(row => row.map(cell => cell.toString().replace(/"/g, '')))
  return table_with_no_quotes.map(row => row.map(c => {
    return c.toString().includes(',') ? `"${c}"` : c
  }).join(',')).join('\n')
}
export function is_status_in_matches (lines, status) {
  for (let i=0;i<lines.length;i++){
    const line = lines[i]
    if (line.status === status.id) {
      return true
    }
  }
  return false
}

export function filter_matched_patents(lines) {
  return (lines || []).filter((line) => (line.family !== undefined && !line.ignore && !is_status_not_found(line.status)))
}

export function consider_selected_matches(lines) {
  const cfamily_regex = /C0*([1-9]\d*)/
  const family_ids_to_add = filter_matched_patents(lines || []).map((line) => parseInt(line.family.match(cfamily_regex)[1]))
  return family_ids_to_add
}

export function create_line(matches, selected, ignore, row_number) {
  const match = selected !== -1 ? matches[selected] : matches[0]

  const {lineno, original, serial, family, application_date, assignee, owner, title, publication, type, status, span} = match || {}

  const status_id = get_status_id_by_name(status)

  const is_ignored = ignore === null ? is_status_not_found(status_id) : ignore

  const [span_start, span_end] = span || []
  
  const source = ((span_start != null) && (span_end != null)) ? original.slice(span_start, span_end+1) : original

  return {
    lineno,
    row_number,
    source,
    ignore: is_ignored,
    matches: matches,
    match_selected: selected,
    serial,
    status: status_id,
    type: type ? type : '',
    publication: publication ? publication : '',
    family,
    application_date,
    assignee,
    owner,
    title,
  }
}

export function sort_by_status(line1, line2) {
  //sort priority:
  //  1) not found
  //  2) ambiguous
  //  3) OK

  if (line1.status === line2.status)
    return 0

  if (is_status_not_found(line1.status) || ((line1.status === AMBIGUOUS.id) && !is_status_not_found(line2.status))) {
    return -1
  } else {
    return 1
  }
}

export function initial_sort(lines_to_sort) {
  lines_to_sort.sort((line1, line2) => sort_by_status(line1, line2))
  return lines_to_sort
}

export function get_lines(data) {
  return data.map((matches, i) => create_line(matches, (matches.length === 1) ? 0 : -1, null, i))
}

export function transform_matches_to_table_rows(data) {
  return initial_sort(get_lines(data))
}

export function get_status_id_by_name(status_name) {
  return get_key_by_value(ID_TO_STATUS, status_name)
}