/**
 * URL can contain an optional '?show_scope=true' query arg.
 * This file contains functions for reading/updating this.
 */

import _ from 'underscore'
import { parse, stringify } from 'query-string'

const SHOW_SCOPE_KEY = 'show_scope'

function get_parsed_url_query() {
  const search = window.location.search
  return parse(search)
}

export function get_is_show_scope() {
  const query = get_parsed_url_query()
  return query[SHOW_SCOPE_KEY]
}

export function show_scope_modal(history) {
  const query            = get_parsed_url_query()
  const new_query        = {...query, [SHOW_SCOPE_KEY]: true}
  const new_query_string = stringify(new_query)
  history.replace({ search: new_query_string })
}

export function hide_scope_modal(history) {
  const query            = get_parsed_url_query()
  const new_query        = _.omit(query, SHOW_SCOPE_KEY)
  const new_query_string = stringify(new_query)
  history.replace({ search: new_query_string })
}

export function toggle_scope_modal(history) {
  const query = get_parsed_url_query()
  if (query[SHOW_SCOPE_KEY]) {
    hide_scope_modal(history)
  }
  else {
    show_scope_modal(history)
  }
}

