import React from 'react'
import cn from 'classnames'

const Grid = ({ className, children }) => {
  return (
    <div
      className={cn('d-sm-flex flex-sm-wrap align-content-stretch', className)}
    >
      {children}
    </div>
  )
}

export default Grid