import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import { DECK_DEFAULT_COLUMN_COLOR } from '../../constants/colours.js'

import s from './ReportDeckLegend.module.scss'

const ReportDeckLegend = ({ items }) => {

  const MAX_LEGEND_ITEMS = 12
  const MAX_NORMAL_SIZE_ITEMS = 5

  const unique_colours = _.keys(_.omit(_.countBy(items, 'color'), value => value > 1))
  const displayable_items = _.first(items.filter(item => _.contains(unique_colours, item.color)), MAX_LEGEND_ITEMS)

  const show_mini_labels = displayable_items.length > MAX_NORMAL_SIZE_ITEMS

  return (
    <div className={cn('d-flex justify-content-end', s.legend)}>
      {displayable_items && !is_single_default_item(displayable_items) &&
        <>
          {displayable_items.map((item, i) => {
            return (
              <div key={i} title={item.name} className='d-flex'>
                <div
                  className={s.legend_color}
                  style={{backgroundColor: item.color}}
                />
                <span className={cn(s.legend_label, {[s.mini_label]: show_mini_labels})}>
        {item.name}
          </span>
              </div>
            )
          })}
        </>
      }
    </div>
  )
}

function is_single_default_item(displayable_items) {
  return displayable_items.length === 1 && _.first(displayable_items).color === DECK_DEFAULT_COLUMN_COLOR
}

export default ReportDeckLegend