import * as d3 from 'd3'
import { Y_COLUMN_IDX } from './treemap_utils.js'
import { get_value_column_idx } from './column_data_utils.js'

export function get_year_min_max_totals({data, timerange}) {
  const value_column_idx = get_value_column_idx(data)
  const [year_min, year_max] = timerange
  const year_max_totals = d3.sum(data.data[value_column_idx].filter((item, i) => data.data[Y_COLUMN_IDX][i] === year_max))
  const year_min_totals = d3.sum(data.data[value_column_idx].filter((item, i) => data.data[Y_COLUMN_IDX][i]=== year_min))

  return { year_min_totals, year_max_totals}
}

export function get_share_data({keys_to_value, x_item, timerange, year_min_totals, year_max_totals}) {
  const [year_min, year_max] = timerange

  const year_max_size = keys_to_value[x_item.id+'_'+year_max] || 0
  const year_min_size = keys_to_value[x_item.id+'_'+year_min] || 0

  const year_max_share = year_max_totals ? year_max_size/year_max_totals : null
  const year_min_share = year_min_totals ? year_min_size/year_min_totals : null

  const share_change_rel = (year_max_share && year_min_share) ? year_max_share/year_min_share : null

  return {year_min_size, year_max_size, year_min_share, year_max_share, share_change_rel}
}