import React from 'react'

import { SAVED_ORGS_LISTS_ADMIN } from '../../constants/paths.js'
import TextLink from '../widgets/TextLink.js'

const OrgListAdminLink = ({label, className}) => {
  return (
    <TextLink element='a' href={SAVED_ORGS_LISTS_ADMIN} className={className}>{label || 'Manage'}</TextLink>
  )
}

export default OrgListAdminLink