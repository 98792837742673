import axios from 'axios'
import _ from 'underscore'

import { PATFAM_SAMPLE_BASE_URL, SUGGESTIONS_BASE_URL } from '../constants/urls.js'

import { NEGATIVE, POSITIVE } from '../components/classifiers_editor/constants/labels.js'

import { CONFIG_NO_CACHE, JSON_POST_HEADER, add_source_err_to_target_err } from './axios_utils.js'
import {
  get_patent_families_by_search_phrase
} from './patent_family_list_utils.js'

import { RANDOM_ID } from '../model/patent_family_fields.js'

import { ID_TO_SUGGESTION_STRATEGY_GROUP } from '../components/classifiers_editor/model/suggestions_strategies.js'
import { classify_patfams, fetch_and_classify_patfams } from './training_set_grpc_utils.js'
import { startsWith } from './utils.js'
import { CLASSIFIERS } from '../constants/paths.js'
import { CLASSIFIER } from '../components/classifiers_editor/constants/classifier_paths.js'

const SUGGESTIONS_MAX_PATFAMS = 250

export function get_patents_by_search_phrase_with_score(search_phrase, size, from, sort_random, latest_built_classifier_version, training_set_alias) {
  const sort_by_field_id = sort_random ? RANDOM_ID : null // 'random' makes tech-search-service sort using a random score

  // 1. Get patents by search phrase (from TextSearchService)
  return get_patent_families_by_search_phrase(search_phrase, size, from, sort_by_field_id)
    .then(({ searchResults: result_patents, totalResults: total_num_results }) => {
      if (latest_built_classifier_version == null || total_num_results === 0) {
        // 2a. No classifier built, or no results: so return as is.
        return { result_patents, total_num_results }
      }

      // 2b. Results: so classify them, and merge in the scores
      const pfam_ids = result_patents.map(p => p.patFamId)
      return classify_patfams(training_set_alias, latest_built_classifier_version, pfam_ids)
        .then((scores) => {
          const result_patents_with_score = result_patents.map((pfam, idx) => {
            const score = scores[idx]
            return { ...pfam, score }
          })
          return { result_patents: result_patents_with_score, total_num_results }
        })
    })
    .then(({ result_patents, total_num_results }) => {
      // 3. Normalise results to CC format.
      const result_patents_normalised = result_patents.map(patent => {
        return {
          ...patent,
          id: patent.patFamId // make sure it has an id field
        }
      })

      return { result_patents: result_patents_normalised, total_num_results }
    })

}

export function fetch_suggestions(training_set_id, latest_built_classifier_version, training_set_patfams, num_results, strategy_group_id, name, description, seen_patfam_ids, key_phrases ) {
  const strategy_group = ID_TO_SUGGESTION_STRATEGY_GROUP[strategy_group_id]
  const { endpoint } = strategy_group

  const url = SUGGESTIONS_BASE_URL + endpoint

  const params = {
    limit: num_results || 10
  }

  const training_set_patfams_pos_neg = training_set_patfams.filter(patfam => _.contains([POSITIVE, NEGATIVE], patfam.user_class))

  const training_set_patfams_limited = training_set_patfams_pos_neg.length > SUGGESTIONS_MAX_PATFAMS ? _.sample(training_set_patfams_pos_neg, SUGGESTIONS_MAX_PATFAMS) : training_set_patfams_pos_neg

  const training_set_patfams_renamed = training_set_patfams_limited.map(patfam => {
    const { patfam_id, patFamId, id, user_class, cpcCodes, score } = patfam

    // TODO: ensure we only use one of patfam_id / patFamId / id - so confusing!

    return {
      pat_fam_id: patfam_id || patFamId || id,
      class: user_class,
      score,
      cpc_codes: cpcCodes || []
    }
  })

  const body = {
    training_set: training_set_patfams_renamed,
    classifier_name: name,
    classifier_description: description,
    classifier_key_phrases: key_phrases,
    seen_pat_fam_ids: seen_patfam_ids,
    training_set_id,
    latest_classifier_id: training_set_id,
    latest_classifier_version: latest_built_classifier_version
  }

  // 1. Fetch suggestions from Classifiers service
  return axios.post(url, body, { params, headers: JSON_POST_HEADER })
    .then(response => {
      return response.data
    })
    .then(suggestions => {
      // 2. Fetch and merge patents from Domain service
      const suggestions_clean = suggestions.map(suggestion => ({ ...suggestion, patfam_id: suggestion.id })) // set patfam_id property
      return fetch_and_classify_patfams(suggestions_clean, null, training_set_id, null /* no classifier specified, so that classification is skipped (suggestions response contains scores) */)
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch patent suggestions from custom_classifiers and/or domain service: `)
      throw wrapped_err
    })
}

export function fetch_random_patfam_ids(num_ids, evaluation_sample_region_id) {
  return axios.get(PATFAM_SAMPLE_BASE_URL + `/random_patfam_ids?n=${num_ids}&region_id=${evaluation_sample_region_id}`, CONFIG_NO_CACHE)
    .then(response => {
      return response.data
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), `Unable to fetch random patfam ids from server: `)
      throw wrapped_err
    })
}

export function check_is_in_classifiers_ui(location) {
  const path = location.pathname
  return startsWith(path, CLASSIFIERS)
}

export function check_is_eval_report_with_access(eval_training_set_id, eval_classifier_data) {
  return eval_training_set_id != null && eval_classifier_data != null /*has access*/
}

export function get_training_set_id_from_url(location) {
  const path = location.pathname

  const expected_prefix = `${CLASSIFIERS}/${CLASSIFIER}/`

  const starts_as_expected = (path.indexOf(expected_prefix) === 0)
  if (!starts_as_expected) {
    return null
  }

  const suffix = path.slice(expected_prefix.length)
  if ((suffix.indexOf('/') === -1) || (suffix.length < 2)) {
    return null
  }

  const parts = suffix.split("/")
  if (parts.length === 0) {
    return null
  }

  return parts[0]
}