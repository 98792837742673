import axios from 'axios'
import _ from 'underscore'

import { REPORT_HISTORY_BASE_URL } from '../constants/urls.js'

import { CONFIG_NO_CACHE, JSON_POST_HEADER, add_source_err_to_target_err, is_not_found } from './axios_utils.js'
import { save_report_user_state_to_window } from './user_state_utils.js'
import { fetch_choreo_status, get_report_classifier_task_details } from './choreo_utils.js'
import { fetch_report_build_status } from './report_reader_utils.js'
import { STATUS_COMPLETED, STATUS_STARTED } from '../model/report_tasks_and_statuses.js'
import { send_error_to_sentry } from './sentry_utils.js'

export const REPORT_SEARCH_PARAM_NAME = 'search'

export function save_user_state_to_history(external_report_id, user_state) {
  // always update in window for debugging and feedback purposes
  save_report_user_state_to_window(user_state)
  return save_report_user_state_to_db(external_report_id, user_state)
}

export function save_report_user_state_to_db(external_report_id, user_state) {
  return axios.post(`${REPORT_HISTORY_BASE_URL}/${external_report_id}/user_state`, user_state, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update user state in report viewed: ')
    })
}

/**
 * Returns user's viewed reports, excluding Classifier Evaluation reports.
 */
export function fetch_report_history(max_reports, ignore_failed, fetch_tags, exclude_eval_reports) {
  const params = { limit: max_reports, ignore_failed, fetch_tags, exclude_eval_reports }
  return axios.get(
    REPORT_HISTORY_BASE_URL, {params, ...CONFIG_NO_CACHE}
  )
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch report history: ')
      throw wrapped_err
    })
}

export function fetch_report_owners() {
  return axios.get(`${REPORT_HISTORY_BASE_URL}/owners`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch owner names for report history: ')
      throw wrapped_err
    })
}

export function fetch_eval_classifier_names() {
  return axios.get(`${REPORT_HISTORY_BASE_URL}/eval_classifier_names`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch owner names for report history: ')
      throw wrapped_err
    })
}

/**
 * Returns user's viewed Classifier Evaluation reports only.
 */
export function fetch_classifier_evaluation_report_history(classifier_id, max_reports) {
  const params = { classifier_id, limit: max_reports }
  return axios.get(
    `${REPORT_HISTORY_BASE_URL}/classifier_evaluation`, {params, ...CONFIG_NO_CACHE}
  )
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch classifier evaluation report history: ')
      throw wrapped_err
    })
}

function fetch_report_status_if_known(internal_report_id) {
  return fetch_report_build_status(internal_report_id)
    .catch(err => {
      if (!is_not_found(err)) {
        throw err
      }
    })
    .then(build_status => {
      if (build_status) {
        const {status} = build_status
        return {report_id: internal_report_id, status}
      }
      // check if report is still running
      return fetch_choreo_status(internal_report_id)
    })
    .catch(err => {
      // for the moment, while we run two choreos, we can expect this to happen sometimes
      if (!is_not_found(err)) {
        // unexpected response from either RR or choreo
        // send error to sentry, but avoid breaking the history page for the user
        send_error_to_sentry(err, {internal_report_id})
      }
      return {report_id: internal_report_id, status: 'Unknown'}
    })
}

export function update_reports_with_latest_status(reports) {
  const incomplete_reports = reports.filter(report => !report.status)

  return Promise.all(incomplete_reports.map(report => fetch_report_status_if_known(report.internal_report_id)))
    .then(choreo_statuses => {
      // Return the reports, with the latest status added
      return reports.map(report => {
        const choreo_status = _.find(choreo_statuses, status => status.report_id === report.internal_report_id)
        return choreo_status ? {...report, status: choreo_status.status} : report
      })
    })
}

function fetch_classified_counts_from_report_build_status(internal_report_id) {
  return fetch_report_build_status(internal_report_id)
    .then((build_status) => {
      const {meta} = build_status
      const {num_pat_fams_classified, num_positives_found} = meta || {}
      return {
        num_pat_fams: num_pat_fams_classified,
        num_positives_found
      }
    })
}

function fetch_classified_counts_so_far_from_choreo(internal_report_id) {
  return fetch_choreo_status(internal_report_id)
    .then(choreo_status => get_report_classifier_task_details(choreo_status))
}

function fetch_classified_counts(internal_report_id, report_status) {
  switch (report_status) {
    case STATUS_COMPLETED:
      // report has finished; we can get the final counts from the build_status
      return fetch_classified_counts_from_report_build_status(internal_report_id)
    case STATUS_STARTED:
      // report is still building; we can extract counts from the choreo progress response
      return fetch_classified_counts_so_far_from_choreo(internal_report_id)
    default:
      // the report probably failed or is in a queue so we likely don't know the counts
      return Promise.resolve({})
  }
}

export function fetch_and_add_classified_counts(reports) {
  return Promise.all(
    reports.map(report => {
      const {internal_report_id, status} = report
      return fetch_classified_counts(internal_report_id, status)
        .catch(err => {
          if (!is_not_found(err)) {
            // report to sentry for investigation, but don't stop the user from getting on with things
            send_error_to_sentry(err, {})
          }
          return {}
        })
    }))
    .then(classifier_task_metadata => {
      return reports.map((report, i) => {
        const { num_pat_fams, num_positives_found } = classifier_task_metadata[i] || {}
        return {
          ...report,
          num_pat_fams,
          num_positives_found
        }
      })
    })
}

