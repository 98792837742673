import _ from 'underscore'
import { get_as_map } from '../../../utils/utils.js'

import { POSITIVE, NEGATIVE, IGNORE, TEST_POSITIVE, TEST_NEGATIVE } from '../constants/labels.js'

export const NO_FILTER = 'NO_FILTER'
export const TRAIN_USER_CLASS_ONLY = 'TRAIN_USER_CLASS_ONLY'
export const TRAIN_POSITIVE_USER_CLASS_ONLY = 'TRAIN_POSITIVE_USER_CLASS_ONLY'
export const TRAIN_NEGATIVE_USER_CLASS_ONLY = 'TRAIN_NEGATIVE_USER_CLASS_ONLY'
export const IGNORE_USER_CLASS_ONLY = 'IGNORE_USER_CLASS_ONLY'
export const NO_USER_CLASS_ONLY = 'NO_USER_CLASS_ONLY'
export const TEST_USER_CLASS_ONLY = 'TEST_USER_CLASS_ONLY'
export const TEST_POSITIVE_USER_CLASS_ONLY = 'TEST_POSITIVE_USER_CLASS_ONLY'
export const TEST_NEGATIVE_USER_CLASS_ONLY = 'TEST_NEGATIVE_USER_CLASS_ONLY'

export const TRAIN_TRUE_POS  = 'TRAIN_TRUE_POS'
export const TRAIN_TRUE_NEG  = 'TRAIN_TRUE_NEG'
export const TRAIN_FALSE_POS = 'TRAIN_FALSE_POS'
export const TRAIN_FALSE_NEG = 'TRAIN_FALSE_NEG'

export const TEST_TRUE_POS  = 'TEST_TRUE_POS'
export const TEST_TRUE_NEG  = 'TEST_TRUE_NEG'
export const TEST_FALSE_POS = 'TEST_FALSE_POS'
export const TEST_FALSE_NEG = 'TEST_FALSE_NEG'

export const HIGH_SCORE_ONLY = 'HIGH_SCORE_ONLY'
export const LOW_SCORE_ONLY = 'LOW_SCORE_ONLY'
export const BORDERLINE_SCORE_ONLY = 'BORDERLINE_SCORE_ONLY'
export const MEDIUM_SCORE_ONLY = 'MEDIUM_SCORE_ONLY'
export const LOW_SCORE_POSITIVES_ONLY = 'LOW_SCORE_POSITIVES_ONLY'
export const HIGH_SCORE_NEGATIVES_ONLY = 'HIGH_SCORE_NEGATIVES_ONLY'
export const CUSTOM_SCORE_RANGE = 'CUSTOM_FILTER'

export function get_default_custom_score_range() {
  return [0, 1]
}

export const NO_FILTERS = [
  { id: NO_FILTER, name: 'all', filter_fn: () => true },
]

const FILTER_TRAIN_USER_CLASS_ONLY= { id: TRAIN_USER_CLASS_ONLY,
  name: 'train (all)',
  short_name: 'all',
  filter_fn: (patent) => (patent.user_class === POSITIVE) || (patent.user_class === NEGATIVE)
}

const FILTER_TRAIN_POSITIVE_USER_CLASS_ONLY = {
  id: TRAIN_POSITIVE_USER_CLASS_ONLY,
  name: 'train positives',
  short_name: 'positives',
  filter_fn: (patent) => patent.user_class === POSITIVE
}

const FILTER_TRAIN_TRUE_POS = {
  id: TRAIN_TRUE_POS,
  name: 'train true positives',
  short_name: 'true pos',
  filter_fn: (patent) => (patent.user_class === POSITIVE) && (patent.score != null) && (patent.score >= 0.5)
}

const FILTER_TRAIN_FALSE_NEG = {
  id: TRAIN_FALSE_NEG,
  name: 'train false negatives',
  short_name: 'false neg',
  filter_fn: (patent) => (patent.user_class === POSITIVE) && (patent.score != null) && (patent.score < 0.5)
}

const FILTER_TRAIN_NEGATIVE_USER_CLASS_ONLY = {
  id: TRAIN_NEGATIVE_USER_CLASS_ONLY,
  name: 'train negatives',
  short_name: 'negatives',
  filter_fn: (patent) => patent.user_class === NEGATIVE
}

const FILTER_TRAIN_TRUE_NEG = {
  id: TRAIN_TRUE_NEG,
  name: 'train true negatives',
  short_name: 'true neg',
  filter_fn: (patent) => (patent.user_class === NEGATIVE) && (patent.score != null) && (patent.score < 0.5)
}

const FILTER_TRAIN_FALSE_POS = {
  id: TRAIN_FALSE_POS,
  name: 'train false positives',
  short_name: 'false pos',
  filter_fn: (patent) => (patent.user_class === NEGATIVE) && (patent.score != null) && (patent.score >= 0.5)
}


export const USER_TRAIN_FILTERS = [
  FILTER_TRAIN_USER_CLASS_ONLY,
  { ...FILTER_TRAIN_POSITIVE_USER_CLASS_ONLY, subfilters: [FILTER_TRAIN_TRUE_POS, FILTER_TRAIN_FALSE_NEG] },
  { ...FILTER_TRAIN_NEGATIVE_USER_CLASS_ONLY, subfilters: [FILTER_TRAIN_TRUE_NEG, FILTER_TRAIN_FALSE_POS] },
]

export const USER_OTHER_FILTERS = [
  { id: IGNORE_USER_CLASS_ONLY, name: 'ignores',    filter_fn: (patent) => patent.user_class === IGNORE },
  { id: NO_USER_CLASS_ONLY,     name: 'unlabelled', filter_fn: (patent) => patent.user_class == null }
]

const FILTER_TEST_USER_CLASS_ONLY = {
  id: TEST_USER_CLASS_ONLY,
  name: 'test (all)',
  short_name: 'all',
  filter_fn: (patent) => (patent.user_class === TEST_POSITIVE) || (patent.user_class === TEST_NEGATIVE)
}

const FILTER_TEST_POSITIVE_USER_CLASS_ONLY = {
  id: TEST_POSITIVE_USER_CLASS_ONLY,
  name: 'test positives',
  short_name: 'positives',
  filter_fn: (patent) => patent.user_class === TEST_POSITIVE
}

const FILTER_TEST_TRUE_POS = {
  id: TEST_TRUE_POS,
  name: 'test true positives',
  short_name: 'true pos',
  filter_fn: (patent) => (patent.user_class === TEST_POSITIVE) && (patent.score != null) && (patent.score >= 0.5)
}

const FILTER_TEST_FALSE_NEG =  {
  id: TEST_FALSE_NEG,
  name: 'test false negatives',
  short_name: 'false neg',
  filter_fn: (patent) => (patent.user_class === TEST_POSITIVE) && (patent.score != null) && (patent.score < 0.5)
}

const FILTER_TEST_NEGATIVE_USER_CLASS_ONLY = {
  id: TEST_NEGATIVE_USER_CLASS_ONLY,
  name: 'test negatives',
  short_name: 'negatives',
  filter_fn: (patent) => patent.user_class === TEST_NEGATIVE
}

const FILTER_TEST_TRUE_NEG =  {
  id: TEST_TRUE_NEG,
  name: 'test true negatives',
  short_name: 'true neg',
  filter_fn: (patent) => (patent.user_class === TEST_NEGATIVE) && (patent.score != null) && (patent.score < 0.5)
}

const FILTER_TEST_FALSE_POS=  {
  id: TEST_FALSE_POS,
  name: 'test false positives',
  short_name: 'false pos',
  filter_fn: (patent) => (patent.user_class === TEST_NEGATIVE) && (patent.score != null) && (patent.score >= 0.5)
}

export const USER_TEST_FILTERS = [
  FILTER_TEST_USER_CLASS_ONLY,
  { ...FILTER_TEST_POSITIVE_USER_CLASS_ONLY, subfilters: [FILTER_TEST_TRUE_POS, FILTER_TEST_FALSE_NEG] },
  { ...FILTER_TEST_NEGATIVE_USER_CLASS_ONLY, subfilters: [FILTER_TEST_TRUE_NEG, FILTER_TEST_FALSE_POS] },
]

export const SCORE_FILTERS = [
  { id: HIGH_SCORE_ONLY,       name: 'high scores (>= 0.8)',       filter_fn: (patent) => patent.score >= 0.8,              extent: [0.8, 1]   },
  { id: MEDIUM_SCORE_ONLY,     name: 'medium scores (0.6 to 0.8)', filter_fn: (patent) => patent.score >= 0.6 && patent.score < 0.8, extent: [0.6, 0.8] },
  { id: BORDERLINE_SCORE_ONLY, name: 'border scores (0.4 to 0.6)', filter_fn: (patent) => patent.score >= 0.4 && patent.score < 0.6, extent: [0.4, 0.6] },
  { id: LOW_SCORE_ONLY,        name: 'low scores (< 0.4)',         filter_fn: (patent) => patent.score < 0.4,               extent: [0, 0.4]   }
]

export const CONFLICT_FILTERS = [
  { id: LOW_SCORE_POSITIVES_ONLY,  name: 'all false negatives', filter_fn: (patent) => (_.contains([POSITIVE, TEST_POSITIVE], patent.user_class)) && (patent.score < 0.5)},
  { id: HIGH_SCORE_NEGATIVES_ONLY, name: 'all false positives', filter_fn: (patent) => (_.contains([NEGATIVE, TEST_NEGATIVE], patent.user_class)) && (patent.score >= 0.5)}
]

export const CUSTOM_FILTERS = [
  { id: CUSTOM_SCORE_RANGE,  name: 'score range', filter_fn: (patent, [min_score, max_score]) => (patent.score >= min_score) && (patent.score < max_score), no_count_when_inactive: true },
]

// This is an array of group objects with 'children' property, and an optional 'name' property (children is an array of filters)
export const DEFAULT_FILTER_GROUPS = [
  { children: NO_FILTERS, name: 'All results'},
  { children: USER_TRAIN_FILTERS, name: 'Train' },
  { children: USER_OTHER_FILTERS },
  { children: USER_TEST_FILTERS,  name: 'Test' },
  { children: CONFLICT_FILTERS },
  { children: CUSTOM_FILTERS,     name: 'Custom' }
]

export const SCORE_FILTER_GROUPS = [
  { children: NO_FILTERS },
  { children: SCORE_FILTERS },
  { children: CUSTOM_FILTERS },
]

// This is just a flat array containing all the filters
export const FILTERS = [
  ...NO_FILTERS,
  ...[FILTER_TRAIN_USER_CLASS_ONLY, FILTER_TRAIN_POSITIVE_USER_CLASS_ONLY, FILTER_TRAIN_TRUE_POS, FILTER_TRAIN_FALSE_NEG, FILTER_TRAIN_NEGATIVE_USER_CLASS_ONLY, FILTER_TRAIN_TRUE_NEG, FILTER_TRAIN_FALSE_POS], //all user train filters
  ...USER_OTHER_FILTERS,
  ...[FILTER_TEST_USER_CLASS_ONLY, FILTER_TEST_POSITIVE_USER_CLASS_ONLY, FILTER_TEST_TRUE_POS, FILTER_TEST_FALSE_NEG, FILTER_TEST_NEGATIVE_USER_CLASS_ONLY, FILTER_TEST_TRUE_NEG, FILTER_TEST_FALSE_POS], //all user test filters
  ...SCORE_FILTERS,
  ...CONFLICT_FILTERS,
  ...CUSTOM_FILTERS,
]

export const ID_TO_FILTER = get_as_map(FILTERS, 'id')
