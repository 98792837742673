import {EXPIRED_COLOR, GRANTED_COLOR, INACTIVE_COLOR, OTHER_COLOR, PENDING_COLOR, REJECTED_COLOR} from './colours.js'

export const FAMILY_STATUS_PENDING_ID = 'pending'
export const FAMILY_STATUS_GRANTED_ID = 'granted'
export const FAMILY_STATUS_EXPIRED_ID = 'expired'
export const FAMILY_STATUS_APP_INACTIVE_ID = 'application_inactive'
export const FAMILY_STATUS_APP_DEAD_ID = 'application_dead'
export const FAMILY_STATUS_OTHER_ID = 'other'

export const ALL_STATUS_IDS = [
  FAMILY_STATUS_PENDING_ID,
  FAMILY_STATUS_GRANTED_ID,
  FAMILY_STATUS_EXPIRED_ID,
  FAMILY_STATUS_APP_INACTIVE_ID,
  FAMILY_STATUS_APP_DEAD_ID,
  FAMILY_STATUS_OTHER_ID
]

// Maps from Status enum to human readable statusLabel.
// NOTE: Remember to keep this consistent with the labels in the java class PatentFamily.Status!
export const STATUS_TO_STATUS_LABEL = {
  [FAMILY_STATUS_OTHER_ID]       : 'Other',
  [FAMILY_STATUS_PENDING_ID]     : 'Pending',
  [FAMILY_STATUS_GRANTED_ID]     : 'Granted',
  [FAMILY_STATUS_EXPIRED_ID]     : 'Expired',
  [FAMILY_STATUS_APP_INACTIVE_ID]: 'Inactive',
  [FAMILY_STATUS_APP_DEAD_ID]    : 'Rejected'
}

// Maps from the above labels into tooltip (help) to show
// NOTE: Some of the tooltip help text is actually rendered server side: See TooltipConstants.java.
export const STATUS_LABEL_TO_TOOLTIP = {
  'pending':          'Patent family is actively being prosecuted, but is not yet granted',
  'granted':          'Patent family is granted and in force (i.e. renewal fees have been paid)',
  'expired':          'Patent families which were granted, but have now expired either due to age (the maximum life of a patent is 20 years, with few exceptions) or non-payment of renewal fees',
  'inactive':         'Patent family has not yet granted and has seen no activity on any of the regional or national applications for over 5 years',
  'rejected':         'Patent applications that have been successfully opposed, rejected by the relevant patent office, or are more than 20 years since their priority date, so could not proceed to grant',
  'total':            'All patent families for this priority year'
}

export const PAT_FAM_STATUS_COLOURS = {
  [FAMILY_STATUS_OTHER_ID]       : OTHER_COLOR,
  [FAMILY_STATUS_PENDING_ID]     : PENDING_COLOR,
  [FAMILY_STATUS_GRANTED_ID]     : GRANTED_COLOR,
  [FAMILY_STATUS_EXPIRED_ID]     : EXPIRED_COLOR,
  [FAMILY_STATUS_APP_INACTIVE_ID]: INACTIVE_COLOR,
  [FAMILY_STATUS_APP_DEAD_ID]    : REJECTED_COLOR
}