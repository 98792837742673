import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { get_help_article_url, on_click_from_help_link } from '../../utils/help_utils.js'

const HyperscriptDescription = ({hyperscript, className, report_input, params}) => {
  const {description: Description, help_article} = hyperscript || {}
  
  if (!Description) return null

  const help_link = help_article ? get_help_article_url(help_article) : null

  return (
    <div className={className}>
      <Description
        report_input={report_input}
        params={params}
      />
      {help_link &&
        <TextLink element='a' target='_blank' href={help_link} onClick={() => on_click_from_help_link(help_link)}>Help Centre article</TextLink>
      }
    </div>
  )
}

export default HyperscriptDescription