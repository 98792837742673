// Flag on each training_set_info object, if "true" then it is for export
export const EXPORT_TO_PATENTSIGHT = 'export_to_patentsight'

// Role in Keycloak
export const HAS_PATENTSIGHT_SYNC_CLASSIFIERS = 'has_patentsight_sync_classifiers'

// Attribute (top-level) in Keycloak
export const PATENTSIGHT_ATTRIBUTES = 'patentsight'

// Attributes of 'patentsight' in Keycloak
export const CLASSIFIER_GRACE = 'classifier_grace'
export const CLASSIFIER_LIMIT = 'classifier_limit'
export const CONTACT_EMAILS   = 'contact_emails'
export const POSITIVE_GRACE   = 'positive_grace'
export const POSITIVE_LIMIT   = 'positive_limit'
export const USER_ID          = 'user_id'

export const TYPE_STRING = 'string'
export const TYPE_INTEGER = 'integer'

export const PS_ATTRIBUTES = [
  { key: CLASSIFIER_LIMIT, type: TYPE_INTEGER,  example: 10,                              description: 'maxmium number of classifiers exported to PatentSight'},
  { key: CLASSIFIER_GRACE, type: TYPE_INTEGER,  example: 11,                              description: 'grace for maxmium number of classifiers exported to PatentSight'},
  { key: POSITIVE_LIMIT,   type: TYPE_INTEGER,  example: 200000,                          description: 'maxmium number of families exported to PatentSight'},
  { key: POSITIVE_GRACE,   type: TYPE_INTEGER,  example: 250000,                          description: 'grace for maxmium number of families exported to PatentSight'},
  { key: CONTACT_EMAILS,   type: TYPE_STRING,   example: 'moniek.stouten@lexisnexis.com', description: 'email addresses to notify (comma separated list)'},
  { key: USER_ID,          type: TYPE_STRING,   example: '194277412',                     description: 'patentsight user id'},
]

export const NO_ACCESS_MESSAGE = 'You need "edit" or "owner" permission to change this'