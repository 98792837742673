import { startsWith } from '../../../utils/utils.js'

export const TAG_PREFIX           = 'tag-'
export const CLIENT_PREFIX        = 'client-'
export const STATUS_PREFIX        = 'status-'

export const TAXONOMY_PATH_SEPARATOR = '/'

export function generic_add_tag_to_training_set_infos(training_set_infos, training_set_id, raw_tag, prefix) {
  return training_set_infos.map(training_set_info => {
    const { alias, tags } = training_set_info
    if (alias === training_set_id) {
      // UPDATE
      const prefixed_tag = prefix + raw_tag
      const new_tags = [...tags, prefixed_tag]
      return { ...training_set_info, tags: new_tags }
    }
    return training_set_info
  })
}

export function generic_remove_tag_from_training_set_infos(training_set_infos, training_set_id, raw_tag, prefix) {
  return training_set_infos.map(training_set_info => {
    const { alias, tags } = training_set_info
    if (alias === training_set_id) {
      // UPDATE
      const prefixed_tag = prefix + raw_tag
      const new_tags = tags.filter(t => t !== prefixed_tag)
      return { ...training_set_info, tags: new_tags }
    }
    return training_set_info
  })
}

export function replace_tag_in_training_set_infos(training_set_infos, training_set_id, old_tags, new_tag, prefix) {
  return training_set_infos.map(training_set_info => {
    const { alias, tags } = training_set_info
    if (alias === training_set_id) {
      // UPDATE
      const prefixed_old_tags = old_tags.map(old_tag => prefix + old_tag)
      const filtered_tags = tags.filter(t => prefixed_old_tags.indexOf(t) === -1)
      if (!new_tag) {
        return {...training_set_info, tags: filtered_tags}
      }
      const prefixed_new_tag = prefix + new_tag
      return {...training_set_info, tags: [...filtered_tags, prefixed_new_tag]}
    }
    return training_set_info
  })
}

function filter_and_clean_tags_by_prefix(prefixed_tags, prefix) {
  return prefixed_tags
    .filter(tag => startsWith(tag, prefix)) // filter
    .map(tag => tag.slice(prefix.length))   // remove prefix
}

export function get_tag_tags(prefixed_tags) {
  return filter_and_clean_tags_by_prefix(prefixed_tags, TAG_PREFIX)
}

export function get_client_tags(prefixed_tags) {
  return filter_and_clean_tags_by_prefix(prefixed_tags, CLIENT_PREFIX)
}

export function get_status_tags(prefixed_tags) {
  return filter_and_clean_tags_by_prefix(prefixed_tags, STATUS_PREFIX)
}

export function get_taxonomy_path_string(paths) {
  if (!paths || paths.length === 0) {
    return null
  }
  return paths.join(`${TAXONOMY_PATH_SEPARATOR}`)
}

export function get_tags_string(tags) {
  return tags.join(', ')
}
