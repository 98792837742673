import React from 'react'
import cn from 'classnames'
import _ from 'underscore'

import {
  ID_TO_SUGGESTIONS_STRATEGY,
  ID_TO_SUGGESTION_STRATEGY_GROUP,
  CPC_STRATEGIES
} from '../model/suggestions_strategies.js'
import Tooltip from '../../widgets/Tooltip.js'

import s from './SuggestionsStrategyName.module.scss'
import { InfoIcon } from '../../widgets/IconSet.js'

const SuggestionsStrategyName = ({ strategy_id, strategy_group_id, strategy_details, className }) => {
  const strategy = strategy_group_id ? ID_TO_SUGGESTION_STRATEGY_GROUP[strategy_group_id] : ID_TO_SUGGESTIONS_STRATEGY[strategy_id]
  const { name, description } = strategy
  return (

    <Tooltip
      toggler={(
        <span
          className={cn(s.label, className)}
        >
          <span className={s.name}>{name}</span>

          {strategy_details && <span className='ms-1'><InfoIcon /></span>}
        </span>
      )}
      delay={[100, 50]}
      placement='top'
      is_in_modal={true}
    >
      <div>{description}</div>
      {strategy_details &&
        <div className={cn('mt-2 pt-2', s.details_wrapper)}>
          {_.contains(CPC_STRATEGIES, strategy_id) &&
            <div className='d-flex'>
              <span className='me-1'>CPC set:</span>
              <span>
                {strategy_details.join('')}
              </span>
            </div>
          }

          {!(_.contains(CPC_STRATEGIES, strategy_id)) &&
            <pre>
              <code>
                {JSON.stringify(strategy_details, null, 4)}
              </code>
            </pre>
          }
        </div>
      }
  </Tooltip>
  )
}

export default SuggestionsStrategyName