import { EXCEL_FILE_FORMAT } from '../utils/download_utils.js'

export const ALERT_SETUP_ID = 'alert'
export const EXECUTIVE_SUMMARY_ID = 'executive_summary'
export const TREND_SPOTTER_ID = 'trend_spotter'
export const PUBLICATIONS_ID = 'publications'
export const BENCHMARK_METRICS_ID = 'benchmark_metrics'
export const BENCHMARKING_REPORT_ID = 'benchmarking_report'
export const FILING_STRATEGY_ID = 'filing_strategy'
export const TECHNOLOGY_RENAME_ID = 'technology_rename'
export const ORGANISATION_RENAME_ID = 'organisation_rename'
export const OVERLAP_ID = 'overlap'
export const LITIGATIONS_DEFENDANT_ID = 'litigations_defendant'
export const LITIGATIONS_PLAINTIFF_ID = 'litigations_plaintiff'
export const QUALITY_ID = 'quality'
export const FILING_TRENDS_ID = 'filing_trends'
export const GEO_WATERFALL_ID = 'geo_waterfall'
export const GEO_COMPARISON_ID = 'geo_comparison'
export const CITATIONS_ID = 'citations'
export const REASSIGNMENT_ID = 'reassignment'
export const ND_REPORT_ID = 'nd_report'
export const PVIX_ID = 'pvix'
export const SPIF_ID = 'spif_export'
export const GOOGLE_VALUATION_ID = 'google_valuation'
export const GEO_TIMELINE_ID = 'geo_timeline'
export const LOTNET_REPORT_ID = 'lotnet'
export const UTT_REPORT_ID = 'utt'
export const REPORT_ZIP_ID = 'report_zip_export'

export const FILING_STRATEGY_PARAM_NAME = 'ref_portfolio'
export const OVERLAP_PARAM_NAME = 'organisation'
export const STATUS_FILTERS_PARAM_NAME = 'status_filters'
export const VERSION_PARAM_NAME = 'version'

export const APRIORI_CUTOFF_PARAM_NAME = 'apriori_cutoff_years'

export const HYPERSCRIPT = {
  id: null,
  name: null,
  help_article: null,
  endpoint: null,
  filename_suffix: null,
  get_filename_suffix: null,
  file_format: EXCEL_FILE_FORMAT,
  permissions_endpoint: null,
  description: null,
  setup_params: null,
  setup_params_component: null,
  is_start_enabled: null
}

const LITIGATION_GROUP_ID      = 'litigation_group'
const QUALITY_GROUP_ID         = 'quality_group'
const BENCHMARKING_GROUP_ID    = 'benchmarking_group'
const FILING_ANALYSIS_GROUP_ID = 'filing analysis_group'
const EXPORT_GROUP_ID          = 'export_group'
export const UTILITY_GROUP_ID  = 'utility_group'
const MONITORING_GROUP_ID      = 'monitoring'

const LITIGATION_GROUP      = {id: LITIGATION_GROUP_ID, name: 'Litigation', hyperscripts: [LITIGATIONS_DEFENDANT_ID, LITIGATIONS_PLAINTIFF_ID]}
const QUALITY_GROUP         = {id: QUALITY_GROUP_ID, name: 'Quality', hyperscripts: [PVIX_ID, QUALITY_ID, CITATIONS_ID]}
const BENCHMARKING_GROUP    = {id: BENCHMARKING_GROUP_ID, name: 'Benchmarking', hyperscripts: [BENCHMARKING_REPORT_ID, EXECUTIVE_SUMMARY_ID, BENCHMARK_METRICS_ID]}
const FILING_ANALYSIS_GROUP = {id: FILING_ANALYSIS_GROUP_ID, name: 'Filing analysis', hyperscripts: [FILING_TRENDS_ID, GEO_WATERFALL_ID, GEO_COMPARISON_ID, FILING_STRATEGY_ID, TREND_SPOTTER_ID, GEO_TIMELINE_ID]}
const UTILITY_GROUP         = {id: UTILITY_GROUP_ID, name: 'Utility', hyperscripts: [REASSIGNMENT_ID, TECHNOLOGY_RENAME_ID, ORGANISATION_RENAME_ID, OVERLAP_ID]}
const EXPORT_GROUP          = {id: EXPORT_GROUP_ID, name: 'Export', hyperscripts: [PUBLICATIONS_ID, SPIF_ID, ND_REPORT_ID, GOOGLE_VALUATION_ID, REPORT_ZIP_ID]}
const MONITORING_GROUP      = {id: MONITORING_GROUP_ID, name: 'Monitoring', hyperscripts: [ALERT_SETUP_ID]}

export const HYPERSCRIPT_GROUPS = [
  LITIGATION_GROUP, QUALITY_GROUP, BENCHMARKING_GROUP, FILING_ANALYSIS_GROUP, UTILITY_GROUP, MONITORING_GROUP, EXPORT_GROUP
]

