import React from 'react'
import cn from 'classnames'

import s from './MonitorResponsePanel.module.scss'

const MonitorResponsePanel = ({children, className}) => {

  return (
    <pre className={cn(className, s.monitor_response_panel)}>
      {children}
    </pre>)
}

export default MonitorResponsePanel