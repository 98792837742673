import Color from 'color'
import _ from 'underscore'

const DEFAULT_HIGHLIGHT_COLOUR = Color('#FFFFA1').hex() // pale yellow
const DEFAULT_COLOUR_ANGLE_DELTA = 23
const DEFAULT_NUM_PHRASES = 14

/**
 * @param {} hex a hex string (i.e. '#aa0088')
 * @returns colour a hex string (i.e. '#aa0088')
 */
function get_next_colour(hex) {
  return new Color(hex).rotate(DEFAULT_COLOUR_ANGLE_DELTA).hex()
}

function get_nth_colour(hex, n) {
  if (n === 0) {
    // base case
    return hex
  }

  // recurse
  return get_nth_colour(get_next_colour(hex), n - 1)
}

function get_default_phrases_to_highlight(num_phrases) {
  return _.range(num_phrases).map(idx => ({ phrase: '', colour: get_nth_colour(DEFAULT_HIGHLIGHT_COLOUR, idx) }))
}

export function get_search_phrases_and_colours(phrases_to_highlight) {
  if (!phrases_to_highlight) {
    return { search_phrases: null, search_colours: null }
  }

  // Some of the phrases may contain multiple comma-separated phrases (i.e. "car, automobile, vehicle")
  // We expand these into multiple individual phrase objects
  const expanded_phrases_to_highlight = phrases_to_highlight.reduce((acc, phrase_obj) => {
    const { phrase, colour } = phrase_obj
    const split_phrases = phrase != null ? phrase.trim().split(',') : null

    const phrase_objs = split_phrases.map(split_phrase => ({ phrase: split_phrase.trim(), colour }))

    return [...acc, ...phrase_objs]
  }, [])

  const search_phrases = expanded_phrases_to_highlight.map(phrase_obj => phrase_obj.phrase)
  const search_colours = expanded_phrases_to_highlight.map(phrase_obj => phrase_obj.colour)

  return { search_phrases, search_colours }
}

export function get_default_phrases_to_highlight_all() {
  return get_default_phrases_to_highlight(DEFAULT_NUM_PHRASES)
}

export function add_new_phrase(phrases_to_highlight) {
  const num_phrases = phrases_to_highlight.length
  const new_phrase = ''
  const new_colour = num_phrases === 0 ? DEFAULT_HIGHLIGHT_COLOUR : get_next_colour(phrases_to_highlight[num_phrases - 1].colour)
  const new_phrase_obj = { phrase: new_phrase, colour: new_colour }

  return [...phrases_to_highlight, new_phrase_obj]
}

export function add_new_phrase_below(phrases_to_highlight, idx) {
  const selected_phrase_obj = phrases_to_highlight[idx]
  const new_phrase_obj = { ...selected_phrase_obj, phrase: '' }

  return [
    ...phrases_to_highlight.slice(0, idx + 1),
    new_phrase_obj,
    ...phrases_to_highlight.slice(idx + 1)
  ]
}

export function remove_phrase(phrases_to_highlight, phrase_idx) {
  return [...phrases_to_highlight.slice(0, phrase_idx), ...phrases_to_highlight.slice(phrase_idx + 1)]
}

export function update_phrase(phrases_to_highlight, phrase_idx, new_phrase) {
  const old_phrase_obj = phrases_to_highlight[phrase_idx]
  const new_phrase_obj = { ...old_phrase_obj, phrase: new_phrase }

  return [...phrases_to_highlight.slice(0, phrase_idx), new_phrase_obj, ...phrases_to_highlight.slice(phrase_idx + 1)]
}

export function update_phrase_colour(phrases_to_highlight, phrase_idx, new_colour) {
  const old_phrase_obj = phrases_to_highlight[phrase_idx]
  const new_phrase_obj = { ...old_phrase_obj, colour: new_colour }

  return [...phrases_to_highlight.slice(0, phrase_idx), new_phrase_obj, ...phrases_to_highlight.slice(phrase_idx + 1)]
}