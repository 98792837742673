import {
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_YEAR_ID,
  ACTIVE_SIZE_GROWTH_BY_PORTFOLIO_ID,
  ACTIVE_SIZE_GROWTH_BY_TECH_ID,
  APPLICATIONS_VS_GRANTS_BY_YEAR_ID,
  EXPIRED_BY_YEAR_BY_ORGANISATION_ID,
  EXPIRED_BY_YEAR_BY_TECH_ID,
  GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  GRANTED_FAMILIES_BY_TECH_BY_YEAR_ID,
  GRANTED_SIZE_GROWTH_BY_PORTFOLIO_ID,
  GRANTED_SIZE_GROWTH_BY_TECH_ID,
  PENDING_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  PENDING_FAMILIES_BY_TECH_BY_YEAR_ID,
  RECENTLY_GRANTED_BY_ORG_BY_TECH_ID,
  RECENTLY_PUBLISHED_BY_ORG_BY_TECH_ID
} from '../spec_ids.js'
import { PORTFOLIO_TRENDS_GROUP_ID } from './spec_group_ids.js'
import { KEY_EXPIRY_YEAR, KEY_ORGANISATION, KEY_TECHNOLOGY, KEY_YEAR } from './spec_keys.js'
import { TAG_ACTIVE, TAG_EXPIRED, TAG_GRANTED, TAG_ORG, TAG_PENDING, TAG_TECH, TAG_TIME } from './spec_tags.js'
import { DEFAULT_RECENT_LAST_FEW_YEARS_COUNT } from '../time_ranges.js'
import {
  DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
  DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS, DEFAULT_2D_NON_TIMESERIES_VIEW, DEFAULT_2D_NON_TIMESERIES_VIEWS,
  DEFAULT_2D_TIMESERIES_VIEW,
  DEFAULT_2D_TIMESERIES_VIEWS, DEFAULT_GROWTH_VIEW, DEFAULT_GROWTH_VIEWS
} from '../views.js'
import { COLUMN_CLUSTER_ID, COLUMN_STACK_ID, GROWTH_TREEMAP_ID, HEATMAP_ID, LINE_ID, TABLE_ID } from '../view_ids.js'

const HELP_ARTICLE = '28884914098579-Portfolio-Trends-charts-datasets'

export const PORTFOLIO_TRENDS_GROUP = {
  id: PORTFOLIO_TRENDS_GROUP_ID,
  name: 'Portfolio trends',
  short_name: 'Trends',
  help_article: HELP_ARTICLE,
  children: [
    {
      spec_id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
      name: 'active families',
      description: 'Number of patent families that are status granted or pending in each year. A pending family is counted each year it remains pending from priority year. A granted family is counted each year it remains alive from its grant year. Patent families are only counted for the years they are active.',
      help_article: `${HELP_ARTICLE}#h_cc92b53092`,
      by: [KEY_ORGANISATION, KEY_YEAR],
      tags: [TAG_ACTIVE, TAG_TIME, TAG_ORG],
      available_view_ids: DEFAULT_2D_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_TIMESERIES_VIEW,
    },
    {
      spec_id: ACTIVE_FAMILIES_BY_TECH_BY_YEAR_ID,
      name: 'active families',
      description: 'Number of patent families that are status granted or pending in each year. A pending family is counted each year it remains pending from priority year. A granted family is counted each year it remains alive from its grant year. Patent families are only counted for the years they are active.',
      help_article: `${HELP_ARTICLE}#h_9a0f066e77`,
      by: [KEY_TECHNOLOGY, KEY_YEAR],
      tags: [TAG_ACTIVE, TAG_TIME, TAG_TECH],
      available_view_ids: DEFAULT_2D_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_TIMESERIES_VIEW,
    },
    {
      spec_id: PENDING_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
      name: 'pending families',
      description: 'Number of patent families that are status pending in each year. A family is counted each year it remains pending from priority year. Patent families are only counted for the years they are active.',
      help_article: `${HELP_ARTICLE}#h_01HXEA0CH6NSZSB710E9P2VBDC`,
      by: [KEY_ORGANISATION, KEY_YEAR],
      tags: [TAG_PENDING, TAG_TIME, TAG_ORG],
      available_view_ids: DEFAULT_2D_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_TIMESERIES_VIEW,
    },
    {
      spec_id: PENDING_FAMILIES_BY_TECH_BY_YEAR_ID,
      name: 'pending families',
      description: 'Number of patent families that are status pending in each year. A family is counted each year it remains pending from priority year. Patent families are only counted for the years they are active.',
      help_article: `${HELP_ARTICLE}#h_60c9e6a50d`,
      by: [KEY_TECHNOLOGY, KEY_YEAR],
      tags: [TAG_PENDING, TAG_TIME, TAG_TECH],
      available_view_ids: DEFAULT_2D_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_TIMESERIES_VIEW,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
      name: 'granted families',
      description: 'Number of patent families that are status granted in each year. A family is counted each year it remains alive from its grant year. Patent families are only counted for the years they are active.',
      help_article: `${HELP_ARTICLE}#h_dc0a82391a`,
      by: [KEY_ORGANISATION, KEY_YEAR],
      tags: [TAG_GRANTED, TAG_TIME, TAG_ORG],
      available_view_ids: DEFAULT_2D_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_TIMESERIES_VIEW,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_TECH_BY_YEAR_ID,
      name: 'granted families',
      description: 'Number of patent families that are status granted in each year. A family is counted each year it remains alive from its grant year. Patent families are only counted for the years they are active.',
      help_article: `${HELP_ARTICLE}#h_01HXEA0YTVS8SYW07JYP50M6SB`,
      by: [KEY_TECHNOLOGY, KEY_YEAR],
      tags: [TAG_GRANTED, TAG_TIME, TAG_TECH],
      available_view_ids: DEFAULT_2D_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_TIMESERIES_VIEW,
    },
    {
      spec_id: EXPIRED_BY_YEAR_BY_ORGANISATION_ID,
      name: 'expired families',
      description: 'Number of patent families that have historically expired in each year. This includes expiry by age and non-payment.',
      help_article: `${HELP_ARTICLE}#h_01HXEA14EX0E155WX7680TW864`,
      by: [KEY_ORGANISATION, KEY_EXPIRY_YEAR],
      tags: [TAG_EXPIRED, TAG_TIME, TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: EXPIRED_BY_YEAR_BY_TECH_ID,
      name: 'expired families',
      description: 'Number of patent families that have historically expired in each year. This includes expiry by age and non-payment.',
      help_article: `${HELP_ARTICLE}#h_6f29998a7b`,
      by: [KEY_TECHNOLOGY, KEY_EXPIRY_YEAR],
      tags: [TAG_EXPIRED, TAG_TIME, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: ACTIVE_SIZE_GROWTH_BY_PORTFOLIO_ID,
      name: 'active size-growth',
      get_description: ({item}) => {
        const {view_id} = item || {}

        if (view_id === GROWTH_TREEMAP_ID) {
          return 'Rectangle colour illustrating change in share of active patent families (pending and granted), over the selected time period relative to all organisations in your report. Green reflects growth, red reflects shrinkage. Rectangle area reflects number of active families overall for most recent year relative to all organisations in your report. Hover over each rectangle to review the detail.'
        }
        return 'Comparing the current size of the portfolio in each organisation with the change size over the time range.'
      },
      help_article: `${HELP_ARTICLE}#h_93563331a5`,
      by: [KEY_ORGANISATION],
      tags: [TAG_ACTIVE, TAG_ORG],
      available_view_ids: DEFAULT_GROWTH_VIEWS,
      default_view_id: DEFAULT_GROWTH_VIEW,
    },
    {
      spec_id: ACTIVE_SIZE_GROWTH_BY_TECH_ID,
      name: 'active size-growth',
      get_description: ({item}) => {
        const {view_id} = item || {}

        if (view_id === GROWTH_TREEMAP_ID) {
          return 'Rectangle colour illustrating change in share of active patent families (pending and granted), over the selected time period relative to all technologies in your report. Green reflects growth, red reflects shrinkage. Rectangle area reflects number of active families overall for most recent year relative to all technologies in your report. Hover over each rectangle to review the detail.'
        }
        return 'Comparing the current size of the portfolio in each technology with the change size over the time range.'
      },
      help_article: `${HELP_ARTICLE}#h_01HXEA31S6XXJMY3S08CJC449J`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_ACTIVE, TAG_TECH],
      available_view_ids: DEFAULT_GROWTH_VIEWS,
      default_view_id: DEFAULT_GROWTH_VIEW,
    },
    {
      spec_id: GRANTED_SIZE_GROWTH_BY_PORTFOLIO_ID,
      name: 'granted size-growth',
      get_description: ({item}) => {
        const {view_id} = item || {}

        if (view_id === GROWTH_TREEMAP_ID) {
          return 'Rectangle colour illustrating change in share of granted patent families, over the selected time period relative to all organisations in your report. Green reflects growth, red reflects shrinkage. Rectangle area reflects number of granted families overall for most recent year relative to all organisations in your report. Hover over each rectangle to review the detail.'
        }
        return 'Comparing the current number of granted families in each organisation with the change size over the time range.'
      },
      help_article: `${HELP_ARTICLE}#h_e92fbd77be`,
      by: [KEY_ORGANISATION],
      tags: [TAG_GRANTED, TAG_ORG],
      available_view_ids: DEFAULT_GROWTH_VIEWS,
      default_view_id: DEFAULT_GROWTH_VIEW,
    },
    {
      spec_id: GRANTED_SIZE_GROWTH_BY_TECH_ID,
      name: 'granted size-growth',
      get_description: ({item}) => {
        const {view_id} = item || {}

        if (view_id === GROWTH_TREEMAP_ID) {
          return 'Rectangle colour illustrating change in share of granted patent families, over the selected time period relative to all technologies in your report. Green reflects growth, red reflects shrinkage. Rectangle area reflects number of granted families overall for most recent year relative to all technologies in your report. Hover over each rectangle to review the detail.'
        }
        return 'Comparing the current number of granted families in each technology with the change size over the time range.'
      },
      help_article: `${HELP_ARTICLE}#h_01HXEA3FZ5RVY35P2X0XTCJ6BW`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_GRANTED, TAG_TIME],
      available_view_ids: DEFAULT_GROWTH_VIEWS,
      default_view_id: DEFAULT_GROWTH_VIEW,
    },
    {
      spec_id: RECENTLY_PUBLISHED_BY_ORG_BY_TECH_ID,
      name: `families published in last ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} years`,
      short_name: `published over ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} years`,
      description: `Number of patent families published in the last ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} years (current and prior ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT - 1} calendar years) by organisation and technology. Counted from first publication date.`,
      help_article: `${HELP_ARTICLE}#h_f691904da2`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ACTIVE, TAG_TIME, TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW
    },
    {
      spec_id: RECENTLY_GRANTED_BY_ORG_BY_TECH_ID,
      name: `families granted in last ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} years`,
      short_name: `granted over ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} years`,
      description: `Number of patent families granted in the last ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} years (current and prior ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT - 1} calendar years) by organisation and technology. Counted from first grant date.`,
      help_article: `${HELP_ARTICLE}#h_01HYZ6WM4FWC8RVN2903C8DDAW`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_GRANTED, TAG_TIME, TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW
    },
    {
      spec_id: APPLICATIONS_VS_GRANTS_BY_YEAR_ID,
      name: 'granted versus pending families',
      short_name: 'granted vs. pending',
      description: 'Split between the number of granted and pending patent families per year. A pending family is counted each year it remains pending from priority year. A granted family is counted each year it remains alive from its grant year. Patent families are only counted for the years they are active.',
      help_article: `${HELP_ARTICLE}#h_01HXEA2BSYZEH3KRE9YHK4DSR5`,
      by: [KEY_YEAR],
      tags: [TAG_ACTIVE, TAG_PENDING, TAG_GRANTED, TAG_TIME],
      available_view_ids: [LINE_ID, COLUMN_STACK_ID, COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    }
  ]
}