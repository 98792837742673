export const TS_POS_IDS ='ts_pos_ids'
export const TS_NEG_IDS ='ts_neg_ids'

export const NUM_POS          = 'num_pos'
export const NUM_NEG          = 'num_neg'
export const NUM_IGNORE       = 'num_ignore'
export const NUM_TEST_POS     = 'num_test_pos'
export const NUM_TEST_NEG     = 'num_test_neg'
export const DELTA_POS_ADD    = 'delta_pos_add'
export const DELTA_POS_REMOVE = 'delta_pos_remove'
export const DELTA_NEG_ADD    = 'delta_neg_add'
export const DELTA_NEG_REMOVE = 'delta_neg_remove'
export const TOTAL_NUM_POS    = 'total_num_pos'
export const TOTAL_NUM_NEG    = 'total_num_neg'

export const MIN_NUM_POS = 5
export const MIN_NUM_NEG = 5

export const EXISTS_PREVIOUS_BUILD = 'exists_previous_build'
export const IS_STALE = 'is_stale'
export const IS_ENOUGH_EXAMPLES_TO_BUILD = 'is_enough_examples_to_build'