import axios from 'axios'

import { REPORT_SHARED_BASE_URL } from '../constants/urls.js'
import { JSON_POST_HEADER, add_source_err_to_target_err } from './axios_utils.js'

export function share_report(external_report_id, user_state, recipient_email, keycloak_user_group, message) {
  const data = {
    external_report_id,
    user_state,
    recipient_email,
    group: keycloak_user_group,
    message
  }
  return axios.post(REPORT_SHARED_BASE_URL, data, {headers: JSON_POST_HEADER})
    .then(response => response.status)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to share report: ')
    })
}