import { get_as_map } from '../utils/utils.js'
import { EN_CODE, ENGLISH_LANG, GERMAN_LANG, JAPANESE_LANG, KOREAN_LANG } from '../constants/languages.js'
import { DE, JP, KR } from '../constants/countries.js'

export const LANGUAGE_OPTIONS = [
  ENGLISH_LANG,
  { ...GERMAN_LANG,   country: DE },
  { ...JAPANESE_LANG, country: JP },
  { ...KOREAN_LANG,   country: KR }
]
export const LANGUAGE_CODE_TO_OPTION = get_as_map(LANGUAGE_OPTIONS, 'id')
export const DEFAULT_LANGUAGE = EN_CODE