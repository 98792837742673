import React from 'react'

import { PaneHeader } from '../widgets/PaneHeader.js'

import s from './TableSummary.module.scss'

export const TableSummary =   ({title, children, header_className}) => {
  title = title || 'Summary'

  return (
    <div className={s.tabular}>

      <PaneHeader
        text={title}
        className={header_className}
      />

      {children}
    </div>
  )
}