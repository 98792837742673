import _ from 'underscore'

import { STATUS_TAG_IDS } from '../model/spec_groups/spec_tags.js'
import { is_array_subset } from './utils.js'

export function update_selected_spec_tags(tags, tag_id) {
  if (is_status_tag(tag_id)) {
    return update_tags_with_status_tag(tags, tag_id)
  }

  return tags.indexOf(tag_id) === -1 ? [...tags, tag_id] : tags.filter(tag => (tag !== tag_id))
}

export function update_tags_with_status_tag(tags, tag_id) {
  return tags.indexOf(tag_id) === -1 ? [...remove_all_status_tags(tags), tag_id] : tags.filter(tag => (tag !== tag_id))
}

function remove_all_status_tags(tags) {
  return tags.filter(tag => !is_status_tag(tag))
}

function is_status_tag(tag_id) {
  return STATUS_TAG_IDS.indexOf(tag_id) > -1
}

export function get_datasets_filtered_by_tags(items, tags) {
  if (!items || (items.length === 0)) return []
  if (!tags || tags.length === 0) return items || []

  const items_filtered_by_status_tag = get_datasets_filtered_by_status_tag(items, tags)

  const non_status_tags = remove_all_status_tags(tags)

  if (non_status_tags.length === 0) return items_filtered_by_status_tag

  return items_filtered_by_status_tag.filter(item => {
    const {tags} = item
    return is_array_subset(tags, non_status_tags)
  })
}

function get_datasets_filtered_by_status_tag(items, tags) {
  const status_tag = tags.filter(tag => is_status_tag(tag))[0]

  if (!status_tag) return items

  return items.filter(item => {
    const {tags=[]} = item

    return (tags.indexOf(status_tag) > -1) || (_.intersection(tags, STATUS_TAG_IDS).length === 0)
  })
}