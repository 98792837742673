import React from 'react'

import { ExternalTextLink } from './ExternalTextLink.js'
import { SUPPORT_EMAIL_ADDRESS } from '../../constants/constants.js'

const SupportInfoDisplay = ({message, className}) => {
  return (
    <div className={className}>
      {message && <div>{message}</div>}
      <div>For assistance, please contact <ExternalTextLink url={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>{SUPPORT_EMAIL_ADDRESS}</ExternalTextLink></div>
    </div>
  )
}

export default SupportInfoDisplay