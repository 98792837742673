import { is_array_non_empty_non_null } from './utils.js'
import { prepare_date_filter_for_query } from './time_range_utils.js'
import { NO_FILTER_ID } from '../model/time_range_filter_fields.js'

const JURISDICTION_COLUMN = 'D.jurisdiction'
const COUNTRY_CODE_COLUMN = 'PFC.country_code'

export function add_custom_selections_to_dispute_table_query(query, selections) {
  const {selected_portfolio_ids,selected_tech_ids, selected_geo_ids, selected_timerange} = selections
  return {
    ...query,
    ...is_array_non_empty_non_null(selected_portfolio_ids) ? {portfolio_ids: selected_portfolio_ids} : {},
    ...is_array_non_empty_non_null(selected_tech_ids) ? {technology_ids: selected_tech_ids} : {},
    ...(selected_timerange !=null) ? {date_filter: prepare_date_filter_for_query(selected_timerange)} : {},
    ...is_array_non_empty_non_null(selected_geo_ids) ? {jurisdiction: selected_geo_ids} : {}
  };
}

export function add_custom_selections_to_patfam_based_litigation_query(query, selections) {
  return add_selections_with_custom_territory_column(query, selections, JURISDICTION_COLUMN )
}

export function add_custom_selections_to_cost_query(query, selections) {
  return add_selections_with_custom_territory_column(query, selections, COUNTRY_CODE_COLUMN)
}

export function add_custom_selections_to_role_based_litigation_query(query, selections) {
  // technology selections can not currently be applied to role-based litigation data, so ignore these
  const {selected_portfolio_ids, selected_geo_ids} = selections

  const query_with_territory_selections = add_territory_selection_constraint_by_custom_column(query, selected_geo_ids, 'D.jurisdiction')
  const query_custom_selections_constraints = add_portfolio_selection_constraint_by_custom_column(query_with_territory_selections, selected_portfolio_ids, 'PTA.portfolio_id')

  return {...query, ...query_custom_selections_constraints}
}

function add_selections_with_custom_territory_column(query, selections, custom_territory_column) {
  const {selected_portfolio_ids, selected_tech_ids, selected_geo_ids, selected_timerange} = selections

  const query_with_territory_selections = add_territory_selection_constraint_by_custom_column(query, selected_geo_ids, custom_territory_column)

  const should_include_date_filter = selected_timerange && (selected_timerange.field_id !== NO_FILTER_ID)

  return {
    ...query_with_territory_selections,
    ...(is_array_non_empty_non_null(selected_tech_ids)      && { technology: selected_tech_ids }),
    ...(is_array_non_empty_non_null(selected_portfolio_ids) && { portfolio: selected_portfolio_ids }),
    ...(should_include_date_filter                          && { date_filter: prepare_date_filter_for_query(selected_timerange) })
  }
}

function add_territory_selection_constraint_by_custom_column(query, selected_geo_ids, custom_territory_column) {
  if (is_array_non_empty_non_null(selected_geo_ids)) {
    // for litigation data with territory selections we need to explicitly constrain using the dispute jurisdiction column
    const constraint = [...(query.constraint || []), `IN ${custom_territory_column} ('${selected_geo_ids.join("','")}')`]
    return {...query, constraint}
  }
  return query
}

function add_portfolio_selection_constraint_by_custom_column(query, selected_portfolio_ids, portfolio_column) {
  if (is_array_non_empty_non_null(selected_portfolio_ids)) {
    const constraints = [...(query.constraint || []), `IN ${portfolio_column} (${selected_portfolio_ids.join(',')})`]
    return {...query, constraint: constraints}
  }
  return query
}
