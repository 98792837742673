export const ACTIVE_FAMILIES_BY_GRANTYEAR_BY_COUNTRY_ID = '3iobB'
export const ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID = 'Y5Jcg'
export const ACTIVE_FAMILIES_BY_PORTFOLIO_ID = 's7twA'
export const ACTIVE_FAMILIES_BY_REGION_ID = 'SfOLJ'
export const ACTIVE_FAMILIES_BY_PORTFOLIO_BY_REGION_ID = 'tKFXI'
export const ACTIVE_FAMILIES_BY_TECH_BY_REGION_ID = 'SipJv'
export const ACTIVE_FAMILIES_BY_COUNTRY_ID = 'YVK84'
export const ACTIVE_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID = 'EWrXW'
export const ACTIVE_FAMILIES_BY_TECH_BY_COUNTRY_ID = 'oZEyN'
export const ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID = 'P11hY'
export const ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID = 'sgWBW'
export const ACTIVE_FAMILIES_BY_PORTFOLIO_BY_STATUS_ID = 'qHLVI'
export const ACTIVE_FAMILIES_BY_TECH_BY_YEAR_ID = '3XrBF'
export const ACTIVE_FAMILIES_BY_TECH_ID = 'cIjAE'
export const AGE_AT_EXPIRY_BY_PORTFOLIO_ID = 'oSwRE'
export const AGE_AT_EXPIRY_BY_TECH_ID = 'Vt8Wh'
export const PERCENTAGE_EXPIRED_BY_AGE_BY_PORTFOLIO_ID = 'plIYO'
export const ALL_FAMILIES_ID = 'L6fHO'
export const APPLICATIONS_VS_GRANTS_BY_YEAR_ID = 'mGYCh'
export const AVG_AGE_AT_EXPIRY_BY_ORG_BY_TECH_ID = 'V3wCH'
export const AVG_AGE_OF_GRANTED_FAMILY_BY_ORG_BY_TECH_ID = 'hICb3'
export const AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID = 'RQyq9'
export const AVG_PRIORITY_YEAR_BY_ORG_ID = 'tRPxg'
export const AVERAGE_COST_BY_PORTFOLIO_ID = 'nijyn'
export const AVERAGE_COST_BY_TECH_ID = 'EWUJz'
export const AVERAGE_COST_BY_ORG_AND_TECH_ID = 'GS0PP'
export const AVERAGE_COST_BY_AGE_ID = '0ZX6D'
export const APPLICATIONS_BY_COUNTRY_ID = 'KVSoI'
export const APPLICATIONS_BY_PORTFOLIO_BY_COUNTRY_ID = 'b7WD1'
export const APPLICATIONS_BY_TECH_BY_COUNTRY_ID = 'qrmcP'
export const APPLICATIONS_VS_GRANTS_BY_COUNTRY_ID = '2NTOO'
export const BACKWARD_CITATIONS_BY_ORG_BY_TECH_ID = 'RCYiZ'
export const COST_BY_COUNTRY_BY_YEAR_ID = 'hX6LO'
export const COST_BY_PORTFOLIO_BY_TECH_ID = 'XR08r'
export const COST_BY_PORTFOLIO_BY_COUNTRY_ID = 'ArI6Y'
export const COST_BY_PORTFOLIO_BY_YEAR_ID = 'kLhM2'
export const COST_BY_COUNTRY_ID = '5iEjr'
export const COST_BY_REGION_ID = '2SoG1'
export const COST_BY_TECH_BY_YEAR_ID = 'i6hiE'
export const ENTRANCE_YEAR_BY_ORG_BY_TECH_ID = 'u6QKm'
export const EXPIRED_BY_YEAR_BY_ORGANISATION_ID = 'PW4BN'
export const EXPIRED_BY_YEAR_BY_TECH_ID = 'hwhPk'
export const EXPIRING_BY_YEAR_BY_ORGANISATION_ID = '5hjE8'
export const EXPIRING_BY_YEAR_BY_TECH_ID = 'hNdOv'
export const FAMILIES_BY_CPC_CODE = 'tQlhP'
export const FAMILIES_BY_PORTFOLIO_BY_CPC_CODE = 'UBbuu'
export const FAMILIES_BY_TECH_BY_CPC_CODE = '7ksPl'
export const FAMILIES_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID = 'XW2qD'
export const FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID = '4LabK'
export const FAMILIES_BY_PORTFOLIO_BY_GRANTED_YEAR_ID = 'xFkLN'
export const FAMILIES_BY_TECH_BY_PRIORITY_YEAR_ID = 'gki2D'
export const FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID = '2pKVs'
export const FAMILIES_BY_TECH_BY_GRANTED_YEAR_ID = 'jrsaY'
export const FORWARD_CITATIONS_BY_ORG_BY_TECH_ID = 'DuUvf'
export const FUTURE_COST_BY_COUNTRY_ID = 'Anp5U'
export const GRANTED_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID = 'Y9O0Z'
export const GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID = 'OY4yA'
export const GRANTED_FAMILIES_BY_PORTFOLIO_ID = '38IGC'
export const GRANTED_FAMILIES_BY_PRIORITY_YEAR_ID = 'Z0eNJ'
export const GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID = 'RP2Id'
export const GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID = 'nZ8E7'
export const GRANTED_FAMILIES_BY_GRANT_YEAR_BY_PORTFOLIO_ID = 'ebd4h'
export const GRANTED_FAMILIES_BY_GRANT_YEAR_BY_TECHNOLOGY_ID = 'y9KUL'
export const GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_COUNTRY_ID = '5A7CI'
export const GRANTED_FAMILIES_BY_PUBLICATION_YEAR_BY_COUNTRY_ID = 'O48M2'
export const GRANTED_FAMILIES_BY_TECH_BY_COUNTRY_ID = 'xPmSF'
export const GRANTED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID = '6meEb'
export const GRANTED_FAMILIES_BY_TECH_BY_YEAR_ID = 'XhplI'
export const GRANTED_FAMILIES_BY_TECH_ID = 'PJFsz'
export const GRANTS_BY_COUNTRY_ID = 'tNVPE'
export const GRANTS_BY_GRANTYEAR_BY_COUNTRY_ID = 'oh8jt'
export const GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID = 'RCwZR'
export const GRANTS_BY_TECHNOLOGY_BY_COUNTRY_ID = 'TG2BR'
export const GRANTS_BY_PRIORITY_YEAR_ID = 'tx8j1'
export const GRANTS_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID = 'PPfVH'
export const GRANTS_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID = 'FzF57'
export const LITIGATED_FAMILIES_BY_PORTFOLIO_ID = 'MAcmw'
export const LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID = 'GYQdR'
export const LITIGATED_FAMILIES_BY_TECH_ID = '7Nhdq'
export const LITIGATION_BALANCE_ID = 'DcKbM'
export const LITIGATIONS_BY_DEFENDANT_BY_START_YEAR_ID = 'YsM8b'
export const LITIGATIONS_BY_DEFENDANT_BY_JURISDICTION = '7UlNg'
export const LITIGATIONS_BY_PLAINTIFF_BY_START_YEAR_ID = 'YFgk8'
export const LITIGATIONS_BY_PLAINTIFF_BY_JURISDICTION = 'pzHk8'
export const LITIGATIONS_DEFENSIVE_DISPUTES = 'WxqtW'
export const LITIGATIONS_OFFENSIVE_DISPUTES = 'aJ5fe'
export const LITIGATIONS_CROSS_DISPUTES = 'gB5Jf'
export const LITIGATIONS_ALL_PATFAMS_DISPUTES = 'M6cKB'
export const PATFAMS_WITH_GRANTS_BY_COUNTRY_ID = 'zZqLt'
export const PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID = 'AWdmd'
export const PATFAMS_WITH_GRANTS_BY_TECH_BY_COUNTRY_ID = 'SQsJ3'
export const PATFAMS_WITH_GRANTS_BY_REGION_ID = 'oWha1'
export const PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_REGION_ID = 'SwcHu'
export const PATFAMS_WITH_GRANTS_BY_TECH_BY_REGION_ID = 'M4B3z'
export const PENDING_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID = 'bfwvp'
export const PENDING_FAMILIES_BY_PORTFOLIO_ID = '57oYc'
export const PENDING_FAMILIES_BY_PRIORITY_YEAR_ID = 'FHDQB'
export const PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID = 'brTk8'
export const PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID = 'zOyFV'
export const PENDING_FAMILIES_BY_TECH_BY_PORTFOLIO_ID = 'vO7V2'
export const PENDING_FAMILIES_BY_TECH_BY_YEAR_ID = 'glNKL'
export const PENDING_FAMILIES_BY_TECH_ID = 'yOiXR'
export const PUBLICATIONS_BY_PUB_YEAR_BY_COUNTRY_ID = 'MhAc5'
export const PUBLICATIONS_BY_PORTFOLIO_BY_BY_CPC_CODE_ID = 'EO7xs'
export const RECENTLY_PUBLISHED_BY_ORG_BY_TECH_ID = 'GydFC'
export const RECENTLY_GRANTED_BY_ORG_BY_TECH_ID = 'LtNRM'
export const ACTIVE_SIZE_GROWTH_BY_PORTFOLIO_ID = 'KxiMT'
export const ACTIVE_SIZE_GROWTH_BY_TECH_ID = 'DEWCJ'
export const GRANTED_SIZE_GROWTH_BY_PORTFOLIO_ID = '1BjkQ'
export const GRANTED_SIZE_GROWTH_BY_TECH_ID = 'VVvut'
export const FAMILIES_BY_STATUS = 'dMWex'
export const NUM_FAMILIES_BY_SCORE_BAND = 'aFNSv'
export const NUM_FAMILIES_BY_SCORE_BAND_BY_TECHNOLOGY = 'CIoVw'
export const NUM_FAMILIES_BY_SCORE_BAND_BY_ORGANISATION = 'JkoAR'
export const APPLICATIONS_AND_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID = '78DOy'
export const APPLICATIONS_AND_GRANTS_BY_TECH_BY_COUNTRY_ID = 'x237Q'
export const APPLICATIONS_AND_GRANTS_BY_TECH_BY_PORTFOLIO_ID = 'v6i3a'
export const TOTAL_NUM_PUBLICATIONS_BY_PORTFOLIO_ID = 'y6zEc'
export const TOTAL_NUM_PUBLICATIONS_BY_TECHNOLOGY_ID = 'd2ul8'
export const GRANTS_BY_TECH_BY_PORTFOLIO_ID = 'RcKDo'
export const COST_BY_TYPE_BY_YEAR_ID = '0fNvW'
export const PVIX_TOTAL_BY_PORTFOLIO_ID = 'sFb2b'
export const PVIX_TOTAL_BY_TECH_ID = 'CXIE5'
export const PVIX_TOTAL_BY_TECH_BY_PORTFOLIO_ID = 'BmpdY'
export const PVIX_MEAN_BY_PORTFOLIO_ID = 'YaMNm'
export const PVIX_MEAN_BY_TECH_ID = '5Nc8g'
export const PVIX_MEAN_BY_TECH_BY_PORTFOLIO_ID = 'UAOde'
export const PVIX_MEAN_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID = '1MCmj'
export const PVIX_MEAN_BY_TECH_BY_PRIORITY_YEAR_ID = 'niMcq'
export const PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID = 'cDCBY'
export const PVIX_MEAN_BY_TECHNOLOGY_BY_SIZE_ID = 'M7f6H'
export const NUM_FAMILIES_BY_PVIX_BAND_BY_PORTFOLIO_ID = 'QAn8X'
export const NUM_FAMILIES_BY_PVIX_BAND_BY_TECHNOLOGY_ID = 'eLhBP'
export const NUM_FAMILIES_BY_PVIX_BAND_ID = 'LZmgJ'
export const NUM_FAMILIES_BY_COST_BAND_ID = 'DQKME'
export const NUM_FAMILIES_BY_PRIO_YEAR_ID = 'USxw9'
export const GRANTED_COUNTRIES_PER_FAMILY_BY_ORG = 'howRs'
export const CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID = 'I2ClC'
export const CAGR_ACTIVE_FAMILIES_BY_TECH_ID = 'J5d7g'
export const PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID = 'WwRrh'
export const EXPIRED_FAMILIES_BY_PORTFOLIO_ID = 'bZcJn'
export const EXPIRED_FAMILIES_BY_TECH_ID = 'guxOc'
export const EXPIRED_FAMILIES_BY_PORTFOLIO_BY_TECH_ID ='cY3PJ'
export const ALL_FAMILIES_BY_PORTFOLIO_ID = 'QlZTP'
export const ALL_FAMILIES_BY_TECH_ID = 'O4iJQ'
export const ALL_FAMILIES_BY_TECH_BY_PORTFOLIO_ID = 'ikEYm'
export const FAMILY_FILINGS_BY_PORTFOLIO_WITHIN_TIMEFRAME_ID = 'hosFh'
export const PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PRIORITY_YEAR_ID = 'TNO3t'
export const PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PORTFOLIO_ID = 'rol8p'
export const PATFAMS_BY_FIRST_FILING_COUNTRY_BY_TECH_ID = 'HCSCE'
export const FAMILIES_BY_PRIMARY_CPC_CODE = 'OT0Z0'
export const FAMILIES_BY_PORTFOLIO_BY_PRIMARY_CPC_CODE = 'a4EIV'
export const FAMILIES_BY_TECH_BY_PRIMARY_CPC_CODE = 'D95Cx'
export const FAMILIES_BY_FULL_PRIMARY_CPC_CODE = 'oR6w0'
export const OLD_ID_TO_NEW_ID = {
  'ACTIVE_FAMILIES_BY_GRANTYEAR_BY_COUNTRY_ID': ACTIVE_FAMILIES_BY_GRANTYEAR_BY_COUNTRY_ID,
  'ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID': ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  'ACTIVE_FAMILIES_BY_PORTFOLIO_ID': ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  'ACTIVE_FAMILIES_BY_REGION_ID': ACTIVE_FAMILIES_BY_REGION_ID,
  'ACTIVE_FAMILIES_BY_PORTFOLIO_BY_REGION_ID': ACTIVE_FAMILIES_BY_PORTFOLIO_BY_REGION_ID,
  'ACTIVE_FAMILIES_BY_TECH_BY_REGION_ID': ACTIVE_FAMILIES_BY_TECH_BY_REGION_ID,
  'ACTIVE_FAMILIES_BY_COUNTRY_ID': ACTIVE_FAMILIES_BY_COUNTRY_ID,
  'ACTIVE_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID': ACTIVE_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID,
  'ACTIVE_FAMILIES_BY_TECH_BY_COUNTRY_ID': ACTIVE_FAMILIES_BY_TECH_BY_COUNTRY_ID,
  'ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID': ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  'ACTIVE_FAMILIES_BY_TECH_BY_YEAR_ID': ACTIVE_FAMILIES_BY_TECH_BY_YEAR_ID,
  'ACTIVE_FAMILIES_BY_TECH_ID': ACTIVE_FAMILIES_BY_TECH_ID,
  'AGE_AT_EXPIRY_BY_PORTFOLIO_ID': AGE_AT_EXPIRY_BY_PORTFOLIO_ID,
  'AGE_AT_EXPIRY_BY_TECH_ID': AGE_AT_EXPIRY_BY_TECH_ID,
  'ALL_FAMILIES_ID': ALL_FAMILIES_ID,
  'APPLICATIONS_VS_GRANTS_BY_YEAR_ID': APPLICATIONS_VS_GRANTS_BY_YEAR_ID,
  'AVG_AGE_AT_EXPIRY_BY_ORG_BY_TECH_ID': AVG_AGE_AT_EXPIRY_BY_ORG_BY_TECH_ID,
  'AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID': AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID,
  'AVERAGE_COST_BY_PORTFOLIO_ID': AVERAGE_COST_BY_PORTFOLIO_ID,
  'AVERAGE_COST_BY_TECH_ID': AVERAGE_COST_BY_TECH_ID,
  'AVERAGE_COST_BY_ORG_AND_TECH_ID': AVERAGE_COST_BY_ORG_AND_TECH_ID,
  'AVERAGE_COST_BY_AGE_ID': AVERAGE_COST_BY_AGE_ID,
  'APPLICATIONS_BY_COUNTRY_ID': APPLICATIONS_BY_COUNTRY_ID,
  'APPLICATIONS_BY_PORTFOLIO_BY_COUNTRY_ID': APPLICATIONS_BY_PORTFOLIO_BY_COUNTRY_ID,
  'APPLICATIONS_BY_TECH_BY_COUNTRY_ID': APPLICATIONS_BY_TECH_BY_COUNTRY_ID,
  'APPLICATIONS_VS_GRANTS_BY_COUNTRY_ID': APPLICATIONS_VS_GRANTS_BY_COUNTRY_ID,
  'BACKWARD_CITATIONS_BY_ORG_BY_TECH_ID': BACKWARD_CITATIONS_BY_ORG_BY_TECH_ID,
  'COST_BY_COUNTRY_BY_YEAR_ID': COST_BY_COUNTRY_BY_YEAR_ID,
  'COST_BY_PORTFOLIO_BY_TECH_ID': COST_BY_PORTFOLIO_BY_TECH_ID,
  'COST_BY_PORTFOLIO_BY_COUNTRY_ID': COST_BY_PORTFOLIO_BY_COUNTRY_ID,
  'COST_BY_PORTFOLIO_BY_YEAR_ID': COST_BY_PORTFOLIO_BY_YEAR_ID,
  'COST_BY_COUNTRY_ID': COST_BY_COUNTRY_ID,
  'COST_BY_REGION_ID': COST_BY_REGION_ID,
  'COST_BY_TECH_BY_YEAR_ID': COST_BY_TECH_BY_YEAR_ID,
  'EXPIRED_BY_YEAR_BY_ORGANISATION_ID': EXPIRED_BY_YEAR_BY_ORGANISATION_ID,
  'EXPIRED_BY_YEAR_BY_TECH_ID': EXPIRED_BY_YEAR_BY_TECH_ID,
  'EXPIRING_BY_YEAR_BY_ORGANISATION_ID': EXPIRING_BY_YEAR_BY_ORGANISATION_ID,
  'EXPIRING_BY_YEAR_BY_TECH_ID': EXPIRING_BY_YEAR_BY_TECH_ID,
  'FAMILIES_BY_CPC_CODE': FAMILIES_BY_CPC_CODE,
  'FAMILIES_BY_PORTFOLIO_BY_CPC_CODE': FAMILIES_BY_PORTFOLIO_BY_CPC_CODE,
  'FAMILIES_BY_TECH_BY_CPC_CODE': FAMILIES_BY_TECH_BY_CPC_CODE,
  'FAMILIES_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID': FAMILIES_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
  'FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID': FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
  'FAMILIES_BY_PORTFOLIO_BY_GRANTED_YEAR_ID': FAMILIES_BY_PORTFOLIO_BY_GRANTED_YEAR_ID,
  'FAMILIES_BY_TECH_BY_PRIORITY_YEAR_ID': FAMILIES_BY_TECH_BY_PRIORITY_YEAR_ID,
  'FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID': FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
  'FAMILIES_BY_TECH_BY_GRANTED_YEAR_ID': FAMILIES_BY_TECH_BY_GRANTED_YEAR_ID,
  'FORWARD_CITATIONS_BY_ORG_BY_TECH_ID': FORWARD_CITATIONS_BY_ORG_BY_TECH_ID,
  'FUTURE_COST_BY_COUNTRY_ID': FUTURE_COST_BY_COUNTRY_ID,
  'GRANTED_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID': GRANTED_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID,
  'GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID': GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  'GRANTED_FAMILIES_BY_PORTFOLIO_ID': GRANTED_FAMILIES_BY_PORTFOLIO_ID,
  'GRANTED_FAMILIES_BY_PRIORITY_YEAR_ID': GRANTED_FAMILIES_BY_PRIORITY_YEAR_ID,
  'GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID': GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  'GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID': GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
  'GRANTED_FAMILIES_BY_GRANT_YEAR_BY_PORTFOLIO_ID': GRANTED_FAMILIES_BY_GRANT_YEAR_BY_PORTFOLIO_ID,
  'GRANTED_FAMILIES_BY_GRANT_YEAR_BY_TECHNOLOGY_ID': GRANTED_FAMILIES_BY_GRANT_YEAR_BY_TECHNOLOGY_ID,
  'GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_COUNTRY_ID': GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_COUNTRY_ID,
  'GRANTED_FAMILIES_BY_PUBLICATION_YEAR_BY_COUNTRY_ID': GRANTED_FAMILIES_BY_PUBLICATION_YEAR_BY_COUNTRY_ID,
  'GRANTED_FAMILIES_BY_TECH_BY_COUNTRY_ID': GRANTED_FAMILIES_BY_TECH_BY_COUNTRY_ID,
  'GRANTED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID': GRANTED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  'GRANTED_FAMILIES_BY_TECH_BY_YEAR_ID': GRANTED_FAMILIES_BY_TECH_BY_YEAR_ID,
  'GRANTED_FAMILIES_BY_TECH_ID': GRANTED_FAMILIES_BY_TECH_ID,
  'GRANTS_BY_COUNTRY_ID': GRANTS_BY_COUNTRY_ID,
  'GRANTS_BY_GRANTYEAR_BY_COUNTRY_ID': GRANTS_BY_GRANTYEAR_BY_COUNTRY_ID,
  'GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID': GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  'GRANTS_BY_TECHNOLOGY_BY_COUNTRY_ID': GRANTS_BY_TECHNOLOGY_BY_COUNTRY_ID,
  'GRANTS_BY_PRIORITY_YEAR_ID': GRANTS_BY_PRIORITY_YEAR_ID,
  'GRANTS_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID': GRANTS_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  'GRANTS_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID': GRANTS_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
  'LITIGATED_FAMILIES_BY_PORTFOLIO_ID': LITIGATED_FAMILIES_BY_PORTFOLIO_ID,
  'LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID': LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  'LITIGATED_FAMILIES_BY_TECH_ID': LITIGATED_FAMILIES_BY_TECH_ID,
  'LITIGATION_BALANCE_ID': LITIGATION_BALANCE_ID,
  'LITIGATIONS_BY_DEFENDANT_BY_START_YEAR_ID': LITIGATIONS_BY_DEFENDANT_BY_START_YEAR_ID,
  'LITIGATIONS_BY_DEFENDANT_BY_JURISDICTION': LITIGATIONS_BY_DEFENDANT_BY_JURISDICTION,
  'LITIGATIONS_BY_PLAINTIFF_BY_START_YEAR_ID': LITIGATIONS_BY_PLAINTIFF_BY_START_YEAR_ID,
  'LITIGATIONS_BY_PLAINTIFF_BY_JURISDICTION': LITIGATIONS_BY_PLAINTIFF_BY_JURISDICTION,
  'LITIGATIONS_DEFENSIVE_DISPUTES': LITIGATIONS_DEFENSIVE_DISPUTES,
  'LITIGATIONS_OFFENSIVE_DISPUTES': LITIGATIONS_OFFENSIVE_DISPUTES,
  'LITIGATIONS_CROSS_DISPUTES': LITIGATIONS_CROSS_DISPUTES,
  'LITIGATIONS_ALL_PATFAMS_DISPUTES': LITIGATIONS_ALL_PATFAMS_DISPUTES,
  'PATFAMS_WITH_GRANTS_BY_COUNTRY_ID': PATFAMS_WITH_GRANTS_BY_COUNTRY_ID,
  'PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID': PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  'PATFAMS_WITH_GRANTS_BY_TECH_BY_COUNTRY_ID': PATFAMS_WITH_GRANTS_BY_TECH_BY_COUNTRY_ID,
  'PATFAMS_WITH_GRANTS_BY_REGION_ID': PATFAMS_WITH_GRANTS_BY_REGION_ID,
  'PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_REGION_ID': PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_REGION_ID,
  'PATFAMS_WITH_GRANTS_BY_TECH_BY_REGION_ID': PATFAMS_WITH_GRANTS_BY_TECH_BY_REGION_ID,
  'PENDING_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID': PENDING_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  'PENDING_FAMILIES_BY_PORTFOLIO_ID': PENDING_FAMILIES_BY_PORTFOLIO_ID,
  'PENDING_FAMILIES_BY_PRIORITY_YEAR_ID': PENDING_FAMILIES_BY_PRIORITY_YEAR_ID,
  'PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID': PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  'PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID': PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
  'PENDING_FAMILIES_BY_TECH_BY_PORTFOLIO_ID': PENDING_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  'PENDING_FAMILIES_BY_TECH_BY_YEAR_ID': PENDING_FAMILIES_BY_TECH_BY_YEAR_ID,
  'PENDING_FAMILIES_BY_TECH_ID': PENDING_FAMILIES_BY_TECH_ID,
  'PUBLICATIONS_BY_PUB_YEAR_BY_COUNTRY_ID': PUBLICATIONS_BY_PUB_YEAR_BY_COUNTRY_ID,
  'PUBLICATIONS_BY_PORTFOLIO_BY_BY_CPC_CODE_ID': PUBLICATIONS_BY_PORTFOLIO_BY_BY_CPC_CODE_ID,
  'RECENTLY_PUBLISHED_BY_ORG_BY_TECH_ID': RECENTLY_PUBLISHED_BY_ORG_BY_TECH_ID,
  'RECENTLY_GRANTED_BY_ORG_BY_TECH_ID': RECENTLY_GRANTED_BY_ORG_BY_TECH_ID,
  'ACTIVE_SIZE_GROWTH_BY_PORTFOLIO_ID': ACTIVE_SIZE_GROWTH_BY_PORTFOLIO_ID,
  'ACTIVE_SIZE_GROWTH_BY_TECH_ID': ACTIVE_SIZE_GROWTH_BY_TECH_ID,
  'GRANTED_SIZE_GROWTH_BY_PORTFOLIO_ID': GRANTED_SIZE_GROWTH_BY_PORTFOLIO_ID,
  'GRANTED_SIZE_GROWTH_BY_TECH_ID': GRANTED_SIZE_GROWTH_BY_TECH_ID,
  'FAMILIES_BY_STATUS': FAMILIES_BY_STATUS,
  'NUM_FAMILIES_BY_SCORE_BAND': NUM_FAMILIES_BY_SCORE_BAND,
  'NUM_FAMILIES_BY_SCORE_BAND_BY_TECHNOLOGY': NUM_FAMILIES_BY_SCORE_BAND_BY_TECHNOLOGY,
  'NUM_FAMILIES_BY_SCORE_BAND_BY_ORGANISATION': NUM_FAMILIES_BY_SCORE_BAND_BY_ORGANISATION,
  'APPLICATIONS_AND_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID': APPLICATIONS_AND_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  'APPLICATIONS_AND_GRANTS_BY_TECH_BY_COUNTRY_ID': APPLICATIONS_AND_GRANTS_BY_TECH_BY_COUNTRY_ID,
  'APPLICATIONS_AND_GRANTS_BY_TECH_BY_PORTFOLIO_ID': APPLICATIONS_AND_GRANTS_BY_TECH_BY_PORTFOLIO_ID,
  'GRANTS_BY_TECH_BY_PORTFOLIO_ID': GRANTS_BY_TECH_BY_PORTFOLIO_ID,
  'COST_BY_TYPE_BY_YEAR_ID': COST_BY_TYPE_BY_YEAR_ID,
  'PVIX_TOTAL_BY_PORTFOLIO_ID': PVIX_TOTAL_BY_PORTFOLIO_ID,
  'PVIX_TOTAL_BY_TECH_ID': PVIX_TOTAL_BY_TECH_ID,
  'PVIX_TOTAL_BY_TECH_BY_PORTFOLIO_ID': PVIX_TOTAL_BY_TECH_BY_PORTFOLIO_ID,
  'PVIX_MEAN_BY_PORTFOLIO_ID': PVIX_MEAN_BY_PORTFOLIO_ID,
  'PVIX_MEAN_BY_TECH_ID': PVIX_MEAN_BY_TECH_ID,
  'PVIX_MEAN_BY_TECH_BY_PORTFOLIO_ID': PVIX_MEAN_BY_TECH_BY_PORTFOLIO_ID,
  'PVIX_MEAN_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID': PVIX_MEAN_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
  'PVIX_MEAN_BY_TECH_BY_PRIORITY_YEAR_ID': PVIX_MEAN_BY_TECH_BY_PRIORITY_YEAR_ID,
  'PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID': PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID,
  'PVIX_MEAN_BY_TECHNOLOGY_BY_SIZE_ID': PVIX_MEAN_BY_TECHNOLOGY_BY_SIZE_ID,
  'NUM_FAMILIES_BY_PVIX_BAND_BY_PORTFOLIO_ID': NUM_FAMILIES_BY_PVIX_BAND_BY_PORTFOLIO_ID,
  'NUM_FAMILIES_BY_PVIX_BAND_BY_TECHNOLOGY_ID': NUM_FAMILIES_BY_PVIX_BAND_BY_TECHNOLOGY_ID,
  'CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO': CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  'EXPIRED_FAMILIES_BY_PORTFOLIO_ID': EXPIRED_FAMILIES_BY_PORTFOLIO_ID,
  'EXPIRED_FAMILIES_BY_TECH_ID': EXPIRED_FAMILIES_BY_TECH_ID,
  'EXPIRED_FAMILIES_BY_PORTFOLIO_BY_TECH_ID': EXPIRED_FAMILIES_BY_PORTFOLIO_BY_TECH_ID,
}

export function get_new_spec_id(spec_id='') {
  const is_old_id = spec_id.split('_').length > 1

  if (!is_old_id) return spec_id

  try {
    return OLD_ID_TO_NEW_ID[spec_id]
  }
  catch (e) {
    return ''
  }
}

/*
* random strings to use as ids
NHwLr
zmKAE
BJBvt
kY6Wu
gyP3I
CvsUQ
zd1RF
SFkxJ
6uXnO
QQYpz
l6VvO
92eZ6
3BX4F
NQ3uj
2uax3
DmnZM
MKkBw
9nkG9
As6ju
xZZun
fiyuM
0knV1
5afmr
sNTMN
ZC26W
14GmT
VChln
CetbK
oddhw
Cq4OI
t5EDp
0ZLvv
HItQw
ExiDR
QGVMp
6a8hp
Jno0J
wRaAM
q39q5
qiJbC
lN3li
UX212
* */