import { TAG_GROUP_PRODUCT } from '../model/org_sets.js'

export function get_selected_products(selected_tags) {
  const selected_product_tags = selected_tags.filter(full_tag => {
    const [category,] = full_tag.split(':')
    return category === TAG_GROUP_PRODUCT
  })

  return selected_product_tags.map(full_tag => {
    const [, tag] = full_tag.split(':')
    return tag
  })
}