export const HAS_VIEWED_INTRO_TOUR = 'has_viewed_intro_tour'

export const USER_DEFAULT_MAIN_ITEMS = 'user_default_main_items'

export const USER_CUSTOM_CHART_SETS = 'user_custom_chart_sets'

export const HAS_ACCEPTED_TRIAL_AGREEMENT = 'has_accepted_trial_agreement'

export const USER_REGION_GROUPING = 'user_report_region_grouping'

export const USER_SHOW_UNGROUPED_FAMILIES = 'user_show_ungrouped_families'

export const USER_CHARTS_IN_THUMBNAILS = 'user_charts_in_thumbnails'

export const USER_DEFAULT_CHART_SELECTION = 'user_default_chart_selection'

export const USER_DEFAULT_UTT_VERSION = 'user_default_utt_version'

export const USER_STARTING_PAGE = 'user_starting_page'

export const USER_INCLUDE_UNRELATED_TECHS = 'user_include_unrelated_techs'

export const NOTIFY_BEFORE_REPORT_EXPIRY = 'notify_before_report_expiry'

export const HAS_VIEWED_SAVED_REPORT_INTRO = 'has_viewed_saved_report_intro'
export const USER_LANGUAGE_PREFERENCE = 'user_language_preference'

export const USER_SAVE_KNN_SEARCHES = 'user_save_knn_searches'

export const SETTINGS_ENABLED_TO_RESET = [
  USER_REGION_GROUPING,
  USER_SHOW_UNGROUPED_FAMILIES,
  USER_CHARTS_IN_THUMBNAILS,
  USER_DEFAULT_CHART_SELECTION,
  USER_STARTING_PAGE,
  USER_INCLUDE_UNRELATED_TECHS,
  USER_LANGUAGE_PREFERENCE,
  NOTIFY_BEFORE_REPORT_EXPIRY,
  USER_SAVE_KNN_SEARCHES
]
