import axios from 'axios'
import _ from 'underscore'

import { USER_GROUP_BASE_URL } from '../constants/urls.js'
import { CONFIG_NO_CACHE, JSON_POST_HEADER, add_source_err_to_target_err } from './axios_utils.js'

export function fetch_keycloak_group(group_id) {
  return axios.get(`${USER_GROUP_BASE_URL}/groups/${group_id}`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch keycloak group: ')
    })
}

export function update_keycloak_group(group_id, group_obj) {
  return axios.put(`${USER_GROUP_BASE_URL}/groups/${group_id}`, group_obj, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update keycloak group: ')
    })
}

export function fetch_my_keycloak_groups() {
  return axios.get(`${USER_GROUP_BASE_URL}/my_groups`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch user\'s keycloak groups: ')
    })
}

export function fetch_all_keycloak_groups() {
  return axios.get(`${USER_GROUP_BASE_URL}/all_groups`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch all keycloak groups: ')
    })
}

export function fetch_all_active_keycloak_groups() {
  return axios.get(`${USER_GROUP_BASE_URL}/all_active_groups`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch all active keycloak groups: ')
    })
}

export function fetch_all_active_keycloak_users() {
  return axios.get(`${USER_GROUP_BASE_URL}/all_active_users`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch all active keycloak users: ')
    })
}

export function fetch_active_classifiers_users_in_group() {
  return axios.get(`${USER_GROUP_BASE_URL}/active_classifiers_users_in_group`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch all active classifiers users in group: ')
    })
}

export function fetch_all_users_in_my_group() {
  return axios.get(`${USER_GROUP_BASE_URL}/all_users_in_my_group`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch all users in my group: ')
    })
}

export function fetch_active_solutions_users() {
  return axios.get(`${USER_GROUP_BASE_URL}/active_solutions_users`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch all active solutions users: ')
    })
}

export function fetch_users_in_keycloak_group(group_id) {
  // Only returns active users
  return axios.get(`${USER_GROUP_BASE_URL}/${group_id}/user`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch users in keycloak group: ')
    })
}

export function fetch_all_users_in_keycloak_group(group_id) {
  // Returns all users (active and disabled)
  return axios.get(`${USER_GROUP_BASE_URL}/${group_id}/users_all`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch all users in keycloak group: ')
    })
}

export function fetch_child_roles(roles) {
  const composite_roles = roles.filter(role => role.composite)
  if (composite_roles.length === 0) {
    return Promise.resolve({})
  }
  const composite_role_ids = composite_roles.map(role => role.id)
  const role_ids_string = composite_role_ids.join(',')
  return axios.get(`${USER_GROUP_BASE_URL}/child_roles/${role_ids_string}`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch child roles for keycloak groups: ')
    })
}

export function get_all_child_role_ids(id_to_child_roles) {
  const child_roles = _.flatten(_.values(id_to_child_roles))
  const child_role_ids = child_roles.map(role => role.id)
  const child_roles_ids__unique = _.uniq(child_role_ids)
  return child_roles_ids__unique
}

export function fetch_roles_for_keycloak_group(group_id) {
  return axios.get(`${USER_GROUP_BASE_URL}/${group_id}/role`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch roles for keycloak group: ')
    })
}

export function fetch_available_roles_for_keycloak_group(group_id) {
  return axios.get(`${USER_GROUP_BASE_URL}/${group_id}/role/available`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch available roles for keycloak group: ')
    })
}

export function create_new_keycloak_user_group(group_name, is_subscriber) {
  return axios.post(USER_GROUP_BASE_URL, {name: group_name, is_subscriber}, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to create new user group: ')
    })
}

export function add_roles_for_keycloak_group(group_id, roles) {
  return axios.post(`${USER_GROUP_BASE_URL}/${group_id}/role`, roles, { headers: JSON_POST_HEADER },  )
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to add roles for keycloak group: ')
    })
}

export function remove_roles_for_keycloak_group(group_id, roles) {
  return axios.delete(`${USER_GROUP_BASE_URL}/${group_id}/role`, { data: roles, headers: JSON_POST_HEADER },  )
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to remove roles for keycloak group: ')
    })
}

export function is_valid_email_address(email_address) {
  return email_address && email_address.length > 0 && _.contains(email_address, '@')
}

export function is_valid_group_name(group_name) {
  return group_name && group_name.length > 0
}
