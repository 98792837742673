import { useEffect } from 'react'
import _ from 'underscore'

import { POSITIVE, NEGATIVE, IGNORE, UNKNOWN, TEST_POSITIVE, TEST_NEGATIVE } from '../../components/classifiers_editor/constants/labels.js'

import { ok_to_proceed_with_shortcuts } from './keyboard_utils.js'

import {
  LABEL_AS_POSITIVE_KEYS,
  LABEL_AS_NEGATIVE_KEYS,
  LABEL_AS_IGNORE_KEYS,
  LEFT_KEY,
  RIGHT_KEY,
  DELETE_KEY,
  BACKSPACE_KEY,
  ENTER_KEY,
  REMOVE_LABEL_KEYS,
  LABEL_AS_TEST_POSITIVE_KEYS,
  LABEL_AS_TEST_NEGATIVE_KEYS
} from '../../constants/keys.js'

/**
 * Custom react hook, which registers keyboard shortcuts.
 */
export function usePatentKeyboardShortcuts(props) {
  const { disable } = props

  useEffect(() => {
    if (disable) {
      return undefined
    }

    // Register handler (post-render)
    const on_keyup_with_props = on_keyup.bind(null, props)
    document.addEventListener('keyup', on_keyup_with_props)

    return () => {
      // Unregister handler (on pre-render/unmount)
      document.removeEventListener('keyup', on_keyup_with_props)
    }
  })
}

function on_keyup(props, event) {
  if (!ok_to_proceed_with_shortcuts(event)) {
    return
  }

  const { keyCode } = event

  const {
    source,
    on_next_patent,
    on_prev_patent,
    selected_patent,
    set_label,
    on_show_details,
    on_hide_details,
  } = props

  if (_.contains([LEFT_KEY], keyCode)) {
    event.preventDefault()
    on_prev_patent()
    return
  }

  if (_.contains([RIGHT_KEY], keyCode)) {
    event.preventDefault()
    on_next_patent()
    return
  }

  if (_.contains(LABEL_AS_POSITIVE_KEYS, keyCode) && set_label && selected_patent) {
    event.preventDefault()
    set_label(source, selected_patent, POSITIVE)
    return
  }

  if (_.contains(LABEL_AS_NEGATIVE_KEYS, keyCode) && set_label && selected_patent) {
    event.preventDefault()
    set_label(source, selected_patent, NEGATIVE)
    return
  }

  if (_.contains(LABEL_AS_TEST_POSITIVE_KEYS, keyCode) && set_label && selected_patent) {
    event.preventDefault()
    set_label(source, selected_patent, TEST_POSITIVE)
    return
  }

  if (_.contains(LABEL_AS_TEST_NEGATIVE_KEYS, keyCode) && set_label && selected_patent) {
    event.preventDefault()
    set_label(source, selected_patent, TEST_NEGATIVE)
    return
  }

  if (_.contains(LABEL_AS_IGNORE_KEYS, keyCode) && set_label && selected_patent) {
    event.preventDefault()
    set_label(source, selected_patent, IGNORE)
    return
  }

  if (_.contains(REMOVE_LABEL_KEYS, keyCode) && set_label && selected_patent) {
    event.preventDefault()
    set_label(source, selected_patent, UNKNOWN)
    return
  }

  if (_.contains([ENTER_KEY], keyCode)) {
    event.preventDefault()
    if (on_show_details) {
      on_show_details()
    }
    return
  }

  if (_.contains([BACKSPACE_KEY, DELETE_KEY], keyCode)) {
    event.preventDefault()
    on_hide_details()
    return
  }


}