import React, { useEffect, useState } from 'react'

import BespokeReportViewer from './BespokeReportViewer.js'
import { BENCHMARKING_DECK } from './benchmarking_deck.js'
import { NavigableReportDeck } from '../../../utils/report_deck_navigation_utils.js'
import { extract_external_report_id } from '../../../utils/report_url_utils.js'
import { track_deck_viewer_event, track_visit_event } from '../../../utils/tracking_utils.js'
import { fetch_report_metadata, update_report_title } from '../../../utils/report_created_utils.js'
import { save_to_report_history, scroll_window_to_top, show_title_in_browser } from '../../../utils/viewer_utils.js'
import ErrorModal from '../../ErrorModal.js'
import { BENCHMARKING_REPORT_TYPE } from '../../../constants/constants.js'
import { check_or_poll_for_final_report_status } from '../../../utils/report_status_utils.js'

const Benchmarking = () => {
  const external_report_id = extract_external_report_id(window.location.href)

  const [is_initial_fetch, set_is_initial_fetch] = useState(true)
  const [initial_data, set_initial_data] = useState(null)
  const [choreo_status, set_choreo_status] = useState(null)
  const [fetch_initial_error, set_fetch_initial_error] = useState(null)
  const [error_renaming_report, set_error_renaming_report] = useState(null)

  useEffect(() => {
    scroll_window_to_top()
  }, [])

  useEffect(() => {
    let did_cancel = false

    track_visit_event('page="benchmarking"')
    fetch_report_metadata(external_report_id)
      .then(report_metadata => {
        const {internal_report_id, title} = report_metadata

        set_initial_data({ external_report_id, report_metadata })
        show_title_in_browser(title)
        save_to_report_history(external_report_id, false, window.location.pathname)

        return check_or_poll_for_final_report_status(internal_report_id, set_choreo_status)
      })
      .catch(error => {
        if (!did_cancel) {
          set_fetch_initial_error(error)
          set_is_initial_fetch(false)
          throw error
        }
      })
      .then(choreo_status => {
        if (!did_cancel) {
          save_to_report_history(external_report_id, true, window.location.pathname)
          set_choreo_status(choreo_status)
          set_is_initial_fetch(false)
        }
      })

    return () => {
      did_cancel = true
    }

  }, [external_report_id])


  function rename_report (new_report_title) {
    track_deck_viewer_event(`obj="report" action="rename" context="deck_viewer" deck_type="${BENCHMARKING_REPORT_TYPE}"`)
    update_report_title(external_report_id, new_report_title)
      .then(() => {
        //refresh with new title
        const {report_metadata} = initial_data
        const updated_metadata = {...report_metadata, title: new_report_title}
        set_initial_data({...initial_data, report_metadata: updated_metadata})
        show_title_in_browser(new_report_title)
      })
      .catch(error => {
        set_error_renaming_report(error)
        throw error
      })
  }

  return (
    <>
      <BespokeReportViewer
        report_deck={NavigableReportDeck(BENCHMARKING_DECK)}
        deck_type={BENCHMARKING_REPORT_TYPE}
        is_initial_fetch={is_initial_fetch}
        fetch_initial_error={fetch_initial_error}
        initial_data={initial_data}
        choreo_status={choreo_status}
        rename_report={rename_report}
      />

      {error_renaming_report &&
        <ErrorModal
          on_hide={() => set_error_renaming_report(null)}
          error={error_renaming_report}
          context={'renaming report'}
        />
      }

    </>
  )
}

export default Benchmarking