import React, { Component } from 'react'
import * as d3 from 'd3'
import cn from 'classnames'

import BaseTreemap from './BaseTreemap.js'
import { get_keys_to_value, get_value_column_idx, IS_NEXT_AGGLOM } from '../../utils/column_data_utils.js'
import {
  format_percent,
  format_percent_plusminus,
  format_plusminus,
  get_color,
  get_legend_ticks,
  LEGEND_WIDTH,
  X_COLUMN_IDX,
  Y_COLUMN_IDX
} from '../../utils/treemap_utils.js'
import { format_integer_with_comma } from '../../utils/utils.js'
import { should_hide_next_agglom_item } from '../../utils/main_items_utils.js'
import { get_share_data, get_year_min_max_totals } from '../../utils/growth_view_utils.js'
import { get_timerange } from '../../utils/time_range_utils.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import GrowthTabularView from './GrowthTabularView.js'

import s from './GrowthTreemap.module.scss'
// NOTE: adds global css in plotly.override.scss

class GrowthTreemap extends Component {
  componentDidMount() {
    this.legend()
  }

  legend() {
    const legend_ticks = get_legend_ticks()
    const tick_width = LEGEND_WIDTH / legend_ticks.length
    const legend_tick_label = function(d) { return format_plusminus((d * 100).toFixed(0)) }

    const legendX = d3.scaleBand().domain(legend_ticks).range([0, LEGEND_WIDTH])

    const legendXAxis = d3.axisBottom().scale(legendX)
      .tickValues(legend_ticks)
      .tickFormat(legend_tick_label)


    const legend_key = d3.select(this.treemap_legend_ref).append('svg').attr('height', 20).attr('width', LEGEND_WIDTH)

    // For each tick, show a colored rectangle.
    legend_key.selectAll('rect')
      .data(legend_ticks)
      .enter().append('rect')
      .attr('height', 8)
      .attr('x', function(d) { return legendX(d) })
      .attr('width', function() { return tick_width })
      .style('fill', function(d) { return get_color(d) })

    legend_key.call(legendXAxis)
      .select(".domain").remove()
  }

  get_tooltip_text({item, timerange, sizes, totals, shares, change}) {
    const {name} = item
    const [year_min, year_max] = timerange
    const [year_min_size, year_max_size] = sizes
    const [year_min_totals, year_max_totals] = totals
    const [year_min_share, year_max_share] = shares

    return `${name}<br><br>` +
      'Share<br>' +
      `${year_min}: ${format_percent(year_min_share)} (${format_integer_with_comma(year_min_size)} of ${format_integer_with_comma(year_min_totals)} families)<br>` +
      `${year_max}: ${format_percent(year_max_share)} (${format_integer_with_comma(year_max_size)} of ${format_integer_with_comma(year_max_totals)} families)<br>` +
      `Change: ${format_percent_plusminus(change)}`
  }

  get_processed_data() {
    const { data, key_dims, spec, item } = this.props
    const x_items = key_dims[X_COLUMN_IDX]

    const hide_next = should_hide_next_agglom_item(spec, item, X_COLUMN_IDX)
    const x_items_filtered = !hide_next ? x_items : x_items.filter(item => (!item[IS_NEXT_AGGLOM]))
    const timerange = get_timerange({spec, item, key_dims})
    const { year_min_totals, year_max_totals } = get_year_min_max_totals({ data, timerange})

    const value_column_idx = get_value_column_idx(data)
    const keys_to_value = get_keys_to_value(data, [X_COLUMN_IDX, Y_COLUMN_IDX], value_column_idx)

    const x_items_with_sizes = []

    x_items_filtered.forEach(x_item => {
      const {year_min_size, year_max_size, year_min_share, year_max_share, share_change_rel} = get_share_data({keys_to_value, x_item, timerange, year_min_totals, year_max_totals})

      const share_change = (share_change_rel) ? share_change_rel - 1 : null

      const square_bg_color = get_color(share_change || 1)

      const sizes = [year_min_size, year_max_size]
      const totals = [year_min_totals, year_max_totals]
      const shares = [year_min_share, year_max_share]

      if (year_max_size !== 0) {
        x_items_with_sizes.push({
          ...x_item,
          value: year_max_share,
          color: square_bg_color,
          tooltip_text: this.get_tooltip_text({item: x_item, timerange, sizes, totals, shares, change: share_change})
        })
      }
    })

    return x_items_with_sizes
  }

  render() {
    const { spec, item, key_dims, is_thumbnail, set_selected, selections } = this.props
    const items = this.get_processed_data()
    const timerange = get_timerange({spec, item, key_dims})
    const [year_min, year_max] = timerange

    return(
      <div>
        {!is_thumbnail &&
          <div className='mt-3'>
            <div className={s.description}>Rectangle area: Share (%), {year_max}</div>
            <div className={s.description}>Rectangle colour: Change in Share, {year_min} to {year_max}</div>
            <div className={cn('growth_treemap_legend', s.legend)} ref={ (treemap_legend) => this.treemap_legend_ref = treemap_legend}> </div>
          </div>
        }

        <BaseTreemap
          is_thumbnail={is_thumbnail}
          items={items}
          set_selected={set_selected}
          selections={selections}
        />
      </div>
    )
  }
}


GrowthTreemap.get_plotly_data = (props) => {
  const data = GrowthTabularView.get_csv_value_rows({ ...props})
  const export_data = get_csv_string(data)
  return export_data
}

GrowthTreemap.get_csv_value_rows = (props) => {
  return GrowthTabularView.get_csv_value_rows({ ...props})
}

export default GrowthTreemap