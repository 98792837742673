import axios from 'axios'

import { TRAINING_ACTIVITY_BASE_URL } from '../constants/urls.js'

import { add_source_err_to_target_err } from './axios_utils.js'

export const LOCATION_CLASSIFIERS_EDITOR    = 'classifiers_editor_ui'
export const LOCATION_EVALUATION_REPORT     = 'evaluation_report'

const DEFAULT_HEADERS = { 'Content-Type': 'application/json' }

export function save_training_activity({
  classifier_id, build_version, owner_id,
  seconds_elapsed, num_classifier_builds, num_eval_report_builds,
  num_boolean_searches, num_suggestions, num_similar_fam_searches,
  counts,
}) {

  const data = {
    classifier_id, build_version, owner_id,
    seconds_elapsed, num_classifier_builds, num_eval_report_builds,
    num_boolean_searches, num_suggestions, num_similar_fam_searches,
    counts
  }

  return axios.post(TRAINING_ACTIVITY_BASE_URL, data, { headers: DEFAULT_HEADERS })
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to save training activity: ')
    })
}

export function get_all_training_activity() {
  return axios.get(TRAINING_ACTIVITY_BASE_URL + '/all_activity')
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get training activity: ')
    })
    .then((response) => response.data)
}

export function get_user_id_to_group__external() {
  return axios.get(TRAINING_ACTIVITY_BASE_URL + '/user_id_to_group__external')
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get user_id_to_group__external: ')
    })
    .then((response) => response.data)
}

export function get_user_id_to_user__internal() {
  return axios.get(TRAINING_ACTIVITY_BASE_URL + '/user_id_to_user__internal')
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to get user_id_to_user__internal: ')
    })
    .then((response) => response.data)
}