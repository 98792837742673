import React from 'react'
import cn from 'classnames'

import { Pane } from '../widgets/Block.js'

const DEFAULT_TEXT = 'Choose to enter an organisation, a technology, an organisation set or a patent upload above to start a new report.'

const ReportBuilderStartScreen = ({ text, className}) => {
  const text_to_display = text || DEFAULT_TEXT

  return (
    <Pane className={cn('text-center', className)}>
      {text_to_display}
    </Pane>
  )
}

export default ReportBuilderStartScreen