import React from 'react'
import cn from 'classnames'

import s from './ScrollableList.module.scss'

function ScrollableList( props, ref ) {
  const { className, children, onClick, no_scroll } = props

  return (
    <div
      className={cn(
        { [s.block]: !no_scroll },
        className
      )}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </div>
  )
}

export default React.forwardRef(ScrollableList)