import { get_as_map } from '../utils/utils.js'

import { PORTFOLIO_SIZE_GROUP } from './spec_groups/portfolio_size.js'
import { PORTFOLIO_TRENDS_GROUP } from './spec_groups/portfolio_trends.js'
import { PATENTING_ACTIVITY_GROUP } from './spec_groups/patenting_activity.js'
import { GEOGRAPHY_GROUP } from './spec_groups/geography.js'
import { AGE_GROUP } from './spec_groups/age.js'
import { COST_GROUP } from './spec_groups/cost.js'
import { LITIGATION_GROUP } from './spec_groups/litigations.js'
import { PORTFOLIO_STATS_GROUP } from './spec_groups/portfolio_stats.js'
import { PVIX_SCORE_GROUP } from './spec_groups/pvix.js'
import { SCORE_GROUP } from './spec_groups/score.js'
import { SELECTED_GROUP } from './spec_groups/selected.js'

export const GROUPS = [
  PORTFOLIO_SIZE_GROUP,
  PORTFOLIO_TRENDS_GROUP,
  PATENTING_ACTIVITY_GROUP,
  GEOGRAPHY_GROUP,
  AGE_GROUP,
  COST_GROUP,
  LITIGATION_GROUP,
  PORTFOLIO_STATS_GROUP,
  PVIX_SCORE_GROUP,
  SCORE_GROUP
]

/**
 * Given a group tree, traverse it depth-first, accumulating three maps:
 * a) spec_id_to_group    - 'group' is the top-level group that the spec is part of.
 * b) spec_id_to_spec_ref - 'spec_ref' is the lowest level group object that references the spec.
 * @param {} groups An array, where each element either has property 'spec_id' (leaf), or property 'children'.
 */
function get_spec_id_to_group_and_spec_id_to_spec_ref(groups, top_level_group=null, level=0) {
  // For now, assume either 3 levels (group ->* subgroup ->* spec), or 2 levels (group ->* spec).
  return groups.reduce(({ spec_id_to_group, spec_id_to_spec_ref }, group) => {
    const { spec_id, children } = group
    const _top_level_group = top_level_group || group

    if (spec_id != null) {
      // It's a spec
      return {
        spec_id_to_group:    {...spec_id_to_group,    [spec_id]: _top_level_group},
        spec_id_to_spec_ref: {...spec_id_to_spec_ref, [spec_id]: group}
      }
    }

    if (children != null) {
      // Recurse
      const {
        spec_id_to_group: _spec_id_to_group,
        spec_id_to_spec_ref: _spec_id_to_spec_ref
      } = get_spec_id_to_group_and_spec_id_to_spec_ref(children, _top_level_group, level + 1)

      return {
        spec_id_to_group:    {...spec_id_to_group,    ..._spec_id_to_group},
        spec_id_to_spec_ref: {...spec_id_to_spec_ref, ..._spec_id_to_spec_ref}
      }
    }

    return { spec_id_to_group, spec_id_to_spec_ref }
  }, {
    spec_id_to_group: {},
    spec_id_to_spec_ref: {}
  })
}

export const ALL_GROUPS = [ ...GROUPS, SELECTED_GROUP ]

export const ORDERED_SPEC_IDS_BY_GROUP = ALL_GROUPS.reduce((acc, group) => {
  const {children=[]} = group

  const spec_ids = children.map(spec => spec.spec_id)

  return [...acc, ...spec_ids]
}, [])

export const ID_TO_GROUP = get_as_map(ALL_GROUPS, 'id')

export const {
  spec_id_to_group: SPEC_ID_TO_GROUP,
  spec_id_to_spec_ref: SPEC_ID_TO_SPEC_REF
} = get_spec_id_to_group_and_spec_id_to_spec_ref(ALL_GROUPS)