import React from 'react'

import { is_IE11 } from '../../utils/browser_utils.js'
import { MIMETYPE_HTML } from '../../utils/download_utils.js'

export const HTMLDocViewer = ({ src, title, className }) => {
  if (is_IE11()) {
    return (
      <iframe src={src} title={title} className={className} />
    )
  }

  return (
    <object data={src} type={MIMETYPE_HTML} className={className}>
    </object>
  )
}