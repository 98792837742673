import axios from 'axios'

import { REPORT_CREATED_BASE_URL, REPORT_HISTORY_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err, CONFIG_NO_CACHE, JSON_POST_HEADER } from './axios_utils.js'
import { DEFAULT_REPORT_TYPE } from '../constants/constants.js'

export function save_report_created(internal_report_id, title, report_type=null, external_report_id=null, evaluation_classifier_id=null /* optional args */) {
  const data = {internal_report_id, title, report_type: (report_type || DEFAULT_REPORT_TYPE), external_report_id, evaluation_classifier_id}
  return axios.post(`${REPORT_CREATED_BASE_URL}/`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save report created: ')
      throw wrapped_err
    })
}

export function update_report_title(external_report_id, title) {
  const data = {title}
  return axios.put(`${REPORT_CREATED_BASE_URL}/${external_report_id}`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update report title: ')
    })
}

export function update_report_internal_id(external_report_id, internal_report_id) {
  return axios.put(`${REPORT_CREATED_BASE_URL}/${external_report_id}/report_id/${internal_report_id}`)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update report internal id: ')
    })
}

export function update_report_status(internal_report_id, status) {
  return axios.put(`${REPORT_CREATED_BASE_URL}/${internal_report_id}/final_status/${status}`, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update report status: ')
    })
}

/**
 * Returns metadata object.
 * Includes properties 'internal_report_id', 'title', 'report_type', 'created_by_user', 'created_at', 'user_state', 'is_saved', 'evaluation_classifier_id'.
 */
export function fetch_report_metadata(external_report_id, include_owner_id) {
  const params = {include_owner_id}
  return axios.get(`${REPORT_CREATED_BASE_URL}/${external_report_id}`, {params, ...CONFIG_NO_CACHE})
    .then(response => response.data)
    .then(data => ({
      ...data,
      user_state: JSON.parse(data.user_state), // parse the json here
      is_saved:   (data.is_saved > 0)          // set to boolean
    }))
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch report metadata: ')
      throw wrapped_err
    })
}

/**
 * Returns creation metadata for the oldest report in the database with the given internal_report_id
 */
export function fetch_oldest_report_by_internal_id(internal_report_id) {
  return axios.get(`${REPORT_CREATED_BASE_URL}/oldest/${internal_report_id}`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch report metadata: ')
      throw wrapped_err
    })
}

/**
 * Fetch creation metadata for recent reports known to have failed
 */
export function fetch_most_recent_n_failed_reports(limit) {
  const params = { limit }
  return axios.get(`${REPORT_CREATED_BASE_URL}/monitor/failed`, {params, ...CONFIG_NO_CACHE})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch report metadata: ')
      throw wrapped_err
    })
}

/**
 * Fetch a recent internal report id for testing
 */
export function fetch_an_internal_report_id() {
  return axios.get(`${REPORT_CREATED_BASE_URL}/monitor/any_internal_id`, CONFIG_NO_CACHE)
    .then(response => response.data.internal_report_id)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch report metadata: ')
      throw wrapped_err
    })
}

export function delete_reports_viewed(external_report_ids) {
  return axios.put(`${REPORT_HISTORY_BASE_URL}/delete`, {external_report_ids})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete report viewed: ')
      throw wrapped_err
    })
}

export function delete_reports_created(external_report_ids) {
  return axios.put(`${REPORT_CREATED_BASE_URL}/delete`, {external_report_ids})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete report created: ')
      throw wrapped_err
    })
}

export function fetch_external_report_ids(internal_report_ids) {
  return axios.post(`${REPORT_CREATED_BASE_URL}/external_report_ids`, {internal_report_ids}, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch external report ids: ')
      throw wrapped_err
    })
}