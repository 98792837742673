import { MAX_REPORT_NAME_LENGTH, MAX_PROJECT_NAME_LENGTH } from '../constants/constants.js'
import { remove_double_spaces, replace_tabs_with_spaces, startsWith, endsWith } from './utils.js'

const MAX_SHORT_LABEL_LENGTH = 32

const PREFIXES = [
  'Compagnie Financiere ',
  'Koninklijke '
]

const SUFFIXES = [
  ' A.S.',
  ' A/S',
  ' AB',
  ' ACE',
  ' AG',
  ' AL',
  ' AND CO',
  ' ANS',
  ' AS',
  ' ASA',
  ' AVV',
  ' Aktiengesellschaft',
  ' AmbA',
  ' ApS',
  ' Apb',
  ' B.V.',
  ' BV',
  ' Bpk',
  ' Bt',
  ' C.V.',
  ' CV',
  ' CVA',
  ' Co',
  ' Co.',
  ' Company',
  ' Corp',
  ' Corp.',
  ' Corporation',
  ' DA',
  ' EE',
  ' EEG',
  ' EURL',
  ' GbR',
  ' Gesellschaft',
  ' GesmbH',
  ' GmbH',
  ' Group',
  ' Holding',
  ' Holdings',
  ' Industries',
  ' I/S',
  ' IS',
  ' Inc',
  ' Inc.',
  ' K.K.',
  ' K/S',
  ' KD',
  ' KDA',
  ' KG',
  ' KGaA',
  ' KK',
  ' KS',
  ' Kb',
  ' Lda',
  ' Limited',
  ' Llc',
  ' Llp',
  ' Ltd',
  ' Ltd.',
  ' Ltda',
  ' N.V.',
  ' NV',
  ' OJY',
  ' Oy',
  ' PT',
  ' Plc',
  ' Plc.',
  ' PrC',
  ' Pty',
  ' Pty.',
  ' RES',
  ' Rt.',
  ' S de RL',
  ' S. de R.L.',
  ' S/A',
  ' S.A.',
  ' SAS',
  ' Sanayi Anonim Sirketi',
  ' SA',
  ' SC',
  ' SE',
  ' SK',
  ' SPRL',
  ' Sarl',
  ' SpA',
  ' Spa',
  ' SrK',
  ' Srl',
  ' TLS',
  ' VEB',
  ' hf',
  ' &'
]

const SUFFIX_ABBREVIATIONS = {
  ' International': 'Int',
  ' Instruments': 'Instr',
  ' Scientific': 'Sci',
  ' Solutions': 'Sol',
  ' Technologies': 'Tech',
  ' Technology': 'Tech',
  ' University': 'U'
}

const ABBREVIATIONS = {
  'BSH Bosch-Siemens Hausgerate': 'BSH',
  'Bayerische Motoren Werke': 'BMW',
  'Compagnie Generale des Establishments Michelin' : 'Michelin',
  'Dai Nippon Printing': 'DNP',
  'Dow Chemical': 'Dow',
  'Ford Motor' : 'Ford',
  'General Electric' : 'GE',
  'General Motors' : 'GM',
  'Hewlett-Packard': 'HP',
  'Honda Motor': 'Honda',
  'Honeywell International': 'Honeywell',
  'Huawei Technologies': 'Huawei',
  'Hyundai Motor': 'Hyundai',
  'International Business Machines' : 'IBM',
  'Johnson & Johnson': 'J&J',
  'LVMH Moet Hennessy Louis Vuitton': 'LVMH',
  'Nippon Sheet Glass': 'NSG',
  'Porsche Automobil Holding': 'Porsche Holding',
  'Procter & Gamble': 'P&G',
  'Toyota Motor': 'Toyota',
  'ZF Friedrichshafen': 'ZF',
  'GSI Helmholtzzentrum fur Schwerionenforschung': 'GSI'
}

export function get_short_cluster_name(name) {
  const phrases = name.split(',') // Assume phrases are comma-delimited

  // Pick first n phrases, such that we have at least MIN_WORDS
  const MIN_WORDS = 3
  let word_count = 0
  const result_phrases = []

  for (let p in phrases) {
    const phrase = phrases[p].trim()
    const num_words = phrase.split(' ').length
    result_phrases.push(phrase)
    word_count += num_words
    if (word_count >= MIN_WORDS) {
      break
    }
  }

  return result_phrases.join(', ')
}

export function extend_cluster_short_name(short_name, full_name) {
  if (full_name.indexOf(short_name) === -1) {
    throw Error('full name does not contain short name')
  }

  if (full_name === short_name) {
    // Nothing we can do, so return original
    return short_name
  }

  const idx_of_next_comma = full_name.indexOf(',',  short_name.length + 1)
  if (idx_of_next_comma === -1) {
    // no more commas, must have got to the end of the full name, so return full name
    return full_name
  }

  return full_name.slice(0, idx_of_next_comma)
}

export function get_short_company_name(name) {
  const in_name = name
  name = name.trim()

  if (name.indexOf(' -') !== -1 || name.indexOf(': ') !== -1) {
    // Name probably contains a group
    // i.e. 'Systems - wireless radio'
    // i.e. 'Systems: wireless radio'
    // i.e. sometimes, it is possible that porfolios are not company names
    // So do not shorten, as this can lead to confusing labelling on charts
    return name
  }

  // Remove anything after (
  if (name.indexOf('(') !== -1) {
    name = name.split('(')[0]
    name = name.trim()
  }

  let changes = 1
  while (changes) {
    changes = 0
    for (let p in PREFIXES) {
      if (startsWith(name, PREFIXES[p]) || startsWith(name, PREFIXES[p].toUpperCase())) {
        changes++
        name = name.substr(PREFIXES[p].length)
        name = name.trim()
      }
    }
  }

  changes = 1
  while (changes) {
    changes = 0
    for (let s in SUFFIXES) {
      if (endsWith(name, SUFFIXES[s]) || endsWith(name, SUFFIXES[s].toUpperCase())) {
        changes++
        name = name.substr(0, name.length - SUFFIXES[s].length)
        name = name.trim()
      }
    }
  }

  for (let a in ABBREVIATIONS) {
    if (name === a || name === a.toUpperCase()) {
      name = ABBREVIATIONS[a]
      break
    }
  }

  changes = 1
  while (changes) {
    changes = 0
    for (let sa in SUFFIX_ABBREVIATIONS) {
      if (endsWith(name, sa) || endsWith(name, sa.toUpperCase())) {
        changes++
        name = name.substr(0, name.length - sa.length) + ' ' + SUFFIX_ABBREVIATIONS[sa]
      }
    }
  }

  // If it's still really long, take the first two words
  if (name.length >= 20) {
    let words = name.split(' ')

    const connective_words = ['and', '&', 'of', 'for']

    if (connective_words.indexOf(words[words.length - 1]) !== -1) {
      name = words.slice(0, -1).join(' ')
    } else {

      switch (true) {
        case (connective_words.indexOf(words[2]) !== -1) :
          name = words[0] + ' ' + words[1] + ' ' + words[2] + ' ' + words[3]
          break
        case (connective_words.indexOf(words[1]) !== -1) :
          name = words[0] + ' ' + words[1] + ' ' + words[2]
          break
        default:
          name = words[0] + ' ' + words[1]
      }
    }
  }

  // Remove weird trailing characters
  name = remove_last_special_character_from_text(name)

  // Catchall, just incase we remove everything!
  if (name.length === 0) return in_name

  return name
}

export function limit_long_text_with_ellipsis(text, max_chars) {
  const limit = max_chars || MAX_SHORT_LABEL_LENGTH
  return text.length > limit ? text.substring(0, limit - 3) + '...' : text
}

export function normalise_name(name) {
  if (!name) return ''

  return get_normalised_name(name)
}

export function is_valid_report_name(report_name) {
  return is_valid_name(report_name, MAX_REPORT_NAME_LENGTH)
}

export function is_valid_name(name, max_length) {
  return name && name.trim().length > 0 && name.length <= max_length
}

export function is_valid_project_name(project_name) {
  return project_name && project_name.trim().length > 0 && project_name.length <= MAX_PROJECT_NAME_LENGTH
}

export function remove_not_allowed_chars_from_text(text) {
  if (!text) return ''


  //blog post https://thekevinscott.com/emojis-in-javascript/#writing-a-regular-expression
  
  const emojis = "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c\ude32-\ude3a]|[\ud83c\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|[\ufe00-\ufe0f])"
  
  const regexp = new RegExp(emojis, 'g') // eslint-disable-line no-misleading-character-class

  return text.replace(regexp, '')
}

export function remove_last_special_character_from_text(text) {
  const SPECIAL_CHARS = ['.', ',', ':', ';', '&']

  if (!text) return ''

  const last_char = text[text.length - 1]

  return (SPECIAL_CHARS.indexOf(last_char) !== -1) ?  remove_last_special_character_from_text(text.substring(0, text.length -1)) : text
}

export function get_subselection_string(subselections) {
  if (!subselections) {
    return null
  }

  const item_names = subselections.map(selection => {
    const { items } = selection

    if (items.length === 1) {
      // single item, so show it
      const { short_name, name } = items[0]
      return short_name || name
    }

    // Assume multiple items (i.e. total cell on a clickthrough)
    return null
  })
  .filter(name => name != null) // remove nulls

  return item_names.join(': ')
}


export function format_search_phrase_as_name(search_phrase) {
  let formatted = search_phrase
    .replace(/OR|AND|["(){}[\]]/g, '')
    .replace(/:/g, ' ')
    .replace(/\s\s+/g, ' ')
    .trim()

  if (formatted.length > 40) {
    formatted = formatted.slice(0, 40).trim() + ' etc.'
  }

  return formatted
}

export function get_normalised_name(text) {
  return remove_double_spaces(replace_tabs_with_spaces(remove_not_allowed_chars_from_text(text || ''))).trim()
}