import _ from 'underscore'
import { get_year_from_date_string } from './date_range_utils.js'
import { get_created_month, get_timerange } from './time_range_utils.js'
import { STATUS_GRANTED_ID, STATUS_PENDING_ID } from '../model/statuses.js'
import { CAGR_SCATTER_ID, CAGR_TABLE_ID } from '../model/view_ids.js'

// CAGR only really makes sense for large portfolios
export const SMALLEST_MEANINGFUL_CAGR_VALUE = 10

export function is_pre_calculation_data_empty({data: rr_data, is_cagr}) {
  const {data} = rr_data || {}
  if (!data || _.isEmpty(data) || _.all(data, array => _.isEmpty(array))) {
    return true
  }
  if (is_cagr && data.length === 3) {
    const [,,counts] = data
    // no portfolio in the data is big enough to show in the chart
    return _.all(counts, value => value < SMALLEST_MEANINGFUL_CAGR_VALUE)
  }
  return false
}

export function calculate_filings_within_timerange({spec, data, key_dims, item, data_creation_date}) {
  const {columns, types} = data
  const [portfolios, years, counts] = data.data

  const timerange = get_timerange({spec, item, key_dims, data_creation_date})
  const [start_year, end_year] = timerange

  const portfolios_to_totals = {}

  portfolios.forEach((portfolio, i) => {
    const year = years[i]
    if (year >= start_year && year <= end_year) {
      portfolios_to_totals[portfolio] = (portfolios_to_totals[portfolio] || 0) + counts[i]
    }
  })

  const portfolios_column = _.keys(portfolios_to_totals).map(id => id * 1)
  const totals_column = _.values(portfolios_to_totals)

  return {
    types: [types[0], types[2]],
    columns: [columns[0], columns[2]],
    data: [portfolios_column, totals_column]
  }
}

export function calculate_cagr_from_counts_by_year({spec, data, key_dims, item, data_creation_date}) {
  const {columns, types} = data
  const {view_id} = item || {}

  const include_counts = view_id && _.contains([CAGR_SCATTER_ID, CAGR_TABLE_ID], view_id)

  // for the purposes of this function/ calculation 'portfolios' can be organisations or technologies (we do CAGR for both)
  const [portfolios, years, counts] = data.data

  const timerange = get_timerange({spec, item, key_dims, data_creation_date})

  const start_year = timerange[0]
  const end_year = timerange[1]
  const time_in_years = get_time_in_years(start_year, end_year, data_creation_date)

  let portfolio_to_family_counts = {}

  // first create a map with counts for start and end year in each portfolio
  portfolios.forEach((portfolio_id, i) => {
    if (!portfolio_to_family_counts[portfolio_id]) {
      portfolio_to_family_counts[portfolio_id] = {}
    }
    const existing_counts = portfolio_to_family_counts[portfolio_id]
    if (years[i] === start_year) {
      portfolio_to_family_counts[portfolio_id] = {...existing_counts, [start_year]: counts[i]}
    } else if (years[i] === end_year) {
      portfolio_to_family_counts[portfolio_id] = {...existing_counts, [end_year]: counts[i]}
    }
  })

  let portfolio_column = []
  let cagr_column = []
  let year_min_size_column = []
  let year_max_size_column = []

  // build columns for CAGR by portfolio (can be org or tech)
  _.keys(portfolio_to_family_counts).forEach(portfolio_id => {
    const year_min_size = portfolio_to_family_counts[portfolio_id][start_year] || 0
    const year_max_size = portfolio_to_family_counts[portfolio_id][end_year] || 0

    // we only want to include portfolios in the data if counts are both at a meaningful level for a calculation
    const has_meaningful_values = year_min_size >= SMALLEST_MEANINGFUL_CAGR_VALUE && year_max_size >= SMALLEST_MEANINGFUL_CAGR_VALUE
    if (!time_in_years || !has_meaningful_values) {
      return
    }

    // calculate the compound annual growth rate as a percentage
    // https://www.vertex42.com/Calculators/cagr-calculator.html
    const cagr = (Math.pow((year_max_size/ year_min_size), (1 / time_in_years))  - 1) * 100

    portfolio_column = [...portfolio_column, parseInt(portfolio_id)]
    year_min_size_column = [...year_min_size_column, parseInt(year_min_size)]
    year_max_size_column = [...year_max_size_column, parseInt(year_max_size)]
    cagr_column = [...cagr_column, parseFloat(cagr.toFixed(2))]
  })

  if (_.all(cagr_column, cagr => cagr === 0)) {
    // there's nothing to see here; reset to empty arrays to avoid an ugly chart
    portfolio_column = []
    year_min_size_column = []
    year_max_size_column = []
    cagr_column = []
  }

  if (include_counts) {
    // for the tabular view we want to be able to display the counts involved in the CAGR calculation
    return {
      types: [types[0], 'INTEGER', 'INTEGER', 'FLOAT'],
      columns: [columns[0], 'year_min_size', 'year_max_size', 'cagr'],
      data: [portfolio_column, year_min_size_column, year_max_size_column, cagr_column]
    }
  }

  return {
    types: [types[0], 'FLOAT'],
    columns: [columns[0], 'cagr'],
    data: [portfolio_column, cagr_column]
  }
}

function get_time_in_years(start_year, end_year, data_creation_date) {
  if (end_year === start_year) {
    return 0
  }
  if (end_year === get_year_from_date_string(data_creation_date)) {
    // use partial years if calculating up to the time of report creation
    const created_month = get_created_month(data_creation_date)
    return (end_year - start_year) - 1 + (created_month / 12)
  }
  return end_year - start_year
}

export function transform_counts_by_status_to_percent_pending(query_data) {
  if (!query_data) {
    return null
  }

  const {columns, data, types} = query_data
  const [portfolios, statuses, counts] = data

  let portfolio_column = []
  let percentage_column = []

  _.uniq(portfolios).forEach(portfolio_id => {
    const first_idx = _.indexOf(portfolios, portfolio_id)
    const last_idx = _.lastIndexOf(portfolios, portfolio_id)

    const count_pending = statuses[first_idx] === STATUS_PENDING_ID ? counts[first_idx] : statuses[last_idx] === STATUS_PENDING_ID ? counts[last_idx] : 0
    const count_granted = statuses[first_idx] === STATUS_GRANTED_ID ? counts[first_idx] : statuses[last_idx] === STATUS_GRANTED_ID ? counts[last_idx] : 0

    const percent_pending = count_pending / (count_pending + count_granted) * 100

    if (percent_pending > 0) {
      portfolio_column = [...portfolio_column, portfolio_id]
      percentage_column = [...percentage_column, parseFloat(percent_pending.toFixed(2))]
    }
  })

  return {
    types: [types[0], 'FLOAT'],
    columns: [columns[0], 'percent'],
    data: [portfolio_column, percentage_column]
  }
}