import React, { useContext, useEffect, useState } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import qs from 'query-string'

import { create_search_name, find_similar_families_by_input_id } from '../utils/knn_search.js'
import { withUser } from './UserContext.js'
import Spinner from './widgets/Spinner.js'
import ContainerFullWidth from './ContainerFullWidth.js'
import ErrorBody from './ErrorBody.js'
import { track_report_builder_event } from '../utils/tracking_utils.js'
import { DEFAULT_REPORT_TYPE } from '../constants/constants.js'
import { get_family_ids_filtered } from '../utils/patent_family_list_utils.js'
import { run_report_for_similar_families } from '../utils/report_builder_utils.js'
import { get_default_utt_version } from '../utils/user_settings_utils.js'
import { UserSettingsContext } from './UserSettingsContext.js'

const SimilarFamiliesReportBuilder = ({location}) => {

  const { user_settings } = useContext(UserSettingsContext)
  const utt_version = get_default_utt_version(user_settings)

  const query_params = qs.parse(location.search)
  const {id, utt} = query_params

  const [show_spinner, set_show_spinner] = useState(true)
  const [report_id, set_report_id] = useState(null)
  const [search_results, set_search_results] = useState(null)
  const [report_name, set_report_name] = useState(null)
  const [start_search, set_start_search] = useState(true)
  const [start_report, set_start_report] = useState(false)

  const [search_error, set_search_error] = useState(null)
  const [report_build_error, set_report_build_error] = useState(null)

  useEffect(() => {
    if (!start_search || !id) return
    track_report_builder_event(`action="build_report" report_type="${DEFAULT_REPORT_TYPE}" context="similar_families"`)
    find_similar_families_by_input_id(id)
      .then(response => {
        const {results, input, settings, name} = response || {}
        const {search_phrase=''} = settings || {}

        const new_report_name = name || create_search_name(input)
        set_report_name(new_report_name)

        const family_ids = (results || []).map(item => (item || {}).patfam_id || item)
        return get_family_ids_filtered(family_ids, search_phrase)
      })
      .then((filtered_family_ids) => {
        set_search_results(filtered_family_ids)
        set_start_search(false)
        set_start_report(true)
      })
      .catch((error) => {
        set_search_error(error)
        set_start_search(false)
        set_show_spinner(false)
      })

  },[start_search, id])

  useEffect(() => {
    if (!start_report || !search_results || search_results.length === 0) return

    const use_utt = utt === 'true'
    run_report_for_similar_families({report_name, pat_fam_ids: search_results, use_utt, utt_version})
    .then(external_report_id => {
      set_report_id(external_report_id)
      set_start_report(false)
    })
    .catch((error) => {
      set_report_build_error(error)
      set_start_report(false)
      set_show_spinner(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_report, search_results])

  if (report_id) {
    return (
      <Redirect to={`/report/${report_id}`} />
    )
  }

  return (
    <ContainerFullWidth>
      {show_spinner &&
        <div className='text-center mt-3'>
          <Spinner/>
          <div>Fetching similar families...</div>
        </div>
      }

      {search_error &&
        <ErrorBody
          error={search_error}
          context={'searching for similar families'}
        />
      }

      {report_build_error &&
        <ErrorBody
          error={report_build_error}
          context={'building a report'}
        />
      }
    </ContainerFullWidth>
  )
}

export default withRouter(withUser(SimilarFamiliesReportBuilder))