import { has_utt } from '../utils/user_permissions.js'
import { DEFAULT_UTT_VERSION, UTT_VERSION_IDS } from './utt_version.js'

export const NEGATIVES_PROCESSING_EXCLUDE = 'exclude'
export const NEGATIVES_PROCESSING_INCLUDE = 'include'
export const NEGATIVES_PROCESSING_CLUSTER = 'cluster'
export const NEGATIVES_PROCESSING_UTT = 'utt'

export const NEGATIVES_PROCESSING_OPTIONS = [
  {
    id: NEGATIVES_PROCESSING_EXCLUDE,
    label: 'Exclude'
  },
  {
    id: NEGATIVES_PROCESSING_INCLUDE,
    label: 'Include as "unrelated"'
  },
  {
    id: NEGATIVES_PROCESSING_CLUSTER,
    label: 'Cluster',
    check_if_available: ({is_nd_report}) => !is_nd_report
  },
  {
    id: NEGATIVES_PROCESSING_UTT,
    label: 'Apply UTT',
    check_if_available: ({user, is_nd_report}) => has_utt(user) && !is_nd_report
  }
]

function get_negatives_processing_by_utt(utt_version) {
  const can_use_version_id = utt_version && UTT_VERSION_IDS.indexOf(utt_version) !== -1
  const version_id = can_use_version_id ? utt_version : DEFAULT_UTT_VERSION

  return {
    type: NEGATIVES_PROCESSING_UTT,
    use_superclasses: false,
    version_id
  }
}

export function get_negatives_processing({type, utt_version=DEFAULT_UTT_VERSION}) {
  if (!type) return null

  if (type === NEGATIVES_PROCESSING_UTT) {
    return get_negatives_processing_by_utt(utt_version)
  }

  return {type}
}