import React from 'react'
import * as d3 from 'd3'

import {
  get_items_filtered_by_company_and_years, sum_item_values
} from './utils/executive_summary_utils.js'
import SvgTitle from './svg/SvgTitle.js'
import {
  BASE_COLOUR,
  DARK_GREY,
  EXECUTIVE_SUMMARY_COLOURS,
  LIGHT_GREY,
  NUM_COMPANIES
} from './utils/executive_summary_constants.js'

const TITLE = 'Competitive landscape'

// internetexplorer does not have Math.log10, so if required add a polyfill
const log10 = Math.log10 || function (x) { return Math.log(x) / Math.LN10 }

function get_size_extent(portfolio_size) {
  return d3.extent(portfolio_size, function (d) { return d.size })
}

function get_growth_extent(portfolio_size) {
  return d3.extent(portfolio_size, function (d) { return d.growth })
}

function get_ticks_covering(ticks, min, max) {
  if (ticks.length < 2) {
    return ticks // hopefully this should never be the case but...
  }

  const min_tick         = ticks[0]
  const max_tick         = ticks[ticks.length - 1]
  const penultimate_tick = ticks[ticks.length - 2]

  const tick_distance = max_tick - penultimate_tick

  if (max_tick < max) {
    ticks = [...ticks, (max_tick + tick_distance)]
  }

  if (min_tick > min) {
    ticks = [(min_tick - tick_distance), ...ticks]
  }

  return ticks
}

class ExecutiveSummaryLandscape extends React.Component{
  constructor(props) {
    super(props)
    this.container_ref = React.createRef()
  }

  componentDidMount() {
    const companies                    = this.props.companies,
          portfolio                    = this.props.portfolio,
          years                        = this.props.years,
          containerWidth               = this.props.width || 300,
          containerHeight              = this.props.height || 300

    const margin = {top: 20, right: 20, bottom: 20, left: 22}
    const graphWidth = containerWidth - margin.left - margin.right
    const graphHeight = containerHeight - margin.top - margin.bottom
    const padding_vertical = 10
    const padding_horizontal = 20
    const tickNumber = 5

    const portfolio_size = this.get_portfolio_data(companies, portfolio, years.fromYear, years.toYear-1)

    // Get extent (max, min) of data
    let portfolio_size_extent   = get_size_extent(portfolio_size)
    const portfolio_growth_extent = get_growth_extent(portfolio_size)

    // Handle zero size values (as log(0) is infinity)
    portfolio_size_extent = portfolio_size_extent.map(function(d) {
      return (d <= 0) ? 0.95 : d
    })

    // Get x domain values
    const min_portfolio_size = portfolio_size_extent[0]
    const max_portfolio_size = portfolio_size_extent[1]
    const log_x_min = Math.floor(log10(min_portfolio_size))
    const log_x_max = Math.ceil(log10(max_portfolio_size))
    const x_min = Math.pow(10, log_x_min)
    const x_max = Math.pow(10, log_x_max)
    const x_tick_values = d3.range(log_x_min, log_x_max + 1).map(function(i) { return Math.pow(10, i) })

    // Get y domain values
    let y_min = portfolio_growth_extent[0]
    const y_max = portfolio_growth_extent[1]
    y_min = Math.min(y_min, 0) // make sure we always show 0

    const x = d3.scaleLog()
                .domain([x_min, x_max])
                .clamp(true)
                .range([0, graphWidth])

    const y = d3.scaleLinear()
                .domain([y_min, y_max])
                .range([graphHeight, 0])

    // Get tick values (as generated by d3)
    const ticks_x = x.ticks(tickNumber)
    var ticks_y = y.ticks(tickNumber)
    if (!ticks_y.length) {
      // This can happen when all growth is 0, giving no y-axis ticks.
      // The downstream rendering code assumes there are some ticks
      // (and so will generate invalid SVG, and break the render and the pdf download).
      // So we add some reasonable ticks here.
      ticks_y = [-50, 0, 50]
    }

    // Adjust y-ticks (to ensure the values fall within the gridlines)
    ticks_y = get_ticks_covering(ticks_y, y_min, y_max)

    // Reset domains based on the ticks
    const y_domain = d3.extent(ticks_y)
    y.domain(y_domain)
    const x_domain = d3.extent(ticks_x)
    x.domain(x_domain)

    const X_FORMAT = d3.format(',.1s')

    const xAxis = d3.axisBottom(x)
                    .tickSize(-graphHeight - padding_vertical)
                    .ticks(tickNumber, function(d) {return (d >=1000) ? X_FORMAT(d) : d})

    const yAxis = d3.axisLeft(y)
                    .tickSize(-graphWidth - padding_horizontal)
                    .ticks(tickNumber)
                    .tickValues(ticks_y)
                    .tickFormat(function(d) {
                      const sign = (d>0) ? '+' : ''
                      return sign+d
                    })

    const container_node = this.container_ref.current
    const container = d3.select(container_node)

    const chart = container
                .append('g')
                .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      chart.append('g')
          .attr('class', 'xaxe axis')
          .attr('transform', 'translate(' + padding_horizontal + ',' + (graphHeight + padding_vertical) + ')')
          .call(xAxis)
          .selectAll('.tick line').attr('stroke', DARK_GREY)
          .append('text')
          .attr('class', 'label')
          .attr('transform', 'translate('+(graphWidth/2) +','+25+')')
          .text('Portfolio size')
          .style('text-anchor', 'middle')
          .style('fill', BASE_COLOUR)

    chart.append('g')
         .attr('class', 'yaxe axis')
         .call(yAxis)
        .selectAll('.tick line').attr('stroke', DARK_GREY)
         .append('text')
         .attr('class', 'label')
         .attr('transform', 'translate(-28,'+(graphHeight/2)+')rotate(-90)')
         .style('text-anchor', 'middle')
         .text('3 year growth (%)')
         .style('fill', BASE_COLOUR)

    chart.selectAll('circle')
         .data(portfolio_size)
         .enter()
         .append('circle')
         .attr('class', 'circle')
         .attr('cx', function (d) {return x(d.size)})
         .attr('cy', function (d) {return y(d.growth)})
         .attr('r', 13)
         .attr('transform', 'translate(' + padding_horizontal + ',0)')
         .style('fill', function(d) {return EXECUTIVE_SUMMARY_COLOURS[d.id]})

    const grid_color_base = LIGHT_GREY
    const grid_color_exposed = DARK_GREY

    const axis = container.selectAll('.axis')
    const yaxe = container.selectAll('.yaxe')

    axis.selectAll('.domain').remove()

    axis.selectAll('.label').style({'font-weight': 'normal'})
    yaxe.selectAll('.tick').style({'stroke': grid_color_exposed})
    axis.selectAll('.tick').selectAll('text').style({'font-size': '10px', 'font-weight': 'normal', 'fill': BASE_COLOUR, 'stroke': 'none', 'font-family': 'Source Sans Pro, Calibri, sans-serif'})

    container.selectAll('.xaxe').selectAll('.tick').style('stroke', function(d){
      return (x_tick_values.indexOf(d) > -1) ? grid_color_exposed : grid_color_base
    })

  }

  get_company_portfolio_size_per_year(portfolio_items, company_id, year) {
    const filtered_items = get_items_filtered_by_company_and_years(portfolio_items, [company_id], [year])
    return sum_item_values(filtered_items)
  }

  get_growth_percentage(from, to) {
    if (from === 0) {
      if (to === 0) return 0
      if (to > 0) return 301
    }

    //different formula for decrease ??

    return ((to - from) / from) * 100
  }

  get_portfolio_data(companies, portfolio_size, start_year, end_year) {
    const from_year = '' + start_year
    const to_year = '' + end_year

    let company_ids = []

    d3.range(NUM_COMPANIES).forEach(function(d, i) {
      if (companies[i] !== undefined) {
        company_ids.push(companies[i].id);
      }
    })

    const filtered_portfolio_size = get_items_filtered_by_company_and_years(portfolio_size, company_ids, [from_year, to_year])

    const portfolio_data = company_ids.map(function(d, i) {
      let portfolio_item = {}
      const new_portfolio_size = this.get_company_portfolio_size_per_year(filtered_portfolio_size, d, to_year)
      const old_portfolio_size = this.get_company_portfolio_size_per_year(filtered_portfolio_size, d, from_year)
      portfolio_item['name'] = companies[i].name
      portfolio_item['size'] = new_portfolio_size
      portfolio_item['prevSize'] = old_portfolio_size
      portfolio_item['growth'] = this.get_growth_percentage(old_portfolio_size, new_portfolio_size)
      portfolio_item['id'] = i

      return portfolio_item

    }.bind(this))

    return portfolio_data
  }

  render() {
    return (
    <g>
      <g transform='translate(210,0)'><SvgTitle label={TITLE} colour={BASE_COLOUR} allow_overflow={true} /></g>
      <g transform='translate(15,30)' ref={this.container_ref}></g>
    </g>
    )
  }
}

export default ExecutiveSummaryLandscape
