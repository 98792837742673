import { min } from 'd3'

import { DEFAULT_RECENT_LAST_FEW_YEARS_COUNT } from '../model/time_ranges.js'
import {ID_TO_TIME_RANGE_FIELD} from '../model/time_range_filter_fields.js'

// All "created_at" inputs are ISO strings ("2019-01-02T18:08:34.000Z") or seconds since 1970 (1544719274577).

// All extents here are from-inclusive, to-exclusive.
// i.e. [from=2016, to=2019] means [2016, 2017, 2018]

/**
 * @param created_at ISO time string, representing when the report was created.
 *           i.e. "2018-09-10T15:29:09.000Z"
 * @returns integer i.e 2018
 */
export function get_current_year(created_at) {
  return new Date(created_at).getFullYear()
}

export function get_created_month(created_at) {
  return new Date(created_at).getMonth()
}

export function get_year_10_years_ago(created_at) {
  const current_year = get_current_year(created_at)
  return current_year - 10
}

export function get_last_n_years_extent(created_at, n) {
  const current_year = get_current_year(created_at)
  return [current_year - n, current_year + 1] // i.e. suppose current_year=2018 and n=10, then 11 years including current, [2008, 2019]
}

export function get_last_n_years_by_month_created_extent(created_at, n) {
  const current_year = get_current_year_by_month_created(created_at)
  return [current_year - n, current_year + 1]
}

export function get_last_10_years_extent(created_at) {
  return get_last_n_years_extent(created_at, 10)
}

export function get_next_20_years(created_at) {
  const current_year = get_current_year(created_at)
  return [current_year, current_year + 20]
}

export function get_next_10_years_exclusive_extent(created_at) {
  // forecast excluding the current year (with costs it looks weird/ wrong)
  const current_year = get_current_year(created_at)
  return [current_year + 1, current_year + 11]
}

export function get_since_2000_extent(created_at) {
  const current_year = get_current_year(created_at)
  return [2000, current_year + 1] // i.e. suppose current_year=2018, then [2000, 2001, .... 2019]
}

export function get_last_10_years_by_month_created_extent(created_at) {
  const current_year = get_current_year_by_month_created(created_at)
  return [current_year - 10, current_year + 1] // i.e. suppose current_year=2018, then 11 years including current, [2008, 2019]
}

export function get_since_2000_by_month_created_extent(created_at) {
  const current_year = get_current_year_by_month_created(created_at)
  return [2000, current_year + 1] // i.e. suppose current_year=2018, then [2000, 2001, .... 2019]
}

function get_current_year_by_month_created(created_at) {
  //used only for growth treemap currently
  //calculates current year by year_created but also looks at month
  //if report created before July, there's not enough data to compare size changes
  //so it returns previous year

  const current_year = get_current_year(created_at)
  const month = get_created_month(created_at)
  const year = (month > 5) ? current_year : current_year - 1

  return year
}

export function get_since_1980_extent(created_at) {
  const current_year = get_current_year(created_at)
  return [1980, current_year + 1] // i.e. suppose current_year=2018, then [1980, 1981, .... 2019]
}

export function get_last_few_full_years_extent(created_at) {
  const current_year = get_current_year(created_at)
  return [current_year - DEFAULT_RECENT_LAST_FEW_YEARS_COUNT, current_year] // i.e. don't include current year
                                          // i.e. suppose current_year=2018, then [2015, 2018] (2015, 2016, 2017)
}

export function get_extent_from_years_column_incl_last_10_years(years_column, created_at) {
  // last 10 years
  const [from, to] = get_last_10_years_extent(created_at) // to-exclusive

  // earliest year from data
  const min_year_from_data = min(years_column) // if no data, will be null

  // pull back the "from" as far as data goes
  const from_full = (min_year_from_data != null) ? Math.min(from, min_year_from_data) : from

  return [from_full, to]
}

export function get_extent_from_key_items(time_items) {
  // Assumes items are sorted
  const min = time_items[0].id           // first
  const max = time_items.slice(-1)[0].id // last
  return [min, max + 1]
}

/**
 * @param {} extent is [from_inclusive, to_exlusive] i.e. [2013, 2016] means 2013, 2014, 2015
 * @returns {} report reader clause, which is to_inclusive i.e "2013 2015" means 2013, 2014, 2015
 */
export function get_extent_as_report_reader_clause(extent) {
  const [from, to] = extent
  return [from, to - 1].join(' ')
}

export function prepare_date_filter_for_query(selected_timerange) {
  const {field_id, timerange} = selected_timerange

  const {date_type} = ID_TO_TIME_RANGE_FIELD[field_id]

  const [start_date, end_date] = timerange

  return [{
    date_type,
    start_date,
    end_date
  }]
}

function get_selected_timerange({spec, item, data_creation_date}) {
  if (item && item.timerange) {
    return item.timerange
  }
  const { timeseries_key_dim_idxs, get_default_selected_timerange } = spec
  if (!timeseries_key_dim_idxs) {
    return get_default_selected_timerange(data_creation_date)
  }
}

export function get_timerange({spec, item, key_dims, data_creation_date}) {
  const selected_timerange = get_selected_timerange({spec, item, data_creation_date})
  if (selected_timerange) {
    return [selected_timerange[0], selected_timerange[1]-1]
  }
  const { timeseries_key_dim_idxs } = spec
  const y_items = key_dims[timeseries_key_dim_idxs]
  const years = y_items.map(item => item.id)
  return [Math.min(...years), Math.max(...years)]
}