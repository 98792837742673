import React, { Component } from 'react'

import SvgText from './svg/SvgText.js'
import SvgTitle from './svg/SvgTitle.js'
import CipherBall from './svg/CipherBall.js'
import MiniPipeChart from './charts/MiniPipeChart.js'
import {
  CIPHER_BALL_OFFSET_X,
  CIPHER_BALL_OFFSET_Y,
  CONNECTOR_DX_1, CONNECTOR_DX_2, CONNECTOR_DY,
  get_geography_level,
  get_litigation_level,
  get_mean_spend_level,
  get_size_level,
  get_total_litigations_per_company,
  LABELLED_PIPE_HEIGHT, PIPE_DIAMETER, PIPE_TITLE_MARGIN,
  RIGHT_COLUMN_OFFSET, STROKE_WIDTH,
  TITLE_HEIGHT
} from './utils/executive_summary_summaries_utils.js'
import { BASE_COLOUR, NUM_COMPANIES } from './utils/executive_summary_constants.js'

export function get_labelled_pipes_as_column(items) {
  return items.map(function(item, i) {
    const y_pos = i * LABELLED_PIPE_HEIGHT
    return (
      <g transform={'translate(0, ' + y_pos + ')'} key={i}>
        {item}
      </g>
    )
  })
}

class ConnectorLine extends React.Component {
  render() {
    const { direction } = this.props

    switch(direction) {
      case 'right_down':
        return (
          <g>
            <line
              x1={PIPE_DIAMETER}
              y1={PIPE_DIAMETER / 2}
              x2={PIPE_DIAMETER + CONNECTOR_DX_1}
              y2={PIPE_DIAMETER / 2}
              stroke={BASE_COLOUR}
              strokeWidth={STROKE_WIDTH}
            />
            <line
              x1={PIPE_DIAMETER + CONNECTOR_DX_1}
              y1={PIPE_DIAMETER / 2}
              x2={PIPE_DIAMETER + CONNECTOR_DX_1 + CONNECTOR_DX_2}
              y2={PIPE_DIAMETER / 2 + CONNECTOR_DY}
              stroke={BASE_COLOUR}
              strokeWidth={STROKE_WIDTH}
            />
          </g>
        )
      case 'right':
        return (
          <line
            x1={PIPE_DIAMETER}
            y1={PIPE_DIAMETER / 2}
            x2={PIPE_DIAMETER + CONNECTOR_DX_1 + CONNECTOR_DX_2}
            y2={PIPE_DIAMETER / 2}
            stroke={BASE_COLOUR}
            strokeWidth={STROKE_WIDTH}
          />
        )
      case 'right_up':
        return (
          <g>
            <line
              x1={PIPE_DIAMETER}
              y1={PIPE_DIAMETER / 2}
              x2={PIPE_DIAMETER + CONNECTOR_DX_1}
              y2={PIPE_DIAMETER / 2}
              stroke={BASE_COLOUR}
              strokeWidth={STROKE_WIDTH}
            />
            <line
              x1={PIPE_DIAMETER + CONNECTOR_DX_1}
              y1={PIPE_DIAMETER / 2}
              x2={PIPE_DIAMETER + CONNECTOR_DX_1 + CONNECTOR_DX_2}
              y2={PIPE_DIAMETER / 2 - CONNECTOR_DY}
              stroke={BASE_COLOUR}
              strokeWidth={STROKE_WIDTH}
            />
          </g>
        )
      case 'left_down':
        return (
          <g>
            <line
              x1={0}
              y1={PIPE_DIAMETER / 2}
              x2={-CONNECTOR_DX_1}
              y2={PIPE_DIAMETER / 2}
              stroke={BASE_COLOUR}
              strokeWidth={STROKE_WIDTH}
            />
            <line
              x1={-CONNECTOR_DX_1}
              y1={PIPE_DIAMETER / 2}
              x2={-CONNECTOR_DX_1 - CONNECTOR_DX_2}
              y2={PIPE_DIAMETER / 2 + CONNECTOR_DY}
              stroke={BASE_COLOUR}
              strokeWidth={STROKE_WIDTH}
            />
          </g>
        )
      case 'left':
        return (
          <line
            x1={0}
            y1={PIPE_DIAMETER / 2}
            x2={-CONNECTOR_DX_1 - CONNECTOR_DX_2}
            y2={PIPE_DIAMETER / 2}
            stroke={BASE_COLOUR}
            strokeWidth={STROKE_WIDTH}
          />
        )
      case 'left_up':
        return (
          <g>
            <line
              x1={0}
              y1={PIPE_DIAMETER / 2}
              x2={-CONNECTOR_DX_1}
              y2={PIPE_DIAMETER / 2}
              stroke={BASE_COLOUR}
              strokeWidth={STROKE_WIDTH}
            />
            <line
              x1={-CONNECTOR_DX_1}
              y1={PIPE_DIAMETER / 2}
              x2={-CONNECTOR_DX_1 - CONNECTOR_DX_2}
              y2={PIPE_DIAMETER / 2 - CONNECTOR_DY}
              stroke={BASE_COLOUR}
              strokeWidth={STROKE_WIDTH}
            />
          </g>
        )
      default: break
    }

    return (
      <line
        y1={PIPE_DIAMETER / 2}
        x2={PIPE_DIAMETER + 200}
        y2={PIPE_DIAMETER / 2}
        stroke={BASE_COLOUR}
        strokeWidth={STROKE_WIDTH}
      />
    )
  }
}

class LabelledPipe extends React.Component{
  render () {
    // var connector_direction = this.props.connector_direction

    const {connector_direction, value, title, title2, align_right } = this.props

    return (
      <g>
        <ConnectorLine
          direction={connector_direction}
        />

        <MiniPipeChart
          width={PIPE_DIAMETER}
          height={PIPE_DIAMETER}
          value={value}
        />
        <g transform={'translate(0,' + (PIPE_DIAMETER + PIPE_TITLE_MARGIN) + ')'}>
          <SvgText
            width={PIPE_DIAMETER}
            label={title}
            align_right={align_right}
            allow_overflow={true}
            colour={BASE_COLOUR}
          />
        </g>
        <g transform={'translate(0,' + (PIPE_DIAMETER + PIPE_TITLE_MARGIN + 12) + ')'}>
          <SvgText
            width={PIPE_DIAMETER}
            label={title2}
            align_right={align_right}
            allow_overflow={true}
            colour={BASE_COLOUR}
          />
        </g>

      </g>
    )
  }
}

class ExecutiveSummarySummaries extends Component{
  render() {
    const data                     = this.props.data,
          companies                = this.props.data.companies,
          current_year             = this.props.data.report_year

    const selected_companies = companies.slice(0, NUM_COMPANIES)

    // Extract litigations for the selected companies
    const selected_years = [current_year - 10, current_year] // excludes current year
    const defensive_litigations = get_total_litigations_per_company(data.defendant_lit_by_porfolio, selected_companies , selected_years)
    const offensive_litigations = get_total_litigations_per_company(data.plaintiff_lit_by_porfolio, selected_companies, selected_years)

    // Get pipe levels
    const granted_size_level         = get_size_level(data.granted_size_by_portfolio, selected_companies, current_year)
    const application_size_level     = get_size_level(data.application_size_by_portfolio, selected_companies, current_year)
    const geography_level            = get_geography_level(data.grants_by_country_by_portfolio, selected_companies)
    const offensive_litigation_level = get_litigation_level(offensive_litigations)
    const defensive_litigation_level = get_litigation_level(defensive_litigations)
    const mean_spend_level           = get_mean_spend_level(data.average_cost_by_portfolio)

    const left_column_pipes = [
      <LabelledPipe
        key={0}
        value={granted_size_level}
        title={'Portfolio'}
        connector_direction={'right_down'}
      />,
      <LabelledPipe
        key={1}
        value={geography_level}
        title={'Geography'}
        connector_direction={'right'}
      />,
      <LabelledPipe
        key={2}
        value={offensive_litigation_level}
        title={'Offensive'}
        title2={'litigation'}
        connector_direction={'right_up'}
      />
    ]

    const right_column_pipes = [
      <LabelledPipe
        key={0}
        value={application_size_level}
        title={'Pipeline'}
        align_right={true}
        connector_direction={'left_down'}
      />,
      <LabelledPipe
        key={1}
        value={mean_spend_level || 0}
        title={'Expenditure'}
        align_right={true}
        connector_direction={'left'}
      />,
      <LabelledPipe
        key={2}
        value={defensive_litigation_level}
        title={'Defensive'}
        title2={'litigation'}
        align_right={true}
        connector_direction={'left_up'}
      />
    ]

    const left_column  = get_labelled_pipes_as_column(left_column_pipes)
    const right_column = get_labelled_pipes_as_column(right_column_pipes)

    function get_target_short_name() {
      return companies[0].short_name
    }

    const label = 'Summary for ' + get_target_short_name()

    return (
      <g>
        <g>
          <SvgTitle
            label={label}
            colour={BASE_COLOUR}
            allow_overflow={true}
          />
        </g>
        <g transform={'translate(0,' + TITLE_HEIGHT + ')'}>
          <g>
            {left_column}
          </g>
          <g transform={'translate(' + RIGHT_COLUMN_OFFSET + ',0)'}>
            {right_column}
          </g>
          <g transform={'translate(' + CIPHER_BALL_OFFSET_X + ',' + CIPHER_BALL_OFFSET_Y + ')'}>
            <CipherBall />
          </g>
        </g>
      </g>

    )
  }
}

export default ExecutiveSummarySummaries
