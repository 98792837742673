import React from 'react'
import cn from 'classnames'

import FamilyId from './FamilyId.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { ExclamationMarkIcon } from '../widgets/IconSet.js'

import s from './FamilyIdDisplay.module.scss'

export const FamilyIdDisplay = (
  {
    family,
    selected_patent_number,
    alternative_family,
    on_show_alternative,
    is_missing,
    is_deprecated,

    report_type,
    eval_training_set_id,
    eval_classifier_data,

    className,
}) => {

  const {patFamId: family_id } = family || {}
  const {patFamId: alternative_id} = alternative_family || {}

  const other_props = {
    is_missing,
    report_type,
    eval_training_set_id,
    eval_classifier_data
  }

  const has_alternative = (alternative_id != null) && (alternative_id !== 0)

  return (
    <div className={cn('d-flex', className)} key={`family_id_${family_id}`}>
      <FamilyId
        family_id={family_id}
        selected_patent_number={selected_patent_number}
        display_as_text_only={true}
        display_as_linkable_icon={true}
        show_similar_families_search={!is_deprecated}
        {...other_props}
      />

      {has_alternative && !is_deprecated &&
        <InfoPopover
          is_in_modal={true}
          interactive={true}
          placement='bottom'

          toggler={<span className={cn(s.alternative_warn_icon)} title='This family is a replacement'><ExclamationMarkIcon /></span>}
          buttonClassName='ms-2 my-auto'
        >

          <div className='mb-2'>
            <span>This family replaces deprecated family</span>
          </div>
          <FamilyId
            family_id={alternative_id}
            display_as_linkable_text={true}
            on_family_id_click={on_show_alternative}
            show_similar_families_search={false}
            {...other_props}
          />
        </InfoPopover>
      }

    </div>
  )
}
