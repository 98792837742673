import React, { useState, useEffect } from 'react'

import ErrorBody from './ErrorBody.js'
import ErrorModal from './ErrorModal.js'

import { withUser } from './UserContext.js'
import { is_admin } from '../utils/user_permissions.js'
import { rerun_report_and_replace_id } from '../utils/report_builder_utils.js'
import { extract_failed_report_status_from_error } from '../utils/report_progress_utils.js'
import { track_report_builder_event } from '../utils/tracking_utils.js'
import { is_not_found } from '../utils/axios_utils.js'
import { fetch_report_input } from '../utils/report_reader_utils.js'
import { send_error_to_sentry } from '../utils/sentry_utils.js'


const ErrorFetchingReportData = ({user, error_fetching_report_data, internal_report_id, external_report_id, created_by_user}) => {

  const [is_retrying, set_is_retrying] = useState(false)
  const [error_on_retry, set_error_on_retry] = useState(null)
  const [report_input_exists, set_report_input_exists] = useState(false)

  useEffect(() => {
    // did input get written before this report failed?
    fetch_report_input(internal_report_id)
      .then(() => set_report_input_exists(true))
      .catch(error => {
        if (!is_not_found(error)) {
          // don't bombard the user with further error messages
          send_error_to_sentry(error, {})
        }
      })
  }, [internal_report_id])

  // if report building has failed, it should be possible to extract the choreo status
  const failed_report_status_from_choreo = extract_failed_report_status_from_error(error_fetching_report_data)
  // only internal users can rerun reports belonging to others
  const user_has_rerun_permissions = created_by_user || is_admin(user)
  // additionally, retry is only available if the choreo/ report-writer has written the report input
  const can_rerun_failed_report = failed_report_status_from_choreo && report_input_exists && user_has_rerun_permissions

  function retry_failed_report() {

    track_report_builder_event('action="retry_failed_report" context="viewer"')
    set_is_retrying(true)

    rerun_report_and_replace_id(internal_report_id, external_report_id)
      .catch(error => {
        set_is_retrying(false)
        set_error_on_retry(error)
      })
      .then(() => {
        // refresh to show progress of rerunning report
        window.location.reload()
      })
  }

  return (
    <>
      <ErrorBody
        error={error_fetching_report_data}
        context={failed_report_status_from_choreo ? 'building this report' : 'fetching initial report data'}
        on_retry={can_rerun_failed_report ? retry_failed_report : null}
        is_retrying={is_retrying}
      />
      { error_on_retry &&
        <ErrorModal
          on_hide={() => set_error_on_retry(null)}
          error={error_on_retry}
          context={`retrying this failed report ${is_not_found(error_on_retry) ? '. Input selections could not be found for the report' : ''}`}
        />
      }
    </>
  )
}

export default withUser(ErrorFetchingReportData)