import { save_report_user_state_to_db } from './report_history_utils.js'
import { CLASSIFIER_EVAL_PRESET } from '../model/chart_sets.js'

export function save_report_state(external_report_id, report_state) {
  return save_report_user_state_to_db(external_report_id, report_state)
    .then(() => {
      return external_report_id
    })
}

export function save_eval_report_selected_charts_in_state(external_report_id) {
  const {items: chart_set_items} = CLASSIFIER_EVAL_PRESET || {}
  const main_items = (chart_set_items || []).map(item => ({...item, is_temp: false}))
  return save_report_state(external_report_id, {main_items})
}