import { get_as_map, format_integer_with_comma, format_integer, number_format } from '../utils/utils.js'

export const GBP = 'GBP'
export const USD = 'USD'
export const EUR = 'EUR'
export const CAD = 'CAD'
export const AUD = 'AUD'

export const CURRENCIES = [
  { id: GBP,  name: GBP,  symbol: '£' ,    short_symbol: '£', cost_currency: true  },
  { id: USD,  name: USD,  symbol: 'US $',  short_symbol: '$', cost_currency: true  },
  { id: EUR,  name: EUR,  symbol: '€',     short_symbol: '€', cost_currency: true  },
  { id: CAD,  name: CAD,  symbol: 'CAD $', short_symbol: '$', cost_currency: false },
  { id: AUD,  name: AUD,  symbol: 'AUD $', short_symbol: '$', cost_currency: false }
];

export const ID_TO_CURRENCY = get_as_map(CURRENCIES, 'id')


export const USD_ITEM = ID_TO_CURRENCY[USD]

export const DOLLAR_PREFIX = USD_ITEM.short_symbol

export function get_long_currency_value_formatter(currency) {
  return (d) => {
    if (d === undefined) {
      return d
    }
    return currency.short_symbol + format_integer_with_comma(d)
  }
}

export function get_long_currency_no_commas_value_formatter(currency) {
  return (d) => {
    if (d === undefined) {
      return d
    }
    return currency.short_symbol + format_integer(d)
  }
}

export function get_short_currency_value_formatter(currency) {
  return (d) => {
    var currency_symbol = currency.short_symbol

    if (d === undefined) {
      return d;
    }

    if (d >= 1000000000) {
      return currency_symbol + number_format(d / 1000000000, 1, ".", ",") + "bn"
    }

    if (d >= 1000000) {
      return currency_symbol + number_format(d / 1000000, 1, ".", ",") + "m"
    }

    if (d >= 1000) {
      return currency_symbol + format_integer_with_comma(d / 1000) + "k"
    }

    return currency_symbol + format_integer_with_comma(d)
  }
}
