import { reset_user, set_user, track_page_visit } from './tracking_utils.js'
import { initialise_pendo, prepare_pathname_for_pendo } from './pendo_utils.js'
import { get_cipher_hostname } from './utils.js'
import { get_document_title, prepare_pathname_for_tracking } from './tracking_hashed_id_utils.js'

export function initialise_user_stats(user_id, user_group) {
  set_user(user_id, user_group || '')
  initialise_pendo(user_id, user_group || '')
}

export function prepare_url_for_stats(history) {

  const { location } = history || {}
  const { pathname } = location || {}

  const custom_url = `${get_cipher_hostname()}${prepare_pathname_for_tracking(pathname)}`
  const page_title = get_document_title(pathname)
  track_page_visit(custom_url, page_title)
  prepare_pathname_for_pendo(custom_url)
}

export function reset_user_for_stats() {
  reset_user()
}