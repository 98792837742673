import React, { Suspense, lazy } from 'react'
import { lazy_with_retry } from '../../utils/react_utils.js'

import Spinner from '../widgets/Spinner.js'

// NOTE: React-plotly gigantic, even our cut-down version (see CustomPlot.js).
// This causes our vendor bundle sourcemap to be too large for Sentry.
// So we lazy load the module here to keep it separate to the vendor bundle.
//
// To analyse the bundle size:
//
// 1. yarn build (shows bundle sizes at end)
//
// 2. yarn source-map-explorer <path_to_bundle>
//      i.e. yarn source-map-explorer build/static/js/5.7526bd83.chunk.js
//      this shows a treemap of the contents of a bundle

// Lazy load (forces Plotly stuff into its own bundle).
const CustomPlot = lazy(() => lazy_with_retry(() => import('./CustomPlot.js')))

const CustomPlotLazy = (props) => {
  return (
    <Suspense
      fallback={<div><Spinner /></div>}
    >
      <CustomPlot {...props} />
    </Suspense>
  )
}

export default CustomPlotLazy