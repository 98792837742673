/*
ref: http://www.rikai.com/library/kanjitables/kanji_codes.unicode.shtml
Unicode character ranges used in Japanese text are:
3000-303f = Japanese-style punctuation
3040-309f = Hiragana
30a0-30ff = Katakana
ff00-ff9f = Full-width Roman characters and half-width Katakana
4e00-9faf = CJK unified ideographs - Common and uncommon Kanji
3400-4dbf = CJK unified ideographs Extension A - Rare Kanji
 */
const JAPANESE_CHARS_REGEX = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/ugi

// ref: https://www.loc.gov/marc/specifications/specchareacc/KoreanHangul.html
const KOREAN_CHARS_REGEX = /[\u3131-\uD79D]/ugi

function has_japanese_characters(search_string) {
  return !!search_string.match(JAPANESE_CHARS_REGEX)
}

function has_korean_characters(search_string) {
  return !!search_string.match(KOREAN_CHARS_REGEX)
}

export function has_japanese_or_korean_characters(search_string) {
  return has_japanese_characters(search_string) || has_korean_characters(search_string)
}