import { TECHNOLOGY_AREA_ID } from '../model/technologies.js'
import { PORTFOLIO_ID } from '../model/portfolios.js'
import { CONTINENT_ID_TO_COUNTRY_IDS, EUROPE_CODE, ID_TO_CONTINENT, ID_TO_COUNTRY } from '../constants/countries.js'

const COUNTRY_CODES = ['US', 'JP', 'KR', 'CN']
const EUROPE_COUNTRY_CODES = CONTINENT_ID_TO_COUNTRY_IDS[EUROPE_CODE]

function get_filter_geos() {

  const geos = []
  COUNTRY_CODES.forEach(code => {
    geos.push({
      id: code,
      country_codes: [code],
      name: ID_TO_COUNTRY[code].name
    })
  })

  geos.push({
    id: EUROPE_CODE,
    country_codes: EUROPE_COUNTRY_CODES,
    name: ID_TO_CONTINENT[EUROPE_CODE].name
  })

  return geos
}

export const FILTER_GEOS = get_filter_geos()
export const ALL_FILTER_COUNTRIES = [...EUROPE_COUNTRY_CODES, ...COUNTRY_CODES]

export function add_or_remove_selected_item(item_id, selected_ids, on_change_handler, is_exclusive_select=false) {
  let updated_selected_ids = []

  if (selected_ids.indexOf(item_id) > -1) {
    updated_selected_ids = selected_ids.filter(id => id !== item_id)
  } else if (is_exclusive_select) {
    updated_selected_ids = [item_id]
  } else {
    updated_selected_ids = [...selected_ids, item_id].sort()
  }

  on_change_handler(updated_selected_ids)
}

export function is_technology_area_id(key) {
  return key.endsWith(TECHNOLOGY_AREA_ID)
}

export function is_portfolio_id(key) {
  return key.endsWith(PORTFOLIO_ID)
}