import {
  AGE_AT_EXPIRY_BY_PORTFOLIO_ID, AGE_AT_EXPIRY_BY_TECH_ID,
  PERCENTAGE_EXPIRED_BY_AGE_BY_PORTFOLIO_ID,
  EXPIRING_BY_YEAR_BY_ORGANISATION_ID, EXPIRING_BY_YEAR_BY_TECH_ID,
  GRANTED_FAMILIES_BY_GRANT_YEAR_BY_PORTFOLIO_ID, GRANTED_FAMILIES_BY_GRANT_YEAR_BY_TECHNOLOGY_ID,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_ID,
  GRANTS_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID, GRANTS_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
  GRANTS_BY_PRIORITY_YEAR_ID,
  PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
  PENDING_FAMILIES_BY_PRIORITY_YEAR_ID
} from '../spec_ids.js'
import {
  TAG_EXPIRED,
  TAG_GRANTED,
  TAG_ORG,
  TAG_PENDING,
  TAG_TECH,
  TAG_TIME
} from './spec_tags.js'
import {
  KEY_GRANT_YEAR,
  KEY_ORGANISATION,
  KEY_PRIORITY_YEAR,
  KEY_TECHNOLOGY
} from './spec_keys.js'
import { AGE_GROUP_ID } from './spec_group_ids.js'
import {
  DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
  DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS
} from '../views.js'
import { LINE_ID, COLUMN_CLUSTER_ID, TABLE_ID } from '../view_ids.js'
import { not_available } from '../../utils/data_availability_utils.js'

const HELP_ARTICLE = '28889092401683-Age-charts-datasets'

export const AGE_GROUP =   {
  id: AGE_GROUP_ID,
  name: 'Age',
  short_name: 'Age',
  help_article: HELP_ARTICLE,
  children: [
    {
      spec_id: PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
      name: 'pending families',
      description: 'Priority year of pending families, by organisation.',
      help_article: `${HELP_ARTICLE}#h_b7b7c426e3`,
      by: [KEY_ORGANISATION, KEY_PRIORITY_YEAR],
      tags: [TAG_PENDING, TAG_ORG, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
      name: 'pending families',
      description: 'Priority year of pending families, by technology.',
      help_article: `${HELP_ARTICLE}#h_421ef66d61`,
      by: [KEY_TECHNOLOGY, KEY_PRIORITY_YEAR],
      tags: [TAG_PENDING, TAG_TECH, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
      name: 'granted families',
      description: 'Priority year of granted families, by organisation.',
      help_article: `${HELP_ARTICLE}#h_eb69e57938`,
      by: [KEY_ORGANISATION, KEY_PRIORITY_YEAR],
      tags: [TAG_GRANTED, TAG_ORG, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
      name: 'granted families',
      description: 'Priority year of granted families, by technology.',
      help_article: `${HELP_ARTICLE}#h_7750a87be4`,
      by: [KEY_TECHNOLOGY, KEY_PRIORITY_YEAR],
      tags: [TAG_GRANTED, TAG_TECH, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_GRANT_YEAR_BY_PORTFOLIO_ID,
      name: 'granted patents',
      description: 'Grant year of granted families, by organisation.',
      help_article: `${HELP_ARTICLE}#h_938f21b117`,
      by: [KEY_ORGANISATION, KEY_GRANT_YEAR],
      tags: [TAG_GRANTED, TAG_ORG, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: GRANTED_FAMILIES_BY_GRANT_YEAR_BY_TECHNOLOGY_ID,
      name: 'granted patents',
      description: 'Grant year of granted families, by technology.',
      help_article: `${HELP_ARTICLE}#h_834523cb8c`,
      by: [KEY_TECHNOLOGY, KEY_GRANT_YEAR],
      tags: [TAG_GRANTED, TAG_TECH, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: GRANTS_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
      name: 'granted patents',
      description: 'Priority year of individual granted patents, by organisation.',
      help_article: `${HELP_ARTICLE}#h_31bd8ff126`,
      by: [KEY_ORGANISATION, KEY_PRIORITY_YEAR],
      tags: [TAG_ORG, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: GRANTS_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
      name: 'granted patents',
      description: 'Priority year of individual granted patents, by technology.',
      help_article: `${HELP_ARTICLE}#h_2fe93de6b0`,
      by: [KEY_TECHNOLOGY, KEY_PRIORITY_YEAR],
      tags: [TAG_TECH, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: AGE_AT_EXPIRY_BY_PORTFOLIO_ID,
      name: 'historical expiry age per family',
      short_name: 'age at expiry',
      description: 'Age at expiry, in years, for each organisation. Calculated from priority date to expiry date. Only shows patent families that have already expired, due to age or non-payment.',
      help_article: `${HELP_ARTICLE}#h_1d0b3b6827`,
      by: [KEY_ORGANISATION],
      tags: [TAG_EXPIRED, TAG_ORG, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: AGE_AT_EXPIRY_BY_TECH_ID,
      name: 'historical expiry age per family',
      short_name: 'age at expiry',
      description: 'Age at expiry, in years, for each technology. Calculated from priority date to expiry date. Only shows patent families that have already expired, due to age or non-payment.',
      help_article: `${HELP_ARTICLE}#h_ff1fc0a1d4`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_EXPIRED, TAG_TECH, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: PERCENTAGE_EXPIRED_BY_AGE_BY_PORTFOLIO_ID,
      name: 'percentage of families expired by age',
      short_name: 'expired by age %',
      description: 'Cumulative percentage of families expired by age in years, for each organisation. Age at expiry is calculated from priority date to expiry date. Only shows patent families that have already expired, due to age or non-payment.',
      help_article: `${HELP_ARTICLE}#h_e02ddbe918`,
      by: [KEY_ORGANISATION],
      tags: [TAG_EXPIRED, TAG_ORG, TAG_TIME],
      available_view_ids: [LINE_ID, COLUMN_CLUSTER_ID, TABLE_ID],
      default_view_id: LINE_ID,
    },
    {
      spec_id: EXPIRING_BY_YEAR_BY_ORGANISATION_ID,
      name: 'future projected expiry age',
      short_name: 'projected expiry',
      description: 'Projected number of patent families expiring per year, for each organisation. Only considers currently granted patent families and assumes patent families are renewed to the maximum term allowed.',
      help_article: `${HELP_ARTICLE}#h_3fde99fa49`,
      by: [KEY_ORGANISATION],
      tags: [TAG_EXPIRED, TAG_ORG, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
    {
      spec_id: EXPIRING_BY_YEAR_BY_TECH_ID,
      name: 'future projected expiry age',
      short_name: 'projected expiry',
      description: 'Projected number of patent families expiring per year, for each technology. Only considers currently granted patent families and assumes patent families are renewed to the maximum term allowed.',
      help_article: `${HELP_ARTICLE}#h_53c28833ba`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_EXPIRED, TAG_TECH, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
    },
 
    {
      check_if_available: not_available,
      spec_id: GRANTED_FAMILIES_BY_PRIORITY_YEAR_ID,
    },
    {
      check_if_available: not_available,
      spec_id: PENDING_FAMILIES_BY_PRIORITY_YEAR_ID
    },
    {
      check_if_available: not_available,
      spec_id: GRANTS_BY_PRIORITY_YEAR_ID
    }
  ]
}
