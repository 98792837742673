import React from 'react'
import s from './ErrorDetails.module.scss'

export const ErrorDetails = ({error}) => {
  return (
    <div>
      {/* js error object: */}
      {error.message &&
        <div>
          Message:
          <pre className={s.error_block}>
            {error.message}
          </pre>
        </div>
      }
      {error.stack &&
        <div>
          Stack:
          <pre className={s.error_block}>
            {error.stack}
          </pre>
        </div>
      }

      {/* Extra details */}
      {error.suggestion &&
        <p>
          error suggestion: {error.suggestion}
        </p>
      }

      {/* AXIOS */}
      {error.request && error.request.responseURL &&
        <div>
          <div>Request:</div>
          <pre className={s.error_block}>
            <code>
              {error.request.responseURL}
            </code>
          </pre>
        </div>
      }
      {error.response && error.response.data &&
        <div>
          <div>Response.data:</div>
          <pre className={s.error_block}>
            <code>
              {JSON.stringify(error.response.data, null, 4).slice(0, 10000)}
            </code>
          </pre>
        </div>
      }
      {error.response &&
        <div>
          <div>Response:</div>
          <pre className={s.error_block}>
            <code>
              {JSON.stringify(error.response, null, 4).slice(0, 10000)}
            </code>
          </pre>
        </div>
      }

    </div>
  )
}