import _ from 'underscore'
import { parse } from 'papaparse'

function csv_escape_and_wrap(val) {
  const escaped = (val == null) ? val :
    val
      .replace(/"/g, "'")  // repace inverted commas with single quotes
      .replace(/\n/g, ' ') // replace newlines with space

  return '"' + escaped + '"'; // wrap in inverted commas
}

function get_csv_row(row) {
  return row.map(function(val) {
    if (val == null || _.isNumber(val)) {
      return val
    }
    return csv_escape_and_wrap(val) // if not a number or null, then escape and wrap
  }).join(',') // join with a comma
}

export function get_csv_string(rows) {
  const csv_rows = rows.map(get_csv_row)
  return csv_rows.join('\n')
}

export function parse_csv(input) {
  const { data, errors } =  parse(input, {delimiter: ','})
  if (errors.length) {
    throw new Error(`csv parsing failed. errors: ${JSON.stringify(errors).slice(0, 200)}`)
  }
  return data
}