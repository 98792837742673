import { usePatentKeyboardShortcuts } from '../../utils/keyboard_shortcuts/patent_keyboard_utils.js'

/**
 * Workaround to call hooks from an old-style stateful component.
 */
const PatentKeyboardShortcutsWrapper = (props) => {
  usePatentKeyboardShortcuts(props) // call the hook
  return null // renders no html
}

export default PatentKeyboardShortcutsWrapper