import React from 'react'
import cn from 'classnames'

import s from './ClassifiersBasketContainer.module.scss'

const ClassifiersBasketContainer = ({className, children}) => {
  return (
    <div className={cn(s.block, className)}>
      {children}
    </div>
  )
}

export default ClassifiersBasketContainer