import _ from 'underscore'

import {
  CLASSIFIER_EVAL_LANDSCAPE_REPORT_TYPE_OLD,
  CLASSIFIER_LANDSCAPE_REPORT_TYPE_OLD,
  ND_REPORT_TYPE,
  ND_REPORT_TYPE_OLD,
  UTT_REPORT_TYPE,
  MULTICLASS_REPORT_TYPE_OLD,
  UTT_LANDSCAPE_REPORT_TYPE,
  EVAL_LANDSCAPE_REPORT_TYPE_OLD,
  CLASSIFIER_LANDSCAPE_REPORT_TYPE,
  SET_THEORY_REPORT_TYPE,
  BENCHMARKING_REPORT_TYPE,
  DEFAULT_REPORT_TYPE,
  CUSTOM_CLUSTERED_REPORT_TYPE
} from '../constants/constants.js'
import { CLASSIFIERS } from '../constants/paths.js'
import { CLASSIFIER } from '../components/classifiers_editor/constants/classifier_paths.js'

export const REPORT_SERIES_SORT_BY_SIZE = {id: 'size',   label: 'By size', default: true }
export const REPORT_SERIES_SORT_NATIVE  = {id: 'native', label: 'By report order'}

export const REPORT_SERIES_SORT_OPTIONS = [
  REPORT_SERIES_SORT_BY_SIZE, REPORT_SERIES_SORT_NATIVE
]

export const DATA_ORIENTATION_NATIVE     = {id: 'native', label: 'Native'}
export const DATA_ORIENTATION_TRANSPOSED = {id: 'transposed', label: 'Transposed'}

export function get_default_report_series_sort() {
  const default_sort = REPORT_SERIES_SORT_OPTIONS.filter(item => item.default)

  return default_sort[0]
}

export function is_report_series_sort_by_size(report_series_sort) {
  return report_series_sort === REPORT_SERIES_SORT_BY_SIZE.id
}

export function get_available_data_orientation_options(selected_data_orientation) {
  return selected_data_orientation === DATA_ORIENTATION_NATIVE.id ? DATA_ORIENTATION_TRANSPOSED : DATA_ORIENTATION_NATIVE
}

export function is_data_transposed(selected_data_orientation) {
  return selected_data_orientation === DATA_ORIENTATION_TRANSPOSED.id
}

export function is_nd_report_type(report_type) {
  return _.contains([ND_REPORT_TYPE, ND_REPORT_TYPE_OLD], report_type)
}

export function get_path_to_classifier_ui(training_set_id) {
  return `${CLASSIFIERS}/${CLASSIFIER}/${training_set_id}`
}

export function is_utt_or_utt_landscape_report_type(report_type) {
  return _.contains([UTT_REPORT_TYPE, MULTICLASS_REPORT_TYPE_OLD, UTT_LANDSCAPE_REPORT_TYPE], report_type)
}

export function is_utt_report_type(report_type) {
  return _.contains([UTT_REPORT_TYPE, MULTICLASS_REPORT_TYPE_OLD], report_type)
}

export function is_utt_landscape_report_type(report_type) {
  return report_type === UTT_LANDSCAPE_REPORT_TYPE
}

export function is_custom_clustered_report_type(report_type) {
  return report_type === CUSTOM_CLUSTERED_REPORT_TYPE
}

export function is_classifier_landscape_report_type(report_type) {
  return _.contains([
    // classifier landscape report type
    CLASSIFIER_LANDSCAPE_REPORT_TYPE,
    // deprecated landscape and eval landscape types (still found on old reports)
    CLASSIFIER_LANDSCAPE_REPORT_TYPE_OLD,
    CLASSIFIER_EVAL_LANDSCAPE_REPORT_TYPE_OLD,
    EVAL_LANDSCAPE_REPORT_TYPE_OLD
  ], report_type)
}

export function is_set_theory_report_type(report_type) {
  return _.contains([SET_THEORY_REPORT_TYPE, BENCHMARKING_REPORT_TYPE], report_type)
}

export function get_updated_report_type(report_type) {
  if (is_utt_report_type(report_type)) {
    return UTT_REPORT_TYPE
  } else if (is_classifier_landscape_report_type(report_type)) {
    return CLASSIFIER_LANDSCAPE_REPORT_TYPE
  } else if (is_nd_report_type(report_type)) {
    return ND_REPORT_TYPE
  }
  return report_type || DEFAULT_REPORT_TYPE
}

export function can_create_alert_for_report_type(report_type) {
  if (is_classifier_landscape_report_type(report_type) || is_nd_report_type(report_type)) {
    return true
  }
  return _.contains([
    DEFAULT_REPORT_TYPE,
    UTT_REPORT_TYPE
  ], report_type)
}