import React from 'react'
import cn from 'classnames'
import { Modal as RSModal, ModalHeader as RSModalHeader, ModalBody as RSModalBody, ModalFooter as RSModalFooter } from 'reactstrap'

import CloseIconButton from './CloseIconButton.js'
import { PrimaryButton, TertiaryButton } from './Button.js'

import s from './Modal.module.scss'


const MODAL_SIZE_TO_CLASS = {
  sm: s.modal__sm,
  md: s.modal__md,
  lg: s.modal__lg
}

/**
 * Renders a modal.
 * Children are rendered in the body of the modal.
 */

const ModalContainer = ({className, is_open, on_hide, backdrop, contentClassName, zIndex, inner_ref, children, no_close_on_esc, centered, size}) => {
  if (is_open === undefined) {
    is_open = true // by default we render the modal
  }

  if (backdrop === undefined) {
    backdrop = true
  }

  const size_classname = MODAL_SIZE_TO_CLASS[size] || s.modal__wide

  return (
    <RSModal
      isOpen={is_open}
      toggle={on_hide}
      className={cn(size_classname, className)}
      fade={false}
      backdrop={backdrop}
      zIndex={zIndex || 10000}
      contentClassName={cn(s.content, contentClassName)}
      innerRef={inner_ref}
      keyboard={!no_close_on_esc}
      centered={centered}
    >
      {children}
    </RSModal>
  )
}

const ModalHeader = ({on_hide, title, headerClassName, closeIconClassName}) => {
  return (
    <RSModalHeader
      toggle={on_hide}
      // workaround to make set className on title tag (ReactStrap weirdly does not expose this)
      tag={({ className, children }) => (
        <h3
          className={cn(s.modal_title, className, headerClassName)}
        >
          {children}
        </h3>
      )}

      close={(<CloseIconButton on_click={on_hide} className={closeIconClassName}/>)}
      className={cn('p-0 justify-content-between', s.header)}
    >
      {title}
    </RSModalHeader>
  )
}

const ModalBody = ({className, children}) => {
  return (
    <RSModalBody className={cn('px-0', className)}>
      {children}
    </RSModalBody>
  )
}

const CloseButton = ({can_only_close, on_hide, close_label}) => {
  if (can_only_close) {
    // use primary button where no other actions are available in the modal
    return <PrimaryButton onClick={on_hide}>{close_label || 'Close'}</PrimaryButton>
  }
  return <TertiaryButton className='me-2' onClick={on_hide}>{close_label || 'Close'}</TertiaryButton>
}

const ModalFooter = (
  {
    no_close_button,
    on_hide,
    close_label,

    primary_button,
    footer,

    on_clear,
    clear_label,
    on_retry,
    retry_label,

  }) => {
  const show_close = on_hide && !no_close_button

  return (
    <RSModalFooter className={cn('px-0 pb-0 d-flex justify-content-between', s.footer)}>
      <div className='ms-0'>
        {/* footer may include secondary button and/ or spinners/ feedback */}
        {footer}
      </div>

      <div className='me-0'>
        {show_close &&
          <CloseButton
            can_only_close={!primary_button && !footer && !on_clear && !on_retry}
            on_hide={on_hide}
            close_label={close_label}
          />
        }

        {primary_button}

        {/* alternative primary buttons used mainly in classifiers ui */}
        {on_clear &&
          <PrimaryButton
            onClick={() => {
              on_clear()
              on_hide()
            }}
          >
            {clear_label || 'Clear error'}
          </PrimaryButton>
        }
        {on_retry &&
          <PrimaryButton
            onClick={() => {
              on_retry()
              on_hide()
            }}
          >
            {retry_label || 'Retry'}
          </PrimaryButton>
        }
      </div>

    </RSModalFooter>
  )
}

const Modal = (
  {
    is_open,
    on_hide,
    title,
    close_label,
    header,
    primary_button,
    footer,
    backdrop,
    children,
    on_retry,
    retry_label,
    zIndex,
    inner_ref,
    no_footer,
    on_clear,
    clear_label,
    no_close_button,
    no_close_on_esc,
    centered,
    size,

    className,
    contentClassName,
    bodyClassName,
    headerClassName,
    closeIconClassName,
  }) => {
  return (
    <ModalContainer
      is_open={is_open}
      on_hide={on_hide}
      className={className}
      contentClassName={contentClassName}
      backdrop={backdrop}
      zIndex={zIndex}
      inner_ref={inner_ref}
      no_close_on_esc={no_close_on_esc}
      centered={centered !== false}
      size={size}
    >
      {header}
      {!header &&
        <ModalHeader
          on_hide={on_hide}
          title={title}
          headerClassName={headerClassName}
          closeIconClassName={closeIconClassName}
        />
      }
      <ModalBody
        className={bodyClassName}
      >
        {children}
      </ModalBody>
      {!no_footer &&
        <ModalFooter
          primary_button={primary_button}
          footer={footer}
          no_close_button={no_close_button}
          on_hide={on_hide}
          close_label={close_label}
          on_retry={on_retry}
          retry_label={retry_label}
          on_clear={on_clear}
          clear_label={clear_label}
        />
      }
    </ModalContainer>
  )
}

export const ScrollModal = ({className, contentClassName, bodyClassName, ...other_props}) => {
  return (
    <Modal
      {...other_props}
      className={cn(s.modal_scroll_container, className)}
      contentClassName={cn(s.modal_scroll_content, contentClassName)}
      bodyClassName={cn(s.modal_scroll_body, bodyClassName)}
    />
  )
}

export default Modal