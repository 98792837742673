import * as d3 from 'd3'

export const REF_HEIGHT = 483
export const REF_WIDTH = 963

export const BLANK_COLOUR = 'rgb(192, 191, 192)'
export const COLOUR_SCALE = d3.scaleLinear()
  .domain([0, 0.05, 1.0])
  .range(['#F6C7CF', '#E8788B', '#D91E3E']) // red scale


