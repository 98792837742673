import { get_as_map } from '../utils/utils.js'
import { ROLE_ANY, ROLE_DEFENDANT, ROLE_EITHER, ROLE_PLAINTIFF } from './dispute_roles.js'
import {PLAINTIFF_COLOR, DEFENDANT_COLOR} from '../constants/colours.js'
import { DISPUTE_TYPE_OPTION_ALL } from '../constants/dispute_routes.js'

export const DISPUTE_ID_FIELD             = { report_reader_name: 'D.dispute_id',     id: 'dispute_id',       label: 'Dispute Id' }
export const PORTFOLIO_ID_FIELD           = { report_reader_name: 'P.portfolio_id',   id: 'portfolio_id',     label: 'Portfolio Id' }
export const ROLE_FIELD                   = { report_reader_name: 'DPO.role',         id: 'role',             label: 'Role' }
export const IS_NPE_FIELD                 = { report_reader_name: 'DLE.is_npe',       id: 'is_npe',           label: 'Plaintiff type' } // This relates to the "general type" of the dispute, but is presented in the UI as relating to "Plaintiff type"
export const DOCKET_NUMBER_FIELD          = { report_reader_name: 'D.docket_number',  id: 'docket_number',    label: 'Docket number' }
export const JURISDICTION_FIELD           = { report_reader_name: 'D.jurisdiction',   id: 'jurisdiction',     label: 'Jurisdiction' }
export const ROUTE_FIELD                  = { report_reader_name: 'D.route',          id: 'route',            label: 'Route' }
export const PORTFOLIO_NAME_FIELD         = { report_reader_name: '',                 id: 'portfolio_name',   label: 'Organisation' }
export const TITLE_FIELD                  = { report_reader_name: 'D.title',          id: 'title',            label: 'Title' }
export const DEFENDANT_FIELD              = { report_reader_name: 'DEFENDANT',        id: 'defendant',        label: 'Defendant' }
export const PLAINTIFF_FIELD              = { report_reader_name: 'PLAINTIFF',        id: 'plaintiff',        label: 'Plaintiff' }
export const PATENTS_FIELD                = { report_reader_name: 'patents',          id: 'patents',          label: 'Patents' }
export const START_DATE_FIELD             = { report_reader_name: 'D.start_date',     id: 'start_date',       label: 'Start date' }
export const END_DATE_FIELD               = { report_reader_name: 'D.end_date',       id: 'end_date',         label: 'End date' }
export const DURATION_FIELD               = { report_reader_name: '',                 id: 'duration',         label: 'Duration' }
export const OUTCOME_FIELD                = { report_reader_name: 'DPO.outcome',      id: 'outcome',          label: 'Outcome' }
export const IS_PLAINTIFF_NPE_FIELD       = { report_reader_name: 'plaintiff_is_NPE', id: 'plaintiff_is_npe', label: 'Plaintiff type' }
export const IS_DEFENDANT_NPE_FIELD       = { report_reader_name: 'defendant_is_NPE', id: 'defendant_is_npe', label: 'Defendant type' }
export const PLAINTIFF_PORTFOLIO_ID_FIELD = { report_reader_name: 'plaintiff_portfolio_id', id: 'plaintiff_portfolio_id', label: 'Plaintiff portfolio id' }
export const DETAILS_FIELD                = { report_reader_name: '',                 id: 'details',          label: 'Details'}

/*this field is used when exporting data with data export service.
The label format is meaningful: the '_link' part let's the export service know it's a special field
* and what comes after the ':' separator is a name of field that the link should be applied to
* */
export const DETAILS_LINK_FIELD           = { report_reader_name: '',                 id: '_link',            label: '_link:Details'}

export const COLUMNS = [
  DISPUTE_ID_FIELD,
  PORTFOLIO_ID_FIELD,
  ROLE_FIELD,
  IS_NPE_FIELD,
  DOCKET_NUMBER_FIELD,
  PORTFOLIO_NAME_FIELD,
  TITLE_FIELD,
  DEFENDANT_FIELD,
  PLAINTIFF_FIELD,
  PATENTS_FIELD,
  START_DATE_FIELD,
  END_DATE_FIELD,
  OUTCOME_FIELD,
  JURISDICTION_FIELD,
  ROUTE_FIELD,
  PLAINTIFF_PORTFOLIO_ID_FIELD,
  IS_PLAINTIFF_NPE_FIELD,
  IS_DEFENDANT_NPE_FIELD
]

export const COLUMNS_BY_REPORT_READER_NAME = get_as_map(COLUMNS, 'report_reader_name')

export const KNOWN_OUTCOMES = ['SETTLED', 'WON', 'LOST', 'ACTIVE']
export const OTHER_OUTCOME = 'OTHER'
export const OUTCOMES = [...KNOWN_OUTCOMES, OTHER_OUTCOME]

export const NPE  = { id: 'npe',  name: 'NPE'}
export const OPCO = { id: 'opco', name: 'Opco'}
export const ALL_PARTY_TYPES = [NPE, OPCO]
export const ALL_PARTY_TYPE_IDS = ALL_PARTY_TYPES.map(type => type.id)

export const TABLE_SUMMARY_TOTAL_FIELD_ID = 'total'
export const PORTFOLIO_NAME_FIELD_ID = 'portfolio_name'
export const PORTFOLIO_ID_FIELD_ID = 'portfolio_id'

export function get_columns_to_export(dispute_role, is_csv) {
  return [
    PORTFOLIO_NAME_FIELD,
    TITLE_FIELD,
    PLAINTIFF_FIELD,
    DEFENDANT_FIELD,
    ROUTE_FIELD,
    JURISDICTION_FIELD,
    PATENTS_FIELD,
    START_DATE_FIELD,
    END_DATE_FIELD,
    DURATION_FIELD,
    OUTCOME_FIELD,
    ...(dispute_role === ROLE_DEFENDANT) ? [IS_PLAINTIFF_NPE_FIELD] : [],
    ...(dispute_role === ROLE_PLAINTIFF) ? [IS_NPE_FIELD, IS_DEFENDANT_NPE_FIELD] : [],
    ...(dispute_role === ROLE_EITHER || dispute_role === ROLE_ANY) ? [IS_PLAINTIFF_NPE_FIELD, IS_DEFENDANT_NPE_FIELD] : [],
    DETAILS_FIELD,
    ...(!is_csv) ? [DETAILS_LINK_FIELD] : []
  ]
}

export function get_dispute_filter_values_or_defaults(item, available_start_dates_extent) {
  const { plaintiff_type_ids, defendant_type_ids, outcome_ids, start_date_range, selected_dispute_type } = item

  return {
    selected_dispute_type: selected_dispute_type || DISPUTE_TYPE_OPTION_ALL.id,
    plaintiff_type_ids:    plaintiff_type_ids    || ALL_PARTY_TYPE_IDS,
    defendant_type_ids:    defendant_type_ids    || ALL_PARTY_TYPE_IDS,
    outcome_ids:           outcome_ids           || OUTCOMES,
    start_date_range:      start_date_range      || available_start_dates_extent // by default, select the full extent
  }
}

export const DISPUTE_ROLE_DIMS = [
  {role: ROLE_DEFENDANT, name: ROLE_DEFENDANT.toLowerCase(), short_name: ROLE_DEFENDANT.toLowerCase(), id: ROLE_DEFENDANT, idx: 0, color: DEFENDANT_COLOR},
  {role: ROLE_PLAINTIFF, name: ROLE_PLAINTIFF.toLowerCase(), short_name: ROLE_PLAINTIFF.toLowerCase(), id: ROLE_PLAINTIFF, idx: 1, color: PLAINTIFF_COLOR}
]