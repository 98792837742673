import React from'react'

const ST_0  = { fill:'#D70036', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_1  = { fill:'#FA9D9C', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_2  = { fill:'#E8505D', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_3  = { fill:'#EE686E', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_4  = { fill:'#FFDFDD', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_5  = { fill:'#F58687', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_6  = { fill:'#FFD2CF', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_7  = { fill:'#E9545F', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_8  = { fill:'#F17478', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_9  = { fill:'#FDAEAC', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_10 = { fill:'#E02C49', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_11 = { fill:'#EC6068', fillRule: 'evenodd', clipRule: 'evenodd' }
const ST_12 = { fill:'#E1304B', fillRule: 'evenodd', clipRule: 'evenodd' }

class CipherBall extends React.Component{
  render() {
    return (
      <g id='XMLID_220_'>
        <polygon id='XMLID_215_' style={ST_0} points='142.8,118.5 139.3,125.5 146.3,110.2 	'/>
        <polygon id='XMLID_214_' style={ST_0} points='118.1,148.7 113.2,150.6 127.1,140.5 	'/>
        <polygon id='XMLID_213_' style={ST_0} points='155.6,77.6 149.6,59 155,68.8 	'/>
        <polygon id='XMLID_212_' style={ST_0} points='53.3,149.2 48.2,146.3 65.2,152.4 	'/>
        <polygon id='XMLID_211_' style={ST_0} points='159.5,104 154.4,111.4 157.9,94.8 	'/>
        <polygon id='XMLID_210_' style={ST_0} points='19.6,65.3 19.5,57.7 28,49.4 	'/>
        <polygon id='XMLID_209_' style={ST_0} points='124.8,147.5 136,141.3 143.3,139.6 	'/>
        <polygon id='XMLID_208_' style={ST_1} points='143.3,139.6 136,141.3 147,127.7 	'/>
        <polygon id='XMLID_207_' style={ST_1} points='127.5,29.5 136.5,31.8 140.7,41.7 	'/>
        <polygon id='XMLID_206_' style={ST_0} points='117.6,25.5 136.5,31.8 127.5,29.5 	'/>
        <polygon id='XMLID_205_' style={ST_2} points='22.4,119.3 33,136.2 22.9,131.3 	'/>
        <polygon id='XMLID_204_' style={ST_0} points='22.4,119.3 22.9,131.3 20.2,109.8 	'/>
        <polygon id='XMLID_203_' style={ST_0} points='111.6,162.9 92.9,158.9 105,159.5 	'/>
        <polygon id='XMLID_202_' style={ST_1} points='111.6,162.9 105,159.5 121.4,152 	'/>
        <polygon id='XMLID_201_' style={ST_0} points='16.1,99.9 20.2,109.8 9.6,91.3 	'/>
        <polygon id='XMLID_200_' style={ST_1} points='14.6,82 16.1,99.9 9.6,91.3 	'/>
        <polygon id='XMLID_199_' style={ST_0} points='92.9,158.9 74,166.1 86.8,160.6 	'/>
        <polygon id='XMLID_198_' style={ST_0} points='100.9,10.7 117.6,25.5 110.9,20.9 	'/>
        <polygon id='XMLID_197_' style={ST_0} points='166.8,85.6 157.9,76.2 155.2,67 	'/>
        <polygon id='XMLID_196_' style={ST_0} points='157.9,76.2 152.2,58.1 155.2,67 	'/>
        <polygon id='XMLID_195_' style={ST_0} points='155.2,67 152.2,58.1 153.7,44.5 	'/>
        <polygon id='XMLID_194_' style={ST_3} points='74,166.1 66.9,157.3 86.8,160.6 	'/>
        <polygon id='XMLID_193_' style={ST_0} points='74,166.1 56.5,153.1 66.9,157.3 	'/>
        <polygon id='XMLID_192_' style={ST_0} points='59.7,21.5 49,27.8 62.9,13 	'/>
        <polygon id='XMLID_191_' style={ST_1} points='62.9,13 81.9,17.1 75.9,17.8 	'/>
        <polygon id='XMLID_190_' style={ST_4} points='59.7,21.5 62.9,13 75.9,17.8 	'/>
        <polygon id='XMLID_189_' style={ST_2} points='93.2,17.1 100.9,10.7 110.9,20.9 	'/>
        <polygon id='XMLID_188_' style={ST_0} points='81.9,17.1 93.2,17.1 75.9,17.8 	'/>
        <polygon id='XMLID_187_' style={ST_1} points='81.9,17.1 100.9,10.7 93.2,17.1 	'/>
        <polygon id='XMLID_186_' style={ST_0} points='44.1,31.6 49,27.8 59.7,21.5 	'/>
        <polygon id='XMLID_185_' style={ST_0} points='44.1,31.6 28.2,34.3 49,27.8 	'/>
        <polygon id='XMLID_184_' style={ST_5} points='166.8,85.6 157.6,92.3 157.9,76.2 	'/>
        <polygon id='XMLID_183_' style={ST_0} points='157.9,101.9 157.6,92.3 166.8,85.6 	'/>
        <polygon id='XMLID_182_' style={ST_0} points='105,159.5 115.8,153.6 121.4,152 	'/>
        <polygon id='XMLID_181_' style={ST_0} points='15.7,90 16.1,99.9 14.6,82 	'/>
        <polygon id='XMLID_180_' style={ST_0} points='56.5,153.1 48.8,149.1 66.9,157.3 	'/>
        <polygon id='XMLID_179_' style={ST_0} points='56.5,153.1 34.8,149.4 48.8,149.1 	'/>
        <polygon id='XMLID_178_' style={ST_6} points='29.6,47.2 28.2,34.3 44.1,31.6 	'/>
        <polygon id='XMLID_177_' style={ST_0} points='29.6,47.2 23,56.1 28.2,34.3 	'/>
        <polygon id='XMLID_176_' style={ST_2} points='136.3,36.7 153.7,44.5 142.7,46.2 	'/>
        <polygon id='XMLID_175_' style={ST_5} points='142.7,46.2 153.7,44.5 152.2,58.1 	'/>
        <polygon id='XMLID_174_' style={ST_0} points='66.9,157.3 78.9,158.5 86.8,160.6 	'/>
        <polygon id='XMLID_173_' style={ST_0} points='160.2,121.4 153.8,106.2 157.9,101.9 	'/>
        <polygon id='XMLID_172_' style={ST_0} points='153.8,106.2 157.6,92.3 157.9,101.9 	'/>
        <polygon id='XMLID_171_' style={ST_0} points='20.7,63.9 23,56.1 29.6,47.2 	'/>
        <polygon id='XMLID_170_' style={ST_0} points='20.7,63.9 8.9,71.6 23,56.1 	'/>
        <polygon id='XMLID_169_' style={ST_0} points='93.2,17.1 110.9,20.9 105,21.8 	'/>
        <polygon id='XMLID_168_' style={ST_0} points='105,21.8 110.9,20.9 125.8,20.2 	'/>
        <polygon id='XMLID_167_' style={ST_0} points='59.7,21.5 75.9,17.8 71,21.7 	'/>
        <polygon id='XMLID_166_' style={ST_0} points='29.6,47.2 44.1,31.6 39.3,39.1 	'/>
        <polygon id='XMLID_165_' style={ST_2} points='36,134.5 48.8,149.1 34.8,149.4 	'/>
        <polygon id='XMLID_164_' style={ST_0} points='36,134.5 34.8,149.4 27.7,127.8 	'/>
        <polygon id='XMLID_163_' style={ST_1} points='160.2,121.4 146.7,120.3 153.8,106.2 	'/>
        <polygon id='XMLID_162_' style={ST_2} points='143.6,132.5 146.7,120.3 160.2,121.4 	'/>
        <polygon id='XMLID_161_' style={ST_0} points='157.9,76.2 154.2,79.2 162,65.7 	'/>
        <polygon id='XMLID_160_' style={ST_0} points='157.6,92.3 154.2,79.2 157.9,76.2 	'/>
        <polygon id='XMLID_159_' style={ST_0} points='148.3,60.3 152.2,58.1 162,65.7 	'/>
        <polygon id='XMLID_158_' style={ST_2} points='148.3,60.3 162,65.7 154.2,79.2 	'/>
        <polygon id='XMLID_157_' style={ST_7} points='142.7,46.2 152.2,58.1 148.3,60.3 	'/>
        <polygon id='XMLID_156_' style={ST_5} points='20.7,63.9 19.2,80.1 8.9,71.6 	'/>
        <polygon id='XMLID_155_' style={ST_0} points='19.2,80.1 15.7,90 8.9,71.6 	'/>
        <polygon id='XMLID_154_' style={ST_0} points='136.3,36.7 142.7,46.2 131.3,38.1 	'/>
        <polygon id='XMLID_153_' style={ST_5} points='125.8,20.2 136.3,36.7 131.3,38.1 	'/>
        <polygon id='XMLID_152_' style={ST_0} points='71,21.7 75.9,17.8 87.1,12.4 	'/>
        <polygon id='XMLID_151_' style={ST_5} points='136.3,151.1 136.7,132.6 143.6,132.5 	'/>
        <polygon id='XMLID_150_' style={ST_0} points='136.7,132.6 146.7,120.3 143.6,132.5 	'/>
        <polygon id='XMLID_149_' style={ST_0} points='100.4,166.8 78.9,158.5 92.1,155.7 	'/>
        <polygon id='XMLID_148_' style={ST_2} points='100.4,166.8 92.1,155.7 109.6,151 	'/>
        <polygon id='XMLID_147_' style={ST_0} points='100.4,166.8 109.6,151 115.8,153.6 	'/>
        <polygon id='XMLID_146_' style={ST_5} points='115.8,153.6 123.8,143.1 136.3,151.1 	'/>
        <polygon id='XMLID_145_' style={ST_0} points='136.3,151.1 123.8,143.1 136.7,132.6 	'/>
        <polygon id='XMLID_144_' style={ST_0} points='109.6,151 123.8,143.1 115.8,153.6 	'/>
        <polygon id='XMLID_143_' style={ST_2} points='26.4,116.1 27.7,127.8 11,110.7 	'/>
        <polygon id='XMLID_142_' style={ST_8} points='36,134.5 27.7,127.8 26.4,116.1 	'/>
        <polygon id='XMLID_141_' style={ST_5} points='21,97.4 26.4,116.1 11,110.7 	'/>
        <polygon id='XMLID_140_' style={ST_2} points='21,97.4 15.7,90 19.2,80.1 	'/>
        <polygon id='XMLID_139_' style={ST_2} points='21,97.4 11,110.7 15.7,90 	'/>
        <polygon id='XMLID_138_' style={ST_4} points='116.8,31.7 125.8,20.2 131.3,38.1 	'/>
        <polygon id='XMLID_137_' style={ST_4} points='105,21.8 125.8,20.2 116.8,31.7 	'/>
        <polygon id='XMLID_136_' style={ST_0} points='52.7,34.2 39.3,39.1 52.1,19.1 	'/>
        <polygon id='XMLID_135_' style={ST_1} points='78.9,158.5 56.5,159.3 72.8,152.6 	'/>
        <polygon id='XMLID_134_' style={ST_0} points='78.9,158.5 72.8,152.6 92.1,155.7 	'/>
        <polygon id='XMLID_133_' style={ST_0} points='47.7,139.5 48.8,149.1 36,134.5 	'/>
        <polygon id='XMLID_132_' style={ST_0} points='47.7,139.5 56.5,159.3 48.8,149.1 	'/>
        <polygon id='XMLID_131_' style={ST_2} points='52.7,34.2 52.1,19.1 69.1,28.3 	'/>
        <polygon id='XMLID_130_' style={ST_1} points='52.1,19.1 71,21.7 69.1,28.3 	'/>
        <polygon id='XMLID_129_' style={ST_0} points='21.2,52.6 26.2,70.8 20.7,63.9 	'/>
        <polygon id='XMLID_128_' style={ST_0} points='26.2,70.8 19.2,80.1 20.7,63.9 	'/>
        <polygon id='XMLID_127_' style={ST_8} points='105,21.8 116.8,31.7 101.1,28.5 	'/>
        <polygon id='XMLID_126_' style={ST_2} points='87.1,12.4 105,21.8 101.1,28.5 	'/>
        <polygon id='XMLID_125_' style={ST_0} points='38.8,49.3 39.3,39.1 52.7,34.2 	'/>
        <polygon id='XMLID_124_' style={ST_6} points='38.8,49.3 21.2,52.6 39.3,39.1 	'/>
        <polygon id='XMLID_123_' style={ST_7} points='71,21.7 87.1,12.4 84.5,27.6 	'/>
        <polygon id='XMLID_122_' style={ST_9} points='84.5,27.6 87.1,12.4 101.1,28.5 	'/>
        <polygon id='XMLID_121_' style={ST_2} points='71,21.7 84.5,27.6 69.1,28.3 	'/>
        <polygon id='XMLID_120_' style={ST_7} points='148.3,60.3 154.2,79.2 144.7,71.9 	'/>
        <polygon id='XMLID_119_' style={ST_7} points='144.7,71.9 154.2,79.2 158.5,92.8 	'/>
        <polygon id='XMLID_118_' style={ST_7} points='146.7,120.3 145.4,105.1 153.8,106.2 	'/>
        <polygon id='XMLID_117_' style={ST_2} points='153.8,106.2 145.4,105.1 158.5,92.8 	'/>
        <polygon id='XMLID_116_' style={ST_0} points='131.3,38.1 142.7,46.2 144.1,47.6 	'/>
        <polygon id='XMLID_115_' style={ST_8} points='144.1,47.6 148.3,60.3 144.7,71.9 	'/>
        <polygon id='XMLID_114_' style={ST_8} points='61.3,141.7 56.5,159.3 47.7,139.5 	'/>
        <polygon id='XMLID_113_' style={ST_2} points='61.3,141.7 72.8,152.6 56.5,159.3 	'/>
        <polygon id='XMLID_112_' style={ST_7} points='116.8,31.7 131.3,38.1 126.2,44.9 	'/>
        <polygon id='XMLID_111_' style={ST_1} points='126.2,44.9 131.3,38.1 144.1,47.6 	'/>
        <polygon id='XMLID_110_' style={ST_0} points='92.1,155.7 98.9,146 109.6,151 	'/>
        <polygon id='XMLID_109_' style={ST_0} points='92.1,155.7 85.4,157.9 98.9,146 	'/>
        <polygon id='XMLID_108_' style={ST_5} points='31.1,104.8 26.4,116.1 21,97.4 	'/>
        <polygon id='XMLID_107_' style={ST_1} points='36.1,64.5 26.2,70.8 21.2,52.6 	'/>
        <polygon id='XMLID_106_' style={ST_0} points='38.8,49.3 36.1,64.5 21.2,52.6 	'/>
        <polygon id='XMLID_105_' style={ST_0} points='123.8,143.1 125.7,129 136.7,132.6 	'/>
        <polygon id='XMLID_104_' style={ST_4} points='144.7,71.9 158.5,92.8 139.4,84.7 	'/>
        <polygon id='XMLID_103_' style={ST_1} points='139.4,84.7 158.5,92.8 145.4,105.1 	'/>
        <polygon id='XMLID_102_' style={ST_0} points='52.7,34.2 69.1,28.3 64.7,40.1 	'/>
        <polygon id='XMLID_101_' style={ST_8} points='144.7,71.9 139.4,84.7 132.7,63.2 	'/>
        <polygon id='XMLID_100_' style={ST_4} points='144.1,47.6 144.7,71.9 132.7,63.2 	'/>
        <polygon id='XMLID_99_' style={ST_7} points='126.2,44.9 144.1,47.6 132.7,63.2 	'/>
        <polygon id='XMLID_98_' style={ST_0} points='31.8,127.3 47.7,139.5 36,134.5 	'/>
        <polygon id='XMLID_97_' style={ST_0} points='31.1,104.8 31.8,127.3 26.4,116.1 	'/>
        <polygon id='XMLID_96_' style={ST_0} points='79,142.6 85.4,157.9 72.8,152.6 	'/>
        <polygon id='XMLID_95_' style={ST_10} points='79,142.6 72.8,152.6 61.3,141.7 	'/>
        <polygon id='XMLID_94_' style={ST_11} points='79,142.6 98.9,146 85.4,157.9 	'/>
        <polygon id='XMLID_93_' style={ST_8} points='20.5,86 31.1,104.8 21,97.4 	'/>
        <polygon id='XMLID_92_' style={ST_0} points='26.2,70.8 20.5,86 19.2,80.1 	'/>
        <polygon id='XMLID_91_' style={ST_0} points='20.5,86 21,97.4 19.2,80.1 	'/>
        <polygon id='XMLID_90_' style={ST_0} points='46.1,38.1 52.7,34.2 64.7,40.1 	'/>
        <polygon id='XMLID_89_' style={ST_5} points='136.7,132.6 125.7,129 142.4,121 	'/>
        <polygon id='XMLID_88_' style={ST_0} points='146.7,120.3 142.4,121 145.4,105.1 	'/>
        <polygon id='XMLID_87_' style={ST_0} points='136.7,132.6 142.4,121 146.7,120.3 	'/>
        <polygon id='XMLID_86_' style={ST_8} points='84.5,27.6 101.1,28.5 95.8,37.9 	'/>
        <polygon id='XMLID_85_' style={ST_8} points='53.5,128.2 47.7,139.5 31.8,127.3 	'/>
        <polygon id='XMLID_84_' style={ST_0} points='61.3,141.7 47.7,139.5 53.5,128.2 	'/>
        <polygon id='XMLID_83_' style={ST_2} points='50.5,55.8 36.1,64.5 38.8,49.3 	'/>
        <polygon id='XMLID_82_' style={ST_6} points='46.1,38.1 64.7,40.1 50.5,55.8 	'/>
        <polygon id='XMLID_81_' style={ST_5} points='46.1,38.1 50.5,55.8 38.8,49.3 	'/>
        <polygon id='XMLID_80_' style={ST_0} points='109.6,151 98.9,146 116.2,144.4 	'/>
        <polygon id='XMLID_79_' style={ST_0} points='123.8,143.1 116.2,144.4 125.7,129 	'/>
        <polygon id='XMLID_78_' style={ST_2} points='109.6,151 116.2,144.4 123.8,143.1 	'/>
        <polygon id='XMLID_77_' style={ST_0} points='38.4,78.7 26.2,70.8 36.1,64.5 	'/>
        <polygon id='XMLID_76_' style={ST_1} points='38.4,78.7 20.5,86 26.2,70.8 	'/>
        <polygon id='XMLID_75_' style={ST_7} points='95.8,37.9 101.1,28.5 114.9,35.2 	'/>
        <polygon id='XMLID_74_' style={ST_1} points='101.1,28.5 116.8,31.7 114.9,35.2 	'/>
        <polygon id='XMLID_73_' style={ST_0} points='114.9,35.2 116.8,31.7 126.2,44.9 	'/>
        <polygon id='XMLID_72_' style={ST_8} points='139.4,84.7 145.4,105.1 130.8,98.3 	'/>
        <polygon id='XMLID_71_' style={ST_2} points='130.8,98.3 145.4,105.1 142.4,121 	'/>
        <polygon id='XMLID_70_' style={ST_0} points='46.2,112.2 31.8,127.3 31.1,104.8 	'/>
        <polygon id='XMLID_69_' style={ST_11} points='46.2,112.2 53.5,128.2 31.8,127.3 	'/>
        <polygon id='XMLID_68_' style={ST_5} points='126.2,44.9 132.7,63.2 117.5,57 	'/>
        <polygon id='XMLID_67_' style={ST_1} points='114.9,35.2 126.2,44.9 117.5,57 	'/>
        <polygon id='XMLID_66_' style={ST_0} points='93.3,133.2 116.2,144.4 98.9,146 	'/>
        <polygon id='XMLID_65_' style={ST_7} points='93.3,133.2 98.9,146 79,142.6 	'/>
        <polygon id='XMLID_64_' style={ST_2} points='46.2,112.2 31.1,104.8 41.6,95.4 	'/>
        <polygon id='XMLID_63_' style={ST_8} points='38.4,78.7 41.6,95.4 20.5,86 	'/>
        <polygon id='XMLID_62_' style={ST_0} points='41.6,95.4 31.1,104.8 20.5,86 	'/>
        <polygon id='XMLID_61_' style={ST_0} points='79.7,31.7 84.5,27.6 95.8,37.9 	'/>
        <polygon id='XMLID_60_' style={ST_0} points='69.1,28.3 84.5,27.6 79.7,31.7 	'/>
        <polygon id='XMLID_59_' style={ST_4} points='64.7,40.1 69.1,28.3 79.7,31.7 	'/>
        <polygon id='XMLID_58_' style={ST_0} points='130.8,98.3 142.4,121 120.1,111.2 	'/>
        <polygon id='XMLID_57_' style={ST_2} points='120.1,111.2 142.4,121 125.7,129 	'/>
        <polygon id='XMLID_56_' style={ST_7} points='107.1,123.5 125.7,129 116.2,144.4 	'/>
        <polygon id='XMLID_55_' style={ST_2} points='107.1,123.5 116.2,144.4 93.3,133.2 	'/>
        <polygon id='XMLID_54_' style={ST_9} points='120.1,111.2 125.7,129 107.1,123.5 	'/>
        <polygon id='XMLID_53_' style={ST_0} points='64.7,40.1 79.7,31.7 67.3,53.9 	'/>
        <polygon id='XMLID_52_' style={ST_1} points='64.7,40.1 67.3,53.9 50.5,55.8 	'/>
        <polygon id='XMLID_51_' style={ST_1} points='95.8,37.9 114.9,35.2 100.9,52.9 	'/>
        <polygon id='XMLID_50_' style={ST_4} points='114.9,35.2 117.5,57 100.9,52.9 	'/>
        <polygon id='XMLID_49_' style={ST_2} points='79.7,31.7 83.5,51.4 67.3,53.9 	'/>
        <polygon id='XMLID_48_' style={ST_8} points='79.7,31.7 95.8,37.9 83.5,51.4 	'/>
        <polygon id='XMLID_47_' style={ST_2} points='95.8,37.9 100.9,52.9 83.5,51.4 	'/>
        <polygon id='XMLID_46_' style={ST_0} points='64.1,116.8 53.5,128.2 46.2,112.2 	'/>
        <polygon id='XMLID_45_' style={ST_0} points='107.1,123.5 93.3,133.2 85,119.4 	'/>
        <polygon id='XMLID_44_' style={ST_0} points='54,85.1 41.6,95.4 38.4,78.7 	'/>
        <polygon id='XMLID_43_' style={ST_0} points='69.2,135.6 79,142.6 61.3,141.7 	'/>
        <polygon id='XMLID_42_' style={ST_5} points='64.1,116.8 85,119.4 69.2,135.6 	'/>
        <polygon id='XMLID_41_' style={ST_11} points='85,119.4 93.3,133.2 69.2,135.6 	'/>
        <polygon id='XMLID_40_' style={ST_12} points='69.2,135.6 93.3,133.2 79,142.6 	'/>
        <polygon id='XMLID_39_' style={ST_7} points='64.1,116.8 69.2,135.6 53.5,128.2 	'/>
        <polygon id='XMLID_38_' style={ST_1} points='69.2,135.6 61.3,141.7 53.5,128.2 	'/>
        <polygon id='XMLID_37_' style={ST_4} points='130.8,98.3 120.1,111.2 112,90.7 	'/>
        <polygon id='XMLID_36_' style={ST_0} points='83.5,51.4 67.9,69.1 67.3,53.9 	'/>
        <polygon id='XMLID_35_' style={ST_7} points='117.5,57 105.2,70.8 100.9,52.9 	'/>
        <polygon id='XMLID_34_' style={ST_9} points='132.7,63.2 139.4,84.7 128.3,74.7 	'/>
        <polygon id='XMLID_33_' style={ST_4} points='117.5,57 128.3,74.7 105.2,70.8 	'/>
        <polygon id='XMLID_32_' style={ST_7} points='117.5,57 132.7,63.2 128.3,74.7 	'/>
        <polygon id='XMLID_31_' style={ST_1} points='128.3,74.7 130.8,98.3 112,90.7 	'/>
        <polygon id='XMLID_30_' style={ST_7} points='128.3,74.7 139.4,84.7 130.8,98.3 	'/>
        <polygon id='XMLID_29_' style={ST_12} points='105.2,70.8 128.3,74.7 112,90.7 	'/>
        <polygon id='XMLID_28_' style={ST_5} points='47,68 67.9,69.1 54,85.1 	'/>
        <polygon id='XMLID_27_' style={ST_1} points='67.3,53.9 67.9,69.1 47,68 	'/>
        <polygon id='XMLID_26_' style={ST_1} points='47,68 54,85.1 38.4,78.7 	'/>
        <polygon id='XMLID_25_' style={ST_11} points='47,68 38.4,78.7 36.1,64.5 	'/>
        <polygon id='XMLID_24_' style={ST_0} points='50.5,55.8 47,68 36.1,64.5 	'/>
        <polygon id='XMLID_23_' style={ST_2} points='50.5,55.8 67.3,53.9 47,68 	'/>
        <polygon id='XMLID_22_' style={ST_12} points='78,102.8 85,119.4 64.1,116.8 	'/>
        <polygon id='XMLID_21_' style={ST_6} points='67.9,69.1 72.1,85.9 54,85.1 	'/>
        <polygon id='XMLID_20_' style={ST_0} points='91.8,86.3 78,102.8 72.1,85.9 	'/>
        <polygon id='XMLID_19_' style={ST_7} points='105.2,70.8 112,90.7 91.8,86.3 	'/>
        <polygon id='XMLID_18_' style={ST_0} points='55.2,103.3 78,102.8 64.1,116.8 	'/>
        <polygon id='XMLID_17_' style={ST_11} points='55.2,103.3 64.1,116.8 46.2,112.2 	'/>
        <polygon id='XMLID_16_' style={ST_2} points='54,85.1 55.2,103.3 41.6,95.4 	'/>
        <polygon id='XMLID_15_' style={ST_0} points='55.2,103.3 46.2,112.2 41.6,95.4 	'/>
        <polygon id='XMLID_14_' style={ST_9} points='72.1,85.9 78,102.8 55.2,103.3 	'/>
        <polygon id='XMLID_13_' style={ST_0} points='54,85.1 72.1,85.9 55.2,103.3 	'/>
        <polygon id='XMLID_12_' style={ST_0} points='85.3,64.8 105.2,70.8 91.8,86.3 	'/>
        <polygon id='XMLID_11_' style={ST_1} points='100.9,52.9 105.2,70.8 85.3,64.8 	'/>
        <polygon id='XMLID_10_' style={ST_0} points='83.5,51.4 100.9,52.9 85.3,64.8 	'/>
        <polygon id='XMLID_9_' style={ST_9} points='85.3,64.8 91.8,86.3 72.1,85.9 	'/>
        <polygon id='XMLID_8_' style={ST_2} points='83.5,51.4 85.3,64.8 67.9,69.1 	'/>
        <polygon id='XMLID_7_' style={ST_1} points='67.9,69.1 85.3,64.8 72.1,85.9 	'/>
        <polygon id='XMLID_6_' style={ST_5} points='112,90.7 120.1,111.2 100.4,107.8 	'/>
        <polygon id='XMLID_5_' style={ST_2} points='100.4,107.8 120.1,111.2 107.1,123.5 	'/>
        <polygon id='XMLID_4_' style={ST_1} points='91.8,86.3 100.4,107.8 78,102.8 	'/>
        <polygon id='XMLID_3_' style={ST_4} points='91.8,86.3 112,90.7 100.4,107.8 	'/>
        <polygon id='XMLID_2_' style={ST_0} points='100.4,107.8 107.1,123.5 85,119.4 	'/>
        <polygon id='XMLID_1_' style={ST_7} points='78,102.8 100.4,107.8 85,119.4 	'/>
      </g>
    )
  }
}

export default CipherBall




