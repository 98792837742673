import _ from 'underscore'

// These label enums are unique to metadata.
// i.e. they are different from the enums used by the TrainingSetService elsewhere
// See labels.js
export const POS_LABEL = 1
export const NEG_LABEL = 0

export function get_pos_and_neg_patfam_ids(classifier_metadata, is_test) {
  if (!classifier_metadata) {
    return [[], []]

  }
  const set_obj = is_test ? classifier_metadata.test_set : classifier_metadata.training_set

  if (!set_obj) {
    return [[], []]
  }

  const { pat_fam_ids, labels } = set_obj

  return pat_fam_ids.reduce(([pos_ids, neg_ids], id, idx) => {
    const label = labels[idx]

    if (label === POS_LABEL) {
      return [
        [...pos_ids, id], // add positive
        neg_ids
      ]
    }

    if (label === NEG_LABEL) {
      return [
        pos_ids,
        [...neg_ids, id]  // add negative
      ]
    }

    // Some other case, so just return as is
    return [pos_ids, neg_ids]
  }, [[], []])

}

export function get_metrics(classifier_metadata) {
  if (!classifier_metadata) {
    return null
  }
  return classifier_metadata.metrics
}

export function get_id_to_score(classifier_metadata) {
  if (!classifier_metadata) {
    return {}
  }
  const { training_set } = classifier_metadata
  const { pat_fam_ids, scores } = training_set
  return _.object(pat_fam_ids, scores)
}