import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'

/**
 * Input for naming (and possibly other things?) where we want to enter a new value
 * or choose one from a list of searchable options
 */
const NamerSelector = (
  {
    options,
    value,
    set_selected_value,
    allow_add_values=true, // allows the input and selection of new values not provided in the `options` list
    auto_focus,
    size,
    max_length,
    label,
    placeholder
  }) => {
  const filter = createFilterOptions()
  const [input_ref, set_input_ref] = useState(null)

  useEffect(() => {
    if (input_ref && auto_focus) {
      input_ref.focus()
    }
  }, [input_ref, auto_focus])

  return (
    <Autocomplete
      disablePortal
      size={size || 'small'}
      freeSolo={allow_add_values}
      clearOnBlur
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          set_selected_value({
            id: newValue,
            label: newValue
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          set_selected_value({
            id: newValue.inputValue,
            label: newValue.inputValue
          });
        } else {
          set_selected_value(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, maxLength: max_length}}
          inputRef={input => { set_input_ref(input) }}
          label={label}
          placeholder={placeholder || 'Enter name'}
        />)}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.id);
        if (allow_add_values && inputValue !== '' && !isExisting) {
          filtered.push({
            id: inputValue,
            label: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
    />
  )
}

export default NamerSelector