import React, { Component } from 'react'
import * as d3 from 'd3'

import { BASE_COLOUR } from '../utils/executive_summary_constants.js'

const MARGIN = 0
// colour scale
const colour = d3.scaleLinear()
  .domain([0, 100])
  .range(['#ffffff', BASE_COLOUR])

const OUTLINE_COLOUR = BASE_COLOUR
const FILL_COLOUR    = BASE_COLOUR
const EMPTY_COLOUR   = colour(20)

/**
 * Calculate pipe height
 * @param {integer} k value between 0 and 1 (i.e. 0 gives height of 0; 1 gives height of 2 * radius)
 * @param {integer} radius of the pipe
 */
function get_height(k, radius) {
  let t0, t1 = k * 2 * Math.PI

  // Solve for theta numerically (Newton's method)
  if (k > 0 && k < 1) {
    t1 = Math.pow(12 * k * Math.PI, 1 / 3)
    for (let i = 0; i < 10; ++i) {
      t0 = t1
      t1 = (Math.sin(t0) - t0 * Math.cos(t0) + 2 * k * Math.PI) / (1 - Math.cos(t0))
    }
    k = (1 - Math.cos(t1 / 2)) / 2
  }

  return 2 * radius * k
}

class MiniPipeChart extends Component {
  render() {
    const containerWidth  = this.props.width || 100,
        containerHeight = this.props.height || 100,
        value           = this.props.value // expects value between 0 and 1

    const available_width = Math.min(containerWidth, containerHeight) - (2 * MARGIN)

    // We render the circles with centre (0, 0),
    // and then translate the entire group right and down,
    // so that the centres are at ()
    // so that it is (group_offset, group_offset)
    const radius       = available_width / 2
    const group_offset = radius - MARGIN

    // To render the 'filled' area of the circle, we draw an arc path
    // from (arc_x1, arc_y) to (arc_x2, arc_y)
    const height    = get_height(value, radius)
    const arc_x     = Math.sqrt(Math.pow(radius, 2) - Math.pow(height - radius, 2)) // pythagoras theorem
    const arc_x1    = -arc_x
    const arc_x2    = arc_x
    const arc_y     = radius - height
    const large_arc = value > 0.5 ? 1 : 0 // whether to draw render the path above/below

    return (
      <g>
        <g className='pipe-group' transform={'translate(' + group_offset + ',' + group_offset + ')'} >
          <circle r={radius} fill={EMPTY_COLOUR} />

          {value >= 1 &&
            <circle r={radius} fill={FILL_COLOUR}/>
          }

          {value < 1 &&
            <path
              d={'M ' + arc_x1 + ' ' + arc_y + ' A ' + radius + ' ' + radius + ' 1 ' + large_arc + ' 0 ' + arc_x2 + ' ' + arc_y}
              stroke='none'
              fill={FILL_COLOUR}
            />
          }

          <circle r={radius} fill='none' stroke={OUTLINE_COLOUR} strokeWidth='1.5' />
        </g>
      </g>
    )
  }
}

export default MiniPipeChart
