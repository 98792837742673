import React from 'react'

import AlertModal from '../AlertModal.js'

export const BadInputAlertModal = ({title, error_msg, on_hide}) => {
  return (
    <AlertModal
      title={title}
      on_hide={on_hide}
    >
      <p>
        {error_msg}. Please edit it and try again.
      </p>
    </AlertModal>
  )
}