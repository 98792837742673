import React from 'react'
import cn from 'classnames'

import {EditIcon, NotesIcon} from '../widgets/IconSet.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import TextLink from '../widgets/TextLink.js'

import s from './OrgNotesPopup.module.scss'

const OrgNotesPopup = ({className, notes, reference, on_click_from_edit_handler}) => {

  return (
    <div className={className}>
      <InfoPopover
        placement='bottom'
        toggler={(<span id={reference}><NotesIcon /></span>)}
        interactive={true}
      >
        <div className={cn('position-relative', s.notes_block)}>
          {notes.split('\n').map((note, i) => (<div key={i}>{note}</div>))}

          <TextLink className={s.edit} onClick={on_click_from_edit_handler}><EditIcon /></TextLink>
        </div>
      </InfoPopover>
    </div>
  )
}

export default OrgNotesPopup