import _ from 'underscore'

import { get_as_map } from '../../../utils/utils.js'

import { IS_PENDING, IS_ERROR } from '../constants/family_state.js'
import { POSITIVE, NEGATIVE } from '../constants/labels.js'

function remove_save_flags(patent) {
  // remove the extra convenience properties added by get_full_training_set()
  return _.omit(patent, [IS_PENDING, IS_ERROR])
}

/**
 * Returns the training set patents, but also including updated and new pending/errors.
 * Also adds flags IS_PENDING and IS_ERROR where appropriate.
 * @param {} training_set Array of patent families
 * @param {} id_to_tsfam Lookup for training set, maps from patfam_id to a patent family object.
 * @param {} id_to_tsfam_pending Represents pending requests, maps from patfam_id to a patent family object.
 * @param {} id_to_tsfam_error Represents failed requests, maps from patfam_id to a patent family object.
 * @returns {} Array of patent families
 */
export function get_full_training_set(training_set, id_to_tsfam, id_to_tsfam_pending, id_to_tsfam_error) {
  // Update existing fams (from pending/errors)
  const training_set_with_updates = update_patents_with_pending_and_errors(training_set, id_to_tsfam_pending, id_to_tsfam_error)

  // Add new fams (from pending/errors)
  const new_pending_fams = _.values(id_to_tsfam_pending)
        .filter(fam => !id_to_tsfam[fam.id])
        .map(fam => ({ ...fam, [IS_PENDING]: true }))

  const new_error_fams = _.values(id_to_tsfam_error)
        .filter(fam => !id_to_tsfam[fam.id])
        .map(fam => ({ ...fam, [IS_ERROR]: true }))

  return [ ...training_set_with_updates, ...new_pending_fams, ...new_error_fams ]
}

export function update_patents_with_ts_and_pending_and_errors(patents, id_to_tsfam, id_to_tsfam_pending, id_to_tsfam_error) {
  const patents_with_ts = update_patents_with_ts_user_class(patents || [], id_to_tsfam || {})

  return update_patents_with_pending_and_errors(patents_with_ts, id_to_tsfam_pending, id_to_tsfam_error)
}

export function update_patents_with_pending_and_errors(patents, id_to_tsfam_pending, id_to_tsfam_error) {
  return patents.map(fam => {
    const { id } = fam

    const error_fam = id_to_tsfam_error[id]
    if (error_fam) {
      return { ...fam, [IS_ERROR]: true }
    }

    const pending_fam = id_to_tsfam_pending[id]
    if (pending_fam) {
      return { ...fam, user_class: pending_fam.user_class, [IS_PENDING]: true }
    }

    return fam
  })
}

export function update_patents_with_ts_user_class(patents, id_to_tsfam) {
  return patents.map(fam => {
    const { id } = fam
    const ts_fam = id_to_tsfam[id]
    return {
      ...fam,
      ...(ts_fam != null ? _.pick(ts_fam, 'user_class') : {})
    }
  })
}

/**
 *
 * @param {} patent
 * @param {} label i.e. POSITIVE, NEGATIVE, IGNORE, UNKNOWN
 * @param {} source i.e. BOOLEAN_SEARCH, SUGGESTIONS etc...
 * @param {} id_to_tsfam_pending
 * @param {} id_to_tsfam_error
 * @returns {}
 */
export function get_state_on_set_label_local(patent, label, source, id_to_tsfam, id_to_tsfam_pending, id_to_tsfam_error) {
  const { id } = patent

  // Clear any previous error
  const new_id_to_tsfam_error = _.omit(id_to_tsfam_error, id)

  // Add to pending
  const new_id_to_tsfam_pending = {
    ...id_to_tsfam_pending,
    [id]: {
      ...remove_save_flags(patent),
      user_class: label,
      source
    }
  }

  return {
    id_to_tsfam_pending: new_id_to_tsfam_pending,
    id_to_tsfam_error:   new_id_to_tsfam_error
  }
}

export function get_state_on_success_setting_remote_label(old_patent, training_set_patfams, id_to_tsfam, id_to_tsfam_pending) {
  const { id } = old_patent

  // The new patent
  const new_patent = id_to_tsfam_pending[id]
  const new_patent_clean = remove_save_flags(new_patent)

  // Remove from pending
  const new_id_to_tsfam_pending = _.omit(id_to_tsfam_pending, id)

  // Update/add in training_set
  const new_training_set_patfams = id_to_tsfam[id] ?
    training_set_patfams.map(p => p.id === id ? new_patent_clean : p) // update
    : [ ...training_set_patfams, new_patent_clean ] // add

  const new_id_to_tsfam = get_as_map(new_training_set_patfams, 'id')

  return {
    training_set_patfams:  new_training_set_patfams,
    id_to_tsfam:           new_id_to_tsfam,
    id_to_tsfam_pending:   new_id_to_tsfam_pending
  }
}

export function get_state_on_error_setting_remote_label(error, patent, id_to_tsfam, id_to_tsfam_pending, id_to_tsfam_error) {
  const { id } = patent

  // The new (failed) patent
  const new_patent = id_to_tsfam_pending[id]

  // Remove from pending
  const new_id_to_tsfam_pending = _.omit(id_to_tsfam_pending, id)

  // Add to errors
  const new_id_to_tsfam_error = {
    ...id_to_tsfam_error,
    [id]: { ...new_patent, error }
  }

  return {
    id_to_tsfam_pending: new_id_to_tsfam_pending,
    id_to_tsfam_error:   new_id_to_tsfam_error
  }
}

export function format_score(score) {
  if (score == null) {
    return score
  }

  // 2 decimal places, but rounded down (i.e. 0.494 -> 0.49,  0.496 -> 0.49)
  return (Math.floor(score * 100) / 100).toFixed(2)
}

export function display_score(score, separator = ', ') {
  return _.isArray(score) ? score.map(item => format_score(item)).join(separator) : format_score(score)
}

export function contains_some_positives_or_negatives(training_set_patfams) {
  return training_set_patfams.some(patent => _.contains([POSITIVE, NEGATIVE], patent.user_class))
}

export function contains_some_positives(training_set_patfams) {
  return training_set_patfams.some(patent => patent.user_class === POSITIVE)
}

export function contains_some_negatives(training_set_patfams) {
  return training_set_patfams.some(patent => patent.user_class === NEGATIVE)
}