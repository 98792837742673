import { get_as_map } from '../../../utils/utils.js'

export const IGNORE_VALUES = [
  { id: 0, value: false, name: 'Show not ignore' },
  { id: 1, value: true, name: 'Show ignored' },
]
export const ID_TO_IGNORE_VALUES = get_as_map(IGNORE_VALUES, 'id')

export function get_all_ignore_value_ids() {
  return IGNORE_VALUES.map(value => value.id)
}

export const TYPE_VALUES = [
  { id: 0, value: '', name: 'Empty'},
  { id: 1, value: 'app', name: 'Application' },
  { id: 2, value: 'pub', name: 'Publication' },
  {id: 3, value: 'fam', name: 'Family'}
]
export const ID_TO_TYPE_VALUES = get_as_map(TYPE_VALUES, 'id')

export function get_all_type_ids() {
  return TYPE_VALUES.map(value => value.id)
}

export const LINENO = 'lineno'
export const STATUS = 'status'
export const SOURCE = 'source'
export const IGNORE = 'ignore'
export const SERIAL = 'serial'
export const TYPE = 'type'
export const PUB_NUMBER = 'publication'
export const CIPHER_FAMILY_ID = 'family'
export const APPLICATION_DATE = 'application_date'
export const ASSIGNEE = 'assignee'
export const TITLE = 'title'
export const OWNER = 'owner'
export const ALL_FIELDS = [
  {id: LINENO, name: 'Line'},
  {id: STATUS, name: 'Result'},
  {id: SOURCE, name: 'Source'},
  {id: IGNORE, name: 'Ignore'},
  {id: SERIAL, name: 'Matches'},
  {id: TYPE, name: 'Type'},
  {id: PUB_NUMBER, name: 'Pub number'},
  {id: APPLICATION_DATE, name: 'App date'},
  {id: CIPHER_FAMILY_ID, name: 'Family'},
  {id: OWNER, name: 'Owner'},
  {id: ASSIGNEE, name: 'Assignee'},
  {id: TITLE, name: 'Title'}
]

export const ID_TO_ALL_FIELDS = get_as_map(ALL_FIELDS, 'id')

const UNRECOGNISED_FORMAT_DESC = 'Unrecognised format'
const UNKNOWN_NUMBER_DESC = 'Unknown number'
const AMBIGUOUS_DESC = 'Ambiguous'
const FOUND_DESC = 'Found'

export const UNRECOGNISED_FORMAT = { id: 1, value: UNRECOGNISED_FORMAT_DESC, name: UNRECOGNISED_FORMAT_DESC }
export const UNKNOWN_NUMBER      = { id: 2, value: UNKNOWN_NUMBER_DESC, name: UNKNOWN_NUMBER_DESC }
export const AMBIGUOUS           = { id: 3, value: AMBIGUOUS_DESC, name: AMBIGUOUS_DESC }
export const FOUND               = { id: 4, value: FOUND_DESC, name: FOUND_DESC }
export const STATUSES      = [ UNRECOGNISED_FORMAT, UNKNOWN_NUMBER, AMBIGUOUS, FOUND ]
export const SPIF_STATUSES = [ UNRECOGNISED_FORMAT, UNKNOWN_NUMBER, AMBIGUOUS ]

export function get_all_status_ids() {
  return STATUSES.map(status => status.id)
}

export function get_all_spif_status_ids() {
  return SPIF_STATUSES.map(status => status.id)
}
export const ID_TO_STATUS = get_as_map(STATUSES, 'id')

export const DEFAULT_FIELDS = [LINENO, STATUS, SOURCE, IGNORE, TYPE, SERIAL, APPLICATION_DATE, CIPHER_FAMILY_ID, OWNER, TITLE]
