import { add_source_err_to_target_err } from './axios_utils.js'

export function check_local_storage_available() {
  // Local strorage can be disabled in some browsers, especially if user is browing in 'private' mode
  // In such cases, trying to access it will throw a Uncaught Error: SecurityError: DOM Exception 18
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch(e) {
    return false
  }
}

export function get_from_local_storage(key) {
  return JSON.parse(localStorage.getItem(key))
}

export function save_to_local_storage(key, obj) {
  try {
    localStorage.setItem(key, JSON.stringify(obj))
  } catch(err) {
    if (err.name === 'QuotaExceededError') {
      // Currently, LocalStorage is used for saving temporary report input when creating new reports from existing - see 'report_builder_utils.js'.
      // In most cases this is fine. But very occasionally we exceed the size limit, for example if the input has many orgs.
      // The limit varies per browser and user, but on Chrome it can be about 5.2million chars.
      // See https://arty.name/localstorage.html
      // For now, there is not much we can do. We provide a slightly more helpful error message here.
      // TODO: we should save this stuff in the db, not local storage.
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save report as input too large for local storage: ')
      throw wrapped_err
    } else {
      throw err
    }
  }
}

export function remove_from_local_storage(key) {
  localStorage.removeItem(key)
}

export function clear_all_local_storage() {
  localStorage.clear()
}
