import React from 'react'
import * as d3 from 'd3'
import _ from 'underscore'

import { EXECUTIVE_SUMMARY_COLOURS } from '../utils/executive_summary_constants.js'

const TOP_MARGIN = 5

class MiniLineChart extends React.Component{
  constructor(props) {
      super(props)
      this.chart_container = React.createRef()
  }

  componentDidMount() {
    const containerWidth  = this.props.width || 100,
        containerHeight = this.props.height || 100,
        values          = this.props.values,
        colours         = this.props.colours || EXECUTIVE_SUMMARY_COLOURS

    // Convert data
    // For now, assume single series...
    const min_max = d3.extent(_.flatten(values))

    const num_categories = values[0].length

    // Render chart
    const margin = {top: TOP_MARGIN, right: 0, bottom: 5, left: 0},
        width = containerWidth - margin.left - margin.right,
        height = containerHeight - margin.top - margin.bottom

    const xScale = d3.scaleLinear()
        .domain([0, num_categories - 1])
        .range([0, width])

    const yScale = d3.scaleLinear()
        .domain([0, min_max[1]])// input
        .range([height, 0])// output

    const line = d3.line()
        .x(function(d, i) { return xScale(i) }) // input
        .y(function(d) { return yScale(d.y) }) // output
        .curve(d3.curveMonotoneX)


    const canvas = d3.select(this.chart_container.current)

    const layer = canvas.append('g')
      .selectAll('.layer')
      .data(values)
      .enter().append('g')
        .attr('class', 'layer')
        .style('fill', 'none')
        .style('stroke', function(d, i) { return colours[i]; })
        .style('stroke-width', 1)


    const dataset = d3.range(values[0].length).map(function(d,i) { return {'y': values[0][i]} })
    layer.append('path')
        .datum(dataset)
        .attr('class', 'line')
        .attr('d', line)


  }

  render (){
    return (
      <g transform={'translate(0, ' + TOP_MARGIN + ')'} ref={this.chart_container} className='mini-line-chart'/>
    )
  }
}

export default MiniLineChart
