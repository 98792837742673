import React from 'react'
import cn from 'classnames'

import Spinner from '../widgets/Spinner.js'

import s from './FullPageSpinner.module.scss'

const FullPageSpinner = () => {
  return (
    <div className={cn('w-100 h-100', s.page_overlay)}>
      <div className={cn(s.spinner_wrapper)}>
        <Spinner size='xl' />
      </div>
    </div>
  )
}

export default FullPageSpinner