import _ from 'underscore'
import {get_as_map} from '../utils/utils.js'

export const WO = 'WO'
export const EP = 'EP'
export const US = 'US'
export const CN = 'CN'
export const JP = 'JP'
export const KR = 'KR'
export const DE = 'DE'
export const EUROPE_CODE = 'EU'

export const CONTINENTS = [
  { id: 'AF',        name: 'Africa' },
  { id: 'AS',        name: 'Asia' },
  { id: EUROPE_CODE, name: 'Europe' },
  { id: 'NA',        name: 'North America' },
  { id: 'SA',        name: 'South America' },
  { id: 'AN',        name: 'Antarctica' },
  { id: 'OC',        name: 'Australia/Oceania' },
  { id: 'OT',        name: 'Other' },
  { id: WO,          name: 'WIPO'}
]

export const ID_TO_CONTINENT = get_as_map(CONTINENTS, 'id')

// TODO: create large colour scheme, apply to countries directly here
export const COUNTRIES =
[
  { 'country_code': 'AD',                 'name': 'Andorra', 'continent_id': 'EU' },
  { 'country_code': 'AE',                 'name': 'United Arab Emirates', 'continent_id': 'AS' },
  { 'country_code': 'AF',                 'name': 'Afghanistan', 'continent_id': 'AS' },
  { 'country_code': 'AG',                 'name': 'Antigua and Barbuda', 'continent_id': 'NA' },
  { 'country_code': 'AI',                 'name': 'Anguilla', 'continent_id': 'NA' },
  { 'country_code': 'AL',                 'name': 'Albania', 'continent_id': 'EU' },
  { 'country_code': 'AM',                 'name': 'Armenia', 'continent_id': 'EU' },
  { 'country_code': 'AN',                 'name': 'Netherlands Antilles', 'continent_id': 'NA' },
  { 'country_code': 'AO',                 'name': 'Angola', 'continent_id': 'AF' },
  { 'country_code': 'AP',                 'name': 'African Regional IP Organization (ARIPO)', 'continent_id': 'AF'},
  { 'country_code': 'AQ',                 'name': 'Antarctica', 'continent_id': 'AN' },
  { 'country_code': 'AR',                 'name': 'Argentina', 'continent_id': 'SA' },
  { 'country_code': 'AS',                 'name': 'American Samoa', 'continent_id': 'OC' },
  { 'country_code': 'AT',                 'name': 'Austria', 'continent_id': 'EU' },
  { 'country_code': 'AU', 'gdp_rank': 13, 'name': 'Australia', 'continent_id': 'OC' },
  { 'country_code': 'AW',                 'name': 'Aruba', 'continent_id': 'NA' },
  { 'country_code': 'AX',                 'name': 'Åland Islands', 'continent_id': 'EU' },
  { 'country_code': 'AZ',                 'name': 'Azerbaijan', 'continent_id': 'EU' },
  { 'country_code': 'BA',                 'name': 'Bosnia and Herzegovina', 'continent_id': 'EU' },
  { 'country_code': 'BB',                 'name': 'Barbados', 'continent_id': 'NA' },
  { 'country_code': 'BD',                 'name': 'Bangladesh', 'continent_id': 'AS' },
  { 'country_code': 'BE',                 'name': 'Belgium', 'continent_id': 'EU' },
  { 'country_code': 'BF',                 'name': 'Burkina Faso', 'continent_id': 'AF' },
  { 'country_code': 'BG',                 'name': 'Bulgaria', 'continent_id': 'EU' },
  { 'country_code': 'BH',                 'name': 'Bahrain', 'continent_id': 'AS' },
  { 'country_code': 'BI',                 'name': 'Burundi', 'continent_id': 'AF' },
  { 'country_code': 'BJ',                 'name': 'Benin', 'continent_id': 'AF' },
  { 'country_code': 'BM',                 'name': 'Bermuda', 'continent_id': 'NA' },
  { 'country_code': 'BN',                 'name': 'Brunei Darussalam', 'continent_id': 'AS' },
  { 'country_code': 'BO',                 'name': 'Bolivia', 'continent_id': 'SA' },
  { 'country_code': 'BR', 'gdp_rank': 8,  'name': 'Brazil', 'continent_id': 'SA' },
  { 'country_code': 'BS',                 'name': 'Bahamas', 'continent_id': 'NA' },
  { 'country_code': 'BT',                 'name': 'Bhutan', 'continent_id': 'AS' },
  { 'country_code': 'BV',                 'name': 'Bouvet Island', 'continent_id': 'AN' },
  { 'country_code': 'BW',                 'name': 'Botswana', 'continent_id': 'AF' },
  { 'country_code': 'BX',                 'name': 'Benelux Office for Intellectual Property', 'continent_id': 'EU'},
  { 'country_code': 'BY',                 'name': 'Belarus', 'continent_id': 'EU' },
  { 'country_code': 'BZ',                 'name': 'Belize', 'continent_id': 'NA' },
  { 'country_code': 'CA', 'gdp_rank': 10, 'name': 'Canada', 'continent_id': 'NA' },
  { 'country_code': 'CC',                 'name': 'Cocos (Keeling) Islands', 'continent_id': 'AS' },
  { 'country_code': 'CD',                 'name': 'Congo, The Democratic Republic of the', 'continent_id': 'AF' },
  { 'country_code': 'CF',                 'name': 'Central African Republic', 'continent_id': 'AF' },
  { 'country_code': 'CG',                 'name': 'Congo', 'continent_id': 'AF' },
  { 'country_code': 'CH', 'gdp_rank': 20, 'name': 'Switzerland', 'continent_id': 'EU' },
  { 'country_code': 'CI',                 'name': "Cote D'Ivoire", 'continent_id': 'AF' },
  { 'country_code': 'CK',                 'name': 'Cook Islands', 'continent_id': 'OC' },
  { 'country_code': 'CL',                 'name': 'Chile', 'continent_id': 'SA' },
  { 'country_code': 'CM',                 'name': 'Cameroon', 'continent_id': 'AF' },
  { 'country_code': 'CN', 'gdp_rank': 2,  'name': 'China', 'continent_id': 'AS' },
  { 'country_code': 'CO',                 'name': 'Colombia', 'continent_id': 'SA' },
  { 'country_code': 'CR',                 'name': 'Costa Rica', 'continent_id': 'NA' },
  { 'country_code': 'CS',                 'name': 'Serbia and Montenegro', 'continent_id': 'EU' },
  { 'country_code': 'CT',                 'name': 'Canton and Enderbury Islands', 'continent_id': 'OC' },
  { 'country_code': 'CU',                 'name': 'Cuba', 'continent_id': 'NA' },
  { 'country_code': 'CV',                 'name': 'Cape Verde', 'continent_id': 'AF' },
  { 'country_code': 'CX',                 'name': 'Christmas Island', 'continent_id': 'AS' },
  { 'country_code': 'CY',                 'name': 'Cyprus', 'continent_id': 'EU' },
  { 'country_code': 'CZ',                 'name': 'Czech Republic', 'continent_id': 'EU' },
  { 'country_code': 'DD',                 'name': 'East Germany', 'continent_id': 'EU'},
  { 'country_code': DE,   'gdp_rank': 4,  'name': 'Germany', 'continent_id': 'EU' },
  { 'country_code': 'DJ',                 'name': 'Djibouti', 'continent_id': 'AF' },
  { 'country_code': 'DK',                 'name': 'Denmark', 'continent_id': 'EU' },
  { 'country_code': 'DM',                 'name': 'Dominica', 'continent_id': 'NA' },
  { 'country_code': 'DO',                 'name': 'Dominican Republic', 'continent_id': 'NA' },
  { 'country_code': 'DZ',                 'name': 'Algeria', 'continent_id': 'AF' },
  { 'country_code': 'EA',                 'name': 'Eurasian Patent Organization (EAPO)', 'continent_id': 'OT'},
  { 'country_code': 'EC',                 'name': 'Ecuador', 'continent_id': 'SA' },
  { 'country_code': 'EE',                 'name': 'Estonia', 'continent_id': 'EU' },
  { 'country_code': 'EF',                 'name': 'Union of Countries under the European Community Patent Convention', 'continent_id': 'EU'},
  { 'country_code': 'EG',                 'name': 'Egypt', 'continent_id': 'AF' },
  { 'country_code': 'EH',                 'name': 'Western Sahara', 'continent_id': 'AF' },
  { 'country_code': 'EM',                 'name': 'European Union Intellectual Property Office (EUIPO)', 'continent_id': 'EU'},
  { 'country_code': EP,                   'name': 'EPO', 'continent_id': 'EU'},
  { 'country_code': 'ER',                 'name': 'Eritrea', 'continent_id': 'AF' },
  { 'country_code': 'ES', 'gdp_rank': 14, 'name': 'Spain', 'continent_id': 'EU' },
  { 'country_code': 'ET',                 'name': 'Ethiopia', 'continent_id': 'AF' },
  { 'country_code': 'FI',                 'name': 'Finland', 'continent_id': 'EU' },
  { 'country_code': 'FJ',                 'name': 'Fiji', 'continent_id': 'OC' },
  { 'country_code': 'FK',                 'name': 'Falkland Islands (Malvinas)', 'continent_id': 'SA' },
  { 'country_code': 'FM',                 'name': 'Micronesia, Federated States of', 'continent_id': 'OC' },
  { 'country_code': 'FO',                 'name': 'Faroe Islands', 'continent_id': 'EU' },
  { 'country_code': 'FR', 'gdp_rank': 7,  'name': 'France', 'continent_id': 'EU' },
  { 'country_code': 'FX',                 'name': 'Metropolitan France', 'continent_id': 'EU'},
  { 'country_code': 'GA',                 'name': 'Gabon', 'continent_id': 'AF' },
  { 'country_code': 'GB', 'gdp_rank': 5,  'name': 'United Kingdom', 'continent_id': 'EU' },
  { 'country_code': 'GC',                 'name': 'Gulf Cooperation Council', 'continent_id': 'AS'},
  { 'country_code': 'GD',                 'name': 'Grenada', 'continent_id': 'NA' },
  { 'country_code': 'GE',                 'name': 'Georgia', 'continent_id': 'EU' },
  { 'country_code': 'GF',                 'name': 'French Guiana', 'continent_id': 'SA' },
  { 'country_code': 'GG',                 'name': 'Guernsey', 'continent_id': 'EU' },
  { 'country_code': 'GH',                 'name': 'Ghana', 'continent_id': 'AF' },
  { 'country_code': 'GI',                 'name': 'Gibraltar', 'continent_id': 'EU' },
  { 'country_code': 'GL',                 'name': 'Greenland', 'continent_id': 'NA' },
  { 'country_code': 'GM',                 'name': 'Gambia', 'continent_id': 'AF' },
  { 'country_code': 'GN',                 'name': 'Guinea', 'continent_id': 'AF' },
  { 'country_code': 'GP',                 'name': 'Guadeloupe', 'continent_id': 'NA' },
  { 'country_code': 'GQ',                 'name': 'Equatorial Guinea', 'continent_id': 'AF' },
  { 'country_code': 'GR',                 'name': 'Greece', 'continent_id': 'EU' },
  { 'country_code': 'GS',                 'name': 'South Georgia and the South Sandwich Islands', 'continent_id': 'AN' },
  { 'country_code': 'GT',                 'name': 'Guatemala', 'continent_id': 'NA' },
  { 'country_code': 'GU',                 'name': 'Guam', 'continent_id': 'OC' },
  { 'country_code': 'GW',                 'name': 'Guinea-Bissau', 'continent_id': 'AF' },
  { 'country_code': 'GY',                 'name': 'Guyana', 'continent_id': 'SA' },
  { 'country_code': 'HK',                 'name': 'Hong Kong', 'continent_id': 'AS' },
  { 'country_code': 'HM',                 'name': 'Heard Island and Mcdonald Islands', 'continent_id': 'AN' },
  { 'country_code': 'HN',                 'name': 'Honduras', 'continent_id': 'NA' },
  { 'country_code': 'HR',                 'name': 'Croatia', 'continent_id': 'EU' },
  { 'country_code': 'HT',                 'name': 'Haiti', 'continent_id': 'NA' },
  { 'country_code': 'HU',                 'name': 'Hungary', 'continent_id': 'EU' },
  { 'country_code': 'ID', 'gdp_rank': 16, 'name': 'Indonesia', 'continent_id': 'AS' },
  { 'country_code': 'IE',                 'name': 'Ireland', 'continent_id': 'EU' },
  { 'country_code': 'IL',                 'name': 'Israel', 'continent_id': 'AS' },
  { 'country_code': 'IM',                 'name': 'Isle of Man', 'continent_id': 'EU' },
  { 'country_code': 'IN', 'gdp_rank': 6,  'name': 'India', 'continent_id': 'AS' },
  { 'country_code': 'IO',                 'name': 'British Indian Ocean Territory', 'continent_id': 'AS' },
  { 'country_code': 'IQ',                 'name': 'Iraq', 'continent_id': 'AS' },
  { 'country_code': 'IR',                 'name': 'Iran', 'continent_id': 'AS' },
  { 'country_code': 'IS',                 'name': 'Iceland', 'continent_id': 'EU' },
  { 'country_code': 'IT', 'gdp_rank': 9,  'name': 'Italy', 'continent_id': 'EU' },
  { 'country_code': 'JE',                 'name': 'Jersey', 'continent_id': 'EU' },
  { 'country_code': 'JM',                 'name': 'Jamaica', 'continent_id': 'NA' },
  { 'country_code': 'JO',                 'name': 'Jordan', 'continent_id': 'AS' },
  { 'country_code': 'JP', 'gdp_rank': 3,  'name': 'Japan', 'continent_id': 'AS' },
  { 'country_code': 'KE',                 'name': 'Kenya', 'continent_id': 'AF' },
  { 'country_code': 'KG',                 'name': 'Kyrgyz Republic', 'continent_id': 'AS' },
  { 'country_code': 'KH',                 'name': 'Cambodia', 'continent_id': 'AS' },
  { 'country_code': 'KI',                 'name': 'Kiribati', 'continent_id': 'OC' },
  { 'country_code': 'KM',                 'name': 'Comoros', 'continent_id': 'AF' },
  { 'country_code': 'KN',                 'name': 'Saint Kitts and Nevis', 'continent_id': 'NA' },
  { 'country_code': 'KP', 'gdp_rank': 11, 'name': "Korea, Democratic People's Republic of", 'continent_id': 'AS' },
  { 'country_code': 'KR',                 'name': 'Korea, Republic of', 'continent_id': 'AS' },
  { 'country_code': 'KW',                 'name': 'Kuwait', 'continent_id': 'AS' },
  { 'country_code': 'KY',                 'name': 'Cayman Islands', 'continent_id': 'NA' },
  { 'country_code': 'KZ',                 'name': 'Kazakhstan', 'continent_id': 'EU' },
  { 'country_code': 'LA',                 'name': "Lao People's Democratic Republic", 'continent_id': 'AS' },
  { 'country_code': 'LB',                 'name': 'Lebanon', 'continent_id': 'AS' },
  { 'country_code': 'LC',                 'name': 'Saint Lucia', 'continent_id': 'NA' },
  { 'country_code': 'LI',                 'name': 'Liechtenstein', 'continent_id': 'EU' },
  { 'country_code': 'LK',                 'name': 'Sri Lanka', 'continent_id': 'AS' },
  { 'country_code': 'LR',                 'name': 'Liberia', 'continent_id': 'AF' },
  { 'country_code': 'LS',                 'name': 'Lesotho', 'continent_id': 'AF' },
  { 'country_code': 'LT',                 'name': 'Lithuania', 'continent_id': 'EU' },
  { 'country_code': 'LU',                 'name': 'Luxembourg', 'continent_id': 'EU' },
  { 'country_code': 'LV',                 'name': 'Latvia', 'continent_id': 'EU' },
  { 'country_code': 'LY',                 'name': 'Libyan Arab Jamahiriya', 'continent_id': 'AF' },
  { 'country_code': 'MA',                 'name': 'Morocco', 'continent_id': 'AF' },
  { 'country_code': 'MC',                 'name': 'Monaco', 'continent_id': 'EU' },
  { 'country_code': 'MD',                 'name': 'Moldova, Republic of', 'continent_id': 'EU' },
  { 'country_code': 'ME',                 'name': 'Montenegro', 'continent_id': 'EU'},
  { 'country_code': 'MG',                 'name': 'Madagascar', 'continent_id': 'AF' },
  { 'country_code': 'MH',                 'name': 'Marshall Islands', 'continent_id': 'OC' },
  { 'country_code': 'MK',                 'name': 'Macedonia', 'continent_id': 'EU' },
  { 'country_code': 'ML',                 'name': 'Mali', 'continent_id': 'AF' },
  { 'country_code': 'MM',                 'name': 'Myanmar', 'continent_id': 'AS' },
  { 'country_code': 'MN',                 'name': 'Mongolia', 'continent_id': 'AS' },
  { 'country_code': 'MO',                 'name': 'Macao', 'continent_id': 'AS' },
  { 'country_code': 'MP',                 'name': 'Northern Mariana Islands', 'continent_id': 'OC' },
  { 'country_code': 'MQ',                 'name': 'Martinique', 'continent_id': 'NA' },
  { 'country_code': 'MR',                 'name': 'Mauritania', 'continent_id': 'AF' },
  { 'country_code': 'MS',                 'name': 'Montserrat', 'continent_id': 'NA' },
  { 'country_code': 'MT',                 'name': 'Malta', 'continent_id': 'EU' },
  { 'country_code': 'MU',                 'name': 'Mauritius', 'continent_id': 'AF' },
  { 'country_code': 'MV',                 'name': 'Maldives', 'continent_id': 'AS' },
  { 'country_code': 'MW',                 'name': 'Malawi', 'continent_id': 'AF' },
  { 'country_code': 'MX', 'gdp_rank': 15, 'name': 'Mexico', 'continent_id': 'NA' },
  { 'country_code': 'MY',                 'name': 'Malaysia', 'continent_id': 'AS' },
  { 'country_code': 'MZ',                 'name': 'Mozambique', 'continent_id': 'AF' },
  { 'country_code': 'NA',                 'name': 'Namibia', 'continent_id': 'AF' },
  { 'country_code': 'NC',                 'name': 'New Caledonia', 'continent_id': 'OC' },
  { 'country_code': 'NE',                 'name': 'Niger', 'continent_id': 'AF' },
  { 'country_code': 'NF',                 'name': 'Norfolk Island', 'continent_id': 'OC' },
  { 'country_code': 'NG',                 'name': 'Nigeria', 'continent_id': 'AF' },
  { 'country_code': 'NI',                 'name': 'Nicaragua', 'continent_id': 'NA' },
  { 'country_code': 'NL', 'gdp_rank': 18, 'name': 'Netherlands', 'continent_id': 'EU' },
  { 'country_code': 'NO',                 'name': 'Norway', 'continent_id': 'EU' },
  { 'country_code': 'NP',                 'name': 'Nepal', 'continent_id': 'AS' },
  { 'country_code': 'NQ',                 'name': 'Dronning Maud Land', 'continent_id': 'AN' },
  { 'country_code': 'NR',                 'name': 'Nauru', 'continent_id': 'OC' },
  { 'country_code': 'NT',                 'name': 'Neutral Zone', 'continent_id': 'OT' },
  { 'country_code': 'NU',                 'name': 'Niue', 'continent_id': 'OC' },
  { 'country_code': 'NZ',                 'name': 'New Zealand', 'continent_id': 'OC' },
  { 'country_code': 'OA',                 'name': 'African Intellectual Property Organization (OAPI)', 'continent_id': 'AF'},
  { 'country_code': 'OM',                 'name': 'Oman', 'continent_id': 'AS' },
  { 'country_code': 'PA',                 'name': 'Panama', 'continent_id': 'NA' },
  { 'country_code': 'PC',                 'name': 'Pacific Islands (Trust Territory)', 'continent_id': 'OC' },
  { 'country_code': 'PE',                 'name': 'Peru', 'continent_id': 'SA' },
  { 'country_code': 'PF',                 'name': 'French Polynesia', 'continent_id': 'OC' },
  { 'country_code': 'PG',                 'name': 'Papua New Guinea', 'continent_id': 'OC' },
  { 'country_code': 'PH',                 'name': 'Philippines', 'continent_id': 'AS' },
  { 'country_code': 'PK',                 'name': 'Pakistan', 'continent_id': 'AS' },
  { 'country_code': 'PL',                 'name': 'Poland', 'continent_id': 'EU' },
  { 'country_code': 'PM',                 'name': 'Saint Pierre and Miquelon', 'continent_id': 'NA' },
  { 'country_code': 'PN',                 'name': 'Pitcairn', 'continent_id': 'OC' },
  { 'country_code': 'PR',                 'name': 'Puerto Rico', 'continent_id': 'NA' },
  { 'country_code': 'PS',                 'name': 'Palestinian Territory, Occupied', 'continent_id': 'AS' },
  { 'country_code': 'PT',                 'name': 'Portugal', 'continent_id': 'EU' },
  { 'country_code': 'PW',                 'name': 'Palau', 'continent_id': 'OC' },
  { 'country_code': 'PY',                 'name': 'Paraguay', 'continent_id': 'SA' },
  { 'country_code': 'PZ',                 'name': 'Panama Canal Zone', 'continent_id': 'NA' },
  { 'country_code': 'QA',                 'name': 'Qatar', 'continent_id': 'AS' },
  { 'country_code': 'QP',                 'name': 'Paracel Islands', 'continent_id': 'AS' },
  { 'country_code': 'RE',                 'name': 'Reunion', 'continent_id': 'AF' },
  { 'country_code': 'RO',                 'name': 'Romania', 'continent_id': 'EU' },
  { 'country_code': 'RS',                 'name': 'Serbia', 'continent_id': 'EU'},
  { 'country_code': 'RU', 'gdp_rank': 12, 'name': 'Russian Federation', 'continent_id': 'EU' },
  { 'country_code': 'RW',                 'name': 'Rwanda', 'continent_id': 'AF' },
  { 'country_code': 'SA', 'gdp_rank': 19, 'name': 'Saudi Arabia', 'continent_id': 'AS' },
  { 'country_code': 'SB',                 'name': 'Solomon Islands', 'continent_id': 'OC' },
  { 'country_code': 'SC',                 'name': 'Seychelles', 'continent_id': 'AF' },
  { 'country_code': 'SD',                 'name': 'Sudan', 'continent_id': 'AF' },
  { 'country_code': 'SE',                 'name': 'Sweden', 'continent_id': 'EU' },
  { 'country_code': 'SG',                 'name': 'Singapore', 'continent_id': 'AS' },
  { 'country_code': 'SH',                 'name': 'Saint Helena', 'continent_id': 'AF' },
  { 'country_code': 'SI',                 'name': 'Slovenia', 'continent_id': 'EU' },
  { 'country_code': 'SJ',                 'name': 'Svalbard and Jan Mayen', 'continent_id': 'EU' },
  { 'country_code': 'SK',                 'name': 'Slovakia', 'continent_id': 'EU' },
  { 'country_code': 'SL',                 'name': 'Sierra Leone', 'continent_id': 'AF' },
  { 'country_code': 'SM',                 'name': 'San Marino', 'continent_id': 'EU' },
  { 'country_code': 'SN',                 'name': 'Senegal', 'continent_id': 'AF' },
  { 'country_code': 'SO',                 'name': 'Somalia', 'continent_id': 'AF' },
  { 'country_code': 'SR',                 'name': 'Suriname', 'continent_id': 'SA' },
  { 'country_code': 'ST',                 'name': 'Sao Tome and Principe', 'continent_id': 'AF' },
  { 'country_code': 'SU',                 'name': 'Soviet Union', 'continent_id': 'EU'},
  { 'country_code': 'SV',                 'name': 'El Salvador', 'continent_id': 'NA' },
  { 'country_code': 'SY',                 'name': 'Syrian Arab Republic', 'continent_id': 'AS' },
  { 'country_code': 'SZ',                 'name': 'Swaziland', 'continent_id': 'AF' },
  { 'country_code': 'TC',                 'name': 'Turks and Caicos Islands', 'continent_id': 'NA' },
  { 'country_code': 'TD',                 'name': 'Chad', 'continent_id': 'AF' },
  { 'country_code': 'TF',                 'name': 'French Southern Territories', 'continent_id': 'AN' },
  { 'country_code': 'TG',                 'name': 'Togo', 'continent_id': 'AF' },
  { 'country_code': 'TH',                 'name': 'Thailand', 'continent_id': 'AS' },
  { 'country_code': 'TJ',                 'name': 'Tajikistan', 'continent_id': 'AS' },
  { 'country_code': 'TK',                 'name': 'Tokelau', 'continent_id': 'OC' },
  { 'country_code': 'TL',                 'name': 'Timor-Leste', 'continent_id': 'AS' },
  { 'country_code': 'TM',                 'name': 'Turkmenistan', 'continent_id': 'AS' },
  { 'country_code': 'TN',                 'name': 'Tunisia', 'continent_id': 'AF' },
  { 'country_code': 'TO',                 'name': 'Tonga', 'continent_id': 'OC' },
  { 'country_code': 'TP',                 'name': 'East Timor', 'continent_id': 'AS'},
  { 'country_code': 'TR', 'gdp_rank': 17, 'name': 'Turkey', 'continent_id': 'EU' },
  { 'country_code': 'TT',                 'name': 'Trinidad and Tobago', 'continent_id': 'NA' },
  { 'country_code': 'TV',                 'name': 'Tuvalu', 'continent_id': 'OC' },
  { 'country_code': 'TW',                 'name': 'Taiwan', 'continent_id': 'AS' },
  { 'country_code': 'TZ',                 'name': 'Tanzania', 'continent_id': 'AF' },
  { 'country_code': 'UA',                 'name': 'Ukraine', 'continent_id': 'EU' },
  { 'country_code': 'UG',                 'name': 'Uganda', 'continent_id': 'AF' },
  { 'country_code': 'UM',                 'name': 'United States Minor Outlying Islands', 'continent_id': 'OC' },
  { 'country_code': 'US', 'gdp_rank': 1,  'name': 'United States', 'continent_id': 'NA' },
  { 'country_code': 'UY',                 'name': 'Uruguay', 'continent_id': 'SA' },
  { 'country_code': 'UZ',                 'name': 'Uzbekistan', 'continent_id': 'AS' },
  { 'country_code': 'VA',                 'name': 'Holy See (Vatican City State)', 'continent_id': 'EU' },
  { 'country_code': 'VC',                 'name': 'Saint Vincent and the Grenadines', 'continent_id': 'NA' },
  { 'country_code': 'VD',                 'name': 'Democratic Republic of Viet-nam', 'continent_id': 'AS' },
  { 'country_code': 'VE',                 'name': 'Venezuela', 'continent_id': 'SA' },
  { 'country_code': 'VG',                 'name': 'Virgin Islands, British', 'continent_id': 'NA' },
  { 'country_code': 'VI',                 'name': 'Virgin Islands, U.S.', 'continent_id': 'NA' },
  { 'country_code': 'VN',                 'name': 'Viet Nam', 'continent_id': 'AS' },
  { 'country_code': 'VU',                 'name': 'Vanuatu', 'continent_id': 'OC' },
  { 'country_code': 'WF',                 'name': 'Wallis and Futuna', 'continent_id': 'OC' },
  { 'country_code': WO,                   'name': 'WIPO', 'continent_id': WO },
  { 'country_code': 'WS',                 'name': 'Samoa', 'continent_id': 'OC' },
  { 'country_code': 'YE',                 'name': 'Yemen', 'continent_id': 'AS' },
  { 'country_code': 'YT',                 'name': 'Mayotte', 'continent_id': 'AF' },
  { 'country_code': 'YU',                 'name': 'Yugoslavia', 'continent_id': 'EU'},
  { 'country_code': 'ZA',                 'name': 'South Africa', 'continent_id': 'AF' },
  { 'country_code': 'ZM',                 'name': 'Zambia', 'continent_id': 'AF' },
  { 'country_code': 'ZW',                 'name': 'Zimbabwe', 'continent_id': 'AF'},
  { 'country_code': 'ZZ',                 'name': 'Stateless', 'continent_id': 'OT'}
].map((country, idx) => {
  return { ...country, idx } // add in integer index (for computing stable colours)
 })

export const ID_TO_COUNTRY = get_as_map(COUNTRIES, 'country_code')

// ensure territories are ordered alphabetically in trees
const countries_alphabetical_by_name = _.sortBy(COUNTRIES, country => country.name)

export const CONTINENT_ID_TO_COUNTRY_IDS = countries_alphabetical_by_name.reduce((acc, country) => {
  const { continent_id, country_code } = country
  const country_ids = acc[continent_id] || []
  return {...acc, [continent_id]: [...country_ids, country_code]}
}, {})

export const IP5_COUNTRIES = [US, CN, JP, KR, ...CONTINENT_ID_TO_COUNTRY_IDS[EUROPE_CODE]]

export const UPC_COUNTRIES = ['AT', 'BE', 'BG', 'DK', 'EE', 'FI', 'FR', 'DE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SI', 'SE']

export const NO_DOCDB_COUNTRIES = ['AR', 'RU']