import { get_as_map } from '../utils/utils.js'

//from https://cloud.google.com/translate/docs/languages

export const EN_CODE = 'en'

export const ENGLISH_LANG = {id: EN_CODE, name: 'English'}
export const GERMAN_LANG = {id: 'de', name: 'German'}
export const JAPANESE_LANG = {id: 'ja', name: 'Japanese'}
export const KOREAN_LANG = { id: 'ko', name: 'Korean' }

export const LANGUAGE_CODES_AND_NAMES = [
  { id: 'af', name: 'Afrikaans' },
  { id: 'sq', name: 'Albanian' },
  { id: 'am', name: 'Amharic' },
  { id: 'ar', name: 'Arabic' },
  { id: 'hy', name: 'Armenian' },
  { id: 'az', name: 'Azerbaijani' },
  { id: 'eu', name: 'Basque' },
  { id: 'be', name: 'Belarusian' },
  { id: 'bn', name: 'Bengali' },
  { id: 'bs', name: 'Bosnian' },
  { id: 'bg', name: 'Bulgarian' },
  { id: 'ca', name: 'Catalan' },
  { id: 'ceb',   name: 'Cebuano' },
  { id: 'zh-CN', name: 'Chinese (Simplified)' },
  { id: 'zh',    name: 'Chinese (Simplified)' },
  { id: 'zh-TW', name: 'Chinese (Traditional)' },
  { id: 'co', name: 'Corsican' },
  { id: 'hr', name: 'Croatian' },
  { id: 'cs', name: 'Czech' },
  { id: 'da', name: 'Danish' },
  { id: 'nl', name: 'Dutch' },
  ENGLISH_LANG,
  { id: 'eo', name: 'Esperanto' },
  { id: 'et', name: 'Estonian' },
  { id: 'fi', name: 'Finnish' },
  { id: 'fr', name: 'French' },
  { id: 'fy', name: 'Frisian' },
  { id: 'gl', name: 'Galician' },
  { id: 'ka', name: 'Georgian' },
  GERMAN_LANG,
  { id: 'el', name: 'Greek' },
  { id: 'gu', name: 'Gujarati' },
  { id: 'ht', name: 'Haitian Creole' },
  { id: 'ha', name: 'Hausa' },
  { id: 'haw', name: 'Hawaiian' },
  { id: 'he',  name: 'Hebrew' },
  { id: 'iw',  name: 'Hebrew' },
  { id: 'hi',  name: 'Hindi' },
  { id: 'hmn', name: 'Hmong' },
  { id: 'hu', name: 'Hungarian' },
  { id: 'is', name: 'Icelandic' },
  { id: 'ig', name: 'Igbo' },
  { id: 'id', name: 'Indonesian' },
  { id: 'ga', name: 'Irish' },
  { id: 'it', name: 'Italian' },
  JAPANESE_LANG,
  { id: 'jw', name: 'Javanese' },
  { id: 'kn', name: 'Kannada' },
  { id: 'kk', name: 'Kazakh' },
  { id: 'km', name: 'Khmer' },
  KOREAN_LANG,
  { id: 'ku', name: 'Kurdish' },
  { id: 'ky', name: 'Kyrgyz' },
  { id: 'lo', name: 'Lao' },
  { id: 'la', name: 'Latin' },
  { id: 'lv', name: 'Latvian' },
  { id: 'lt', name: 'Lithuanian' },
  { id: 'lb', name: 'Luxembourgish' },
  { id: 'mk', name: 'Macedonian' },
  { id: 'mg', name: 'Malagasy' },
  { id: 'ms', name: 'Malay' },
  { id: 'ml', name: 'Malayalam' },
  { id: 'mt', name: 'Maltese' },
  { id: 'mi', name: 'Maori' },
  { id: 'mr', name: 'Marathi' },
  { id: 'mn', name: 'Mongolian' },
  { id: 'my', name: 'Myanmar (Burmese)' },
  { id: 'ne', name: 'Nepali' },
  { id: 'no', name: 'Norwegian' },
  { id: 'ny', name: 'Nyanja (Chichewa)' },
  { id: 'ps', name: 'Pashto' },
  { id: 'fa', name: 'Persian' },
  { id: 'pl', name: 'Polish' },
  { id: 'pt', name: 'Portuguese (Portugal, Brazil)' },
  { id: 'pa', name: 'Punjabi' },
  { id: 'ro', name: 'Romanian' },
  { id: 'ru', name: 'Russian' },
  { id: 'sm', name: 'Samoan' },
  { id: 'gd', name: 'Scots Gaelic' },
  { id: 'sr', name: 'Serbian' },
  { id: 'st', name: 'Sesotho' },
  { id: 'sn', name: 'Shona' },
  { id: 'sd', name: 'Sindhi' },
  { id: 'si', name: 'Sinhala (Sinhalese)' },
  { id: 'sk', name: 'Slovak' },
  { id: 'sl', name: 'Slovenian' },
  { id: 'so', name: 'Somali' },
  { id: 'es', name: 'Spanish' },
  { id: 'su', name: 'Sundanese' },
  { id: 'sw', name: 'Swahili' },
  { id: 'sv', name: 'Swedish' },
  { id: 'tl', name: 'Tagalog (Filipino)' },
  { id: 'tg', name: 'Tajik' },
  { id: 'ta', name: 'Tamil' },
  { id: 'te', name: 'Telugu' },
  { id: 'th', name: 'Thai' },
  { id: 'tr', name: 'Turkish' },
  { id: 'uk', name: 'Ukrainian' },
  { id: 'ur', name: 'Urdu' },
  { id: 'uz', name: 'Uzbek' },
  { id: 'vi', name: 'Vietnamese' },
  { id: 'cy', name: 'Welsh' },
  { id: 'xh', name: 'Xhosa' },
  { id: 'yi', name: 'Yiddish' },
  { id: 'yo', name: 'Yoruba' },
  { id: 'zu', name: 'Zulu' }
]

export const LANGUAGE_BY_ID = get_as_map(LANGUAGE_CODES_AND_NAMES, 'id')

