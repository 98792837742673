import React from 'react'
import cn from 'classnames'
import { DropdownToggle } from 'reactstrap'

import { TagIcon } from '../../widgets/IconSet.js'

import s from './EditReportTagsToggleButton.module.scss'


export const EditReportTagsToggleButton = ({is_open, disabled}) => {

  const title = 'tag report'

  return (
    <DropdownToggle
      className={cn(s.edit_report_tags_dropdown_toggle,
        {'show-on-hover': !is_open,
          [s.edit_report_tags_dropdown_toggle_disabled] : disabled})}
      tag='span'
      title={title}
      disabled={disabled}
    >
      <TagIcon/>
    </DropdownToggle>
  )
}