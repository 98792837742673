import axios from 'axios'

import { DOMAIN_SERVICE_BASE_URL, DOMAIN_SERVICE_NOAUTH_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err } from './axios_utils.js'
import { calculate_duration } from './utils.js'

export function get_data_version() {
  return axios.get(`${DOMAIN_SERVICE_BASE_URL}/data_version`)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch data_version from domain service: ')
      throw wrapped_err
    })
}

/**
 * @param {} pat_fam_ids
 * @param {} fields
 */
export function get_patent_families_by_pat_fam_ids(pat_fam_ids, fields) {
  const fields_str = fields.join(',')
  return axios.post(`${DOMAIN_SERVICE_NOAUTH_BASE_URL}/patent_families/${fields_str}/byIds`, { pat_fam_ids })
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patent families from domain service: ')
      throw wrapped_err
    })
}

export function get_patent_family_disputes_by_pat_fam_id(pat_fam_id) {
  return axios.get(`${DOMAIN_SERVICE_NOAUTH_BASE_URL}/disputes/by_pat_fam_id/${pat_fam_id}`)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patent family disputes from domain service: ')
      throw wrapped_err
    })
}

export function get_inpadocs_by_pat_fam_ids(pat_fam_id) {
  const url = `${DOMAIN_SERVICE_NOAUTH_BASE_URL}/inpadoc/byPatFamIds?ids=${pat_fam_id}`
  return axios.get(url)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch inpadocs from domain service: ')
      throw wrapped_err
    })
    .then(response => response.data)
}

function process_patfam_dispute_party(party) {
  const { party_name, npe } = party || {}

  return {
    ...party,
    name: party_name,
    is_npe: npe
  }
}

function process_patfam_dispute_patent(patent) {
  const { patent_title, outcome } = patent || {}

  return {
    ...patent,
    title: patent_title,
    patent_outcome: outcome
  }
}

function process_patfam_dispute_document(document) {
  const { name } = document || {}

  return {
    ...document,
    attachment_name: name
  }
}

export function process_patfam_disputes(disputes) {
  const { start_date, end_date, party_outcomes, patent_outcomes, attachments } = disputes

  const duration = calculate_duration(start_date, end_date)

  // TODO: not sure why we transform the below properties - might be better for client code to use the raw properties instead?
  const parties = (party_outcomes || []).map(party => (process_patfam_dispute_party(party)))
  const patents = (patent_outcomes || []).map(patent => (process_patfam_dispute_patent(patent)))
  const attachments_clean = (attachments || []).map(doc => (process_patfam_dispute_document(doc)))

  return {
    ...disputes,
    duration,
    parties,
    patents,
    attachments: attachments_clean
  }
}