import { get_as_map } from '../utils/utils.js'

const CBM = 'CBM'
const DER = 'DER'
const IPR = 'IPR'
const ITC = 'ITC'
const PGR = 'PGR'

export const INVALIDITY = 'INVALIDITY'
export const LITIGATION = 'LITIGATION'

export const LITIGATION_ROUTES = [LITIGATION, INVALIDITY]
export const PTAB_ROUTES = [CBM, DER, IPR, PGR]

export const DISPUTE_TYPE_OPTION_LITIGATION = {id: 'litigation', label: 'Litigation', routes: LITIGATION_ROUTES}
export const DISPUTE_TYPE_OPTION_PTAB = {id: 'ptab', label: 'PTAB (Patent Trial and Appeal Board)', routes: PTAB_ROUTES}
export const DISPUTE_TYPE_OPTION_ITC = {id: 'itc', label: 'ITC disputes', routes: [ITC]}
export const DISPUTE_TYPE_OPTION_ALL = {id: 'all', label: 'All disputes', routes: [...LITIGATION_ROUTES, ...PTAB_ROUTES, ITC]}

export const DISPUTE_TYPE_OPTIONS = [DISPUTE_TYPE_OPTION_LITIGATION, DISPUTE_TYPE_OPTION_PTAB, DISPUTE_TYPE_OPTION_ITC, DISPUTE_TYPE_OPTION_ALL]
export const ID_TO_DISPUTE_TYPE_OPTION = get_as_map(DISPUTE_TYPE_OPTIONS, 'id')