import React from 'react'

import ContainerFullWidth from '../ContainerFullWidth.js'
import { PdfViewer } from '../widgets/PdfViewer.js'
import { track_visit_event } from '../../utils/tracking_utils.js'
import { CI_KEY } from './utils/executive_summary_constants.js'

const ExecutiveSummaryGuide = () => {
  document.title = 'Cipher: Executive Summary Guide'
  track_visit_event('page="ci_guide"')

  return (
    <ContainerFullWidth className='px-0'>
      <PdfViewer
        className='w-100 h-100'
        src={CI_KEY}
        title='Cipher Executive Summary key'
      />
    </ContainerFullWidth>
  )
}

export default ExecutiveSummaryGuide