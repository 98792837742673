import { add_source_err_to_target_err } from './axios_utils.js';

// React "lazy()" loads can fail, for example if user has network problems.
// The below function can be used in conjunction with lazy(), in order to add retries.
//
// Example without retries:
//     const SomeComponent = lazy(() => import('./SomeComponent.js')))
//
// Example with retries:
//     const SomeComponent = lazy(() => lazy_with_retry(() => import('./SomeComponent.js')))
//
export function lazy_with_retry(fn, retriesLeft = 3, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            const wrapped_err = add_source_err_to_target_err(error, new Error(), 'Unable to lazy load, maximum retries exceeded: ')
            reject(wrapped_err)
            return
          }

          // Recurse
          lazy_with_retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}
