import axios from 'axios'
import _ from 'underscore'

import { json_into_params_string, is_sets_equal, is_number } from './utils.js'
import { add_source_err_to_target_err, CONFIG_NO_CACHE } from './axios_utils.js'
import {
  COST_BUCKET_KEY,
  COUNTRY_DISTRIBUTION_BUCKET_KEY,
  PVIX_SCORE_BUCKET_KEY,
  SCORE_BUCKET_KEY
} from '../constants/report_reader.js'
import {
  add_selections_to_query,
  get_constraint,
  get_pvix_bucket_constraints,
  get_cost_bucket_constraints
} from './report_reader_utils.js'
import { save_to_local_storage, get_from_local_storage } from './local_storage_utils.js'
import { fetch_excel_document } from './download_utils.js'
import { get_restricted_family_fields, has_family_details_links } from './user_permissions.js'

import {
  PATENT_FAMILY_SEARCH_BY_PATFAM_IDS,
  PATENT_FAMILY_SEARCH_BY_PHRASE_URL,
  PATENT_FAMILY_SEARCH_BY_QUERY_URL,
  SUBSET_REPORT_CREATE_INPUT_URL,
  SUBSET_REPORT_SAVE_INPUT_URL
} from '../constants/urls.js'
import { ALL_STATUS_IDS } from '../constants/family_status.js'

import {
  PAT_FAM_ID_FIELD_ID,
  ALL_ES_FIELD_IDS,
  ALL_FIELD_IDS,
  PORTFOLIO_FIELD_ID,
  ID_TO_FIELD,
  TAGS_ID,
  PRIORITY_DATE_FIELD_ID,
  GROUPS,
} from '../model/patent_family_fields.js'

import { NO_FILTER as NO_SCORE_FILTER, ID_TO_FILTER as ID_TO_SCORE_FILTER, HIGH_SCORE_ONLY, LOW_SCORE_ONLY, CUSTOM_SCORE_RANGE, MEDIUM_SCORE_ONLY, BORDERLINE_SCORE_ONLY } from '../components/classifiers_editor/model/filters.js'
import { PATENT_LINK_OPTION_DEFAULT_ID, PATENT_LINKS_OPTIONS_BY_ID } from '../model/patent_links.js'
import { ASCENDING, DESCENDING } from '../model/sort_directions.js'
import { TABLE } from '../model/patent_family_list_views.js'

export const FAMILIES_TO_DOWNLOAD_THRESHOLD = 1000000

const FAMILY_QUERY_VALUE_COLUMNS = ['P.name', 'T.name', 'PF.pat_fam_id']

const ALL_STATUS_IDS_SET = new Set(ALL_STATUS_IDS)

const QUERY_KEY_PORTFOLIO = 'portfolio'
const QUERY_KEY_TECH = 'technology'
const QUERY_KEY_TERRITORY = 'territory'

export const GLOBAL_BOOL_SEARCH_PHRASE_LIMIT = 2200
export const REPORT_FROM_LIST_UNKNOWN_TYPE = 'generic'

export function get_normalised_search_phrase(search_phrase) {
  if (!search_phrase) return ''

  return search_phrase
    .replace(/\t+/g, ' ')
    .replace(/(“|”)+/g, '"')
    .replace(/\n/g, ' ')
}

export function is_search_phrase_valid(search_phrase) {
  const normalised_search_phrase = get_normalised_search_phrase(search_phrase).trim()
  return (normalised_search_phrase.length > 0) && (normalised_search_phrase.length < GLOBAL_BOOL_SEARCH_PHRASE_LIMIT + 1)
}

function get_params_object(limit, startAt, sort_field_id, sort_direction_id, searchFor) {
  return {
    fields: [PAT_FAM_ID_FIELD_ID, ...order_field_ids_by_group(ALL_ES_FIELD_IDS)].join(','),
    ...(limit ? {limit} : {}),
    ...(startAt ? {startAt} : {}),
    ...(sort_field_id ? {orderBy: sort_field_id} : {}),
    ...(sort_direction_id ? {orderDir: sort_direction_id} : {}),
    ...((searchFor || '') !== '' ? {searchFor} : {})
  }
}

export function get_patent_families_by_ids(pat_fam_ids, search_phrase='', size, from, sort_field_id, sort_direction_id, fields=ALL_ES_FIELD_IDS) {
  const url = PATENT_FAMILY_SEARCH_BY_PATFAM_IDS + '/list_view'

  const request_data = {
    'patFamIds': pat_fam_ids,
    'fields': [PAT_FAM_ID_FIELD_ID, ...fields],
    'orderBy': sort_field_id,
    'orderDir': sort_direction_id,
    'startAt': from,
    'limit': size,
    ...((search_phrase || '') !== '' ? {'searchFor': search_phrase} : {})
  }

  return axios.post(url, request_data)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patent families by ids for list view from text search service: ')
      throw wrapped_err
    })
}

export function get_patent_families_by_ids_as_csv(pat_fam_ids, selected_field_ids, sort_field_id, sort_direction_id) {
  const url = PATENT_FAMILY_SEARCH_BY_PATFAM_IDS + '/list_view'

  return axios.post(url, {
    'patFamIds': pat_fam_ids,
    'fields': selected_field_ids,
    'orderBy': sort_field_id,
    'orderDir': sort_direction_id,
    'startAt': 0,
    'limit': pat_fam_ids.length,
    'format': 'csv'
  })
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patent families by ids for list view as CSV from text search service: ')
      throw wrapped_err
    })
}

export function get_family_ids_filtered(family_ids, search_phrase) {
  if (!search_phrase || search_phrase === '') return Promise.resolve(family_ids)

  return get_patent_families_by_ids(family_ids, search_phrase, family_ids.length, 0, PRIORITY_DATE_FIELD_ID, ASCENDING, [])
    .then(response => {
      const {searchResults = []} = response || []
      return searchResults.map(family => family[PAT_FAM_ID_FIELD_ID])
    })
}

export function get_patent_families_by_search_phrase(search_phrase, size, from, sort_field_id, sort_direction_id) {
  // Currently the backend does not support status/timerange filters.
  // TODO: add this stuff to the backend!

  const params_obj = get_params_object(size, from, sort_field_id, sort_direction_id)

  const params = {
    ...params_obj,
    searchFor: encodeURIComponent(search_phrase) // TODO: check: Monika adds this in get_params_obj (but without encoding) - can we just add there and always encode?
  }

  const params_string = json_into_params_string(params)

  const url = PATENT_FAMILY_SEARCH_BY_PHRASE_URL + '?' + params_string
  return axios.get(url)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patent families by search phrase from text search service: ')
      throw wrapped_err
    })
}

export function get_patent_families_by_search_phrase_count(search_phrase) {
  const params = {
    fields: PAT_FAM_ID_FIELD_ID,
    limit: 0,
    startAt:0,
    searchFor: encodeURIComponent(search_phrase) // TODO: check: Monika adds this in get_params_obj (but without encoding) - can we just add there and always encode?
  }

  const params_string = json_into_params_string(params)

  const url = PATENT_FAMILY_SEARCH_BY_PHRASE_URL + '?' + params_string
  return axios.get(url)
    .then(response => response.data.totalResults)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patent families by search phrase from text search service: ')
      throw wrapped_err
    })
}

export function get_bool_search_items_sizes(search_phrases) {
  if (!search_phrases || search_phrases.length === 0) return Promise.resolve(null)

  return Promise.all(search_phrases.map(search_phrase => get_patent_families_by_search_phrase_count(search_phrase)))
    .then(sizes => {
      const search_phrases_to_sizes = {}

      search_phrases.forEach((item, i) => {
        search_phrases_to_sizes[item] = sizes[i] || 0
      })

      return search_phrases_to_sizes
    })
}

export function get_patent_families_by_search_phrase_as_csv(search_phrase, selected_field_ids_string, sort_field_id, sort_direction_id) {
  const params = {
    fields: selected_field_ids_string,
    orderBy: sort_field_id,
    orderDir: sort_direction_id,
    format: 'csv',
    searchFor: encodeURIComponent(search_phrase)
  }

  const params_string = json_into_params_string(params)

  const url = PATENT_FAMILY_SEARCH_BY_PHRASE_URL + '?' + params_string
  return axios.get(url, {responseType: 'arraybuffer'})
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patent families by search phrase as CSV from text search service: ')
      throw wrapped_err
    })
}

export function get_patent_families_by_search_phrase_as_xls(search_phrase, selected_field_ids, sort_field_id, sort_direction_id, patent_link_option_id) {
  const data = {
    fields: selected_field_ids,
    order_by: sort_field_id,
    order_dir: sort_direction_id,
    search_for: search_phrase
  }

  const params = {
    title: `Search for '${search_phrase}'`,
    description: '',
    view_id: 'text_search_list_view',
    publication_link_type: patent_link_option_id,
    data
  }

  return fetch_excel_document([params], false)
}

function get_status_constraint(status_ids) {
  if (!status_ids) {
    return null
  }

  const is_default_statuses_selected = is_sets_equal(new Set(status_ids), ALL_STATUS_IDS_SET)
  if (is_default_statuses_selected) {
    return null
  }

  const statuses_string = status_ids.map(id => `'${id}'`).join(', ')
  return `IN PF.status (${statuses_string})`
}

function get_score_constraints(score_filter_id, custom_score_range) {
  if (!score_filter_id || score_filter_id === NO_SCORE_FILTER) {
    return
  }

  const score_filter = ID_TO_SCORE_FILTER[score_filter_id]

  const { extent } = score_filter
  const [min, max]               = (extent || [null, null])
  const [custom_min, custom_max] = custom_score_range

  if (score_filter_id === HIGH_SCORE_ONLY) {
    return [`>= PFTT.score ${min}`]
  }

  if (score_filter_id === LOW_SCORE_ONLY) {
    return [`< PFTT.score ${max}`]
  }

  // For score ranges, BETWEEN clause does not accept numeric expressions, so use two subclauses instead.

  if ((score_filter_id === MEDIUM_SCORE_ONLY) || (score_filter_id === BORDERLINE_SCORE_ONLY)) {
    return [`>= PFTT.score ${min}`, `< PFTT.score ${max}`]
  }

  if (score_filter_id === CUSTOM_SCORE_RANGE) {
    return [`>= PFTT.score ${custom_min}`, `<= PFTT.score ${custom_max}`]
  }

  throw Error(`score_filter_id ${score_filter_id} not accepted here.`)
}

function get_family_tags_constraints(selected_family_tag_values, apply_constraints) {

  if (!apply_constraints){
    //no restrictions
    return []
  }
  if (_.isEmpty(selected_family_tag_values) && apply_constraints){
    //FALSE constraint to return zero results
    return ['AND NULL Tx.tag_id NOTNULL Tx.tag_id']
  }

  const selected_tags_constraint = `IN TV.tag_value_id (${selected_family_tag_values})`
  const filter_tags_constraints = !_.isEmpty(selected_family_tag_values)? [selected_tags_constraint] : []

  return filter_tags_constraints
}

function get_query_with_filter_constraints(query, status_ids, score_filter_id, custom_score_range, selected_family_tags, apply_tag_filter) {
  const status_constraint = get_status_constraint(status_ids) // may be null
  const score_constraints = get_score_constraints(score_filter_id, custom_score_range) // may be null
  const family_tags_constraints = get_family_tags_constraints(selected_family_tags, apply_tag_filter)

  const constraints = [
    ...(query.constraint || []),
    ...(status_constraint ? [status_constraint] : []),
    ...(score_constraints ? score_constraints : []),
    ...(family_tags_constraints)
  ]

  return {
    ...query,
    ...(constraints.length ? { constraint: constraints } : {})
  }
}

function get_subselections_constraints(subselections) {
  if (!subselections) return []

  const constraints = []

  subselections.forEach(({ key, items }) => {
    switch (key) {
      case PVIX_SCORE_BUCKET_KEY: {
        if (items.length === 1) {
          //need to mutate the constraint only if it's with regard to a single pvix score bucket,
          //many subselection items mean it's a total clicktrough and for that there's no need to look as specific values
          const pvix_constraints = get_pvix_bucket_constraints(items[0].id * 1)
          pvix_constraints.forEach(item => constraints.push(item))
        }
        break
      }
      case SCORE_BUCKET_KEY: {
        // RR complains when there are floats and integers mixed in the list so all values must be floats
        const updated_items = items.map(item => ({id: (item.id === '0') ? '0.0' : item.id}))
        constraints.push(get_constraint(key, updated_items))

        break
      }
      case COST_BUCKET_KEY: {
        if (items.length === 1) {
          //need to mutate the constraint only if it's with regard to a single pvix score bucket,
          //many subselection items mean it's a total clicktrough and for that there's no need to look as specific values
          const cost_constraints = get_cost_bucket_constraints(items[0].id * 1)
          cost_constraints.forEach(item => constraints.push(item))
        }
        break
      }
      default: constraints.push(get_constraint(key, items))
    }
  })

  return constraints
}

function get_subselection_updated_items(query_with_selections, subselection_items, query_key) {
  const selection_by_key = query_with_selections[query_key]

  if (!selection_by_key) return subselection_items

  const filtered_items = []

  subselection_items.forEach(item => {
    if (item.is_next_agglom) {
      const {child_ids} = item
      child_ids.forEach(child_id => {
        const id = (query_key === QUERY_KEY_TERRITORY) ? child_id : child_id * 1
        if (selection_by_key.indexOf(id) !== -1) {
          filtered_items.push({id})
        }
      })
    } else {
      if (selection_by_key.indexOf(item.id) !== -1) {
        filtered_items.push(item)
      }
    }
  })

  return filtered_items
}

function get_query_with_subselections(query, selections, subselections) {
  // Add selections (portfolio, tech, country)

  const query_with_selections = add_selections_to_query(query, selections)

  const { sort=[] } = query

  // Remove certain keys
  // i.e always remove 'key', 'distinct'
  // If subselection contains tech / portfolio / country, need to remove (so that it won't conflict with constraint)

  const keys_to_remove = [
    'key',
    'distinct'
  ]

  const updated_subselections = (subselections || []).map(subselection_item => {
    const {key, items} = subselection_item

    const subselection_contains_portfolio = key.indexOf('portfolio_id') !== -1
    const subselection_contains_technology = key.indexOf('technology_id') !== -1
    const subselection_contains_country = ((key.indexOf('country_code') !== -1) && (key !== COUNTRY_DISTRIBUTION_BUCKET_KEY))

    const subselection_contains_single_year = key.indexOf('year') !== -1
    const sort_is_by_year = sort.some(s => s.indexOf('year') !== -1)

    if (! (subselection_contains_portfolio || subselection_contains_technology || subselection_contains_country || subselection_contains_single_year) ) {
      return subselection_item
    }

    if ( subselection_contains_single_year ) {
      if (sort_is_by_year) {
        keys_to_remove.push('sort')
      }
      return subselection_item
    }

    if (subselection_contains_portfolio) {
      keys_to_remove.push(QUERY_KEY_PORTFOLIO)
      return {key, items: get_subselection_updated_items(query_with_selections, items, QUERY_KEY_PORTFOLIO)}
    }

    if (subselection_contains_technology) {
      keys_to_remove.push(QUERY_KEY_TECH)
      return {key, items: get_subselection_updated_items(query_with_selections, items, QUERY_KEY_TECH)}
    }

    if (subselection_contains_country) {
      keys_to_remove.push(QUERY_KEY_TERRITORY)
      return {key, items: get_subselection_updated_items(query_with_selections, items, QUERY_KEY_TERRITORY)}
    }

    return subselection_item
  })

  const query_returns_no_data = _.some(updated_subselections, subselection => {
    const {items} = subselection

    if (!items) return false

    return items.length === 0
  })

  if (query_returns_no_data) {
    return null
  }

  const query_with_selections_clean = _.omit(query_with_selections, keys_to_remove)

  // Get new constraint array

  const constraints = query.constraint || []
  const subselection_constraints = get_subselections_constraints(updated_subselections)

  const new_constraints = [...constraints, ...subselection_constraints]

  return {
    ...query_with_selections_clean,
    ...(new_constraints.length) ? {constraint: new_constraints} : {} // only add if non-empty
  }
}

export function save_clickthrough_report_input(input) {
  const {params, report_type, evaluation_classifier_id, technology_partitioning} = input
  const {query, selections, subselections, search_phrase, status_ids, internal_report_id, score_filter_id, custom_score_range, selected_viewable_family_tags, apply_tag_filter} = params || {}

  const query_params = query ? {
    ...((search_phrase || '') !== '' ? {searchFor: search_phrase} : {}),
    fields: [PAT_FAM_ID_FIELD_ID, PORTFOLIO_FIELD_ID],
    reportId: internal_report_id,
    query: get_patent_families_query_with_subselections(query, selections, subselections, status_ids, score_filter_id, custom_score_range, selected_viewable_family_tags, apply_tag_filter)
  } : null

  return axios.post(SUBSET_REPORT_SAVE_INPUT_URL, {query_params, report_type, evaluation_classifier_id, ...(technology_partitioning ? {technology_partitioning} : {})})
    .then(response => (response.data.input_id))
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to create report input: ')
      throw wrapped_err
    })
}

export function prepare_clickthrough_report_input(input) {
  const { params, report_type, evaluation_classifier_id } = input || {}
  const { query={}, selections, subselections, search_phrase, status_ids, internal_report_id, score_filter_id, custom_score_range, selected_viewable_family_tags, apply_tag_filter } = params || {}

  const query_params = {
    ...((search_phrase || '') !== '' ? {searchFor: search_phrase} : {}),
    fields: [PAT_FAM_ID_FIELD_ID, PORTFOLIO_FIELD_ID],
    reportId: internal_report_id,
    query: get_patent_families_query_with_subselections(query, selections, subselections, status_ids, score_filter_id, custom_score_range, selected_viewable_family_tags, apply_tag_filter)
  }

  return axios.post(SUBSET_REPORT_CREATE_INPUT_URL, {query_params, report_type, evaluation_classifier_id})
    .then(response => (response.data))
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to create report input: ')
      throw wrapped_err
    })
}

/**
 * Fetches patent families from TextSearchService.
 */
export function get_patent_families_by_query(
  { query, selections, subselections, status_ids, size, from, sort_field_id,
    sort_direction_id, search_phrase, internal_report_id, score_filter_id, custom_score_range,
    subset_id, can_see_tags, selected_family_tags, apply_tag_filter }) {
  const params_obj = get_params_object(size, from, sort_field_id, sort_direction_id, search_phrase)

  const report_reader_query = get_patent_families_query_with_subselections(query, selections, subselections, status_ids, score_filter_id, custom_score_range, selected_family_tags, apply_tag_filter)

  if (!report_reader_query) {
    //query is null due to constraints conflict
    return Promise.resolve({results: {totalResults: 0, searchResults: []}})
  }
  const fields = [PAT_FAM_ID_FIELD_ID, ...order_field_ids_by_group(filter_fields(ALL_FIELD_IDS, {can_see_tags}))]

  const request_data = {
    ...params_obj,
    fields,
    reportId: internal_report_id,
    query: report_reader_query
  }
  let url = PATENT_FAMILY_SEARCH_BY_QUERY_URL + '?subset_search=true'
  if (can_see_tags){
    url = url + '&includes_user_info=' + can_see_tags
  }
  return axios.post(url, {
    request_data,
    id: subset_id,
    filters: {subselections, status_ids, size, from, sort_field_id, sort_direction_id, search_phrase, score_filter_id, custom_score_range}
  }, can_see_tags? CONFIG_NO_CACHE : {})
    .then(response => {
      return response.data
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patent families by query from text search service: ')
      throw wrapped_err
    })
}

function filter_fields(field_ids, {can_see_tags}) {
  if (can_see_tags) return field_ids
  return field_ids.filter(field => field !== TAGS_ID)
}

/**
 * Fetches patent families from TextSearchService.
 */
export function get_patent_families_by_query_as_csv({query, selections, subselections, status_ids, selected_field_ids, sort_field_id, sort_direction_id, internal_report_id, search_phrase, score_filter_id, custom_score_range, can_see_tags, selected_family_tags, apply_tag_filter}) {
  const fields = order_field_ids_by_group(filter_fields(selected_field_ids, {can_see_tags}))
  const params = {
    fields,
    orderBy: sort_field_id,
    orderDir: sort_direction_id,
    ...((search_phrase || '') !== '' ? {searchFor: search_phrase} : {}),
    reportId: internal_report_id,
    query: get_patent_families_query_with_subselections(query, selections, subselections, status_ids, score_filter_id, custom_score_range, selected_family_tags, apply_tag_filter),
    format: 'csv'
  }

  let url = PATENT_FAMILY_SEARCH_BY_QUERY_URL
  if (can_see_tags){
    url = url + '?includes_user_info=' + can_see_tags
  }
  return axios({
    method: 'POST',
    url: url,
    data: params,
    headers: {
      responseType: 'arraybuffer'
    }
  })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch patent families by query as CSV from text search service: ')
      throw wrapped_err
    })
}

/**
 * Fetches patent families from DataExportService
 */
export function get_patent_families_by_query_as_xls({query, selections, subselections, status_ids, selected_field_ids, sort_field_id, sort_direction_id, internal_report_id, report_title, search_phrase, description, score_filter_id, custom_score_range, can_see_tags, selected_family_tags, apply_tag_filter, publication_link_type}) {
  const fields = filter_fields(selected_field_ids, {can_see_tags})

  const data = {
    report_id: internal_report_id,
    query: get_patent_families_query_with_subselections(query, selections, subselections, status_ids, score_filter_id, custom_score_range, selected_family_tags, apply_tag_filter),
    fields,
    order_by: sort_field_id,
    order_dir: sort_direction_id,
    ...(search_phrase ? {search_for: search_phrase} : {})
  }

  const params = {
    title: report_title,
    description: description || '',
    view_id: 'report_reader_list_view',
    publication_link_type,
    data
  }

  return fetch_excel_document([params], can_see_tags)
}

function get_patent_families_query_with_subselections(query, selections, subselections, status_ids, score_filter_id, custom_score_range, selected_family_tags, apply_tag_filter) {
  const query_with_filters = get_query_with_filter_constraints(query, status_ids, score_filter_id, custom_score_range, selected_family_tags, apply_tag_filter)
  const query_with_subselections = get_query_with_subselections(query_with_filters, selections, subselections)

  if (!query_with_subselections) {
    //query is null because constraints contradict each other
    return null
  }

  const query_without_count = _.omit(query_with_subselections, 'count') // workaround to remove 'count' (i.e. from ALL_FAMILIES_ID preview spec) - otherwise ReportReader can throw a 400 error

  return {
    ...query_without_count,
    value: FAMILY_QUERY_VALUE_COLUMNS
  }
}

/*
*  Preferences in local storage
*/
const LOCAL_STORAGE_LIST_PREFERENCES_KEY = 'cipher_families_list'
const LIST_PAGE_SIZE                     = 'patent_families_page_size'
const LIST_FIELD_IDS                     = 'patent_families_field_ids'
const LIST_VIEW                          = 'patent_families_list_view'
const CLASSIFIERS_LIST_VIEW              = 'classifiers_patent_families_list_view'
const PATENT_LINK_OPTION_ID              = 'patent_link_option_id'

function update_list_preferences(param, value) {
  save_to_local_storage(LOCAL_STORAGE_LIST_PREFERENCES_KEY+param, value)
}

function get_list_preferences(param) {
  return get_from_local_storage(LOCAL_STORAGE_LIST_PREFERENCES_KEY+param)
}

export function get_page_size_preference() {
  // may return null
  return get_list_preferences(LIST_PAGE_SIZE)
}

export function update_page_size_preferences(value) {
  update_list_preferences(LIST_PAGE_SIZE, value)
}

export function get_list_field_ids_preference() {
  // may return null
  return get_list_preferences(LIST_FIELD_IDS)
}

export function update_list_field_ids_preferences(field_ids) {
  update_list_preferences(LIST_FIELD_IDS, field_ids)
}

export function get_list_view_preference(user) {
  if (has_family_details_links(user)) {
    return get_list_preferences(LIST_VIEW)
  }
  // card view has too many restricted details, so always fall back to the default table
  return TABLE
}

export function update_list_view_preference(view) {
  return update_list_preferences(LIST_VIEW, view)
}

export function get_classifiers_list_view_preference() {
  return get_list_preferences(CLASSIFIERS_LIST_VIEW)
}

export function update_classifiers_list_view_preference(view) {
  return update_list_preferences(CLASSIFIERS_LIST_VIEW, view)
}

export function get_patent_link_option_id_preference() {
  const patent_link_preference = get_list_preferences(PATENT_LINK_OPTION_ID)
  return PATENT_LINKS_OPTIONS_BY_ID[patent_link_preference] ? patent_link_preference : PATENT_LINK_OPTION_DEFAULT_ID
}

export function update_patent_link_option_id_preference(patent_link_option_id) {
  update_list_preferences(PATENT_LINK_OPTION_ID, patent_link_option_id)
}

export function remove_unavailable_field_ids(field_ids, {is_keyword_search, report_has_scores, schema_version, user}) {
  // remove restricted fields (if required)
  const field_ids_without_restricted_fields = get_field_ids_without_restricted_fields(field_ids, user)

  const fields_to_return = field_ids_without_restricted_fields.filter(field_id => {
    const field = ID_TO_FIELD[field_id] || {}

    const {check_if_available} = field || {}
    if (check_if_available) return check_if_available({is_keyword_search, report_has_scores, schema_version, user})
    return true
  })

  return fields_to_return
}

export function get_field_ids_without_restricted_fields(field_ids, user) {
  const restricted_field_ids = get_restricted_family_fields(user)

  return (restricted_field_ids != null && restricted_field_ids.length > 0) ?
    field_ids.filter(id => !_.contains(restricted_field_ids, id)) // Restrictions, so filter
    : field_ids                                                  // No restrictions, so do nothing
}

export function get_es_only_fields(field_ids=[]) {
  return field_ids.filter(field_id => {
    const field = ID_TO_FIELD[field_id] || {}

    return field.in_es !== false
  })
}

export function get_fields_by_group(fields, group) {
  const {id: group_id} = group || {}
  return (fields || []).filter(field => (field || {}).group === group_id)
}

export function get_fields_ungrouped(fields) {
  return (fields || []).filter(field => (field || {}).group == null)
}

export function has_ungrouped_fields(fields) {
  return get_fields_ungrouped(fields).length > 0
}

export function order_field_ids_by_group(field_ids) {
  if (field_ids == null) return null
  if (field_ids.length === 0) return []
  const fields = field_ids.map(field_id => ID_TO_FIELD[field_id])
  const grouped_fields_ids = GROUPS.reduce((acc, group) => {
    const group_fields = get_fields_by_group(fields, group)
    return [...acc, ...group_fields.map(field => field.id)]
  }, [])

  const ungrouped_fields_ids = _.difference(field_ids, grouped_fields_ids)

  return [...grouped_fields_ids, ...ungrouped_fields_ids]
}

function get_patent_field_value(field, family) {
  const raw_value = family[field]

  if (is_number(raw_value)) return raw_value

  const string_value = _.isArray(raw_value) ? raw_value.join(' | ') : raw_value + ''  // coerce to string

  return string_value.toUpperCase()
}

export function get_sorted_families(families_list, sort_field_id, sort_direction_id) {
  const _sorted = _.sortBy(families_list || [], get_patent_field_value.bind(null, sort_field_id))
  return (sort_direction_id === DESCENDING) ? _sorted.slice().reverse() : _sorted
}

export function check_if_all_fields_selected({selected_fields=[], all_fields=[]}) {
  return (selected_fields.length === all_fields.length)
}

export function check_if_no_fields_selected({selected_fields=[]}) {
  return (selected_fields.length === 0)
}

export function check_if_default_field_selected({selected_fields=[], default_fields=[]}) {
  return is_sets_equal(new Set(selected_fields), new Set(default_fields))
}
