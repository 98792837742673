import React from 'react'

import BasePlotlyXYChart from './BasePlotlyXYChart.js'
import { LINEMODE_AREA, SCATTER_CHART_TYPE } from './base_plotly_props.js'

const LineAreaChart = (props) => {
  return (
    <BasePlotlyXYChart
      {...props}
      chart_type={SCATTER_CHART_TYPE}
      linemode={LINEMODE_AREA}
      use_value_prefix={false}
    />
  )
}

LineAreaChart.get_plotly_data = (props) => {
  return BasePlotlyXYChart.get_plotly_data({ ...props, chart_type: SCATTER_CHART_TYPE })
}

LineAreaChart.get_axis_titles = BasePlotlyXYChart.get_axis_titles

LineAreaChart.get_csv_value_rows = (props) => {
  return BasePlotlyXYChart.get_csv_value_rows({ ...props, chart_type: SCATTER_CHART_TYPE })
}

export default LineAreaChart
