import React, { useState, useEffect } from 'react'

import { Pane } from '../widgets/Block.js'
import Spinner from '../widgets/Spinner.js'
import ErrorModal from '../ErrorModal.js'
import OrgSuggestionsDisplay from './OrgSuggestionsDisplay.js'
import { get_org_suggestions_for_items } from '../../utils/organisation_utils.js'
import { withReportBasket } from '../builder/ReportBasketContext.js'

const OrgSuggestionsSearch = ({base_name, base_items, base_items_type, on_result_select, reset_handler, on_show_similar_organisations, selected_organisations, suggestions_mode}) => {
  const [show_spinner, set_show_spinner] = useState(false)
  const [org_suggestions, set_org_suggestions] = useState(null)

  const [fetch_org_suggestions_error, set_fetch_org_suggestions_error] = useState(null)

  useEffect(() => {
    set_show_spinner(true)
    get_org_suggestions_for_items(base_items, base_items_type)
      .then(response => {
        set_show_spinner(false)
        set_org_suggestions(response)
      })
      .catch(error => {
        set_fetch_org_suggestions_error(error)
        set_show_spinner(false)
      })
  }, [base_items, base_items_type])

  if (fetch_org_suggestions_error) {
    return(
      <ErrorModal
        on_hide={() => {set_fetch_org_suggestions_error(null)}}
        error={fetch_org_suggestions_error}
        context='fetching organisation suggestions'
      />
    )
  }

  return (
    <div>
      {show_spinner &&
        <Pane className='text-center'>
          <Spinner />
          <p>Fetching similar organisations ...</p>
        </Pane>
      }

      {!show_spinner && org_suggestions &&
        <OrgSuggestionsDisplay
          base_name={base_name}
          org_suggestions={org_suggestions}
          on_result_select={on_result_select}
          reset_handler={reset_handler}
          on_show_similar_organisations={on_show_similar_organisations}
          selected_organisations={selected_organisations}
          suggestions_mode={suggestions_mode}
        />
      }
    </div>
  )
}

export default withReportBasket(OrgSuggestionsSearch)