import axios from 'axios'

import Keycloak from '../lib/keycloak.js' // Temporary workaround (see file for details)

import {
  is_dev_environment, /*is_ln_environment, is_ln_preprod_environment*/
  is_tech_discovery,
  is_tech_discovery_cdn_environment
} from './utils.js'
import { WAM_TECHDISCOVERY_USER_DETAILS_URL } from '../constants/urls.js'
import { add_source_err_to_target_err, JSON_POST_HEADER } from './axios_utils.js'

const KC_TOKEN = 'kc_token'
const KC_REFRESH_TOKEN = 'kc_refresh_token'
const KC_ID_TOKEN = 'kc_id_token'

const KC_TOKEN__TD = 'kc_token__td'
const KC_REFRESH_TOKEN__TD = 'kc_refresh_token__td'
const KC_ID_TOKEN__TD = 'kc_id_token__td'

const PROD_LN_AUTH_URL = 'https://login.aws.cipher.ai/auth/'
const DEV_AUTH_URL = 'https://login-dev.aws.cipher.ai/auth/'

const CUSTOM_AUTH_URL  = process.env['REACT_APP_AUTH_URL']

const REACT_APP_AUTH_REALM     = process.env['REACT_APP_AUTH_REALM']     || 'cipher'
const REACT_APP_AUTH_CLIENT_ID = process.env['REACT_APP_AUTH_CLIENT_ID'] || 'cipher3'

// // for now, use CERT Keycloak (OIDC) by default
// const TECH_DISCOVERY_AUTH_CLIENT_ID            = process.env['REACT_APP_TECH_DISCOVERY_AUTH_CLIENT_ID']            || '9e0110b8-1c1f-43fb-a58a-e736ef528766'
// const TECH_DISCOVERY_AUTH_AUTH_ENDPOINT        = process.env['REACT_APP_TECH_DISCOVERY_AUTH_AUTH_ENDPOINT']        || 'https://cdc1c-login.lexisnexis.com/oauth2/auth'
// const TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT       = process.env['REACT_APP_TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT']       || 'https://cdc1c-login.lexisnexis.com/oauth2/token'
// const TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT = process.env['REACT_APP_TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT'] || 'https://cdc1c-login.lexisnexis.com/oauth2/sessions/logout'
// const TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT    = process.env['REACT_APP_TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT']    || 'https://cdc1c-login.lexisnexis.com/userinfo'

// WAM PROD OIDC endpoints
const TECH_DISCOVERY_AUTH_CLIENT_ID            = process.env['REACT_APP_TECH_DISCOVERY_AUTH_CLIENT_ID']            || '9e0110b8-1c1f-43fb-a58a-e736ef528766'
const TECH_DISCOVERY_AUTH_AUTH_ENDPOINT        = process.env['REACT_APP_TECH_DISCOVERY_AUTH_AUTH_ENDPOINT']        || 'https://login.lexisnexis.com/oauth2/auth'
const TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT       = process.env['REACT_APP_TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT']       || 'https://login.lexisnexis.com/oauth2/token'
const TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT = process.env['REACT_APP_TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT'] || 'https://login.lexisnexis.com/oauth2/sessions/logout'
const TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT    = process.env['REACT_APP_TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT']    || 'https://login.lexisnexis.com/userinfo'

// // TEMP: for now, use Prod Keycloak (OIDC) by default
// const TECH_DISCOVERY_AUTH_CLIENT_ID            = process.env['REACT_APP_TECH_DISCOVERY_AUTH_CLIENT_ID']            || 'cipher3'
// const TECH_DISCOVERY_AUTH_AUTH_ENDPOINT        = process.env['REACT_APP_TECH_DISCOVERY_AUTH_AUTH_ENDPOINT']        || 'https://login.aws.cipher.ai/auth/realms/cipher/protocol/openid-connect/auth'
// const TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT       = process.env['REACT_APP_TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT']       || 'https://login.aws.cipher.ai/auth/realms/cipher/protocol/openid-connect/token'
// const TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT = process.env['REACT_APP_TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT'] || 'https://login.aws.cipher.ai/auth/realms/cipher/protocol/openid-connect/logout'
// const TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT    = process.env['REACT_APP_TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT']    || 'https://login.aws.cipher.ai/auth/realms/cipher/protocol/openid-connect/userinfo'

export const REFRESH_JWT_ERROR_MESSAGE = 'refresh jwt failed - maybe max_SSO_session_length or jwt_token have expired'

function get_auth_url() {
  if (CUSTOM_AUTH_URL) {
    return CUSTOM_AUTH_URL
  }

  const in_dev_env = is_dev_environment()
  if (in_dev_env) {
    return DEV_AUTH_URL
  }

  return PROD_LN_AUTH_URL // login.aws.cipher.ai
}

export function create_keycloak_client(is_tech_discovery) {
  const auth_url = get_auth_url()

  const kc = new Keycloak({

    clientId: (is_tech_discovery ? TECH_DISCOVERY_AUTH_CLIENT_ID: REACT_APP_AUTH_CLIENT_ID),

    // Keycloak config
    realm:    REACT_APP_AUTH_REALM,
    url:      auth_url,

    // TechDiscovery config
    ...(is_tech_discovery ? {
      oidcProvider: {
        authorization_endpoint: TECH_DISCOVERY_AUTH_AUTH_ENDPOINT,
        token_endpoint:         TECH_DISCOVERY_AUTH_TOKEN_ENDPOINT,
        end_session_endpoint:   TECH_DISCOVERY_AUTH_END_SESSION_ENDPOINT,
        userinfo_endpoint:      TECH_DISCOVERY_AUTH_USERINFO_ENDPOINT,
      }
    } : {})

  })

  return kc
}

export function init_keycloak(kc, is_tech_discovery) {
  const { token, refresh_token } = get_from_local_storage()

  const is_tech_discovery_cdn_context = is_tech_discovery_cdn_environment()

  return kc.init({
    onLoad: 'check-sso',
    token,
    refreshToken: refresh_token,
    timeSkew: 0,
    checkLoginIframe: false,// disable iframe as it can make local/dev/staging inaccessible on Safari
                            // (as iframe/parent must be on same protocol, i.e. both http or both https).
    promiseType: 'native',

    // Currently WAM configuation only supports explicit redirect uri i.e. http://localhost:3000 (no deep links etc)
    // TODO: chase Evan to add wildcard (i.e. http://localhost:3000* etc)
    // TEMP WORKAROUND FOR NOW: if TechDiscovery, pass in explicit redirectURI without deep path
    ...(is_tech_discovery ? {
      jwtRefreshToken: false,
      redirectUri: window.location.origin,
      scope: 'openid offline email',
      forwardParameters: is_tech_discovery_cdn_context ? { 'appContextId': 'tdsc' } : null // On prod CDN, use the TechDiscovery ACI (custom WAM login layout)
                                                                                           // On dev/cert, do not add this (ACI not working for dev/cert - see https://pdc1c-authena.route53.lexis.com/)
    } : {})
  })
  .then(authenticated => {
    if (!authenticated) {
      return false
    }

    save_to_local_storage(kc)
    return true
  })
}

export function force_refresh_jwt_token(kc) {
  kc.updateToken(-1) // -1 forces refresh
    .catch(on_update_token_error.bind(null, kc))
}

export function on_update_token_error(kc) {
  // Annoyingly keycloak does not expose the response or error, so we can not know exactly what went wrong.
  // There's three main possibilities:
  // a) Refresh token has expired (keycloak returns http 400).
  //    i.e. currently SSO session max is 14 days.
  //    If so, user needs to log in again.
  // b) Refresh token is invalid (keycloak returns http 400)
  //    i.e. perhaps user has logged out (or switched accounts) in another tab,
  //    revoking session associated with current refresh token.
  // c) Some other keycloak error.
  // Whatever the cause, at this point user can not make any calls to our API.
  // So we try to get them logged in again.
  kc.login() // redirects to keycloak for authentication (if cookie is still valid, will immediately redirect back with new refresh token)
}

function get_token_keys() {
  const is_tech_discovery_context = is_tech_discovery()
  return is_tech_discovery_context ? [KC_TOKEN__TD, KC_REFRESH_TOKEN__TD, KC_ID_TOKEN__TD] : [KC_TOKEN, KC_REFRESH_TOKEN, KC_ID_TOKEN]
}

export function save_to_local_storage(kc) {
  const [token_key, refresh_token_key, id_token_key] = get_token_keys()
  localStorage.setItem(token_key, kc.token);
  localStorage.setItem(refresh_token_key, kc.refreshToken)
  localStorage.setItem(id_token_key, kc.idToken)
}

export function get_from_local_storage() {
  const [token_key, refresh_token_key, id_token_key] = get_token_keys()
  const token         = localStorage.getItem(token_key)
  const refresh_token = localStorage.getItem(refresh_token_key)
  const id_token      = localStorage.getItem(id_token_key)
  return { token, refresh_token, id_token }
}

export function remove_from_local_storage() {
  const [token_key, refresh_token_key, id_token_key] = get_token_keys()
  localStorage.removeItem(refresh_token_key)
  localStorage.removeItem(token_key)
  localStorage.removeItem(id_token_key)
}

export function fetch_wam_oidc_token() {
  const base_url = get_auth_url()
  const idp_alias = 'wam-pdc1c' // TODO: move this to config
  const url = `${base_url}/realms/${REACT_APP_AUTH_REALM}/broker/${idp_alias}/token`
  return axios.get(url)
    .then(response => {
      return response.data
    })
}

export function convert_wam_token_to_cookie(oidc_token) {
  // NOTE:
  // Calling this via ajax will not work in Safari/Firefox, as they block third party cookies.
  // It also suffers from CORS issues, so need to register valid callers with WAM.

  const url = `https://signin.lexisnexis.com/lnaccess/oidc/sso`

  const data = {
    RelayState: 'aci=ciph',
    id_token: oidc_token,
  }

  return axios.post(url, data, {headers: {'content-type': 'application/x-www-form-urlencoded'}} )
}

export function get_tech_discovery_wam_user_details(id_token, user_perm_id) {
  return axios.post(WAM_TECHDISCOVERY_USER_DETAILS_URL, { id_token, user_perm_id}, { headers: JSON_POST_HEADER })
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch tech_discovery_wam_user_details: ')
    })
}

export function tech_discovery__clean_url() {
  // For TechDiscovery, on successsful authentication, there is an ugly hash fragment in the url (i.e. #scope=openid etc...)
  // So we remove this here.
  const url = window.location.href
  const clean_url = url.split('#')[0]
  if (url !== clean_url) {
    window.history.replaceState({}, '', clean_url)
  }

  // NOTE: on authentication, TechDiscovery WAM only redirects to the root of tha app (i.e. /)
  // So deeplinks are lost.
  // A workaround might involve storing the deeplink in local storage before the initial keycloak-js to WAM redirect,
  // and then restoring the url upon successful authentication.
  // But detecting the above two events is really hard (keycloak-js has no hooks for this).
}