import _ from 'underscore'

import { get_short_company_name, get_short_cluster_name } from './name_utils.js'

import { PORTFOLIO_ID, PORTFOLIO_META_TYPE_ROLLUP_FIELD } from '../model/portfolios.js'
import { TECH_ID } from '../model/technologies.js'

import { ID_TO_COUNTRY } from '../constants/countries.js'
import { PORTFOLIO_COLOR_SCHEME, TECH_COLOR_SCHEME, GEO_COLOR_SCHEME, DEFAULT_NEXT_AGGLOM_COLOR } from '../constants/colours.js'
import { is_array_non_empty_non_null } from './utils.js'
import {
  CONTINENT_ID_TO_GEO_IDS,
  DATA_CREATION_DATE,
  GEOS,
  PORTFOLIOS,
  PORTFOLIOS_COUNT_INCL_ROLLUPS,
  SCHEMA_VERSION,
  TECHS
} from '../model/ref.js'
import {
  BY_EXPIRY_YEAR_ID,
  BY_GRANTED_YEAR_ID,
  BY_PRIORITY_YEAR_ID,
  BY_PUBLICATION_YEAR_ID
} from '../model/time_range_filter_fields.js'
import { get_clean_classifier_description } from './classifier_description_utils.js'
import {
  SOURCE_TYPE_CLASSIFIER,
  SOURCE_TYPE_UNRELATED,
  SOURCE_TYPE_UTT,
  UNRELATED_SOURCE_TYPES,
  WITH_PATH_SOURCE_TYPES
} from '../constants/source_type.js'

const TREE_NODE_LABEL_UNRELATED = 'Unrelated'

function is_source_value_object_not_array(source_value) {
  return _.isObject(source_value) && !_.isArray(source_value)
}

export function get_tree_path(source_value) {
  if (!source_value) return null

  // Before December 2019, source_value was a comma-separated string i.e. 'automotive,ADAS,lidar'. We can not safely parse this to a tree, so we return null here.
  // After July 2022, source_value is an array
  // After March 2023 source_value is an object
  if (is_source_value_object_not_array(source_value)) {
    const {path} = source_value || {}
    return path
  }

  if (is_array_non_empty_non_null(source_value)) {
    return source_value
  }

  return null
}

export function get_technology_description(source_value) {
  if (!source_value) return null

  if (is_source_value_object_not_array(source_value)) {
    const {description} = source_value
    return get_clean_classifier_description(description)
  }

  return null
}

export function extend_display_data_to_portfolios(portfolios) {
  return portfolios.map((portfolio, idx) => {
    const {meta, id} = portfolio || {}

    const rollup = meta && meta.type === PORTFOLIO_META_TYPE_ROLLUP_FIELD ? meta : null // if non-null, indicate server-side agglomeration of many portfolios

    // TEMPORARY rollup renaming.
    // The below line is currently necessary for old reports (created before January 2019).
    // TODO: It can be removed in April 2019 (i.e. all new reports created after January 2019 have correct rollup names).

    //TODO: ask Ben about this
    const name = rollup ? ('Next ' + rollup.count) : portfolio.name // rename server-side rollups

    const color = rollup ? DEFAULT_NEXT_AGGLOM_COLOR : PORTFOLIO_COLOR_SCHEME[idx % PORTFOLIO_COLOR_SCHEME.length] // TODO: better colours

    return {
      ...portfolio,
      id,
      [PORTFOLIO_ID]: id,
      name,
      short_name: get_short_company_name(name),
      color,
      rollup
    }
  })
}

function check_if_techs_include_classified_negatives(techs) {
  const source_types = _.uniq(techs.map(item => item.source_type))

  return  source_types.length > 1 && _.contains(source_types, SOURCE_TYPE_CLASSIFIER) && !_.contains(source_types, SOURCE_TYPE_UNRELATED)
}

export function extend_display_data_to_technologies(techs) {
  const has_negative_processing = check_if_techs_include_classified_negatives(techs)

  return techs.map((tech, idx) => {
    const {source_type, source_value, families_count, id} = tech

    const is_unrelated = UNRELATED_SOURCE_TYPES.indexOf(source_type) > -1

    const is_empty = families_count === 0

    const tree_path = (WITH_PATH_SOURCE_TYPES.indexOf(source_type) > -1) && source_value ?
      get_tree_path(source_value) :
      (has_negative_processing && source_type !== SOURCE_TYPE_CLASSIFIER) ? [TREE_NODE_LABEL_UNRELATED] : null

    if (has_negative_processing && (source_type === SOURCE_TYPE_UTT) && tree_path) {
      //swap UTT for 'Unrelated' if negatives classified by UTT
      tree_path[0] = TREE_NODE_LABEL_UNRELATED
    }

    return {
      ...tech,
      id,
      [TECH_ID]: id,
      short_name: get_short_cluster_name(tech.name),
      color: TECH_COLOR_SCHEME[idx % TECH_COLOR_SCHEME.length], // TODO: better colours
      is_unrelated,
      is_empty,
      tree_path
    }
  })
}

export function extend_display_data_to_geos(geos) {
  return geos.map((geo) => {
    const country = ID_TO_COUNTRY[geo]

    const color_index = country.gdp_rank || country.idx // If gdp rank exists use it. Otherwise fall back to index.

    return {
      ...country,
      id: geo,
      short_name: country.name,
      color: GEO_COLOR_SCHEME[color_index % GEO_COLOR_SCHEME.length]
    }
  })
}

export function get_ref_data_from_data_summary({portfolios, technologies, country_codes, schema_version, created_at}) {
  const ref_data_portfolios = extend_display_data_to_portfolios(portfolios)

  const geos = extend_display_data_to_geos(country_codes).sort((a, b) => {  // sort by name ascending (case-insensitive)
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1 })

  const continent_id_to_geo_ids = geos.reduce((acc, geo) => {
    const { continent_id } = geo
    const geo_ids = acc[continent_id] || []
    return {...acc, [continent_id]: [...geo_ids, geo.id]}
  }, {})

  const ref_data_techs = extend_display_data_to_technologies(technologies)

  return {
    [PORTFOLIOS]                    : ref_data_portfolios,
    [TECHS]                         : ref_data_techs,
    [GEOS]                          : geos,
    [CONTINENT_ID_TO_GEO_IDS]       : continent_id_to_geo_ids,
    [PORTFOLIOS_COUNT_INCL_ROLLUPS] : get_count_inc_rollups(ref_data_portfolios),
    [DATA_CREATION_DATE]            : created_at,
    [SCHEMA_VERSION]                : schema_version
  }
}

export function get_timeranges_from_data_summary({year_ranges}) {

  const fields = [
    {field_id_in_viewer: BY_PRIORITY_YEAR_ID, field_id_in_data: 'priority_year'},
    {field_id_in_viewer: BY_PUBLICATION_YEAR_ID, field_id_in_data: 'publication_year'},
    {field_id_in_viewer: BY_GRANTED_YEAR_ID, field_id_in_data: 'granted_year'},
    {field_id_in_viewer: BY_EXPIRY_YEAR_ID, field_id_in_data: 'expiry_year'},
  ]

  const timerange_field_id_to_default_extent = {}

  fields.forEach(field => {
    const {field_id_in_viewer, field_id_in_data} = field

    const { min, max } = year_ranges[field_id_in_data] || {}

    if (min || max) {
      timerange_field_id_to_default_extent[field_id_in_viewer] = {min, max: max + 1}
    }

  })

  return timerange_field_id_to_default_extent
}

function is_empty(item) {
  const {size, is_empty} = item

  return is_empty || (size === 0)
}

export function get_non_empty_items(items) {
  return items ? items.filter(item => !is_empty(item)) : []
}

export function get_empty_items(items) {
  return items ? items.filter(item => (is_empty(item))) : []
}

export function get_count_inc_rollups(items) {
  return items.reduce((_count, item) => {
    if (!item) return _count
    const { rollup } = item
    return _count + (rollup ? rollup.count : 1)
  }, 0)
}

export function get_count_excl_rollups(items) {
  return items.reduce((_count, item) => {
    const { rollup } = item
    return _count + (rollup ? 0 : 1)
  }, 0)
}

export function contains_server_side_rollup(items) {
  return items.some(item => item.rollup)
}
