import axios from 'axios'
import _ from 'underscore'
import { BENCHMARKING_BUILDER_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err, JSON_POST_HEADER } from './axios_utils.js'
import { fetch_data_from_report_reader, fetch_report_data_summary, fetch_report_input } from './report_reader_utils.js'
import {
  get_org_lists_from_data_summary,
  get_tech_areas_from_data_summary
} from './report_deck_utils.js'
import { convert_columns_data_to_objects } from './column_data_utils.js'
import { get_tree_path } from './ref_data_utils.js'
import { TECHS_SPEC } from '../model/ref_specs.js'

export function build_benchmarking_tables({internal_report_id, external_report_id, setup_params}) {
  const {company_lists, tech_areas, is_overwrite} = setup_params

  const data = {external_report_id, tech_areas, company_lists, is_overwrite}
  return axios.post(`${BENCHMARKING_BUILDER_BASE_URL}/${internal_report_id}`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to convert to benchmarking report: ')
      throw wrapped_err
    })
}

function fetch_report_R1_input(internal_report_id) {
  return fetch_report_input(internal_report_id)
    .then(merged_report_input => {
      // extract the id of the company list report from the set theory merge input
      const { R1 } = (merged_report_input.meta || {}).operands || {}
      if (!R1) {
        return null
      }
      return fetch_report_input(R1)
        .then(R1_input => ({...R1_input, r1_report_id: R1}))
    })
}

export function fetch_benchmarking_input_orgs_and_techs(internal_report_id) {
  // in new choreo reports we could get this from the data_summary, but for older reports we still need to use input
  return fetch_report_R1_input(internal_report_id)
    .then(r1_report_input => {
      if (!r1_report_input) {
        return null
      }
      const {r1_report_id} = r1_report_input
      return Promise.all([
        extract_company_lists_from_report_input(r1_report_input, internal_report_id),
        fetch_R1_report_techs_with_paths(r1_report_id)
      ])
    })
}

function fetch_R1_report_techs_with_paths(r1_report_id) {
  return fetch_data_from_report_reader(r1_report_id, TECHS_SPEC.get_query())
    .then(techs => convert_columns_data_to_objects(techs, TECHS_SPEC.column_names))
    .then(techs => techs.map(tech => {
      const {source_type, source_value} = tech
      const tree_path = (['taxonomy_classifier', 'multiclass', 'utt'].indexOf(source_type) > -1) && source_value ? get_tree_path(source_value) : null
      return {...tech, tree_path}
    }))
}

function extract_company_lists_from_report_input(report_input_orgs_report, merged_report_internal_id) {
  const {company_lists, portfolios} = report_input_orgs_report
  if (!company_lists) {
    return null
  }
  const company_list_orgs = []
  company_lists.forEach(company_list => {
    const {name, member_index} = company_list
    // find the portfolios with the relevant indexes and put them in an array; we need their names for matching
    member_index.forEach(i => company_list_orgs.push({...portfolios[i], company_list: name}))
  })
  return find_list_company_portfolio_ids(merged_report_internal_id, company_list_orgs)
    .then(orgs_with_ids => {
      // sort into lists for display and input
      return _.groupBy(orgs_with_ids, org => org.company_list)
    })
}

export function fetch_benchmarking_techs(internal_report_id) {
  return fetch_report_R1_input(internal_report_id)
    .then(report_input => {
      return report_input.technology_partitioning
    })
}

function find_list_company_portfolio_ids(internal_report_id, list_orgs_meta) {
  const query = {"key": ["P.portfolio_id", "P.name"]}
  return fetch_data_from_report_reader(internal_report_id, query)
    .then(merged_portfolio_data => {
      const [ids, names] = merged_portfolio_data.data
      return list_orgs_meta.map(org => {
        // find the portfolio id for each list org in the merged report (this might be different from the company list report)
        const {name, company_list} = org
        const idx = names.indexOf(name)
        const portfolio_id = ids[idx]
        return {name, company_list, portfolio_id}
      })
        .filter(org => _.isNumber(org.portfolio_id)) // orgs excluded in the merge will not have portfolio_ids
    })
}

export function fetch_existing_benchmarking_params(internal_report_id) {
  return fetch_report_data_summary(internal_report_id)
    .then(data_summary => {
      return [get_tech_areas_from_data_summary(data_summary), get_org_lists_from_data_summary(data_summary)]
    })
}
