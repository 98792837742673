import axios, { Method } from 'axios'
import _ from 'underscore'

import { FAMILY_TAGS_BASE_URL } from '../../constants/urls.js'
import { add_source_err_to_target_err } from '../../utils/axios_utils.js'
import { MAX_TAG_NAME_LENGTH } from '../../constants/constants'
import { remove_last_special_character_from_text, remove_not_allowed_chars_from_text } from '../../utils/name_utils.js'
import { contains } from '../../utils/utils'

export enum Choice {
  SINGLE,
  MULTI,
  FREE_FORMAT
}

export function choice_as_str(choice:Choice){
  switch(choice){
    case Choice.SINGLE: return 'single choice'
    case Choice.MULTI: return 'multiple choice'
    case Choice.FREE_FORMAT: return 'free format text'
  }
}

export enum TagPermission {
  VIEW =1 ,
  TAG =2 ,
  EDIT = 3
}

export function tag_permission_as_str(permission:TagPermission | string):string {
  if (permission === TagPermission.VIEW  || permission === 'VIEW') {
    return 'can view tagged families'
  }
  if (permission === TagPermission.TAG || permission ==='TAG') {
    return 'can tag families'
  }
  if (permission === TagPermission.EDIT || permission === 'EDIT') {
    return 'can create, amend, share and delete'
  }
  return 'Default not recognised'
}

export function from_str_to_tag_permission(permission_type:TagPermission | string): TagPermission{
  if (permission_type === TagPermission.TAG ||permission_type === 'TAG'){
    return TagPermission.TAG
  }
  if (permission_type === TagPermission.EDIT || permission_type === 'EDIT'){
    return TagPermission.EDIT
  }
  return TagPermission.VIEW
}

export enum UUIDType {
  USER=1,
  GROUP=2
}
export interface UUIDPermissions {
  uuid:string,
  name:string,
  type: UUIDType,
  level: TagPermission | string
}

export interface Tag {
  id: number,
  name: string,
  values: Array<TagValue>,
  type: Choice,
  sort_index:number,
  section:string,
  orgs?: Array<string>,
}

export interface TagValue {
  id: number,
  value: string,
  families_count: number
}

export interface SectionSorting {
  tag_id: number,
  tag_name:string,
  sort_index: number,
  is_current: boolean
}

export const NO_FAMILY_TAG = {id:0, name: 'None', values:[{id:0, value:'None', families_count:0}], type:Choice.SINGLE}

function get_tag_type(tag_records: Array<any>) {
  if (tag_records.length > 0) {
    switch (tag_records[0].tag_type_id) {
      case 4: return tag_records[0].is_tag_single_choice === 1 ? Choice.SINGLE : Choice.MULTI
      default: return Choice.FREE_FORMAT
    }
  }
  return Choice.FREE_FORMAT
}

function get_record_tag_type_id (choice:Choice){
  switch (choice) {
    case Choice.FREE_FORMAT: return 5
    default: return 4;

  }
}
export const DEFAULT_SECTION_NAME = 'DEFAULT'

function sort_tags(tags: Array<Tag>): Array<Tag> {
  const grouped_tags = _.groupBy(tags, 'section')
  const section_keys = Object.keys(grouped_tags).sort()
  const sorted_tags = _.flatten(section_keys.map(section => {
    const sorted_tags = sort_tag_groups(grouped_tags[section])
    return sorted_tags
  }))
  return sorted_tags
}

export function to_tags(db_tags: any): Array<Tag> {
  /*
  * Given a list of database records where each row identifies a tag value for a given tag group it converts the data to
  * a data structure as a collection of Tag objects
  */
  let tags: Array<Tag> = []
  //group the list of rows by tag group (tag_id field) so we bundle all the tag values for it
  const by_tag_group = _.groupBy(db_tags, 'tag_id');
  for (const tag_id in by_tag_group) {
    const tag_records: Array<any> = by_tag_group[tag_id]
    const tag_name = tag_records.length > 0 ? tag_records[0].tag_name : 'no tag name'
    const tag_choice = get_tag_type(tag_records)
    const tag_sort_index = tag_records.length > 0 ? tag_records[0].sort_index : 0
    const {tag_values, tag_section} = get_tag_values_and_section_name(tag_records)
    const tag: Tag = {
      id: parseInt(tag_id),
      name: tag_name,
      values: tag_values,
      type: tag_choice,
      section: tag_section,
      sort_index: tag_sort_index
    }
    tags = add_no_duplicates(tags, tag, 'id')
  }
  //first order by section, and within each section order tags either by sort_index (if set) or alphabetically by default
  const sorted_tags = sort_tags(tags)
  return sorted_tags
}

function get_tag_values_and_section_name(tag_records: Array<any>): any {
  /**
   * Transforms a list of database records into a list of TagValue objects that belong to a tag group within a section
   */
  let tag_values: Array<TagValue> = []
  let tag_section = DEFAULT_SECTION_NAME
  for (const tv of tag_records) {
    const tag_value = {id: tv.tag_value_id, value: tv.tag_value}
    tag_values = add_no_duplicates(tag_values, tag_value, 'id')
    tag_section = tv.section_name
  }
  return {tag_values, tag_section}
}


export function contains_tag_value(tag: Tag, selection: Tag) {
  if (_.isEmpty(tag) && _.isEmpty(selection)) {
    return true
  }

  if (_.isEmpty(tag) || _.isEmpty(selection)) {
    return false
  }
  const any_tag_value = selection.values.filter((tag_value: TagValue) => include_tag_value(tag.values, tag_value));
  return tag.id === selection.id && any_tag_value.length > 0
}

export function add_tag_value(tags: Array<Tag>, tag_to_add: Tag): Array<Tag> {
  if (_.isEmpty(tag_to_add)) {
    return tags
  }
  if (_.isEmpty(tags)) {
    return [tag_to_add]
  }

  const tags_constains_tag_to_add = _.some(tags, existing_tag => existing_tag.id === tag_to_add.id)
  if (!tags_constains_tag_to_add) {
    return [...tags, tag_to_add]
  }

  return tags.map((tag: Tag) => {
    return (tag.id !== tag_to_add.id) ? tag : add_or_update_tag_value(tag, tag_to_add)
  })
}

export function is_single_choice(tag:Tag): boolean {
  return tag.type === Choice.SINGLE
}

export function is_multi_choice(tag:Tag): boolean {
  return tag.type === Choice.MULTI
}

export function is_editable(tag:Tag): boolean {
  return tag.type === Choice.FREE_FORMAT
}

function add_or_update_tag_value(tag: Tag, updated_tag: Tag): Tag {
  if (is_multi_choice(tag) || is_editable(tag)) {
    const new_values = [...tag.values, ...updated_tag.values]
    return {...tag, values: new_values}
  }
  return {...tag, values: updated_tag.values}
}

export function remove_tag_value(tags: Array<Tag>, tag_value_to_remove: Tag): Array<Tag> {
  return tags.map((tag: Tag) => {
    if (tag.id === tag_value_to_remove.id) {
      const new_values = tag.values.filter(tag_value => (tag_value.id !== tag_value_to_remove.values[0].id))
      return {id: tag.id, name: tag.name, type: tag.type, sort_index:tag.sort_index, section: tag.section,values: new_values}
    } else {
      return tag
    }
  }).filter((tag: Tag) => {
    return tag.values.length > 0
  })
}

export function include_tag_value(values: Array<TagValue>, tag_value: TagValue): boolean {
  const filtered_values = values.filter((value: TagValue) => {
    return value.id === tag_value.id && value.value === tag_value.value
  })
  return filtered_values.length > 0

}

export function filter_tags(tags: Array<Tag>, search_value: string): Array<Tag> {

  if (_.isEmpty(search_value.trim())) {
    return tags
  }
  const search_terms = search_value.trim().toLowerCase().split(" or ")
  let filtered_tags: Array<Tag> = tags.map(tag => {
    return filter_search_value(tag, search_terms)
  })
  filtered_tags = filtered_tags.filter(tag => {
    return tag !== null
  })
  return filtered_tags
}

export function filter_grouped_tags(grouped_tags: Array<GroupedTags>, search_value: string): Array<GroupedTags> {

  if (_.isEmpty(search_value.trim())) {
    return grouped_tags
  }
  const matched = grouped_tags.map((grouped:GroupedTags) => {
    if (contains(grouped.tag_section, search_value?.toLowerCase())){
      return grouped
    }
    const filtered_tags = filter_tags(grouped.tags, search_value)
    return {...grouped, tags:filtered_tags}
  })
  const filtered = matched.filter(group=>(group.tags.length > 0))

  return filtered
}

function filter_search_value(tag: Tag, search_values: Array<string>): any {

  if (_.some(search_values, search_value => contains(tag.name, search_value?.toLowerCase()) || contains(tag.section, search_value?.toLowerCase()))) {
    return tag
  }

  const filtered_values = tag.values.filter(tag_value => {
    // find any matching values
    return _.some(search_values, search_value => contains(tag_value.value, search_value?.toLowerCase()))
  })
  return _.isEmpty(filtered_values) ? null : {...tag, values: tag.values}

}

export interface GroupedTags {
  tag_section: string,
  tags: Array<Tag>
}

function sort_tag_groups(section_tags_set: Array<Tag>) {
  const is_default_sorting = section_tags_set.filter((tag) => tag.sort_index > 0).length === 0
  const sorted_section_tags = _.sortBy(section_tags_set, (tag) => is_default_sorting ? tag.name.toLowerCase() : tag.sort_index)
  return sorted_section_tags
}

export function to_section_tags(user_tags: any): Array<GroupedTags> {
  const tags_set: Array<GroupedTags> = []
  const user_tags_by_section = _.groupBy(user_tags, 'section_name')
  for (const section in user_tags_by_section) {
    let section_tags_set: Array<Tag> = []
    const tags = _.groupBy(user_tags_by_section[section], 'tag_id')
    let tag_name = ''
    let tag_sort_index = ''
    let tag_section:string = DEFAULT_SECTION_NAME
    for (const tag_id in tags) {
      let tag_orgs= []
      const tag_values = tags[tag_id]
      let values_set: Array<TagValue> = []
      const tag_choice = get_tag_type(tag_values)
      for (const record of tag_values) {
        if (record.tag_value_id != null) {
          values_set = add_no_duplicates(values_set, {
            id: record.tag_value_id,
            value: record.tag_value,
            families_count: record.pat_fams_count
          }, 'id')
        }
        tag_name = record.tag_name
        tag_section = record.section_name
        tag_sort_index = record.sort_index
        if (!_.isEmpty(record.group)){
          tag_orgs.push(record.group)
        }
      }
      const tag_i: Tag = {id: parseInt(tag_id), name: tag_name, values: values_set, type: tag_choice, sort_index:parseInt(tag_sort_index), section: tag_section, orgs:_.unique(tag_orgs)}
      section_tags_set = add_no_duplicates(section_tags_set, tag_i, 'id')
    }
    const sorted_section_tags = sort_tag_groups(section_tags_set)
    const custom_tag_i: GroupedTags = {tag_section: tag_section, tags: sorted_section_tags}
    add_no_duplicates(tags_set, custom_tag_i, 'tag_section')
  }
  return tags_set
}

export function add_no_duplicates(items: Array<any>, item: any, comparison_field: string): Array<any> {
  const is_value_in_set = items.filter((v: any) => (item[comparison_field] === v[comparison_field])).length > 0
  if (!is_value_in_set) {
    items.push(item)
  }
  return items
}

export function get_tag_value_ids(tags:Array<Tag>){
  const tag_value_ids = _.flatten(tags.map(tag => {
    const tag_value_ids = tag.values.map((tag_value: TagValue) => tag_value.id)
    return tag_value_ids
  }))
  return tag_value_ids
}

export function do_nothing_func(){}

export function is_valid_name(name:string, existing: Array<any>, field:(string | null)): boolean{
  const clean_name = remove_special_characters(name)
  const trimmed_name = (clean_name || '').trim()
  if (trimmed_name.length < 1 || trimmed_name.length > MAX_TAG_NAME_LENGTH) {
    return false
  }
  return !_.some(existing, t => ((field)? t[field] === trimmed_name : t === trimmed_name))
}

export function remove_special_characters(text:string): string {
  return remove_last_special_character_from_text(remove_not_allowed_chars_from_text(text))
}

//////////////////////////////////////////////////////////////

export function fetch_grouped_displayable_tags() {
  return fetch_grouped_tags('Unable to fetch available family tags for viewing', 'view')
}

export function fetch_grouped_editable_tags() {
  return fetch_grouped_tags('Unable to fetch family tags to edit', 'edit')
}

export function fetch_all_grouped_tags() {
  return fetch_grouped_tags('Unable to fetch custom all tags grouped by section', null)
}

function fetch_grouped_tags(err_message:string, permission:string | null){
  let uri = FAMILY_TAGS_BASE_URL + '/section_tag'
  if (!_.isEmpty(permission)) {
    uri = uri + '?permission=' + permission
  }
  return axios({
    method: 'GET',
    url: uri,
    timeout: 300000,
  }).then(response => {
      return to_section_tags(response.data)
  })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), err_message)
      throw wrapped_err
    })
}

export function fetch_executable_tags() {
  return fetch_tags ('Unable to fetch family tags grouped by section for tagging', 'tag')
}

export function fetch_viewable_tags() {
  return fetch_tags ('Unable to fetch family tags grouped by for viewing', 'view')
}

function fetch_tags (err_msg:string, permission:string) {
  const uri = FAMILY_TAGS_BASE_URL + '/tag?permission=' + permission
  return axios({
    method: 'GET',
    url: uri,
  }).then(response => {
    if (response != null && response.data != null) {
      return to_tags(response.data)
    } else {
      return []
    }
  })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), err_msg)
      throw wrapped_err
    })
}

export function fetch_families_for_tag_value(tag_value_id: number) {
  return axios({
    method: 'GET',
    url: FAMILY_TAGS_BASE_URL + '/tag_value/' + tag_value_id + '/pat_fam_id',
  }).then(response => {
    //@ts-expect-error
    return response ? response.data.map(family => family.pat_fam_id) : []
  }).catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch families for tag ')
    throw wrapped_err
  })
}

export function fetch_families_for_tag_group(tag_id: number) {
  return axios({
    method: 'GET',
    url: FAMILY_TAGS_BASE_URL + '/tag/' + tag_id + '/pat_fam_id',
  }).then(response => {
    //@ts-expect-error
    return response ? response.data.map(family => family.pat_fam_id) : []
  }).catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch families for tag ')
    throw wrapped_err
  })
}
export function fetch_executable_tags_for_families(pat_fam_ids: Array<number>) {
  const suffix_path = '/tag?permission=tag'
  return fetch_tags_for_families('Unable to fetch executable tags for families ', pat_fam_ids, suffix_path, to_tags)
}

export function fetch_viewing_tags_for_families(pat_fam_ids: Array<number>) {
  const suffix_path = '/tag?permission=view'
  return fetch_tags_for_families('Unable to fetch read only tags for families',  pat_fam_ids, suffix_path, to_tags )
}
export function fetch_full_name_tags_for_families(pat_fam_ids: Array<number>){
  return fetch_tags_for_families('Unable to fetch full name tags for families', pat_fam_ids, '/full_name_tag' )
}

function fetch_tags_for_families(err_msg:string, pat_fam_ids:Array<any>, suffix_path:string, transform_func?: Function) {
  if (!_.isEmpty(pat_fam_ids)) {
    return axios({
      method: 'GET',
      url: FAMILY_TAGS_BASE_URL + '/pat_fam_id/' + pat_fam_ids.join(',') + suffix_path,
    }).then(response => {
      return transform_func ? transform_func(response.data) : response.data
    }).catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch families to view')
      throw wrapped_err
    })
  } else {
    return Promise.resolve()
  }
}

export function fetch_taggers_for_family(family_id: number){
  return axios({
    method: 'GET',
    url: FAMILY_TAGS_BASE_URL + '/pat_fam_id/' + family_id + '/tagger',
  }).then(response => {
    return response.data
  }).catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch taggers for family ')
    throw wrapped_err
  })
}

export function fetch_taggers_for_family_and_tag_group(family_id: number, tag:Tag){
  const value_ids:string = tag.values.map((tv) => tv.id).join(",")
  return axios({
    method: 'GET',
    url: FAMILY_TAGS_BASE_URL + '/pat_fam_id/' + family_id + '/tag_value_ids/' + value_ids + '/tagger',
  }).then(response => {
    return response.data
  }).catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch taggers for family ')
    throw wrapped_err
  })
}

export function tag_families(family_ids: Array<number>, tag: Tag) {
  if (!_.isEmpty(family_ids)) {
    const body = {tag_id: tag.id, tag_name: tag.name, tag_label: tag.values[0].value}
    return axios({
      method: 'PUT',
      url: FAMILY_TAGS_BASE_URL + '/pat_fam_id/' + family_ids.join(",") + '/tag_value/' + tag.values[0].id,
      data: body,
    }).catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to tag family:')
      throw wrapped_err
    })
  } else {
    return Promise.resolve()
  }
}

export function untag_families(family_ids: Array<number>, tag: Tag) {
  if (!_.isEmpty(family_ids)) {
    const tag_data = tag.values.map(tag_value => {
      return {tag_id: tag.id, tag_name: tag.name, tag_value_id: tag_value.id, tag_label: tag_value.value}
    })
    return axios({
      method: 'DELETE',
      url: FAMILY_TAGS_BASE_URL + '/pat_fam_id/' + family_ids.join(",") + '/tag_value',
      data: tag_data,
    }).catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to untag family:')
      throw wrapped_err
    })
  } else {
    return Promise.resolve()
  }

}

export function add_tag_value_for_families(family_ids: Array<number>, tag: Tag) : any{
  if (!_.isEmpty(family_ids)) {
    //first value is the new label to add to the free format tags
    const body = {tag_id: tag.id, tag_name: tag.name, tag_label: tag.values[0].value}
    return axios({
      method: 'POST',
      url: FAMILY_TAGS_BASE_URL + '/pat_fam_id/' + family_ids.join(",") + '/tag_value',
      data: body,
    }).catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to tag family:')
      throw wrapped_err
    })
  } else {
    return Promise.resolve()
  }
}

export function rename_tag_name(tag:Tag, new_name:string) {
  return axios({
    method: 'PUT',
    url: FAMILY_TAGS_BASE_URL + '/tag/' + tag.id +'/name/' + encodeURIComponent(new_name)
  }).catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to change the family tag.')
    throw wrapped_err
  })
}

export function rename_tag_value_name(tag_value:TagValue, new_name:string) {
  return axios({
    method: 'PUT',
    url: FAMILY_TAGS_BASE_URL + '/tag_value/' + tag_value.id +'/name/' + encodeURIComponent(new_name)
  }).catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to change the family tag.')
    throw wrapped_err
  })
}

export function persist_tag_value(tag_id: number, new_tag_value: string) : any{
  const body = { tag_label: new_tag_value}
  return axios({
    method: 'PUT',
    url: FAMILY_TAGS_BASE_URL + '/tag/' + tag_id+ '/tag_value',
    data: body,
  }).catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to create a new label:')
    throw wrapped_err
  })
}

export function delete_tag_value (tag_value:TagValue | null){
  if (!_.isEmpty(tag_value)) {
    return axios({
      method: 'DELETE',
      url: FAMILY_TAGS_BASE_URL + '/tag_value/' + tag_value?.id,
    }).catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete tag value:' + tag_value?.value)
      throw wrapped_err
    })
  } else {
    return Promise.resolve()
  }
}

export function delete_tag (tag:Tag){
  return axios({
    method: 'DELETE',
    url: FAMILY_TAGS_BASE_URL + '/tag/' + tag.id,
  }).catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to delete tag:' + tag.name)
    throw wrapped_err
  })
}

export function persist_new_tag (tag_group:GroupedTags, permissions:Array<UUIDPermissions>) : any{
  return persist_tag('POST', tag_group, permissions)
}

export function persist_existing_tag (tag_group:GroupedTags, permissions:Array<UUIDPermissions>) : any{
  return persist_tag('PUT', tag_group, permissions)
}

function persist_tag (http_action: Method | undefined, tag_group:GroupedTags, permissions:Array<UUIDPermissions>) : any{
  if (!_.isEmpty(tag_group) && !_.isEmpty(tag_group.tags)) {
    const tag = tag_group.tags[0]
    const body = {tag_id:tag.id ,name: tag.name, section_name:tag_group.tag_section, single:tag.type === Choice.SINGLE, type: get_record_tag_type_id(tag.type), tag_values: tag.values, permissions: permissions}
    return axios({
      method: http_action,
      url: FAMILY_TAGS_BASE_URL + '/tag/',
      data: body,
    }).catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to create/amend tag:')
      throw wrapped_err
    })
  } else {
    return Promise.reject('Tag parameters are not specified:' + tag_group)
  }
}

export function amend_tag_sorting (sorting: Array<SectionSorting>) {
  const tag_ids_to_sort = sorting.map((sorted) => sorted.tag_id)
  const indexes_to_sort = sorting.map((sorted) => sorted.sort_index)
  return axios({
    method: 'PUT',
    url: FAMILY_TAGS_BASE_URL + '/tag/' + tag_ids_to_sort.join(',') + '/sort_index/' + indexes_to_sort.join(','),
  }).catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to change sorting for tag group:' + sorting)
    throw wrapped_err
  })
}

export function fetch_tag_permissions(tag_id:number){
  return axios({
    method: 'GET',
    url: FAMILY_TAGS_BASE_URL + '/tag/' + tag_id + '/permission/',
  }).then(response => {
    return response.data
  })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch permissions for tag:' + tag_id)
      throw wrapped_err
    })
}

export function rename_tag_section_name(section_tags:GroupedTags, new_name:string) {

  const tag_ids = section_tags.tags.map(tag => tag.id)
  return axios({
    method: 'PUT',
    url: FAMILY_TAGS_BASE_URL + '/tag/' + tag_ids.join(',') +'/section/name/' + encodeURIComponent(new_name)
  })
    .catch(err => {
    const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to rename tag section description')
    throw wrapped_err
  })
}
