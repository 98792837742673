import React from 'react'
import cn from 'classnames'

import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'

const PrivateSearchModeControl = (
  {
    is_checked,
    on_click,
    className
  }) => {

  return (
    <CheckboxAndLabel
      label='Pause saving search history'

      is_checked={is_checked}
      on_click={on_click}
      className={cn('fs-unmask', className)}
    />
  )
}

export default PrivateSearchModeControl