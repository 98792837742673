import cn from 'classnames'
import React, {useEffect, useState} from 'react'
import {Redirect, withRouter} from 'react-router-dom'
import {PROJECT} from '../../constants/paths.js'
import { is_not_found } from '../../utils/axios_utils.js'
import { get_project } from '../../utils/project_and_versioning_utils'
import { send_error_to_sentry } from '../../utils/sentry_utils.js'
import { withUser } from '../UserContext'
import Spinner from '../widgets/Spinner.js'
import {Project} from './model/project'

type WithProjectProps = {
  project_id: string,
  children: React.ReactNode
}

const WithProject: React.FC<WithProjectProps> = ({project_id, children}) => {

  const [project, set_project] = useState<Project | null>(null)
  const [is_fetching, set_is_fetching] = useState<boolean>(true)
  const [can_view, set_can_view] = useState<boolean>(false)

  useEffect(() => {
    let did_cancel = false
    if (!did_cancel && project_id) {
      set_is_fetching(true)
      get_project(project_id)
        .then((p) => {
          set_project(p)
          set_is_fetching(false)
        })
        .catch(error => {
          if(is_not_found(error)) {
            set_project(null)
          } else {
            send_error_to_sentry(error, {})
          }
          set_is_fetching(false)
        })
    }
    return () => {
      did_cancel = true
    }
  }, [project_id])

  useEffect(() => {
    let did_cancel = false

    if (!did_cancel && project) {
      set_can_view(project.current_user_permissions.view)
    }

    return () => {
      did_cancel = true
    }
  }, [project])

  function render(): JSX.Element {
    return (
      // @ts-ignore
      <React.Fragment>
        {is_fetching &&
          <Spinner size="md" className={cn('d-flex')}/>
        }
        {!is_fetching && can_view && children}
        {!is_fetching && !project && <Redirect to={`${PROJECT}/${project_id}`}/>}
      </React.Fragment>
    )
  }

  return render()
}

export default withRouter(withUser(WithProject))