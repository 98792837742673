import React from 'react'

import BasePlotlyXYChart from './BasePlotlyXYChart.js'

import { BUBBLE_CHART_TYPE } from './base_plotly_props.js'
import Heatmap from './Heatmap.js'
import { get_csv_string } from '../../utils/csv_utils.js'

const BubbleChart = (props) => {

  return (
    <BasePlotlyXYChart
      {...props}
      chart_type={ BUBBLE_CHART_TYPE }
      use_value_prefix={false}
    />
  )
}

BubbleChart.get_plotly_data = (props) => {
  const {spec} = props || {}
  const data = Heatmap.get_csv_value_rows({ ...props, spec: {...spec, hide_totals: true}})
  const export_data = get_csv_string(data)
  return export_data
}

BubbleChart.get_csv_value_rows = (props) => {
  const {spec} = props || {}
  return Heatmap.get_csv_value_rows({ ...props, spec: {...spec, hide_totals: true}})
}

BubbleChart.get_axis_titles = BasePlotlyXYChart.get_axis_titles

export default BubbleChart