import {
  DECK_BACKGROUND_ORG_COLUMN_COLOR,
  DECK_DEFAULT_COLUMN_COLOR,
  GRANTED_COLOR,
  PENDING_COLOR
} from '../constants/colours.js'

import { get_as_map } from '../utils/utils.js'

export const STATUS_COLUMN_NAME = 'Status'

export const STATUS_GRANTED_ID  = 'granted'
export const STATUS_PENDING_ID  = 'pending'
export const STATUS_EXPIRED_ID  = 'expired'
export const STATUS_INACTIVE_ID = 'application_inactive'
export const STATUS_DEAD_ID     = 'application_dead'
export const STATUS_OTHER_ID    = 'other'

export const STATUS_ACTIVE_ID   = 'active' // not a real family status, but a label including 'granted' and 'pending'

export const STATUS_GRANTED = { id: STATUS_GRANTED_ID,  name: 'Granted' }
export const STATUS_PENDING = { id: STATUS_PENDING_ID,  name: 'Pending' }
export const STATUS_EXPIRED = { id: STATUS_EXPIRED_ID,  name: 'Expired' }
export const STATUS_INATIVE = { id: STATUS_INACTIVE_ID, name: 'Inactive' }
export const STATUS_DEAD    = { id: STATUS_DEAD_ID,     name: 'Rejected' }
export const STATUS_OTHER   = { id: STATUS_OTHER_ID,    name: 'Other' }

export const ALL_STATUSES = [
  STATUS_GRANTED,
  STATUS_PENDING,
  STATUS_EXPIRED,
  STATUS_INATIVE,
  STATUS_DEAD,
  STATUS_OTHER
]

export const ID_TO_STATUS = get_as_map(ALL_STATUSES, 'id')

export const ALL_STATUS_IDS = ALL_STATUSES.map(status => status.id)

export const ALIVE_PATFAM_STATUS_DIMS = [
  { ...STATUS_GRANTED, status: STATUS_GRANTED_ID, short_name: STATUS_GRANTED_ID, idx: 0, color: GRANTED_COLOR },
  { ...STATUS_PENDING, status: STATUS_PENDING_ID, short_name: STATUS_PENDING_ID, idx: 1, color: PENDING_COLOR }
]

export const DECK_ALIVE_PATFAM_STATUS_DIMS = [
  { ...STATUS_GRANTED, status: STATUS_GRANTED_ID, short_name: STATUS_GRANTED_ID, idx: 0, color: DECK_DEFAULT_COLUMN_COLOR },
  { ...STATUS_PENDING, status: STATUS_PENDING_ID, short_name: STATUS_PENDING_ID, idx: 1, color: DECK_BACKGROUND_ORG_COLUMN_COLOR }
]