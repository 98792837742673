import React from 'react'
import cn from 'classnames'

import { SparklesIcon } from './IconSet.js'

import cs from '../cipher_styles.module.scss'
import s from './Button.module.scss'

const SIZE_TO_CLASS = {
  xs: s.__xs,
  sm: cn('btn-sm', s.__sm),
  lg: 'btn-lg'
}

/**
 * @param {} size (optional) can be 'sm' or 'lg' (default is medium).
 * @param {} disabled (optional) if true, button is disabled.
 * @param {} outline (optional) if true, outline version of button is shown.
 */
export const PrimaryButton = ({ className, title, children, size, disabled, onClick, onMouseMove, outline, icon_only }) => {
  return (
    <button
      className={cn([
        'btn',
        s.__primary,
        SIZE_TO_CLASS[size],
        {
          [s.__outline]: outline,
          [s.__disabled]: disabled,
          [s.__icon_only]: icon_only
        },
        className
      ])}
      disabled={disabled}
      onClick={onClick}
      title={title || ''}
      onMouseMove={onMouseMove}
    >
      {children}
    </button>
  )
}

export const ProminentButton = ({disabled, className, ...other_props}) => {
  return (
    <PrimaryButton
      {...other_props}
      className={cn(
        s.__prominent,
        {
          [s.__disabled]: disabled
        },
        className
      )}
      disabled={disabled}
    />
  )
}

export const AIButton = ({disabled, className, children,    ...other_props}) => {
  return (
    <PrimaryButton
      {...other_props}
      className={cn(
        s.__ai,
        {
          [s.__disabled]: disabled
        },
        'd-flex align-items-center',
        className
      )}
      disabled={disabled}
    >
      <SparklesIcon className={cn('me-2 my-auto', cs.font_size_large)}/>
      <span className='my-auto'>{children}</span>
    </PrimaryButton>
  )
}

/**
 * @param {} size (optional) can be 'sm' or 'lg' (default is medium).
 * @param {} disabled (optional) if true, button is disabled.
 * @param {} outline (optional) if true, outline version of button is shown.
 */
export const TertiaryButton = ({ className, title, children, size, disabled, onClick, icon_only, innerRef }) => {
  return (
    <button
      ref={innerRef}
      className={cn([
        'btn',
        SIZE_TO_CLASS[size],
        s.__tertiary,
        {
          [s.__disabled]: disabled,
          [s.__icon_only]: icon_only,
        },
        className
      ])}
      disabled={disabled}
      onClick={onClick}
      title={title || ''}
    >
      {children}
    </button>
  )
}

/**
 * @param {} is_on i.e. true/false
 * @param {} size (optional) can be 'sm' or 'lg' (default is medium).
 * @param {} disabled (optional) if true, button is disabled.
 */
export const OnOffButton = ({ className, is_on, children, size, disabled, onClick, title, icon_only }) => {
  return (
    <button
      className={cn([
        'btn',
        SIZE_TO_CLASS[size],
        s.__grouped,
        {
          [s.__icon_only]: icon_only,
          [s.__grouped__on]: is_on,
          [s.__disabled]: disabled
        },
        className
      ])}
      disabled={disabled}
      onClick={onClick}
      title={title || ''}
    >
      {children}
    </button>
  )
}
