import React from 'react'
import cn from 'classnames'

import { get_status, get_status_tooltip } from '../../utils/family_view_utils.js'
import { InfoPopover } from '../widgets/Tooltip.js'
import { WRAPPER_ELEMENT_PARENT } from '../../constants/tooltip.js'

import s from './FamilyStatus.module.scss'
import { ExclamationMarkIcon } from '../widgets/IconSet.js'
import FamilyId from './FamilyId.js'

export const FamilyStatus = ({family, is_in_modal, className}) => {
  const {status, patFamId} = family
  const non_null_status = status? status : 'no-status'
  const family_status = get_status(non_null_status) || non_null_status
  const family_status_hint = get_status_tooltip(family_status)

  return (
    <div className={cn('d-flex', className)} key={`status_${patFamId}`}>
      <div className={cn('px-2 my-auto', s.content)}>{family_status}</div>
      {family_status_hint &&
        <InfoPopover is_in_modal={is_in_modal} wrapper={WRAPPER_ELEMENT_PARENT} placement='bottom' buttonClassName='my-auto ms-1'><span className='text-endnormal'>{family_status_hint}</span></InfoPopover>
      }
    </div>
  )
}

export const FamilyDeprecated = ({family, alternative_family, is_in_modal, on_show_alternative, className}) => {
  const {patFamId} = family
  const {patFamId: alternative_id} = alternative_family || {}
  return (
    <div className={cn('d-flex', className)} key={`status_${patFamId}`}>
      <div className={cn('px-2 my-auto', s.content, s.content__deprecated)}>Deprecated</div>
      <InfoPopover
        is_in_modal={is_in_modal}
        interactive={true}
        placement='bottom'

        toggler={<span className={cn(s.__deprecated)}><ExclamationMarkIcon /></span>}
        buttonClassName='ms-2 my-auto'
      >

        <div className='mb-2'>
          <span>This deprecated family is replaced by</span>
        </div>
        <FamilyId
          family_id={alternative_id}
          display_as_linkable_text={true}
          on_family_id_click={on_show_alternative}
          show_similar_families_search={false}
        />
      </InfoPopover>
    </div>
  )
}