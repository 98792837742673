import axios from 'axios'
import _ from 'underscore'

import { USER_SETTINGS_BASE_URL } from '../constants/urls.js'
import { add_source_err_to_target_err } from './axios_utils.js'
import { get_default_report_region_grouping } from './regions_utils.js'
import { DEFAULT_UTT_VERSION, DEPRECATED_UTT_VERSION_IDS } from '../model/utt_version.js'
import { DEFAULT_LANGUAGE, LANGUAGE_CODE_TO_OPTION } from '../model/language_prefs.js'

export function fetch_user_settings() {
  return axios.get(`${USER_SETTINGS_BASE_URL}`)
    .then(response => {
      const key_to_string = response.data
      // parse values
      return _.mapObject(key_to_string, val => JSON.parse(val))
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch user setting: ')
      throw wrapped_err
    })
}

export function fetch_user_settings_by_id(user_id) {
  return axios.get(`${USER_SETTINGS_BASE_URL}/id/${user_id}`)
    .then(response => {
      const key_to_string = response.data
      // parse values
      return _.mapObject(key_to_string, val => JSON.parse(val))
    })
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to fetch user setting: ')
      throw wrapped_err
    })
}

export function save_user_setting(key, value) {
  const data = [{
    key: key,
    value: JSON.stringify(value)
  }]

  return axios.post(`${USER_SETTINGS_BASE_URL}`, data, {headers: {'Content-Type': 'application/json' }})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save user setting: ')
      throw wrapped_err
    })
}

export function save_user_setting_by_id(user_id, key, value) {
  const data = [{
    key: key,
    value: JSON.stringify(value)
  }]

  return axios.post(`${USER_SETTINGS_BASE_URL}/id/${user_id}`, data, {headers: {'Content-Type': 'application/json' }})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save user setting: ')
      throw wrapped_err
    })
}

export function delete_user_settings(keys_to_delete=[]) {
  return axios.delete(`${USER_SETTINGS_BASE_URL}`, {data: keys_to_delete})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to reset user settings: ')
      throw wrapped_err
    })
}

export function delete_user_settings_by_id(user_id, keys_to_delete=[]) {
  return axios.delete(`${USER_SETTINGS_BASE_URL}/id/${user_id}`, {data: keys_to_delete})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to reset user settings: ')
      throw wrapped_err
    })
}

export function get_report_region_grouping(user_settings) {
  const {user_report_region_grouping} = user_settings || {}
  return user_report_region_grouping || get_default_report_region_grouping().id
}

export function get_show_ungrouped_families(user_settings) {
  const {user_show_ungrouped_families} = user_settings || {}
  return (user_show_ungrouped_families != null) ? user_show_ungrouped_families : false
}

export function get_default_utt_version(user_settings) {
  const {user_default_utt_version} = user_settings || {}

  if (!user_default_utt_version || DEPRECATED_UTT_VERSION_IDS.indexOf(user_default_utt_version) !== -1) return DEFAULT_UTT_VERSION

  return user_default_utt_version
}

export function has_charts_in_thumbnails(user_settings) {
  const {user_charts_in_thumbnails} = user_settings || {}
  return (user_charts_in_thumbnails != null) ? user_charts_in_thumbnails : true
}

export function get_default_chart_selection(user_settings) {
  const {user_default_chart_selection} = user_settings || {}
  return user_default_chart_selection
}

export function get_starting_page(user_settings) {
  const {user_starting_page} = user_settings || {}
  return user_starting_page
}

export function should_report_show_ungrouped_families(user_state, user_settings) {
  const {show_ungrouped_families} = user_state || {}
  return (show_ungrouped_families != null) ? show_ungrouped_families : get_show_ungrouped_families(user_settings)
}

export function should_include_unrelated_technologies(user_settings) {
  const {user_include_unrelated_techs} = user_settings || {}
  return (user_include_unrelated_techs != null) ? user_include_unrelated_techs : false
}

export function should_notify_before_report_expiry(user_settings) {
  const {notify_before_report_expiry} = user_settings || {}
  return (notify_before_report_expiry != null) ? notify_before_report_expiry : true
}

export function get_language_preference(user_settings) {
  const {user_language_preference} = user_settings || {}

  return (user_language_preference && LANGUAGE_CODE_TO_OPTION[user_language_preference]) ? user_language_preference : DEFAULT_LANGUAGE
}

export function get_save_knn_searches(user_settings) {
  const {user_save_knn_searches} = user_settings || {}
  return user_save_knn_searches
}
