import React from 'react'

import BasePlotlyXYChart from './BasePlotlyXYChart.js'
import { LINEMODE_LINE, SCATTER_CHART_TYPE } from './base_plotly_props.js'

const LineChart = (props) => {
  return (
    <BasePlotlyXYChart
      {...props}
      chart_type={SCATTER_CHART_TYPE}
      linemode={LINEMODE_LINE}
    />
  )
}

LineChart.get_plotly_data = (props) => {
  return BasePlotlyXYChart.get_plotly_data({ ...props, chart_type: SCATTER_CHART_TYPE })
}

LineChart.get_axis_titles = BasePlotlyXYChart.get_axis_titles

LineChart.get_csv_value_rows = (props) => {
  return BasePlotlyXYChart.get_csv_value_rows({ ...props, chart_type: SCATTER_CHART_TYPE })
}

export default LineChart
