export const AGE_GROUP_ID = '8fH8h'
export const COST_GROUP_ID = '54QI1'
export const DATA_GROUP_ID = 'Lul8R'
export const GEOGRAPHY_GROUP_ID = '5zXrl'
export const LITIGATION_GROUP_ID = 'wb8UR'
export const PATENTING_ACTIVITY_GROUP_ID = 'Mh07G'
export const PORTFOLIO_SIZE_GROUP_ID = 'U3oWO'
export const PORTFOLIO_STATS_GROUP_ID = 'bfR9x'
export const PORTFOLIO_TRENDS_GROUP_ID = 'j7Fqb'
export const SCORE_GROUP_ID = 'nChUZ'
export const SELECTED_GROUP_ID = 'selected'
export const PVIX_SCORE_GROUP_ID = 'CcIql'