import React, { Component } from 'react'

import ReportBuilder from './ReportBuilder.js'

class NDReportBuilder extends Component {
  render() {
    return (
      <ReportBuilder
        is_nd_report={true}
      />
    )
  }
}

export default NDReportBuilder