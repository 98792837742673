export function is_item_within_histogram_range(item, range) {
  const [from_value, to_value] = range

  const {id, name} = item

  const item_value = id * 1

  const is_multi_value_bin = (name || '').split('-').length > 1

  return (item_value >= from_value) && (is_multi_value_bin ? item_value < to_value : item_value <= to_value)
}

export function get_from_value_from_bin_name(bin_item) {
  const {name} = bin_item || {}

  return ((name || '').split('-')[0] || '').trim()
}

export function get_to_value_from_bin_name(bin_item) {
  const {name} = bin_item || {}

  const name_chunks = (name || '').split('-')

  return (name_chunks[1] || name_chunks[0] || '').trim()
}