import { get_as_map } from '../utils/utils.js'

const HELP = {id: 'help', label: 'Help/Support' }
const REQUEST = {id: 'request', label: 'Feedback/Requests'}
const ERROR = {id: 'error', label: 'Report an error'}
const OTHER = {id: 'other', label: 'Other'}

export const ALL_FEEDBACK_CATEGORIES = [
  HELP,
  {id: 'grouping', label: 'Grouping Organisations'},
  REQUEST,
  ERROR,
  OTHER
]

export const TECH_DISCOVERY_FEEDBACK_CATEGORIES = [
  HELP,
  REQUEST,
  ERROR,
  OTHER
]


export const FEEDBACK_CATEGORIES_BY_ID = get_as_map(ALL_FEEDBACK_CATEGORIES, 'id')