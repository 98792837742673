import { get_as_map } from '../../../utils/utils.js'

export const IS_SELECTED_FIELD_ID     = 'IS_SELECTED_FIELD_ID'
export const NAME_FIELD_ID            = 'title'
export const BUILD_NEW_FIELD_ID       = 'BUILD_NEW_FIELD_ID'
export const RERUN_FIELD_ID           = 'RERUN_FIELD_ID'
export const OWNER_FIELD_ID           = 'owner_name'
export const CREATED_AT_FIELD_ID      = 'created_at'
export const LAST_VIEWED_FIELD_ID     = 'last_viewed'
export const STATUS_FIELD_ID          = 'status'
export const NUM_PATFAMS_PROCESSED_ID = 'NUM_PATFAMS_PROCESSED_ID'
export const NUM_PATFAMS_TOTAL_ID     = 'NUM_PATFAMS_TOTAL_ID'
export const NUM_POSITIVES_FOUND_ID   = 'NUM_POSITIVES_FOUND_ID'
export const ACTIONS_FIELD_ID         = 'ACTIONS_ID'

const REPORT_FIELDS = [
  { id: IS_SELECTED_FIELD_ID,                        sortable: false },
  { id: NAME_FIELD_ID,            name: 'Name'       },
  { id: BUILD_NEW_FIELD_ID,                          sortable: false },
  { id: RERUN_FIELD_ID,                              sortable: false },
  { id: OWNER_FIELD_ID,           name: 'Owner'      },
  { id: CREATED_AT_FIELD_ID,      name: 'Created'    },
  { id: LAST_VIEWED_FIELD_ID,     name: 'Last used'  },
  { id: STATUS_FIELD_ID,          name: 'Status'     },
  { id: ACTIONS_FIELD_ID,         name: 'Actions',   sortable: false },

  { id: NUM_PATFAMS_TOTAL_ID,     name: 'Families'   },
  { id: NUM_PATFAMS_PROCESSED_ID, name: 'Processed'  },
  { id: NUM_POSITIVES_FOUND_ID,   name: 'Positives'  },
]

export const ID_TO_REPORT_FIELD = get_as_map(REPORT_FIELDS, 'id')
