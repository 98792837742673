import React from 'react'

import TextLink from '../widgets/TextLink.js'
import { CI_GUIDE, HELP } from '../../constants/paths.js'

export const ExecutiveSummaryGuideLink = ({className}) => {
  return (
    <TextLink element='a' target='_blank' href={`${HELP}/${CI_GUIDE}`} className={className}>
      See key
    </TextLink>
  )
}