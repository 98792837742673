import { useEffect, useState } from 'react'

import {
  fetch_organisation_tree_by_id,
  fetch_organisations_by_search_phrase,
  get_grouped_children,
  get_ordered_children,
  get_org_id,
  is_organisation,
  SEARCH_DEBOUNCE_PERIOD, update_tree_with_agglomeration_ids
} from '../utils/organisation_utils.js'

import { useDebounce, useToggle } from './general_hooks.js'
import { DESCENDING } from '../model/sort_directions.js'
import { SORT_TYPE_BY_ID } from '../model/organisation.js'

export function useOrgSearch(search_phrase, roots_only, filter_empty) {
  const [is_fetching, set_is_fetching] = useState(false)
  const [temp_results, set_temp_results] = useState(null)
  const [results, set_results] = useState(null)
  const [search_error, set_search_error] = useState(null)

  const [should_refresh, set_should_refresh] = useState(false)

  const debounce_time = (search_phrase && search_phrase.length > 2) ? SEARCH_DEBOUNCE_PERIOD : 2 * SEARCH_DEBOUNCE_PERIOD

  const debounced_search_phrase = useDebounce(search_phrase, debounce_time)

  function clear_error() {
    set_search_error(null)
  }

  function refresh_search() {
    set_should_refresh(true)
  }

  useEffect(() => {
    if (!debounced_search_phrase || debounced_search_phrase === '' || debounced_search_phrase.length === 1) {
      set_results(null)
      set_is_fetching(false)
    } else {
      set_is_fetching(true)
      set_results(null)
      fetch_organisations_by_search_phrase(debounced_search_phrase, roots_only, filter_empty)
        .then(response => {
          set_temp_results(response)
        })
        .catch(error => {
          set_is_fetching(false)
          set_search_error(error)
        })
    }
  }, [debounced_search_phrase, roots_only, filter_empty])

  useEffect(() => {

    if (!should_refresh || !debounced_search_phrase) return

    set_is_fetching(true)
    set_should_refresh(false)
    fetch_organisations_by_search_phrase(debounced_search_phrase, roots_only, filter_empty)
      .then(response => {
        set_temp_results(response)
      })
      .catch(error => {
        set_is_fetching(false)
        set_search_error(error)
      })

  }, [should_refresh, debounced_search_phrase, roots_only, filter_empty])

  useEffect(() => {
    if (temp_results == null || temp_results.search_phrase.trim() !== search_phrase.trim()) {
      return
    }

    const { results } = temp_results

    set_results(results)
    set_is_fetching(false)
  }, [temp_results, search_phrase])

  return [is_fetching, results, search_error, clear_error, refresh_search]
}

export function useOrganisationTree(org, is_grouping_mode) {
  const [should_fetch_tree, set_should_fetch_tree] = useState(false)
  const [should_order_tree, set_should_order_tree] = useState(false)
  const [should_group_by_type, toggle_group_by_type ] = useToggle(is_grouping_mode)
  const [is_fetching, set_is_fetching] = useState(false)
  const [org_tree, set_org_tree] = useState(org.children ? org : null)
  const [ordered_children, set_ordered_children] = useState(null)

  const [selected_sort_dir, set_selected_sort_dir] = useState(DESCENDING)
  const [selected_sort_by, set_selected_sort_by] = useState(SORT_TYPE_BY_ID.size.id)

  const [error, set_error] = useState(null)

  function do_fetch() {
    set_should_fetch_tree(true)
  }

  function clear_error() {
    set_error(null)
  }

  function do_reorder({selected_sort_by, selected_sort_dir}) {
    set_selected_sort_by(selected_sort_by)
    set_selected_sort_dir(selected_sort_dir)
    set_should_order_tree(true)
  }

  function do_group_by_type() {
    toggle_group_by_type()
  }

  useEffect(() => {
    if (!should_fetch_tree) return

    if (!is_organisation(org) || org.children) {
      set_org_tree(org)
      set_should_fetch_tree(false)
      set_should_order_tree(true)
      return
    }

    set_is_fetching(true)
    fetch_organisation_tree_by_id(get_org_id(org))
      .then(tree => {
        const tree_with_ids = update_tree_with_agglomeration_ids(tree)
        set_org_tree(tree_with_ids)
        set_is_fetching(false)
        set_should_order_tree(true)
      })
      .catch(error => {
        set_is_fetching(false)
        set_error(error)
      })
  }, [should_fetch_tree, org])

  useEffect(() => {
    if (!org_tree) return
    set_is_fetching(true)
    const {children} = org_tree
    const ordered_children = get_ordered_children(children, {sort_by: selected_sort_by, sort_dir: selected_sort_dir})

    set_ordered_children(should_group_by_type ? get_grouped_children(ordered_children) : ordered_children)
    set_is_fetching(false)
    set_should_order_tree(false)
  }, [should_order_tree, should_group_by_type, selected_sort_by, selected_sort_dir, org_tree])

  return [do_fetch, do_reorder, do_group_by_type, is_fetching, {...org_tree, children: ordered_children}, {selected_sort_by, selected_sort_dir}, should_group_by_type, error, clear_error]
}