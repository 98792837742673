import React, {useState} from 'react'
import { DropdownItem, UncontrolledDropdown, DropdownMenu } from 'reactstrap'
import cn from 'classnames'

import { has_utt, is_creator } from '../../utils/user_permissions.js'
import { withUser } from '../UserContext.js'
import { DEFAULT_REPORT_TYPE, UTT_REPORT_TYPE } from '../../constants/constants.js'
import { REPORT_FROM_LIST_UNKNOWN_TYPE } from '../../utils/patent_family_list_utils.js'
import { DownloadSpinner } from '../widgets/Spinner.js'
import SelectDropdownToggleStatic from '../widgets/SelectDropdownToggleStatic.js'

import s from './PatentFamilyListActions.module.scss'
import { get_static_classname } from '../../utils/tracking_utils.js'

export const PatentFamilyListActionsWrapper = (
  {
    is_csv_downloading,
    is_xls_downloading,

    spinner_right,

    children,

    className
  }) => {

  return (
    <div className={cn('d-flex justify-content-end', s.actions_wrapper, className)}>
      {spinner_right && children}
      <div className='my-auto me-2'>
        {(is_csv_downloading || is_xls_downloading) &&
          <DownloadSpinner
            text={`Downloading ${is_csv_downloading?'CSV':''}${is_xls_downloading?'Excel':''}`}
          />
        }
      </div>
      {!spinner_right && children}
    </div>
  )
}

const PatentFamilyListActions = (
  {
    user,
    download_as_csv_handler,
    download_as_xls_handler,
    is_export_in_progress,
    no_field_ids_selected,
    create_report_from_subset_handler,
    list_no_results,
    list_fetching,
    list_too_long,
    list_too_long_to_create_report,

    CustomActions,

    menu_on_right,
    direction,
    menu_in_navbar,
    button_label,
    download_header,

    className,
    buttonClassName,
    menuClassName
  }) => {

  const [is_open, set_is_open] = useState(false)

  const disabled = list_no_results || list_fetching || list_too_long
  const has_results = !list_no_results && !list_fetching

  const should_display_report_build_options = is_creator(user) && create_report_from_subset_handler && has_results

  const should_display_option_headers = should_display_report_build_options || (CustomActions != null)

  return (
    <UncontrolledDropdown
      className={className}
      onToggle={() => set_is_open(!is_open)}
      inNavbar={menu_in_navbar != null ? menu_in_navbar : false}
      direction={direction || 'down'}
    >
      <SelectDropdownToggleStatic
        className={cn(s.dropdown_button, buttonClassName)}
        tag='div'
        is_dropdown_open={is_open}
      >
        {button_label || <span>Export</span>}
      </SelectDropdownToggleStatic>

      <DropdownMenu
        className={cn(s.menu, menuClassName)}
        end={menu_on_right != null ? menu_on_right : true}
      >
        {!disabled && (should_display_report_build_options || (CustomActions != null)) &&
          <DropdownItem header>
            {download_header || <span>Download</span>}
          </DropdownItem>
        }
        {!disabled && download_as_csv_handler &&
          <DropdownItem
            disabled={is_export_in_progress || no_field_ids_selected}
            onClick={download_as_csv_handler}
            className={get_static_classname('families_download_csv_btn')}
          >
            CSV
          </DropdownItem>
        }

        {!disabled && download_as_xls_handler &&
          <DropdownItem
            disabled={is_export_in_progress || no_field_ids_selected}
            onClick={() => download_as_xls_handler()}
            className={get_static_classname('families_download_xls_btn')}
          >
            Excel
          </DropdownItem>
        }

        {disabled &&
          <DropdownItem disabled>
            {list_too_long && <span>Download disabled (selection too large)</span>}
            {(list_fetching) && <span>Waiting for results</span>}
            {(!list_fetching && list_no_results) && <span>No results found</span>}
          </DropdownItem>
        }

        {should_display_report_build_options &&
          <>
            {should_display_option_headers &&
              <>
                <DropdownItem divider />
                <DropdownItem header>
                  Report
                </DropdownItem>
              </>
            }
            <DropdownItem
              onClick={() => create_report_from_subset_handler(REPORT_FROM_LIST_UNKNOWN_TYPE)}
              disabled={list_too_long_to_create_report}
            >
              {list_too_long_to_create_report && <span>Starting new report disabled (selection too large)</span>}
              {!list_too_long_to_create_report && <span>Start new report from list</span>}
            </DropdownItem>

            <DropdownItem
              onClick={() => create_report_from_subset_handler(DEFAULT_REPORT_TYPE)}
              disabled={list_too_long_to_create_report}
            >
              {list_too_long_to_create_report && <span>Clustering disabled (selection too large)</span>}
              {!list_too_long_to_create_report && <span>Cluster this list</span>}
            </DropdownItem>

            {has_utt(user) &&
              <DropdownItem
                onClick={() => create_report_from_subset_handler(UTT_REPORT_TYPE)}
                disabled={list_too_long_to_create_report}
              >
                {list_too_long_to_create_report && <span>Building UTT report disabled (selection too large)</span>}
                {!list_too_long_to_create_report && <span>Build UTT report from list</span>}
              </DropdownItem>
            }
          </>
        }
        {CustomActions &&
          <>
            <DropdownItem divider />
            {CustomActions}
          </>
        }
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default withUser(PatentFamilyListActions)