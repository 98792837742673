import React, { Component } from 'react'

import SvgText from './SvgText.js'

class SvgTitle extends Component {
  render() {
    return (
      <SvgText
        fontSize={'14.5px'}
        y={20}
        {... this.props}
      />
    )
  }
}

export default SvgTitle
