import {COST_APPLICATION_TYPE, COST_RENEWAL_TYPE} from '../model/cost.js'

export function transform_application_and_renewals_data(query_data) {
  const {columns, data, types} = query_data

  let year_column = []
  let type_column = []
  let cost_column = []

  const years = data[0]
  const applications = data[1]
  const renewals = data[2]

  years.forEach((year, i) => {
    year_column = [...year_column, year, year]
    type_column = [...type_column, COST_APPLICATION_TYPE, COST_RENEWAL_TYPE]
    cost_column = [...cost_column, applications[i], renewals[i]]
  })

  const new_data = [year_column, type_column, cost_column]

  return {
    types: [types[0], 'VARCHAR', types[2]],
    columns: [columns[0], 'type', columns[2]],
    data: new_data
  }
}