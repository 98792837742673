import React from 'react'
import { Container } from 'reactstrap'
import cn from 'classnames'

import s from './ContainerFixedWidth.module.scss'

const ContainerFixedWidth = ({className, children}) => {
  return (
    <Container className={cn(s.block, className)}>
      {children}
    </Container>
  )
}

export default ContainerFixedWidth