import axios from 'axios'
import _ from 'underscore'

import { ALERTS_BASE_URL, SUBSCRIPTIONS_URL } from '../constants/urls.js'
import { CONFIG_NO_CACHE, JSON_POST_HEADER, add_source_err_to_target_err } from '../utils/axios_utils.js'

export const DEFAULT_THRESHOLD = 0.5
export const MULTI_THRESHOLD = 'both'
export const ALERT_THRESHOLD_OPTIONS = [0.4, DEFAULT_THRESHOLD, MULTI_THRESHOLD]
export const UNKNOWN_ALERT_TECH_NAME = 'unknown alert technology'

export const CLASSIFIER_ID = 'classifier_id'
export const NAME = 'name'
export const THRESHOLD = 'threshold'

export function get_all_user_subscriptions() {
  return axios.get(SUBSCRIPTIONS_URL, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch user alerts subscriptions: ')
    })
}

export function save_or_update_subscriptions(subscriptions) {
  return axios.post(SUBSCRIPTIONS_URL, subscriptions, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update user alerts subscriptions: ')
    })
}

export function fetch_classifier_alert_content(alert_tech_id, event_type, territory, from_date, to_date) {
  return axios.get(`${ALERTS_BASE_URL}/${alert_tech_id}/updates/${from_date}`, {params: {event_type, territory, to_date}})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch alerted patfams: ')
    })
}

export function classifier_as_subscription_object(classifier, active, updated_threshold) {
  const {classifier_id, threshold} = classifier
  return {
    classifier_id,
    active,
    threshold: updated_threshold || threshold || DEFAULT_THRESHOLD
  }
}

export function is_same_subscription(this_subscription, that_subscription) {
  return _.isMatch(this_subscription, _.pick(that_subscription, CLASSIFIER_ID, THRESHOLD))
}

export function get_subscription_display_name(subscription, classifier) {
  if (!classifier) {
    return UNKNOWN_ALERT_TECH_NAME
  }
  const {name} = classifier
  const {threshold} = subscription
  return threshold === DEFAULT_THRESHOLD ? name : `${name} (${threshold})`
}