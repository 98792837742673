import axios from 'axios'
import qs from 'query-string'

import {
  SIMILAR_FAMILIES_BASE_URL,
  KNN_SEARCH_URL,
  KNN_TEXT_TO_QUERY_URL
} from '../constants/urls.js'
import { add_source_err_to_target_err, FORM_POST_HEADER } from './axios_utils.js'
import { generate_random_hash, get_hashed_id_with_salt, is_number } from './utils.js'
import { SIMILARITY_SCORE_ID } from '../model/patent_family_fields.js'
import { create_cipher_family_id_from_family_id } from './family_view_utils.js'
import { get_from_local_storage, save_to_local_storage } from './local_storage_utils.js'
import { KNN } from '../constants/paths.js'

export const CONTEXT = 'tech_explorer'
export const LS_TECH_EXPLORER_K = 'cipher_tech_explorer_k'
export const LS_CLASSIFIER_BUILDER_K = 'cipher_classifier_builder_k'

const COUNT_1000 = 1000
export const COUNT_OPTIONS = [100, 200, 500, COUNT_1000, 2000, 5000, 10000, 20000, 50000]
export const EXPERIMENTAL_COUNT_OPTIONS = [100000]
export const DEFAULT_COUNT_OPTION = COUNT_1000

export const TEXT_TYPE_TECH_NAME = 'tech_name'

export const FAMILY_ID = 'family_id'
export const COUNT     = 'count'

export const NAME_MAX_LENGTH = 100

const LS_NN_ANONYMOUS_SEARCH = 'cipher_nn_anon_search_enabled'

export function get_ids_and_count_from_url(location) {
  const query_params = qs.parse(location.search)

  const {[FAMILY_ID]: family_ids /* comma-separated string */, [COUNT]: count} = query_params || {}

  const count_from_url = (count && is_number(count)) ? parseInt(count) : get_default_count_setting()

  return [ family_ids, count_from_url ]
}

export function check_knn_input_valid(family_ids, text) {
  const has_family_ids = (family_ids != null) && (family_ids.length > 0)
  const has_text = (text != null) && (text.length > 0)
  const is_search_valid = (has_family_ids && (family_ids.trim().length > 0)) || (has_text && (text.trim().length > 0))
  return { has_family_ids, has_text, is_search_valid }
}

function do_knn_search(input_data) {
   return axios.post(KNN_SEARCH_URL, input_data, {headers: {FORM_POST_HEADER, 'accept': 'application/json'}})
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch similar families search results: ')
    })
}

export function find_similar_families(pat_fam_ids, text, k=get_default_count_setting(), blocklist) {
  const input_data = {
    ...(pat_fam_ids && pat_fam_ids.length > 0) ? {pat_fam_ids} : {},
    ...(text && text.length > 0) ? {text} : {},
    ...(blocklist != null) ? {blocklist} : {},
    k,
    scores: true
  }
  return do_knn_search(input_data)
    .then(response => {
      const { data } = response || {}
      const {pat_fam_ids=[], scores=[]} = data || {}

      return pat_fam_ids.map((patfam_id, i) => {
        return {patfam_id, [SIMILARITY_SCORE_ID]: scores[i] || 0}
      })
    })
}

export function parse_text_input(text) {
  return axios.post(KNN_TEXT_TO_QUERY_URL, qs.stringify({text}), {headers: {FORM_POST_HEADER, 'accept': 'application/json'}})
    .then(response => {
      const {data} = response || {}
      const {query} = data || {}
      const { pat_fam_ids, text } = query || {}

      if (((pat_fam_ids || []).length === 0) && ((text || []).length === 0)) {
        const error = new Error('No input')
        error.status = 400
        throw error
      }

      return response.data
    })
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch parsed input: ')
    })
}

export function get_endpoint_url(endpoint, id) {
  return `${SIMILAR_FAMILIES_BASE_URL}/${endpoint}/${id}`
}

export function get_similar_families_search_data(id) {
  return axios.get(`${get_endpoint_url('search', id)}`)
    .then(response => {
      const { input, settings, user_id, is_saved, name, search_id, revision_id, context } = response.data
      const { k } = input || {}
      return { input: {...input, k: k || get_default_count_setting()}, settings, user_id, is_saved, name, search_id, revision_id, context }
    })
}

export function find_similar_families_by_input_id(id) {
  return get_similar_families_search_data(id)
    .then(({input, settings, name}) => {
      const { text, pat_fam_ids } = input || {}

      if (!(pat_fam_ids || text)) {
        return null
      }

      return do_knn_search(input)
        .then((response) => {
          const {data: results} = response || {}

          return { results, input, settings, name }
        })
    })
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch similar families by saved input: ')
    })
}

export function get_search_unique_name(base_name, history_enabled) {
  if (!history_enabled) return Promise.resolve({name: base_name})
  return get_search_unique_name_from_base_name(base_name)
}

export function get_search_unique_name_from_base_name(base_name) {
  const data = {base_name}
  return axios.post(`${SIMILAR_FAMILIES_BASE_URL}/find_unique_name`, data)
    .then(response => {
      const { name } = response.data || {}
      return { name }
    })
}

export function save_similar_families_search_input({input, settings, is_saved, name, context}, id, search_id, revision_id, history_enabled) {
  const data = {input, settings, is_saved, name, id, search_id, revision_id, history_enabled, context}
  return axios.post(`${SIMILAR_FAMILIES_BASE_URL}/save`, data)
    .then(response => {
      const { id } = response.data || {}
      return { id }
    })
}

export function update_similar_families_search_settings(settings, id) {
  const data = {settings}
  return axios.put(`${get_endpoint_url('update_settings', id)}`, data)
    .then(response => {
      const { id } = response.data || {}
      return { id }
    })
}

export function update_similar_families_input_saved_status(id, is_saved) {
  const data = {is_saved}
  return axios.put(`${get_endpoint_url('update_saved', id)}`, data)
    .then(response => {
      const { id } = response.data || {}
      return { id }
    })
}

export function update_similar_families_input_name(id, name) {
  const data = {name}
  return axios.put(`${get_endpoint_url('update_name', id)}`, data)
    .then(response => {
      const { id } = response.data || {}
      return { id }
    })
}

export function update_similar_families_input_context(id, context) {
  const data = {context}
  return axios.put(`${get_endpoint_url('update_context', id)}`, data)
    .then(response => {
      const { id } = response.data || {}
      return { id }
    })
}

export function transform_search_to_tech_explorer_context(id) {
  return axios.put(`${get_endpoint_url('update_tech_explorer_context', id)}`)
    .then(response => response.data)
}

export function get_recent_and_saved_searches() {
  return axios.get(`${SIMILAR_FAMILIES_BASE_URL}/searches`)
    .then(response => response.data)
}

export function delete_search(id) {
  return axios.delete(`${get_endpoint_url('delete', id)}`)
    .then(response => response.data)
}

export function generate_input_id() {
  return generate_random_hash(10)
}

function get_search_name_by_input(input) {
  if (!input) return ''
  const {pat_fam_ids=[], text=[]} = input

  const input_text = text || []

  const tech_names = []
  const abstracts = []

  input_text.forEach(item => {
    const {type, value} = item || {}

    if (type === TEXT_TYPE_TECH_NAME) {
      tech_names.push(value)
    } else {
      abstracts.push(value)
    }
  })

  const abstract_string = abstracts[0]
  const families_string = (pat_fam_ids || []).slice(0,3).map(item => create_cipher_family_id_from_family_id(item)).join(', ')

  const has_abstracts = abstracts.length > 0
  const has_families = (pat_fam_ids || []).length > 0

  if (tech_names.length > 0) {
    let name = ''

    for (let i = 0; i < tech_names.length; i++) {
      const extended_name = `${name}${((i !== 0) && (i !== (tech_names.length - 1))) ? ', ' : ''}${tech_names[i]}`

      if (extended_name.length <= NAME_MAX_LENGTH) {
        name = extended_name
      } else {
        return extended_name
      }
    }

    return name
  }

  if (has_abstracts) {
    const families_suffix = has_families ? ` and ${pat_fam_ids.length > 1 ? 'families' : families_string}` : ''

    let name_abs = ''

    const abstract_words = abstract_string.split(' ')

    for (let i = 0; i < abstract_words.length; i++) {
      const extended_name = `${name_abs}${((i !== 0) && (i !== (tech_names.length - 1))) ? ' ' : ''}${abstract_words[i]}`

      if (extended_name.length <= (NAME_MAX_LENGTH - families_suffix.length)) {
        name_abs = extended_name
      } else {
        return `${extended_name}${families_suffix}`
      }
    }

    return `${name_abs}${families_suffix}`
  }

  return families_string
}

export function create_search_name(input) {
  const name_string = get_search_name_by_input(input)

  return name_string
}

export function has_user_selected_anonymous_mode() {
  return get_from_local_storage(LS_NN_ANONYMOUS_SEARCH)
}

export function change_user_selected_anonymous_mode(is_anonymous) {
  save_to_local_storage(LS_NN_ANONYMOUS_SEARCH, is_anonymous)
}

export function is_history_enabled({should_save_knn_searches, anonymous_mode_enabled}) {
  return (should_save_knn_searches === true) && !anonymous_mode_enabled
}

export function is_tech_explorer_url(pathname) {
  return pathname.startsWith(KNN)
}

export function mask_knn_id(id) {
  return get_hashed_id_with_salt(id, CONTEXT)
}

export function save_default_count_setting_to_ls(count) {
  save_to_local_storage(LS_TECH_EXPLORER_K, count)
}

export function save_default_classifier_builder_count_setting_to_ls(count) {
  save_to_local_storage(LS_CLASSIFIER_BUILDER_K, count)
}

export function get_default_count_setting() {
  return get_from_local_storage(LS_TECH_EXPLORER_K) || DEFAULT_COUNT_OPTION
}

export function get_default_classifier_builder_count_setting() {
  return get_from_local_storage(LS_CLASSIFIER_BUILDER_K) || DEFAULT_COUNT_OPTION
}
