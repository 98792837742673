
// report building, and report building task statuses
export const STATUS_QUEUED = 'queued'
export const STATUS_STARTED = 'started'
export const STATUS_COMPLETED = 'completed'
export const STATUS_FAILED = 'failed'

// additional statuses used for tasks only
export const STATUS_NOT_STARTED = 'not_started'
export const STATUS_WAITING = 'waiting' // not a 'real' choreo status; extra for progress page

// task descriptions (used to identify tasks in the progress response)
export const RUNNING_CLASSIFIERS_TASK_DESC = 'run-classifiers'
export const TEXT_SEARCH_TASK_DESC = 'Performing keyword search'
const PORTFOLIO_CLUSTERING_TASK_DESC = 'run-portfolio-clustering'
const CLUSTER_TO_CLUSTER_TASK_DESC = 'run-cluster-to-cluster'
const COMPARABLES_TASK_DESC = 'run-comparables'
const GROUP_BY_OWNER_TASK_DESC = 'run-group-by-owner'
const UTT_TASK_DESC = 'run-utt'
const DISPUTES_CLASSIFIERS_TASK_DESC = 'run-dispute-classifiers'

// human-readable progress task labels
export const FETCHING_TASK_GROUP_LABEL = 'Fetching data'
export const WRITING_TASK_GROUP_LABEL = 'Writing report'
const RUNNING_CLASSIFIERS_TASK_LABEL = 'Running classifiers'
const PORTFOLIO_CLUSTERING_TASK_LABEL = 'Clustering'
const CLUSTER_TO_CLUSTER_TASK_LABEL = 'Cluster mapping'
const COMPARABLES_TASK_LABEL = 'Finding similar organisations'
const GROUP_BY_OWNER_TASK_LABEL = 'Grouping families by owner'
const UTT_TASK_LABEL = 'Running UTT'
const DISPUTES_CLASSIFIERS_TASK_LABEL = 'Classifying dispute families'


export const TASK_TO_DISPLAY_PROPERTIES = {
  // TODO add text search task first if/when it exists
  [PORTFOLIO_CLUSTERING_TASK_DESC]: {
    label: PORTFOLIO_CLUSTERING_TASK_LABEL,
    order: 0
  },
  [COMPARABLES_TASK_DESC]: {
    label: COMPARABLES_TASK_LABEL,
    order: 1
  },
  [RUNNING_CLASSIFIERS_TASK_DESC]: {
    label: RUNNING_CLASSIFIERS_TASK_LABEL,
    order: 2
  },
  [DISPUTES_CLASSIFIERS_TASK_DESC]: {
    label: DISPUTES_CLASSIFIERS_TASK_LABEL,
    order: 3
  },
  [UTT_TASK_DESC]: {
    label: UTT_TASK_LABEL,
    order: 4
  },
  [CLUSTER_TO_CLUSTER_TASK_DESC]: {
    label: CLUSTER_TO_CLUSTER_TASK_LABEL,
    order: 5
  },
  [GROUP_BY_OWNER_TASK_DESC]: {
    label: GROUP_BY_OWNER_TASK_LABEL,
    order: 6
  },
}