import { SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF } from './spec_groups.js'
import { ID_TO_VIEW } from './views.js'

import { get_as_map } from '../utils/utils.js'
import { get_new_spec_id } from './spec_ids.js'
import {
  DATA_ORIENTATION,
  DEFENDANT_TYPE_IDS,
  DESELECTED_GEO_IDS,
  DESELECTED_PORTFOLIO_IDS,
  DESELECTED_TECH_IDS,
  SPOTLIGHTED_PORTFOLIOS,
  SPOTLIGHTED_TECHS,
  NEXT_AGGLOM_VISIBILITY,
  OUTCOME_IDS,
  PLAINTIFF_TYPE_IDS,
  ROLLUP_THRESHOLDS,
  SELECTED_DISPUTE_TYPE,
  SELECTED_REGION_GROUPING,
  SPEC_ID,
  START_DATE_RANGE,
  TIMERANGE,
  VIEW_ID,
  INCLUDE_ALL_ZEROS,
  HISTOGRAM_RANGE
} from '../constants/main_items_state_params.js'
import { ID_TO_SPEC } from './specs.js'

export const GENERIC_MAIN_ITEM_PROPERTIES = [SPEC_ID, VIEW_ID]

export function add_main_item_as_selected(main_items, spec_id) {
  const is_temp = false

  if (!main_items || main_items.length === 0) {
    return [{ spec_id, is_temp }]
  }

  const existing_item = get_main_item(main_items, spec_id)

  if (!existing_item) {
    return [ ...main_items, { spec_id, is_temp }]
  }

  //it's important to keep the order of adding (for chart sets, e.g.)
  const main_items_filtered = remove_main_item(main_items, spec_id)
  main_items_filtered.push({...existing_item, is_temp })
  return main_items_filtered
}

export function get_main_item(main_items, spec_id) {
  const id_to_main_items = get_as_map(main_items, SPEC_ID)
  return id_to_main_items[spec_id]
}

export function remove_main_item(main_items, spec_id) {
  return main_items.filter(item => {
    return item[SPEC_ID] !== spec_id
  })
}

function _with_main_item_key_value(main_items, spec_id, key, value) {
  return main_items.map(item => {
    if (item[SPEC_ID] === spec_id) {
      return {...item, [key]: value}
    }
    return item
  })
}

export function with_main_item_view_id(main_items, spec_id, view_id) {
  return _with_main_item_key_value(main_items, spec_id, VIEW_ID, view_id)
}

export function with_main_item_rollup_thresholds(main_items, spec_id, rollup_thresholds) {
  return _with_main_item_key_value(main_items, spec_id, ROLLUP_THRESHOLDS, rollup_thresholds)
}

export function with_main_item_next_agglom_item_visibility(main_items, spec_id, next_agglom_visibility) {
  return _with_main_item_key_value(main_items, spec_id, NEXT_AGGLOM_VISIBILITY, next_agglom_visibility)
}

export function with_main_item_data_orientation(main_items, spec_id, data_orientation) {
  return _with_main_item_key_value(main_items, spec_id, DATA_ORIENTATION, data_orientation)
}

export function with_main_item_data_should_include_all_zeros(main_items, spec_id, should_include_all_zeros) {
  return _with_main_item_key_value(main_items, spec_id, INCLUDE_ALL_ZEROS, should_include_all_zeros)
}

export function with_main_item_timerange(main_items, spec_id, timerange) {
  return _with_main_item_key_value(main_items, spec_id, TIMERANGE, timerange)
}

export function with_main_item_histogram_range(main_items, spec_id, histogram_range) {
  return _with_main_item_key_value(main_items, spec_id, HISTOGRAM_RANGE, histogram_range)
}

export function with_main_item_dispute_type(main_items, spec_id, selected_dispute_type) {
  return _with_main_item_key_value(main_items, spec_id, SELECTED_DISPUTE_TYPE, selected_dispute_type)
}

export function with_main_item_region_grouping(main_items, spec_id, selected_region_grouping) {
  return _with_main_item_key_value(main_items, spec_id, SELECTED_REGION_GROUPING, selected_region_grouping)
}

export function with_main_item_deselected_portfolio_ids(main_items, spec_id, deselected_portfolio_ids) {
  return _with_main_item_key_value(main_items, spec_id, DESELECTED_PORTFOLIO_IDS, deselected_portfolio_ids)
}

export function with_main_item_deselected_tech_ids(main_items, spec_id, deselected_tech_ids) {
  return _with_main_item_key_value(main_items, spec_id, DESELECTED_TECH_IDS, deselected_tech_ids)
}

export function with_main_item_deselected_geo_ids(main_items, spec_id, deselected_geo_ids) {
  return _with_main_item_key_value(main_items, spec_id, DESELECTED_GEO_IDS, deselected_geo_ids)
}

export function reset_deselected_items(main_items, spec_id, key_name) {
  return _with_main_item_key_value(main_items, spec_id, key_name, [])
}

export function with_main_item_plaintiff_type_ids(main_items, spec_id, plaintiff_type_ids) {
  return _with_main_item_key_value(main_items, spec_id, PLAINTIFF_TYPE_IDS, plaintiff_type_ids)
}

export function with_main_item_defendant_type_ids(main_items, spec_id, defendant_type_ids) {
  return _with_main_item_key_value(main_items, spec_id, DEFENDANT_TYPE_IDS, defendant_type_ids)
}

export function with_main_item_outcome_ids(main_items, spec_id, outcome_ids) {
  return _with_main_item_key_value(main_items, spec_id, OUTCOME_IDS, outcome_ids)
}

export function with_main_item_spotlighted_portfolios(main_items, spec_id, spotlighted_portfolios) {
  return _with_main_item_key_value(main_items, spec_id, SPOTLIGHTED_PORTFOLIOS, spotlighted_portfolios)
}

export function with_main_item_spotlighted_techs(main_items, spec_id, spotlighted_techs) {
  return _with_main_item_key_value(main_items, spec_id, SPOTLIGHTED_TECHS, spotlighted_techs)
}

export function with_main_item_start_date_range(main_items, spec_id, start_date_range) {
  return _with_main_item_key_value(main_items, spec_id, START_DATE_RANGE, start_date_range)
}

/**
 * Try to get spec, group and view objects from main_item.
 */
export function get_spec_group_and_view(main_item) {
  const { spec_id } = main_item

  const new_spec_id = get_new_spec_id(spec_id)

  const spec = {...ID_TO_SPEC[new_spec_id], ...SPEC_ID_TO_SPEC_REF[new_spec_id], }
  if (!spec) {
    return {}
  }

  const {default_view_id, available_view_ids = []} = spec

  const group = SPEC_ID_TO_GROUP[new_spec_id] // may be null

  const { view_id = default_view_id } = main_item
  const main_item_view_id = (available_view_ids.indexOf(view_id) > -1) ? view_id : default_view_id //it can happen that selected view disappears from the available views list
  const view = ID_TO_VIEW[main_item_view_id] // may be null

  return { spec, group, view }
}
