import { REPORT_REGION_GROUPING_OPTIONS } from '../constants/regions.js'

export function get_report_region_grouping_by_id(region_grouping_option_id) {
  if (!region_grouping_option_id) {
    return get_default_report_region_grouping()
  }
  return REPORT_REGION_GROUPING_OPTIONS.filter(region => region.id === region_grouping_option_id)[0] || REPORT_REGION_GROUPING_OPTIONS.filter(region => region.default)[0]
}

export function get_default_report_region_grouping() {
  return REPORT_REGION_GROUPING_OPTIONS.filter(region => region.default)[0]
}

export function get_report_region_column(region_grouping_option_id) {
  return get_report_region_grouping_by_id(region_grouping_option_id).column
}
