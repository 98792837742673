import { get_as_map } from '../../../utils/utils.js'

// For now, frontend only deals with read/edit, but we keep the other levels here just in case...
export const INVALID = 'PERMISSION_INVALID'
export const READ    = 'PERMISSION_READ'
export const EDIT    = 'PERMISSION_EDIT'
//export const RUN     = 'PERMISSION_RUN' // Currently not used ("read" is used instead)
export const OWNER   = 'PERMISSION_OWNER'

export const PERMISSION_READ  = { id: READ,  name: 'Viewer', access: 'Can run' }
export const PERMISSION_EDIT  = { id: EDIT,  name: 'Editor', access: 'Can edit' }
export const PERMISSION_OWNER = { id: OWNER, name: 'Owner',  access: 'Owner' }

export const PERMISSION_LEVELS = [
  PERMISSION_EDIT,
  PERMISSION_READ,
  PERMISSION_OWNER
]

export const ID_TO_PERMISSION = get_as_map(PERMISSION_LEVELS, 'id')
