import React from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { MONITOR, REPORT, REPORT_READER_QUERY } from '../../constants/paths.js'
import {RouteWithTracing} from '../RouteWithTracing'
import MonitorReport from './MonitorReport.js'
import MonitorReportReaderQuery from './MonitorReportReaderQuery.js'

const MonitorRoutes = () => {
 return (
   <Switch>
     <RouteWithTracing exact path={MONITOR} component={() => (<Redirect to={`${MONITOR}${REPORT}`}/>)} />
     <RouteWithTracing path={`${MONITOR}${REPORT}`} component={MonitorReport} />
     <RouteWithTracing path={`${MONITOR}${REPORT_READER_QUERY}`} component={MonitorReportReaderQuery} />
   </Switch>
 )
}

export default MonitorRoutes