import _ from 'underscore'

import { get_object_values } from '../../../utils/utils.js'

//TODO: this file is deprecated (extracted from cipher2) and will probably be removed once that regions are mapped and returned by the backend

export const REGIONS_MAP =
    {'EUROPE':['AD', 'AL', 'AM', 'AT', 'BA', 'BE', 'BG', 'BX', 'BY', 'CH', 'CS', 'CZ', 'DD', 'DE', 'DK', 'EE', 'EF', 'EM', 'EP', 'ES', 'FI', 'FR', 'FX', 'GB', 'GE', 'GI', 'GL', 'GR', 'HR', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MC', 'MD', 'ME', 'MK', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'RS', 'SE', 'SI', 'SK', 'SM', 'TR', 'UA', 'VA', 'YU'],
    'USA':['US'],
    'JAPAN':['JP']
    }

export const NON_ROW_REGION = _.flatten(get_object_values(REGIONS_MAP))

