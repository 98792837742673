export const BASE_LAYOUT_PROPS = {
  showlegend: false,
  autosize: true,
  paper_bgcolor: 'rgba(0,0,0,0)', // transparent background
  plot_bgcolor: 'rgba(0,0,0,0)'
}

export const BASE_OTHER_PROPS = {
  useResizeHandler: true,
  style: {width: '100%', height: '100%'},
  config: { displayModeBar: false }
}

export const SCATTER_CHART_TYPE = 'scatter'
export const BUBBLE_CHART_TYPE = 'bubble'
export const BAR_CHART_TYPE = 'bar'

export const BARMODE_STACK = 'stack'
export const BARMODE_CLUSTER = ''

export const BAR_ORIENTATION_HORIZONTAL = 'h'

export const LINEMODE_AREA = 'area'
export const LINEMODE_LINE = 'line'

export const NO_AXIS_PROPS = {
  showgrid: false,
  showline: false,
  zeroline: false,
  showticklabels: false,
  fixedrange: true
}

export const THUMBNAIL_MARGIN = {
  t: 10,
  r: 10,
  b: 30,
  l: 10
}

export const NORMAL_MARGIN = {
  // Needs more space for the axis ticks/labels
  t: 10,
  r: 30,
  b: 80,
  l: 60
}

export const BUBBLE_CHART_NORMAL_MARGIN = {
  ...NORMAL_MARGIN,
  r: 300
}
