export const DECK_PAGE_SUBPATH = 'p'
export const DECK_CHART_SUBPATH = 'c'
export const LANDING_PAGE_ID = 'intro'

export const IS_TARGET_ORG_TAG = 'is_target_org'

export const ORG_LISTS_KEY_DATA_PARAM_NAME = 'org_lists'
export const TECH_AREAS_KEY_DATA_PARAM_NAME = 'tech_areas'

export const EXPORT_MODE_CURRENT_PAGE = 'current_page'
export const EXPORT_MODE_ALL_PAGES = 'all_pages'