import _ from 'underscore'
import { get_as_map } from '../../../utils/utils.js'

// The Suggestions api has five endpoints:
// - an "automatic" endpoint (i.e. "/")
// - 4 strategy GROUP endpoints (i.e. "/similar-cpcs", "similar-text" etc...)
//
// Each endpoint can return various STRATEGIES.
// For example the "similar-cpcs" endpoint can return a mixture of
// cpc strtagies (i.e. "cpc-set-in-pos-and-not-negs", "cpc-set-in-lowest-scoring-pos" etc....)

export const AUTO_STRATEGY_GROUP_ID     = 'auto-strategy'
export const SIMILAR_CITATIONS_GROUP_ID = 'similar-citations'
export const SIMILAR_CPCS_GROUP_ID      = 'similar-cpcs'
export const SIMILAR_TEXT_GROUP_ID      = 'similar-text'
export const CLASSIFIER_TEXT_GROUP_ID   = 'classifier-text'

export const SUGGESTION_STATEGY_GROUPS = [
  { id: AUTO_STRATEGY_GROUP_ID,       name: 'Automatic',                      endpoint: '',                        description: 'A mix of all available suggestion strategies' },
  { id: CLASSIFIER_TEXT_GROUP_ID,     name: 'Similar text to Scope',          endpoint: '/classifier-text',        description: 'Suggests families with text that is similar to the Title or Scope' },
  { id: SIMILAR_TEXT_GROUP_ID,        name: 'Similar text to positives',      endpoint: '/similar-text',           description: 'Suggests families with text that is similar to the positive Training Set examples' },
  { id: SIMILAR_CITATIONS_GROUP_ID,   name: 'Similar citations to positives', endpoint: '/similar-citations',      description: 'Suggests families that have similar citations to the Training Set positives' },
  { id: SIMILAR_CPCS_GROUP_ID,        name: 'Similar CPCs',                   endpoint: '/similar-cpcs',           description: 'Takes sets of CPCs that occur frequently in the Training Set, and suggests families similar to these.' },
]

export const SUGGESTIONS_STRATEGY_GROUPS_NO_CLASSIFIER_BUILT = SUGGESTION_STATEGY_GROUPS.filter(strategy => !_.contains([SIMILAR_CPCS_GROUP_ID], strategy.id))

export const SIMILAR_TEXT_TO_TECH_TITLE_OR_DESCRIPTION = 'similar-text-to-tech-title-or-description'
export const SIMILAR_TEXT_TO_POSITIVES                 = 'similar-text-to-positives'
export const CONTAINS_KEY_PHRASE_IN_POSITIVES          = 'contains-key-phrase-in-positives'
export const SIMILAR_CITATIONS                         = 'similar-citations'
export const CPC_SET_IN_POS_AND_NEGS                   = 'cpc-set-in-pos-and-negs'
export const CPC_SET_IN_POS_AND_NOT_NEGS               = 'cpc-set-in-pos-and-not-negs'
export const CPC_SET_IN_LOWEST_SCORING_POS             = 'cpc-set-in-lowest-scoring-pos'
export const CPC_SET_IN_HIGHEST_SCORING_NEG            = 'cpc-set-in-highest-scoring-neg'

// use the same name and description for both 'similar text' strategies (the difference between them isn't meaningful for the user)
const SIMILAR_TEXT_TO_POSITIVES_NAME = 'Similar text to positives'
const SIMILAR_TEXT_TO_POSITIVES_DESC = 'Suggests families with text that is similar to the positive Training Set examples'

export const SUGGESTIONS_STRATEGIES = [
  { id: SIMILAR_TEXT_TO_TECH_TITLE_OR_DESCRIPTION, name: 'Similar text to Scope',                           description: 'Suggests families with text that is similar to the Title or Description' },
  { id: SIMILAR_TEXT_TO_POSITIVES,                 name: SIMILAR_TEXT_TO_POSITIVES_NAME,                    description: SIMILAR_TEXT_TO_POSITIVES_DESC },
  { id: CONTAINS_KEY_PHRASE_IN_POSITIVES,          name: SIMILAR_TEXT_TO_POSITIVES_NAME,                    description: SIMILAR_TEXT_TO_POSITIVES_DESC },
  { id: SIMILAR_CITATIONS,                         name: 'Similar citations',                               description: 'Suggests families that have similar citations to the Training Set families' },
  { id: CPC_SET_IN_POS_AND_NOT_NEGS,               name: 'Similar CPC sets to positives',                   description: 'Takes sets of CPCs that occur frequently in only the positive Training Set families, and suggests families that share these CPCs.' },
  { id: CPC_SET_IN_POS_AND_NEGS,                   name: 'Similar CPC sets to positives and negatives',     description: 'Takes sets of CPCs that occur frequently in both positive and negative Training Set families, and suggests families that share these CPCs.' },
  { id: CPC_SET_IN_LOWEST_SCORING_POS,             name: 'Similar CPC sets to lowest scoring positive',     description: 'Takes sets of CPCs that occur frequently in the lowest scoring positive Training Set family, and suggests families that share these CPCs.' },
  { id: CPC_SET_IN_HIGHEST_SCORING_NEG,            name: 'Similar CPC sets to highest scoring negative',    description: 'Takes sets of CPCs that occur frequently in the highest scoring negative Training Set family, and suggests families that share these CPCs.' },
]

export const CPC_STRATEGIES = [ CPC_SET_IN_POS_AND_NEGS, CPC_SET_IN_POS_AND_NOT_NEGS, CPC_SET_IN_LOWEST_SCORING_POS, CPC_SET_IN_HIGHEST_SCORING_NEG ]

export const ID_TO_SUGGESTIONS_STRATEGY = get_as_map(SUGGESTIONS_STRATEGIES, 'id')

export const ID_TO_SUGGESTION_STRATEGY_GROUP = get_as_map(SUGGESTION_STATEGY_GROUPS, 'id')