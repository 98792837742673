import React from 'react'
import axios from 'axios'

import { is_aistemos } from '../utils/user_permissions.js'

import { SERVER_API_ERROR_TEST_URL } from '../constants/urls.js'

import ErrorBody from './ErrorBody.js'
import ContainerFullWidth from './ContainerFullWidth.js'
import { withUser } from './UserContext.js'


class ErrorTest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.async_error = this.async_error.bind(this)
    this.sync_error = this.sync_error.bind(this)
    this.network_error = this.network_error.bind(this)
    this.server_api_error = this.server_api_error.bind(this)
  }

  async_error() {
    setTimeout(() => {
      const err = new Error('arghghg async error')
      this.setState({ err })
      throw err
    }, 1000)
  }

  sync_error() {
    const err = new Error('arghghg sync error')
    this.setState({ err })
    throw err
  }

  network_error() {
    axios.get('https://aistemossss.com') // wrong url triggers a "network error" (no response)
      .catch(err => {
        this.setState({ err })
        throw err
      })
  }

  server_api_error() {
    axios.get(SERVER_API_ERROR_TEST_URL)
      .catch(err => {
        // This should happen! i.e. server should respond with a 500
        this.setState({ err })
        throw err
      })
      .then(() => {
        // should never get called
        const err = new Error('This should not happen. We were expecting the server to respond with a 500 error.')
        this.setState({ err })
        throw err
      })
  }

  render() {
    if (!is_aistemos(this.props.user)) {
      return (
        <ContainerFullWidth>
          You do not have permission to view this page.
        </ContainerFullWidth>
      )
    }

    if (this.state.render_error) {
      throw new Error('arghghg render error')
    }

    return (
      <div className='m-3'>
        <div className='mb-3'>
          <button onClick={() => this.setState({ render_error: true })}>render error</button>
        </div>

        <div className='mb-3'>
          <button onClick={this.sync_error}>(non-render) sync error</button>
        </div>

        <div className='mb-3'>
          <button onClick={this.async_error}>(non-render) async error</button>
        </div>

        <div className='mb-3'>
          <button onClick={this.network_error}>network error</button>
        </div>

        <div className='mb-3'>
          <button onClick={this.server_api_error}>server api error</button>
        </div>

        {this.state.err &&
          <ErrorBody
              error={this.state.err}
          />
        }
      </div>
    )
  }
}

export default withUser(ErrorTest)