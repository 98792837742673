import React, { useEffect, useState } from 'react'

import { get_as_map } from '../../utils/utils.js'
import {
  CIPHER_FAMILY_ID_FIELD_ID,
  PAT_FAM_ID_FIELD_ID,
} from '../../model/patent_family_fields.js'
import Spinner from '../widgets/Spinner.js'
import FamiliesTable from './FamiliesTable.js'
import { INPUT_COLUMN_IDS } from './utils/families_table.js'
import {
  get_patent_families_by_ids,
  get_patent_link_option_id_preference,
  get_sorted_families, update_patent_link_option_id_preference
} from '../../utils/patent_family_list_utils.js'
import { ASCENDING } from '../../model/sort_directions.js'
import FamilyViewModal from '../family_view/FamilyViewModal.js'
import { PATENT_LINK_OPTION_DEFAULT_ID } from '../../model/patent_links.js'
import { track_knn_events } from '../../utils/tracking_utils.js'
import { CONTEXT, mask_knn_id } from '../../utils/knn_search.js'

import s from './InputFamiliesDisplay.module.scss'

const InputFamiliesDisplay = (
  {
    current_id,

    families_list,
    family_ids,
    blocklist,
    bookmarked,

    on_add_to_family_ids,
    on_add_to_blocklist,
    on_add_to_bookmarked,

    className
  }) => {

  const patent_link_option_id_preference = get_patent_link_option_id_preference()

  const [should_fetch, set_should_fetch] = useState(false)
  const [is_fetching, set_is_fetching] = useState(false)
  const [input_family_ids, set_input_family_ids] = useState(null)
  const [input_families, set_input_families] = useState(null)
  const [sort_field_id, set_sort_field_id] = useState(CIPHER_FAMILY_ID_FIELD_ID)
  const [sort_direction_id, set_sort_direction_id] = useState(ASCENDING)

  const [family_idx_to_display, set_family_idx_to_display] = useState(null)
  const [patent_link_option_id, set_patent_link_mode_id] = useState(patent_link_option_id_preference || PATENT_LINK_OPTION_DEFAULT_ID)
  const [is_family_tagging_mode_on, set_is_family_tagging_mode_on] = useState(false)
  const [family_tagging_search_phrase, set_family_tagging_search_phrase] = useState(null)

  const pat_fam_id_to_family = get_as_map(families_list || [], PAT_FAM_ID_FIELD_ID)
  const pat_fam_id_to_input_family = get_as_map(input_families || [], PAT_FAM_ID_FIELD_ID)

  useEffect(() => {
    const input_family_ids = [...family_ids || [], ...blocklist || [], ...bookmarked || []]
    set_input_family_ids(input_family_ids)

    const families = input_family_ids.map(pat_fam_id => (pat_fam_id_to_input_family[pat_fam_id] || pat_fam_id_to_family[pat_fam_id] || null)).filter(item => item != null)

    if (families.length !== input_family_ids.length) {
      set_should_fetch(true)
    } else {
      set_input_families(get_sorted_families(families, sort_field_id, sort_direction_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [family_ids, blocklist, bookmarked, sort_field_id, sort_direction_id])

  useEffect(() => {
    if (!should_fetch) return

    set_is_fetching(true)

    get_patent_families_by_ids(input_family_ids, null, input_family_ids.length, 0, CIPHER_FAMILY_ID_FIELD_ID, ASCENDING)
      .then((families_data_search_results) => {
        const { searchResults } = families_data_search_results

        const families = get_sorted_families(searchResults, sort_field_id, sort_direction_id)

        set_input_families(families)
        set_is_fetching(false)
      })
    set_should_fetch(false)
  }, [should_fetch, input_family_ids, sort_field_id, sort_direction_id])

  function on_change_sort_field_id_and_sort_direction_id(sort_field_id, sort_direction_id) {
    set_sort_field_id(sort_field_id)
    set_sort_direction_id(sort_direction_id)
  }

  function on_change_patent_link_option_id(patent_link_option_id) {
    set_patent_link_mode_id(patent_link_option_id)
    update_patent_link_option_id_preference(patent_link_option_id)
  }

  function on_toggle_family_tagging_mode() {
    set_is_family_tagging_mode_on(!is_family_tagging_mode_on)
  }

  function on_update_family_tagging_search_phrase(text) {
    set_family_tagging_search_phrase(text)
  }

  function on_show_details(idx) {
    track_knn_events(`context="${CONTEXT}" action="show_details_view" id="${mask_knn_id(current_id)}"`)
    set_family_idx_to_display(idx)
  }

  function on_hide_details() {
    set_family_idx_to_display(null)
  }

  const family_to_display = family_idx_to_display != null ? input_families[family_idx_to_display] : null

  return (
    <div className={className}>
      <div className='mt-2'>
        {is_fetching &&
          <Spinner />
        }
      </div>

      {!is_fetching && input_families &&

        <FamiliesTable
          column_ids={INPUT_COLUMN_IDS}
          family_ids={family_ids}
          blocklist={blocklist}
          bookmarked={bookmarked}
          families={input_families}

          on_add_to_family_ids={(family_id) => on_add_to_family_ids({family_id, component: 'input_selection'})}
          on_add_to_blocklist={(family_id) => on_add_to_blocklist({family_id, component: 'input_selection'})}
          on_add_to_bookmarked={(family_id) => on_add_to_bookmarked({family_id, component: 'input_selection'})}

          selected_sort_field_id={sort_field_id}
          selected_sort_direction_id={sort_direction_id}
          on_change_sort_field_id_and_sort_direction_id={on_change_sort_field_id_and_sort_direction_id}

          on_show_details={on_show_details}

          className={s.families_table}
        />
      }


      {family_to_display &&
        <FamilyViewModal
          on_hide={on_hide_details}

          patfam={family_to_display}
          on_prev={() => {}}
          on_next={() => {}}
          idx={family_idx_to_display}
          total_num_families={input_families.length}

          is_fetching={is_fetching}
          on_retry={ () => {}}

          patent_link_option_id={patent_link_option_id}
          on_change_patent_link_option_id={on_change_patent_link_option_id}

          //family tagging
          is_family_tagging_mode_on={is_family_tagging_mode_on}
          family_tagging_search_phrase={family_tagging_search_phrase || ''}
          on_toggle_family_tagging_mode={on_toggle_family_tagging_mode}
          on_update_family_tagging_search_phrase={on_update_family_tagging_search_phrase}
          //end of family tagging

          knn_family_ids={family_ids}
          knn_blocklist={blocklist}
          knn_bookmarked={bookmarked}
          on_add_to_knn_family_ids={(family_id) => on_add_to_family_ids({family_id, component: 'input_selection_details_view'})}
          on_add_to_knn_blocklist={(family_id) => on_add_to_blocklist({family_id, component: 'input_selection_details_view'})}
          on_add_to_knn_bookmarked={(family_id) => on_add_to_bookmarked({family_id, component: 'input_selection_details_view'})}

          hide_paging_controls={true}
        />
      }

    </div>
  )
}

export default InputFamiliesDisplay