export const ORG_COLUMN_NAME  = 'Organisation'
export const TECH_COLUMN_NAME = 'Technology'
export const GEO_COLUMN_NAME  = 'Territory'

export const REGION_COLUMN_NAME = 'Region'

export const CPC_CODE_COLUMN_NAME = 'CPC code'
export const FIRST_FILING_COUNTRY_COLUMN_NAME = 'First filing territory'
export const PATENTS_COLUMN_NAME = 'Patents'
export const STATUS_COLUMN_NAME = 'Status'
export const ACTIVE_GRANTS_COLUMN_NAME = 'Active grants'

export const PATENT_FAMILIES_COLUMN_NAME         = 'Patent families'
export const ACTIVE_PATENT_FAMILIES_COLUMN_NAME  = 'Active patent families'
export const GRANTED_PATENT_FAMILIES_COLUMN_NAME = 'Granted patent families'

export const YEAR_COLUMN_NAME = 'Year'
export const GRANT_YEAR_COLUMN_NAME = 'Grant year'
export const PUBLICATION_YEAR_COLUMN_NAME = 'Publication year'
export const PRIORITY_YEAR_COLUMN_NAME = 'Priority year'
export const EXPIRY_YEAR_COLUMN_NAME = 'Expiry year'
