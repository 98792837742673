import React from 'react'
import cn from 'classnames'

import { CheckboxAndLabel } from '../widgets/CheckboxAndLabel.js'
import { HEATMAP_ID } from '../../model/view_ids.js'
import { is_data_transposed, get_available_data_orientation_options } from '../../utils/report_utils.js'
import { get_data_orientation } from '../../utils/main_items_utils.js'

const DataOrientationControls = ({item, selected_view_id, on_change_data_orientation, className}) => {
  if (selected_view_id !== HEATMAP_ID) {
    return null
  }

  const selected_data_orientation = get_data_orientation(item)
  const available_data_orientation = get_available_data_orientation_options(selected_data_orientation)
  return (
    <div className={cn(className)}>
      <CheckboxAndLabel
        label='Transposed'
        on_click={() => on_change_data_orientation(available_data_orientation.id)}
        is_checked={is_data_transposed(selected_data_orientation)}
      />
    </div>
  )
}

export default DataOrientationControls