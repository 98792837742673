import React from 'react'
import cn from 'classnames'

import { DISPUTES_TABLE_ID, EXTENDED_TABLE_ID, TABLE_ID } from '../../model/view_ids.js'
import { VIEW_ID_TO_VIEW_ICON } from '../../model/views.js'

const ChartIcon = ({view_id, view_icon, dimension='4', className}) => {

  function get_clean_view(view_id) {
    if ([DISPUTES_TABLE_ID, EXTENDED_TABLE_ID].indexOf(view_id) > -1) {
      return VIEW_ID_TO_VIEW_ICON[TABLE_ID]
    }

    return VIEW_ID_TO_VIEW_ICON[view_id]
  }

  const custom_style = {width: dimension+'rem', height: dimension+'rem'}

  const { icon } = get_clean_view(view_id) || {}

  const ViewIcon = view_icon || icon

  return (
    <div className={cn(className)}>
      <ViewIcon
        custom_style={custom_style}
      />
    </div>
  )
}

export default ChartIcon