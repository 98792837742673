import React from 'react'

import ReportBuilder from './ReportBuilder.js'

const ValuationReportBuilder = () => {
  return (
    <ReportBuilder
      is_valuation_report={true}
    />
  )
}

export default ValuationReportBuilder