/**
 * This file contains hooks for state/update logic relating to core
 * Classifier-related stuff
 * i.e. "Scope modal" (name, description, phrases_to_highlight etc...)
 * and "set_label()".
 *
 * Currently it is used in Cipher reports (ViewerContainer.js).
 *
 * Ideally it would also be used by the Classifier UI (ClassifierContainer.js),
 * but currently (July 2022) that is still a class-based component,
 * and so can not use hooks.
 */

import { useState, useCallback } from 'react'
import _ from 'underscore'

import { track_classifiers_event } from '../../../utils/tracking_utils.js'
import { add_or_remove_patent_from_training_set, save_description, save_name, save_notes, save_no_highlighting, save_phrases_to_highlight } from '../../../utils/training_set_grpc_utils.js'
import { is_empty_or_null, limit_length } from '../../../utils/utils.js'
import { get_state_on_error_setting_remote_label, get_state_on_set_label_local, get_state_on_success_setting_remote_label } from '../utils/training_set_utils.js'

import { CLASSIFIER_DEBOUNCE_PERIOD, DESCRIPTION_MAX_LENGTH, HIGHLIGHT_PHRASE_MAX_LENGTH, NAME_MAX_LENGTH, NOTES_MAX_LENGTH } from '../constants/constants.js'


export function useClassifierScopeState() {

  const [latest_built_classifier_version, set_latest_built_classifier_version] = useState(null)
  const [training_set_info,    set_training_set_info]    = useState(null)
  const [phrases_to_highlight, set_phrases_to_highlight] = useState(null)
  const [ts_user_settings,     set_ts_user_settings]     = useState(null)

  const [ts_state, set_ts_state] = useState({})

  const [error_updating_scope, set_error_updating_scope] = useState(null)

  function get_classifier_id() {
    return training_set_info.alias
  }

  // =================================================
  // Remote functions
  // =================================================

  function remote_update_phrases_to_highlight(eval_training_set_id, phrases_to_highlight) {
    save_phrases_to_highlight(eval_training_set_id, phrases_to_highlight)
      .catch(err => {
        set_error_updating_scope(err)
      })
  }

  function remote_update_classifier_title(eval_training_set_id, title) {
    const is_empty = is_empty_or_null(title)
    if (is_empty) {
      // API does not accept empty strings or null
      return
    }

    save_name(eval_training_set_id, title)
      .catch(err => {
        set_error_updating_scope(err)
      })
  }

  function remote_update_classifier_notes(eval_training_set_id, notes) {
    save_notes(eval_training_set_id, notes)
      .catch(err => {
        set_error_updating_scope(err)
      })
  }

  function remote_update_classifier_description(eval_training_set_id, description) {
    save_description(eval_training_set_id, description)
      .catch(err => {
        set_error_updating_scope(err)
      })
  }

  function remote_update_no_highlighting(eval_training_set_id, no_highlighting) {
    save_no_highlighting(eval_training_set_id, no_highlighting)
      .catch(err => {
        set_error_updating_scope(err)
      })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const remote_update_phrases_to_highlight_debounced   = useCallback(_.debounce(remote_update_phrases_to_highlight,   CLASSIFIER_DEBOUNCE_PERIOD), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const remote_update_classifier_title_debounced       = useCallback(_.debounce(remote_update_classifier_title,       CLASSIFIER_DEBOUNCE_PERIOD), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const remote_update_classifier_notes_debounced       = useCallback(_.debounce(remote_update_classifier_notes,       CLASSIFIER_DEBOUNCE_PERIOD), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const remote_update_classifier_description_debounced = useCallback(_.debounce(remote_update_classifier_description, CLASSIFIER_DEBOUNCE_PERIOD), [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const remote_update_no_highlighting_debounced        = useCallback(_.debounce(remote_update_no_highlighting,        CLASSIFIER_DEBOUNCE_PERIOD), [])

  // =================================================
  // Main handlers
  // =================================================

  function on_change_phrases_to_highlight(phrases_to_highlight) {
    const classifier_id = get_classifier_id()
    const phrases_to_highlight_clean = phrases_to_highlight.map(({ phrase, ...rest }) => {
      return {
        phrase: limit_length(phrase, HIGHLIGHT_PHRASE_MAX_LENGTH),
        ...rest
      }
    })
    set_phrases_to_highlight(phrases_to_highlight_clean)                                     // local
    remote_update_phrases_to_highlight_debounced(classifier_id, phrases_to_highlight_clean)  // remote
  }

  function on_change_classifier_title(title) {
    const classifier_id = get_classifier_id()
    const title_clean = limit_length(title, NAME_MAX_LENGTH)
    const new_training_set_info = { ...training_set_info, name: title_clean }
    set_training_set_info(new_training_set_info)                         // local
    remote_update_classifier_title_debounced(classifier_id, title_clean) // remote
  }

  function on_change_classifier_notes(notes) {
    const classifier_id = get_classifier_id()
    const notes_clean = limit_length(notes, NOTES_MAX_LENGTH)
    const new_training_set_info = { ...training_set_info, notes: notes_clean }
    set_training_set_info(new_training_set_info)                               // local
    remote_update_classifier_notes_debounced(classifier_id, notes_clean)       // remote
  }

  function on_change_classifier_description(description) {
    const classifier_id = get_classifier_id()
    const description_clean = limit_length(description, DESCRIPTION_MAX_LENGTH)
    const new_training_set_info = { ...training_set_info, description: description_clean }
    set_training_set_info(new_training_set_info)                                      // local
    remote_update_classifier_description_debounced(classifier_id, description_clean)  // remote
  }

  function on_change_no_highlighting(no_highlighting) {
    const classifier_id = get_classifier_id()
    const new_ts_user_settings = { ...ts_user_settings, no_highlighting }
    set_ts_user_settings(new_ts_user_settings)                               // local
    remote_update_no_highlighting_debounced(classifier_id, no_highlighting)  // remote
  }

  function set_label(source, patfam, label) {
    const id = patfam.id || patfam.patFamId
    const patent = { ...patfam, id } // workaround to ensure we have an 'id' property

    set_ts_state(current_ts_state => {
      if (current_ts_state.id_to_tsfam_pending[id]) { // use latest data!
        // Patent is currently being saved, so do nothing
        return current_ts_state
      }

      if (patfam.user_class === label) {
        // Patent is already set, so do nothing
        return current_ts_state
      }

      track_classifiers_event('obj="evaluation_report" action="evaluation_report_set_label"')

      // ----------- Remote (Asynchronous) ---------------
      const classifier_id = get_classifier_id()
      add_or_remove_patent_from_training_set(classifier_id, id, label)
      .catch(err => {
        // FAIL
        set_ts_state(current_ts_state => {
          const { id_to_tsfam, id_to_tsfam_pending, id_to_tsfam_error } = current_ts_state // use latest data!
          const new_eval_state = get_state_on_error_setting_remote_label(err, patent, id_to_tsfam, id_to_tsfam_pending, id_to_tsfam_error)
          return { ...current_ts_state, ...new_eval_state }
        })
        throw err
      })
      .then(() => {
        // SUCCESS
        set_ts_state(current_ts_state => {
          const { id_to_tsfam, id_to_tsfam_pending, training_set_patfams } = current_ts_state // use latest data!
          const new_eval_state = get_state_on_success_setting_remote_label(patent, training_set_patfams, id_to_tsfam, id_to_tsfam_pending)
          return { ...current_ts_state, ...new_eval_state }
        })
      })
      // --------------------------------------------------

      // Local (Synchronous)
      const new_eval_state = get_state_on_set_label_local(patent, label, source, current_ts_state.id_to_tsfam, current_ts_state.id_to_tsfam_pending, current_ts_state.id_to_tsfam_error) // use latest data!
      return { ...current_ts_state, ...new_eval_state }
    })

  }

  // ==================================================
  // Exports
  // ==================================================

  return {
    latest_built_classifier_version,
    training_set_info,
    phrases_to_highlight,
    ts_user_settings,

    set_latest_built_classifier_version,
    set_training_set_info,
    set_phrases_to_highlight,
    set_ts_user_settings,

    ts_state,
    set_ts_state,

    on_change_phrases_to_highlight,
    on_change_classifier_title,
    on_change_classifier_notes,
    on_change_classifier_description,
    on_change_no_highlighting,

    set_label,

    error_updating_scope,
    set_error_updating_scope,
  }
}
