import {
  extract_litigations,
  get_items_filtered_by_company_and_years,
  group_as_nested_array,
  GROUPINGS
} from './executive_summary_utils.js'
import * as d3 from 'd3'
import { get_geography_portfolio_data } from './executive_summary_columns_utils.js'

export const TITLE_HEIGHT = 35
export const PIPE_DIAMETER = 52
export const PIPE_TITLE_MARGIN = 5
export const LABELLED_PIPE_HEIGHT = 78
export const CIPHER_BALL_OFFSET_X = 90
export const CIPHER_BALL_OFFSET_Y = 18
export const RIGHT_COLUMN_OFFSET = 296
export const CONNECTOR_DX_1 = 45
export const CONNECTOR_DX_2 = 45
export const CONNECTOR_DY = 80
export const STROKE_WIDTH = '1.5'

export function get_company_ids(companies) {
  return companies.map(function(d) { return d.id })
}

export function get_size_level(items, selected_companies, year) {
  // filter for (selected_year, selected companies)
  const company_ids = get_company_ids(selected_companies)
  const portfolio_size_items = get_items_filtered_by_company_and_years(items, company_ids, ['' + year])

  // Group per company
  const size_per_company = group_as_nested_array(portfolio_size_items, [GROUPINGS.byCompany], [selected_companies], 0 /* fill gaps with 0 */)

  const max_size    = d3.max(size_per_company)
  const target_size = size_per_company[0] // assumes target is first company

  return max_size === 0 ? 0 : target_size / max_size
}

export function get_geography_level(granted_regions, selected_companies) {
  let geographical_data_per_portfolio = selected_companies.map(company => get_geography_portfolio_data(granted_regions, company)) //array of ojb
  geographical_data_per_portfolio = geographical_data_per_portfolio.map(obj => obj.values)
  const std_per_company = geographical_data_per_portfolio.map(geography_data => d3.deviation(geography_data))

  const max = d3.max(std_per_company)

  const denominator = max
  return denominator > 0? std_per_company[0]/denominator : 0
}

export function get_litigation_level(litigations) {
  // Get level
  const max_value    = d3.max(litigations)
  const target_value = litigations[0] // assumes target is first company
  return max_value === 0 ? 0 : target_value / max_value
}

export function get_mean_spend_level(average_cost_by_portfolio) {
  const mean_spend_per_company = average_cost_by_portfolio.map(function(d) { return d.value })

  // Get level
  const max_value    = d3.max(mean_spend_per_company)
  const target_value = mean_spend_per_company[0] // assumes target is first company
  return !max_value || !target_value ? null : target_value / max_value
}

export function get_total_litigations_per_company(litigations, selected_companies, selected_years) {
  const litigations_per_company = selected_companies.map(company => extract_litigations(litigations, [company], selected_years))
  return litigations_per_company.map(company_litigations => d3.sum(company_litigations))
}