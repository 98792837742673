import _ from 'underscore'

import { DATA_ORIENTATION_NATIVE } from './report_utils.js'
import {CHOROPLETH_MAP_ID} from '../model/view_ids.js'
import { add_alpha, remove_alpha } from './color_utils.js'
import {
  CPC_CODE_COLUMN_NAME,
  FIRST_FILING_COUNTRY_COLUMN_NAME,
  GEO_COLUMN_NAME,
  ORG_COLUMN_NAME,
  TECH_COLUMN_NAME
} from '../constants/spec_column_names.js'

export const THUMBNAIL_MAX_SERIES = 5

/**
 * returns an array of size num_dims
 * i.e. some element values may be null (if they have not been explicitly set yet)
 */
export function get_rollup_thresholds(main_item, num_dims) {
  return main_item.rollup_thresholds || _.range(num_dims).map(() => null)
}

export function get_next_agglom_visibility(main_item, num_dims) {
  if (main_item.next_agglom_visibility != null) return main_item.next_agglom_visibility

  return _.range(num_dims).map(() => (true))
}

export function should_hide_next_agglom_item(spec, main_item, column_idx) {
  const { no_rollups } = spec

  if (no_rollups && no_rollups({item: main_item})) {
    return true
  }

  const { next_agglom_visibility=[] } = main_item
  return next_agglom_visibility[column_idx] === false
}

export function get_data_orientation(main_item) {
  const { data_orientation = DATA_ORIENTATION_NATIVE.id } = main_item

  return data_orientation
}

export function get_should_display_all_zeros_series(main_item) {
  const { include_all_zeros } = main_item

  return include_all_zeros != null ? include_all_zeros : false
}

export function get_deselected(main_item) {
  const { deselected_portfolio_ids=[], deselected_tech_ids=[], deselected_geo_ids=[] } = main_item

  return { deselected_portfolio_ids, deselected_tech_ids, deselected_geo_ids }
}

export function get_spotlighted(main_item) {
  const { spotlighted_portfolios, spotlighted_techs } = main_item

  return { spotlighted_portfolios, spotlighted_techs }
}

export function get_portfolio_column_idx(spec) {
  const {column_names = []} = spec

  return column_names.indexOf(ORG_COLUMN_NAME)
}

export function get_tech_column_idx(spec) {
  const {column_names = []} = spec

  return column_names.indexOf(TECH_COLUMN_NAME)
}

export function get_geo_column_idx(spec) {
  const {column_names = []} = spec

  return column_names.indexOf(GEO_COLUMN_NAME)
}

export function get_cpc_code_column_idx(spec) {
  const {column_names = []} = spec

  return column_names.indexOf(CPC_CODE_COLUMN_NAME)
}

export function get_first_filing_country_column_idx(spec) {
  const {column_names = []} = spec

  return column_names.indexOf(FIRST_FILING_COUNTRY_COLUMN_NAME)
}

export function should_show_item_filters({spec, view_id}) {
  if (view_id === CHOROPLETH_MAP_ID) return false

  const portfolio_dim_column_idx = get_portfolio_column_idx(spec)
  const technology_dim_column_idx = get_tech_column_idx(spec)
  const geo_dim_column_idx = get_geo_column_idx(spec)

  return portfolio_dim_column_idx > -1 || technology_dim_column_idx > -1 || geo_dim_column_idx > -1
}

export function should_show_item_rollups({spec, view_id}) {
  if (view_id === CHOROPLETH_MAP_ID) return false

  const cpc_code_dim_column_idx = get_cpc_code_column_idx(spec)
  const first_filing_country_dim_column_idx = get_first_filing_country_column_idx(spec)

  return cpc_code_dim_column_idx > -1 || first_filing_country_dim_column_idx > -1
}

function adjust_item_colour(item, ids_to_spotlight) {
  const {id, color} = item
  const color_no_alpha = remove_alpha(color.slice(1,color.length))
  const new_color = ((ids_to_spotlight.indexOf(id) !== -1) || (ids_to_spotlight.length === 0)) ? color_no_alpha : add_alpha(color_no_alpha, 0.1)

  return {...item, color: '#'+ new_color}
}

export function add_spotlights_to_deref_data(item, deref_data, selections) {
  const { spotlighted_portfolios: global_spotlighted_portfolios, spotlighted_techs: global_spotlighted_techs } = selections || {}
  const { spotlighted_portfolios: item_spotlighted_portfolios, spotlighted_techs: item_spotlighted_techs } = get_spotlighted(item)
  const spotlighted_portfolios = {...(global_spotlighted_portfolios || {}), ...(item_spotlighted_portfolios || {})}
  const spotlighted_techs = {...(global_spotlighted_techs || {}), ...(item_spotlighted_techs || {})}

  const { ids: spotlighted_portfolio_ids, apply: apply_portfolios_spotlights } = spotlighted_portfolios || {}
  const { ids: spotlighted_tech_ids, apply: apply_techs_spotlights }           = spotlighted_techs || {}

  const { selected_portfolios, selected_techs } = deref_data || {}

  const portfolio_ids_to_highlight = (apply_portfolios_spotlights === false) ? [] : ((spotlighted_portfolio_ids || []).length > 0) ? spotlighted_portfolio_ids : null
  const tech_ids_to_highlight = (apply_techs_spotlights === false) ? [] : ((spotlighted_tech_ids || []).length > 0) ? spotlighted_tech_ids : null

  const updated_selected_portfolios = (portfolio_ids_to_highlight) ? selected_portfolios.map(item => (adjust_item_colour(item, portfolio_ids_to_highlight))) : selected_portfolios
  const updated_selected_techs = (tech_ids_to_highlight) ? selected_techs.map(item => (adjust_item_colour(item, tech_ids_to_highlight))) : selected_techs

  return {
    ...deref_data,
    selected_portfolios: updated_selected_portfolios,
    selected_techs: updated_selected_techs
  }
}


