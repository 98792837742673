import React from 'react'

import MonitorResponsePanel from './MonitorResponsePanel.js'

const MonitorErrorDisplay = ({error}) => {

  const displayable_error = error.response || error.message || 'No message'

  return (
    <MonitorResponsePanel>
      {JSON.stringify(displayable_error, null, '\t')}
    </MonitorResponsePanel>
  )
}

export default MonitorErrorDisplay