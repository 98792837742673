import _ from 'underscore'
import qs from 'query-string'

import { DESCENDING } from '../../../model/sort_directions.js'
import { ID_TO_REPORT_FILTER, NO_FILTER } from '../model/filters.js'
import { OWNER_FIELD_ID } from '../model/report_fields.js'
import { is_completed_status } from '../../../utils/report_status_utils.js'

export const REPORT_FILTER_PARAM = 'filter'
export const REPORT_TAG_PARAM = 'tag'

export function filter_reports(reports, report_owners, search_input, report_filter_id, tag_id_to_filter_by, eval_classifier_names) {
  const filtered_reports = apply_selected_filter(reports, report_filter_id, tag_id_to_filter_by)
  return search_input ? apply_search_filter(filtered_reports, report_owners, search_input, eval_classifier_names) : filtered_reports
}

function apply_selected_filter(reports, report_filter_id, tag_id_to_filter_by) {
  if (tag_id_to_filter_by) {
    return reports.filter(report => _.contains(report.tag_ids, tag_id_to_filter_by))
  }
  return reports.filter(report => ID_TO_REPORT_FILTER[report_filter_id].filter_fn(report))
}

function apply_search_filter(reports, report_owners, search_input, eval_classifier_names) {
  const search_input_lower = search_input.toLowerCase().trim()

  return reports.filter(report => {
    const is_project = report.project_id

    if (!is_project) {
      const {external_report_id, evaluation_classifier_id} = report
      const owner_name = report_owners[external_report_id]
      const title = (report.title || '').toLowerCase()
      const owner = (owner_name || '').toLowerCase()
      const report_type = report.report_type.toLowerCase()

      if (evaluation_classifier_id && eval_classifier_names) {
        const classifier_name = eval_classifier_names[evaluation_classifier_id]
        if ((classifier_name || '').toLowerCase().indexOf(search_input_lower) !== -1) {
          return true
        }
      }

      return title.indexOf(search_input_lower) !== -1 || owner.indexOf(search_input_lower) !== -1 || report_type.indexOf(search_input_lower) !== -1 || list_includes_search_match(report.tag_names, search_input_lower)
    }
    else {
      const title = (report.name || '').toLowerCase()
      return title.indexOf(search_input_lower) !== -1
    }
  })
}

function list_includes_search_match(list, search_input) {
  return _.some(list, item => item.indexOf(search_input) !== -1)
}

export function get_searchable_tag_name(tag) {
  return tag.name.toLowerCase()
}

/**
 * @param {} reports Array of report objects
 * @param {} report_owners Mapping of externale
 * @param {} sort_field_id some property on the report object (i.e. 'name')
 * @param {} sort_direction_id
 * @returns {} Array of report objects
 */
export function sort_reports(reports, report_owners, sort_field_id, sort_direction_id) {
  const reports_sorted = _.sortBy(reports, (report) => {
    // Weirdly, underscore's sortBy seems to have problems with a mixture of strings/Numbers and nulls.
    // So we convert the nulls to an empty string here
    const value = extract_sortable_value(report, report_owners, sort_field_id)
    if (value == null) {
      return ''
    }
    return _.isString(value) ? value.toLowerCase() : value
  })
  return (sort_direction_id === DESCENDING) ? reports_sorted.slice().reverse() : reports_sorted
}

function extract_sortable_value(report, report_owners, sort_field_id) {
  return (sort_field_id === OWNER_FIELD_ID) ? report_owners[report.external_report_id] : report[sort_field_id]
}

export function get_new_selected_external_report_ids(external_report_ids, is_selected, selected_external_report_ids) {
  return is_selected ?
    _.uniq([...selected_external_report_ids, ...external_report_ids])                 // add
    : selected_external_report_ids.filter(id => !_.contains(external_report_ids, id)) // remove
}

export function get_new_selected_project_ids(project_ids, is_selected, selected_project_ids) {
  return is_selected ?
    _.uniq([...selected_project_ids, ...project_ids])
    : selected_project_ids.filter(id =>!_.contains(project_ids, id))
}

export function is_all_reports_selected(data_rows, selected_external_report_ids) {
  const reports = _.filter(data_rows, row => row.external_report_id)
  return _.every(reports, report => _.contains(selected_external_report_ids, report.external_report_id))
}

export function report_can_be_saved(report) {
  const {status, evaluation_classifier_id} = report || {}
  if (evaluation_classifier_id) {
    return false
  }
  return status && is_completed_status(status)
}

export function alphabetise_tags(tags) {
  return _.sortBy(tags, tag => tag.name.toLowerCase())
}

export function filter_on_search_string(unfiltered_list, search_string, field) {
  const search_string_lower = (search_string || '').toLowerCase().trim()
  if (!field) {
    // assume unfiltered is list of strings
    return _.filter(unfiltered_list, item => item.toLowerCase().indexOf(search_string_lower) !== -1)
  }
  // filter by values in specified field
  return _.filter(unfiltered_list, item => item[field].toLowerCase().indexOf(search_string_lower) !== -1)
}

export function get_filter_params_from_url() {
  const query_params = qs.parse(window.location.search)
  const report_tag_id = query_params[REPORT_TAG_PARAM]
  const report_filter_id = query_params[REPORT_FILTER_PARAM] || NO_FILTER
  if (report_tag_id) {
    // if specified, tag filters take precedence (all tagged reports are saved anyway)
    return {tag_id_from_url: report_tag_id, filter_id_from_url: NO_FILTER}
  } else if (report_filter_id && _.contains(_.keys(ID_TO_REPORT_FILTER), report_filter_id)) {
    return {tag_id_from_url: null, filter_id_from_url: report_filter_id}
  }
  return {tag_id_from_url: null, filter_id_from_url: report_filter_id}
}

export function update_query_params_for_filtering(report_tag_id, report_filter_id) {
  const query_params = qs.parse(window.location.search)
  if (report_tag_id) {
    return {..._.omit(query_params, REPORT_FILTER_PARAM), [REPORT_TAG_PARAM]: report_tag_id}
  } else if (report_filter_id && _.contains(_.keys(ID_TO_REPORT_FILTER), report_filter_id)) {
    return {..._.omit(query_params, REPORT_TAG_PARAM), [REPORT_FILTER_PARAM]: report_filter_id}
  }
  return _.omit(query_params, REPORT_TAG_PARAM, REPORT_FILTER_PARAM)
}