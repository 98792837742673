import _ from 'underscore'
import { extent } from 'd3'

import { DEFAULT_NEXT_AGGLOM_COLOR } from '../constants/colours.js'
import { PERCENTAGE_SYMBOL } from '../constants/constants.js'

import { format_integer_with_comma, get_as_map, get_object_values, sum } from './utils.js'
import { is_selections_empty } from './viewer_utils.js'
import { values_to_item_objects } from './item_utils.js'
import { get_year_items } from './item_utils.js'
import {
  get_deselected,
  get_geo_column_idx,
  get_portfolio_column_idx,
  get_rollup_thresholds,
  get_should_display_all_zeros_series,
  get_tech_column_idx
} from './main_items_utils.js'

import { DEFAULT_ROLLUP_LIMIT, MINIMUM_ROLLUP_LIMIT, NO_ROLLUP_LIMIT } from '../model/rollups.js'
import { HEATMAP_ID } from '../model/view_ids.js'
import { is_report_series_sort_by_size } from './report_utils.js'
import { is_item_within_histogram_range } from './histogram_range_utils.js'

export const IS_NEXT_AGGLOM = 'is_next_agglom'
export const INDIVIDUAL_OWNERS_ITEM_NAME = 'individual owners'

export const CHILD_IDS = 'child_ids'

export const OTHER_ID = -1

const COMPOUND_KEY_SEPARATOR = '_'

function is_individual_owners_item(item) {
  const {name=''} = item || {}
  return name.toString().toLowerCase() === INDIVIDUAL_OWNERS_ITEM_NAME.toLowerCase()
}
/**
 * @param {array} input
 * @param {array} column_names
 *
 * Given data in ReportReader "column json" format, returns a list of objects.
 *
 * For example, given input:
 *
 *    {
 *      "columns": ["PFTP.portfolio_id","PFTT.technology_id","COUNT DISTINCT PF.pat_fam_id"],
 *      "data": [
 *        [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 6, 6, 6, 6, 6, 6, 6, 6, 6, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 7, 8, 8, 8, 8, 8, 9, 9, 10, 10, 11],
 *        [0, 1, 2, 4, 6, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 0, 2, 4, 6, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 1, 2, 4, 6, 9, 10, 12, 13, 14, 17, 18, 20, 21, 0, 2, 9, 10, 11, 12, 13, 14, 15, 18, 19, 20, 0, 2, 4, 9, 10, 14, 17, 18, 19, 20, 0, 2, 9, 12, 13, 14, 17, 18, 20, 0, 2, 10, 11, 12, 13, 14, 16, 17, 18, 21, 0, 2, 9, 12, 17, 13, 17, 2, 13, 14],
 *        [32, 10, 192, 79, 5, 37, 17, 7, 36, 65, 48, 381, 1, 32, 10, 18, 6, 6, 14, 132, 36, 6, 35, 23, 16, 30, 115, 60, 178, 1, 24, 27, 12, 15, 11, 18, 6, 23, 1, 31, 3, 1, 8, 18, 10, 6, 30, 44, 26, 99, 4, 10, 12, 5, 7, 6, 1, 34, 17, 1, 13, 12, 4, 14, 7, 5, 2, 2, 2, 3, 5, 1, 1, 1, 2, 5, 1, 1, 3, 2, 1, 1, 3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 3, 2, 4, 7, 3, 2, 1, 2, 1, 6, 1, 1, 1, 11, 1, 1, 1, 1, 2, 1, 2, 1, 1, 1, 1, 1, 4, 1, 3]
 *      ]
 *    }
 *
 * and oldkey_to_newkey:
 *
 *    ["portfolio_id", "technology_id", "size"]
 *
 * we return:
 *
 *    [
 *      { portfolio_id: 0, technology_id: 0, "size": 32 },
 *      ...
 *      {}
 *    ]
 */
export function convert_columns_data_to_objects(input, column_names) {
  column_names = column_names || []
  return input.data[0].map((_, row_idx) => {
    // For each value, return an object
    return input.columns.reduce((acc, key, col_idx) => {
      // For each column... add it as property on object
      const newkey = column_names[col_idx] || key
      const value = input.data[col_idx][row_idx]
      return {...acc, [newkey]: value }
    }, {})
  })
}

export function get_value_column_idx(data) {
  return data.columns.length - 1 // value column is always the last one
}

function get_compound_key(keys) {
  return keys.join(COMPOUND_KEY_SEPARATOR)
}

function get_compound_key_from_data(data, key_column_idxs, row_idx) {
  const keys = key_column_idxs.map(col_idx => data.data[col_idx][row_idx])
  return get_compound_key(keys)
}

export function get_keys_to_value(data, key_column_idxs, value_column_idx, show_average) {
  const keys = data.data[0]
  const num_rows = keys.length
  const unique_keys = [...new Set(keys)]
  let key_counter = {}
  unique_keys.forEach(key => key_counter[key] = 0)

  let result =  _.range(num_rows).reduce((acc, _, row_idx) => {
    const key        = get_compound_key_from_data(data, key_column_idxs, row_idx)
    const prev_total = acc[key] || 0
    const value      = data.data[value_column_idx][row_idx]
    const total      = prev_total + value
    acc[key] = total // mutate (as immutable is too slow for large object arrays here)
    key_counter[key] +=1
    return acc
  }, {})

  if (show_average)
    _.forEach(result,(value, key) => result[key] = value/key_counter[key]) //averages needs calculating not totals

  return result
}

export function decimal_value_formatter(decimal_places) {
  return (v) => {
    if (!_.isNumber(v)) {
      return v
    }
    const num_string = v.toFixed(decimal_places)
    return Number.parseFloat(num_string) // return a Number here (strings break Excel download)
  }
}


export function percentage_value_formatter(decimal_places) {
  return (value) => {
    if (!_.isNumber(value)) {
      return value
    }
    const num_string = value.toFixed(decimal_places) + PERCENTAGE_SYMBOL
    return num_string
  }

}

export function decimal_value_with_leading_zeros_formatter(decimal_places) {
  return (v) => {
    const value = decimal_value_formatter(decimal_places)(v)
    return value.toFixed(decimal_places) // return a Number here (strings break Excel download)
  }
}

export function get_value(keys_to_value, keys, no_value_label) {
  const key = get_compound_key(keys)
  const value = keys_to_value[key]
  const value_to_return = ((value != null) || no_value_label) ? value : 0
  return value_to_return
}

export function get_selected_timerange_items(full_timerange_items, user_timerange, spec, data_creation_date) {
  if (user_timerange) {
    // User-specified timerange
    const [from, to] = user_timerange
    return full_timerange_items.filter(item => (item.id >= from && item.id < to))
  }

  const { get_default_selected_timerange } = spec
  if (get_default_selected_timerange) {
    // Default specified in spec
    const default_extent = get_default_selected_timerange(data_creation_date)
    const default_year_items =  get_year_items(default_extent)
    return default_year_items
  }

  // Otherwise show all
  return full_timerange_items
}

export function get_selected_histogram_range(full_histogram_range, user_histogram_range) {
  if (user_histogram_range) {
    const selected_histogram_range = full_histogram_range.filter(item => {
      return is_item_within_histogram_range(item, user_histogram_range)
    })

    return selected_histogram_range
  }

  return full_histogram_range
}

function filter_key_dims_with_calculated_data(key_dims, calculated_data, item) {
  const { next_agglom_visibility=[] } = item
  return key_dims.map((key_items, column_idx) => {
    return key_items.filter(item => {
      if (item.is_next_agglom) {
        return next_agglom_visibility[column_idx] !== false
      }
      return _.contains(calculated_data.data[column_idx], item.id)
    })
  })
}

function filter_key_dims(key_dims, timeseries_key_dim_idxs, histogram_bucket_key_dim_idxs, item, spec, data_creation_date, check_item_selections) {
  const { next_agglom_visibility=[] } = item
  const portfolio_dim_column_idx = get_portfolio_column_idx(spec)
  const technology_dim_column_idx = get_tech_column_idx(spec)
  const geo_dim_column_idx = get_geo_column_idx(spec)

  const {deselected_portfolio_ids=[], deselected_tech_ids=[], deselected_geo_ids=[]} = get_deselected(item)

  return key_dims.map((key_items, column_idx) => {

    const is_timeseries = is_column_timeseries(timeseries_key_dim_idxs, column_idx)
    if (is_timeseries) {
      // It's a timeseries, so filter it
      return get_selected_timerange_items(key_items, item.timerange, spec, data_creation_date)
    }

    const is_histogram_bucket = is_column_histogram_bucket(histogram_bucket_key_dim_idxs, column_idx)

    if (is_histogram_bucket) {
      return get_selected_histogram_range(key_items, item.histogram_range)
    }

    if (check_item_selections) {

      if ((column_idx === portfolio_dim_column_idx) && (deselected_portfolio_ids.length > 0)) {
        return key_items.filter(item => {
          if (item.is_next_agglom) {
            return next_agglom_visibility[column_idx] !== false
          }
          return deselected_portfolio_ids.indexOf(item.portfolio_id) === -1
        })
      }

      if ((column_idx === technology_dim_column_idx) && (deselected_tech_ids.length > 0)) {
        return key_items.filter(item => {
          if (item.is_next_agglom) {
            return next_agglom_visibility[column_idx] !== false
          }
          return deselected_tech_ids.indexOf(item.technology_id) === -1
        })
      }
      if ((column_idx === geo_dim_column_idx) && (deselected_geo_ids.length > 0)) {
        return key_items.filter(item => {
          if (item.is_next_agglom) {
            return next_agglom_visibility[column_idx] !== false
          }
          return deselected_geo_ids.indexOf(item.country_code) === -1
        })
      }
    }

    return key_items
  })
}

/**
 * Restrict data to rows whose keys are in selection.
 *
 * @param {} data ReportReader column-style data
 * @param {} key_dims 2d array. Array of arrays of "items" (each item has an "id" property).
 *           Assumes the columns correspond to the key groups.
 *           i.e. if data.data[1] is the "techs" column, key_dims[1] should be techs too.
 */
export function filter_data(data, key_dims) {
  const selected_key_ids = key_dims.map(items => items.map(item => item.id)) // 2d array
  const num_rows = data.data[0].length

  const selected_key_id_dicts = key_dims.map(items => get_as_map(items, 'id'))

  const filtered_rows_idxs = _.range(num_rows).filter(row_idx => {
    // For each row_idx (0, 1, 2.... )
    // Only return this row if every column is in selected_key_ids
    return selected_key_ids.every((keys, col_idx) => {
      const column_key = data.data[col_idx][row_idx]
      return selected_key_id_dicts[col_idx][column_key]
    })
  })

  const data_filtered = data.data.map(column => {
    return filtered_rows_idxs.map((row_idx) => column[row_idx])
  })

  return {
    ...data,
    data: data_filtered
  }
}

function rollup_sorted_data({data, key_items, key_column_idx, sorted_keys_ids, key_to_rollup, limit}) {
  const key_column_data   = data.data[key_column_idx]
  const id_to_key_item = get_as_map(key_items, 'id')
  // Get top keys.
  const top_keys = sorted_keys_ids.slice(0, limit)

  // Get next key_id (re-use existing key).
  const next_key = sorted_keys_ids[limit]

  // Get next child ids
  const next_child_ids = sorted_keys_ids.slice(limit)

  // Generate ltd key_items
  const rollup_ids_in_next = next_child_ids.filter(id => key_to_rollup[id])
  const total_rollup_child_ids = sum(rollup_ids_in_next.map(id => key_to_rollup[id].count))
  const num_next = next_child_ids.length - rollup_ids_in_next.length + total_rollup_child_ids
  const name = `Next ${num_next}`
  const short_name = name

  const top_key_items = top_keys.map(id => id_to_key_item[id])

  const unrelated_item = top_key_items.filter(item => item.is_unrelated)[0]

  let top_items = top_key_items

  if (unrelated_item) {
    top_items = top_key_items.filter(item => !item.is_unrelated)
    top_items.push(unrelated_item)
  }

  const key_items_ltd = top_items
    .concat({
      id: next_key,
      [IS_NEXT_AGGLOM]: true,
      [CHILD_IDS]: next_child_ids,
      name,
      short_name,
      color: DEFAULT_NEXT_AGGLOM_COLOR,
      'rollup': {count: num_next * 1}
    })

  // Generate ltd key data (rename key if not in topX).
  const key_column_data_ltd = key_column_data.map(key => {
    if (!_.contains(top_keys, key + '')) {
      return next_key
    }
    return key
  })

  // Generate ltd data
  const data_ltd = {
    ...data,
    data: [
      ...data.data.slice(0, key_column_idx),
      key_column_data_ltd, // this is the the edited column
      ...data.data.slice(key_column_idx + 1)
    ]
  }

  // Return
  return [ data_ltd, key_items_ltd ]

}

function sort_keys_before_rollup({data, key_items, key_column_idx, value_column_idx, selected_key_ids, key_to_rollup, key_to_private_owner}) {
  const is_1d = data.data.length === 2

  const key_column_data   = data.data[key_column_idx]
  const value_column_data = data.data[value_column_idx]

  const the_other_key_column_data_idx = is_1d ? null : (key_column_idx === 0) ? 1 : 0

  let key_to_total = {}
  key_column_data.forEach((key, i) => { // mutate (as immutable approach is too slow here)
    const val = value_column_data[i]
    let is_selected = true
    if (!is_1d && (selected_key_ids != null)) {
      const the_other_key_column_data = data.data[the_other_key_column_data_idx]
      is_selected = (selected_key_ids[the_other_key_column_data_idx] || []).indexOf(the_other_key_column_data[i]) > -1
    }

    const prev_total = key_to_total[key] || 0
    const total = prev_total + (is_selected ? val : 0)
    key_to_total[key] = total
  })

  // Add in missing keys (i.e. ReportReader omits 0s from results)
  key_items.forEach(({id}) => { // mutate (as immutable approach is too slow here)
    if (key_to_total[id] == null) {
      key_to_total[id] = 0
    }
  })

  // Sort descending.
  const key_total = _.pairs(key_to_total)

  const sorted_pairs =  _.sortBy(key_total, ([ key, val ]) => {
    const is_rollup = key_to_rollup[key]
    const is_private_owners = key_to_private_owner[key]
    if (is_rollup || is_private_owners) {
      return Infinity // make sure (server-side) rolled-up portfolios are at the end (so they will be agglomerated into "next")
    }

    return -val
  })

  return sorted_pairs.map(([key]) => key)
}

/**
 * Restrict the data (columns) and key_items (one column) to maximum limit elements.
 * @param {} data ReportReader column-style data.
 * @param {} key_items 1d array of "items" (each has an "id" property).
 * @param {} key_column_idx The column index of given key items.
 * @param {} value_column_idx The column index for values.
 * @param {} limit Maximum number of items
 * @returns {} Returns new data and key_items. Any agglomerate "next" items will be tagged.
 */
export function sort_and_rollup_data(data, key_items, key_column_idx, value_column_idx, limit, selected_key_ids, should_sort=true) {
  const value_column_data = data.data[value_column_idx]

  if (value_column_data.length === 0) {
    // No data, so return as is.
    return [data, key_items]
  }

  // Get key_to_rollup
  const key_to_rollup = {}
  const key_to_private_owner = {}
  key_items.forEach(item => {
    if (item.rollup) {
      key_to_rollup[item.id] = item.rollup
    }

    if(is_individual_owners_item(item)) {
      key_to_private_owner[item.id] = item
    }
  })

  // Get key_to_total dicts
  const sorted_keys_ids = should_sort ? sort_keys_before_rollup({data, key_items, key_column_idx, value_column_idx, selected_key_ids, key_to_rollup, key_to_private_owner}) : key_items.map(item => item.id)
  const should_rollup = !((limit === NO_ROLLUP_LIMIT) || (key_items.length <= limit + 1))

  if (!should_rollup) {
    const id_to_key_item = get_as_map(key_items, 'id')
    const sorted_key_items = sorted_keys_ids.map(id => id_to_key_item[id])

    const unrelated_item = sorted_key_items.filter(item => item.is_unrelated)[0]

    let sorted_items = sorted_key_items

    if (unrelated_item) {
      sorted_items = sorted_key_items.filter(item => !item.is_unrelated)
      sorted_items.push(unrelated_item)
    }
    return [data, sorted_items]
  }

  const [ data_ltd, key_items_ltd ] = rollup_sorted_data({data, key_items, key_column_idx, sorted_keys_ids, key_to_rollup, limit})

  return [ data_ltd, key_items_ltd ]
}

export function is_column_timeseries(timeseries_key_dim_idxs, column_idx) {
  return _.contains(timeseries_key_dim_idxs, column_idx)
}

export function is_column_histogram_bucket(histogram_bucket_key_dim_idxs, column_idx) {
  return _.contains(histogram_bucket_key_dim_idxs, column_idx)
}

export function is_any_rollup_available({view_id, spec, column_idx, key_items}) {
  const { is_rollup_available, timeseries_key_dim_idxs, histogram_bucket_key_dim_idxs } = spec

  const is_histogram_bucket = is_column_histogram_bucket(histogram_bucket_key_dim_idxs, column_idx)
  if (is_histogram_bucket) {
    // We don't rollup histogram buckets (would be weird to have missing buckets)
    return false
  }

  if (is_rollup_available) {
    return is_rollup_available({view_id})
  }

  const is_timeseries = is_column_timeseries(timeseries_key_dim_idxs, column_idx)
  if (is_timeseries) {
    // We don't rollup timeseries (would be weird to have missing years)
    return false
  }

  // Is rollup possible for minimum rollup threshold?
  return is_rollup_possible(MINIMUM_ROLLUP_LIMIT, key_items.length)
}

export function is_rollup_possible(threshold, num_items) {
  // Are there enough key items to actually roll up?
  // For example, suppose rollup threshold is 5.
  // Then there would need to be at least 7 items to do a rollup
  // i.e. if only 6 there's no point, as "next" would simply contain the 6th
  return num_items > (threshold + 1)
}

export function get_processed_data_and_key_dims(spec, view_id, data_creation_date, item, report_reader_data, ref_data, deref_data, report_series_sort, is_preview, check_item_selections) {
  const { get_key_dims, timeseries_key_dim_idxs, histogram_bucket_key_dim_idxs, value_to_sort_column_idx, apply_calculation } = spec
  // Get key_dims
  const should_display_all_zeros_series = (view_id === HEATMAP_ID) && get_should_display_all_zeros_series(item)

  const pre_calculation_key_dims = get_key_dims({data: report_reader_data, ref_data, deref_data, item, data_creation_date, hide_no_data_dim_keys: !should_display_all_zeros_series}) // 2d array

  const data = apply_calculation ? apply_calculation({spec, data: report_reader_data, key_dims_before_calculation: pre_calculation_key_dims, item, data_creation_date}) : report_reader_data
  const key_dims = apply_calculation ? filter_key_dims_with_calculated_data(pre_calculation_key_dims, data, item) : pre_calculation_key_dims

  const is_1d = data.data.length === 2

  const value_column_idx = value_to_sort_column_idx || data.data.length - 1 // "value" is usually the last column
  if (data.columns.length === 1) {
    // There are no key columns (i.e. only a count), so no need to limit keys etc
    return [data, key_dims, key_dims]
  }

  const selected_rollup_thresholds = get_rollup_thresholds(item, key_dims.length)

  // Filter key dims (i.e. apply any time range restrictions etc)
  const key_dims_filtered = filter_key_dims(key_dims, timeseries_key_dim_idxs, histogram_bucket_key_dim_idxs, item, spec, data_creation_date, false)

  // Filter data
  let data_filtered = filter_data(data, key_dims_filtered)

  const key_dims_dataset_filters_applied = filter_key_dims(key_dims, timeseries_key_dim_idxs, histogram_bucket_key_dim_idxs, item, spec, data_creation_date, true)
  const is_histogram = (histogram_bucket_key_dim_idxs != null) && (histogram_bucket_key_dim_idxs.length > 0)
  const dataset_selected_key_ids = key_dims_dataset_filters_applied.map(items => items.map(item => item.id))

  // Limit data
  //to do rollup properly we have to ignore chart filter selections for now
  const [data_ltd, key_dims_ltd] = key_dims_filtered.reduce(([_data, _key_dims], key_items, column_idx) => {
    const is_timeseries_column = (timeseries_key_dim_idxs || []).indexOf(column_idx) > -1
    const is_histogram_column = (histogram_bucket_key_dim_idxs || []).indexOf(column_idx) > -1

    const should_check_selection_in_other_dim = !(
      is_1d ||
      (is_histogram && !is_histogram_column) ||
      (value_to_sort_column_idx != null) //currently, mean pvix charts, sorted by size total
    )

    const selected_key_ids = should_check_selection_in_other_dim ? dataset_selected_key_ids : null

    const should_sort = !(is_timeseries_column || is_histogram_column)

    // For each key column...

    // Get rollup-threshold
    const selected_threshold = (!is_preview && !check_item_selections) ? NO_ROLLUP_LIMIT : is_preview ?
          DEFAULT_ROLLUP_LIMIT // for previews, rollup data (no point in showing 1000s of series, and it will slow/freeze the screen)
          : (selected_rollup_thresholds[column_idx] || DEFAULT_ROLLUP_LIMIT)
    // Is any rollup available?
    const rollup_available = is_any_rollup_available({spec, view_id, column_idx, key_items})

    // Is rollup possible for the currently selected threshold?
    const rollup_possible = is_rollup_possible(selected_threshold, key_items.length)

    if (!rollup_available || !rollup_possible || selected_threshold === -1) {
      // don't rollup this column

      if (is_preview) {
        return [_data, [..._key_dims, key_items]]
      }

      const [processed_data, key_dims_sorted] = sort_and_rollup_data(_data, key_items, column_idx, value_column_idx, NO_ROLLUP_LIMIT, selected_key_ids, should_sort && is_report_series_sort_by_size(report_series_sort))

      return [processed_data,  [..._key_dims, key_dims_sorted]]
    }

    // Apply limit to column, and its corresponding key_items
    const [data_ltd, key_items_ltd] = sort_and_rollup_data(_data, key_items, column_idx, value_column_idx, selected_threshold, selected_key_ids, should_sort)

    // Add limited key_items to key_dims
    const key_dims_ltd = [..._key_dims, key_items_ltd]

    // Return
    return [data_ltd, key_dims_ltd]

  }, [data_filtered, [] ])

  //only now key dims can be cleared of items deselected in chart filters
  const key_dims__clean = filter_key_dims(key_dims_ltd, timeseries_key_dim_idxs, histogram_bucket_key_dim_idxs, item, spec, data_creation_date, check_item_selections)
  const data_clean = filter_data(data_ltd, key_dims__clean)

  // Return
  return [data_clean, key_dims__clean, key_dims /* the original key_dims - useful for knowing whether to show rollup controls etc */]
}

/**
 * Returns true if the selections are empty, or any of the key_dims are empty.
 * Otherwise returns false.
 * @param {} data Column data
 * @param {} key_dims 2d array, each element is an array of key_items
 * @param {} selections obj (portfolios, techs, geos)
 * @param {} spec dataset specification
 */
export function is_data_empty(data, key_dims, selections, spec) {
  const {requires_assignee_links: ignore_tech_selections, is_data_empty_check} = spec || {}

  if (is_data_empty_check) {
    return is_data_empty_check({data})
  }

  if (is_selections_empty(selections, ignore_tech_selections)) {
    return true
  }

  if (!data.data[0].length) {
    return true
    // This will happen if all the data is zeros (i.e column-data does not contain zeros)
    // There's (arguably) not much point in showing this to users.
    // Plus it currently messes up certain charts (previews just look blank).
  }

  return has_empty_key_dims(key_dims)
}

export function has_empty_key_dims(key_dims) {
  return !key_dims || key_dims.some(key_items => !key_items.length)
}

function get_values_by_x_keys(keys_to_value) {
  const keys_to_values = {}

  Object.keys(keys_to_value).forEach(key => {
    const x = key.split(COMPOUND_KEY_SEPARATOR)[0]
    keys_to_values[x] = (keys_to_values[x] || 0) + keys_to_value[key]
  })

  return keys_to_values
}

/**
 * Sort items by value descending (but make sure "NEXT" / "OTHER" / "Rollup" / "Unrelated" go at the end)
 */
export function get_key_items_sorted_by_value(key_items, keys_to_value, is_1d=true) {
  const k_2_v = (is_1d) ? keys_to_value : get_values_by_x_keys(keys_to_value)

  const key_items_sorted = _.sortBy(key_items, (key_item) => {
    const { portfolio_id, rollup, is_unrelated } = key_item

    if ( key_item[IS_NEXT_AGGLOM] || (portfolio_id === OTHER_ID) || rollup || is_unrelated || is_individual_owners_item(key_item) ) {
      return Infinity // put at the end
    }
    const value = get_value(k_2_v, [key_item.id]) || 0
    return -value
  })

  const items = is_1d ? key_items_sorted : key_items_sorted.filter(key => (key[IS_NEXT_AGGLOM] || k_2_v[key.id]))

  //if needed, swap individual owners and server-side rollup items, so the rollup one is always last
  const last_item = items[items.length-1] || {}
  const next_to_last_item = items[items.length-2] || {}

  if (is_individual_owners_item(last_item) && next_to_last_item.meta && (next_to_last_item.meta !== 'null')) {
    items[items.length-2] = last_item
    items[items.length-1] = next_to_last_item
  }

  return items
}

export function get_dim_keys_from_column(column) {
  const unique_items = {}

  column.forEach(item => {
    unique_items[item] = item
  })

  return values_to_item_objects(Object.keys(unique_items))
}

export function apply_colours_to_dim_keys(dim_keys=[], colour_scheme) {
  return dim_keys.map((item, idx) => {
    const color = colour_scheme[idx % colour_scheme.length]
    return {
      ...item,
      color
    }
  })
}

export function get_dim_keys_with_colours(column, colour_scheme) {
  return apply_colours_to_dim_keys(get_dim_keys_from_column(column), colour_scheme)
}

export function get_countries_dim_keys_with_colours(countries_column, ref_data) {
  const {geos=[]} = ref_data || {}

  const id_to_geo = get_as_map(geos, 'id')

  const countries_dim_keys = []

  const country_codes = _.uniq(countries_column || [])
  country_codes.forEach(country_code => {
    const country_by_id = id_to_geo[country_code]
    if (country_by_id) {
      countries_dim_keys.push(country_by_id)
    }
  })

  return countries_dim_keys
}

export function get_year_items_from_column(years_column) {
  if (!years_column || !years_column.length) {
    // No years to show
    return []
  }

  const [min_year, max_year] = extent(years_column) // from, to are both inclusive
  return get_year_items([min_year, max_year + 1 /* to is exclusive */])
}

export function sum_values(keys_to_value) {
  return sum(get_object_values(keys_to_value))
}

export function get_display_value(value, value_formatter, no_value_label) {
  if (value == null && no_value_label) {
    return no_value_label
  }

  const value_or_zero = value || 0

  if (value_formatter) {
    return value_formatter(value_or_zero)
  }

  return format_integer_with_comma(value_or_zero)
}

export function get_subselections(should_transpose_data, keys, items) {
  const {x_key, y_key} = keys
  const {x_items, y_items} = items

  const subselections = []

  if (x_key) {
    subselections.push({
      key: x_key, items: should_transpose_data ? y_items : x_items
    })
  }

  if (y_key) {
    subselections.push({
      key: y_key, items: should_transpose_data ? x_items : y_items
    })
  }

  return subselections
}

export function calculate_custom_y_range({spec, plotly_data}) {
  const {axis_range} = spec
  // this might need amending in future if we want to export horizontal bar charts with custom axis ranges outside of the report deck
  const y_values = _.flatten(plotly_data.map(item => item.y))
  const [y_min, y_max] = extent(y_values)
  return axis_range(y_min, y_max)
}