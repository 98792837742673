import React, { useEffect, useRef, useState } from 'react'

import { exec_summary_svg2pdf } from '../executive_summary/utils/executive_summary_utils.js'
import { get_clean_filename, trigger_download } from '../../utils/download_utils.js'
import { track_download_event } from '../../utils/tracking_utils.js'
import ErrorModal from '../ErrorModal.js'
import { PrimaryButton } from '../widgets/Button.js'
import ExecutiveSummaryReport from '../executive_summary/ExecutiveSummaryReport.js'
import HyperscriptDescription from './HyperscriptDescription.js'
import HyperscriptSpinner from './HyperscriptSpinner.js'
import { set_favicon } from '../../utils/viewer_utils.js'
import { NUM_COMPANIES } from '../executive_summary/utils/executive_summary_constants.js'

const ExecSummaryHyperscript = ({hyperscript, selections, ref_data, deref_data, report_title, internal_report_id}) => {
  const exec_summary_ref = useRef()

  const [show_spinner, set_show_spinner] = useState(false)
  const [hs_data, set_hs_data] = useState(null)
  const [download_pdf_error, set_download_pdf_error] = useState(null)
  const [fetch_error, set_fetch_error] = useState(null)

  useEffect(() => {
    set_favicon(show_spinner)
  }, [show_spinner])

  function run_exec_summary() {
    set_show_spinner(true)
    const { endpoint } = hyperscript
    const { data_creation_date } = ref_data || {}
    const { selected_portfolios, selected_techs } = deref_data || {}

    //only first NUM_COMPANIES(5) are chosen to be displayed as there wouldn't be enough space for more
    const portfolios_for_exec_summary = selected_portfolios.slice(0, NUM_COMPANIES)
    const selections_for_exec_summary = {...selections, selected_portfolio_ids: portfolios_for_exec_summary.map(portfolio => portfolio.portfolio_id)}

    const input_data = {
      report_title,
      internal_report_id,
      data_creation_date,
      selections_for_exec_summary,
      portfolios_for_exec_summary,
      selected_techs
    }

    endpoint(input_data)
      .catch(error => {
        set_show_spinner(false)
        set_fetch_error(error)
        throw error
      })
      .then(data => {
        set_hs_data(data)
        set_show_spinner(false)
      })
  }

  function download_exec_summary() {
    const { id, file_format, filename_suffix } = hyperscript || {}
    const filename = get_clean_filename(`${report_title}_${filename_suffix}${file_format.ext}`)
    track_download_event(`obj="hyperscript" name="${id} download"`)
    exec_summary_svg2pdf({ref: exec_summary_ref})
      .then(pdf => {
        trigger_download(pdf, file_format.mime, filename)
        set_show_spinner(false)
      })
      .catch(err => {
        track_download_event(`obj="hyperscript" name="${id} download" status="failed"`)
        set_download_pdf_error(err)
        set_show_spinner(false)
        throw err
      })
  }

  if (download_pdf_error) {
    return(
      <ErrorModal
        on_hide={() => {set_download_pdf_error(null)}}
        error={download_pdf_error}
        context={'downloading report'}
      />
    )
  }

  if (fetch_error) {
    return(
      <ErrorModal
        on_hide={() => {set_fetch_error(null)}}
        error={fetch_error}
        context={`fetching Exacutive Summary report - ${fetch_error.message}`}
      />
    )
  }

  return (
    <div>

      <HyperscriptDescription
        hyperscript={hyperscript}
        className='mt-2 mb-2'
      />

      {show_spinner &&
        <HyperscriptSpinner
          hyperscript={hyperscript}
        />
      }

      {!show_spinner && !hs_data &&
        <div>
          <PrimaryButton
            className='mt-3 mt-md-0'
            onClick={() => {run_exec_summary()}}
          >
            Start
          </PrimaryButton>
        </div>
     }

      {!show_spinner && hs_data &&
        <div>
          <ExecutiveSummaryReport ref={exec_summary_ref} data={hs_data} download_handler={download_exec_summary} />
        </div>
      }
    </div>
  )
}

export default ExecSummaryHyperscript