import _ from 'underscore'
import $ from 'jquery'

const APPLE_COMMAND_KEYS = [224, 17, 91, 93] // Firefox / Opera / Webkit (left/right)
export const ESC_KEY = 27
export const ENTER_KEY = 13
export const SPACE_KEY = 32
export const LETTER_A_KEY = 65
export const ARROW_LEFT = 37
export const ARROW_RIGHT = 39

function event_contains_meta_key(keyCode, shiftKey, ctrlKey, metaKey) {
  return (ctrlKey || metaKey || shiftKey || _.contains(APPLE_COMMAND_KEYS, keyCode))
}

export function active_element_is_input(active_el) {
  return _.contains(['input', 'textarea', 'select'], active_el.tagName.toLowerCase())
}

export function ok_to_proceed_with_shortcuts(event, allow_modal=false) {
  const { keyCode, shiftKey, ctrlKey, metaKey } = event

  if (event_contains_meta_key(keyCode, shiftKey, ctrlKey, metaKey)) {
    // Combination keys (i.e. ctrl-s, command-i etc..), so don't proceed
    return false
  }

  const is_modal_showing = ($('.modal.show').length > 0)
  if (!allow_modal && is_modal_showing) {
    // Bootstrap modal is showing, so don't proceed
    return false
  }

  const active_el = document.activeElement
  if (active_el && active_element_is_input(active_el)) {
    // Currently active element is an input (or button in a dropdown), so don't proceed

    if (keyCode === ESC_KEY) {
      // User hit the "escape" key, so un-focus current element so that subsequent keyboard events are picked up
      active_el.blur()
    }
    return false
  }

  // Ok to proceed
  return true
}

export function react_strap_popover_clear_popovers_workaround() {
  // Currently, it seems ReactStrap assumes open popovers will be closed by some click action.
  // But keyboard events break this assumption.
  // For now, we work around this by simulating a click here.
  document.body.click()
}

export function is_escape_key_down(e) {
  return e.which === ESC_KEY
}

export function is_enter_key_down(e) {
  return e.which === ENTER_KEY
}