import { IS_PROD } from '../constants/constants.js'
import { has_performance_cookies_disabled } from './onetrust_utils.js'

const NO_ANALYTICS = process.env.REACT_APP_NO_ANALYTICS === 'true'

export function initialise_pendo(user_id, user_group) {
  const {pendo} = window

  if ((pendo == null) || NO_ANALYTICS || !IS_PROD || has_performance_cookies_disabled()) return

  pendo.initialize({
    visitor: {
      id: user_id,
      group: user_group
    },
    account: {
      id: user_group,
      name: user_group,
    }
  })
}

export function prepare_pathname_for_pendo(url) {
  const {pendo} = window

  if (pendo == null) return

  const {location} = pendo

  if (location == null) return

  location.setUrl(url)
}
