import React from 'react'

import BasePlotlyXYChart from './BasePlotlyXYChart.js'
import { BAR_CHART_TYPE, BARMODE_CLUSTER } from './base_plotly_props.js'


const ColumnClusteredChart = (props) => {
  return (
    <BasePlotlyXYChart
      {...props}
      chart_type={BAR_CHART_TYPE}
      barmode={BARMODE_CLUSTER}
    />
  )
}

ColumnClusteredChart.get_plotly_data = (props) => {
  return BasePlotlyXYChart.get_plotly_data({ ...props, chart_type: BAR_CHART_TYPE })
}

ColumnClusteredChart.get_axis_titles = BasePlotlyXYChart.get_axis_titles

ColumnClusteredChart.get_csv_value_rows = (props) => {
  return BasePlotlyXYChart.get_csv_value_rows({ ...props, chart_type: BAR_CHART_TYPE })
}

export default ColumnClusteredChart
