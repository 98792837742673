import { get_as_map } from '../../utils/utils.js'

export const KEY_ORGANISATION = 'org'
export const KEY_TECHNOLOGY ='tech'
export const KEY_YEAR =  'year'
export const KEY_REGION = 'region'
export const KEY_COUNTRY = 'geo'
export const KEY_FIRST_FILING_COUNTRY = 'first_filing_country'
export const KEY_GRANT_YEAR = 'grant_year'
export const KEY_PUBLICATION_YEAR = 'pub_year'
export const KEY_EXPIRY_YEAR = 'exp_year'
export const KEY_EXPIRY_YEAR_PROJECTED = 'proj_exp_year'
export const KEY_PRIORITY_YEAR = 'prio_year'
export const KEY_SCORE = 'score'
export const KEY_CPC_CODE = 'cpc_code'
export const KEY_PRIMARY_CPC_CODE = 'primary_cpc_code'
export const KEY_FULL_PRIMARY_CPC_CODE = 'full_cpc_code'
export const KEY_STATUS = 'status'
export const KEY_DEFENDANT = 'defendant'
export const KEY_PLAINTIFF = 'plaintiff'
export const KEY_CROSS = 'cross'
export const KEY_DISPUTED_FAMILIES = 'disputed_families'
export const KEY_OFFEN_VS_DEFEN = 'def_vs_plaintiff'

export const SPEC_KEYS = [
  { id: KEY_ORGANISATION, short_name: 'org', name: 'organisation' },
  { id: KEY_TECHNOLOGY, short_name: 'tech', name: 'technology' },
  { id: KEY_YEAR, name: 'year' },
  { id: KEY_REGION, name:'region' },
  { id: KEY_COUNTRY, name: 'territory' },
  { id: KEY_GRANT_YEAR, name: 'grant year' },
  { id: KEY_PUBLICATION_YEAR, short_name: 'pub year', name: 'publication year' },
  { id: KEY_EXPIRY_YEAR, short_name: 'exp year', name: 'expiry year' },
  { id: KEY_EXPIRY_YEAR_PROJECTED, short_name: 'exp year', name: 'projected expiry year' },
  { id: KEY_PRIORITY_YEAR, short_name: 'prio year', name: 'priority year' },
  { id: KEY_SCORE, name: 'score' },
  { id: KEY_CPC_CODE, name: 'CPC code' },
  { id: KEY_PRIMARY_CPC_CODE, short_name: 'primary CPC', name: 'primary CPC code'},
  { id: KEY_FULL_PRIMARY_CPC_CODE, short_name: 'full primary CPC', name: 'full primary CPC code'},
  { id: KEY_STATUS, name: 'status' },
  
  { id: KEY_DEFENDANT, name: 'defendant-side'},
  { id: KEY_PLAINTIFF, name: 'plaintiff-side'},
  { id: KEY_CROSS, name: 'cross'},
  { id: KEY_DISPUTED_FAMILIES, name: 'families'},
  { id: KEY_OFFEN_VS_DEFEN, short_name: 'offen. vs. defen.', name: 'offensive versus defensive'},
  { id: KEY_FIRST_FILING_COUNTRY, short_name: 'first territory', name: 'first filing territory'},
]

export const SPEC_KEYS_BY_ID = get_as_map(SPEC_KEYS, 'id')
