import { BROWSER } from '../constants/constants.js'

export function is_browser_supported() {
  if (BROWSER && (BROWSER.name === 'ie') && (!BROWSER.version.startsWith('11'))) {
    return false
  }

  return true
}

export function is_IE11() {
  return (BROWSER && (BROWSER.name === 'ie') && BROWSER.version.startsWith('11'))
}

export function is_safari() {
  return (BROWSER && (BROWSER.name === 'safari'))
}