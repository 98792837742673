import { get_as_map, is_array_non_empty_non_null } from '../../../utils/utils.js'

export const NO_FILTER = 'NO_FILTER'
export const REPORTS_FILTER = 'ALL_REPORTS'
export const EVAL_ONLY = 'EVAL_ONLY'
export const SAVED_REPORTS = 'SAVED_REPORTS'
export const TAGGED_REPORTS = 'TAGGED_REPORTS'
export const UNSAVED_REPORTS = 'UNSAVED_REPORTS'
export const UNTAGGED_REPORTS = 'UNTAGGED_REPORTS'
export const PROJECTS_FILTER = 'ALL_PROJECTS'

function is_evaluation_report(report) {
  // evaluation tools are only available to the user if they have edit rights for the classifier
  // assumption: if they created the report, they probably have edit rights for the classifier
  return report.evaluation_classifier_id && report.created_by_user
}

export const REPORT_FILTERS = [
  {
    id: NO_FILTER,
    get_name: ({has_projects}) => has_projects ? 'Projects and reports' : 'All reports',
    can_show: () => true,
    filter_fn: (data_row) => !is_evaluation_report(data_row)
  },
  {
    id: REPORTS_FILTER,
    name: 'Reports only',
    can_show: ({has_projects}) => has_projects,
    filter_fn: (data_row) => data_row.external_report_id && !is_evaluation_report(data_row)
  },
  {
    id: PROJECTS_FILTER,
    name: 'Projects only',
    can_show: ({has_projects}) => has_projects,
    filter_fn: (data_row) => data_row.project_id
  },
  {
    id: SAVED_REPORTS,
    name: 'Saved reports',
    can_show: () => true,
    filter_fn: (report) => report.is_saved && !is_evaluation_report(report)
  },
  {
    id: UNSAVED_REPORTS,
    name: 'Unsaved reports',
    can_show: () => true,
    filter_fn: (report) => report.external_report_id && !report.is_saved && !is_evaluation_report(report)
  },
  {
    id: TAGGED_REPORTS,
    name: 'Tagged reports',
    can_show: () => true,
    filter_fn: (report) => is_array_non_empty_non_null(report.tag_ids) && !is_evaluation_report(report)
  },
  {
    id: UNTAGGED_REPORTS,
    name: 'Untagged reports',
    can_show: () => true,
    filter_fn: (report) => report.external_report_id && !is_array_non_empty_non_null(report.tag_ids) && !is_evaluation_report(report)
  },
  {
    id: EVAL_ONLY,
    name: 'Evaluation reports',
    can_show: ({can_evaluate_classifiers}) => can_evaluate_classifiers,
    filter_fn: (data_row) => is_evaluation_report(data_row)
  }
]

export const ID_TO_REPORT_FILTER = get_as_map(REPORT_FILTERS, 'id')