import React from 'react'
import cn from 'classnames'

import { ExternalLinkIcon } from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'
import { get_dispute_details_link } from '../../utils/disputes_utils.js'

import s from './DisputeLink.module.scss'

export const DisputeLink = ({external_report_id, dispute_id, className}) => {
  return (
    <TextLink element='a' className={cn(s.link, className)} href={get_dispute_details_link({external_report_id, dispute_id})} target='_blank' title='Dispute page'>
      <ExternalLinkIcon/>
    </TextLink>
  )
}