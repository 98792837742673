import { fetch_report_build_status } from './report_reader_utils.js'
import { is_not_found } from './axios_utils.js'
import { poll_choreo_until_report_is_done } from './choreo_utils.js'
import { STATUS_COMPLETED, STATUS_FAILED } from '../model/report_tasks_and_statuses.js'
import { update_report_status } from './report_created_utils.js'
import { send_error_to_sentry } from './sentry_utils.js'

export function is_completed_status(status) {
  return status === STATUS_COMPLETED
}

export function is_failed_status(status) {
  return status === STATUS_FAILED
}

export function check_or_poll_for_final_report_status(internal_report_id, on_status_update) {
  return fetch_report_build_status(internal_report_id)
    .then(build_status => {
      const {status} = build_status
      // final_status is usually populated by the choreo's callback on completion,
      // but in this case it looks like it may be missing, so update the db just in case
      update_status_for_report_history(internal_report_id, status)
      return build_status
    })
    .catch(err => {
      if (is_not_found(err)) {
        // build status table doesn't exist yet; this report is probably still being created
        return poll_choreo_until_report_is_done(internal_report_id, on_status_update)
      }
      throw err
    })
}

function update_status_for_report_history(report_id, status) {
  update_report_status(report_id, status)
    .catch(err => {
      // Not crucial, but send to sentry just in cases
      send_error_to_sentry(err, {})
    })
}