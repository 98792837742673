import {
  LITIGATED_FAMILIES_BY_PORTFOLIO_ID,
  LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  LITIGATED_FAMILIES_BY_TECH_ID,
  LITIGATION_BALANCE_ID,
  LITIGATIONS_ALL_PATFAMS_DISPUTES,
  LITIGATIONS_BY_DEFENDANT_BY_JURISDICTION,
  LITIGATIONS_BY_DEFENDANT_BY_START_YEAR_ID,
  LITIGATIONS_BY_PLAINTIFF_BY_JURISDICTION,
  LITIGATIONS_BY_PLAINTIFF_BY_START_YEAR_ID,
  LITIGATIONS_CROSS_DISPUTES,
  LITIGATIONS_DEFENSIVE_DISPUTES,
  LITIGATIONS_OFFENSIVE_DISPUTES
} from '../spec_ids.js'
import { TAG_GEO, TAG_ORG, TAG_TECH, TAG_TIME } from './spec_tags.js'
import {
  KEY_COUNTRY,
  KEY_CROSS,
  KEY_DEFENDANT,
  KEY_DISPUTED_FAMILIES,
  KEY_OFFEN_VS_DEFEN, KEY_ORGANISATION,
  KEY_PLAINTIFF, KEY_TECHNOLOGY, KEY_YEAR
} from './spec_keys.js'
import { LITIGATION_GROUP_ID } from './spec_group_ids.js'
import {
  DEFAULT_1D_NON_TIMESERIES_VIEW,
  DEFAULT_1D_NON_TIMESERIES_VIEWS,
  DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
  DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
  DEFAULT_2D_NON_TIMESERIES_VIEW,
  DEFAULT_2D_NON_TIMESERIES_VIEWS
} from '../views.js'
import {
  BAR_STACK_ID,
  COLUMN_CLUSTER_ID,
  COLUMN_STACK_ID,
  DISPUTES_TABLE_ID,
  HEATMAP_ID, TABLE_ID
} from '../view_ids.js'
import { get_tabular_view_litigations_total } from '../../utils/tabular_view_utils.js'
import { can_view_dispute_data } from '../../utils/data_availability_utils.js'

const HELP_ARTICLE = '28889498279443-Disputes-charts-datasets'

export const LITIGATION_GROUP =   {
  id: LITIGATION_GROUP_ID,
  name: 'Disputes',
  short_name: 'Disputes',
  help_article: HELP_ARTICLE,
  check_if_available: can_view_dispute_data,
  children: [
    {
      spec_id: LITIGATED_FAMILIES_BY_PORTFOLIO_ID,
      name: 'litigated families',
      description: 'Count of litigated patent families by current owning organisation.',
      help_article: `${HELP_ARTICLE}#h_00c7e68705`,
      by: [KEY_ORGANISATION],
      tags: [TAG_ORG],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW
    },
    {
      spec_id: LITIGATED_FAMILIES_BY_TECH_ID,
      name: 'litigated families',
      description: 'Count of litigated patent families by technology.',
      help_article: `${HELP_ARTICLE}#h_9d556c665b`,
      by: [KEY_TECHNOLOGY],
      tags: [TAG_TECH],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW
    },
    {
      spec_id: LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
      name: 'litigated families',
      description: 'Count of litigated patent families, split by technology and current owning organisation',
      help_article: `${HELP_ARTICLE}#h_88611042e4`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW
    },
    {
      spec_id: LITIGATIONS_BY_DEFENDANT_BY_START_YEAR_ID,
      name: 'defendant litigations',
      short_name: 'def. litigations',
      description: 'Number of litigations per year, where an organisation (or its subsidiaries) is a defendant. Each litigation is only counted by the earliest year we have data for it. This is usually the start year, but may be the end year if only the end date is known.',
      help_article: `${HELP_ARTICLE}#h_8fa2dfc258`,
      by: [KEY_YEAR],
      tags: [TAG_ORG, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
      get_tabular_view_summary: get_tabular_view_litigations_total
    },
    {
      spec_id: LITIGATIONS_BY_PLAINTIFF_BY_START_YEAR_ID,
      name: 'plaintiff litigations',
      short_name: 'plain. litigations',
      description: 'Number of litigations per year, where an organisation (or its subsidiaries) is a plaintiff. Each litigation is only counted by the earliest year we have data for it. This is usually the start year, but may be the end year if only the end date is known.',
      help_article: `${HELP_ARTICLE}#h_1476e7858f`,
      by: [KEY_YEAR],
      tags: [TAG_ORG, TAG_TIME],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_GROUPED_WITH_LINE_VIEW,
      get_tabular_view_summary: get_tabular_view_litigations_total
    },
    {
      spec_id: LITIGATIONS_BY_DEFENDANT_BY_JURISDICTION,
      name: 'defendant litigations',
      short_name: 'def. litigations',
      description: 'Number of litigations per territory, where an organisation (or its subsidiaries) is a defendant.',
      help_article: `${HELP_ARTICLE}#h_e7ae5db226`,
      by: [KEY_COUNTRY],
      tags: [TAG_ORG, TAG_GEO],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
      get_tabular_view_summary: get_tabular_view_litigations_total
    },
    {
      spec_id: LITIGATIONS_BY_PLAINTIFF_BY_JURISDICTION,
      name: 'plaintiff litigations',
      short_name: 'plain. litigations',
      description: 'Number of litigations per territory, where an organisation (or its subsidiaries) is a plaintiff.',
      help_article: `${HELP_ARTICLE}#h_95f21ab3e8`,
      by: [KEY_COUNTRY],
      tags: [TAG_ORG, TAG_GEO],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_VIEW,
      get_tabular_view_summary: get_tabular_view_litigations_total
    },
    {
      spec_id: LITIGATIONS_DEFENSIVE_DISPUTES,
      name: 'disputes',
      description: 'Overview of disputes where an organisation (or its subsidiary) is a defendant. The summary table provides total counts of defendant-side disputes and breaks them down by outcome. The disputes table shows detailed information on each of the disputes.',
      help_article: `${HELP_ARTICLE}#h_e9a58e0062`,
      by: [KEY_DEFENDANT],
      tags: [TAG_ORG],
      available_view_ids: [DISPUTES_TABLE_ID],
      default_view_id: DISPUTES_TABLE_ID,
    },
    {
      spec_id: LITIGATIONS_OFFENSIVE_DISPUTES,
      name: 'disputes',
      description: 'Overview of disputes where an organisation (or its subsidiary) is a plaintiff. The summary table provides total counts of plaintiff-side disputes and breaks them down by outcome. The disputes table shows detailed information on each of the disputes.',
      help_article: `${HELP_ARTICLE}#h_caa707e0c0`,
      by: [KEY_PLAINTIFF],
      tags: [TAG_ORG],
      available_view_ids: [DISPUTES_TABLE_ID],
      default_view_id: DISPUTES_TABLE_ID,
    },
    {
      spec_id: LITIGATIONS_CROSS_DISPUTES,
      name: 'disputes',
      description: 'View of cross disputes between the selected organisations. The summary table provides total counts of disputes. The disputes table shows detailed information on each of the mutual disputes.',
      help_article: `${HELP_ARTICLE}#h_c825ac3e3b`,
      by: [KEY_CROSS],
      tags: [TAG_ORG],
      available_view_ids: [DISPUTES_TABLE_ID],
      default_view_id: DISPUTES_TABLE_ID,
    },
    {
      spec_id: LITIGATIONS_ALL_PATFAMS_DISPUTES,
      name: 'disputes',
      description: 'Overview of disputes with patent families belonging to an organisation. The summary table provides total counts of disputes and breaks them down by outcome. The disputes table shows detailed information on each of the disputes.',
      help_article: `${HELP_ARTICLE}#h_89c3cc0983`,
      by: [KEY_DISPUTED_FAMILIES],
      available_view_ids: [DISPUTES_TABLE_ID],
      default_view_id: DISPUTES_TABLE_ID,
    },
    {
      spec_id: LITIGATION_BALANCE_ID,
      name: 'litigations',
      description: 'Overall count of both plaintiff-side and defendant-side litigations by organisation(s) and its subsidiaries.',
      help_article: `${HELP_ARTICLE}#h_93c3071114`,
      by: [KEY_OFFEN_VS_DEFEN],
      available_view_ids: [COLUMN_STACK_ID, BAR_STACK_ID, COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: COLUMN_STACK_ID,
    }
  ]
}
