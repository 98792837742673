import { get_as_map } from '../../../utils/utils.js'
import { DEFAULT_NUM_TRIALS } from './xg_boost_hyperparams.js'

export const ENGINE_XG_BOOST_V1_ID = 'xgboost-v1'
export const ENGINE_XG_BOOST_V2_ID = 'xgboost-v2'
export const ENGINE_XG_BOOST_V3_ID = 'xgboost-v3'
export const ENGINE_NN_CLASSIFIER_V1_ID = 'nn-classifier-v1'

export const TRIALS = 'trials'
export const NN_K = 'nn_k'
export const NN_NEGATIVES = 'nn_negatives'

export const XG_BOOST_V2_BASE_PARAMS = {
  test_size: 0.2,
}

export function get_state_from_hyperparams(hyperparameters) {
  // Map hyperparams from various engines to the names we will use in container state...

  if (!hyperparameters) {
    return {}
  }

  const { trials, k, negatives } = hyperparameters

  return {
    ...((trials != null)     ? { trials }                  : {}),
    ...((k != null)          ? { nn_k: k }                 : {}),
    ...((negatives != null)  ? { nn_negatives: negatives } : {})
  }
}

const ENGINE_XG_BOOST_V1 = {
  id: ENGINE_XG_BOOST_V1_ID,
  name: 'Standard 2019',
  description: '',
  get_build_params: () => ({ })
}

const ENGINE_XG_BOOST_V2 = {
  id: ENGINE_XG_BOOST_V2_ID,
  name: 'Standard 2021',
  description: '',
  params: [TRIALS],
  get_build_params: (params) => {
    const { trials } = params || {}

    return {
      ...XG_BOOST_V2_BASE_PARAMS,
      hyperparameters: (trials === 0) ? {
        source: 'default'
      } : {
        source:   'search',
        trials:   trials || DEFAULT_NUM_TRIALS,
        folds:    3,
        num_jobs: 4
      }
    }
  },
  get_quick_build_params: (custom_hyperparameters) => {
    return {
      ...XG_BOOST_V2_BASE_PARAMS,
      hyperparameters: custom_hyperparameters ? {
        source: 'custom',
        parameters: custom_hyperparameters
      } : {
        source: 'default'
      }
    }
  },
  get_estimated_build_time: ({ num_patfams, trials }) => {
    // TODO: get a real model!
    return 30 + trials * 1.2 + num_patfams * 0.1
  }
}

const ENGINE_XG_BOOST_V3 = {
  id: ENGINE_XG_BOOST_V3_ID,
  name: 'Standard 2023',
  description: '',
  get_build_params: () => ({ })
}

const ENGINE_NN_CLASSIFIER_V1 = {
  id: ENGINE_NN_CLASSIFIER_V1_ID,
  name: 'Nearest neighbour',
  description: '',
  params: [NN_K, NN_NEGATIVES],
  get_build_params: (params) => {
    const { nn_k, nn_negatives } = params
    return {
      hyperparameters: {
        k: +nn_k, // cast to integer
        score_func: 'meanmax',
        negatives: nn_negatives
      },
    }
  }
}

export const ENGINE_ITEMS = [
  ENGINE_XG_BOOST_V1,
  ENGINE_XG_BOOST_V2,
  ENGINE_XG_BOOST_V3,
  ENGINE_NN_CLASSIFIER_V1,
]

export const ENGINE_ITEM_GROUPS = [
  {
    name: 'Current',
    children: [
      ENGINE_XG_BOOST_V3,
      ENGINE_NN_CLASSIFIER_V1,
    ]
  },
  {
    name: 'Legacy',
    children: [
      ENGINE_XG_BOOST_V2,
      ENGINE_XG_BOOST_V1,
    ]
  }
]

export function is_quick_build_available(latest_built_classifier_metadata, engine_id) {
  if (!latest_built_classifier_metadata) {
    // No previous build
    return false
  }

  const { classifier_engine } = latest_built_classifier_metadata
  if (classifier_engine !== engine_id) {
    // Currently selected engine is different to engine in previous build
    return false
  }

  const engine = ID_TO_ENGINE_ITEM[classifier_engine]
  if (!engine) {
    // This should not happen, but it is possible a dev report has an id for an unreleased engine (on a different code branch)
    return false
  }

  const { get_quick_build_params } = engine
  if (!get_quick_build_params) {
    // Previous engine does not support quick build
    return false
  }

  return true
}

export const ID_TO_ENGINE_ITEM = get_as_map(ENGINE_ITEMS, 'id')