import React, { useState } from 'react'
import HighlightPhrasesContextMenu from './HighlightPhrasesContextMenu.js'


const WithHighlightPhrasesContextMenu = ({
  disable_context_menu,
  phrases_to_highlight,
  on_change_phrases_to_highlight,
  className,
  children
}) => {

  const [phrases_context_menu, set_phrases_context_menu] = useState(null)

  function handle_phrases_context_menu(event) {
    const { shiftKey, ctrlKey, metaKey } = event

    if (shiftKey || ctrlKey || metaKey) {
      return
    }

    const selected_text = window.getSelection().toString()

    if (!selected_text || !selected_text.trim()) {
      return
    }

    event.preventDefault()
    const new_phrases_context_menu = (phrases_context_menu !== null) ? null : {
      mouseX: event.clientX + 2,
      mouseY: event.clientY - 6,
      selected_text: window.getSelection().toString()
    }

    set_phrases_context_menu(new_phrases_context_menu)
  }

  function close_phrases_context_menu() {
    set_phrases_context_menu(null)
  }

  if (!phrases_to_highlight || (disable_context_menu === true)) {
    return (
      <div className={className}>
        {children}
      </div>
    )
  }

  return (
    <div
      onContextMenu={handle_phrases_context_menu}
      className={className}
    >
      {children}

      <HighlightPhrasesContextMenu
        phrases_context_menu={phrases_context_menu}
        close_phrases_context_menu={close_phrases_context_menu}
        phrases_to_highlight={phrases_to_highlight}
        on_change_phrases_to_highlight={on_change_phrases_to_highlight}
      />
    </div>
  )
}

export default WithHighlightPhrasesContextMenu