import React, { Component } from 'react'

import BaseTreemap from './BaseTreemap.js'
import { KEY_COLUMN_IDX_1D } from '../../model/column_data.js'
import {
  get_key_items_sorted_by_value,
  get_keys_to_value,
  get_value,
  get_value_column_idx, IS_NEXT_AGGLOM
} from '../../utils/column_data_utils.js'
import { DEFAULT_1D_COLOR_SCHEME } from '../../constants/colours.js'
import { should_hide_next_agglom_item } from '../../utils/main_items_utils.js'
import TabularView from './TabularView.js'
import { get_csv_string } from '../../utils/csv_utils.js'

class SimpleTreemap extends Component {
  get_items() {
    const { data, key_dims, spec, item } = this.props

    const hide_next = should_hide_next_agglom_item(spec, item, KEY_COLUMN_IDX_1D)

    const key_items = key_dims[KEY_COLUMN_IDX_1D]

    const key_items_filtered = !hide_next ? key_items : key_items.filter(item => (!item[IS_NEXT_AGGLOM]))

    const value_column_idx = get_value_column_idx(data)

    const keys_to_value = get_keys_to_value(data, [KEY_COLUMN_IDX_1D], value_column_idx)

    const key_items_sorted = get_key_items_sorted_by_value(key_items_filtered, keys_to_value)

    const key_items_sorted_with_colours = key_items_sorted
      .map((item, idx) => ({...item, color: item.color || DEFAULT_1D_COLOR_SCHEME[idx % DEFAULT_1D_COLOR_SCHEME.length]})) // add a default colour if none given

    const key_items_sorted_no_zeros = key_items_sorted_with_colours.filter(item => get_value(keys_to_value, [item.id]))

    const items = key_items_sorted_no_zeros.map(item => {

      const { color,  id, name, short_name, is_next_agglom, child_ids } = item

      return {color, id, name, short_name, value: keys_to_value[id] || 0, ...is_next_agglom ? {is_next_agglom, child_ids} : {}}
    })

    //return is_thumbnail ? items.slice(0, 5) : items

    return items
  }

  render() {
    const { is_thumbnail, spec, set_families_subselections, set_selected, selections } = this.props
    const { full_length_value_formatter } = spec
    const items = this.get_items()

    return (
      <BaseTreemap
        is_thumbnail={is_thumbnail}
        items={items}
        value_formatter={full_length_value_formatter}
        spec={spec}
        set_families_subselections={set_families_subselections}
        set_selected={set_selected}
        selections={selections}
      />
    )
  }
}


SimpleTreemap.get_plotly_data = (props) => {
  const {spec} = props || {}
  const data = TabularView.get_csv_value_rows({ ...props, spec: {...spec, hide_totals: true}})
  const export_data = get_csv_string(data)
  return export_data
}

SimpleTreemap.get_csv_value_rows = (props) => {
  const {spec} = props || {}
  return TabularView.get_csv_value_rows({ ...props, spec: {...spec, hide_totals: true}})
}

export default SimpleTreemap