import React from 'react'
import Grid from '../widgets/Grid.js'
import GridElement from '../widgets/GridElement.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import { PatentPanel } from  './PatentPanel.js'

export const PatentsPanel = ({title, patents, patent_link_mode_id}) => {
  return (
    <div>
      <PaneHeader text={title}/>
      <Grid className='mt-3'>
        {patents.map((patent, i) => (
          <GridElement key={i}>
            <PatentPanel patent={patent} patent_link_mode_id={patent_link_mode_id}/>
          </GridElement>
        ))}
      </Grid>
    </div>
  )
}