import _ from 'underscore'
import { extent } from 'd3'

import {DISPUTE} from '../constants/paths.js'
import {LITIGATION, INVALIDITY, PTAB_ROUTES, DISPUTE_TYPE_OPTION_ALL, ID_TO_DISPUTE_TYPE_OPTION} from '../constants/dispute_routes.js'

import {
  calculate_duration,
  format_dispute_status,
  format_string_first_character_capitalised,
  get_as_map,
  get_cipher_hostname
} from './utils.js'
import { fetch_data_from_report_reader } from './report_reader_utils.js'
import { get_patent_families_by_pat_fam_ids } from './domain_utils.js'

import {
  COLUMNS_BY_REPORT_READER_NAME,
  KNOWN_OUTCOMES,
  NPE,
  OPCO,
  OTHER_OUTCOME,
  START_DATE_FIELD,
  get_dispute_filter_values_or_defaults,
  PORTFOLIO_NAME_FIELD,
  PORTFOLIO_ID_FIELD_ID,
  PORTFOLIO_NAME_FIELD_ID,
  TABLE_SUMMARY_TOTAL_FIELD_ID,
  OUTCOMES
} from '../model/disputes.js'
import {
  LITIGATIONS_DEFENSIVE_DISPUTES,
  LITIGATIONS_OFFENSIVE_DISPUTES,
  LITIGATIONS_ALL_PATFAMS_DISPUTES,
  LITIGATIONS_CROSS_DISPUTES
} from '../model/spec_ids.js'
import { ROLE_DEFENDANT, ROLE_EITHER, ROLE_PLAINTIFF, ROLE_ANY } from '../model/dispute_roles.js'

import { get_patent_links } from '../components/widgets/PatentLink.js' // Not great that this utils file is returning JSX. But this is copied over from Cipher2.
import { DESCENDING } from '../model/sort_directions.js'

export const PAGE_VIEW = {id: 'page', name: 'Page view'}
export const MODAL_VIEW = {id: 'modal', name: 'Modal view'}

export const DISPUTE_DETAILS_VIEWS = [PAGE_VIEW, MODAL_VIEW]
export const DISPUTE_DETAILS_VIEWS_BY_ID = get_as_map(DISPUTE_DETAILS_VIEWS)

export const PAGING_THRESHOLD = 21

const STATUS_WON = 'WON'
const STATUS_LOST = 'LOST'

const CURRENT_YEAR = new Date().getFullYear()

const DEFAULT_START_DATE_RANGE = ['1990-01-01', `${CURRENT_YEAR}-12-31`]

export function render_cell(value) {
  return (<span>{(value) ? value : 'None'}</span>)
}

function process_response(response) {
  const {columns, data} = response

  const results = data[0].map((item, i) => {
    let result = {}
    columns.forEach((column, column_id) => {
      const field_name = column.split('.')[1]
      result = {...result, [field_name]: data[column_id][i]}
    })

    return result
  })

  return results
}

export function fetch_dispute_patents(internal_report_id, dispute_id) {
  const query = {
    key: ['DPOx.patent_outcome', 'DP.country_code', 'DP.number', 'DP.kind_code', 'DP.pat_fam_id'],
    constraint: [`= D.dispute_id ${dispute_id}`]
  }

  return fetch_data_from_report_reader(internal_report_id, query)
    .then(rr_response => {
      const patents = process_response(rr_response)

      if (patents.length === 0) {
        return patents
      }

      const pat_fam_ids = patents.map(patent => patent.pat_fam_id)

      // Fetch patent title.
      // Ideally we would do this by (country, patent_number, kind_code), but domain call is not available for this currently.
      // So for now, we fetch the title of the patent family (which is a concatenation of the English patent titles).
      return get_patent_families_by_pat_fam_ids(pat_fam_ids, ['title'])
        .then(pat_fams => {
          const pat_fam_id_to_fam = get_as_map(pat_fams, 'id')
          return patents.map(patent => {
            const pat_fam = pat_fam_id_to_fam[patent.pat_fam_id] || {}
            const title = pat_fam.title || ''
            return {...patent, title}
          })
        })
    })
}

export function fetch_dispute_details(internal_report_id, dispute_id) {
  const query = {
    key: ['D.title', 'D.route', 'D.start_date', 'D.end_date', 'D.docket_number', 'D.jurisdiction'],
    constraint: [`= D.dispute_id ${dispute_id}`]
  }

  return fetch_data_from_report_reader(internal_report_id, query)
    .then(response => {
      const processed_response = process_response(response)

      return processed_response[0]
    })
}

export function fetch_dispute_parties(internal_report_id, dispute_id) {
  const query = {
    key: ['DPO.outcome', 'DPO.end_date', 'DPO.role', 'DLE.name', 'DLE.is_npe'],
    constraint: [`= D.dispute_id ${dispute_id}`]
  }

  return fetch_data_from_report_reader(internal_report_id, query)
    .then(response => {
      return process_response(response)
    })
}

export function fetch_dispute_attachments(internal_report_id, dispute_id) {
  const query = {
    value: ['DA.attachment_name', 'DA.description', 'DA.last_modified'],
    constraint: [`= D.dispute_id ${dispute_id}`],
    sort: ['DESC DA.last_modified']
  }

  return fetch_data_from_report_reader(internal_report_id, query)
    .then(response => {
      return process_response(response)
    })
}

export function format_status_with_role(status, dispute_role) {
  if (dispute_role === ROLE_ANY && [STATUS_LOST, STATUS_WON].indexOf(status) !== -1) {
    return format_dispute_status(`${ROLE_PLAINTIFF} ${status}`)
  }
  return format_dispute_status(status)
}

export function format_dispute_party_name(name, role) {
  if (name && name.length && name !== '-') {
    // eliminate double commas, weird spacing around commas
    return name.replace(/[\s,]+[\s,]+[\s,]/g, ', ')
  }
  return role ? `Unknown ${format_role(role)}` : 'Unknown'
}

export function format_role(role) {
  return format_string_first_character_capitalised(role)
}

export function format_route(route) {
  if (_.contains(PTAB_ROUTES, route)) {
    return `PTAB (${route})`
  } else if (route === LITIGATION) {
      return format_string_first_character_capitalised(route)
  } else if (route === INVALIDITY) {
    return `${format_string_first_character_capitalised(LITIGATION)} (${format_string_first_character_capitalised(route)})`
  }
  return route
}

export function format_is_npe(is_npe) {
  return is_npe === 'false' ? 'Opco' : 'NPE'
}

export function get_dispute_details(data) {
  const {dispute_id, start_date, end_date, docket_number, title, outcome} = data

  return {dispute_id, start_date, end_date, docket_number, title, outcome}
}

export function what_dispute_role_from_spec(spec_id) {
  switch (spec_id) {
    case LITIGATIONS_DEFENSIVE_DISPUTES:
      return ROLE_DEFENDANT
    case LITIGATIONS_OFFENSIVE_DISPUTES:
      return ROLE_PLAINTIFF
    case LITIGATIONS_ALL_PATFAMS_DISPUTES:
      return ROLE_ANY
    case LITIGATIONS_CROSS_DISPUTES:
      return ROLE_EITHER
    default:
      // this isn't a disputes table spec
      return null
  }
}

export function get_default_summary_sort_props(item) {
  const {spec_id} = item
  const dispute_role = what_dispute_role_from_spec(spec_id)
  if (dispute_role && !is_cross_disputes_role(dispute_role)) {
    return [TABLE_SUMMARY_TOTAL_FIELD_ID, DESCENDING]
  }
  return [null, null]
}

export function is_role_defensive(dispute_role) {
  return (dispute_role === ROLE_DEFENDANT)
}

export function is_cross_disputes_role(dispute_role) {
  return dispute_role === ROLE_EITHER
}

export function format_patents(patents) {
  if (!patents) return ''

  return get_patent_links(patents.split(','))
}

export function get_start_dates_extent(data_from_report_reader) {
  const { columns, data } = data_from_report_reader

  // Get index of start date column
  const start_date_column_idx = _.indexOf(columns, START_DATE_FIELD.report_reader_name)
  if (start_date_column_idx === -1) {
    // This should never happen
    throw new Error(`${START_DATE_FIELD.report_reader_name} column not available`)
  }

  const start_dates = data[start_date_column_idx]

  // dates have format YYYY-MM-DD  i.e. '2015-06-03'
  // So the extent here is from-inclusive, and to-inclusive
  // i.e. ['2015-06-03', '2018-11-31']

  const start_dates_non_null = start_dates.filter(date => date != null)
  if (start_dates_non_null.length === 0) {
    // No start dates, so return a default
    return DEFAULT_START_DATE_RANGE
  }

  return extent(start_dates_non_null)
}

export function get_disputes(data_from_report_reader, deref_data) {
  const {columns, data} = data_from_report_reader
  const {selected_portfolios} = deref_data
  const selected_portfolios_by_id = get_as_map(selected_portfolios, 'id')

  const first_column = data[0]

  const disputes = first_column.map((item, i) => {

    let dispute = columns.reduce((dispute, column, column_id) => {
      const field = COLUMNS_BY_REPORT_READER_NAME[column] || {}
      const {id} = field
      const value = (data[column_id][i] == null) ? '' : data[column_id][i]
      return {...dispute, [id]: value.toString()} // TODO: make note in code review - coercing to string is a really bad idea here :( Better to cast each field individually as required. i.e. so can handle booleans, integers etc
    }, {})

    dispute = {
      ...dispute,
      'duration': calculate_duration(dispute.start_date, dispute.end_date),
      'portfolio_name': (selected_portfolios_by_id[dispute.portfolio_id] || {}).name || ''
    }

    if ((columns.indexOf('plaintiff_portfolio_id') !== -1) && dispute.plaintiff_portfolio_id) {
      //it happens when we do cross disputes
      dispute = {
        ...dispute,
        'plaintiff_name': (selected_portfolios_by_id[dispute.plaintiff_portfolio_id] || {}).name || ''
      }
    }

    return dispute
  })

  return disputes
}
export function filter_disputes(disputes, selected_dispute_type_id, dispute_role, plaintiff_type_ids, defendant_type_ids, outcome_ids, start_date_range) {
  const is_filter_on_plaintiff_type = (dispute_role !== ROLE_PLAINTIFF)
  const is_filter_on_defandant_type = (dispute_role !== ROLE_DEFENDANT)
  const is_filter_on_general_type   = (dispute_role === ROLE_PLAINTIFF)

  const [from_start_date, to_start_date] = start_date_range // start_date_range is guaranteed to be non-null (we will always provide a default if there is no start_date data)

  const dispute_type_option = ID_TO_DISPUTE_TYPE_OPTION[selected_dispute_type_id]
  const { routes } = dispute_type_option

  const filtered_disputes = disputes.filter(dispute => {
    const { plaintiff_is_npe, defendant_is_npe, is_npe, outcome, start_date, route } = dispute

    // Type
    if (selected_dispute_type_id !== DISPUTE_TYPE_OPTION_ALL.id) {
      if (!_.contains(routes, route)) {
        return false
      }
    }

    // Plaintiff types
    if (is_filter_on_plaintiff_type) {
      const found_party_id = (plaintiff_is_npe === 'true') ? NPE.id : OPCO.id
      if (!_.contains(plaintiff_type_ids, found_party_id)) {
        return false
      }
    }

    // Defendant types
    if (is_filter_on_defandant_type) {
      const found_party_id = (defendant_is_npe === 'true') ? NPE.id : OPCO.id
      if (!_.contains(defendant_type_ids, found_party_id)) {
        return false
      }
    }

    // General types (presented as Plaintiff type in table)
    if (is_filter_on_general_type) {
      const found_party_id = (is_npe === 'true') ? NPE.id : OPCO.id
      if (!_.contains(plaintiff_type_ids, found_party_id)) {
        return false
      }
    }

    // Outcome
    const found_outcome_id = _.contains(KNOWN_OUTCOMES, outcome) ? outcome : OTHER_OUTCOME
    if (!_.contains(outcome_ids, found_outcome_id)) {
      return false
    }

    // Start date
    if (start_date != null && start_date !== '') {
      if ((from_start_date != null) && (start_date < from_start_date)) {
        return false
      }
      if ((to_start_date != null) && (start_date > to_start_date)) {
        return false
      }
    }

    return true
  })

  return filtered_disputes
}

function get_outcome_breakdown(disputes) {
  const outcomes = disputes.map(item => (item.outcome))

  const npe = disputes.map(item => ((item.plaintiff_is_npe || 'false')))

  const breakdown = {}
  outcomes.forEach(outcome => {
    const known_outcome = KNOWN_OUTCOMES.indexOf(outcome) !== -1 ? outcome : 'OTHER'
    breakdown[known_outcome] = (breakdown[known_outcome] || 0) + 1
  })

  return {...breakdown, total: disputes.length, npe: npe.filter(item => item === 'true').length}
}

export function get_table_outcome_summary_data(data) {
  const disputes_to_portfolios = data.reduce((aggregate, item) => {
    const {portfolio_id} = item || {}
    aggregate[portfolio_id] = [...aggregate[portfolio_id] || [], item]
    return aggregate
  }, {})

  return Object.keys(disputes_to_portfolios).map(portfolio_id => ({portfolio_id, ...get_outcome_breakdown(disputes_to_portfolios[portfolio_id], 'outcome')}))
}

function calculate_cross_disputes(disputes, portfolio_ids, portfolio_id) {
  return portfolio_ids.reduce((row, column) => {
    const row_disputes_as_plaintiff = disputes.filter(dispute => (dispute.portfolio_id === column && dispute.plaintiff_portfolio_id === portfolio_id))
    row[column] = row_disputes_as_plaintiff.length
    return row
  }, {})
}

function get_disputes_by_portfolio(table_data) {
  const disputes = {}

  table_data.forEach(item => {
    const {portfolio_id, plaintiff_portfolio_id} = item || {}
    const disputes_by_portfolio_id = disputes[portfolio_id] || []
    disputes[portfolio_id] = [...disputes_by_portfolio_id, item]

    const disputes_by_plaintiff_portfolio_id = disputes[plaintiff_portfolio_id] || []
    disputes[plaintiff_portfolio_id] = [...disputes_by_plaintiff_portfolio_id, item]
  })

  return disputes
}

export function get_cross_disputes_summary_data(data) {
  const disputes_by_portfolio = get_disputes_by_portfolio(data)
  const portfolio_ids = Object.keys(disputes_by_portfolio)
  return portfolio_ids.map(portfolio_id => ({portfolio_id, ...calculate_cross_disputes(data, portfolio_ids, portfolio_id)}))
}

export function get_disputes_summary_data(data, deref_data, role) {
  const {selected_portfolios} = deref_data || {}
  const selected_portfolios_to_portfolio_id = get_as_map(selected_portfolios || '', 'portfolio_id')

  const get_summary_handler = is_cross_disputes_role(role) ? get_cross_disputes_summary_data : get_table_outcome_summary_data
  const summary = get_summary_handler(data)

  return summary.map(item => {
    const {portfolio_id} = item || {}
    return {...item, portfolio_name: (selected_portfolios_to_portfolio_id[portfolio_id] || {}).name || ''}
  })

}

export function get_dispute_details_link({external_report_id, dispute_id}) {
  return `${get_cipher_hostname()}${DISPUTE}/${external_report_id}/${dispute_id}`
}

export function get_filtered_disputes({data, item, deref_data}) {
  const {spec_id} = item || {}
  const available_start_dates_extent = get_start_dates_extent(data) // always returns a range (or a default), never null
  const { plaintiff_type_ids, defendant_type_ids, outcome_ids, start_date_range, selected_dispute_type } = get_dispute_filter_values_or_defaults(item, available_start_dates_extent)

  const dispute_role = what_dispute_role_from_spec(spec_id)
  const disputes = get_disputes(data, deref_data)

  return filter_disputes(disputes, selected_dispute_type, dispute_role, plaintiff_type_ids, defendant_type_ids, outcome_ids, start_date_range)
}

export function get_disputes_summary_columns_to_export({table_data, dispute_role, disputes, is_expanded}) {
  if (is_cross_disputes_role(dispute_role)) {
    // workaround to make up for additional un-exportable axis labels
    const plaintiff_name_field = {...PORTFOLIO_NAME_FIELD, label: 'Plaintiff (y-axis)'}
    const other_org_columns = table_data.map(item => ({id: item.portfolio_id, field: item.portfolio_id, label: item.portfolio_name}))

    if (is_expanded) {
      return [
        plaintiff_name_field,
        ...other_org_columns
      ]
    }
    const portfolio_ids = get_portfolio_ids_for_cross_disputes_columns({summary: table_data, disputes, is_expanded})
    const filtered_org_columns = other_org_columns.filter(item => _.contains(portfolio_ids, item.id))
    return [
      plaintiff_name_field,
      ...filtered_org_columns
    ]
  }

  const outcome_columns = OUTCOMES.map(field => ({id: field, label: format_status_with_role(field, dispute_role), field}))
  return [
    PORTFOLIO_NAME_FIELD,
    { id: TABLE_SUMMARY_TOTAL_FIELD_ID, label: 'Total', field: TABLE_SUMMARY_TOTAL_FIELD_ID },
    ...outcome_columns,
    ...(is_role_defensive(dispute_role) ? [{id: 'npe', label: 'NPEs', field: 'npe'}] : [])
  ]
}

export function get_portfolio_ids_for_cross_disputes_columns({ summary, disputes, is_expanded }) {
  const portfolio_ids = get_cross_disputes_summary_data(disputes).map(item => item.portfolio_id)

  if (is_expanded) return portfolio_ids

  let non_zero_summary_portfolio_ids = []

  summary.forEach(item => {
    const ids = Object.keys(_.omit(item, PORTFOLIO_ID_FIELD_ID, PORTFOLIO_NAME_FIELD_ID))
    const non_zero_parties = ids.filter(id => item[id] > 0)
    non_zero_summary_portfolio_ids = [...non_zero_summary_portfolio_ids, ...non_zero_parties]
  })

  non_zero_summary_portfolio_ids = _.uniq(non_zero_summary_portfolio_ids)

  return portfolio_ids.filter(item => non_zero_summary_portfolio_ids.indexOf(item) !== -1)
}