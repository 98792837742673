import { is_sets_equal } from '../utils/utils.js'

import { PORTFOLIO_ID } from './portfolios.js'
import { TECH_ID } from './technologies.js'
import { GEO_ID } from './geographies.js'
import { PORTFOLIOS, TECHS, GEOS } from './ref.js'
import { DISPUTE_TYPE_OPTION_ALL, DISPUTE_TYPE_OPTIONS } from '../constants/dispute_routes.js'
import { NO_FILTER_ID } from './time_range_filter_fields.js'
import { NO_DOCDB_COUNTRIES } from '../constants/countries.js'
import { get_report_region_grouping, should_report_show_ungrouped_families } from '../utils/user_settings_utils.js'
import { get_report_region_column } from '../utils/regions_utils.js'

// This file contains all functions for creating / accessing / immutably modifying "user_state" objects.

export function create_new_user_state() {
  return {}
}

function get_minimal(selected_values, all_values) {
  if (is_sets_equal(new Set(selected_values), new Set(all_values))) {
    // All values selected, so no point in specifying
    return null
  }

  return selected_values
}

function get_all_portfolio_ids(ref_data) {
  if (!ref_data) {
    return null
  }
  return ref_data[PORTFOLIOS].map(p => p[PORTFOLIO_ID])
}

function get_not_empty_tech_ids(ref_data) {
  if (!ref_data) {
    return null
  }
  return ref_data[TECHS].filter(t => (!t.is_empty)).map(t => t[TECH_ID])
}

function get_empty_tech_ids(ref_data) {
  if (!ref_data) {
    return null
  }
  return ref_data[TECHS].filter(t => (t.is_empty || (t.size === 0))).map(t => t[TECH_ID])
}

function get_all_geo_ids(ref_data) {
  if (!ref_data) {
    return null
  }
  return ref_data[GEOS].map(g => g[GEO_ID])
}

function get_all_time() {
  return {field_id: NO_FILTER_ID, timerange: null}
}

function get_default_selected_portfolio_ids(ref_data) {
  return get_all_portfolio_ids(ref_data)
}

function get_default_selected_tech_ids(ref_data) {
  return get_not_empty_tech_ids(ref_data)
}

function get_default_selected_geo_ids(ref_data) {
  return get_all_geo_ids(ref_data)
}

function get_default_selected_dispute_type() {
  return DISPUTE_TYPE_OPTION_ALL.id
}

export function get_dispute_type_by_id(dispute_type_id) {
  if (!dispute_type_id) {
    return get_default_selected_dispute_type()
  }
  return DISPUTE_TYPE_OPTIONS.filter(dispute_type => dispute_type.id === dispute_type_id)[0]
}

export function get_selected_portfolio_ids(user_state, ref_data) {
  const { selections } = user_state || {}
  const { selected_portfolio_ids } = selections || {}
  return selected_portfolio_ids || get_default_selected_portfolio_ids(ref_data)
}

export function get_spotlighted_portfolios(user_state) {
  const { selections } = user_state || {}
  const { spotlighted_portfolios } = selections || {}
  return spotlighted_portfolios || {}
}

export function get_spotlighted_techs(user_state) {
  const { selections } = user_state || {}
  const { spotlighted_techs } = selections || {}
  return spotlighted_techs || {}
}

export function get_selected_tech_ids(user_state, ref_data) {
  const { selections } = user_state || {}
  const { selected_tech_ids } = selections || {}

  if (selected_tech_ids) {
    const empty_tech_ids = get_empty_tech_ids(ref_data) || []
    return selected_tech_ids.filter(id => (empty_tech_ids.indexOf(id) === -1))
  }

  return get_default_selected_tech_ids(ref_data)
}

export function get_selected_geo_ids(user_state, ref_data, user_settings) {
  const { selections } = user_state || {}

  const show_ungrouped_families = should_report_show_ungrouped_families(user_state, user_settings)

  const { selected_geo_ids } = selections || {}

  const geo_ids = selected_geo_ids || get_default_selected_geo_ids(ref_data)
  return geo_ids && !show_ungrouped_families ? geo_ids.filter(item => NO_DOCDB_COUNTRIES.indexOf(item) === -1) : geo_ids
}

export function get_selected_timerange(user_state) {
  const { selections } = user_state || {}
  const { selected_timerange } = selections || {}

  return selected_timerange || get_all_time()
}

export function get_selections(user_state, ref_data, user_settings) {
  const region_grouping = get_report_region_grouping(user_settings)
  const report_region_column = get_report_region_column(region_grouping)

  return {
    selected_portfolio_ids:    get_selected_portfolio_ids(user_state, ref_data),
    selected_tech_ids:         get_selected_tech_ids(user_state, ref_data),
    selected_geo_ids:          get_selected_geo_ids(user_state, ref_data, user_settings),
    selected_timerange:        get_selected_timerange(user_state),
    spotlighted_portfolios: get_spotlighted_portfolios(user_state),
    spotlighted_techs:      get_spotlighted_techs(user_state),
    report_region_column
  }
}

export function get_selections_minimal(user_state, ref_data, user_settings) {
  const all_portfolio_ids = get_all_portfolio_ids(ref_data)
  const all_tech_ids      = get_not_empty_tech_ids(ref_data)
  const all_geo_ids       = get_all_geo_ids(ref_data)
  const all_time          = get_all_time()

  const selected_portfolio_ids = get_selected_portfolio_ids(user_state, ref_data)
  const selected_tech_ids      = get_selected_tech_ids(user_state, ref_data)
  const selected_geo_ids       = get_selected_geo_ids(user_state, ref_data, user_settings)
  const selected_timerange     = get_selected_timerange(user_state)

  const minimal_selected_portfolio_ids = get_minimal(selected_portfolio_ids, all_portfolio_ids)
  const minimal_selected_tech_ids      = get_minimal(selected_tech_ids, all_tech_ids)
  const minimal_selected_geo_ids       = get_minimal(selected_geo_ids, all_geo_ids)
  const minimal_selected_timerange     = selected_timerange.field_id === all_time.field_id ? null : selected_timerange

  const region_grouping = get_report_region_grouping(user_settings)
  const report_region_column = get_report_region_column(region_grouping)

  return {
    selected_portfolio_ids: minimal_selected_portfolio_ids,
    selected_tech_ids:      minimal_selected_tech_ids,
    selected_geo_ids:       minimal_selected_geo_ids,
    selected_timerange:     minimal_selected_timerange,
    report_region_column
  }
}

export function get_selections_minimal_for_main_item(minimal_selections, item_selections, ref_data) {

  const { selected_portfolio_ids, selected_tech_ids, selected_geo_ids, report_region_column } = item_selections

  const all_portfolio_ids = get_all_portfolio_ids(ref_data)
  const all_tech_ids      = get_not_empty_tech_ids(ref_data)
  const all_geo_ids       = get_all_geo_ids(ref_data)

  const minimal_selected_portfolio_ids = get_minimal(selected_portfolio_ids, all_portfolio_ids)
  const minimal_selected_tech_ids      = get_minimal(selected_tech_ids, all_tech_ids)
  const minimal_selected_geo_ids       = get_minimal(selected_geo_ids, all_geo_ids)

  return {
    ...minimal_selections,
    selected_portfolio_ids: minimal_selected_portfolio_ids,
    selected_tech_ids:      minimal_selected_tech_ids,
    selected_geo_ids:       minimal_selected_geo_ids,
    ...(report_region_column) ? {report_region_column} : {}
  }
}

export function with_selected_portfolio_ids(user_state, selected_portfolio_ids) {
  const { selections, ...other } = user_state

  return {
    selections: { ...selections, selected_portfolio_ids },
    ...other
  }
}

export function with_selected_tech_ids(user_state, selected_tech_ids) {
  const { selections, ...other } = user_state

  return {
    selections: { ...selections, selected_tech_ids },
    ...other
  }
}

export function with_selected_geo_ids(user_state, selected_geo_ids) {
  const { selections, ...other } = user_state

  return {
    selections: { ...selections, selected_geo_ids },
    ...other
  }
}

export function with_selected_timerange(user_state, selected_timerange) {
  const { selections, ...other } = user_state

  return {
    selections: { ...selections, selected_timerange },
    ...other
  }
}

export function with_spotlighted_portfolios(user_state, spotlighted_portfolios) {
  const { selections, ...other } = user_state

  return {
    selections: { ...selections, spotlighted_portfolios },
    ...other
  }
}

export function with_spotlighted_techs(user_state, spotlighted_techs) {
  const { selections, ...other } = user_state

  return {
    selections: { ...selections, spotlighted_techs },
    ...other
  }
}

export function with_main_items(user_state, main_items) {
  return {
    ...user_state,
    main_items
  }
}
