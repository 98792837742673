import { color, hsl } from 'd3'

const WHITE = hsl('#fff').toString()

function hex(value) {
  const value_round = Math.max(0, Math.min(255, Math.round(value) || 0));
  return (value_round < 16 ? "0" : "") + value_round.toString(16);
}

/**
 * @param {} d3_color_string i.e. "rgb(255,0,0)"
 * @returns {} hex color string i.e. "#ff0000"
 */
export function to_hex_string(d3_color_string) {
  if (!d3_color_string) {
    return null
  }
  const color_obj = color(d3_color_string)
  return "#" + hex(color_obj.r) + hex(color_obj.g) + hex(color_obj.b)
}

export function add_alpha(color, opacity) {
  //add opacity
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}

export function remove_alpha(color) {
  //remove opacity
  return color.slice(0, 6)
}

/**
 * Finds lighter shades of a given colour, using d3:
 * https://d3-wiki.readthedocs.io/zh_CN/master/Colors/#hsl
 * Parameter steps_from_lightest allows us to specify the degree of lightening. 0 steps will return the lightest
 * version of the colour, while 2 steps, for eg, will return the third lightest. If the given colour is already quite light,
 * the step value may result in a darker version instead
 */
export function lighten_color(hex, steps_from_lightest= 0) {
  const step_increments = 3
  const increment = 0.1 // the increment we are lightening by
  const poss_increments = get_max_lightening_steps(hex, increment)

  if (poss_increments > 0) {
    const offset = ((steps_from_lightest + 1) * step_increments) * increment
    return hsl(hex).brighter(poss_increments - offset).toString()
  }
  return hex
}

export function lighten_color_by_percentage(hex, perc) {
  const increment = 0.1
  const poss_increments = get_max_lightening_steps(hex, increment)
  if (poss_increments > 0) {
    const brighten_by = (poss_increments / 100) * perc
    const rgb_string = hsl(hex).brighter(brighten_by).toString()
    return convert_rgb_string_to_hex(rgb_string)
  }
  return hex
}

function convert_rgb_string_to_hex(rgb_string) {
  const [red, green, blue] = rgb_string.split('(')[1].split(')')[0].split(',')
  return rgb_2_hex(red, green, blue)
}

function rgb_2_hex(red, green, blue) {
  const [hex_red, hex_green, hex_blue] = [red, green, blue].map(c => {
    const hex = parseInt(c).toString(16)
    if (hex.length === 1 ) {
      return `0${hex}`
    }

    return hex
  })

  return `#${hex_red}${hex_green}${hex_blue}`
}

function get_max_lightening_steps(hex, increment=0.1) {
  let increments = 0
  let lightened = hsl(hex).toString()

  // lighten the given colour in increments until we can lighten no more
  while (lightened !== WHITE) {
    lightened = hsl(hex).brighter(increments).toString()
    increments += increment
  }

  return increments - increment
}