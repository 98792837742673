import React from 'react'
import cn from 'classnames'

import Footer from './ExecutiveSummaryFooter.js'
import Columns from './ExecutiveSummaryColumns.js'
import Landscape from './ExecutiveSummaryLandscape.js'
import Summaries from './ExecutiveSummarySummaries.js'
import { ExecutiveSummaryGuideLink } from './ExecutiveSummaryGuideLink.js'
import TextLink from '../widgets/TextLink.js'
import { GROWTH_TIME_SPAN } from './utils/executive_summary_constants.js'

import s from './ExecutiveSummaryReport.module.scss'

class ExecutiveSummaryReport extends React.Component {
  constructor(props) {
    super(props)
    this.svg_ref = React.createRef()
  }

  render() {
    const { data, download_handler} = this.props
    const { companies, granted_size_by_portfolio, report_year, report_date } = data || {}
    return (
        <div>
          <div className='d-flex justify-content-between'>
            <TextLink onClick={download_handler}>Download summary</TextLink>
            <ExecutiveSummaryGuideLink />
          </div>
          <div className={s.wrapper}>
            <div className={cn('mx-auto', s.inner_wrapper)} ref={this.svg_ref}>
              <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0' y='0' width='900' height='1275'> {/* width and height correspond with wrapper style settings*/}
                <g transform='translate(30, 55)'>
                  <g transform='translate(100, 0)'>
                    <Summaries data={data}/>
                  </g>
                  <g transform='translate(453, 0)'>
                    <g transform='translate(20, 0)'>
                      <Landscape
                       companies={companies}
                       portfolio={granted_size_by_portfolio}
                       width={330}
                       height={238}
                       years={{
                         fromYear: report_year - GROWTH_TIME_SPAN,
                         toYear: report_year + 1
                       }}
                      />
                    </g>
                  </g>
                </g>
                <g transform='translate(30, 350)'>
                 <Columns
                   data={data}
                 />
                </g>
                <g transform='translate(30, 1190)'>
                  <Footer
                    date={report_date}
                    year={report_year}
                  />
                </g>
              </svg>
            </div>
          </div>
      </div>
    )
  }
}

export default ExecutiveSummaryReport