import { is_dev_environment, is_tech_discovery } from './utils.js'
import {
  CIPHER_OT_SITE_ID,
  PERFORMANCE_COOKIES_DISABLED_LS_KEY,
  TECHDISCOVERY_OT_SITE_ID
} from '../constants/onetrust.js'
import { get_from_local_storage } from './local_storage_utils.js'

export function launch_cookie_manager() {
  const otConsentSdk = document.getElementById('onetrust-consent-sdk')

  //workaround so we can display the banner on every click without refreshing the page; looks like a knows OT problem

  if (otConsentSdk) {
    otConsentSdk.remove()
  }

  const one_trust = window.OneTrust

  if (!one_trust) return

  one_trust.Init()
  one_trust.LoadBanner()
  one_trust.ToggleInfoDisplay()
}

export function has_performance_cookies_disabled() {
  const disabled = get_from_local_storage(PERFORMANCE_COOKIES_DISABLED_LS_KEY)

  return disabled != null ? disabled : false
}

export function init_onetrust_scripts() {
  const ot_site_id = `${is_tech_discovery() ? TECHDISCOVERY_OT_SITE_ID : CIPHER_OT_SITE_ID}${is_dev_environment() ? '-test' : ''}`
  const script = document.getElementById('ot_sdk_stub')

  if (script) {
    script.src = `https://cdn.cookielaw.org/scripttemplates/otSDKStub.js`
    script.setAttribute('data-domain-script', ot_site_id)
    script.removeAttribute('id')
  }
}
