import moment from 'moment/moment.js'
import _ from 'underscore'
import axios from 'axios'

import {
  GOOGLE_VALUATION_REPORT_STREAM_ID,
  ID_TO_REPORT_STREAM,
  ND_REPORT_STREAM_ID,
  NEW_FILINGS_IN_TECH_STREAM_ID,
  SPIF_REPORT_STREAM_ID,
  TECH_LANDSCAPE_STREAMS
} from './report_streams.js'
import { get_boolean_search_as_portfolio_item } from '../../../model/portfolio_basket.js'
import { get_short_date_string_for_now } from '../../../utils/time_utils.js'
import { get_as_map, is_int } from '../../../utils/utils.js'
import {
  ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION,
  ACCESSION_DATE_CUT_OFF_OPTIONS,
  STEP_PORTFOLIO_ORG_LIST
} from './builder_wizard.js'
import {
  get_technology_partitioning_by_classifiers, get_technology_partitioning_by_clustering,
  get_technology_partitioning_by_utt,
  TECH_PARTITIONING_TYPE_CLASSIFIER,
} from '../../../model/technology_basket.js'
import { CREATE_REPORT } from '../../../constants/paths.js'
import { UTT_GROUP_NAME } from '../../../constants/utt.js'

import { REPORT_STREAM_BASE_URL } from '../../../constants/urls.js'
import {
  get_leaf_nodes_as_array,
  get_tree_node_by_path,
  is_node_fully_selected
} from '../../../utils/classifier_tree_utils.js'
import { UNCATEGORISED_GROUP_NAME } from '../../../utils/classifier_group_utils.js'
import { get_short_company_name } from '../../../utils/name_utils.js'

export const STREAM_PARAMS_DELIMITER = '_'

export function get_accession_start_date_by_days(days_count=ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION) {
  return moment().subtract(days_count, 'days').format('YYYY-MM-DD')
}

export function get_accession_date_portfolio_item(param) {
  const days_count_param = param * 1
  const threshold = is_int(days_count_param) && ACCESSION_DATE_CUT_OFF_OPTIONS.indexOf(days_count_param) > -1 ? days_count_param : ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION

  const portfolio_item = get_boolean_search_as_portfolio_item(`accession_date:[${get_accession_start_date_by_days(threshold)} TO ${get_short_date_string_for_now()}]`)

  return {...portfolio_item, name: `${days_count_param} days`}
}

const NO_PATH = 'no_path'
const THRESHOLD = 3

function get_classifiers_node_name_if_applicable(selected_classifiers=[], classifiers_group={}) {
  const {path=[]} = selected_classifiers[0] || {}

  const has_group = !_.isEmpty(classifiers_group)

  const { children = [] } = has_group ? (get_tree_node_by_path(classifiers_group, path.slice(1)) || {}) : {}

  if (
    (selected_classifiers.length > THRESHOLD) ||
    (children.length === selected_classifiers.length)
  ) {
   return path[path.length - 1]
  }

  return null
}

function has_full_group_selected(subgroup, group) {
  const has_group = !_.isEmpty(group)

  if (!has_group) return false

  const subgroup_classifier_ids = _.flatten(_.values(subgroup)).map(item => item.classifier_id)
  const all_classifier_ids = get_leaf_nodes_as_array(group).map(item => item.classifier_id)

  return is_node_fully_selected(subgroup_classifier_ids, all_classifier_ids)
}

function get_classifier_names_for_report_name(classifiers, classifier_groups) {
  const classifier_groups_by_name = get_as_map(classifier_groups || [], 'name')
  const classifiers_list = classifiers || []

  if (classifiers_list.length <= THRESHOLD - 1) return classifiers_list.map(item => item.name || '')

  const paths_to_classifiers = classifiers_list.reduce((acc, item) => {
    const { path=[] } = item
    const group_top_node = path[0] || NO_PATH
    const path_str = path.join('_')
    const key = path_str === '' ? NO_PATH : path_str

    const group = acc[group_top_node] || {}

    return {
      ...acc,
      [group_top_node]: { ...group, [key]: [...group[key] || [], item] }
    }
  }, {})

  const classifier_names = []
  const node_names = []

  Object.keys(paths_to_classifiers).forEach(group_name => {
    const classifiers_group = (classifier_groups_by_name || {})[group_name]
    const subgroup = paths_to_classifiers[group_name] || {}

    if (has_full_group_selected(subgroup, classifiers_group)) {
      node_names.push(group_name)
      return
    }

    Object.keys(subgroup).forEach(key => {

      const same_path_classifiers = subgroup[key] || []
      const same_path_classifiers_names = same_path_classifiers.map(item => item.name)

      if (
        _.contains([NO_PATH, UNCATEGORISED_GROUP_NAME], key) ||
        (same_path_classifiers.length < (THRESHOLD - 1))
      ) {
        same_path_classifiers_names.forEach(item => classifier_names.push(item))
        return
      }

      const {path=[]} = same_path_classifiers[0] || {}
      const group_top_node = path[0]
      const group = (classifier_groups_by_name || {})[group_top_node]

      const classifier_tree_node_name = get_classifiers_node_name_if_applicable(same_path_classifiers, group)

      if (classifier_tree_node_name) {
        node_names.push(classifier_tree_node_name)
      } else {
        same_path_classifiers_names.forEach(item => classifier_names.push(item))
      }

    })
  })

  return [...node_names, ...classifier_names]
}

export function get_report_name({stream_id, portfolios, technology_partitioning, classifiers, classifier_groups, user_report_name, portfolio_type}) {
  const portfolio_names = _.uniq((portfolios || []).map(item => {
    const {name, company_list} = item
    return (portfolio_type === STEP_PORTFOLIO_ORG_LIST ? company_list : get_short_company_name(name)) || ''
  }))

  const classifier_names = get_classifier_names_for_report_name(classifiers, classifier_groups)
  const {get_default_report_name} = ID_TO_REPORT_STREAM[stream_id] || {}

  const auto_report_name = get_default_report_name ? get_default_report_name({portfolios, portfolio_names, technology_partitioning, classifier_names}) : 'New report'

  return user_report_name || auto_report_name
}

export function should_select_technology_partitioning({stream_id, user_has_utt, user_has_classifiers}) {
  if ([SPIF_REPORT_STREAM_ID, GOOGLE_VALUATION_REPORT_STREAM_ID].indexOf(stream_id) > -1) return false

  const can_select = user_has_utt || user_has_classifiers

  return can_select
}

export function get_stream_technology_partitioning({stream_id, user_has_classifiers, user_has_utt, utt_version}) {
  if (SPIF_REPORT_STREAM_ID === stream_id) {
    return user_has_utt ? get_technology_partitioning_by_utt({utt_version}) : get_technology_partitioning_by_clustering()
  }

  if ([ND_REPORT_STREAM_ID, GOOGLE_VALUATION_REPORT_STREAM_ID].indexOf(stream_id) > -1) {
    return { type: TECH_PARTITIONING_TYPE_CLASSIFIER }
  }

  return user_has_utt ? get_technology_partitioning_by_utt({utt_version}) :
    (user_has_classifiers ? get_technology_partitioning_by_classifiers() : get_technology_partitioning_by_clustering())
}

export function get_stream_url(stream_id, params) {
  const {portfolio_type, landscape_tech_type, accession_date_threshold} = params || {}

  const stream_id_url = `${CREATE_REPORT}/${stream_id}`

  if (_.isEmpty(params)) {
    return stream_id_url
  }

  const portfolio_type_url_part = portfolio_type ? `${STREAM_PARAMS_DELIMITER}${portfolio_type}` : ''
  const tech_type_url_part = landscape_tech_type ? `${STREAM_PARAMS_DELIMITER}${landscape_tech_type}` : ''
  const accession_date_url_part = stream_id === NEW_FILINGS_IN_TECH_STREAM_ID ? `${STREAM_PARAMS_DELIMITER}${accession_date_threshold || ACCESSION_DATE_CUT_OFF_DEFAULT_OPTION}`: ''

  const url_params = `${tech_type_url_part}${portfolio_type_url_part}${accession_date_url_part}`.slice(1)

  return `${stream_id_url}/${url_params}`
}

export function get_classifiers_group_names(classifiers) {
  return _.uniq((classifiers || []).map(item => {
    const {path} = item

    return (path || [])[0] || ''
  }))
}

export function is_utt_among_classifiers(classifiers) {
  if ((classifiers || []).length === 0) return false

  return get_classifiers_group_names(classifiers).indexOf(UTT_GROUP_NAME) !== -1
}

export function get_all_available_taxonomy_ids(classifier_groups) {
  if (!classifier_groups) return []

  return classifier_groups.map(group => group.id)
}

export function save_stream_input(request_data, input_id) {
  const data = {request_data, id: input_id}
  return axios.post(`${REPORT_STREAM_BASE_URL}/save_input`, data)
    .then(response => {
      const { id } = response.data || {}
      return id
    })
}

export function is_tech_landscape_stream(stream_id) {
  return _.contains(TECH_LANDSCAPE_STREAMS, stream_id)
}