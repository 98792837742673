import React from 'react'
import { Switch } from 'react-router-dom'

import { ADMIN, CHART_SETS, CREATE_USERS, FTAGS_UI, ROLE_OVERVIEW, USER_SETTINGS } from '../../constants/paths.js'
import {RouteWithTracing} from '../RouteWithTracing'
import ChartSetsSetup from './chart_sets/ChartSetsSetup.js'
import UserManagement from './UserManagement.js'
import UserSettingsManagement from './UserSettingsManagement.js'
import AdminLanding from './AdminLanding.js'
import FamilyTagsAdmin from './FamilyTagsAdmin'
import RoleOverview from './RoleOverview.js'

const AdminRoutes = () => {
  return (
    <Switch>
      <RouteWithTracing exact path={`${ADMIN}${CHART_SETS}`} component={ChartSetsSetup} />
      <RouteWithTracing exact path={`${ADMIN}${USER_SETTINGS}`} component={UserSettingsManagement} />
      <RouteWithTracing exact path={`${ADMIN}${CREATE_USERS}`} component={UserManagement} />
      <RouteWithTracing exact path={`${ADMIN}${ROLE_OVERVIEW}`} component={RoleOverview} />
      <RouteWithTracing exact path={`${ADMIN}${FTAGS_UI}`} component={FamilyTagsAdmin} />
      <RouteWithTracing exact path={ADMIN} component={AdminLanding}/>
    </Switch>
  )
}

export default AdminRoutes