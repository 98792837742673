// TEMPORARY - TODO: remove once Classifiers uses camelCase patfam format

export const PAT_FAM_ID_FIELD_ID            = 'id'
export const OWNERS_FIELDS_ID               = 'owners'
export const ASSIGNEES_FIELD_ID             = 'assignees'
export const PATENT_NUMBERS_FIELD_ID        = 'patentNumbers'
export const TITLE_FIELD_ID                 = 'title'
export const ABSTRACT_FIELD_ID              = 'abstract'
export const STATUS_FIELD_ID                = 'status'
export const PENDING_TERRITORIES_FIELD_ID   = 'pendingTerritories'
export const GRANTED_TERRITORIES_FIELD_ID   = 'grantedTerritories'
export const EXPIRED_TERRITORIES_FIELD_ID   = 'expiredTerritories'
export const PRIORITY_DATE_FIELD_ID         = 'priorityDate'
export const GRANTED_DATE_FIELD_ID          = 'grantedDate'
export const PUBLICATION_DATE_FIELD_ID      = 'publicationDate'
export const EXPIRY_DATE_FIELD_ID           = 'expiryDate'
export const CPC_CODES_FIELD_ID             = 'cpcCodes'
//export const INVENTORS_FIELD_ID             = 'inventors'
export const SCORE_ID                       = 'score'
export const USER_CLASS_ID                  = 'user_class'

export const ALL_FIELD_IDS = [
  PAT_FAM_ID_FIELD_ID,
  OWNERS_FIELDS_ID,
  ASSIGNEES_FIELD_ID,
  PATENT_NUMBERS_FIELD_ID,
  TITLE_FIELD_ID,
  ABSTRACT_FIELD_ID,
  STATUS_FIELD_ID,
  PENDING_TERRITORIES_FIELD_ID,
  GRANTED_TERRITORIES_FIELD_ID,
  EXPIRED_TERRITORIES_FIELD_ID,
  PRIORITY_DATE_FIELD_ID,
  GRANTED_DATE_FIELD_ID,
  PUBLICATION_DATE_FIELD_ID,
  EXPIRY_DATE_FIELD_ID,
  CPC_CODES_FIELD_ID,
  //INVENTORS_FIELD_ID,
  SCORE_ID,
  USER_CLASS_ID
]