import React from 'react'
import ClassifierAlertSubscriptions from './ClassifierAlertSubscriptions.js'
import { RouteWithTracing } from '../RouteWithTracing.js'
import { Redirect } from 'react-router-dom'
import { remove_trailing_slashes } from '../../utils/history_utils.js'
import { withRouter } from 'react-router-dom'
import ReportBasedAlertSubscriptions from './ReportBasedAlertSubscriptions.js'
import { CLASSIFIERS, REPORTS } from '../../constants/paths.js'

const AlertSubscriptionsContainer = ({match}) => {

  const base_path = remove_trailing_slashes(match.url)

  return (
   <>
     <RouteWithTracing
       path={base_path}
       exact
       render={() => (<Redirect to={`${base_path}${REPORTS}`}/>)}
     />

     <RouteWithTracing
       path={`${base_path}${REPORTS}`}
       render={() => (<ReportBasedAlertSubscriptions/>)}
     />

     <RouteWithTracing
       path={`${base_path}${CLASSIFIERS}`}
       render={() => (<ClassifierAlertSubscriptions/>)}
     />
   </>
  )
}

export default withRouter(AlertSubscriptionsContainer)