import React from 'react'
import cn from 'classnames'

import { PatentLink } from '../widgets/PatentLink.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import { format_dispute_status } from '../../utils/utils.js'

import s from './PatentPanel.module.scss'

export const PatentPanel = ({patent, patent_link_mode_id}) => {
  const { patent_outcome, patent_number, country_code, number, kind_code, title } = patent

  return (
    <div className={cn('p-3', s.block)}>
      <div className='d-flex justify-content-between mb-1'>
        <PatentLink
          patent_number={patent_number || country_code+number+kind_code}
          patent_link_mode_id={patent_link_mode_id}
        />
        <div>
          <PaneHeader text='Outcome' />
          <div>{format_dispute_status(patent_outcome)}</div>
        </div>
      </div>
      <div>{title}</div>
    </div>
  )
}