import React from 'react'

import IndirectlySelectedMarker from './IndirectlySelectedMarker.js'

const IndirectlySelectedHint = ({className}) => {
  return (
    <div className={className}>
      <IndirectlySelectedMarker /> classifiers selected as part of a different taxonomy
    </div>
  )
}

export default IndirectlySelectedHint