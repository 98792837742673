import React, { useState } from 'react'

import { is_tech_discovery } from '../utils/utils.js'
import TechDiscoveryUserAuthorisationLoader from './TechDiscoveryUserAuthorisationLoader.js'
import App from './App.js'
import { remove_from_local_storage } from '../utils/auth_utils.js'
import { LOGOUT } from '../constants/paths.js'

const AppContainer = ({ kc, history, tracker }) => {
  const [tech_discovery__wam_details, set_tech_discovery__wam_details] = useState(null)

  function logout() {
    remove_from_local_storage()
    kc.logout({redirectUri: origin}) // Note no trailing "/", as WAM (TechDiscovery) does not accept this.
  }


  const { pathname } = window.location
  const is_logout_page = (pathname === LOGOUT)
  if (is_logout_page) {
    logout()
    return null
  }

  const is_tech_discovery_context = is_tech_discovery()

  if (is_tech_discovery_context && !tech_discovery__wam_details) {
    return (
      <TechDiscoveryUserAuthorisationLoader
        id_token={kc.idToken}
        user_perm_id={kc.idTokenParsed.sub}
        set_tech_discovery__wam_details={set_tech_discovery__wam_details}
        on_logout={logout}
      />
    )
  }

  return (
    <App
      kc={kc}
      history={history}
      tracker={tracker}
      tech_discovery__wam_details={tech_discovery__wam_details}
    />
  )
}

export default AppContainer