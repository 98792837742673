import React from 'react'
import _ from 'underscore'
import * as d3 from 'd3'

import { EXECUTIVE_SUMMARY_COLOURS } from '../utils/executive_summary_constants.js'

const TOP_MARGIN = 5

class MiniAreaChart extends React.Component{

  constructor(props) {
    super(props)
    this.container_ref = React.createRef()
  }

  componentDidMount() {
    const containerWidth  = this.props.width || 100,
          containerHeight = this.props.height || 100,
          values          = this.props.values,
          colours         = this.props.colours || EXECUTIVE_SUMMARY_COLOURS,
          y_domain        = this.props.y_domain

    // Get max value (we'll use this for setting the domain)
    const max_value = d3.max(_.flatten(values))

    const num_categories = values[0].length

    // Render chart
    const margin = {top: TOP_MARGIN, right: 0, bottom: 0, left: 0},
        width = containerWidth - margin.left - margin.right,
        height = containerHeight - margin.top - margin.bottom

    const x = d3.scaleLinear()
        .range([0, width])

    const y = d3.scaleLinear()
        .range([height, 0])

    const area = d3.area()
          .x(function(d, i) { return x(i) })
          .y0(function() { return y(0) })
          .y1(function(d) { return y(d) })

    const container_node = this.container_ref.current
    const canvas = d3.select(container_node)

    x.domain([0, num_categories - 1])
    y.domain(y_domain || [0, max_value])

    const layer = canvas.append('g')
      .selectAll('.layer')
      .data(values)
      .enter().append('g')
        .attr('class', 'layer')
        .style('fill', function(d, i) { return colours[i] })
        .style('stroke', 'none')

    layer.append('path')
        .attr('class', 'line')
        .attr('d', function(d) { return area(d) })
  }

  render() {
    return (
      <g transform={'translate(0, ' + TOP_MARGIN + ')'} ref={this.container_ref} className='mini-area-mirror-chart'/>
    )
  }
}

export default MiniAreaChart
