export const ZERO_KEY = 48
export const ONE_KEY = 49
export const TWO_KEY = 50
export const THREE_KEY = 51
export const FOUR_KEY = 52
export const FIVE_KEY = 53
export const SEVEN_KEY = 55
export const EIGHT_KEY = 56
export const NINE_KEY = 57

export const ZERO_NUMERIC_KEY = 96
export const ONE_NUMERIC_KEY = 97
export const TWO_NUMERIC_KEY = 98
export const THREE_NUMERIC_KEY = 99
export const FOUR_NUMERIC_KEY = 100
export const FIVE_NUMERIC_KEY = 101

export const LEFT_KEY = 37
export const UP_KEY = 38
export const RIGHT_KEY = 39
export const DOWN_KEY = 40

export const DELETE_KEY = 46
export const BACKSPACE_KEY = 8
export const ENTER_KEY = 13
export const ENTER_CODE = 'Enter'

export const F1_KEY = 112
export const F2_KEY = 113
export const F3_KEY = 114
export const F4_KEY = 115
export const F5_KEY = 116
export const F6_KEY = 117
export const F7_KEY = 118

export const A_KEY = 65
export const B_KEY = 66
export const D_KEY = 68
export const I_KEY = 73
export const R_KEY = 82
export const S_KEY = 83

export const LABEL_AS_POSITIVE_KEYS = [ONE_KEY, ONE_NUMERIC_KEY]
export const LABEL_AS_NEGATIVE_KEYS = [TWO_KEY, TWO_NUMERIC_KEY]
export const LABEL_AS_IGNORE_KEYS   = [THREE_KEY, THREE_NUMERIC_KEY]
export const LABEL_AS_TEST_POSITIVE_KEYS = [FOUR_KEY, FOUR_NUMERIC_KEY]
export const LABEL_AS_TEST_NEGATIVE_KEYS = [FIVE_KEY, FIVE_NUMERIC_KEY]
export const REMOVE_LABEL_KEYS      = [ZERO_KEY, ZERO_NUMERIC_KEY]

export const TOGGLE_DESCRIPTION_KEY = D_KEY
export const TOGGLE_ABSTRACT_KEY = A_KEY