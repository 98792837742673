import * as d3 from 'd3'
import _ from 'underscore'

import { get_as_map } from '../../../utils/utils.js'
import * as ID_TO_CURRENCY from '../../../model/currencies.js'
import { convert_svg_to_pdf } from '../../../utils/download_utils.js'

import { CLUSTER_COLOURS, DEFAULT_CIPHER2_COLOURS } from './executive_summary_constants.js'

export function colour_scheme_default(index) {
  // index is an integer
  return DEFAULT_CIPHER2_COLOURS[index % DEFAULT_CIPHER2_COLOURS.length]
}


export function colour_scheme_cluster(index) {
  // index is an integer
  return CLUSTER_COLOURS(index)
}

export function get_items_filtered_by_company_and_years(items, allowed_company_ids, allowed_years) {
  let filters = []

  const company_ids = allowed_company_ids || []
  const years = allowed_years || []

  if (company_ids.length > 0) {
    filters.push({key: 'company_id', valid_vals: company_ids})
  }

  if (years.length > 0) {
    filters.push({key: 'year', valid_vals: years})
  }

  return filter_items(items, filters)
}

export function sum_item_values(items) {
  let total = 0

  items.forEach(function (d) {
    total += d.value
  })

  return total
}

export function convert_gbp_to_dollars(gbp_value, exchange_rates) {
  if (!gbp_value) {
    return gbp_value
  }
  const rate = exchange_rates[ID_TO_CURRENCY.USD.id]
  return gbp_value / rate
}

export function format_financial_amount(amount) {
  const FORMAT_AMOUNT = d3.format(',.3r') // 3 sig figs, comma seperated thousands

  return '$' + ((amount >= 1000000) ? format_number_with_precision(amount / 1000000, 1) + 'M' : FORMAT_AMOUNT(amount))
}

// from src/model/groupings.js

export const GROUPINGS_LIST = [
  {
    id: 'byCompany',
    key: 'company_id',
    name: 'Organisation',
    short_name: 'Organisation',
    colour_scheme: colour_scheme_default
  },
  {
    id: 'byCluster',
    key: 'cluster_id',
    name: 'Technology',
    short_name: 'Technology',
    colour_scheme: colour_scheme_cluster
  }
]
export const GROUPINGS = get_as_map(GROUPINGS_LIST, 'id')

export function format_number_with_precision(num, precision) {
  const number = Math.round(+num, precision)
  const format = d3.format(',')
  return format(number)
}

export function format_date(date) {
  const format_str = '%Y-%m-%d'
  const parse = d3.timeFormat(format_str)

  return parse(new Date(date))
}


//from src/model/filter_utils in cipher2
/**
 * Filters the given item objects using the filters.
 * @param {Object[]} items
 * @param {Object[]} filters - Each filter must have a 'key' AND either
 *                               a) 'valid_vals' list
 *                               b) 'pred_fn' predicate function.
 * @returns {Object[]}
 *
 * Example usage: filter_items(ITEMS, [{ key: key, valid_vals: valid_vals }])
 * Example usage: filter_items(ITEMS, [{ key: key, pred_fn: function(item) { return true } }])
 */
function filter_items(items, filters) {
  return items.filter(function (item) {
    return _.every(filters, function (filter) {
      const key = filter.key,        // i.e 'company_id'
        valid_vals = filter.valid_vals, // i.e. ['c1', 'c3']
        pred_fn = filter.pred_fn     // i.e. function(val) { return val == 'c1' }

      const val = item[key]
      return valid_vals ? _.contains(valid_vals, val) : pred_fn(val)
    })
  })
}


/**
 * For the given items, returns a map of group_key to total.
 * Items with the same group_key will have their values summed.
 *
 * For example suppose we have:
 *
 *   var items = [
 *     { company: 'c1', cluster: 'k2', territory: 'DE', value: 4 },
 *     { company: 'c1', cluster: 'k2', territory: 'FR', value: 6 },
 *     { company: 'c1', cluster: 'k3', territory: 'DE', value: 2 },
 *     { company: 'c2', cluster: 'k1', territory: 'GB', value: 3 }
 *   ]
 *
 *   var COMPANY_DIMENSION = { key: 'company' }
 *   var CLUSTER_DIMENSION = { key: 'cluster' }
 *   var group_by = [COMPANY_DIMENSION, CLUSTER_DIMENSION]
 *
 * Then:
 *
 *   var result = get_group_key_to_total(items, group_by, 'value')
 *
 *   // Returns:
 *   { c1_k2: 10, c1_k3: 2, c2_k1: 3 }
 *
 * @param {Object[]} items - The items to group. Must contain the properties for each of the given group_by and value_key.
 * @param {string[]} group_by - The dimension items to group by (i.e. must contain a 'key' property)
 * @param {?string} value_key - Optional value key. Defaults to 'value'.
 * @returns {Object}
 */
function get_group_key_to_total(items, group_by, value_key) {
  value_key = value_key || 'value'

  return items.reduce(function (acc, item) { // for each item... add to map (updating total)
    const keys = group_by.map(function (dim) {
      return item[dim.key]
    })
    const group_key = get_group_key(keys)
    const total = acc[group_key] || 0
    acc[group_key] = total + item[value_key]
    return acc
  }, {})
}

function render_as_nested_array(group_key_to_value, dims, missing_value) {
  // Renders a multidimensional array of values,
  // with an element per combination of dims.
  // For example, given dims = [[ {id: 'c1'}, {id:'c2'}], [{id:'d1'}, {id:'d2'}, {id:'d3'} ]]
  // we return a 2-dimensional array, with 6 elements (2 rows, 3 columns).

  function _render_as_nested_array(_dims, _keys) { // Helper function
    if (_dims.length === 0) {
      // Base case:
      // There are no more dimensions left.
      // So return value for the specified keys.
      const group_key = get_group_key(_keys)
      const value = group_key_to_value[group_key]
      return (value !== undefined) ? value : missing_value
    }

    // Render an array for first dimension,
    // recursing for each category.
    return _dims[0].map(function (cat) {
      return _render_as_nested_array(_dims.slice(1), _keys.concat(cat.id))
    })
  }

  return _render_as_nested_array(dims, [])
}

export function get_group_key(keys) {
  // Returns a compound key
  return keys.join('_')
}

export function filter_by_portfolio(items, selected_portfolios) {
  return items.filter(item => constains_portfolio(item.company_id, selected_portfolios))
}

function constains_portfolio(company_id, selected_companies) {

  const _any = selected_companies.filter(company => company_id === company.id)

  return (_any.length > 0)
}

function extract_litigations_per_year(litigations, selected_years) {
  return litigations.filter(item => (item.year >= selected_years[0] && item.year <= selected_years[1]))
    .map(item => item.value)
}

export function extract_litigations(litigations, selected_companies, selected_years) {
  const filtered_litigations = filter_by_portfolio(litigations, selected_companies)
  return extract_litigations_per_year(filtered_litigations, selected_years)
}

export const VIEWS_LIST = [
  {
    id: 'miniline',
    name: 'miniline',
    download_png_available: true,
    icon_class: 'linear-icon',
    is_time_series: true,
    get_component: function () {
      return require('../charts/MiniLineChart')
    }
  },
  {
    id: 'minibar',
    name: 'minibar',
    download_png_available: true,
    icon_class: 'bar-icon',
    is_time_series: false,
    get_component: function () {
      return require('../charts/MiniBarChart')
    }
  },
  {
    id: 'minicolumn',
    name: 'minicolumn',
    download_png_available: true,
    icon_class: 'column-icon',
    is_time_series: true,
    get_component: function () {
      return require('../charts/MiniColumnChart')
    }
  },
  {
    id: 'miniarea',
    name: 'miniarea',
    download_png_available: true,
    icon_class: 'stream-icon',
    is_time_series: true,
    get_component: function () {
      return require('../charts/MiniAreaChart')
    }
  },
  {
    id: 'miniareamirror',
    name: 'miniareamirror',
    download_png_available: true,
    icon_class: 'stream-icon',
    is_time_series: true,
    get_component: function () {
      return require('../charts/MiniAreaMirrorChart')
    }
  },
  {
    id: 'mini4bubble',
    name: 'mini4bubble',
    download_png_available: true,
    icon_class: '',
    is_time_series: true,
    get_component: function () {
      return require('../charts/Mini4BubbleChart')
    }
  },
]

export function get_default_last_years(data_items, current_year, count, years_key) {
  count = count || 10
  let years = get_years_from_data_items(data_items, years_key)
  years = years.filter((year) => +year <= current_year) // ensure data does not include an years greater than current year (i.e. currently on Jan 1st, sometimes data may include a year ahead of CURRENT_YEAR)
  return get_last_x_extent(years, count)
}

function get_years_from_data_items(data_items, years_key) {
  years_key = years_key || 'year'
  let years = get_categories(data_items, years_key)
    .map(function (d) {
      return +d
    }) // coerce to numbers

  const extent = d3.extent(years)
  return d3.range(extent[0], extent[1] + 1) // ensure continuous monotonically increasing sequence
}

function get_last_x_extent(arr, x) {
  const subset = arr.slice(-x)
  return extent_excl(subset)
}

function extent_excl(arr) {
  // i.e. given [2000, 2001, 2002] returns [2000, 2003]
  const extent = d3.extent(arr)
  return [extent[0], (extent[1] + 1)] // [startInclsive, endExclusive]
}


/**
 * Returns the unique values for the given key within the items.
 * @param {Object[]} items - Array of item objects, with various properties
 * @param {string} key - A key of interest
 * @returns {[]} Array of unique category values for the items for the given key.
 */
function get_categories(items, key) {
  return _.uniq(items.map(function (d) {
    return d[key]
  }))
}

export function get_year_items(selected_years) {
  return d3.range(selected_years[0], selected_years[1])
    .map(function (d, i) {
      return {
        id: d,
        name: d,
        short_name: d,
        idx: i
      }
    })
}

export function filter_items_by_company_and_cluster(items, company_ids, cluster_ids, other_filters) {
  other_filters = other_filters || []

  const filters = [
    {key: 'company_id', valid_vals: company_ids},
    {key: 'cluster_id', valid_vals: cluster_ids}
  ].concat(other_filters)

  return filter_items(items, filters)
}

export function filter_items_by_company(items, company_ids, other_filters) {
  other_filters = other_filters || []

  const filters = [
    {key: 'company_id', valid_vals: company_ids}
  ].concat(other_filters)

  return filter_items(items, filters)
}


//from grouping_utils.js in cipher2
/**
 * Groups the given items by the specified keys.
 * Items with the same group_key will have their values summed.
 * Returns a multidimensional array, containing an element
 * for each combination of the dims specified.
 *
 * For example suppose we have:
 *
 *   var items = [
 *     { company: 'c1', cluster: 'k2', territory: 'DE', value: 4 },
 *     { company: 'c1', cluster: 'k2', territory: 'FR', value: 6 },
 *     { company: 'c1', cluster: 'k3', territory: 'DE', value: 2 },
 *     { company: 'c2', cluster: 'k1', territory: 'GB', value: 3 }
 *   ]
 *
 *   var COMPANY_DIMENSION = { key: 'company' }
 *   var CLUSTER_DIMENSION = { key: 'cluster' }
 *   var group_by = [COMPANY_DIMENSION, CLUSTER_DIMENSION]
 *
 *   var dims = [ [ {id: 'c1'}, {id: 'c2'} ], [ {id: 'k1'}, {id: 'k2'}, {id: 'k3'} ] ]
 *
 * Then:
 *
 *   var result = group_as_nested_array(items, group_by, dims, 0)
 *
 *   // Returns:
 *   [ [ 0, 10, 2 ], [ 3, 0, 0 ] ]
 *
 * Similarly:
 *
 *   var result2 = group_as_nested_array(items, group_by, dims)
 *
 *   // Returns:
 *   [ [ undefined, 10, 2 ], [ 3, undefined, undefined ] ]
 *
 * @param {Object[]} items - The items to group. Must contain the properties for each of the given group_by and value_key.
 * @param {string[]} group_by - The dimension items to group by.
 * @param {string[][]} dims - For each group_by key, specifies categories to include in the resulting array. Must be same length as group_by.
 * @param {?} missing_value - Optional missing value (i.e. 0) for use when combination does not exist. Defaults to undefined.
 * @param {?string} value_key - Optional value key. Defaults to 'value'.
 * @returns {Object}
 */
export function group_as_nested_array(items, group_by, dims, missing_value, value_key) {
  if (group_by.length !== dims.length) {
    // these must be the same
    throw new Error(`group_by.length (${group_by.length}) not same as dims.length (${dims.length})`)
  } 

  const group_key_to_total = get_group_key_to_total(items, group_by, value_key)
  return render_as_nested_array(group_key_to_total, dims, missing_value)
}

export function exec_summary_svg2pdf({ref}) {
  const svg_inner = ref.current.svg_ref.current.children[0]
  const serializer = new XMLSerializer()

  const svg_content = Array.prototype.map.call(
    svg_inner.childNodes,
    (child) => serializer.serializeToString(child)
  ).join('')

  const svg_string = '<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0" y="0" width="900" height="1275">'+svg_content+'</svg>'

  return convert_svg_to_pdf(svg_string)
}