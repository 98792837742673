import { SELECTED_PORTFOLIOS, SELECTED_TECHS, SELECTED_GEOS } from '../model/deref.js'
import { get_selected_portfolio_ids, get_selected_tech_ids, get_selected_geo_ids } from '../model/user_state.js'

import { get_array_as_val_to_true } from './utils.js'
import { add_alpha } from './color_utils.js'

function get_selected_items(items, selected_item_ids, spotlighted_item_ids) {
  const selected_id_to_true = get_array_as_val_to_true(selected_item_ids)
  const selected_items = items.filter(d => selected_id_to_true[d.id])

  return (spotlighted_item_ids && spotlighted_item_ids.length > 0) ? selected_items.map(item => {
    const {id, color} = item
    return (spotlighted_item_ids.indexOf(id) !== -1) ? item : {...item, color: add_alpha(color, 0.1)}

  }) : selected_items
}

export function get_deref_data(ref_data, user_state, selections) {
  if (!ref_data || !user_state) {
    return null
  }

  const { portfolios, techs, geos } = ref_data

  const {
    spotlighted_portfolios,
    spotlighted_techs
  } = selections || {}

  const { ids: spotlighted_portfolio_ids, apply: apply_portfolios_spotlights } = spotlighted_portfolios || {}
  const { ids: spotlighted_tech_ids, apply: apply_techs_spotlights }           = spotlighted_techs || {}

  const selected_portfolio_ids = get_selected_portfolio_ids(user_state, ref_data)
  const selected_tech_ids = get_selected_tech_ids(user_state, ref_data)
  const selected_geo_ids = get_selected_geo_ids(user_state, ref_data)

  // Important: preserve ordering of ref data (i.e. selected_ids may be in a different order)

  const selected_geo_id_to_true = get_array_as_val_to_true(selected_geo_ids)

  const selected_portfolios = get_selected_items(portfolios, selected_portfolio_ids, (spotlighted_portfolio_ids && apply_portfolios_spotlights) ? spotlighted_portfolio_ids : [] )
  const selected_techs = get_selected_items(techs, selected_tech_ids, (spotlighted_tech_ids && apply_techs_spotlights) ? spotlighted_tech_ids : [])
  const selected_geos = geos.filter(d => selected_geo_id_to_true[d.id])

  return {
    [SELECTED_PORTFOLIOS]: selected_portfolios,
    [SELECTED_TECHS]: selected_techs,
    [SELECTED_GEOS]: selected_geos
  }
}
