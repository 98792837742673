import React, { Component } from 'react'
import _ from 'underscore'
import * as d3 from 'd3'

import MiniAreaChart from './MiniAreaChart.js'

class MiniAreaMirrorChart extends Component{
  render() {
    const { values, y_domain } = this.props
    const max_value = d3.max(_.flatten(values))

    return (
      <MiniAreaChart
        {... this.props}
        values={[ values[0], values[1].map(function(val) { return -val })]}
        y_domain={y_domain ||  [-max_value, max_value]}
      />

    )
  }
}

export default MiniAreaMirrorChart
