import { POSITIVE, NEGATIVE, TEST_POSITIVE, TEST_NEGATIVE, IGNORE } from '../constants/labels.js'
import {
  NUM_POS, NUM_NEG, DELTA_POS_ADD, DELTA_POS_REMOVE, DELTA_NEG_ADD, DELTA_NEG_REMOVE, MIN_NUM_POS, MIN_NUM_NEG,
  EXISTS_PREVIOUS_BUILD, IS_STALE, IS_ENOUGH_EXAMPLES_TO_BUILD, TOTAL_NUM_POS, TOTAL_NUM_NEG, NUM_TEST_POS, NUM_TEST_NEG, NUM_IGNORE, TS_POS_IDS, TS_NEG_IDS
} from '../constants/counts.js'
import { get_pos_and_neg_patfam_ids } from './metadata_utils.js'
import { ENGINE_NN_CLASSIFIER_V1_ID } from '../model/classifier_engines.js'

function get_count_missing(source_values, target_values) {
  const target_set = new Set(target_values)
  return source_values.reduce((count, val) => {
    return !target_set.has(val) ? count + 1 : count
  }, 0)
}

export function get_counts(training_set_patfams, latest_built_classifier_metadata, engine_id) {
  training_set_patfams = training_set_patfams || []

  // Latest built classifier
  const classifier_built = latest_built_classifier_metadata != null
  const [classifier_pos_ids, classifier_neg_ids] = get_pos_and_neg_patfam_ids(latest_built_classifier_metadata)
  const latest_build_pos = classifier_pos_ids.length
  const latest_build_neg = classifier_neg_ids.length

  // Training set
  const {
    [TS_POS_IDS]:    ts_pos_ids,
    [TS_NEG_IDS]:    ts_neg_ids,
    [TOTAL_NUM_POS]: num_ts_pos,
    [TOTAL_NUM_NEG]: num_ts_neg,
    [NUM_IGNORE]:    num_ts_ignore,
    [NUM_TEST_POS]:  num_test_pos,
    [NUM_TEST_NEG]:  num_test_neg,
  } = get_ts_counts(training_set_patfams)

  // Deltas
  const num_pos_to_add    = get_count_missing(ts_pos_ids,         classifier_pos_ids)
  const num_pos_to_remove = get_count_missing(classifier_pos_ids, ts_pos_ids)
  const num_neg_to_add    = get_count_missing(ts_neg_ids,         classifier_neg_ids)
  const num_neg_to_remove = get_count_missing(classifier_neg_ids, ts_neg_ids)

  const is_stale = num_pos_to_add || num_pos_to_remove || num_neg_to_add || num_neg_to_remove

  const is_enough_examples_to_build = enough_examples_to_build(num_ts_pos, num_ts_neg, engine_id)

  return {
    [NUM_POS]:                     latest_build_pos,
    [NUM_NEG]:                     latest_build_neg,
    [NUM_IGNORE]:                  num_ts_ignore,
    [DELTA_POS_ADD]:               num_pos_to_add,
    [DELTA_POS_REMOVE]:            num_pos_to_remove,
    [DELTA_NEG_ADD]:               num_neg_to_add,
    [DELTA_NEG_REMOVE]:            num_neg_to_remove,
    [TOTAL_NUM_POS]:               num_ts_pos,
    [TOTAL_NUM_NEG]:               num_ts_neg,
    [NUM_TEST_POS]:                num_test_pos,
    [NUM_TEST_NEG]:                num_test_neg,
    [IS_STALE]:                    is_stale,
    [EXISTS_PREVIOUS_BUILD]:       classifier_built, // this is from the backend, but guaranteed to be up-to-date because we always refetch backend data after doing a build
    [IS_ENOUGH_EXAMPLES_TO_BUILD]: is_enough_examples_to_build
  }

}

export function get_ts_counts(training_set_patfams) {
  const { ts_pos_ids, ts_neg_ids, ts_ignore_ids, test_pos_ids, test_neg_ids } = training_set_patfams.reduce(({ ts_pos_ids, ts_neg_ids, ts_ignore_ids, test_pos_ids, test_neg_ids }, pfam) => {
    const { id, user_class } = pfam
    return {
      ts_pos_ids:    user_class === POSITIVE      ? [...ts_pos_ids,    id] : ts_pos_ids,
      ts_neg_ids:    user_class === NEGATIVE      ? [...ts_neg_ids,    id] : ts_neg_ids,
      ts_ignore_ids: user_class === IGNORE        ? [...ts_ignore_ids, id] : ts_ignore_ids,
      test_pos_ids:  user_class === TEST_POSITIVE ? [...test_pos_ids,  id] : test_pos_ids,
      test_neg_ids:  user_class === TEST_NEGATIVE ? [...test_neg_ids,  id] : test_neg_ids
    }
  }, { ts_pos_ids: [], ts_neg_ids: [], ts_ignore_ids: [], test_pos_ids: [], test_neg_ids: [] })
  const num_ts_pos    = ts_pos_ids.length
  const num_ts_neg    = ts_neg_ids.length
  const num_ts_ignore = ts_ignore_ids.length
  const num_test_pos  = test_pos_ids.length
  const num_test_neg  = test_neg_ids.length

  return {
    [TS_POS_IDS]:    ts_pos_ids,
    [TS_NEG_IDS]:    ts_neg_ids,
    [TOTAL_NUM_POS]: num_ts_pos,
    [TOTAL_NUM_NEG]: num_ts_neg,
    [NUM_IGNORE]:    num_ts_ignore,
    [NUM_TEST_POS]:  num_test_pos,
    [NUM_TEST_NEG]:  num_test_neg,
  }
}

function enough_examples_to_build(num_ts_pos, num_ts_neg, engine_id) {
  if (engine_id === ENGINE_NN_CLASSIFIER_V1_ID) {
    return num_ts_pos > 0
  }

  return num_ts_pos >= MIN_NUM_POS && num_ts_neg >= MIN_NUM_NEG
}
