import React from 'react'
import { PaneHeader } from '../widgets/PaneHeader.js'
import { PartyPanel } from './PartyPanel.js'
import Grid from '../widgets/Grid.js'
import GridElement from '../widgets/GridElement.js'

export const PartiesPanel = ({parties, title}) => {
  return (
    <div>
      <PaneHeader text={title}/>
      <Grid className='mt-3'>
        {parties.map((party, i) => (
          <GridElement key={i}>
            <PartyPanel party={party}/>
          </GridElement>
        ))}
      </Grid>
    </div>
  )
}