export const TABLE_COLUMN_HEADER = {
  id: null, /*mandatory*/
  label: null, /*mandatory*/
  field: null, /*mandatory*/
  sortable: true,
  resizable: true,
  className: null,
  cell_render: null,
  min_width: null,
  max_width: null,
  foot_render: null,
  sort: null
}