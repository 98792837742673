import React from 'react'

/**
 * Returns a new list of elements, where each input element is interspersed with a connector.
 * i.e. [El1, connector, El2, connector, El3, ...]
 * @param {} input_elements Array of react elements.
 * @param {} connector A react element.
 */
export function join(input_elements=[], connector) {
  return input_elements.reduce((els, el, idx) => {
    return idx > 0 ?
      (
        [
          ...els,
          React.cloneElement(connector, { key: idx + '-connector'}),
          el
        ]
      )
      : [...els, el]
  }, [])
}