import React from 'react'
import { DragOverlay } from '@dnd-kit/core'

const DragAndDropOverlay = ({children}) => {
  return (
    <DragOverlay>
      {children}
    </DragOverlay>
  )
}

export default DragAndDropOverlay