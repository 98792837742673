import React from 'react'
import cn from 'classnames'

import { DISPUTE, DOC } from '../../constants/paths.js'
import TextLink from '../widgets/TextLink.js'

import s from './AttachmentPanel.module.scss'

export const AttachmentPanel = ({attachment, show_document_link}) => {
  const {attachment_name='', last_modified, description} = attachment
  const url = `${DISPUTE}/${DOC}/${encodeURIComponent(attachment_name)}`

  return (
    <div className={cn('p-3', s.block)}>
      <div className='d-flex justify-content-between mb-1'>
        <div className={'text-break w-75'}><TextLink element='a' target='_blank' rel='noopener noreferrer' href={url} disable={!show_document_link}>{attachment_name}</TextLink></div>
        <div>{last_modified}</div>
      </div>
      <div>{description}</div>
    </div>
  )
}