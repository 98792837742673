import React from 'react'
import cn from 'classnames'

import s from './ClassifiersDisplayContainer.module.scss'

const ClassifiersDisplayContainer = ({className, children}) => {
  return (
    <div className={cn(s.block, className)}>
      {children}
    </div>
  )
}

export default ClassifiersDisplayContainer