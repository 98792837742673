export const PIE_ID = 'pie'
export const LINE_ID = 'line'
export const SCATTER_ID = 'scatter'
export const AREA_ID = 'area'
export const COLUMN_ID = 'column'
export const BUBBLE_ID = 'bubble'
export const COLUMN_CLUSTER_ID = 'column_cluster'
export const COLUMN_STACK_ID = 'column_stack'
export const BAR_CLUSTER_ID = 'bar_cluster'
export const BAR_STACK_ID = 'bar_stack'
export const BAR_ID = 'bar'
export const HEATMAP_ID = 'heatmap'
export const TREEMAP_ID = 'treemap'
export const GROWTH_TREEMAP_ID = 'growth_treemap'
export const GROWTH_TABLE_ID = 'growth_table'
export const GROWTH_SCATTER_ID = 'growth_scatter'
export const TABLE_ID = 'table'
export const EXTENDED_TABLE_ID = 'extended_table'
export const DISPUTES_TABLE_ID = 'disputes_table'
export const LICENCES_TABLE_ID = 'licences_table'
export const CHOROPLETH_MAP_ID = 'map'
export const CAGR_TABLE_ID = 'cagr_table'
export const CAGR_SCATTER_ID = 'cagr_scatter'
export const UNHANDLED_TABLE = 'unhandled_table'