import { PERCENTAGE_SYMBOL } from '../constants/constants.js'
import { GEO_ID } from './geographies.js'

import { WO } from '../constants/countries.js'

import { SELECTED_PORTFOLIOS, SELECTED_TECHS, SELECTED_GEOS } from './deref.js'
import { DEFAULT_RECENT_LAST_FEW_YEARS_COUNT, COST_DATA_START_YEAR } from './time_ranges.js'
import {
  get_short_currency_value_formatter,
  get_long_currency_value_formatter,
  USD_ITEM,
  DOLLAR_PREFIX
} from './currencies.js'
import { ALIVE_PATFAM_STATUS_DIMS, STATUS_ACTIVE_ID, STATUS_COLUMN_NAME } from './statuses.js'
import { DISPUTE_ROLE_DIMS } from './disputes.js'

import {
  CHOROPLETH_MAP_ID, GROWTH_SCATTER_ID,
} from './view_ids.js'

import { ROLE_DEFENDANT, ROLE_EITHER, ROLE_PLAINTIFF, ROLE_ANY } from '../model/dispute_roles.js'

import { get_as_map, to_date } from '../utils/utils.js'
import {
  get_status_items,
  get_year_items,
  get_jurisdiction_data,
  get_default_classifier_score_bucket_items,
  transform_pvix_histogram_data,
  get_pvix_score_buckets,
  get_count_items,
  to_percentages,
  to_cumulative_percentages,
  filter_expiry_ages_out_of_range,
  get_cost_buckets,
  transform_cost_histogram_data,
  get_pvix_scatter_axis_range,
  transform_granted_families_age_data
} from '../utils/item_utils.js'
import {
  get_year_items_from_column,
  decimal_value_formatter,
  decimal_value_with_leading_zeros_formatter,
  percentage_value_formatter,
  get_dim_keys_with_colours,
  get_countries_dim_keys_with_colours
} from '../utils/column_data_utils.js'
import {
  get_current_year,
  get_last_few_full_years_extent,
  get_year_10_years_ago,
  get_last_10_years_extent,
  get_last_n_years_extent,
  get_next_10_years_exclusive_extent,
  get_since_2000_extent,
  get_since_1980_extent,
  get_extent_from_years_column_incl_last_10_years,
  get_extent_as_report_reader_clause,
  get_next_20_years,
  get_since_2000_by_month_created_extent,
  get_last_10_years_by_month_created_extent,
  get_last_n_years_by_month_created_extent
} from '../utils/time_range_utils.js'
import {
  DISPUTES_DATASET_ENDPOINT,
  COST_DATASET_ENDPOINT,
  PVIX_SCORE_BUCKET_KEY,
  HISTOGRAM_ENDPOINT,
  SCORE_BUCKET_KEY,
  COUNTRY_DISTRIBUTION_BUCKET_KEY,
  COST_BUCKET_KEY
} from '../constants/report_reader.js'
import {
  add_custom_selections_to_dispute_table_query,
  add_custom_selections_to_cost_query,
  add_custom_selections_to_patfam_based_litigation_query,
  add_custom_selections_to_role_based_litigation_query,
} from '../utils/spec_custom_selections_utils.js'

import {
  MISC_COLOR_SCHEME,
  PORTFOLIO_COLOR_SCHEME,
  TECH_COLOR_SCHEME
} from '../constants/colours.js'

import {
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_REGION_ID,
  ACTIVE_FAMILIES_BY_REGION_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_REGION_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_YEAR_ID,
  ACTIVE_FAMILIES_BY_TECH_ID,
  AGE_AT_EXPIRY_BY_PORTFOLIO_ID,
  AGE_AT_EXPIRY_BY_TECH_ID,
  PERCENTAGE_EXPIRED_BY_AGE_BY_PORTFOLIO_ID,
  APPLICATIONS_VS_GRANTS_BY_YEAR_ID,
  AVG_AGE_AT_EXPIRY_BY_ORG_BY_TECH_ID,
  AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID,
  AVERAGE_COST_BY_PORTFOLIO_ID,
  AVERAGE_COST_BY_TECH_ID,
  AVERAGE_COST_BY_ORG_AND_TECH_ID,
  AVERAGE_COST_BY_AGE_ID,
  AVG_PRIORITY_YEAR_BY_ORG_ID,
  APPLICATIONS_BY_COUNTRY_ID,
  APPLICATIONS_VS_GRANTS_BY_COUNTRY_ID,
  BACKWARD_CITATIONS_BY_ORG_BY_TECH_ID,
  CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
  CAGR_ACTIVE_FAMILIES_BY_TECH_ID,
  COST_BY_COUNTRY_ID,
  COST_BY_COUNTRY_BY_YEAR_ID,
  COST_BY_PORTFOLIO_BY_TECH_ID,
  COST_BY_PORTFOLIO_BY_COUNTRY_ID,
  COST_BY_PORTFOLIO_BY_YEAR_ID,
  COST_BY_REGION_ID,
  COST_BY_TECH_BY_YEAR_ID,
  EXPIRED_BY_YEAR_BY_ORGANISATION_ID,
  EXPIRED_BY_YEAR_BY_TECH_ID,
  EXPIRING_BY_YEAR_BY_ORGANISATION_ID,
  EXPIRING_BY_YEAR_BY_TECH_ID,
  FAMILIES_BY_CPC_CODE,
  FAMILIES_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
  FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
  FAMILIES_BY_PORTFOLIO_BY_GRANTED_YEAR_ID,
  FAMILIES_BY_TECH_BY_PRIORITY_YEAR_ID,
  FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
  FAMILIES_BY_TECH_BY_GRANTED_YEAR_ID,
  FORWARD_CITATIONS_BY_ORG_BY_TECH_ID,
  FUTURE_COST_BY_COUNTRY_ID,
  GRANTED_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID,
  GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  GRANTED_FAMILIES_BY_PORTFOLIO_ID,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_ID,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
  GRANTED_FAMILIES_BY_TECH_BY_COUNTRY_ID,
  GRANTED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  GRANTED_FAMILIES_BY_TECH_BY_YEAR_ID,
  GRANTED_FAMILIES_BY_TECH_ID,
  GRANTS_BY_COUNTRY_ID,
  GRANTS_BY_GRANTYEAR_BY_COUNTRY_ID,
  GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  GRANTS_BY_TECHNOLOGY_BY_COUNTRY_ID,
  GRANTS_BY_PRIORITY_YEAR_ID,
  GRANTS_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  GRANTS_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
  LITIGATED_FAMILIES_BY_PORTFOLIO_ID,
  LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  LITIGATED_FAMILIES_BY_TECH_ID,
  LITIGATION_BALANCE_ID,
  LITIGATIONS_BY_DEFENDANT_BY_START_YEAR_ID,
  LITIGATIONS_BY_PLAINTIFF_BY_START_YEAR_ID,
  LITIGATIONS_DEFENSIVE_DISPUTES,
  LITIGATIONS_OFFENSIVE_DISPUTES,
  LITIGATIONS_CROSS_DISPUTES,
  LITIGATIONS_ALL_PATFAMS_DISPUTES,
  PATFAMS_WITH_GRANTS_BY_COUNTRY_ID,
  PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  PATFAMS_WITH_GRANTS_BY_TECH_BY_COUNTRY_ID,
  PENDING_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
  PENDING_FAMILIES_BY_PORTFOLIO_ID,
  PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID,
  PENDING_FAMILIES_BY_PRIORITY_YEAR_ID,
  PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
  PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
  PENDING_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  PENDING_FAMILIES_BY_TECH_BY_YEAR_ID,
  PENDING_FAMILIES_BY_TECH_ID,
  PUBLICATIONS_BY_PUB_YEAR_BY_COUNTRY_ID,
  RECENTLY_PUBLISHED_BY_ORG_BY_TECH_ID,
  RECENTLY_GRANTED_BY_ORG_BY_TECH_ID,
  ACTIVE_SIZE_GROWTH_BY_PORTFOLIO_ID,
  ACTIVE_SIZE_GROWTH_BY_TECH_ID,
  GRANTED_SIZE_GROWTH_BY_PORTFOLIO_ID,
  GRANTED_SIZE_GROWTH_BY_TECH_ID,
  FAMILIES_BY_STATUS,
  NUM_FAMILIES_BY_SCORE_BAND,
  NUM_FAMILIES_BY_SCORE_BAND_BY_TECHNOLOGY,
  NUM_FAMILIES_BY_SCORE_BAND_BY_ORGANISATION,
  APPLICATIONS_AND_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
  APPLICATIONS_AND_GRANTS_BY_TECH_BY_COUNTRY_ID,
  APPLICATIONS_AND_GRANTS_BY_TECH_BY_PORTFOLIO_ID,
  TOTAL_NUM_PUBLICATIONS_BY_PORTFOLIO_ID,
  TOTAL_NUM_PUBLICATIONS_BY_TECHNOLOGY_ID,
  GRANTS_BY_TECH_BY_PORTFOLIO_ID,
  GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_COUNTRY_ID,
  GRANTED_FAMILIES_BY_PUBLICATION_YEAR_BY_COUNTRY_ID,
  COST_BY_TYPE_BY_YEAR_ID,
  PATFAMS_WITH_GRANTS_BY_REGION_ID,
  PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_REGION_ID,
  PATFAMS_WITH_GRANTS_BY_TECH_BY_REGION_ID,
  APPLICATIONS_BY_PORTFOLIO_BY_COUNTRY_ID,
  APPLICATIONS_BY_TECH_BY_COUNTRY_ID,
  GRANTED_FAMILIES_BY_GRANT_YEAR_BY_PORTFOLIO_ID,
  GRANTED_FAMILIES_BY_GRANT_YEAR_BY_TECHNOLOGY_ID,
  PVIX_TOTAL_BY_TECH_BY_PORTFOLIO_ID,
  PVIX_MEAN_BY_TECH_BY_PORTFOLIO_ID,
  NUM_FAMILIES_BY_PVIX_BAND_BY_PORTFOLIO_ID,
  PVIX_MEAN_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
  PVIX_MEAN_BY_TECH_BY_PRIORITY_YEAR_ID,
  PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID,
  PVIX_MEAN_BY_TECHNOLOGY_BY_SIZE_ID,
  NUM_FAMILIES_BY_PVIX_BAND_BY_TECHNOLOGY_ID,
  PVIX_TOTAL_BY_PORTFOLIO_ID,
  PVIX_TOTAL_BY_TECH_ID,
  PVIX_MEAN_BY_PORTFOLIO_ID,
  PVIX_MEAN_BY_TECH_ID,
  ENTRANCE_YEAR_BY_ORG_BY_TECH_ID,
  GRANTED_COUNTRIES_PER_FAMILY_BY_ORG,
  ACTIVE_FAMILIES_BY_COUNTRY_ID,
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_COUNTRY_ID,
  EXPIRED_FAMILIES_BY_PORTFOLIO_ID,
  EXPIRED_FAMILIES_BY_TECH_ID,
  EXPIRED_FAMILIES_BY_PORTFOLIO_BY_TECH_ID,
  ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID,
  ACTIVE_FAMILIES_BY_PORTFOLIO_BY_STATUS_ID,
  ALL_FAMILIES_BY_PORTFOLIO_ID,
  ALL_FAMILIES_BY_TECH_ID,
  ALL_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
  FAMILIES_BY_PORTFOLIO_BY_CPC_CODE,
  FAMILIES_BY_TECH_BY_CPC_CODE,
  FAMILY_FILINGS_BY_PORTFOLIO_WITHIN_TIMEFRAME_ID,
  NUM_FAMILIES_BY_PVIX_BAND_ID,
  NUM_FAMILIES_BY_COST_BAND_ID,
  NUM_FAMILIES_BY_PRIO_YEAR_ID,
  PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PRIORITY_YEAR_ID,
  AVG_AGE_OF_GRANTED_FAMILY_BY_ORG_BY_TECH_ID,
  PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PORTFOLIO_ID,
  PATFAMS_BY_FIRST_FILING_COUNTRY_BY_TECH_ID,
  FAMILIES_BY_PRIMARY_CPC_CODE,
  FAMILIES_BY_PORTFOLIO_BY_PRIMARY_CPC_CODE,
  FAMILIES_BY_TECH_BY_PRIMARY_CPC_CODE, FAMILIES_BY_FULL_PRIMARY_CPC_CODE
} from './spec_ids.js'
import { LITIGATIONS_BY_DEFENDANT_BY_JURISDICTION, LITIGATIONS_BY_PLAINTIFF_BY_JURISDICTION } from './spec_ids.js'
import { transform_application_and_renewals_data } from '../utils/cost_utils.js'
import { COST_DIMS } from './cost.js'
import {
  COST_DESCRIPTION,
  LITIGATION_ROUTES_STRING,
  NOT_AVAILABLE_LABEL,
  STATUS_ACTIVE,
  STATUS_EXPIRED,
  STATUS_GRANTED,
  STATUS_PENDING
} from '../constants/specs_params.js'
import {
  calculate_cagr_from_counts_by_year,
  calculate_filings_within_timerange,
  is_pre_calculation_data_empty,
  SMALLEST_MEANINGFUL_CAGR_VALUE,
  transform_counts_by_status_to_percent_pending
} from '../utils/patenting_activity_utils.js'
import {
  ACTIVE_GRANTS_COLUMN_NAME,
  ACTIVE_PATENT_FAMILIES_COLUMN_NAME,
  CPC_CODE_COLUMN_NAME,
  EXPIRY_YEAR_COLUMN_NAME,
  FIRST_FILING_COUNTRY_COLUMN_NAME,
  GEO_COLUMN_NAME,
  GRANT_YEAR_COLUMN_NAME,
  GRANTED_PATENT_FAMILIES_COLUMN_NAME,
  ORG_COLUMN_NAME,
  PATENT_FAMILIES_COLUMN_NAME,
  PATENTS_COLUMN_NAME,
  PRIORITY_YEAR_COLUMN_NAME,
  PUBLICATION_YEAR_COLUMN_NAME,
  REGION_COLUMN_NAME,
  TECH_COLUMN_NAME,
  YEAR_COLUMN_NAME
} from '../constants/spec_column_names.js'
import { not_available } from '../utils/data_availability_utils.js'

const short_dollar_formatter = get_short_currency_value_formatter(USD_ITEM)
const long_dollar_formatter = get_long_currency_value_formatter(USD_ITEM)

export function get_since_2000_year_range_clause(data_creation_date) {
  const extent = get_since_2000_extent(data_creation_date)
  return get_extent_as_report_reader_clause(extent)
}

function get_last_few_full_years_clause(data_creation_date) {
  const extent = get_last_few_full_years_extent(data_creation_date)
  return get_extent_as_report_reader_clause(extent)
}

function get_age_items(datum) {
  return datum.map(age => {
    return {
      id: age,
      name: age,
      short_name: age
    }
  })
}

function get_region_dim_keys(region_data) {
  return get_dim_keys_with_colours(region_data, MISC_COLOR_SCHEME)
}

export function get_filtered_dim_keys(data, dim_key_idx, deref_keys) {
  //return items with data only

  const key_dims = data.data[dim_key_idx]

  const to_return = (deref_keys || []).filter(item => key_dims.indexOf(item.id) !== -1)

  return to_return
}

function exclude_wipo_from_selections(deref_data) {
  // WIPO ('WO') doesn't grant patents, so for charts showing only granted families or patents we exclude it from the data
  const selected_geos = (deref_data && deref_data[SELECTED_GEOS]) || []
  return selected_geos.filter(geo => geo[GEO_ID] !== WO)
}

function no_rollups() {
  return true
}

function no_rollups_for_size_growth({item}) {
  const {view_id} = item || {}
  return view_id === GROWTH_SCATTER_ID
}

export const SPECS = [
  {
    id: ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  {
    id: ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]),
      ]
    },
  },

  {
    id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_REGION_ID,
    get_query: ({region_column}) => {
      return {
        "key": ["PFTP.portfolio_id", `TTR.${region_column}`],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, REGION_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        get_region_dim_keys(data.data[1]),
      ]
    },
    show_region_grouping_selector: true,
    hide_totals: true
  },

  {
    id: ACTIVE_FAMILIES_BY_TECH_BY_REGION_ID,
    get_query: ({region_column}) => {
      return {
        "key": ["PFTT.technology_id", `TTR.${region_column}`],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [TECH_COLUMN_NAME, REGION_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS],
        get_region_dim_keys(data.data[1]),
      ]
    },
    show_region_grouping_selector: true,
    hide_totals: true
  },

  {
    id: ACTIVE_FAMILIES_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [TECH_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS])
      ]
    },
  },

  {
    id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PFSBY.year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PFSBY.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, ORG_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    hide_row_totals: true,
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: ACTIVE_FAMILIES_BY_REGION_ID,
    get_query: ({region_column}) => {
      return {
        "key": [`TTR.${region_column}`],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [REGION_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data}) => {
      return [
        get_region_dim_keys(data.data[0])
      ]
    },
    show_region_grouping_selector: true,
    hide_totals: true
  },

  {
    check_if_available: not_available,
    id: ACTIVE_FAMILIES_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PFT.country_code"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [GEO_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({deref_data}) => {
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        selected_geos_excluding_wipo
      ]
    },
  },

  {
    check_if_available: not_available,
    id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [GEO_COLUMN_NAME, ORG_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        selected_geos_excluding_wipo,
        get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS])
      ]
    },
  },

  {
    check_if_available: not_available,
    id: ACTIVE_FAMILIES_BY_TECH_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [GEO_COLUMN_NAME, TECH_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        selected_geos_excluding_wipo,
        get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS])
      ]
    },
  },

  {
    id: ACTIVE_FAMILIES_BY_TECH_BY_STATUS_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id", "PF.status"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [TECH_COLUMN_NAME, STATUS_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS],
        ALIVE_PATFAM_STATUS_DIMS
      ]
    }
  },

  {
    id: ACTIVE_FAMILIES_BY_PORTFOLIO_BY_STATUS_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PF.status"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, STATUS_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        ALIVE_PATFAM_STATUS_DIMS
      ]
    }
  },

  {
    id: CAGR_ACTIVE_FAMILIES_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFSBY.year" ],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PFSBY.status (${STATUS_GRANTED}, ${STATUS_PENDING})`,
          `> COUNT DISTINCT PF.pat_fam_id ${SMALLEST_MEANINGFUL_CAGR_VALUE}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, 'Compound Annual Growth Rate (CAGR) in Active Families'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_n_years_by_month_created_extent(data_creation_date, 5),
    // use 'apply_calculation' where report reader data is the input for a calculation which needs to be reapplied when timeslider changes
    // 'transform_data', in contrast, is only applied when data is fetched/ refetched from report reader
    apply_calculation: calculate_cagr_from_counts_by_year,
    get_query_for_clickthroughs: ({data_creation_date, selected_timerange}) => {
      const extent = selected_timerange || get_last_n_years_extent(data_creation_date, 5)
      const in_timerange_constraint = get_extent_as_report_reader_clause(extent)
      return {
        "key": ["PFTP.portfolio_id", "PFSBY.year" ],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PFSBY.status (${STATUS_GRANTED}, ${STATUS_PENDING})`, `BETWEEN PFSBY.year ${in_timerange_constraint}`]
      }
    },
    value_formatter: percentage_value_formatter(2),
    value_suffix: PERCENTAGE_SYMBOL,
    can_sort_by_size: true, // we wouldn't know this otherwise because this looks like a timeseries
    hide_totals: true,
    no_rollups: no_rollups,
    get_hidden_dim_full_timerange: (data_creation_date) => get_year_items(get_last_n_years_extent(data_creation_date, 20)),
    is_data_empty_check: ({data}) => is_pre_calculation_data_empty({data, is_cagr: true})
  },

  {
    id: FAMILY_FILINGS_BY_PORTFOLIO_WITHIN_TIMEFRAME_ID,
    get_query: ({status_filter, data_creation_date}) => {
      const year_column = (status_filter === STATUS_ACTIVE_ID) ? 'PF.publication_year' : 'PF.granted_year'
      const base_timerange_constraint = get_extent_as_report_reader_clause(get_last_n_years_extent(data_creation_date, 20))
      return {
        "key": ["PFTP.portfolio_id", year_column],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`BETWEEN ${year_column} ${base_timerange_constraint}`]
      }
    },
    get_query_for_clickthroughs: ({status_filter, selected_timerange, data_creation_date}) => {
      const year_column = (status_filter === STATUS_ACTIVE_ID) ? 'PF.publication_year' : 'PF.granted_year'
      const extent = selected_timerange || get_last_n_years_extent(data_creation_date, 3)
      const in_timerange_constraint = get_extent_as_report_reader_clause(extent)
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`BETWEEN ${year_column} ${in_timerange_constraint}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    apply_calculation: calculate_filings_within_timerange,
    get_default_selected_timerange: (data_creation_date) => get_last_n_years_extent(data_creation_date, 3),
    get_hidden_dim_full_timerange: (data_creation_date) => get_year_items(get_last_n_years_extent(data_creation_date, 20)),
    is_data_empty_check: ({data}) => is_pre_calculation_data_empty({data})
  },

  {
    id: CAGR_ACTIVE_FAMILIES_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id", "PFSBY.year" ],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PFSBY.status (${STATUS_GRANTED}, ${STATUS_PENDING})`,
          `> COUNT DISTINCT PF.pat_fam_id ${SMALLEST_MEANINGFUL_CAGR_VALUE}`]
      }
    },
    column_names: [TECH_COLUMN_NAME, 'Compound Annual Growth Rate (CAGR) in Active Families'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_n_years_by_month_created_extent(data_creation_date, 5),
    // use 'apply_calculation' where report reader data is the input for a calculation which needs to be reapplied when timeslider changes
    // 'transform_data', in contrast, is only applied when data is fetched/ refetched from report reader
    apply_calculation: calculate_cagr_from_counts_by_year,
    get_query_for_clickthroughs: ({data_creation_date, selected_timerange}) => {
      const extent = selected_timerange || get_last_n_years_extent(data_creation_date, 5)
      const in_timerange_constraint = get_extent_as_report_reader_clause(extent)
      return {
        "key": ["PFTT.technology_id", "PFSBY.year" ],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PFSBY.status (${STATUS_GRANTED}, ${STATUS_PENDING})`, `BETWEEN PFSBY.year ${in_timerange_constraint}`]
      }
    },
    value_formatter: percentage_value_formatter(2),
    value_suffix: PERCENTAGE_SYMBOL,
    can_sort_by_size: true, // we wouldn't know this otherwise because this looks like a timeseries
    hide_totals: true,
    no_rollups: no_rollups,
    get_hidden_dim_full_timerange: (data_creation_date) => get_year_items(get_last_n_years_extent(data_creation_date, 20)),
    is_data_empty_check: ({data}) => is_pre_calculation_data_empty({data, is_cagr: true})
  },

  {
    id: ACTIVE_FAMILIES_BY_TECH_BY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PFSBY.year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PFSBY.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, TECH_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    hide_row_totals: true,
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: APPLICATIONS_VS_GRANTS_BY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PFSBY.year", "PFSBY.status"],
        "value": ["COUNT DISTINCT PFSBY.pat_fam_id"],
        "constraint": [`IN PFSBY.status (${STATUS_GRANTED}, ${STATUS_PENDING})`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, STATUS_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    hide_row_totals: true,
    get_key_dims: ({data_creation_date}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        ALIVE_PATFAM_STATUS_DIMS
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: RECENTLY_PUBLISHED_BY_ORG_BY_TECH_ID,
    get_query: ({data_creation_date}) => {
      const current_year = get_current_year(data_creation_date)
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["NOTNULL PF.publication_date", `> PF.publication_year ${current_year - DEFAULT_RECENT_LAST_FEW_YEARS_COUNT}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  {
    id: RECENTLY_GRANTED_BY_ORG_BY_TECH_ID,
    get_query: ({data_creation_date}) => {
      const current_year = get_current_year(data_creation_date)
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["NOTNULL PF.granted_date", `> PF.granted_year ${current_year - DEFAULT_RECENT_LAST_FEW_YEARS_COUNT}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  // TEMPORARILY HIDDEN - the rolled up totals (i.e. "next 64") are wrong, as they contain duplicates.
  //                    - TODO: move rollup to the backend, then we can get the correct numbers.
  // {
  //   // CHECK WITH STEVE: probably no point in having GRANTED/PENDING versions of this????
  //   // CHECK WITH STEVE: Total is confusing. i.e. Gives different total to Activefams-by-ptflio/tech (since fam can cover countries).
  //   id: ACTIVE_FAMILIES_BY_GRANTYEAR_BY_COUNTRY_ID,
  //   query: {
  //     "key": ["PF.granted_year", "PFT.country_code"],
  //     "value": ["COUNT DISTINCT PF.pat_fam_id"],
  //     "constraint": [
  //       "NOTNULL PF.granted_date",
  //       `IN PF.status (${STATUS_ACTIVE})`
  //     ]
  //   },
  //   column_names: [YEAR_COLUMN_NAME, GEO_COLUMN_NAME, 'Publications'],
  //   get_key_dims: (data, ref_data, deref_data, item, data_creation_date) => {
  //     return [
  //       get_year_items(get_since_2000_extent(data_creation_date)),
  //       deref_data[SELECTED_GEOS]
  //     ]
  //   },
  //   get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
  //   timeseries_key_dim_idxs: [0],
  // },

  {
    id: GRANTED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  {
    id: GRANTED_FAMILIES_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])
      ]
    },
  },

  {
    id: GRANTED_FAMILIES_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [TECH_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS])
      ]
    },
  },

  {
    id: GRANTED_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PFSBY.year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PFSBY.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, ORG_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    hide_row_totals: true,
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: GRANTED_FAMILIES_BY_TECH_BY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PFSBY.year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PFSBY.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, TECH_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    hide_row_totals: true,
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: ALL_FAMILIES_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [ORG_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]),
      ]
    },
  },

  {
    id: ALL_FAMILIES_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS])
      ]
    },
  },

  {
    id: ALL_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  {
    id: FAMILIES_BY_CPC_CODE,
    get_query: () => {
      return {
        "key": ["PFCC.cpc4"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "sort": ["DESC COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [CPC_CODE_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data}) => {
      return [
        get_dim_keys_with_colours(data.data[0], MISC_COLOR_SCHEME)
      ]
    },
  },

  {
    id: FAMILIES_BY_PORTFOLIO_BY_CPC_CODE,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFCC.cpc4"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [ORG_COLUMN_NAME, CPC_CODE_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        get_dim_keys_with_colours(data.data[1], MISC_COLOR_SCHEME)
      ]
    },
    hide_totals: true,
  },

  {
    id: FAMILIES_BY_TECH_BY_CPC_CODE,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id", "PFCC.cpc4"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [TECH_COLUMN_NAME, CPC_CODE_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS],
        get_dim_keys_with_colours(data.data[1], MISC_COLOR_SCHEME)
      ]
    },
    hide_totals: true,
  },
  {
    id: FAMILIES_BY_PRIMARY_CPC_CODE,
    get_query: () => {
      return {
        "key": ["PFCC.cpc4"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["NOTNULL PF.primary_cpc", "= PFCC.pat_fam_id PF.pat_fam_id", "= PFCC.cpc_code PF.primary_cpc"]
      }
    },
    column_names: [CPC_CODE_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data}) => {
      return [
        get_dim_keys_with_colours(data.data[0], MISC_COLOR_SCHEME)
      ]
    },
  },
  {
    id: FAMILIES_BY_PORTFOLIO_BY_PRIMARY_CPC_CODE,
    get_query: () => {
      return {
        "key": ["PFCC.cpc4", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["NOTNULL PF.primary_cpc", "= PFCC.pat_fam_id PF.pat_fam_id", "= PFCC.cpc_code PF.primary_cpc"]
      }
    },
    column_names: [CPC_CODE_COLUMN_NAME, ORG_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        get_dim_keys_with_colours(data.data[0], MISC_COLOR_SCHEME),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
      ]
    },
    hide_totals: true,
  },
  {
    id: FAMILIES_BY_TECH_BY_PRIMARY_CPC_CODE,
    get_query: () => {
      return {
        "key": ["PFCC.cpc4", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["NOTNULL PF.primary_cpc", "= PFCC.pat_fam_id PF.pat_fam_id", "= PFCC.cpc_code PF.primary_cpc"]
      }
    },
    column_names: [CPC_CODE_COLUMN_NAME, TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        get_dim_keys_with_colours(data.data[0], MISC_COLOR_SCHEME),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS],
      ]
    },
    hide_totals: true,
  },
  {
    id: FAMILIES_BY_FULL_PRIMARY_CPC_CODE,
    get_query: () => {
      return {
        "key": ["PFCC.cpc_code"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["NOTNULL PF.primary_cpc", "= PFCC.pat_fam_id PF.pat_fam_id", "= PFCC.cpc_code PF.primary_cpc"]
      }
    },
    column_names: [CPC_CODE_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data}) => {
      return [
        get_dim_keys_with_colours(data.data[0], MISC_COLOR_SCHEME)
      ]
    },
  },
  {
    id: AVG_AGE_AT_EXPIRY_BY_ORG_BY_TECH_ID,
    get_query: ({data_creation_date}) => {
      const year_ten_years_ago = get_year_10_years_ago(data_creation_date)
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["AVG / PF.age_at_expiry 365"],
        "constraint": ["= PF.status 'expired'", "NOTNULL PF.expiry_date", `> PF.expiry_year ${year_ten_years_ago}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Average age at expiry'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    value_formatter: decimal_value_formatter(1),
    value_rounder: decimal_value_formatter(1), // used by data export (i.e. for excel)
    hide_totals: true,
    no_rollups: no_rollups,
    no_value_label: NOT_AVAILABLE_LABEL,
  },

  {
    id: AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID,
    get_query: ({data_creation_date}) => {
      const year_ten_years_ago = get_year_10_years_ago(data_creation_date)
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["AVG / PF.time_to_grant 365"],
        "constraint": ["NOTNULL PF.granted_date", `> PF.granted_year ${year_ten_years_ago}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Average age'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    value_formatter: decimal_value_formatter(1),
    value_rounder: decimal_value_formatter(1), // used by data export (i.e. for excel)
    hide_totals: true,
    no_rollups: no_rollups,
    no_value_label: NOT_AVAILABLE_LABEL,
  },

  {
    id: AVG_AGE_OF_GRANTED_FAMILY_BY_ORG_BY_TECH_ID,
    get_query: ({data_creation_date}) => {
      const current_date = to_date(data_creation_date)
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id", "PF.priority_date"],
        "value": [`- '${current_date}' PF.priority_date`],
        "constraint": [`IN PF.status (${STATUS_GRANTED})`, "NOTNULL PF.priority_date"]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Average age'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    transform_data: transform_granted_families_age_data,
    value_formatter: decimal_value_formatter(1),
    value_rounder: decimal_value_formatter(1), // used by data export (i.e. for excel)
    hide_totals: true,
    no_rollups: no_rollups,
    no_value_label: NOT_AVAILABLE_LABEL,
  },

  {
    id: FORWARD_CITATIONS_BY_ORG_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["SUM PF.num_in_citations"],
        "constraint": ["> PF.num_in_citations 0"]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Forward citations'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  {
    id: BACKWARD_CITATIONS_BY_ORG_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["SUM PF.num_out_citations"],
        "constraint": ["> PF.num_out_citations 0"]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Backward citations'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  {
    check_if_available: not_available,
    id: GRANTED_FAMILIES_BY_PRIORITY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ?  get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: GRANTED_FAMILIES_BY_GRANT_YEAR_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PF.granted_year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [GRANT_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: GRANTED_FAMILIES_BY_GRANT_YEAR_BY_TECHNOLOGY_ID,
    get_query: () => {
      return {
        "key": ["PF.granted_year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [GRANT_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_PENDING})`]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, 'Pending patent families'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: PENDING_FAMILIES_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_PENDING})`]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    check_if_available: not_available,
    id: PENDING_FAMILIES_BY_PRIORITY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_PENDING})`]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, 'Pending patent families'],
    get_key_dims: ({data}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: AGE_AT_EXPIRY_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["/ PF.age_at_expiry 365", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["= PF.status 'expired'", "NOTNULL PF.expiry_date"]
      }
    },
    column_names: ['Age at expiry', ORG_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        get_dim_keys_with_colours(
          filter_expiry_ages_out_of_range(data.data[0]),
          PORTFOLIO_COLOR_SCHEME
        ),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    timeseries_key_dim_idxs: [0],
    hide_timerange_control: true,
  },

  {
    id: AGE_AT_EXPIRY_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["/ PF.age_at_expiry 365", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["= PF.status 'expired'", "NOTNULL PF.expiry_date"]
      }
    },
    column_names: ['Age at expiry', TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        get_dim_keys_with_colours(
          filter_expiry_ages_out_of_range(data.data[0]),
          TECH_COLOR_SCHEME
        ),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    timeseries_key_dim_idxs: [0],
    hide_timerange_control: true,
  },

  {
    id: PERCENTAGE_EXPIRED_BY_AGE_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["/ PF.age_at_expiry 365", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["= PF.status 'expired'"]
      }
    },
    column_names: ['Age', ORG_COLUMN_NAME, 'Expired patent families'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        get_dim_keys_with_colours(
          filter_expiry_ages_out_of_range(data.data[0]),
          PORTFOLIO_COLOR_SCHEME
        ),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    transform_data: to_cumulative_percentages,
    timeseries_key_dim_idxs: [0],
    hide_timerange_control: true,
    value_formatter: percentage_value_formatter(2),
    value_suffix: PERCENTAGE_SYMBOL,
    hide_totals: true,
    no_rollups: no_rollups
  },

  {
    check_if_available: not_available,
    id: GRANTED_FAMILIES_BY_PORTFOLIO_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [GEO_COLUMN_NAME, ORG_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        deref_data[SELECTED_GEOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
  },

  {
    id: PATFAMS_WITH_GRANTS_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PFT.country_code"],
        "value": ["COUNT DISTINCT PFT.pat_fam_id"],
        "constraint": [`= PFT.status ${STATUS_GRANTED}`]
      }
    },
    column_names: [GEO_COLUMN_NAME, 'Families with grants'],
    get_key_dims: ({deref_data}) => {
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        selected_geos_excluding_wipo
      ]
    },
    hide_totals: true
  },

  {
    id: PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PFT.pat_fam_id"],
        "constraint": [`= PFT.status ${STATUS_GRANTED}`]
      }
    },
    column_names: [GEO_COLUMN_NAME, ORG_COLUMN_NAME, 'Families with grants'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        selected_geos_excluding_wipo,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    hide_totals: true
  },

  {
    check_if_available: not_available,
    id: GRANTED_FAMILIES_BY_TECH_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [GEO_COLUMN_NAME, TECH_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        deref_data[SELECTED_GEOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    hide_totals: true
  },

  {
    id: PATFAMS_WITH_GRANTS_BY_TECH_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PFT.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [GEO_COLUMN_NAME, TECH_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        selected_geos_excluding_wipo,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    hide_totals: true
  },

  {
    id: PATFAMS_WITH_GRANTS_BY_REGION_ID,
    get_query: ({region_column}) => {
      return {
        "key": [`TTR.${region_column}`],
        "value": ["COUNT DISTINCT PFT.pat_fam_id"],
        "constraint": [`= PFT.status ${STATUS_GRANTED}`]
      }
    },
    column_names: [REGION_COLUMN_NAME, 'Families with grants'],
    get_key_dims: ({data}) => {
      return [
        get_region_dim_keys(data.data[0])
      ]
    },
    show_region_grouping_selector: true,
    hide_totals: true
  },

  {
    id: PATFAMS_WITH_GRANTS_BY_PORTFOLIO_BY_REGION_ID,
    get_query: ({region_column}) => {
      return {
        "key": [`TTR.${region_column}`, "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PFT.pat_fam_id"],
        "constraint": [`= PFT.status ${STATUS_GRANTED}`]
      }
    },
    column_names: [REGION_COLUMN_NAME, ORG_COLUMN_NAME, 'Families with grants'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        get_region_dim_keys(data.data[0]),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    show_region_grouping_selector: true,
    hide_totals: true
  },

  {
    id: PATFAMS_WITH_GRANTS_BY_TECH_BY_REGION_ID,
    get_query: ({ region_column }) => {
      return {
        "key": [`TTR.${region_column}`, "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PFT.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [REGION_COLUMN_NAME, TECH_COLUMN_NAME, 'Families with grants'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        get_region_dim_keys(data.data[0]),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    show_region_grouping_selector: true,
    hide_totals: true
  },

  {
    id: PENDING_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_PENDING})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Pending patent families'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    }
  },

  {
    id: PENDING_FAMILIES_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_PENDING})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, 'Pending patent families'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])
      ]
    }
  },

  {
    id: PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PF.status"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, 'Active families with pending status'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])
      ]
    },
    transform_data: transform_counts_by_status_to_percent_pending,
    value_formatter: percentage_value_formatter(2),
    value_suffix: PERCENTAGE_SYMBOL,
    hide_totals: true,
    no_rollups: no_rollups,
  },
  {
    id: PENDING_FAMILIES_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_PENDING})`]
      }
    },
    column_names: [TECH_COLUMN_NAME, 'Pending patent families'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS])
      ]
    }
  },

  {
    id: PENDING_FAMILIES_BY_PORTFOLIO_BY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PFSBY.year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PFSBY.status (${STATUS_PENDING})`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, ORG_COLUMN_NAME, 'Pending patent families'],
    hide_row_totals: true,
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: PENDING_FAMILIES_BY_TECH_BY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PFSBY.year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PFSBY.status (${STATUS_PENDING})`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, TECH_COLUMN_NAME, 'Pending patent families'],
    hide_row_totals: true,
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PF.publication_year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["NOTNULL PF.publication_date"]
      }
    },
    column_names: [PUBLICATION_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PF.publication_year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["NOTNULL PF.publication_date"]
      }
    },
    column_names: [PUBLICATION_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS],
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: FAMILIES_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
    get_query: () => {
      return {
        "key": [`PF.priority_year`, "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: NUM_FAMILIES_BY_PRIO_YEAR_ID,
    get_query: () => {
      return {
        "key": [`PF.priority_year`],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "sort": [`PF.priority_year`]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data_creation_date, data}) => {
      return [
        get_year_items(get_extent_from_years_column_incl_last_10_years(data.data[0], data_creation_date))
      ]
    },
    histogram_bucket_key_dim_idxs: [0]
  },

  {
    id: FAMILIES_BY_TECH_BY_PRIORITY_YEAR_ID,
    get_query: () => {
      return {
        "key": [`PF.priority_year`, "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    timeseries_key_dim_idxs: [0],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
  },

  {
    id: AVG_PRIORITY_YEAR_BY_ORG_ID,
    get_query: () => {
      return {
        key: ["PFTP.portfolio_id"],
        value: ["ROUND AVG PF.priority_year 2"],
        constraint: [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, 'Average priority year'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]),
      ]
    },
    show_average: false,
    value_formatter: parseInt,
    hover_value_format: '.0f',
    hide_totals: true,
    no_rollups: no_rollups,
    axis_range: (y_min, y_max) => [Math.round(y_min) - 2, Math.round(y_max) + 2],
    no_value_label: NOT_AVAILABLE_LABEL
  },

  {
    id: FAMILIES_BY_PORTFOLIO_BY_GRANTED_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PF.granted_year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [GRANT_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: FAMILIES_BY_TECH_BY_GRANTED_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PF.granted_year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [GRANT_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    timeseries_key_dim_idxs: [0],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
  },

  {
    check_if_available: not_available,
    id: GRANTS_BY_PRIORITY_YEAR_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PF.priority_year"],
        "value": ["SUM PFT.num_publications"],
        "constraint": ["= PF.status 'granted'"],
        "sort": ["PF.priority_year"]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, ACTIVE_GRANTS_COLUMN_NAME],
    get_key_dims: ({data}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: GRANTS_BY_PRIORITY_YEAR_BY_PORTFOLIO_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PFTP.portfolio_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": ["= PFT.status 'granted'"],
        "sort": ["PF.priority_year"]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, ACTIVE_GRANTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: GRANTS_BY_PRIORITY_YEAR_BY_TECHNOLOGY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PFTT.technology_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": ["= PFT.status 'granted'"],
        "sort": ["PF.priority_year"]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, ACTIVE_GRANTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: GRANTS_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFT.country_code"],
        "value": ["SUM PFT.num_publications"],
        "constraint": ["= PFT.status 'granted'"]
      }
    },
    column_names: [GEO_COLUMN_NAME, ACTIVE_GRANTS_COLUMN_NAME],
    get_key_dims: ({deref_data}) => {
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        selected_geos_excluding_wipo
      ]
    },
    is_rollup_available: function({view_id}) {
      return view_id !== CHOROPLETH_MAP_ID
    }
  },

  {
    id: TOTAL_NUM_PUBLICATIONS_BY_PORTFOLIO_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["SUM PFT.num_publications"]
      }
    },
    column_names: [ORG_COLUMN_NAME, PATENTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    }
  },

  {
    id: TOTAL_NUM_PUBLICATIONS_BY_TECHNOLOGY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["SUM PFT.num_publications"]
      }
    },
    column_names: [TECH_COLUMN_NAME, PATENTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    }
  },

  {
    check_if_available: not_available,
    id: GRANTS_BY_GRANTYEAR_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PF.granted_year", "PFT.country_code"],
        "value": ["SUM PFT.num_publications"],
        "constraint": [
          "NOTNULL PF.granted_date",
          "= PFT.status 'granted'"
        ]
      }
    },
    column_names: [YEAR_COLUMN_NAME, GEO_COLUMN_NAME, 'Publications'],
    timeseries_key_dim_idxs: [0],
    get_key_dims: ({deref_data, data_creation_date}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        deref_data[SELECTED_GEOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
  },

  {
    id: GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTP.portfolio_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": [`= PFT.status ${STATUS_GRANTED}`]
      }
    },
    column_names: [GEO_COLUMN_NAME, ORG_COLUMN_NAME, ACTIVE_GRANTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        selected_geos_excluding_wipo,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
  },

  {
    id: GRANTS_BY_TECHNOLOGY_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTT.technology_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": [`= PFT.status ${STATUS_GRANTED}`]
      }
    },
    column_names: [GEO_COLUMN_NAME, TECH_COLUMN_NAME, ACTIVE_GRANTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        selected_geos_excluding_wipo,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  {
    check_if_available: not_available,
    id: PUBLICATIONS_BY_PUB_YEAR_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PF.publication_year", "PFT.country_code"],
        "value": ["SUM PFT.num_publications"],
        "sort": ["PF.publication_year"],
        "constraint": ["NOTNULL PF.publication_date"]
      }
    },
    column_names: [PUBLICATION_YEAR_COLUMN_NAME, GEO_COLUMN_NAME, 'Publications'],
    get_key_dims: ({deref_data, data_creation_date}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        deref_data[SELECTED_GEOS]
      ]
    },
    timeseries_key_dim_idxs: [0],
  },

  {
    id: APPLICATIONS_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFT.country_code"],
        "value": ["SUM PFT.num_publications"],
        "constraint": ["= PFT.status 'pending'"]
      }
    },
    column_names: [GEO_COLUMN_NAME, 'Patents pending'],
    get_key_dims: ({deref_data}) => {
      return [
        deref_data[SELECTED_GEOS]
      ]
    },
  },
  {
    id: APPLICATIONS_BY_PORTFOLIO_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTP.portfolio_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": ["= PFT.status 'pending'"]
      }
    },
    column_names: [GEO_COLUMN_NAME, ORG_COLUMN_NAME, 'Patents pending'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        deref_data[SELECTED_GEOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },

  },
  {
    id: APPLICATIONS_BY_TECH_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTT.technology_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": ["= PFT.status 'pending'"]
      }
    },
    column_names: [GEO_COLUMN_NAME, TECH_COLUMN_NAME, 'Patents pending'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        deref_data[SELECTED_GEOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },
  {
    id: APPLICATIONS_VS_GRANTS_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFT.status"],
        "value": ["SUM PFT.num_publications"],
        "constraint": [`IN PFT.status (${STATUS_GRANTED}, ${STATUS_PENDING})`]
      }
    },
    column_names: [GEO_COLUMN_NAME, STATUS_COLUMN_NAME, 'Patents'],
    hide_row_totals: true,
    get_key_dims: ({deref_data}) => {
      return [
        deref_data[SELECTED_GEOS],
        ALIVE_PATFAM_STATUS_DIMS
      ]
    },
  },

  {
    id: EXPIRED_BY_YEAR_BY_ORGANISATION_ID,
    get_query: () => {
      return {
        "key": ["PF.expiry_year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`= PF.status ${STATUS_EXPIRED}`, "NOTNULL PF.expiry_date"]
      }
    },
    column_names: [EXPIRY_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, 'Expired patent families'],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: EXPIRING_BY_YEAR_BY_ORGANISATION_ID,
    get_query: () => {
      return {
        "key": ["PF.expiry_year", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`= PF.status ${STATUS_GRANTED}`, "NOTNULL PF.expiry_date"]
      }
    },
    column_names: [EXPIRY_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_next_20_years(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_next_20_years(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: EXPIRED_BY_YEAR_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PF.expiry_year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`= PF.status ${STATUS_EXPIRED}`, "NOTNULL PF.expiry_date"]
      }
    },
    column_names: [EXPIRY_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, 'Expired patent families'],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: EXPIRING_BY_YEAR_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PF.expiry_year", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`= PF.status ${STATUS_GRANTED}`, "NOTNULL PF.expiry_date"]
      }
    },
    column_names: [EXPIRY_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_next_20_years(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_next_20_years(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: COST_BY_COUNTRY_BY_YEAR_ID,
    get_query: ({data_creation_date}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      return {
        "key": ["PFC.year", "PFC.country_code"],
        "value": ["SUM PFC.cost"],
        "constraint": [`BETWEEN PFC.year ${since_2000_years_clause}`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, GEO_COLUMN_NAME, COST_DESCRIPTION],
    full_length_value_formatter: long_dollar_formatter,
    value_formatter: short_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    get_key_dims: ({deref_data, data_creation_date}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        deref_data[SELECTED_GEOS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
    timeseries_key_dim_idxs: [0],
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
  },

  {
    id: COST_BY_REGION_ID,
    get_query: ({data_creation_date, region_column}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      return {
        "key": [`CTTR.${region_column}`],
        "value": ["SUM PFC.cost"],
        "constraint": [`BETWEEN PFC.year ${since_2000_years_clause}`, `NOT IN CTTR.${region_column} ('Other')`]
      }
    },
    column_names: [REGION_COLUMN_NAME, COST_DESCRIPTION],
    value_formatter: short_dollar_formatter,
    full_length_value_formatter: long_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    get_key_dims: ({data}) => {
      return [
        get_region_dim_keys(data.data[0])
      ]
    },
    show_region_grouping_selector: true,
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
  },

  {
    id: COST_BY_COUNTRY_ID,
    get_query: ({data_creation_date}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      return {
        "key": ["PFC.country_code"],
        "value": ["SUM PFC.cost"],
        "constraint": [`BETWEEN PFC.year ${since_2000_years_clause}`]
      }
    },
    column_names: [GEO_COLUMN_NAME, COST_DESCRIPTION],
    value_formatter: short_dollar_formatter,
    full_length_value_formatter: long_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    get_key_dims: ({deref_data}) => {
      return [
        deref_data[SELECTED_GEOS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
  },

  {
    id: COST_BY_PORTFOLIO_BY_TECH_ID,
    get_query: ({data_creation_date}) => {
      const last_few_full_years_clause = get_last_few_full_years_clause(data_creation_date)
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": [`/ SUM PFC.cost ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT}`],
        "constraint": [`BETWEEN PFC.year ${last_few_full_years_clause}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, `Cost (${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} yr avg)`],
    full_length_value_formatter: long_dollar_formatter,
    value_formatter: short_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    hide_totals: false,
    no_rollups: no_rollups,
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
  },

  {
    id: COST_BY_PORTFOLIO_BY_COUNTRY_ID,
    get_query: ({data_creation_date}) => {
      const last_few_full_years_clause = get_last_few_full_years_clause(data_creation_date)
      return {
        "key": [`PFTP.portfolio_id`, `PFC.country_code`],
        "value": [`/ SUM PFC.cost ${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT}`],
        "constraint": [`BETWEEN PFC.year ${last_few_full_years_clause}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, GEO_COLUMN_NAME, `Cost (${DEFAULT_RECENT_LAST_FEW_YEARS_COUNT} yr avg)`],
    full_length_value_formatter: long_dollar_formatter,
    value_formatter: short_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    hide_totals: false,
    no_rollups: no_rollups,
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        deref_data[SELECTED_GEOS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
  },

  {
    id: COST_BY_PORTFOLIO_BY_YEAR_ID,
    get_query: ({data_creation_date}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      return {
        "key": ["PFC.year", "PFTP.portfolio_id"],
        "value": ["SUM PFC.cost"],
        "constraint": [`BETWEEN PFC.year ${since_2000_years_clause}`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, ORG_COLUMN_NAME, COST_DESCRIPTION],
    full_length_value_formatter: long_dollar_formatter,
    value_formatter: short_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: FUTURE_COST_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PFC.year", "PFC.country_code"],
        "value": ["SUM PFC.cost"],
        "constraint": ["> PFC.year $creation_year"]
      }
    },
    column_names: [YEAR_COLUMN_NAME, GEO_COLUMN_NAME, COST_DESCRIPTION],
    value_formatter: short_dollar_formatter,
    full_length_value_formatter: long_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    get_key_dims: ({deref_data, data_creation_date}) => {
      return [
        get_year_items(get_next_10_years_exclusive_extent(data_creation_date)),
        deref_data[SELECTED_GEOS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
    timeseries_key_dim_idxs: [0],
    get_default_selected_timerange: (data_creation_date) => get_next_10_years_exclusive_extent(data_creation_date),
  },

  {
    id: COST_BY_TECH_BY_YEAR_ID,
    get_query: ({data_creation_date}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      return {
        "key": ["PFC.year", "PFTT.technology_id"],
        "value": ["SUM PFC.cost"],
        "constraint": [`BETWEEN PFC.year ${since_2000_years_clause}`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, TECH_COLUMN_NAME, COST_DESCRIPTION],
    full_length_value_formatter: long_dollar_formatter,
    value_formatter: short_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: AVERAGE_COST_BY_PORTFOLIO_ID,
    get_query: ({data_creation_date}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      const year_ten_years_ago = get_year_10_years_ago(data_creation_date)
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["/ SUM PFC.cost COUNT DISTINCT PFC.pat_fam_id"],
        "constraint": [`BETWEEN PF.priority_year ${COST_DATA_START_YEAR} ${year_ten_years_ago}`, `BETWEEN PFC.year ${since_2000_years_clause}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, COST_DESCRIPTION],
    value_formatter: short_dollar_formatter,
    full_length_value_formatter: long_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    hide_totals: true,
    no_rollups: no_rollups,
    show_average: false,
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
  },

  {
    id: AVERAGE_COST_BY_TECH_ID,
    get_query: ({data_creation_date}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      const year_ten_years_ago = get_year_10_years_ago(data_creation_date)
      return {
        "key": ["PFTT.technology_id"],
        "value": ["/ SUM PFC.cost COUNT DISTINCT PFC.pat_fam_id"],
        "constraint": [`BETWEEN PF.priority_year ${COST_DATA_START_YEAR} ${year_ten_years_ago}`, `BETWEEN PFC.year ${since_2000_years_clause}`]
      }
    },
    column_names: [TECH_COLUMN_NAME, COST_DESCRIPTION],
    value_formatter: short_dollar_formatter,
    full_length_value_formatter: long_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    hide_totals: true,
    no_rollups: no_rollups,
    show_average: false,
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS])
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
  },

  {
    id: AVERAGE_COST_BY_ORG_AND_TECH_ID,
    get_query: ({data_creation_date}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      const year_ten_years_ago = get_year_10_years_ago(data_creation_date)
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["/ SUM PFC.cost COUNT DISTINCT PFC.pat_fam_id"],
        "constraint": [`BETWEEN PF.priority_year ${COST_DATA_START_YEAR} ${year_ten_years_ago}`, `BETWEEN PFC.year ${since_2000_years_clause}`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, COST_DESCRIPTION],
    value_formatter: short_dollar_formatter,
    full_length_value_formatter: long_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    hide_totals: true,
    no_rollups: no_rollups,
    show_average: false,
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
  },

  {
    id: AVERAGE_COST_BY_AGE_ID,
    check_if_available: not_available,
    report_reader_endpoint: COST_DATASET_ENDPOINT,
    get_query: () => {
      return {
           "key": ["`+ - PFSBY.year PF.priority_year 1`"," / `SUM PFC.cost` `COUNT DISTINCT PF.pat_fam_id`"],
           "subquery":[
             { "key": ["+ - PFC.year PF.priority_year 1"],
               "value": ["SUM PFC.cost"],
                "constraint": ["BETWEEN PFC.year PF.priority_year PF.expiry_year",
                               "BETWEEN PFC.year PF.priority_year $creation_year",
                               "BETWEEN + - PFC.year PF.priority_year 1 0 20",
                               ">= PFC.year 2000"]
              },
             { "key": ["+ - PFSBY.year PF.priority_year 1"],
               "value": ["COUNT DISTINCT PF.pat_fam_id"],
               "constraint": ["BETWEEN PFSBY.year PF.priority_year PF.expiry_year",
                              "BETWEEN PFSBY.year PF.priority_year $creation_year",
                              "BETWEEN + - PFSBY.year PF.priority_year 1 0 20",
                              ">= PFSBY.year 2000",
                              "OR = PFSBY.status 'pending' = PFSBY.status 'granted'"]
             }
           ],
           "join_constraint":["= `+ - PFC.year PF.priority_year 1` `+ - PFSBY.year PF.priority_year 1`"],
           "sort": ["`+ - PFSBY.year PF.priority_year 1`"]
      }
      },
    get_query_for_clickthroughs: () => {
      return {
           "key": ["+ - PFSBY.year PF.priority_year 1"],
           "value": ["COUNT DISTINCT PF.pat_fam_id"],
           "constraint": ["BETWEEN PFSBY.year PF.priority_year PF.expiry_year",
                          "BETWEEN PFSBY.year PF.priority_year $creation_year",
                          "BETWEEN + - PFSBY.year PF.priority_year 1 0 20",
                          ">= PFSBY.year 2000",
                          "OR = PFSBY.status 'pending' = PFSBY.status 'granted'"]
          }
    },
    column_names: ['Age', COST_DESCRIPTION],
    value_formatter: short_dollar_formatter,
    full_length_value_formatter: long_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    hide_totals: true,
    show_average: false,
    no_sorting: true,
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
    get_key_dims: ({data}) => {
      return [
        get_age_items(data.data[0])
      ]
    },
  },

  //DISPUTES DATASETS
  {
    id: LITIGATIONS_BY_DEFENDANT_BY_START_YEAR_ID,
    get_query: () => {
      return {
        "key": ["D.start_or_end_year", "PTA.portfolio_id"],
        "value": ["COUNT DISTINCT D.dispute_id"],
        "constraint": ["OR NOTNULL D.start_date NOTNULL D.end_date", `IN D.route (${LITIGATION_ROUTES_STRING})`, "= DPO.role 'DEFENDANT'"],
        "sort": ["D.start_or_end_year"]
      }
    },
    column_names: [YEAR_COLUMN_NAME, ORG_COLUMN_NAME, 'Litigations'],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_extent_from_years_column_incl_last_10_years(data.data[0], data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_role_based_litigation_query,
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
    requires_assignee_links: true,
    no_clickthrough: true
  },

  {
    id: LITIGATIONS_BY_DEFENDANT_BY_JURISDICTION,
    get_query: () => {
      // the "NOTNULL P.portfolio_id" restriction is added to be able to join into the portfolio and return the right number of disputes
      return {
        "key": ["D.jurisdiction"],
        "value": ["COUNT DISTINCT D.dispute_id"],
        "constraint": [`IN D.route (${LITIGATION_ROUTES_STRING})`, "= DPO.role 'DEFENDANT'", "NOTNULL PTA.portfolio_id"],
      }
    },
    column_names: ['Jurisdiction', 'Litigations'],
    get_key_dims: ({data, deref_data}) => {
      return [
         get_jurisdiction_data(deref_data[SELECTED_GEOS], data.data),
        ]
    },
    add_custom_selections_to_query: add_custom_selections_to_role_based_litigation_query,
    requires_assignee_links: true,
    no_clickthrough: true
  },

  {
    id: LITIGATIONS_BY_PLAINTIFF_BY_JURISDICTION,
    get_query: () => {
      return {
        "key": ["D.jurisdiction"],
        "value": ["COUNT DISTINCT D.dispute_id"],
        "constraint": [`IN D.route (${LITIGATION_ROUTES_STRING})`, "= DPO.role 'PLAINTIFF'", "NOTNULL PTA.portfolio_id"],
      }
    },
    column_names: ['Jurisdiction', 'Litigations'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_jurisdiction_data(deref_data[SELECTED_GEOS], data.data),
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_role_based_litigation_query,
    requires_assignee_links: true,
    no_clickthrough: true
  },

  {
    id: LITIGATIONS_BY_PLAINTIFF_BY_START_YEAR_ID,
    get_query: () => {
      return {
        "key": ["D.start_or_end_year", "PTA.portfolio_id"],
        "value": ["COUNT DISTINCT D.dispute_id"],
        "constraint": ["OR NOTNULL D.start_date NOTNULL D.end_date", `IN D.route (${LITIGATION_ROUTES_STRING})`, "= DPO.role 'PLAINTIFF'"],
        "sort": ["D.start_or_end_year"]
      }
    },
    column_names: [YEAR_COLUMN_NAME, ORG_COLUMN_NAME, 'Litigations'],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        get_year_items(get_extent_from_years_column_incl_last_10_years(data.data[0], data_creation_date)),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_role_based_litigation_query,
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
    requires_assignee_links: true,
    no_clickthrough: true
  },

  {
    id: LITIGATIONS_OFFENSIVE_DISPUTES,
    get_key_dims: () => {
      return []
    },
    report_reader_endpoint: DISPUTES_DATASET_ENDPOINT,
    get_query: () => {return {"role": ROLE_PLAINTIFF}},
    add_custom_selections_to_query: add_custom_selections_to_dispute_table_query,
    filter_by_dispute_type: true,
    requires_assignee_links: true
  },

  {
    id: LITIGATIONS_DEFENSIVE_DISPUTES,
    get_key_dims: () => {
      return []
    },
    report_reader_endpoint: DISPUTES_DATASET_ENDPOINT,
    get_query: () => {return {"role": ROLE_DEFENDANT}},
    add_custom_selections_to_query: add_custom_selections_to_dispute_table_query,
    filter_by_dispute_type: true,
    requires_assignee_links: true
  },

  {
    id: LITIGATIONS_CROSS_DISPUTES,
    get_key_dims: () => { return [] },
    report_reader_endpoint: DISPUTES_DATASET_ENDPOINT,
    get_query: () => {return {"role": ROLE_EITHER}},
    add_custom_selections_to_query: add_custom_selections_to_dispute_table_query,
    filter_by_dispute_type: true,
    requires_assignee_links: true,
  },

  {
    id: LITIGATIONS_ALL_PATFAMS_DISPUTES,
    get_key_dims: () => {
      return []
    },
    report_reader_endpoint: DISPUTES_DATASET_ENDPOINT,
    get_query: () => {return {"role": ROLE_ANY}},
    add_custom_selections_to_query: add_custom_selections_to_dispute_table_query,
    filter_by_dispute_type: true
  },

  {
    id: LITIGATED_FAMILIES_BY_TECH_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT DP.pat_fam_id"],
        "constraint": [`IN D.route (${LITIGATION_ROUTES_STRING})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Litigated patent families'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_patfam_based_litigation_query,
  },

  {
    id: LITIGATED_FAMILIES_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT DP.pat_fam_id"],
        "constraint": [`IN D.route (${LITIGATION_ROUTES_STRING})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, 'Litigated patent families'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_patfam_based_litigation_query,
  },

  {
    id: LITIGATED_FAMILIES_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["COUNT DISTINCT DP.pat_fam_id"],
        "constraint": [`IN D.route (${LITIGATION_ROUTES_STRING})`]
      }
    },
    column_names: [TECH_COLUMN_NAME, 'Litigated patent families'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS])
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_patfam_based_litigation_query,
  },

  {
    id: LITIGATION_BALANCE_ID,
    get_query: () => {
      return {
        "key": ["PTA.portfolio_id", "DPO.role"],
        "value": ["COUNT DISTINCT D.dispute_id"],
        "constraint": [`IN D.route (${LITIGATION_ROUTES_STRING})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, 'Role', 'Disputes'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        DISPUTE_ROLE_DIMS
      ]
    },
    add_custom_selections_to_query: add_custom_selections_to_role_based_litigation_query,
    no_clickthrough: true,
    requires_assignee_links: true
  },

  {
    id: ACTIVE_SIZE_GROWTH_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFSBY.year"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PFSBY.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, PUBLICATION_YEAR_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        get_year_items(get_since_2000_by_month_created_extent(data_creation_date))
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_by_month_created_extent(data_creation_date),
    timeseries_key_dim_idxs: [1],
    no_rollups: no_rollups_for_size_growth,
  },

  {
    id: ACTIVE_SIZE_GROWTH_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id", "PFSBY.year"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PF.status (${STATUS_ACTIVE})`]
      }
    },
    column_names: [TECH_COLUMN_NAME, PUBLICATION_YEAR_COLUMN_NAME, ACTIVE_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS],
        get_year_items(get_since_2000_by_month_created_extent(data_creation_date))
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_by_month_created_extent(data_creation_date),
    timeseries_key_dim_idxs: [1],
    no_rollups: no_rollups_for_size_growth,
  },

  {
    id: GRANTED_SIZE_GROWTH_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFSBY.year"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PFSBY.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, GRANT_YEAR_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        get_year_items(get_since_2000_by_month_created_extent(data_creation_date))
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_by_month_created_extent(data_creation_date),
    timeseries_key_dim_idxs: [1],
    no_rollups: no_rollups_for_size_growth,
  },

  {
    id: GRANTED_SIZE_GROWTH_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id", "PFSBY.year"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`IN PFSBY.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [TECH_COLUMN_NAME, PUBLICATION_YEAR_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data, data_creation_date, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS],
        get_year_items(get_since_2000_by_month_created_extent(data_creation_date))
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_by_month_created_extent(data_creation_date),
    timeseries_key_dim_idxs: [1],
    no_rollups: no_rollups_for_size_growth,
  },

  {
    id: FAMILIES_BY_STATUS,
    get_query: () => {
      return {
        "key": ["PF.status"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "sort": ["DESC COUNT DISTINCT PF.pat_fam_id"]
      }
    },
    column_names: [STATUS_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data}) => {
      return [
        get_status_items(data.data[0])
      ]
    },
  },

  {
    id: NUM_FAMILIES_BY_SCORE_BAND_BY_TECHNOLOGY,
    get_query: () => {
      return {
        "key":[SCORE_BUCKET_KEY, "PFTT.technology_id"], // score to 1 decimal place (i.e. 0.12374 becomes 0.1)
        "value": ["COUNT DISTINCT PFTT.pat_fam_id"]
      }
    },
    column_names: ['Score band', TECH_COLUMN_NAME, 'Count'],
    //hide_row_totals: true,
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const score_band_items = get_default_classifier_score_bucket_items()
      return [
        score_band_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    histogram_bucket_key_dim_idxs: [0],
  },

  {
    id: NUM_FAMILIES_BY_SCORE_BAND_BY_ORGANISATION,
    get_query: () => {
      return {
        "key":[SCORE_BUCKET_KEY, "PFTP.portfolio_id"], // score to 1 decimal place (i.e. 0.12374 becomes 0.1)
        "value": ["COUNT DISTINCT PFTP.pat_fam_id"]
      }
    },
    column_names: ['Score band', ORG_COLUMN_NAME, 'Count'],
    //hide_row_totals: true,
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const score_band_items = get_default_classifier_score_bucket_items()
      return [
        score_band_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    histogram_bucket_key_dim_idxs: [0],
  },

  {
    id: NUM_FAMILIES_BY_SCORE_BAND,
    get_query: () => {
      return {
        "key":[SCORE_BUCKET_KEY], // score to 1 decimal place (i.e. 0.12374 becomes 0.1)
        "value": ["COUNT DISTINCT PFTT.pat_fam_id"]
      }
    },
    column_names: ['Score band', 'Count'],
    //hide_row_totals: true,
    get_key_dims: () => {
      const score_band_items = get_default_classifier_score_bucket_items()
      return [
        score_band_items
      ]
    },
    histogram_bucket_key_dim_idxs: [0],
  },

  {
    id: APPLICATIONS_AND_GRANTS_BY_PORTFOLIO_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTP.portfolio_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": [`IN PFT.status (${STATUS_GRANTED}, ${STATUS_PENDING})`]
      }
    },
    column_names: [GEO_COLUMN_NAME, ORG_COLUMN_NAME, PATENTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        deref_data[SELECTED_GEOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
  },

  {
    id: APPLICATIONS_AND_GRANTS_BY_TECH_BY_COUNTRY_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFT.country_code", "PFTT.technology_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": [`IN PFT.status (${STATUS_GRANTED}, ${STATUS_PENDING})`]
      }
    },
    column_names: [GEO_COLUMN_NAME, TECH_COLUMN_NAME, PATENTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        deref_data[SELECTED_GEOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  {
    id: APPLICATIONS_AND_GRANTS_BY_TECH_BY_PORTFOLIO_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": [`IN PFT.status (${STATUS_GRANTED}, ${STATUS_PENDING})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, PATENTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    }
  },
  {
    id: GRANTS_BY_TECH_BY_PORTFOLIO_ID,
    is_patent_count: true,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["SUM PFT.num_publications"],
        "constraint": [`= PFT.status (${STATUS_GRANTED})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, PATENTS_COLUMN_NAME],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    }
  },
  {
    id: GRANTED_FAMILIES_BY_PRIORITY_YEAR_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PFT.country_code"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: ["= PFT.status 'granted'"]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, GEO_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        year_range_items,
        selected_geos_excluding_wipo,
      ]
    },
    timeseries_key_dim_idxs: [0],
    hide_totals: true
  },
  {
    id: GRANTED_FAMILIES_BY_PUBLICATION_YEAR_BY_COUNTRY_ID,
    get_query: () => {
      return {
        "key": ["PF.publication_year", "PFT.country_code"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: ["= PFT.status 'granted'"]
      }
    },
    column_names: [PUBLICATION_YEAR_COLUMN_NAME, GEO_COLUMN_NAME, GRANTED_PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({data, deref_data}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      const selected_geos_excluding_wipo = exclude_wipo_from_selections(deref_data)
      return [
        year_range_items,
        selected_geos_excluding_wipo,
      ]
    },
    timeseries_key_dim_idxs: [0],
    hide_totals: true
  },

  {
    id: COST_BY_TYPE_BY_YEAR_ID,
    get_query: ({data_creation_date}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      return {
        "key": ["PFC.year"],
        "value": ["SUM PFC.application_cost", "SUM PFC.renewal_cost"],
        "constraint": [`BETWEEN PFC.year ${since_2000_years_clause}`]
      }
    },
    column_names: [YEAR_COLUMN_NAME, 'Type', 'Cost'],
    full_length_value_formatter: long_dollar_formatter,
    value_formatter: short_dollar_formatter,
    value_prefix: DOLLAR_PREFIX,
    get_key_dims: ({data_creation_date}) => {
      return [
        get_year_items(get_since_2000_extent(data_creation_date)),
        COST_DIMS
      ]
    },
    transform_data: transform_application_and_renewals_data,
    add_custom_selections_to_query: add_custom_selections_to_cost_query,
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },

  {
    id: PVIX_TOTAL_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["ROUND SUM PF.pvix_score 2"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },
    column_names: [ORG_COLUMN_NAME, 'Total PVIX scores'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])
      ]
    },
    no_value_label: NOT_AVAILABLE_LABEL,
  },

  {
    id: PVIX_TOTAL_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["ROUND SUM PF.pvix_score 2"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },
    column_names: [TECH_COLUMN_NAME, 'Total PVIX scores'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS])
      ]
    },
    no_value_label: NOT_AVAILABLE_LABEL,
  },

  {
    id: PVIX_TOTAL_BY_TECH_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["ROUND SUM PF.pvix_score 2"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Total PVIX scores'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    no_value_label: NOT_AVAILABLE_LABEL,
  },

  {
    id: PVIX_MEAN_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["ROUND / SUM PF.pvix_score COUNT DISTINCT PF.pat_fam_id 2"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },
    column_names: [ORG_COLUMN_NAME, 'PVIX (avg.)'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])
      ]
    },
    hide_totals: true,
    no_rollups: no_rollups,
    no_value_label: NOT_AVAILABLE_LABEL,
    value_formatter: decimal_value_with_leading_zeros_formatter(2),
  },

  {
    id: PVIX_MEAN_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["ROUND / SUM PF.pvix_score COUNT DISTINCT PF.pat_fam_id 2"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },
    column_names: [TECH_COLUMN_NAME, 'PVIX (avg.)'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS])
      ]
    },
    hide_totals: true,
    no_rollups: no_rollups,
    no_value_label: NOT_AVAILABLE_LABEL,
    value_formatter: decimal_value_with_leading_zeros_formatter(2),
  },

  {
    id: PVIX_MEAN_BY_TECH_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["ROUND / SUM PF.pvix_score COUNT DISTINCT PF.pat_fam_id 2"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'PVIX (avg.)'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    hide_totals: true,
    no_rollups: no_rollups,
    no_value_label: NOT_AVAILABLE_LABEL,
    value_formatter: decimal_value_with_leading_zeros_formatter(2),
  },

  {
    id: PVIX_MEAN_BY_PORTFOLIO_BY_PRIORITY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PFTP.portfolio_id"],
        "value": ["ROUND / SUM PF.pvix_score COUNT DISTINCT PF.pat_fam_id 2"],
        "constraint": ["NOTNULL PF.pvix_score"],
        "sort": ["PF.priority_year"]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, ORG_COLUMN_NAME, 'PVIX (avg.)'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    hide_totals: true,
    no_rollups: no_rollups,
    timeseries_key_dim_idxs: [0],
    no_value_label: NOT_AVAILABLE_LABEL,
    value_formatter: decimal_value_with_leading_zeros_formatter(2),
  },

  {
    id: PVIX_MEAN_BY_TECH_BY_PRIORITY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PFTT.technology_id"],
        "value": ["ROUND / SUM PF.pvix_score COUNT DISTINCT PF.pat_fam_id 2"],
        "constraint": ["NOTNULL PF.pvix_score"],
        "sort": ["PF.priority_year"]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, TECH_COLUMN_NAME, 'PVIX (avg.)'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const year_range_items = get_year_items_from_column(data.data[0])
      return [
        year_range_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    hide_totals: true,
    no_rollups: no_rollups,
    timeseries_key_dim_idxs: [0],
    no_value_label: NOT_AVAILABLE_LABEL,
    value_formatter: decimal_value_with_leading_zeros_formatter(2),
  },

  {
    id: PVIX_MEAN_BY_PORTFOLIO_BY_SIZE_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id", "ROUND / SUM PF.pvix_score COUNT DISTINCT PF.pat_fam_id 2"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },

    column_names: [ORG_COLUMN_NAME, 'Size (granted)', 'PVIX (avg.)'],

    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS])
      ]
    },

    hide_totals: true,
    no_rollups: no_rollups,
    value_to_sort_column_idx: 1,
    value_formatter: decimal_value_with_leading_zeros_formatter(2),
    axis_range: get_pvix_scatter_axis_range
  },

  {
    id: PVIX_MEAN_BY_TECHNOLOGY_BY_SIZE_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id", "ROUND / SUM PF.pvix_score COUNT DISTINCT PF.pat_fam_id 2"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },
    column_names: [TECH_COLUMN_NAME, 'Size (granted)', 'PVIX (avg.)'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS])
      ]
    },

    hide_totals: true,
    no_rollups: no_rollups,
    value_to_sort_column_idx: 1,
    value_formatter: decimal_value_with_leading_zeros_formatter(2),
    axis_range: get_pvix_scatter_axis_range
  },

  {
    id: NUM_FAMILIES_BY_PVIX_BAND_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key":[PVIX_SCORE_BUCKET_KEY, "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PFTP.pat_fam_id"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },

    column_names: ['PVIX band', ORG_COLUMN_NAME, 'Proportion'],

    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const score_band_items = get_pvix_score_buckets(data)
      return [
        score_band_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    transform_data: (data) => to_percentages(transform_pvix_histogram_data(data)),
    value_formatter: percentage_value_formatter(1),
    value_rounder: percentage_value_formatter(1),
    value_suffix: PERCENTAGE_SYMBOL,
    histogram_bucket_key_dim_idxs: [0],
    no_rollups: no_rollups,
    hide_totals: true
  },

  {
    id: NUM_FAMILIES_BY_PVIX_BAND_BY_TECHNOLOGY_ID,

    get_query: () => {
      return {
        "key":[PVIX_SCORE_BUCKET_KEY, "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PFTP.pat_fam_id"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },

    column_names: ['PVIX band', TECH_COLUMN_NAME, 'Proportion'],

    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      const score_band_items = get_pvix_score_buckets(data)
      return [
        score_band_items,
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
    transform_data: (data) => to_percentages(transform_pvix_histogram_data(data)),
    value_formatter: percentage_value_formatter(1),
    value_rounder: percentage_value_formatter(1),
    value_suffix: PERCENTAGE_SYMBOL,
    histogram_bucket_key_dim_idxs: [0],
    no_rollups: no_rollups,
    hide_totals: true
  },

  {
    id: NUM_FAMILIES_BY_PVIX_BAND_ID,
    get_query: () => {
      return {
        "key":[PVIX_SCORE_BUCKET_KEY],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": ["NOTNULL PF.pvix_score"]
      }
    },
    column_names: ['PVIX band', 'Count'],
    get_key_dims: ({data}) => {
      const score_band_items = get_pvix_score_buckets(data)
      return [
        score_band_items
      ]
    },
    transform_data: transform_pvix_histogram_data,
    histogram_bucket_key_dim_idxs: [0],
  },

  {
    id: NUM_FAMILIES_BY_COST_BAND_ID,
    report_reader_endpoint: HISTOGRAM_ENDPOINT,
    get_query: ({data_creation_date}) => {
      const since_2000_years_clause = get_since_2000_year_range_clause(data_creation_date)
      const year_ten_years_ago = get_year_10_years_ago(data_creation_date)
      return {
        "key":[COST_BUCKET_KEY],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`BETWEEN PF.priority_year ${COST_DATA_START_YEAR} ${year_ten_years_ago}`, `BETWEEN PFC.year ${since_2000_years_clause}`]
      }
    },
    column_names: ['Cost band', 'Count'],
    get_key_dims: ({data}) => {
      const score_band_items = get_cost_buckets(data)
      return [
        score_band_items
      ]
    },
    transform_data: transform_cost_histogram_data,
    histogram_bucket_key_dim_idxs: [0],
    add_custom_selections_to_query: add_custom_selections_to_cost_query
  },

  {
    id: ENTRANCE_YEAR_BY_ORG_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id",  "PFTT.technology_id"],
        "value": ["MIN PF.priority_year"]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Earliest priority year'],
    get_key_dims: ({deref_data}) => {
      return [
        deref_data[SELECTED_PORTFOLIOS],
        deref_data[SELECTED_TECHS]
      ]
    },
    value_formatter: (v) => v,
    hide_totals: true,
    no_rollups: no_rollups,
    no_value_label: NOT_AVAILABLE_LABEL,
  },

  {
    id: GRANTED_COUNTRIES_PER_FAMILY_BY_ORG,
    report_reader_endpoint: HISTOGRAM_ENDPOINT,
    no_timefilter: true,
    get_query: () => {
      return {
        "key": [COUNTRY_DISTRIBUTION_BUCKET_KEY, "P.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        "constraint": [`= PFT.status 'granted'`],
        "sort": [`ASC ${COUNTRY_DISTRIBUTION_BUCKET_KEY}`]
      }
    },
    column_names: ['Number of countries', ORG_COLUMN_NAME, 'Proportion of families'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        get_count_items(data,0),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS]
      ]
    },
    transform_data: to_percentages,
    histogram_bucket_key_dim_idxs: [0],
    value_formatter: percentage_value_formatter(2),
    value_rounder: percentage_value_formatter(2),
    value_suffix: PERCENTAGE_SYMBOL,
    hide_totals: true,
    no_rollups: no_rollups,
  },

  {
    id: EXPIRED_FAMILIES_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_EXPIRED})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, 'Expired families'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]),
      ]
    },
  },

  {
    id: EXPIRED_FAMILIES_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_EXPIRED})`]
      }
    },
    column_names: [TECH_COLUMN_NAME, 'Expired families'],
    get_key_dims: ({data, deref_data}) => {
      return [
        get_filtered_dim_keys(data, 0, deref_data[SELECTED_TECHS]),
      ]
    },
  },

  {
    id: EXPIRED_FAMILIES_BY_PORTFOLIO_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PFTP.portfolio_id", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: [`IN PF.status (${STATUS_EXPIRED})`]
      }
    },
    column_names: [ORG_COLUMN_NAME, TECH_COLUMN_NAME, 'Expired families'],
    get_key_dims: ({data, deref_data, hide_no_data_dim_keys=true}) => {
      return [
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 0, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS]
      ]
    },
  },

  {
    id: PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PRIORITY_YEAR_ID,
    get_query: () => {
      return {
        "key": ["PF.priority_year", "PF.first_filing_country"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: ["NOTNULL PF.first_filing_country"]
      }
    },
    column_names: [PRIORITY_YEAR_COLUMN_NAME, FIRST_FILING_COUNTRY_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({ data, ref_data, data_creation_date }) => {
      return [
        get_year_items(get_since_1980_extent(data_creation_date)),
        get_countries_dim_keys_with_colours(data.data[1], ref_data)
      ]
    },
    get_default_selected_timerange: (data_creation_date) => get_last_10_years_extent(data_creation_date),
    timeseries_key_dim_idxs: [0],
  },
  {
    id: PATFAMS_BY_FIRST_FILING_COUNTRY_BY_PORTFOLIO_ID,
    get_query: () => {
      return {
        "key": ["PF.first_filing_country", "PFTP.portfolio_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: ["NOTNULL PF.first_filing_country"]
      }
    },
    column_names: [FIRST_FILING_COUNTRY_COLUMN_NAME, ORG_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({ data, ref_data, deref_data, hide_no_data_dim_keys=true }) => {
      return [
        get_countries_dim_keys_with_colours(data.data[0], ref_data),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_PORTFOLIOS]) : deref_data[SELECTED_PORTFOLIOS],
      ]
    },
  },
  {
    id: PATFAMS_BY_FIRST_FILING_COUNTRY_BY_TECH_ID,
    get_query: () => {
      return {
        "key": ["PF.first_filing_country", "PFTT.technology_id"],
        "value": ["COUNT DISTINCT PF.pat_fam_id"],
        constraint: ["NOTNULL PF.first_filing_country"]
      }
    },
    column_names: [FIRST_FILING_COUNTRY_COLUMN_NAME, TECH_COLUMN_NAME, PATENT_FAMILIES_COLUMN_NAME],
    get_key_dims: ({ data, ref_data, deref_data, hide_no_data_dim_keys=true }) => {
      return [
        get_countries_dim_keys_with_colours(data.data[0], ref_data),
        hide_no_data_dim_keys ? get_filtered_dim_keys(data, 1, deref_data[SELECTED_TECHS]) : deref_data[SELECTED_TECHS],
      ]
    },
  }
]

export const ID_TO_SPEC = get_as_map(SPECS, 'id')