import {
  ACTIVITY_PAGE_ID,
  ACTIVITY_TITLE,
  AGE_TITLE,
  BENCHMARKING_SECTION_ID,
  COST_TITLE,
  DECK_TOP_OWNERS_IN_LANDSCAPE_CHART,
  DECK_ACTIVE_FAMILIES_BY_PORTFOLIO_CHART,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_TARGET_ORG_CHART,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_ORGS_CHART,
  DECK_ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_CHART,
  DECK_ACTIVE_FAMILIES_BY_TECH_BY_STATUS_CHART,
  DECK_AVERAGE_COST_BY_PORTFOLIO_CHART,
  DECK_AVG_PRIORITY_YEAR_BY_ORG_CHART,
  DECK_CAGR_FAMILY_FILINGS_BY_PORTFOLIO_CHART,
  DECK_CAGR_FAMILY_FILINGS_BY_TECH_CHART,
  DECK_FAMILY_FILINGS_BY_LIST_PORTFOLIO_WITHIN_TIMEFRAME_CHART,
  DECK_FAMILY_FILINGS_BY_LANDSCAPE_PORTFOLIO_WITHIN_TIMEFRAME_CHART,
  DECK_FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_CHART,
  DECK_FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_CHART,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_ALL_TECHS_CHART,
  DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_TECHS_CHART,
  DECK_PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_CHART,
  DECK_PVIX_MEAN_BY_PORTFOLIO_CHART,
  GEOGRAPHY_PAGE_ID,
  GEOGRAPHY_TITLE,
  LANDSCAPE,
  PIPELINE_AGE_PAGE_ID,
  PIPELINE_TITLE,
  PORTFOLIO_SIZE_TITLE,
  QUALITY_COST_PAGE_ID,
  QUALITY_TITLE,
  SIZE_PAGE_ID,
  SIZE_LANDSCAPE_PAGE_ID,
  TECH_SECTION_ID,
  TECH_SCREENING_SECTION_MODEL,
  BENCHMARKING_SECTION_MODEL,
  PORTFOLIO_SIZE_SHORT_TITLE,
  PVIX_DISTRIBUTION_PAGE_ID,
  DECK_PVIX_DISTRIBUTION_BY_PORTFOLIO_SELECTED_ORGS_CHART,
  DECK_PVIX_DISTRIBUTION_BY_PORTFOLIO_TARGET_ORG_CHART,
  COST_DISTRIBUTION_PAGE_ID,
  DECK_COST_DISTRIBUTION_BY_PORTFOLIO_TARGET_ORG_CHART,
  DECK_COST_DISTRIBUTION_BY_PORTFOLIO_SELECTED_ORGS_CHART,
  DECK_PRIO_YEAR_DISTRIBUTION_BY_PORTFOLIO_TARGET_ORG_CHART,
  DECK_PRIO_YEAR_DISTRIBUTION_BY_PORTFOLIO_SELECTED_ORGS_CHART,
  PRIO_YEAR_DISTRIBUTION_PAGE_ID
} from './deck_charts.js'

const TECH_SCREENING_SECTION = {
  ...TECH_SCREENING_SECTION_MODEL,
  pages: [
    {
      id: `${TECH_SECTION_ID}_${SIZE_PAGE_ID}`,
      title: PORTFOLIO_SIZE_TITLE,
      charts: [
        DECK_ACTIVE_FAMILIES_BY_TECH_BY_STATUS_CHART,
        DECK_TOP_OWNERS_IN_LANDSCAPE_CHART
      ]
    },
    {
      id: `${TECH_SECTION_ID}_${ACTIVITY_PAGE_ID}`,
      title: ACTIVITY_TITLE,
      charts: [
        DECK_FAMILIES_BY_TECH_BY_PUBLICATION_YEAR_CHART,
        DECK_CAGR_FAMILY_FILINGS_BY_TECH_CHART
      ]
    },
    {
      id: `${TECH_SECTION_ID}_${GEOGRAPHY_PAGE_ID}`,
      title: GEOGRAPHY_TITLE,
      charts: [
        DECK_PATFAMS_WITH_GRANTS_BY_REGION_ALL_TECHS_CHART,
        DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_TECHS_CHART
      ]
    },
  ]
}

const BENCHMARKING_SECTION = {
  ...BENCHMARKING_SECTION_MODEL,
  pages: [
    {
      id: `${BENCHMARKING_SECTION_ID}_${SIZE_LANDSCAPE_PAGE_ID}`,
      title: `${PORTFOLIO_SIZE_TITLE} (${LANDSCAPE})`,
      short_title: `${PORTFOLIO_SIZE_SHORT_TITLE} (${LANDSCAPE})`,
      charts: [
        DECK_ACTIVE_FAMILIES_BY_TECH_BY_PORTFOLIO_CHART,
        DECK_FAMILY_FILINGS_BY_LANDSCAPE_PORTFOLIO_WITHIN_TIMEFRAME_CHART
      ]
    },
    {
      id: `${BENCHMARKING_SECTION_ID}_${SIZE_PAGE_ID}`,
      title: PORTFOLIO_SIZE_TITLE,
      charts: [
        DECK_ACTIVE_FAMILIES_BY_PORTFOLIO_CHART,
        DECK_FAMILY_FILINGS_BY_LIST_PORTFOLIO_WITHIN_TIMEFRAME_CHART
      ]
    },
    {
      id: `${BENCHMARKING_SECTION_ID}_${ACTIVITY_PAGE_ID}`,
      title: ACTIVITY_TITLE,
      charts: [
        DECK_CAGR_FAMILY_FILINGS_BY_PORTFOLIO_CHART,
        DECK_FAMILIES_BY_PORTFOLIO_BY_PUBLICATION_YEAR_CHART
      ]
    },
    {
      id: `${BENCHMARKING_SECTION_ID}_${GEOGRAPHY_PAGE_ID}`,
      title: GEOGRAPHY_TITLE,
      charts: [
        DECK_PATFAMS_WITH_GRANTS_BY_REGION_TARGET_ORG_CHART,
        DECK_PATFAMS_WITH_GRANTS_BY_REGION_SELECTED_ORGS_CHART
      ]
    },
    {
      id: `${BENCHMARKING_SECTION_ID}_${QUALITY_COST_PAGE_ID}`,
      title: `${QUALITY_TITLE} and ${COST_TITLE}`,
      charts: [
        DECK_PVIX_MEAN_BY_PORTFOLIO_CHART,
        DECK_AVERAGE_COST_BY_PORTFOLIO_CHART
      ]
    },
    {
      id: `${BENCHMARKING_SECTION_ID}_${PIPELINE_AGE_PAGE_ID}`,
      title: `${PIPELINE_TITLE} and ${AGE_TITLE}`,
      charts: [
        DECK_PENDING_FAMILIES_PERCENT_BY_PORTFOLIO_CHART,
        DECK_AVG_PRIORITY_YEAR_BY_ORG_CHART
      ]
    },
    {
      id: PVIX_DISTRIBUTION_PAGE_ID,
      title: 'PVIX distribution',
      hidden_in_main_nav: true,
      root_page_id: `${BENCHMARKING_SECTION_ID}_${QUALITY_COST_PAGE_ID}`,
      charts: [
        DECK_PVIX_DISTRIBUTION_BY_PORTFOLIO_TARGET_ORG_CHART,
        DECK_PVIX_DISTRIBUTION_BY_PORTFOLIO_SELECTED_ORGS_CHART
      ]
    },
    {
      id: COST_DISTRIBUTION_PAGE_ID,
      title: 'Cost distribution',
      hidden_in_main_nav: true,
      root_page_id: `${BENCHMARKING_SECTION_ID}_${QUALITY_COST_PAGE_ID}`,
      charts: [
        DECK_COST_DISTRIBUTION_BY_PORTFOLIO_TARGET_ORG_CHART,
        DECK_COST_DISTRIBUTION_BY_PORTFOLIO_SELECTED_ORGS_CHART
      ]
    },
    {
      id: PRIO_YEAR_DISTRIBUTION_PAGE_ID,
      title: 'Age distribution',
      hidden_in_main_nav: true,
      root_page_id: `${BENCHMARKING_SECTION_ID}_${PIPELINE_AGE_PAGE_ID}`,
      charts: [
        DECK_PRIO_YEAR_DISTRIBUTION_BY_PORTFOLIO_TARGET_ORG_CHART,
        DECK_PRIO_YEAR_DISTRIBUTION_BY_PORTFOLIO_SELECTED_ORGS_CHART
      ]
    }
  ]
}

export const BENCHMARKING_DECK = [
  TECH_SCREENING_SECTION, BENCHMARKING_SECTION
]
