import React from 'react'
import { DropdownItem } from 'reactstrap'
import cn from 'classnames'

import {
  CIPHER_FAMILY_ID_FIELD_ID,
  PATENT_NUMBERS_FIELD_ID,
  PRIMARY_PUBLICATION_FIELD_ID
} from '../../model/patent_family_fields.js'
import CopyToClipboard from '../widgets/CopyToClipboard.js'
import { get_static_classname } from '../../utils/tracking_utils.js'

import cs from '../cipher_styles.module.scss'

export const CopyFamilyIdsToClipboardAction = ({results, context}) => {
  const text_to_copy = (results || []).map(item => item[CIPHER_FAMILY_ID_FIELD_ID]).join('\n')

  const label = 'Copy family IDs to clipboard'

  return (
    <CopyToClipboardAction
      label={label}
      text_to_copy={text_to_copy}
      disabled={(results || []).length === 0}
      to_copy='family_id'
      context={context}
      btnClassName={get_static_classname('btn_family_ids_copy_to_clipboard')}
    />
  )
}

export const CopyPrimaryPublicationsToClipboardAction = ({results, context}) => {
  const text_to_copy = (results || []).map(item => item[PRIMARY_PUBLICATION_FIELD_ID]).filter(item => item != null).join('\n')

  const label = 'Copy family representative'

  return (
    <CopyToClipboardAction
      text_to_copy={text_to_copy}
      label={label}
      disabled={(results || []).length === 0}
      tooltip_placement='right'
      to_copy='family_representative'
      context={context}
      className={cn(cs.white_space_wrap)}
      btnClassName={get_static_classname('btn_primary_pub_ids_copy_to_clipboard')}
    />
  )
}

export const CopyAllPublicationsToClipboardAction = ({results, context}) => {
  const text_to_copy = (results || []).map(item => {
    const publications = item[PATENT_NUMBERS_FIELD_ID] || []
    return publications.length > 0 ? publications.join('\n') : ''
  }).filter(item => item !== '').join('\n')

  const label = 'Copy all family members'

  return (
    <CopyToClipboardAction
      text_to_copy={text_to_copy}
      label={label}
      disabled={(results || []).length === 0}
      tooltip_placement='right'
      to_copy='family_members'
      context={context}

      className={cs.white_space_wrap}
      btnClassName={get_static_classname('btn_all_pub_ids_copy_to_clipboard')}
    />
  )
}

const CopyToClipboardAction = (
  {
    text_to_copy,
    label,
    disabled,
    tooltip_placement,
    to_copy, //for tracking
    context, //for tracking
    className,
    btnClassName
  }) => {
  return (
    <DropdownItem toggle={false} className={className}>
      <CopyToClipboard
        label={label}
        title={label}
        text_to_copy={text_to_copy}
        disabled={disabled}
        tooltip_placement={tooltip_placement}
        trigger_element='div'
        to_copy={to_copy}
        context={context}
        className={cn(cs.no_outline, btnClassName)}
      />
    </DropdownItem>
  )
}
