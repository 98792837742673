import React from 'react'

import ScatterChart from './ScatterChart.js'
import { get_timerange } from '../../utils/time_range_utils.js'
import { get_share_data } from '../../utils/growth_view_utils.js'
import { should_hide_next_agglom_item } from '../../utils/main_items_utils.js'
import { X_COLUMN_IDX, Y_COLUMN_IDX } from '../../utils/treemap_utils.js'
import { get_keys_to_value, get_value_column_idx, IS_NEXT_AGGLOM } from '../../utils/column_data_utils.js'

function transform_data_for_scatter_chart({spec, data, item, key_dims}) {
  const x_items = key_dims[X_COLUMN_IDX]
  const timerange = get_timerange({spec, item, key_dims})
  const hide_next = should_hide_next_agglom_item(spec, item, X_COLUMN_IDX)
  const x_items_filtered = !hide_next ? x_items : x_items.filter(item => (!item[IS_NEXT_AGGLOM]))
  const value_column_idx = get_value_column_idx(data)
  const keys_to_value = get_keys_to_value(data, [X_COLUMN_IDX, Y_COLUMN_IDX], value_column_idx)

  const i = []
  const x = []
  const y = []

  x_items_filtered.forEach(x_item => {
    const {
      year_min_size,
      year_max_size
    } = get_share_data({ keys_to_value, x_item, timerange })

    i.push(x_item.id)
    x.push(year_max_size)

    y.push(year_max_size - year_min_size)
  })

  return {data: [i,x,y]}
}

function get_change_column_name({spec, item, key_dims}) {
  const timerange = get_timerange({spec, item, key_dims})

  const [year_min, year_max] = timerange || []

  return !year_min || !year_max ? 'Change' : `Change (${year_min} - ${year_max})`
}

function get_spec_with_column_names({spec, item, key_dims}) {
  const { column_names } = spec || {}

  const [x,,z] = column_names || []

  const updated_column_names = [x, z, get_change_column_name({spec, item, key_dims})]

  return {...spec, column_names: updated_column_names}
}

const GrowthScatterChart = (props) => {
  const {spec, data, item, key_dims} = props
  const scatter_data = transform_data_for_scatter_chart({spec, data, item, key_dims})
  const spec_with_column_names = get_spec_with_column_names({spec, item, key_dims})
  return (
    <ScatterChart
      {...props}
      data={scatter_data}
      spec={spec_with_column_names}
    />
  )
}

GrowthScatterChart.get_axis_titles    = (spec, other_props) => {
  const {item, key_dims} = other_props
  return ScatterChart.get_axis_titles(get_spec_with_column_names({spec, item, key_dims}))
}
GrowthScatterChart.get_plotly_data    = (props) => {
  const data = transform_data_for_scatter_chart(props)
  const spec = get_spec_with_column_names(props)
  return ScatterChart.get_plotly_data({...props, data, spec})
}
GrowthScatterChart.get_csv_value_rows = (props) => {
  const data = transform_data_for_scatter_chart(props)
  const spec = get_spec_with_column_names(props)
  return ScatterChart.get_csv_value_rows({...props, data, spec})
}

export default GrowthScatterChart