import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { get_deck_state, scroll_window_to_top } from '../../../utils/viewer_utils.js'
import { get_cipher_hostname } from '../../../utils/utils.js'
import ContainerFullWidth from '../../ContainerFullWidth.js'
import Spinner from '../../widgets/Spinner.js'
import ErrorBody from '../../ErrorBody.js'

const DeckState = ({history, match}) => {
  const { params } = match || {}
  const { state_id, deck } = params || {}

  const [error, set_error] = useState(null)

  useEffect(() => {
    if (!state_id) return

    get_deck_state(state_id)
      .catch(error => {
        set_error(error)
        throw error
      })
      .then(external_report_id => {
        const { pathname, search } = history.location
        const path_parts = pathname.split(state_id)
        const report_url = `${get_cipher_hostname()}/${deck}/${external_report_id}${path_parts[1]}${search || ''}`

        window.location.replace(report_url)
      })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_id])

  useEffect(() => {
    scroll_window_to_top()
  }, [])

  return (
    <ContainerFullWidth>
      {!error &&
        <Spinner/>
      }

      {error &&
        <ErrorBody
          error={error}
          context='fetching report state for shareable link'
        />
      }
    </ContainerFullWidth>
  )
}

export default withRouter(DeckState)