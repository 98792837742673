import TimeMe from 'timeme.js'

import { is_prod_cdn_environment } from './utils.js'

// NOTE: unfortunately the 'timeme.js' library has a single global instance.
//       The below code assumes that it has been initialised at the root of the app
//       (i.e. in App.js) and that there is only a single instance running.
//       Also, it seems there is no way to unregister callbacks, so we wipe the
//       internal array.
//       See https://github.com/jasonzissman/TimeMe.js
//
// TO TEST LOCALLY:
// 1. Connect local Frontend Server to a dev db (NOT PROD!!!)
// 2. Set a small TIMEOUT_SECONDS below (i.e. 3)
// 3. Comment out the three is_prod_cdn_environment() checks below, so that logging code runs
// 4. In training_activity_utils.js - save_training_activity(), possibly add a console.log('save!', data)
// 5. Run the Frontend Client, and so some actions (i.e. open classifier, do labelling, build etc...)
// 6. Check the Frontend db table ClassifiersTrainingActivity

const TIMEOUT_SECONDS = 60

export function init_time_monitor() {
  if (!is_prod_cdn_environment()) {
    return
  }

  // i.e. it is assumed this will only be called once, at the top level of the app (i.e. in App.js)
  TimeMe.initialize({
    idleTimeoutInSeconds: TIMEOUT_SECONDS
  })
}

export function register_time_monitor(page_name, log_fn) {
  if (!is_prod_cdn_environment()) {
    return
  }

  function on_leave() {
    // This fires when user is inactive for a while, or switches tabs, or switches to a different application
    const seconds_elapsed = Math.ceil(TimeMe.getTimeOnPageInSeconds(page_name))
    log_fn(seconds_elapsed)
  }

  function on_return() {
    // This fires when user returns again.
    TimeMe.resetRecordedPageTime(page_name)
  }

  // Register the handlers
  TimeMe.setCurrentPageName(page_name)
  TimeMe.resetRecordedPageTime(page_name)
  TimeMe.startTimer()
  TimeMe.userLeftCallbacks   = [{ callback: on_leave }]
  TimeMe.userReturnCallbacks = [{ callback: on_return }]
}

export function unregister_time_monitor() {
  if (!is_prod_cdn_environment()) {
    return
  }

  // Call "leave" (so that final time on page is logged)
  const on_leave = TimeMe.userLeftCallbacks[0]
  if (on_leave && on_leave.callback) {
    on_leave.callback()
  }

  // Unregister the handlers
  TimeMe.userLeftCallbacks = []
  TimeMe.userReturnCallbacks = []
}