const UTT_V_1 = 1
const UTT_V_2 = 2
const UTT_V_3 = 3

export const DEPRECATED_UTT_VERSION_IDS = [UTT_V_1, UTT_V_2]

export const UTT_VERSIONS = [
  {id: UTT_V_3, label: '2024'},
]

export const UTT_VERSION_IDS = UTT_VERSIONS.map(version => version.id)
export const DEFAULT_UTT_VERSION = UTT_V_3
