import React from 'react'
import cn from 'classnames'

import s from './SelectedMark.module.scss'

const SelectedMark = ({is_selected, className}) => {
  return (
    <div className={cn(s.block, className)}>
      {is_selected && <span>&#9679;</span>}
    </div>
  )
}

export default SelectedMark