import { get_as_map } from '../utils/utils.js'
import {ASCENDING, DESCENDING, ID_TO_SORT_DIRECTION} from './sort_directions.js'

export const ORG_SEARCH_CONTEXT = {id: 'org_search', name: 'Organisation search'}
export const GROUPING_CONTEXT   = {id: 'grouping', name: 'Assignee grouping'}

export const SORT_TYPES = [
  {id: 'size', name: 'Size'},
  {id: 'name', name: 'Name'}
]

export const SORT_DIRS = [ ID_TO_SORT_DIRECTION[DESCENDING], ID_TO_SORT_DIRECTION[ASCENDING] ]

export const SORT_TYPE_BY_ID = get_as_map(SORT_TYPES, 'id')

export const COMPARABLES_BY_SIMILAR_SIZE = 'similar_size_scaled'
export const COMPARABLES_BY_LARGEST_PORTFOLIO = 'not_scaled'
export const COMPARABLES_MODES = [
  {id: COMPARABLES_BY_SIMILAR_SIZE, label: 'Most similar portfolio'},
  {id: COMPARABLES_BY_LARGEST_PORTFOLIO, label: 'Largest relevant portfolio'}
]
export const COMPARABLES_MODES_BY_ID = get_as_map(COMPARABLES_MODES, 'id')

export const TYPE_ORGANISATION   = 'organisation'
export const TYPE_ASSIGNEE       = 'assignee'
export const TYPE_AGGLOMERATION  = 'agglomeratedAssignee'
export const TYPE_AGGLOMERATED_ASSIGNEES = 'agglomerated_assignees'
export const TYPE_ORGANISATION_ASSIGNEE_GROUP = 'organisation_assignee_group'

export const GROUP_BY_TYPE_ORDER = [TYPE_ORGANISATION, TYPE_AGGLOMERATION, TYPE_ASSIGNEE]

export const ORGANISATION_COLUMN_NAME = 'Organisation'