export const ID_TO_PORTFOLIO = 'id_to_portfolio'
export const ID_TO_TECH = 'id_to_tech'
export const ID_TO_GEO = 'id_to_geo'

export const PORTFOLIOS = 'portfolios'
export const PORTFOLIOS_COUNT_INCL_ROLLUPS = 'portfolios_count_incl_rollups'
export const TECHS = 'techs'
export const GEOS = 'geos'
export const DATA_CREATION_DATE = 'data_creation_date'
export const SCHEMA_VERSION = 'schema_version'
export const AVAILABLE_DISPUTE_TYPES = 'available_dispute_types'

export const CONTINENT_ID_TO_GEO_IDS = 'continent_id_to_geo_ids'