import {
  AVG_AGE_AT_EXPIRY_BY_ORG_BY_TECH_ID,
  AVG_AGE_OF_GRANTED_FAMILY_BY_ORG_BY_TECH_ID,
  AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID,
  BACKWARD_CITATIONS_BY_ORG_BY_TECH_ID,
  FAMILIES_BY_CPC_CODE, FAMILIES_BY_FULL_PRIMARY_CPC_CODE,
  FAMILIES_BY_PORTFOLIO_BY_CPC_CODE,
  FAMILIES_BY_PORTFOLIO_BY_PRIMARY_CPC_CODE,
  FAMILIES_BY_PRIMARY_CPC_CODE,
  FAMILIES_BY_STATUS,
  FAMILIES_BY_TECH_BY_CPC_CODE,
  FAMILIES_BY_TECH_BY_PRIMARY_CPC_CODE,
  FORWARD_CITATIONS_BY_ORG_BY_TECH_ID,
  GRANTED_COUNTRIES_PER_FAMILY_BY_ORG,
  TOTAL_NUM_PUBLICATIONS_BY_PORTFOLIO_ID,
  TOTAL_NUM_PUBLICATIONS_BY_TECHNOLOGY_ID
} from '../spec_ids.js'
import {
  KEY_CPC_CODE,
  KEY_FULL_PRIMARY_CPC_CODE,
  KEY_ORGANISATION,
  KEY_PRIMARY_CPC_CODE,
  KEY_STATUS,
  KEY_TECHNOLOGY
} from './spec_keys.js'
import {
  TAG_ACTIVE,
  TAG_EXPIRED,
  TAG_GEO,
  TAG_GRANTED,
  TAG_ORG,
  TAG_PENDING,
  TAG_TECH,
  TAG_TIME
} from './spec_tags.js'
import { PORTFOLIO_STATS_GROUP_ID } from './spec_group_ids.js'
import {
  DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW,
  DEFAULT_1D_NON_TIMESERIES_VIEWS,
  DEFAULT_2D_HISTOGRAM_VIEWS,
  DEFAULT_2D_NON_TIMESERIES_VIEW,
  DEFAULT_2D_NON_TIMESERIES_VIEWS
} from '../views.js'
import { COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID } from '../view_ids.js'
import { is_schema_version_15_or_above } from '../../utils/data_availability_utils.js'
import { get_tabular_view_patents_total } from '../../utils/tabular_view_utils.js'
import { get_tabular_view_citations_total } from '../../utils/tabular_view_utils.js'

const HELP_ARTICLE = '28889822599187-Portfolio-Stats-charts-datasets'

export const PORTFOLIO_STATS_GROUP =  {
  id: PORTFOLIO_STATS_GROUP_ID,
  name: 'Portfolio stats',
  short_name: 'Stats',
  help_article: HELP_ARTICLE,
  children: [
    {
      spec_id: FAMILIES_BY_STATUS,
      name: 'families',
      description: 'Number of patent families by status.',
      help_article: `${HELP_ARTICLE}#h_ab3176aaad`,
      by: [KEY_STATUS],
      tags: [TAG_PENDING, TAG_GRANTED, TAG_EXPIRED, TAG_ACTIVE],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW,
    },
    {
      spec_id: AVG_AGE_AT_EXPIRY_BY_ORG_BY_TECH_ID,
      name: 'average age at expiry',
      description: 'Average age, in years, at expiry (for patent families that have expired within the last 10 years). Calculated from priority date to expiry date. Only shows patents families that have already expired, due to age or non-payment.',
      help_article: `${HELP_ARTICLE}#h_51a73c3782`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_EXPIRED],
      available_view_ids: [COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: AVG_AGE_OF_GRANTED_FAMILY_BY_ORG_BY_TECH_ID,
      name: 'average age of granted family',
      short_name: 'granted family avg age',
      description: 'Average age, in years, of granted family. Calculated from priority date to date of report.',
      help_article: `${HELP_ARTICLE}#h_13270546cd`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_GRANTED],
      available_view_ids: [COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: AVG_TIME_TO_GRANT_BY_ORG_BY_TECH_ID,
      name: 'average time to grant',
      description: 'Average time to grant, in years (for patent families granted within the last 10 years). Calculated from date of priority filing to date of grant.',
      help_article: `${HELP_ARTICLE}#h_5339d3ea5e`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH, TAG_TIME],
      available_view_ids: [COLUMN_CLUSTER_ID, HEATMAP_ID, TABLE_ID],
      default_view_id: HEATMAP_ID,
    },
    {
      spec_id: BACKWARD_CITATIONS_BY_ORG_BY_TECH_ID,
      name: 'backward citations',
      description: 'Sum of all backward citations of portfolio(s) (i.e. patents being cited by portfolio) by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_4f1f399079`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
      get_tabular_view_summary: get_tabular_view_citations_total,
    },
    {
      spec_id: FORWARD_CITATIONS_BY_ORG_BY_TECH_ID,
      name: 'forward citations',
      description: 'Sum of all forward citations of portfolio(s) (i.e. patents citing the portfolio) by organisation and technology.',
      help_article: `${HELP_ARTICLE}#h_01HZFA0GH6X14V5FX74BQJN6AP`,
      by: [KEY_ORGANISATION, KEY_TECHNOLOGY],
      tags: [TAG_ORG, TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
      get_tabular_view_summary: get_tabular_view_citations_total,
    },
    {
      spec_id: GRANTED_COUNTRIES_PER_FAMILY_BY_ORG,
      name: 'Granted territories per family',
      short_name: 'granted territories distr',
      description: 'Number of granted territories per family, by organisation. This is shown as a percentage of the entire portfolio on the Y axis. ',
      help_article: `${HELP_ARTICLE}#h_8bd295bbc8`,
      by: [KEY_ORGANISATION],
      tags: [TAG_GEO, TAG_ORG],
      available_view_ids: DEFAULT_2D_HISTOGRAM_VIEWS,
      default_view_id: COLUMN_CLUSTER_ID,
    },
    {
      spec_id: TOTAL_NUM_PUBLICATIONS_BY_PORTFOLIO_ID,
      name: 'total publications',
      description: 'Number of publications of any status (including expired) by organisation.',
      help_article: `${HELP_ARTICLE}#h_c06df938b8`,
      short_name: 'total publication count',
      by: [KEY_ORGANISATION],
      tags: [TAG_ORG],
      get_tabular_view_summary: get_tabular_view_patents_total,
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW
    },
    {
      spec_id: TOTAL_NUM_PUBLICATIONS_BY_TECHNOLOGY_ID,
      name: 'total publications',
      description: 'Number of publications of any status (including expired) by technology.',
      help_article: `${HELP_ARTICLE}#h_5175772e22`,
      short_name: 'total publication count',
      by: [KEY_TECHNOLOGY],
      tags: [TAG_TECH],
      get_tabular_view_summary: get_tabular_view_patents_total,
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW
    },
    {
      spec_id: FAMILIES_BY_CPC_CODE,
      name: 'families',
      description: 'Number of patent families referencing a specific CPC code.',
      help_article: `${HELP_ARTICLE}#h_0276988e84`,
      by: [KEY_CPC_CODE],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW,
    },
    {
      spec_id: FAMILIES_BY_PORTFOLIO_BY_CPC_CODE,
      name: 'families',
      description: 'Number of patent families referencing a specific CPC code by organisation.',
      help_article: `${HELP_ARTICLE}#h_75f34764fe`,
      by: [KEY_ORGANISATION, KEY_CPC_CODE],
      tags: [TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: FAMILIES_BY_TECH_BY_CPC_CODE,
      name: 'families',
      description: 'Number of patent families referencing a specific CPC code by technology.',
      help_article: `${HELP_ARTICLE}#h_45eee613bf`,
      by: [KEY_TECHNOLOGY, KEY_CPC_CODE],
      tags: [TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
    },
    {
      spec_id: FAMILIES_BY_PRIMARY_CPC_CODE,
      name: 'families',
      description: 'Number of patent families by primary CPC code.',
      help_article: `${HELP_ARTICLE}#h_d5773b5957`,
      by: [KEY_PRIMARY_CPC_CODE],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW,
      check_if_available: is_schema_version_15_or_above
    },
    {
      spec_id: FAMILIES_BY_PORTFOLIO_BY_PRIMARY_CPC_CODE,
      name: 'families',
      description: 'Number of patent families by primary CPC code, by organisation.',
      help_article: `${HELP_ARTICLE}#h_e75531c48f`,
      by: [KEY_ORGANISATION, KEY_PRIMARY_CPC_CODE],
      tags: [TAG_ORG],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
      check_if_available: is_schema_version_15_or_above
    },
    {
      spec_id: FAMILIES_BY_TECH_BY_PRIMARY_CPC_CODE,
      name: 'families',
      description: 'Number of patent families by primary CPC code, by technology.',
      help_article: `${HELP_ARTICLE}#h_d05cf40b68`,
      by: [KEY_TECHNOLOGY, KEY_PRIMARY_CPC_CODE],
      tags: [TAG_TECH],
      available_view_ids: DEFAULT_2D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_2D_NON_TIMESERIES_VIEW,
      check_if_available: is_schema_version_15_or_above
    },
    {
      spec_id: FAMILIES_BY_FULL_PRIMARY_CPC_CODE,
      name: 'families',
      description: 'Number of patent families by full primary CPC code.',
      help_article: `${HELP_ARTICLE}#h_f413394e86`,
      by: [KEY_FULL_PRIMARY_CPC_CODE],
      available_view_ids: DEFAULT_1D_NON_TIMESERIES_VIEWS,
      default_view_id: DEFAULT_1D_NON_TIMESERIES_GROUPED_VIEW,
      check_if_available: is_schema_version_15_or_above
    },
  ]
}