import React, { Component } from 'react'

import { TEXT_BASE_STYLE_REACT }  from './svg/svg_styles.js'
import { format_date } from './utils/executive_summary_utils.js'

class ExecutiveSummaryFooter extends Component {
  render() {
    const report_date = this.props.date
    const report_year = this.props.year
    const style = {...TEXT_BASE_STYLE_REACT}

    const COPYRIGHT_SYMBOL = '\u00A9'

    return (
      <g>
        <text width='125' y='0' x='0' style={style}><tspan>Cipher report: {format_date(report_date)}</tspan></text>
        <text width='125' y='0' x='735' style={style}><tspan>{COPYRIGHT_SYMBOL} Aistemos, {report_year}</tspan></text>
      </g>
    )
  }
}

export default ExecutiveSummaryFooter