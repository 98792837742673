import { format_string_only_first_character_capitalised } from '../utils/utils.js'
import { ID_TO_SPEC } from '../model/specs.js'
import { KEY_CROSS, SPEC_KEYS_BY_ID } from '../model/spec_groups/spec_keys.js'

export function get_spec_name(spec, SPEC_ID_TO_SPEC_REF) {
  const spec_id = spec.id
  const spec_ref = SPEC_ID_TO_SPEC_REF[spec_id]
  return format_spec_name(spec_ref)
}

export function get_short_spec_name(spec, SPEC_ID_TO_SPEC_REF) {
  const spec_id = spec.id
  const spec_ref = SPEC_ID_TO_SPEC_REF[spec_id]
  return format_short_spec_name(spec_ref)
}

export function format_spec_name(spec) {
  if (!spec) return ''

  const { name, by } = spec

  const slicing = by.map(item => {
    const { name } = SPEC_KEYS_BY_ID[item] || {}
    return name || item || ''
  }).join(' and ')

  return `${format_string_only_first_character_capitalised(name)}${slicing ? `, ${slicing === KEY_CROSS ? '' : ' by'} ${slicing}` : ''}`
}

export function format_short_spec_name(spec) {
  if (!spec) return ''

  const { name, by } = spec

  const slicing = by.map(item => {
    const { short_name, name } = SPEC_KEYS_BY_ID[item] || {}
    return short_name || name || item || ''
  }).join(' & ')

  return `${format_string_only_first_character_capitalised(name)}${slicing ? `, by ${slicing}` : ''}`
}

export function get_group_and_spec_name(spec, SPEC_ID_TO_GROUP, SPEC_ID_TO_SPEC_REF) {
  const spec_id = spec.id
  const group = SPEC_ID_TO_GROUP[spec_id]
  const name = get_spec_name(spec, SPEC_ID_TO_SPEC_REF)

  return `${format_string_only_first_character_capitalised(group.name)}: ${name}`
}

export function get_key_from_spec(spec, region_column, status_filter) {
  //specs with a dedicated rr endpoint may not include a query that is passed to that endpoint
  //but visual components still rely on it, so we just add a representation of what would be a query if the spec used the regular rr endpoint

  const { get_query, get_query_for_clickthroughs } = spec

  const get_query_fn = get_query_for_clickthroughs || get_query

  const query = get_query_fn({region_column, status_filter})

  const { key } = query

  return key
}

export function spec_requires_assignee_links(spec_id) {
  const {requires_assignee_links} = ID_TO_SPEC[spec_id] || {}
  return (requires_assignee_links != null) ? requires_assignee_links : false
}
