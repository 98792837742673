import {
  NUM_FAMILIES_BY_SCORE_BAND,
  NUM_FAMILIES_BY_SCORE_BAND_BY_ORGANISATION,
  NUM_FAMILIES_BY_SCORE_BAND_BY_TECHNOLOGY
} from '../spec_ids.js'
import { TAG_ORG, TAG_TECH } from './spec_tags.js'
import { KEY_ORGANISATION, KEY_SCORE, KEY_TECHNOLOGY } from './spec_keys.js'
import { SCORE_GROUP_ID } from './spec_group_ids.js'
import {
  DEFAULT_1D_HISTOGRAM_VIEW,
  DEFAULT_1D_HISTOGRAM_VIEWS,
  DEFAULT_2D_HISTOGRAM_VIEW,
  DEFAULT_2D_HISTOGRAM_VIEWS
} from '../views.js'
import { has_classifier_scores } from '../../utils/data_availability_utils.js'

const HELP_ARTICLE = '28891300552083-Classifier-Score-Charts-Datasets'

export const SCORE_GROUP = {
  id: SCORE_GROUP_ID,
  name: 'Classifier score',
  short_name: 'Score',
  help_article: HELP_ARTICLE,
  check_if_available: has_classifier_scores,
  children: [
    {
      spec_id: NUM_FAMILIES_BY_SCORE_BAND,
      name: 'families',
      description: 'Count of patent families by score band.',
      help_article: `${HELP_ARTICLE}#h_39279ef61b`,
      by: [KEY_SCORE],
      available_view_ids: DEFAULT_1D_HISTOGRAM_VIEWS,
      default_view_id: DEFAULT_1D_HISTOGRAM_VIEW,
    },
    {
      spec_id: NUM_FAMILIES_BY_SCORE_BAND_BY_ORGANISATION,
      name: 'families',
      description: 'Count of patent families by organisation and score band.',
      help_article: `${HELP_ARTICLE}#h_3cf162e3ba`,
      by: [KEY_SCORE, KEY_ORGANISATION],
      tags: [TAG_ORG],
      available_view_ids: DEFAULT_2D_HISTOGRAM_VIEWS,
      default_view_id: DEFAULT_2D_HISTOGRAM_VIEW,
    },
    {
      spec_id: NUM_FAMILIES_BY_SCORE_BAND_BY_TECHNOLOGY,
      name: 'families',
      description: 'Count of patent families by technology and score band.',
      help_article: `${HELP_ARTICLE}#h_295c188ae2`,
      by: [KEY_SCORE, KEY_TECHNOLOGY],
      tags: [TAG_TECH],
      available_view_ids: DEFAULT_2D_HISTOGRAM_VIEWS,
      default_view_id: DEFAULT_2D_HISTOGRAM_VIEW,
    }
  ]
}
