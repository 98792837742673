import React from 'react'
import cn from 'classnames'

import s from './InputStatusMarker.module.scss'

const InputStatusMarker = ({family_id, positives, negatives, bookmarked, display, className}) => {

  const is_positive = (positives || []).indexOf(family_id) > -1
  const is_negative = (negatives || []).indexOf(family_id) > -1
  const is_bookmarked = (bookmarked || []).indexOf(family_id) > -1

  if (display === 'table') {
    return (<InputStatusMarkerForTableRow is_positive={is_positive} is_negative={is_negative} is_bookmarked={is_bookmarked} className={className} />)
  }

  if (display === 'card') {
    return (<InputStatusMarkerForCard is_positive={is_positive} is_negative={is_negative} is_bookmarked={is_bookmarked} className={className} />)
  }

  return null
}

const InputStatusMarkerForTableRow = ({is_positive, is_negative, is_bookmarked, className}) => {
  return (
    <div className={cn('h-100', s.status_mark__vertical, {[s.status_mark__positive]: is_positive}, {[s.status_mark__negative]: is_negative}, {[s.status_mark__bookmarked]: is_bookmarked}, className)} />
  )
}

const InputStatusMarkerForCard = ({is_positive, is_negative, is_bookmarked, className}) => {
  return (
    <div className={cn('w-100', s.status_mark__horizontal, {[s.status_mark__positive]: is_positive}, {[s.status_mark__negative]: is_negative}, {[s.status_mark__bookmarked]: is_bookmarked}, className)} />
  )
}

export default InputStatusMarker
