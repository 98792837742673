import { send_error_to_sentry } from './sentry_utils.js'

const NOTIFICATION_PERMISSION_GRANTED = 'granted'
const NOTIFICATION_PERMISSION_DENIED = 'denied'

const cipher_logo_small = 'https://s3.eu-west-1.amazonaws.com/cdn.cipher.ai/static/images/cipher-logo-small.png'

const DEFAULT_OPTIONS = {
  icon: cipher_logo_small,
  badge: cipher_logo_small,
  silent: false,
  requireInteraction: true // notifications do not disappear on their own
}

/**
 * Display in-browser messages about long-running tasks in Cipher to users who want to receive them.
 * Uses the Notifications API; docs here:
 * Docs: https://developer.mozilla.org/en-US/docs/Web/API/notification
 *
 * To see notifications the user needs to have enabled browser notifications in their OS settings, and to opt in to
 * receiving notifications from Cipher specifically when prompted (requestPermission() below triggers the prompt).
 *
 * @param message This is the main/ title bit of text in the notification (more text can be included in a 'body' property in options if needed)
 * @param options JSON properties to apply as per the Notifications docs, and to override the DEFAULT_OPTIONS above if desired.
 */
export function notify_in_browser_if_permitted(message, options) {
  if (!notifications_supported_by_browser()) {
    return
  }
  if (notifications_permitted()) {
    // user has already opted in to notifications from Cipher
    show_notification(message, options)
  } else if (!notifications_prohibited()) {
    // user has neither opted in nor opted out yet;
    // request their permission before showing any notifications
    request_permission_and_notify(message, options)
  }
}

export function request_permission_to_show_notifications() {
  if (!notifications_supported_by_browser()) {
    return
  }
  if (!notifications_permitted() && !notifications_prohibited()) {
    // no message to send now; only request permission for notifications if not already enabled/ disabled
    request_permission_and_notify(null, null)
  }
}

function request_permission_and_notify(message, options) {
  try {
    Notification.requestPermission()
      .then(() => {
        if (notifications_permitted() && message) {
          show_notification(message, options)
        }
      })
  } catch(err) {
   if (err instanceof TypeError) {
     // older versions of Safari can throw a type error because they don't return a promise for requestPermission
     // so try sending as a callback instead:
     try {
       Notification.requestPermission(() => {
         if (notifications_permitted() && message) {
           show_notification(message, options)
         }
       })
       return
     } catch(err_on_callback) {
       // something else went wrong; log it to sentry
       send_error_to_sentry(err_on_callback, {})
     }
   }
    send_error_to_sentry(err, {})
  }
}

function show_notification(message, options) {
  const all_options = {
    ...DEFAULT_OPTIONS,
    ...(options || {})
  }
  try {
    const notification = new Notification(message, all_options)

    // when the user clicks the notification, go to the notifying tab
    // this happens automatically in safari and firefox but for some reason needs to be spelled out for chrome...
    notification.onclick = function() {
      window.parent.focus()
      notification.close()
    }
  } catch(err) {
    send_error_to_sentry(err, {})
  }
}

function notifications_supported_by_browser() {
  return 'Notification' in window
}

function notifications_permitted() {
  return Notification.permission === NOTIFICATION_PERMISSION_GRANTED
}

function notifications_prohibited() {
  return Notification.permission === NOTIFICATION_PERMISSION_DENIED
}