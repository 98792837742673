import React, { useState, useEffect } from 'react'
import {fetch_organisation_details_by_id} from '../../utils/organisation_utils.js'
import OrganisationModal from './OrganisationModal.js'
import ErrorModal from '../ErrorModal.js'

const OrgEditModal = ({org_id, on_confirm, on_hide}) => {
  const [show_spinner, set_show_spinner] = useState(true)
  const [org_details, set_org_details] = useState(null)

  const [fetch_error, set_fetch_error] = useState(null)

  useEffect(() => {
    fetch_organisation_details_by_id(org_id)
      .then(org => {
        set_org_details(org)
        set_show_spinner(false)
      })
      .catch(e => {
        set_fetch_error(e)
        set_show_spinner(false)
        on_hide()
      })
  }, [org_id, on_hide])

  function submit_changes({new_name, non_practicing_entity_override, tags, notes, grouping_only}) {
    const {name, tags: org_tags} = org_details

    on_confirm({
      new_name: (new_name !== name) ? new_name : '',
      ...(tags.length === 0 && !org_tags) ? {} : { 'tags': tags },
      grouping_only,
      non_practicing_entity_override,
      notes
    })
  }

  const title = (org_details) ? `Editing ${org_details.name} (${org_id})` : 'Edit organisation'

  if (fetch_error) {
    return (
      <ErrorModal
        error={fetch_error}
        on_hide={() => set_fetch_error(null)}
        context={'fetching organisation details'}
      />
    )
  }

  return (
    <OrganisationModal
      show_spinner={show_spinner}
      org={org_details}
      title={title}
      on_hide={on_hide}
      on_confirm={submit_changes}
      confirm_label='Confirm changes'
    />
  )
}

export default OrgEditModal