import React from 'react'
import cn from 'classnames'

import s from './GridElement.module.scss'

const GridElement = ({ className, children }) => {
  return (
    <div
      className={cn(s.block, className)}
    >
      {children}
    </div>
  )
}

export default GridElement
