import React from 'react'
import { extent } from 'd3'
import _ from 'underscore'

import Plot from './CustomPlotLazy.js'

import {
  BASE_LAYOUT_PROPS,
  BASE_OTHER_PROPS,
  SCATTER_CHART_TYPE,
  NORMAL_MARGIN,
  THUMBNAIL_MARGIN
} from './base_plotly_props.js'
import Legend from './Legend.js'
import ChartAndLegendHolder from './ChartAndLegendHolder.js'
import { should_hide_next_agglom_item, THUMBNAIL_MAX_SERIES } from '../../utils/main_items_utils.js'
import { get_key_items_sorted_by_value, get_keys_to_value, IS_NEXT_AGGLOM } from '../../utils/column_data_utils.js'
import { is_report_series_sort_by_size } from '../../utils/report_utils.js'
import { get_key_from_spec } from '../../utils/spec_utils.js'
import { get_as_map } from '../../utils/utils.js'
import BasePlotlyXYChartWrapper from './BasePlotlyXYChartWrapper.js'

//this view has been created for the pvix by size datasets and it will need further customisations if it were to be used in other datasets

let _x_items_sorted_by_id = null

function get_axis_titles(spec) {
  const { column_names } = spec
  const x_axis_title = column_names[1]
  const y_axis_title = column_names[2]

  return { y_axis_title, x_axis_title }
}

function get_margin(is_thumbnail) {
  if (is_thumbnail) {
    return THUMBNAIL_MARGIN
  }
  return NORMAL_MARGIN
}

function get_processed_data({ spec, data, key_dims, item, report_series_sort, is_thumbnail, is_download }) {
  const { column_names, value_formatter } = spec
  const legend_items = key_dims[0]

  const key_to_value = get_keys_to_value(data, [0], 1, false)

  const legend_items_ordered = is_report_series_sort_by_size(report_series_sort) ? get_key_items_sorted_by_value(legend_items, key_to_value, false) : legend_items

  const hide_next = should_hide_next_agglom_item(spec, item, 0)
  const legend_items_filtered = legend_items_ordered.filter(item => !(hide_next && item[IS_NEXT_AGGLOM]))
  const clean_legend_items = (is_thumbnail) ? legend_items_filtered.slice(0, THUMBNAIL_MAX_SERIES) : legend_items_filtered

  const spec_data = data.data
  const key_ids = spec_data[0]

  const x_idx = 1
  const y_idx = 2

  const x_axis_title = column_names[x_idx]
  const y_axis_title = column_names[y_idx]

  const plotly_data = clean_legend_items.map(legend_item => {
    const { id, name, short_name } = legend_item
    const idx = key_ids.indexOf(id)
    const x = spec_data[x_idx][idx]
    const y = spec_data[y_idx][idx]

    const y_formatted = value_formatter ? value_formatter(y) : y

    return {
      x: [x], y: [y],
      name: is_download ? name : short_name,
      id,
      type: SCATTER_CHART_TYPE,
      mode: 'markers',
      text: `${short_name}<br><br>${x_axis_title}: ${x}<br>${y_axis_title}: ${y_formatted}`,
      hoverinfo: 'text',
      marker: {
        color: legend_item.color,
        size: 25
      }
    }
  })

  const y_values = _.flatten(plotly_data.map(item => item.y))
  const [y_min, y_max] = extent(y_values)
  const y_min_or_zero = Math.min(y_min, 0)
  const y_range = spec.axis_range ? spec.axis_range(y_min, y_max) : null

  return {
    plotly_data,
    legend_items: clean_legend_items,
    x_axis_title,
    y_axis_title,
    y_min: y_range ? y_min : y_min_or_zero,
    y_max,
    y_range
  }
}


function get_plotly_data({ spec, data, key_dims, item, report_series_sort }) {
  const { plotly_data } = get_processed_data({ spec, data, key_dims, item, report_series_sort, is_download: true })
  return plotly_data
}


function get_csv_value_rows({ spec, data, key_dims, item, report_series_sort }) {
  const { plotly_data } = get_processed_data({ spec, data, key_dims, item, report_series_sort, is_download: true })

  if (!plotly_data.length) {
    return []
  }

  const header_row = [...spec.column_names]

  const main_rows = plotly_data.map(element => {
    return [element.name, element.x[0], element.y[0]]
  })

  return [header_row, ...main_rows]
}

const ScatterChart = ({ chart_type, spec, data, key_dims, item, is_thumbnail, set_families_subselections, report_series_sort, use_value_prefix }) => {
  const { value_prefix, value_suffix, no_clickthrough } = spec

  const show_legend = !is_thumbnail

  const { plotly_data, legend_items, x_axis_title, y_axis_title, y_min, y_max, y_range } = get_processed_data({ chart_type, spec, data, key_dims, item, report_series_sort, is_thumbnail })
  _x_items_sorted_by_id = get_as_map(legend_items, 'id')

  const should_use_value_prefix = (use_value_prefix != null) ? use_value_prefix : true

  const y_spread = y_max - y_min
  const y_tick_suffix = value_suffix || ''

  const margin = get_margin(is_thumbnail, chart_type)
  const chart = (
    <BasePlotlyXYChartWrapper
      is_thumbnail={is_thumbnail}
      no_clickthrough={no_clickthrough}
    >
      <Plot
        {...BASE_OTHER_PROPS}
        data={plotly_data}
        layout={{
          ...BASE_LAYOUT_PROPS,

          margin: margin,

          hovermode: is_thumbnail ? false : 'closest',

          hoverlabel: {
            bgcolor: 'black' // tooltips all in black
          },

          xaxis: {
            title: x_axis_title,
            titlefont: {
              size: 11
            },
            zeroline: false,
            fixedrange: true, // disable select/zoom
            tickformat: 'd', // if numbers, only show integers
            ...(is_thumbnail) ? {
              title: null,
              ticks: '',
              showticklabels: false,
              showgrid: false,
              fixedrange: true
            } : {}
          },

          yaxis: {
            side: 'left',
            title: y_axis_title + '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;', // hack to ensure title takes a whole line (and is not interleaved with result)
            titlefont: {
              size: 11
            },
            tickformat: y_spread <= 10 ? 'd' : null,
            // Frustratingly, plotly does not let us pass in a function for the tick format: https://github.com/plotly/plotly.js/issues/1464
            // This means we are stuck with d3 format strings.
            // We want to avoid having y-axis interpolated with non-decimals (i.e. 0.5 patents does not make sense).
            // Using 'd' (integer) everywhere is not possible, as large numbers (i.e. $123,456) will overflow across the y-axis title.
            // So we only use 'd' when the y_spread (difference between min and max) is less than 10.
            // Otherwise we fallback to the default (which uses d3 's' - i.e. $123,456 is displayed as $100k)
            fixedrange: true, // disable select/zoom
            tickprefix: should_use_value_prefix ? value_prefix : '',
            ticksuffix: y_tick_suffix,
            hoverformat: ',.0f',
            rangemode: y_range ? 'normal' : 'tozero',
            range: y_range,
            ...(is_thumbnail) ? {
              title: null,
              ticks: '',
              showticklabels: false,
              zeroline: false,
              showgrid: false,
              fixedrange: true
            } : {}
          },
        }}

        onClick= {function({points}) {
          if (is_thumbnail) {
            return
          }

          if (!points || !points.length) {
            return
          }

          const key = get_key_from_spec(spec, null)
          const x_key = key[0]

          const { data } = points[0]
          const { id } = data

          const x_item = _x_items_sorted_by_id[id]

          if (!x_item) {
            // No item anymore (DOM may have been updated already (i.e. by some network call)).
            // So do nothing.
            return
          }

          set_families_subselections(
            [{ key: x_key, items: [x_item] }], // the subselections
            x_item.x                             // the value
          )
        }}
      />
    </BasePlotlyXYChartWrapper>
  )

  const legend = !show_legend ? null : (
    <Legend
      items={legend_items}
    />
  )

  return (
    <ChartAndLegendHolder
      chart={chart}
      legend={legend}
    />
  )
}

ScatterChart.get_axis_titles    = get_axis_titles
ScatterChart.get_plotly_data    = get_plotly_data
ScatterChart.get_csv_value_rows = get_csv_value_rows

export default ScatterChart
