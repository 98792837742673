import React from 'react'

import ScatterChart from './ScatterChart.js'
import { get_timerange } from '../../utils/time_range_utils.js'

function transform_data_for_scatter_chart({data}) {
  const [x,, count, cagr] = data.data
  return {data: [x, count, cagr]}
}

function get_spec_with_column_names({spec, item, key_dims}) {
  const {column_names} = spec || {}
  const [x,] = column_names || []
  const [count, cagr] = get_cagr_column_names({spec, item, key_dims})
  return {...spec, column_names: [x, count, cagr]}
}

function get_cagr_column_names({spec, item, key_dims}) {
  const timerange = get_timerange({spec, item, key_dims})
  const [year_min, year_max] = timerange || []
  const active_families_column_name = (!year_min || !year_max) ? 'Active patent families' : `Active patent families in ${year_max}`
  const cagr_column_name = (!year_min || !year_max) ? 'Compound Annual Growth Rate (CAGR)' : `Compound Annual Growth Rate (CAGR) ${year_min}-${year_max}`
  return [active_families_column_name, cagr_column_name]
}

const CagrScatterChart = (props) => {
  const {spec, data, key_dims, item, data_creation_date} = props
  const scatter_data = transform_data_for_scatter_chart({spec, data, key_dims, item, data_creation_date})
  const spec_with_column_names = get_spec_with_column_names({spec, item, key_dims})
  return (
    <ScatterChart
      {...props}
      data={scatter_data}
      spec={spec_with_column_names}
    />
  )
}

CagrScatterChart.get_axis_titles = (spec, other_props) => {
  const {item, key_dims} = other_props
  return ScatterChart.get_axis_titles(get_spec_with_column_names({spec, item, key_dims}))
}

CagrScatterChart.get_plotly_data = (props) => {
  const data = transform_data_for_scatter_chart(props)
  const spec = get_spec_with_column_names(props)
  return ScatterChart.get_plotly_data({...props, data, spec})
}

CagrScatterChart.get_csv_value_rows = (props) => {
  const data = transform_data_for_scatter_chart(props)
  const spec = get_spec_with_column_names(props)
  return ScatterChart.get_csv_value_rows({...props, data, spec})
}

export default CagrScatterChart