import { get_as_map } from '../../utils/utils.js'
import _ from 'underscore'

export const TAG_ACTIVE  = 'active'
export const TAG_GRANTED = 'granted'
export const TAG_EXPIRED = 'expired'
export const TAG_PENDING = 'pending'
export const TAG_TIME    = 'time'
export const TAG_ORG     = 'org'
export const TAG_TECH    = 'tech'
export const TAG_GEO     = 'geo'

export const STATUS_TAGS = [
  { id: TAG_ACTIVE,  label: 'Active' },
  { id: TAG_PENDING, label: 'Pending'},
  { id: TAG_GRANTED, label: 'Granted'},
  { id: TAG_EXPIRED, label: 'Expired'},
]

export const NON_STATUS_TAGS = [
  { id: TAG_ORG,     label: 'Organisation' },
  { id: TAG_TECH,    label: 'Technology'   },
  { id: TAG_GEO,     label: 'Geography'    },
  { id: TAG_TIME,    label: 'Time'         },
]

const ALL_TAGS = [
  ...STATUS_TAGS, ...NON_STATUS_TAGS
]

const STATUS_TAGS_BY_ID = get_as_map(STATUS_TAGS, 'id')
export const STATUS_TAG_IDS = _.keys(STATUS_TAGS_BY_ID)
export const TAGS_BY_ID = get_as_map(ALL_TAGS, 'id')

