import { get_as_map } from '../../../utils/utils.js'

export const ACTION_UNSAVE_ID = 'unsave'
export const ACTION_DELETE_ID = 'delete'
export const ACTION_ADD_TO_PROJECT_ID = 'add_to_project'

const ACTION_UNSAVE = {
  id: ACTION_UNSAVE_ID,
  modal_title: (report_count) => report_count > 1 ? `Removing saved status from ${report_count} reports - are you sure?` : 'Are you sure you want to remove this report from saved?',
  confirm_label: 'Remove from saved',
  modal_text: 'Unsaved reports expire after six months of inactivity.'
}

const ACTION_DELETE = {
  id: ACTION_DELETE_ID,
  modal_title: (report_count) => report_count > 1 ? `Deleting ${report_count} selected reports - are you sure?` : 'Are you sure you want to delete this report?',
  confirm_label: 'Delete',
  modal_text: 'Deleted reports will no longer be available to you on this page. Any users you have shared the reports with will still be able to access them until the reports expire, which occurs after six months of inactivity.'
}

const ACTION_ADD_TO_PROJECT = {
  id: ACTION_ADD_TO_PROJECT_ID,
  modal_title: (report_count) => report_count > 1? `Adding ${report_count} reports to project - are you sure?` : `Are you sure you want to add this report to the project?`,
  confirm_label: 'Add to project',
  modal_text: ''
}

const MODAL_REPORT_ACTIONS = [ACTION_DELETE, ACTION_UNSAVE, ACTION_ADD_TO_PROJECT]
export const ID_TO_MODAL_REPORT_ACTION = get_as_map(MODAL_REPORT_ACTIONS, 'id')

