import React from 'react'
import cn from 'classnames'

import { InfoPopover } from '../widgets/Tooltip.js'
import { ChevronRightIcon, MenuIcon } from '../widgets/IconSet.js'
import TextLink from '../widgets/TextLink.js'
import { GoogleSearchLink } from '../widgets/GoogleSearchLink.js'

import s from './OrgMenu.module.scss'

const OrgMenu = ({ export_assignees_handler, org_name, run_assignee_report, buttonClassName }) => {
  return (
    <InfoPopover
      toggler={<span><MenuIcon /></span>}
      placement='bottom'
      interactive={true}
      buttonClassName={buttonClassName}
    >
      <div className={cn('d-flex flex-column p-2', s.menu)}>
        {export_assignees_handler && <TextLink className='mt-1' onClick={export_assignees_handler}><ChevronRightIcon /> Export assignees</TextLink>}
        {run_assignee_report && <TextLink className='mt-1' onClick={run_assignee_report}><ChevronRightIcon /> Build report</TextLink>}
        <GoogleSearchLink className='mt-1' search_string={org_name} label={<span><ChevronRightIcon /> Search in Google</span>} />
      </div>

    </InfoPopover>
  )
}

export default OrgMenu