import React from 'react'

import { MAX_REPORT_NAME_LENGTH } from '../constants/constants.js'
import { is_valid_report_name } from '../utils/name_utils.js'
import NameInput from './NameInput.js'

const ReportNameInput = ({report_name, on_change, on_key_up, className}) => {

  return (
    <NameInput
      name={report_name}
      heading='Report name'
      invalid_name_message={`Report name cannot be empty or longer than ${MAX_REPORT_NAME_LENGTH} characters.`}

      on_change={on_change}
      on_key_up={on_key_up}
      is_name_valid_handler={is_valid_report_name}

      className={className}
    />
  )
}

export default ReportNameInput