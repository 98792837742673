import { get_as_map } from '../utils/utils.js'

export const PARENT_LEVEL_ID = 'parent'
export const ROOT_LEVEL_ID = 'root'

export const PARENT_LEVEL = {id: PARENT_LEVEL_ID, name: 'Direct owner'}
export const ROOT_LEVEL   = {id: ROOT_LEVEL_ID, name: 'Top level owner'}

export const GROUP_BY_OWNER_LEVELS = [
  PARENT_LEVEL, ROOT_LEVEL
]

export const DEFAULT_GROUP_BY_OWNER_LEVEL = PARENT_LEVEL_ID

export const ID_TO_GROUP_BY_OWNER_LEVELS = get_as_map(GROUP_BY_OWNER_LEVELS, 'id')