import _ from 'underscore'

import { flatten_arrays } from './utils.js'
import { SUPER_CLASSIFIERS_GROUP_TITLE } from '../constants/constants.js'

export function is_classifier(node_data) {
  return node_data.classifier_id != null
}

export function is_same_classifier(this_classifier, that_classifier) {
  return this_classifier.classifier_id === that_classifier.classifier_id
}

export function is_deep_tree(node_data) {
  // returns true if the tree has more than one level of parent nodes to expand
  return _.some(node_data, node => {
    return node.children && _.some(node.children, child => child.children)
  })
}

export function is_search_match(search_input, name) {
  return name.toLowerCase().indexOf(search_input.toLowerCase()) !== -1
}

export function get_checkbox_tree_value(node_data) {
  const { classifier_id, name, path } = node_data

  // each classifier node needs a unique string value that will be passed when its box is checked
  // use the classifier ids if available so we can access these in props function(s)
  if (is_classifier(node_data)) {
    return classifier_id
  }

  // Risk of conflict here? Ideally we would generate a truly unique value for each node.
  // For now, use combination of path and name
  const path_string = (path != null) ? path.join('/') : '' // path is null for top-level nodes
  const value = `${path_string}/${name.toLowerCase()}`
  return value
}

export function get_child_nodes_as_array(node) {
  if (node.children) {
    return [...node.children, ...flatten_arrays(node.children.map(child => get_child_nodes_as_array(child)))]
  }
  return []
}

export function get_leaf_nodes_as_array(node) {
  if (node && node.children) {
    const leaves = node.children.filter(child => !child.children)
    return [...leaves, ...flatten_arrays(node.children.map(child => get_leaf_nodes_as_array(child)))]
  }
  return []
}

export function get_as_list(node_data) {
  // return flat list of nodes
  if (!node_data.children) {
    return [node_data]
  }
  return [node_data].concat(_.flatten(node_data.children.map(get_as_list)))
}

export function add_parent_refs(classifiers) {
  let node_data = {...classifiers}

  // For each child, adds a property "parent" that references the parent
  if (node_data.children) {
    node_data.children.forEach(child => {
      const ancestor_parent = node_data.parent || []
      const ancestor_name = node_data.name
      child.parent = [...ancestor_parent, ancestor_name]
      add_parent_refs(child) // recurse
    })
  }

  return node_data
}

export function filter_super_classifiers(taxonomy_classifiers) {
  // super classifiers are not available for subscriptions
  const filtered_techs = taxonomy_classifiers.children.filter(t => t.name !== SUPER_CLASSIFIERS_GROUP_TITLE)
  return {...taxonomy_classifiers, children: filtered_techs}
}

/**
 * Given an array of item objects with 'tree_path' properties (i.e. '/automotive/ADAS/lidar', 'automotive/brakes'),
 * parse into a tree.
 */
export function get_items_as_tree(items, root_name, no_prepend) {
  root_name = root_name || 'root'

  // Prepend 'root' to all paths
  const items_with_root_path = no_prepend ? items : items.map(item => {
    const { tree_path } = item
    const tree_path_clean = tree_path || [] // non-taxonomy classifiers have null path, so add an empty array here

    const path = [root_name, ...tree_path_clean] // append root
    return {
      ...item, tree_path: path
    }
  })

  // Iterate over items, adding them into the root tree (immutably)
  const root_tree = items_with_root_path.reduce((acc, item) => {
    const { tree_path } = item
    return update_tree(acc, tree_path, item)
  }, { name: root_name })

  return root_tree
}

/**
 * Given a tree, immutably insert data at the specified path (returns a new tree).
 */
export function update_tree(tree, path, data) {
  const { name, children=[] } = tree

  if (name !== path[0]) {
    // This should never happen
    throw Error('name and path mismatch')
  }

  // BASE CASE
  if (path.length === 0) {
    return { ...tree, name: data.name, ...data }
  }

  // GENERAL CASE
  const child_path = path[1]

  const existing_child            = !children ? null : _.find(children, item => item.name === child_path)
  const children_without_existing = !children ? []   : children.filter(item => item.name !== child_path)

  const next_child = existing_child ?
        update_tree(existing_child, path.slice(1), data) : // recurse: update existing
        update_tree({ name: child_path },  path.slice(1), data) // recurse: create new

  const next_children = [ ...children_without_existing, next_child ]
  const next_children_sorted = _.sortBy(next_children, 'name')

  return { ...tree, children: next_children_sorted }
}

/**
 * Remove any single-child nodes, pushing their children up the tree.
 */
export function remove_single_child_nodes(tree) {
  const { children } = tree

  if (!children || children.length === 0) {
    // BASE CASE
    return tree
  }

  if (children.length === 1) {
    // SINGLE CHILD: remove this node and recurse
    return remove_single_child_nodes(children[0])
  }

  // MULTIPLE CHILDREN: return node, but recurse over children
  return {
    ...tree,
    children: tree.children.map(remove_single_child_nodes)
  }
}

/**
 * This function expects each classifier to have a taxonomy_path property which is a list (i.e. ['Energy'],['Energy', 'Wind', 'Turbines'] etc...).
 */
export function get_classifiers_group_as_tree(classifiers) {
  // Ensure all classifiers start with same path prefix i.e. ['Energy', 'Solar'], ['Energy', 'Wind'] etc...
  const prefix = classifiers[0].taxonomy_path[0]
  const classifier_with_invalid_prefix = _.find(classifiers, (classifier) => classifier.taxonomy_path[0] !== prefix)
  if (classifier_with_invalid_prefix) {
    throw new Error(`Invalid prefix found. Expected all taxonomy paths to start with '${prefix}'. But found '${classifier_with_invalid_prefix.taxonomy_path[0]}'`)
  }

  // Parse
  const tree = classifiers.reduce((_tree, classifier) => {
    const { taxonomy_path } = classifier
    return get_node_with_leaf(_tree, taxonomy_path.slice(0, 1), taxonomy_path.slice(1), classifier)
  }, { name: prefix, children: [] })

  // Sort alphabetically
  const sorted_tree = get_sorted_tree(tree)

  return sorted_tree
}

/**
 * Given a target node (a tree, with 'children' property),
 * a 'path' and 'parent_path' relative to the target node,
 * and leaf value,
 * this function returns a new node with the leaf inserted at the correct level.
 *
 * So for example, given parent_path [] and path ['Energy', 'Renewable', 'Solar', 'Panel'],
 * new node will have the leaf at ['Energy', 'Renewable', 'Solar', 'Panel'].
 *
 * And likewise, given parent_path ['Energy', 'Renewable'] and path ['Solar', 'Panel'],
 * new node will have the leaf at ['Solar', 'Panel'].
 */
function get_node_with_leaf(node, parent_path, path, leaf) {
  if (path.length === 0) {
    // BASE CASE
    const leaf_full = { ...leaf, parent: parent_path}
    const new_children = [...node.children, leaf_full] // INSERT LEAF
    return { ...node, children: new_children }
  }

  // Try to find parent node
  const parent_name = path[0]
  const existing_parent = _.find(node.children, (child) => check_is_matching_parent(child, parent_name))

  // Recurse
  const new_parent = get_node_with_leaf(
    existing_parent || { name: parent_name, children: [], parent: parent_path},   // next node
    [...parent_path, parent_name],                                                // next parent path
    path.slice(1),                                                                // next path
    leaf                                                                          // value
  )

  if (existing_parent != null) {
    // REPLACE
    const new_children = node.children.map((child) => check_is_matching_parent(child, parent_name) ? new_parent : child)
    return { ...node, children: new_children }
  }

  // APPEND
  const new_children = [...node.children, new_parent]
  return { ...node, children: new_children }
}

function check_is_matching_parent(node, name) {
  return (node.name === name) && (node.children != null)
}

/**
 * Sorts classifier tree.
 */
function get_sorted_tree(node) {
  const { children } = node

  // BASE CASE
  if (!children) {
    return node
  }

  // Sort (put parents first, then leaves)
  const [parents, leaves] = _.partition(children, (node) => node.children != null)
  const leaves__sorted = _.sortBy(leaves, 'name')
  const parents__sorted = _.sortBy(parents, 'name')
  const children__sorted = [...parents__sorted, ...leaves__sorted]

  return { ...node, children: children__sorted.map(get_sorted_tree) }
}

export function find_classifier_match_in_trees(classifier_trees, classifier_id) {
  // returns the first matching classifier in an array of classifier trees
  const all_classifiers = classifier_trees.map(tree => tree.children ? get_leaf_nodes_as_array(tree) : tree)
  return _.find(_.flatten(all_classifiers), {classifier_id})
}


export function is_node_fully_deselected(selected_ids, node_ids) {
  if ((selected_ids || []).length === 0) return true
  return !_.some(node_ids, c => _.contains(selected_ids, c))
}

export function is_node_fully_selected(selected_ids, node_ids) {
  if ((selected_ids || []).length === 0) return false
  return _.every(node_ids, c => _.contains(selected_ids, c))
}

export function is_node_partially_selected(selected_ids, node_ids) {
  return !is_node_fully_selected(selected_ids, node_ids) && !is_node_fully_deselected(selected_ids, node_ids)
}

function include_every_node_id(selected_ids, node_ids) {
  return _.uniq([...selected_ids, ...node_ids])
}

function exclude_every_node_id(selected_ids, node_ids) {
  return selected_ids.filter(c => !_.contains(node_ids, c))
}

export function update_selected_with_node_ids(selected_ids, node_ids, should_exclude_if_partial_node_clicked) {
  if (is_node_fully_deselected(selected_ids, node_ids)) {
    return include_every_node_id(selected_ids, node_ids)
  }

  if (is_node_fully_selected(selected_ids, node_ids)) {
    return exclude_every_node_id(selected_ids, node_ids)
  }

  return (should_exclude_if_partial_node_clicked) ? exclude_every_node_id(selected_ids, node_ids) : include_every_node_id(selected_ids, node_ids)
}

export function get_tree_node_by_path(tree, path) {
  const { children } = tree || {}

  if (path.length === 0 || !children) {
    return tree
  }

  const node_name = path[0]

  return get_tree_node_by_path(children.filter(item => item.name === node_name)[0], path.slice(1))
}
