import { CUSTOM_SET_CIPHER, CUSTOM_SET_SHARED, CUSTOM_SET_USER } from '../constants/default_chart_selection.js'

function get_prefix(chart_set) {
  const {id, is_shared_set} = chart_set
  if (id) {
    //chart set created by Cipher
    return  CUSTOM_SET_CIPHER
  } else {
    return is_shared_set ? CUSTOM_SET_SHARED : CUSTOM_SET_USER
  }
}

export function get_default_chart_selection_value(chart_set) {
  const {name=''} = chart_set
  const prefix = get_prefix(chart_set)
  return `${prefix}:${name}`
}
