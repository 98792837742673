import { LITIGATION_ROUTES } from './dispute_routes.js'

export const STATUS_ACTIVE  = "'granted', 'pending'"
export const STATUS_GRANTED = "'granted'"
export const STATUS_PENDING = "'pending'"
export const STATUS_EXPIRED = "'expired'"

export const LITIGATION_ROUTES_STRING = `'${LITIGATION_ROUTES.join("','")}'`

export const COST_DESCRIPTION = 'Preparation, prosecution and renewal cost'

export const NOT_AVAILABLE_LABEL = 'n/a'

export const PVIX_BUCKET_SIZE = 5
export const COST_BUCKET_SIZE = 5000