export const SPEC_ID = 'spec_id'
export const VIEW_ID = 'view_id'
export const ROLLUP_THRESHOLDS = 'rollup_thresholds'
export const NEXT_AGGLOM_VISIBILITY = 'next_agglom_visibility'
export const DATA_ORIENTATION = 'data_orientation'
export const INCLUDE_ALL_ZEROS = 'include_all_zeros'
export const TIMERANGE = 'timerange'
export const HISTOGRAM_RANGE = 'histogram_range'
export const SELECTED_DISPUTE_TYPE = 'selected_dispute_type'
export const SELECTED_REGION_GROUPING = 'selected_region_grouping'
export const DESELECTED_PORTFOLIO_IDS = 'deselected_portfolio_ids'
export const SPOTLIGHTED_PORTFOLIOS = 'spotlighted_portfolios'
export const DESELECTED_TECH_IDS = 'deselected_tech_ids'
export const SPOTLIGHTED_TECHS = 'spotlighted_techs'
export const DESELECTED_GEO_IDS = 'deselected_geo_ids'
export const PLAINTIFF_TYPE_IDS = 'plaintiff_type_ids'
export const DEFENDANT_TYPE_IDS = 'defendant_type_ids'
export const OUTCOME_IDS = 'outcome_ids'
export const START_DATE_RANGE = 'start_date_range'