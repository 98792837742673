import React, { useState } from 'react'
import _ from 'underscore'

import { ID_TO_CONTINENT, ID_TO_COUNTRY, WO } from '../../constants/countries.js'
import { Highlighter } from '../widgets/Highlighter.js'
import { is_search_match } from '../../utils/classifier_tree_utils.js'
import CheckboxTree from '../widgets/CheckboxTree.js'

const CONTINENT_ID_PREFIX = 'continent_'

const GeoCheckboxTree = ({continent_id_to_geo_ids, selected_geo_ids, on_toggle_select_all_continent_id, on_toggle_selected_geo_id, search_phrase}) => {
  const [expanded, set_expanded] = useState([])

  function make_continent_id_for_tree(continent_id) {
    return CONTINENT_ID_PREFIX + continent_id
  }

  function extract_continent_id(continent_id_with_prefix) {
    return (continent_id_with_prefix.split('_') || [])[1] || ''
  }

  function is_wipo(node_value) {
    return node_value === make_continent_id_for_tree(WO)
  }

  function on_expand(to_expand) {
    const new_expanded = to_expand
    set_expanded(new_expanded)
  }

  function on_check(checked, node) {
    if (node && (node.children || is_wipo(node.value))) {
      const {value} = node
      const continent_id = extract_continent_id(value)
      return on_toggle_select_all_continent_id(continent_id)
    }
    return on_toggle_selected_geo_id(node.value)
  }

  function render_label(text, search_phrase) {
    return (
      <Highlighter
        search_words={[search_phrase]}
        text_to_highlight={text}
      />
    )
  }

  function is_match(text, search_phrase) {
    if ((!search_phrase || !search_phrase.length) || (is_search_match(search_phrase || '', text || ''))) {
      return true
    }
    return false
  }

  function get_nodes() {
    const continent_ids = _.keys(continent_id_to_geo_ids)
    continent_ids.sort((id_a, id_b) => {
      return ID_TO_CONTINENT[id_a].name >= ID_TO_CONTINENT[id_b].name ? 1 : -1 // sort alphabetically (in place)
    })
    const nodes = continent_ids.map(continent_id => {
      const continent = ID_TO_CONTINENT[continent_id]

      const continent_geos = continent_id_to_geo_ids[continent_id]

      const territories = continent_geos
        .map(geo_id => ID_TO_COUNTRY[geo_id])
        .filter(territory => (is_match(territory.name, search_phrase)))
        .map(territory => {
          const {name, country_code} = territory
          return {
            value: country_code,
            label: render_label(name, search_phrase),
            showCheckbox: true
          }
      })

      const value = make_continent_id_for_tree(continent_id)

      return {
        value,
        label: continent.name,
        showCheckbox: true,
        className: territories.length > 0 ? '' : 'd-none',
        children: is_wipo(value) ? null : territories // WIPO only includes one territory, so show to one level only
    }})
    return nodes
  }

  const territories = get_nodes()

  const expanded_continents = (!search_phrase || search_phrase.length === 0) ? expanded : _.keys(continent_id_to_geo_ids).map(continent_id => (make_continent_id_for_tree(continent_id)))

  const is_wipo_selected = _.contains(selected_geo_ids, WO)
  const checked = [...selected_geo_ids, ...(is_wipo_selected ? [make_continent_id_for_tree(WO)] : [])]

  return (
    <CheckboxTree
      nodes={territories}
      checked={checked}
      expanded={expanded_continents}
      on_expand={(expanded) => on_expand(expanded)}
      on_check={(checked, node) => on_check(checked, node)}
      on_click={(node) => {
        const {value} = node
        if (node.children || is_wipo(value)) {
          on_toggle_select_all_continent_id(extract_continent_id(value))
        } else {
          on_toggle_selected_geo_id(value)
        }
      }}
      show_expand_all={true}
    />
  )
}

export default GeoCheckboxTree