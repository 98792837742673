import React from 'react'
import { ReportBuilderInfoPanel } from './ReportBuilderInfoPanel.js'

const ReportBuilderNoAccessPanel = () => {
  return (
    <div className='d-lg-flex w-100'>
      <ReportBuilderInfoPanel>
        <div>You do not have permission to view this page.</div>
      </ReportBuilderInfoPanel>
    </div>
  )
}

export default ReportBuilderNoAccessPanel