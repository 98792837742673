import _ from 'underscore'

export const NO_ROLLUP_LIMIT = -1

export const AVAILABLE_ROLLUP_LIMITS = [
  5,
  10,
  15,
  20,
  25,
  50,
  100,
  1000,
  NO_ROLLUP_LIMIT // all
]

export const DEFAULT_ROLLUP_LIMIT = 10 // must be in AVAILABLE_ROLLUP_LIMITS

export const MINIMUM_ROLLUP_LIMIT = _.min(AVAILABLE_ROLLUP_LIMITS.filter(d => d !== -1))
