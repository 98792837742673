import React from 'react'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'

/**
 * This modal has no close button.
 */
const BlockingNotificationModal = ({title, children}) => {

  return (
    <Modal
      isOpen={true}
      zIndex={10000}
    >
       <ModalHeader>
         {title}
       </ModalHeader>
       <ModalBody>
         {children}
       </ModalBody>
       <ModalFooter/>
     </Modal>
  )

}

export default BlockingNotificationModal