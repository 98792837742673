import React from 'react'
import cn from 'classnames'

import TextLink from '../widgets/TextLink.js'
import { DATASET_HELP_LINK_CLASSNAME, get_help_article_url, on_click_from_help_link } from '../../utils/help_utils.js'

const SpecHelpLink = ({help_article, label, className}) => {
  if (!help_article) return null

  const help_link = get_help_article_url(help_article)
  
  return (
    <TextLink element='a' href={help_link} target='_blank' className={cn(DATASET_HELP_LINK_CLASSNAME, className)} onClick={() => on_click_from_help_link(help_link)}>{label || 'Help centre article'}</TextLink>
  )
}

export default SpecHelpLink