import React from 'react'

/**
 * This component uses React's Context API (https://reactjs.org/docs/context.html) to make user permissions data available
 * via props to components throughout the app that need it (rather than having to pass an extra prop through several
 * layers of the tree that don't to know about it).
 *
 * A "context" in React is really just a prop object or value that we want to use in multiple various components throughout
 * our app.
 *
 * Usage:
 * To give the "context" a value we first need to add a context Provider as a wrapper to encompass all the components
 * that will use this value, supplying a value as a prop to the provider. As in App.js:
 *
 * <UserContext.Provider value={user_permissions}>
 *   <PageContainer>
 *     <MainRoutes />
 *          (.... etc etc this can be a big tree with a lot of nested components)
 *   </PageContainer>
 * </UserContext.Provider>
 *
 * The usual way to then use the context value is to add a Consumer to a render() function. The Consumer should have a function
 * as it's child which allows us to parse the value as a prop into components nested there or to use the value
 * immediately to decide how to render, egs:
 *
 * render(
 *   <UserContext.Consumer>
 *     {user => (
 *       <DisputeDetails user={user} />
 *     )
 *   </UserContext.Consumer>
 * )
 *
 * render(
 *   <UserContext.Consumer>
 *     {user => {   // you can call this variable anything you like when you consume it; the context only has one value
 *       if (!has_restricted_fields(user)) {
 *         return <RestrictedComponent />
 *       }
 *     }
 *   </UserContext.Consumer>
 * )
 */
const UserContext = React.createContext({})

/**
 * A higher order component to allow us to make the context prop available in a component's lifecycle methods without
 * having to nest in a render function. This is useful where we're specifying components to use for particular routes
 * etc. Example usage:
 *
 * const DashboardWithUser = withUser(ReportBuilderDashboard)
 * @param {*} Component
 */
export function withUser(Component) {
  return function ComponentWithUser(props) {
    return (
      <UserContext.Consumer>
        {user => <Component {...props} user={user} />}
      </UserContext.Consumer>
    )
  }
}

export default UserContext