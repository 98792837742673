import _ from 'underscore'
import {ID_TO_SPEC} from './specs.js'

// Functions for immutably transforming "spec_id_to_fetch_obj"

export function set_all_is_fetching(spec_id_to_fetch_obj, spec_ids) {
  return spec_ids.reduce(set_is_fetching, spec_id_to_fetch_obj)
}

export function set_is_fetching(spec_id_to_fetch_obj, spec_id) {
  return {
    ...spec_id_to_fetch_obj,
    [spec_id]: { is_fetching: true }
  }
}

export function set_all_to_fetch(spec_id_to_fetch_obj, spec_ids) {
  return spec_ids.reduce(set_to_fetch, spec_id_to_fetch_obj)
}

export function set_to_fetch(spec_id_to_fetch_obj, spec_id) {
  return {
    ...spec_id_to_fetch_obj,
    [spec_id]: { to_fetch: true }
  }
}

export function set_data(spec_id_to_fetch_obj, spec_id, data) {
  const spec = ID_TO_SPEC[spec_id]
  const { transform_data } = spec || {}
  const item_data = transform_data ? transform_data(data) : data

  return {
    ...spec_id_to_fetch_obj,
    [spec_id]: { data: item_data }
  }
}

export function set_error(spec_id_to_fetch_obj, spec_id, error) {
  return {
    ...spec_id_to_fetch_obj,
    [spec_id]: { fetch_error: error }
  }
}

export function remove_fetch_obj(spec_id_to_fetch_obj, spec_id) {
  return _.omit(spec_id_to_fetch_obj, spec_id)
}