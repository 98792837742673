import React from 'react'
import cn from 'classnames'

import { CPC_CODE_COLUMN_NAME } from '../../constants/spec_column_names.js'

import {
  get_keys_to_value,
  get_value_column_idx,
  get_key_items_sorted_by_value,
  get_value,
  IS_NEXT_AGGLOM,
  get_subselections
} from '../../utils/column_data_utils.js'
import { format_integer_with_comma, sum, startsWith } from '../../utils/utils.js'
import { get_csv_string } from '../../utils/csv_utils.js'
import { is_report_series_sort_by_size } from '../../utils/report_utils.js'

import { CATEGORY_COLUMN_IDX, KEY_COLUMN_IDX_1D, KEY_COLUMN_IDXS_2D, SERIES_COLUMN_IDX } from '../../model/column_data.js'
import { TABLE_COLUMN_HEADER } from '../../model/table.js'
import { BaseTable } from './BaseTable.js'
import CpcWithHover from '../family_view/CpcWithHover.js'
import { get_key_from_spec } from '../../utils/spec_utils.js'
import { should_hide_next_agglom_item } from '../../utils/main_items_utils.js'

import s from './TabularView.module.scss'

const TOTAL_LABEL = 'TOTAL'
const AVERAGE_LABEL = 'AVERAGE'

function get_1d_data({y_items, keys_to_value, spec, report_region_column, item}) {
  const { hide_totals, show_average } = spec

  const hide_next = should_hide_next_agglom_item(spec, item, 0)

  const key = get_key_from_spec(spec, report_region_column)
  const y_key = key[0]

  const rows = []
  const values = []

  const y_items_filtered = y_items.filter(y_item => !(hide_next && y_item[IS_NEXT_AGGLOM])) // remove rolled up items if spec doesn't allow them

  y_items_filtered.forEach(y_item => {
    const value = keys_to_value[y_item.id] || 0

    if (value !== 0) {
      values.push(value)
      rows.push([{value: y_item.name}, { value, subselection: get_subselections(false,{y_key}, {y_items: [y_item]})}])
    }
  })

  const total_value = sum(values)
  const total_row = [{ value: TOTAL_LABEL, is_total: true }, { value: total_value, subselection: get_subselections(false,{y_key}, {y_items: y_items_filtered}), is_total: true }]
  const avg_value = total_value/rows.length
  const average_row = [{ value: AVERAGE_LABEL, is_avg: true}, { value: avg_value, subselection: get_subselections(false,{y_key}, {y_items: y_items_filtered}), is_avg: true }]

  if (show_average) {
    rows.push(average_row)
  }

  if (!hide_totals) {
    rows.push(total_row)
  }

  return rows
}


function get_2d_data({y_items, x_items, keys_to_value, spec, report_region_column, item}) {
  const { hide_totals, no_value_label } = spec
  const key = get_key_from_spec(spec, report_region_column)
  const [ x_key, y_key ] = key
  const hide_x_item_next = should_hide_next_agglom_item(spec, item, 0)
  const hide_y_item_next = should_hide_next_agglom_item(spec, item, 1)

  const x_items_filtered = x_items.filter(item => !(hide_x_item_next && item[IS_NEXT_AGGLOM]))
  const y_items_filtered = y_items.filter(item => !(hide_y_item_next && item[IS_NEXT_AGGLOM]))

  const rows = []
  const values = []
  x_items_filtered.forEach(x_item => {
    const y_item_to_value = []
    y_items_filtered.forEach(y_item => {
      const value = get_value(keys_to_value, [x_item.id, y_item.id], no_value_label)
      if (value != null) {
        y_item_to_value.push({y_item, value})
      }
    })

    const sum_of_values = sum(y_item_to_value.map(item => item.value))

    if (sum_of_values > 0) {
      y_item_to_value.forEach(item => {
        const {y_item, value} = item
        rows.push([{value: x_item.name}, {value: y_item.name}, {value, subselection: get_subselections(false,{x_key, y_key}, {x_items: [x_item], y_items: [y_item]})}])
        values.push(value)
      })
    }

  })

  if (!hide_totals) {
    const totals_row = [{value: TOTAL_LABEL, is_total: true}, {value: '', is_total: true}, {value: sum(values), subselection: get_subselections(false,{x_key, y_key}, {x_items, y_items: y_items_filtered}), is_total: true}]
    rows.push(totals_row)
  }

  return rows
}

function get_multi_values_data({spec, item, data, key_dims, report_series_sort}) {
  const key = get_key_from_spec(spec, null)
  const x_key = key[0]
  const hide_x_item_next = should_hide_next_agglom_item(spec, item, 0)
  const key_items = key_dims[0]
  const key_to_value = get_keys_to_value(data, [0], 1, false)

  const key_items_ordered = is_report_series_sort_by_size(report_series_sort) ? get_key_items_sorted_by_value(key_items, key_to_value, false) : key_items
  const key_items_filtered = key_items_ordered.filter(item => !(hide_x_item_next && item[IS_NEXT_AGGLOM]))
  const key_ids = data.data[0]
  const rows = []

  key_items_filtered.forEach(x_item => {
    const {id} = x_item
    const idx = key_ids.indexOf(id)
    const row = []
    row.push({value: x_item.name})

    const values_count = data.data.length -1

    for (let j=1; j <= values_count; j++ ) {
      const value = data.data[j][idx]
      row.push({value, subselection: get_subselections(false,{x_key}, {x_items: [x_item]})})
    }

    rows.push(row)
  })

  return rows
}

function get_processed_data({ spec, data, key_dims, report_series_sort, report_region_column, item }) {
  //TODO: refactor this, it resambles what we do in BasePlotlyXYChart very much
  const is_1d = (data.data.length === 2)

  if (!is_1d && key_dims.length === 1) {
    //single key, multiple values
    const tab_data = get_multi_values_data({spec, data, key_dims, item, report_series_sort})

    return {
      tabular_data_rows: tab_data
    }
  }

  const is_time_series = (spec.timeseries_key_dim_idxs && spec.timeseries_key_dim_idxs.length) // TODO: probably need this for sorting 1d non-timeseries charts
  // x_items
  const x_items = is_1d ? key_dims[KEY_COLUMN_IDX_1D] : key_dims[CATEGORY_COLUMN_IDX]
  const y_items = is_1d ? key_dims[KEY_COLUMN_IDX_1D] : key_dims[SERIES_COLUMN_IDX]
  const value_column_idx = get_value_column_idx(data)
  const key_idxs = is_1d ? [KEY_COLUMN_IDX_1D] : KEY_COLUMN_IDXS_2D
  const keys_to_value = get_keys_to_value(data, key_idxs, value_column_idx, spec.show_average)

  const y_items_sorted = is_1d && !is_time_series && !spec.no_sorting && is_report_series_sort_by_size(report_series_sort) ? get_key_items_sorted_by_value(y_items, keys_to_value) : y_items

  const tabular_data_rows = is_1d ?
    get_1d_data({
      y_items: y_items_sorted,
      keys_to_value,
      spec,
      report_region_column,
      item
    }) : get_2d_data({
      y_items: y_items_sorted || [],
      x_items,
      keys_to_value,
      spec,
      report_region_column,
      item
    })

  return {
    tabular_data_rows
  }
}

function get_csv_value_rows({ spec, data, key_dims, report_series_sort, report_region_column, item }) {

  const { column_names } = spec

  const { tabular_data_rows } = get_processed_data({ spec, data, key_dims, report_series_sort, report_region_column, item })

  const data_rows = tabular_data_rows.map(row => {
    return row.map(item => (item.value))
  })

  return [
    column_names,
    ...data_rows
  ]
}

function get_plotly_data({ spec, data, key_dims, report_series_sort, report_region_column, item }) {
  const csv_data_rows = get_csv_value_rows({ spec, data, key_dims, report_series_sort, report_region_column, item })
  return get_csv_string(csv_data_rows)
}

const TabularView = ({spec, data, key_dims, is_thumbnail, report_series_sort, selections, set_families_subselections, item, thumbnail_className}) => {
  const COLUMN = {
    ...TABLE_COLUMN_HEADER,
    sort: column_sort,
    cell_render: row => (<span>{row.value.value}</span>)
  }

  function cell_render(item, set_families_subselections, has_clickthroughs, formatter) {
    const {value, subselection} = item

    const formatted_value = formatter ? formatter(value) : format_integer_with_comma(value)

    return (
      has_clickthroughs ? (<span
        className={cn({ [s.cell__clickthrough]: has_clickthroughs })}
        onClick={() => set_families_subselections(subselection, value)}
      >{formatted_value}</span>) : formatted_value
    )
  }

  function column_sort(item_a, item_b, desc) {
    if (item_a.is_total || item_a.is_avg) return (desc) ? -Infinity : Infinity
    if (item_b.is_total || item_a.is_avg) return (desc) ? -Infinity : Infinity

    const value_a = item_a.value
    const value_b = item_b.value

    if (value_a === value_b) {
      return 0
    }
    return value_a > value_b ? 1 : -1
  }

  function render_cpc_with_hover(row) {
    // Render CPCs with hover showing full detail
    const cpc_code = row.value ? row.value.value : row.value
    const cpc_lower_case = cpc_code ? cpc_code.toLowerCase() : cpc_code
    if (cpc_code && !startsWith(cpc_lower_case, 'total') && !startsWith(cpc_lower_case, 'next') ) {
      return (
        <CpcWithHover
          cpc_codes={[cpc_code]}
        />
      )
    }
    return cpc_code
  }

  function get_1d_columns({columns, formatter, set_families_subselections, spec}) {
    const { no_clickthrough } = spec
    const has_clickthroughs = !no_clickthrough

    return [
      {...COLUMN,
        id: columns[0],
        label: columns[0],
        headerClassName: cn(s.header_cell, s.table_y_values, s.table_column_width__80),
        className: cn(s.table_y_values, s.table_column_width__80),
        field: (row) => (row[0]),
        ...((columns[0] === CPC_CODE_COLUMN_NAME) ? { cell_render: (row) => render_cpc_with_hover(row) } : {})
      },
      {...COLUMN,
        id: columns[1],
        label: columns[1],
        headerClassName: cn(s.header_cell, s.table_x_values),
        className: cn(s.table_x_values),
        field: row => (row[1] || 0),
        cell_render: row => (
          cell_render(row.value, set_families_subselections, has_clickthroughs, formatter)
        )
      }
    ]
  }

  function get_2d_columns({columns, formatter, set_families_subselections, spec, has_multi_values}) {
    const { no_clickthrough } = spec
    const has_clickthroughs = !no_clickthrough
    return [
      {...COLUMN,
        id: columns[0],
        label: columns[0],
        headerClassName: cn(s.header_cell, s.table_y_values, s.table_column_width__40),
        className: cn(s.table_y_values, s.table_column_width__40),
        field: row => (row[0]),
        ...((columns[0] === CPC_CODE_COLUMN_NAME) ? { cell_render: (row) => render_cpc_with_hover(row) } : {})
      },
      {...COLUMN,
        id: columns[1],
        label: columns[1],
        headerClassName: cn(s.header_cell, {[s.table_y_values]: !has_multi_values}, {[s.table_x_values]: has_multi_values}, {[s.table_column_width__40]: !has_multi_values}),
        className: cn( {[s.table_y_values]: !has_multi_values}, {[s.table_x_values]: has_multi_values}, {[s.table_column_width__40]: !has_multi_values}),
        field: row => (row[1]),
        ...((columns[1] === CPC_CODE_COLUMN_NAME) ? { cell_render: (row) => render_cpc_with_hover(row) } : {})
      },
      {...COLUMN,
        id: columns[2],
        label: columns[2],
        headerClassName: cn(s.header_cell, s.table_x_values),
        className: cn(s.table_x_values),
        field: row => (row[2] || 0),
        cell_render: row => (
          cell_render(row.value, set_families_subselections, has_clickthroughs, formatter)
        )
      }
    ]
  }

  const { column_names, full_length_value_formatter, value_formatter } = spec
  const {report_region_column} = selections
  const is_1d = (data.data.length === 2)
  const { tabular_data_rows } = get_processed_data({ spec, data, key_dims, report_series_sort, report_region_column, item })
  const formatter = full_length_value_formatter || value_formatter

  const has_multi_values = !is_1d && (key_dims.length === 1)

  const columns = is_thumbnail ? [] : is_1d ?
    get_1d_columns({
      columns: column_names,
      formatter,
      set_families_subselections,
      spec
    }) :
    get_2d_columns({
      columns: column_names,
      formatter,
      set_families_subselections,
      spec,
      has_multi_values
    })

  return (
    <BaseTable
      item={item}
      columns={columns}
      data={tabular_data_rows}
      is_thumbnail={is_thumbnail}
      thumbnail_className={thumbnail_className}
    />
  )
}

TabularView.get_plotly_data = get_plotly_data

TabularView.get_csv_value_rows = get_csv_value_rows

export default TabularView