import React from 'react'

import { MIMETYPE_HTML, MIMETYPE_PDF } from '../../utils/download_utils.js'
import { PdfViewer } from './PdfViewer.js'
import { HTMLDocViewer } from './HTMLDocViewer.js'

export const DocViewer = ({src, mime_type, download_url, title, className}) => {
  switch (mime_type) {
    case MIMETYPE_PDF: return (
      <PdfViewer
        src={src}
        className={className}
        title={title}
        download_url={download_url}
      />
    )

    case MIMETYPE_HTML: return (
      <HTMLDocViewer
        src={src}
        className={className}
        title={title}
      />
    )

    default: return null
  }
}