import React from 'react'
import _ from 'underscore'

import { TEXT_BASE_STYLE_REACT } from './svg_styles.js'

const DEFAULT_WIDTH = 125

const ELLIPSIS = '..'

function is_overflow(container, required_width) {
  const bbox = container.getBBox();
  const width = bbox.width;
  return width > required_width;
}

function reduce_label(label) {
  // Are there commas? If so get the last one... try removing all words after it...
  const comma_idx = label.lastIndexOf(',')
  if (comma_idx !== -1) {
    return label.slice(0, comma_idx)
  }

  // Otherwise.. are there multiple words? If so, try remove the last word
  const words = label.split(' ')
  if (words.length > 1) {
    return label.split(' ').slice(0, -1).join(' ')
  }

  // Otherwise.. try removing the last character, and add elipsis
  // (need to remove any existing ellipsis first)
  const word = label.replace(ELLIPSIS, '')
  return word.slice(0, -1) + ELLIPSIS
}

class SvgText extends React.Component{

  constructor(props) {
    super(props)
    this.container_ref = React.createRef()
  }

  ensure_no_overflow() {
    const container      = this.container_ref.current
    const required_width = this.get_required_width()

    let label = container.textContent

    while (is_overflow(container, required_width)) {
      label = reduce_label(label)
      container.textContent = label
    }
  }

  componentDidMount() {
    if (!this.props.allow_overflow) {
      this.ensure_no_overflow()
    }
  }

  componentDidUpdate() {
    if (!this.props.allow_overflow) {
      this.ensure_no_overflow()
    }
  }

  get_required_width() {
    return this.props.width || DEFAULT_WIDTH
  }

  render() {
    const {bold, fontSize, colour, align_centre, align_right, y, label} = this.props

    const required_width = this.get_required_width()
    const style = _.extend({}, TEXT_BASE_STYLE_REACT)

    if (bold) {
      style.fontWeight = 'bold'
    }

    if (fontSize) {
      style.fontSize = fontSize;
    }

    if (colour) {
      style.fill = colour
    }

    let x = 0, text_anchor = 'left'

    if (align_centre) {
      x = required_width / 2
      text_anchor = 'middle'
    } else if (align_right) {
      x = required_width
      text_anchor = 'end'
    }

    return (
      <text
        ref={this.container_ref}
        width={required_width}
        y={y || 10 }
        x={x}
        textAnchor={text_anchor}
        style={style}
       >
        {label}
       </text>
    )
  }
}

export default SvgText
