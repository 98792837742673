import axios from 'axios'
import _ from 'underscore'

import { REPORT_HISTORY_BASE_URL, REPORT_SAVED_BASE_URL } from '../constants/urls.js'
import { CONFIG_NO_CACHE, JSON_POST_HEADER, add_source_err_to_target_err } from './axios_utils.js'
import { MAX_TAG_NAME_LENGTH } from '../constants/constants.js'

export function update_reports_saved(external_report_ids, is_save) {
  const params = is_save ? '' : '?delete=true'
  return axios.put(`${REPORT_SAVED_BASE_URL}${params}`, {external_report_ids}, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to save report: ')
    })
}

export function update_saved_reports_with_tag(external_report_ids, tag_id, to_tag) {
  const params = to_tag ? '' : '?delete=true'
  return axios.put(`${REPORT_SAVED_BASE_URL}/tag/${tag_id}/report${params}`, {external_report_ids}, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update report tags: ')
    })
}

export function create_new_tag(external_report_ids, name) {
  const data = {name, external_report_ids}
  return axios.post(`${REPORT_SAVED_BASE_URL}/tag`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update report tags: ')
    })
}

export function fetch_all_tags() {
  return axios.get(`${REPORT_SAVED_BASE_URL}/tag`, CONFIG_NO_CACHE)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to fetch tags: ')
    })
}

export function update_tag_name(tag_id, name) {
  const data = {name}
  return axios.put(`${REPORT_SAVED_BASE_URL}/tag/${tag_id}`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to update tag name: ')
    })
}

export function delete_tag(tag_id) {
  return axios.delete(`${REPORT_SAVED_BASE_URL}/tag/${tag_id}`)
    .then(response => response.data)
    .catch(err => {
      throw add_source_err_to_target_err(err, new Error(), 'Unable to delete tag: ')
    })
}

export function is_valid_tag_name(name, existing_tags) {
  // trailing whitespace will be removed from the tag name before saving
  const trimmed_name = (name || '').trim()
  if (trimmed_name.length < 1 || trimmed_name.length > MAX_TAG_NAME_LENGTH) {
    return false
  }
  return !_.some(existing_tags, t => t.name === trimmed_name)
}

export function report_has_tag(report, tag) {
  return report.tag_ids && _.contains(report.tag_ids, tag.tag_id)
}

export function save_report_viewed(external_report_id, has_viewed) {
  const data = {external_report_id, has_viewed}
  return axios.post(`${REPORT_HISTORY_BASE_URL}`, data, {headers: JSON_POST_HEADER})
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to save report viewed: ')
      throw wrapped_err
    })
}

/**
 * How many saved reports does the current user have?
 */
export function fetch_saved_reports_count() {
  return axios.get(`${REPORT_HISTORY_BASE_URL}/saved_count`)
    .then(response => response.data)
    .catch(err => {
      const wrapped_err = add_source_err_to_target_err(err, new Error(), 'Unable to count saved reports: ')
      throw wrapped_err
    })
}
