import { get_as_map } from '../utils/utils.js'

export const ASCENDING = 'asc'   // These are the ids that text-search service recognizes
export const DESCENDING = 'desc'

export const SORT_DIRECTIONS = [
  { id: ASCENDING,  name: 'ascending' },
  { id: DESCENDING, name: 'descending' }
]

export const ID_TO_SORT_DIRECTION = get_as_map(SORT_DIRECTIONS, 'id')
