import { detect } from 'detect-browser'

export const IS_PROD = (process.env.NODE_ENV === 'production')

export const BROWSER = detect()

export const MAX_REPORT_NAME_LENGTH = 100
export const MAX_PROJECT_NAME_LENGTH = 255
export const MAX_TAG_NAME_LENGTH = 100
export const MAX_FAMILY_TAG_NAME_LENGTH = 255

export const COMPARABLES_IN_SPEEDY_REPORT_COUNT = 7

// deprecated report types (no longer used for building, kept for now for reference/ checks)
// TODO update values in the db when we merge
export const C2_REPORT_TYPE = 'c2_migrated'
export const ND_REPORT_TYPE_OLD = 'nd_report'
export const MULTICLASS_REPORT_TYPE_OLD = 'multiclass'
export const CLASSIFIER_LANDSCAPE_REPORT_TYPE_OLD = 'cc_against_world'
export const EVAL_LANDSCAPE_REPORT_TYPE_OLD = 'eval_landscape'
export const CLASSIFIER_EVAL_LANDSCAPE_REPORT_TYPE_OLD = 'classifier_evaluation_landscape'

// report types
export const DEFAULT_REPORT_TYPE = 'default'
export const ND_REPORT_TYPE = 'nd'
export const CUSTOM_CLUSTERED_REPORT_TYPE = 'custom_clustered'
export const CLASSIFIER_LANDSCAPE_REPORT_TYPE = 'classifier_landscape'
export const UTT_LANDSCAPE_REPORT_TYPE = 'utt_landscape'
export const UTT_REPORT_TYPE = 'utt'
export const BENCHMARKING_REPORT_TYPE = 'benchmarking'
export const SET_THEORY_REPORT_TYPE = 'set_theory'

export const NO_REBUILD_REPORT_TYPES = [
  C2_REPORT_TYPE,
  CUSTOM_CLUSTERED_REPORT_TYPE,
  CLASSIFIER_LANDSCAPE_REPORT_TYPE,
  UTT_LANDSCAPE_REPORT_TYPE,
  BENCHMARKING_REPORT_TYPE,
  SET_THEORY_REPORT_TYPE,
  // deprecated types
  CLASSIFIER_LANDSCAPE_REPORT_TYPE_OLD,
  CLASSIFIER_EVAL_LANDSCAPE_REPORT_TYPE_OLD,
  EVAL_LANDSCAPE_REPORT_TYPE_OLD
]

export const DEBOUNCE_PERIOD = 1000 // milliseconds

export const SUPER_CLASSIFIERS_GROUP_TITLE = 'OVERVIEW'

export const SUPPORT_EMAIL_ADDRESS = 'support@cipher.ai'

export const PERCENTAGE_SYMBOL = '%'

export const FAVICON_FILE_NAME = 'favicon.ico'
export const FAVICON_WAIT_FILE_NAME = 'favicon_wait.ico'

export const SPIF_GROUP_URI='https://spif.group'
