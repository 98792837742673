export const SOURCE_TYPE_CLASSIFIER = 'classifier'
export const SOURCE_TYPE_UNRELATED = 'unrelated'
export const SOURCE_TYPE_C2C_UNRELATED = 'c2c_unrelated'
export const SOURCE_TYPE_CUSTOM_CLASSIFIER_NEGATIVES = 'custom_classifier_negatives'
export const SOURCE_TYPE_UTT = 'utt'
export const SOURCE_TYPE_CUSTOM_UPLOAD = 'custom_upload'
export const SOURCE_TYPE_TAXONOMY_CLASSIFIER = 'taxonomy_classifier' //deprecated
export const SOURCE_TYPE_MULTICLASS = 'multiclass' //deprecated


export const UNRELATED_SOURCE_TYPES = [SOURCE_TYPE_C2C_UNRELATED, SOURCE_TYPE_CUSTOM_CLASSIFIER_NEGATIVES, SOURCE_TYPE_UNRELATED]

export const WITH_PATH_SOURCE_TYPES = [SOURCE_TYPE_CLASSIFIER, SOURCE_TYPE_UTT, SOURCE_TYPE_CUSTOM_UPLOAD, SOURCE_TYPE_TAXONOMY_CLASSIFIER, SOURCE_TYPE_MULTICLASS]