import { notify_in_browser_if_permitted } from '../../../utils/browser_notifications.js'

/**
 * Triggers an in-browser notification that the user's classifier build has succeeded or failed,
 * provided the user has opted in to Cipher notifications (see client/src/utils/browser_notifications.js for details)
 *
 * build_identifier should be supplied where possible to avoid the danger of multiple notifications
 *
 * @param classifier_name
 * @param build_identifier can be job_id or build_id (whichever is available)
 * @param is_success determines whether we send a notification of completion or failure
 */
export function classifier_build_notification_in_browser(classifier_name, build_identifier, is_success) {
  const body_text = `Classifier build ${is_success ? 'complete' : 'failed'}`
  const options = {
    body: body_text,
    renotify: !!build_identifier,
    ...(build_identifier ? {tag: build_identifier} : {})
  }
  notify_in_browser_if_permitted(classifier_name, options)
}

/**
 * Triggers an in-browser notification that the user's classifier evaluation report has succeeded or failed
 * provided the user has opted in to Cipher notifications (see client/src/utils/browser_notifications.js for details)
 */
export function eval_report_build_notification_in_browser(report_name, classifier_name, report_id, is_success) {
  const message = classifier_name ? `${classifier_name}: ${report_name}` : report_name
  const body_text = `Evaluation report ${is_success ? 'complete' : 'failed'}`
  const options = {
    body: body_text,
    renotify: false,
    tag: report_id // avoid multiple notifications about the same report (eg from multiple tabs)
  }
  notify_in_browser_if_permitted(message, options)
}