import React from 'react'
import cn from 'classnames'

import cs from '../cipher_styles.module.scss'
import s from './PaneHeader.module.scss'

export const PaneHeader = ({text, className, hint, menu}) => {
  return (
    <div className={cn(['d-flex justify-content-between', s.pane_heading, className])}>
      <div>{text}</div>
      {menu}
      {hint}
    </div>
  )
}

export const Heading = ({text, on_click, className}) => {
  return (
    <div className={cn(cs.font_size_large, s.heading, className)} onClick={on_click}>
      {text}
    </div>
  )
}