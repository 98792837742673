import React from 'react'
import { DropdownItem } from 'reactstrap'
import _ from 'underscore'
import cn from 'classnames'

import { CUSTOM_SCORE_RANGE, get_default_custom_score_range, NO_FILTER } from '../model/filters.js'
import BaseDropdown from '../../widgets/BaseDropdown.js'
import Label from '../../widgets/Label.js'
import LabelledSlider from '../../LabelledSlider.js'
import TextLink from '../../widgets/TextLink.js'

import cs from '../../cipher_styles.module.scss'
import s from './ScoreFilterControl.module.scss'

const FilterDropdownItem = ({ filter, on_click, is_selected, children, className }) => {
  const { name, short_name, disabled } = filter || {}
  const name_clean = short_name || name

  return (
    <button
      onClick={on_click}
      disabled={disabled}
      className={cn(s.dropdown_btn, {[s.dropdown_btn__disabled]: disabled}, {[s.dropdown_btn__enabled]: !disabled},  {[s.dropdown_btn__active]: is_selected}, className)}
    >
      {name_clean}
      {children}
    </button>
  )
}

/**
 * @param {} className
 * @param {} filter_groups An array of group objects, where each has 'name' and 'children' properties. Each child item has 'id' and 'name' properties
 * @param {} selected_filter_name
 * @param {} on_change_filter_id
 */
const ScoreFilterControl = (
  {
    className,
    filter_groups,
    selected_filter_id,
    on_change_filter_id,
    custom_score_range,
    on_change_custom_score_range,
    title
  }) => {

  function handle_filter_id_change(id) {
    if (id !== CUSTOM_SCORE_RANGE) {
      on_change_custom_score_range(get_default_custom_score_range())
    }
    on_change_filter_id(id)
  }

  function handle_custom_range_clear() {
    on_change_custom_score_range(get_default_custom_score_range())
    on_change_filter_id(NO_FILTER)
  }

  function is_custom_group(group_idx) {
    const {children=[], name} = filter_groups[group_idx] || {}
    if (name == null) return false
    return children.some(({id}) => id === CUSTOM_SCORE_RANGE)
  }

  function is_custom_range_selected() {
    return selected_filter_id === CUSTOM_SCORE_RANGE
  }

  const all_filters = filter_groups.reduce((acc, group) => {
    const { children } = group

    const subfilters = children.reduce((acc, filter) => {
      const { subfilters } = filter
      if (subfilters) {
        return [...acc, ...subfilters]
      }
      return acc
    }, [])

    return [...acc, ...children, ...subfilters]
  }, [])

  const selected_filter = _.find(all_filters, filter => filter.id === selected_filter_id)

  if (!selected_filter) return null
  const { name } = selected_filter

  return (
    <span className={className}>
      <Label className={cs.font_weight_normal}>{title || 'Show'}</Label>

      <BaseDropdown
        className='ms-2'
        label={name}
        right={false}
        buttonClassName={s.button}
        menuClassName={s.menu}
        labelClassName={s.button_label}
      >
        {filter_groups.map(({ name: group_name, children }, i) => {
          // for each group of filter ids, show the filter options and a Divider line

          return (
            <span key={i}>
              { group_name != null &&
                <>
                  {i !== 0 && <DropdownItem divider />}
                  <DropdownItem header>
                    <span className='d-flex'>
                      <span className='d-flex'>{group_name}</span>{is_custom_group(i) && is_custom_range_selected() && <TextLink className='ms-2' onClick={handle_custom_range_clear}>clear</TextLink>}
                    </span>
                  </DropdownItem>
                </>
              }
              {children.map((filter, j) => {
                const { id, subfilters = [], name:filter_name } = filter

                if (subfilters.length > 0) {
                  return (
                    <div className='d-flex mb-1' key={j}>
                      <FilterDropdownItem key={j} filter={filter} is_selected={selected_filter_id === id} on_click={() => handle_filter_id_change(id)}/>
                      {subfilters.map((subfilter, k) => {
                        const { id } = subfilter
                        return (
                          <FilterDropdownItem
                            key={`${j}_${k}`}
                            filter={subfilter}
                            is_selected={selected_filter_id === id}
                            on_click={() => on_change_filter_id(id)}
                            className='ms-2'
                          />
                        )
                      })}
                    </div>
                )}

                if (id === CUSTOM_SCORE_RANGE) return (
                  <div className='d-flex' onClick={() => handle_filter_id_change(id)} key={j}>
                    <div className={cn('ps-2', s.custom_score_label)}>{filter_name}</div>
                    <div className='d-flex align-items-center ms-3'>
                      <LabelledSlider
                        value={custom_score_range}
                        extent={[0, 1]}
                        handleChange={(values) => on_change_custom_score_range(values)}
                        step={0.1}
                        hide_label
                        min_distance={0.1}
                      />
                      <div className={cn('ms-3', s.slider_label)}>
                        <span>{custom_score_range[0]}</span>&nbsp;to&nbsp;<span>{custom_score_range[1]}</span>
                      </div>
                    </div>
                  </div>
                )

                return (
                  <div className='mb-1' key={j}>
                    <FilterDropdownItem
                      key={j}
                      filter={filter}
                      on_click={() => handle_filter_id_change(id)}
                      is_selected={selected_filter_id === id}
                    />
                  </div>
                )
              })}
            </span>
          )
        })}
      </BaseDropdown>

    </span>
  )
}

export default ScoreFilterControl
