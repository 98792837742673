import * as d3 from 'd3'

import {
  extract_litigations,
  filter_by_portfolio, filter_items_by_company, filter_items_by_company_and_cluster, get_default_last_years,
  get_items_filtered_by_company_and_years, get_year_items, group_as_nested_array,
  sum_item_values
} from './executive_summary_utils.js'
import {NON_ROW_REGION, REGIONS_MAP} from './regions.js'
import { EXECUTIVE_SUMMARY_COLOURS } from './executive_summary_constants.js'


function get_portfolio_size_by_year(portfolio_items, company, year) {
  const filtered_portfolio_items = get_items_filtered_by_company_and_years(portfolio_items, [company.id], ['' + year])

  return sum_item_values(filtered_portfolio_items)
}

export function get_portfolio_overlay_data(portfolio_items, company, year) {
  return {
    'current_size': get_portfolio_size_by_year(portfolio_items, company, year),
    'prev_size': get_portfolio_size_by_year(portfolio_items, company, year - 1)
  }
}

export function filter_cost(years, cost_items, company) {
  years = d3.range(years[0], years[1]).map(function (d) {
    return '' + d
  })

  return get_items_filtered_by_company_and_years(cost_items, [company.id], years)
}

export function get_spend_over_past_years(filtered_cost_items) {
  return sum_item_values(filtered_cost_items);
}

export function get_mean_spend(mean_spend_items, company) {
  const items = filter_by_portfolio(mean_spend_items,[company]) //mean_spend_items.filter(function(d) { return d.company_id === company.id });
  if (items.length === 0) {
    return null
  }
  return items[0].value // should be only one value here
}

function colour_scheme_default(index) {
  // index is an integer
  return EXECUTIVE_SUMMARY_COLOURS[index % EXECUTIVE_SUMMARY_COLOURS.length]
}

export function get_portfolio_size_chart_data(granted_size_by_portfolio, selected_company, current_year) {
  //TODO: rewrite this function to simplify and adapt to the executive summary only. The code has been extracted from the BaseTimeSeriesDisplay
  const selected_years = get_default_last_years(granted_size_by_portfolio, current_year)
  const selected_year_items = get_year_items(selected_years);
  // Years filter
  const years_filter = {
    key: 'year',
    pred_fn: function(val) {
      return +val >= +selected_years[0] && +val < +selected_years[1]
    }
  }
  const dims  = [[selected_company], selected_year_items]

  const dims_descriptions = [
    {id: 'byCompany', key: 'company_id', name: 'Organisation', short_name: 'Organisation', icon_class: 'donut-icon', colour_scheme: colour_scheme_default},
    {name: 'Year', short_name: 'Year', key: 'year'}
  ]

  const value_items = filter_items_by_company(granted_size_by_portfolio, [selected_company.id], [years_filter]);
  const values = group_as_nested_array(value_items, dims_descriptions, dims, 0 )/* fill gaps with 0 */

  return values
}

export function get_technology_size_chart_data(granted_size_by_tech, selected_company, selected_clusters) {

  selected_clusters = selected_clusters.map(id => id.toString())//needed because of comparison in the filter items by company and cluster
  const value_items = filter_items_by_company_and_cluster(granted_size_by_tech, [selected_company.id], selected_clusters);

  let tech_size = {}
  value_items.forEach(item => { return tech_size[item.cluster_id]=item.value })
  const values = selected_clusters.map(cluster_id => { return (tech_size[cluster_id])? tech_size[cluster_id] : 0 })

  return values
}

function sum_up(acc, value){
  return acc + value
}

function get_granted_size_in_row(negative_regions, granted_size_by_region) {
  const filtered_other_regions = granted_size_by_region.filter(item => {
    return negative_regions.indexOf(item.country_code) === -1
  })

  return sum_values(filtered_other_regions)
}

function sum_values(filtered) {
  const filtered_values = filtered.map(item => {
    return item.value
  })

  return filtered_values.reduce(sum_up, 0)
}

function get_granted_size_in(region, granted_size_by_region) {
  const filtered_by_region = granted_size_by_region.filter(item => {
    return region.indexOf(item.country_code) !== -1
  })
  return sum_values(filtered_by_region)
}

export function get_geography_portfolio_data(granted_size_by_portfolio_and_region, selected_company) {
  const granted_size_by_region = filter_by_portfolio(granted_size_by_portfolio_and_region,[selected_company])//granted_size_by_portfolio_and_region.filter(item => {return item.company_id === selected_company.id})
  const total_in_usa = get_granted_size_in(REGIONS_MAP.USA, granted_size_by_region)
  const total_in_jp = get_granted_size_in(REGIONS_MAP.JAPAN, granted_size_by_region)
  const total_in_europe = get_granted_size_in(REGIONS_MAP.EUROPE, granted_size_by_region)

  const total_in_row = get_granted_size_in_row(NON_ROW_REGION, granted_size_by_region)

  const dims = [
    [selected_company],
    [
      {id: 'US', name: 'United States', short_name: 'USA', per_region: false, is_region: false},
      {id: 'EUROPE', name: 'Europe', short_name: 'Europe', per_region: false, is_region: true},
      {id: 'JAPAN', name: 'Japan', short_name: 'Japan', per_region: false, is_region: false,search_id: 'JP'},
      {id: 'ROW', name: 'Rest of the world', short_name: 'Row', per_region: false, is_region: false, search_id: 'ROW'}
    ]
  ]

  const values = [total_in_usa, total_in_europe, total_in_jp, total_in_row]

  let total = values.reduce(sum_up, 0)
  total = total === 0? 1 : total

  const percentage_values = values.map(value => {return value/total})

  return {'values': percentage_values, 'dims': dims}
}

export const YEAR_BUCKET_DIMENSIONS = ['≤4', '≤8', '≤12', '≤16', '≤20'];
export const STATUS_DIMENSIONS = ['GRANTED', 'APPLICATION']

function get_bucket_year(year) {
  if (year <= 4) {
    return 0
  }
  if (year <= 8) {
    return 1
  }
  if (year <= 12) {
    return 2
  }
  if (year <= 16) {
    return 3
  }
  if (year <= 20) {
    return 4
  }
  return 5
}

export function get_age_chart_data(granted_by_year, application_by_year, selected_company, current_year ) {

  const granted_by_year_company_filtered = granted_by_year.filter((item) => {return item.company_id === selected_company.id})
  const application_by_year_company_filtered = application_by_year.filter((item) => {return item.company_id === selected_company.id})

  const granted_size_age = granted_by_year_company_filtered.map((item) => {return {'age': current_year-item.year, 'value': item.value, 'status': STATUS_DIMENSIONS[0]}})
  const application_size_age = application_by_year_company_filtered.map((item) => {return {'age': current_year-item.year, 'value': item.value, 'status': STATUS_DIMENSIONS[1]}})

  const company_items = granted_size_age.concat(application_size_age)

  // Count data in year buckets
  let stacked_values = YEAR_BUCKET_DIMENSIONS.map(bucket => {return {'bucket': bucket, 'APPLICATION':0, 'GRANTED': 0}}) //note APPLICATION AND GRANTED must be the same values as STATUS_DIMENSIONS

  company_items.forEach(function(item) {
    const bucket_year_key = get_bucket_year(item.age)
    if (bucket_year_key < 5) {
      stacked_values[bucket_year_key][item.status] += item.value
    }
  })

  return stacked_values
}

export function get_cost_chart_data(value_items, selected_company,selected_years) {

  const selected_year_items = get_year_items(selected_years)

  const dims_descriptions = [ {id: 'byCompany', key: 'company_id', name: 'Organisation', short_name: 'Organisation', icon_class: 'donut-icon', colour_scheme: colour_scheme_default},
    {name: 'Year', short_name: 'Year', key: 'year'}]
  const dims  = [[selected_company], selected_year_items]

  const values = group_as_nested_array(value_items, dims_descriptions, dims, 0 )/* fill gaps with 0 */
  return values
}

export function get_litigations_y_domain(defensive, offensive, selected_companies, selected_years) {
  // Get min/max for the litigation count per year charts

  const defensive_litigations  = extract_litigations(defensive,selected_companies, selected_years)
  const offensive_litigations  = extract_litigations(offensive,selected_companies, selected_years)


  const max_offensive_litigations = d3.max(defensive_litigations) || 0
  const max_defensive_litigations = d3.max(offensive_litigations) || 0

  const max_litigations = Math.max(max_offensive_litigations, max_defensive_litigations);

  return [-max_litigations, max_litigations];
}

export function get_litigation_chart_data(defensive_litigations, offensive_litigations, selected_company, selected_years) {
  let values = []
  values[0] = extract_litigations(defensive_litigations,[selected_company], selected_years)
  values[1] = extract_litigations(offensive_litigations,[selected_company], selected_years)

  return values
}
