import React from 'react'
import cn from 'classnames'

import { LightbulbOffIcon, LightbulbOnIcon } from './IconSet.js'

import s from './SpotlightControl.module.scss'

const SpotlightControl = ({is_spotlighted, on_click, className}) => {
  
  const [Current, Next] = is_spotlighted ? [LightbulbOnIcon, LightbulbOffIcon] : [LightbulbOffIcon,LightbulbOnIcon]

  return (
    <div
      onClick={on_click}
      className={cn(s.wrapper, className)}
    >
      <span className={cn(s.current)}><Current /></span>
      <span className={cn(s.next)}><Next /></span>
    </div>
  )
}

export default SpotlightControl