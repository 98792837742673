import _ from 'underscore'

import { fetch_oldest_report_by_internal_id, fetch_report_metadata, fetch_most_recent_n_failed_reports } from './report_created_utils.js'
import {
  fetch_choreo_recent_reports_summary,
  report_input_is_valid,
  transform_report_input_for_choreo
} from './choreo_utils.js'
import { RUNNING_CLASSIFIERS_TASK_DESC } from '../model/report_tasks_and_statuses.js'
import { is_final_status } from './report_progress_utils.js'
import { to_local_datetime } from './utils.js'
import { fetch_original_report_input, translate_report_input } from './report_reader_utils.js'


export function fetch_report_lists_for_monitor() {
  return Promise.all([fetch_most_recent_n_failed_reports(), fetch_choreo_recent_reports_summary()])
}

export function report_id_is_internal_id(report_id) {
  // this dumb check will suffice here since we've already used a proper regex to extract the id
  return report_id.length > 10
}

export function find_report_by_internal_or_external_id(some_report_id) {
  if (report_id_is_internal_id(some_report_id)) {
    return find_report_by_internal_id(some_report_id)
  }
  return find_report_by_external_id(some_report_id)
}

function find_report_by_external_id(external_report_id) {
  const include_owner_id = true
  return fetch_report_metadata(external_report_id, include_owner_id)
    .then(report_metadata => {
      const relevant_metadata_fields = filter_metadata_fields(report_metadata)
      return ({...relevant_metadata_fields, external_report_id})
    })
}

function find_report_by_internal_id(internal_report_id) {
  return fetch_oldest_report_by_internal_id(internal_report_id)
}

export function get_validated_input(internal_report_id) {
  return fetch_original_report_input(internal_report_id)
    .then(report_input => {
      if (!report_input) {
        return null
      }
      return transform_report_input_for_choreo(translate_report_input(report_input))
        .then(translated_input => {
          const {input_schema_version} = report_input
          const has_translated_input = !input_schema_version || !original_input_matches_translated_input(report_input, translated_input)
          return report_input_is_valid(has_translated_input ? translated_input : report_input)
            .then(validation_message => {
              return {
                report_input,
                ...(has_translated_input ? {translated_input} : {}),
                validation_message
              }
            })
        })
    })
}

function original_input_matches_translated_input(report_input, translated_input) {
  return _.isEqual(report_input, translated_input)
}

export function get_most_accurate_report_status(report_progress, report_build_status, report_metadata) {
  if (report_progress && report_progress.status) {
    return report_progress.status
  } else if (report_build_status && report_build_status.status) {
    return report_build_status.status
  } else if (report_metadata && report_metadata.final_status) {
    // useful if report progress response not available for some reason
    return report_metadata.final_status
  }
  return 'status unknown'
}

export function get_most_informative_user_name(report_owner_info) {
  const {firstName, lastName, email, groups} = report_owner_info
  const group_name = (groups && groups[0]) ? groups[0].name : null
  const name = (!firstName && !lastName) ? email : `${firstName} ${lastName}`
  return name + (group_name ? ` (${group_name})` : '')
}

export function estimate_cc_task_endtime(report_input, report_progress) {
  const {tasks, status} = report_progress || {}
  const cc_task = _.findWhere((tasks || []), {description: RUNNING_CLASSIFIERS_TASK_DESC})
  const {details} = (cc_task || {})

  const {classifier_sources} = (report_input || {}).technology_partitioning || {}
  const number_classifiers = (classifier_sources || []).length

  if (is_final_status(status) || !details || number_classifiers < 1) {
    return null
  }

  const {num_pat_fams, progress_percent} = details
  const families_to_classify = num_pat_fams * ((100 - progress_percent) / 100)

  // TODO update from estimated_duration
  const time_remaining_ms = families_to_classify * number_classifiers
  const current_date_in_ms = new Date().getTime()
  const estimated_finish_date = new Date(current_date_in_ms + time_remaining_ms)

  return to_local_datetime(estimated_finish_date)
}

function filter_metadata_fields(metadata) {
  const fields_to_always_omit = ['user_state', 'is_saved', 'created_by_user', 'tag_ids']
  if (metadata.evaluation_classifier_id) {
    return _.omit(metadata, fields_to_always_omit)
  }
  return _.omit(metadata, [...fields_to_always_omit, 'evaluation_classifier_id'])
}