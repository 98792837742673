import _ from 'underscore'
import { get_as_map } from './utils.js'

import { SECTION_BY_ID } from '../components/viewer/bespoke/deck_charts.js'

export const NavigableReportDeck = (report_deck_model) => {
  const contents = report_deck_model

  const page_id_to_section_id = {}

  report_deck_model.forEach(deck_section => {
    const {pages, id: deck_section_id} = deck_section

    pages.forEach(page => {
      const {id: page_id} = page
      page_id_to_section_id[page_id] = deck_section_id
    })
  })

  const pages_as_flat_array = _.flatten(report_deck_model.map(section => section.pages))
  const charts_as_flat_array = _.flatten(pages_as_flat_array.map(page => page.charts.map(chart => ({...chart, page_id: page.id}))))

  const page_id_to_page = get_as_map(pages_as_flat_array, 'id')
  const page_ids = pages_as_flat_array.map(page => page.id)
  const page_ids_for_main_nav = pages_as_flat_array.filter(page => !page.hidden_in_main_nav).map(page => page.id)

  const spec_id_to_chart = get_as_map(charts_as_flat_array, 'spec_id')

  function get_page_by_id(page_id) {
    return page_id_to_page[page_id]
  }

  function get_chart_by_spec_id(spec_id) {
    return spec_id_to_chart[spec_id]
  }

  function get_first_page_id_in_section(section_id) {
    const section = _.findWhere(report_deck_model, {id: section_id})
    return _.first(section.pages).id
  }

  function get_page_section(page_id) {
    const section_id = page_id_to_section_id[page_id] || ''
    return SECTION_BY_ID[section_id]
  }

  function get_sub_pages(page_id) {
    return pages_as_flat_array.filter(page => page.root_page_id === page_id)
  }

  return {
    contents,
    page_ids,
    page_ids_for_main_nav,
    get_page_by_id,
    get_chart_by_spec_id,
    get_first_page_id_in_section,
    get_page_section,
    get_sub_pages
  }
}