import _ from 'underscore'

import { DOC, PATENT } from '../constants/paths.js'
import {API_GATEWAY_NOAUTH_BASE_URL} from '../constants/urls.js'
import { PATENT_LINK_AS_EPODOC_OPTION_ID, PATENT_LINK_AS_GOOGLE_OPTION_ID } from '../model/patent_links.js'
import { NO_DOCDB_COUNTRIES } from '../constants/countries.js'

const IFI_PDF_COUNTRIES = [
  'AT',
  'AU',
  'BE',
  'CA',
  'CH',
  'CZ',
  'DD',
  'DE',
  'DK',
  'EP',
  'ES',
  'FI',
  'FR',
  'GB',
  'IL',
  'IN',
  'JP',
  'KR',
  'LU',
  'MX',
  'NL',
  'NZ',
  'RU',
  'SK',
  'SU',
  'TH',
  'US',
  'WO'
]

const GOOGLE_URL_TEMPLATE = 'https://patents.google.com/patent/{patent_id}/en'
const IFI_PDF_URL_TEMPLATE = `${API_GATEWAY_NOAUTH_BASE_URL}/ifi_claims/doc/{ucid}`
const ESPACENET_URL_TEMPLATE = 'https://worldwide.espacenet.com/patent/search?q={patent_id}'

export function get_ucid(patent_id) {
  if (!patent_id) return ''
  if (patent_id.split('-').length === 3) return patent_id

  const parsed_patent = parse_patent(patent_id)
  if (!parsed_patent) return ''

  const {country_code, number, kind_code} = parsed_patent
  return `${country_code}-${number}-${kind_code}`
}

/**
 * Parse a patent into country_code, number and kind_code, or null if couldn't be matched
 */
export function parse_patent(patent_id) {
  const regex = new RegExp('^([A-Z]{2})s*(.+?)s*([A-Z][0-9]?)?$')

  const match = regex.exec(patent_id)

  return (match) ? {
    country_code: match[1],
    number: match[2],
    kind_code: match[3]
  } : null
}

function get_patent_country_code(patent_id) {
  return patent_id.substring(0, 2)
}

export function is_ifi_pdf_compatible(patent_id, country_code) {
  country_code = country_code || get_patent_country_code(patent_id)

  return _.contains(IFI_PDF_COUNTRIES, country_code)
}

export function is_espacenet_compatible(patent_id, country_code) {
  country_code = country_code || get_patent_country_code(patent_id)

  return !_.contains(NO_DOCDB_COUNTRIES, country_code)
}

export function get_ifi_doc_url(patent_id) {
  return IFI_PDF_URL_TEMPLATE.replace('{ucid}', get_ucid(patent_id))
}

export function get_ifi_image_url(filename, ucid) {
  return API_GATEWAY_NOAUTH_BASE_URL + `/ifi_claims/images/${ucid}/${filename}`
}

export function get_espacenet_url(patent_id) {
  return ESPACENET_URL_TEMPLATE.replace('{patent_id}', patent_id)
}

export function convert_ifi_image_pkey_to_ucid(pkey) {
  // Get UCID from file metadata `.pkey`. Api response -> https://docs.ificlaims.com/display/CDVDP/Attachments#Attachments-/attachment/list
  const ucid_parts = pkey.split("-")
  return [ucid_parts[0], ucid_parts[1], ucid_parts[2]].join('-')
}

export function get_patent_url(patent_id, patent_link_mode_id) {
  if (patent_id.length < 2) {
    return null
  }

  if (patent_link_mode_id === PATENT_LINK_AS_GOOGLE_OPTION_ID) {
    return GOOGLE_URL_TEMPLATE.replace('{patent_id}', patent_id)
  }

  const {country_code='', kind_code=''} = parse_patent(patent_id) || {}

  //Filing application
  if (kind_code === 'D0') return null

  if (patent_link_mode_id === PATENT_LINK_AS_EPODOC_OPTION_ID) {
    return get_espacenet_url(patent_id)
  }
  
  // IFI pdf countries
  if (is_ifi_pdf_compatible(patent_id, country_code)) {
    return `${PATENT}/${DOC}/${patent_id}`
  }

  // EP member countries
  if (is_espacenet_compatible(patent_id, country_code)) {
    return get_espacenet_url(patent_id)
  }

  return null
}
