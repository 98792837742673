import React from 'react'

import AlertModal from '../AlertModal.js'

const BadSyntaxAlertModal = ({ on_hide }) => {
  return (
    <AlertModal
      title={'Bad syntax in query.'}
      on_hide={on_hide}
    >
      <p>There is a problem with your query&#39;s syntax. Please edit it and try again. </p>
    </AlertModal>
  )
}

export default BadSyntaxAlertModal