import React from 'react'

import EditableTextLink from './EditableTextLink.js'
import { is_valid_report_name } from '../../../utils/name_utils.js'
import { track_report_viewer_event } from '../../../utils/tracking_utils.js'
import { is_failed_status } from '../../../utils/report_progress_utils.js'
import { get_report_viewer_url } from '../../../utils/report_url_utils.js'


const EditableReportLink = (
  { report,
    tracking_context,
    user_is_admin,
    on_edit,
    on_confirm,
    on_cancel,
    is_edit,
  }) => {
  const {status, title, created_by_user, external_report_id, report_type} = report
  const url = get_report_viewer_url(report_type, external_report_id)

  return (
    <EditableTextLink
      link_text={title}
      on_edit={on_edit}
      is_valid={is_valid_report_name}
      to={url}
      on_click={() => track_report_viewer_event(`obj="report" action="show" context="${tracking_context}"`)}
      on_confirm={on_confirm}
      on_cancel={on_cancel}
      is_editable={created_by_user}
      is_clickable={user_is_admin || !is_failed_status(status)}
      is_edit={is_edit}
    />
  )
}

export default EditableReportLink