import _ from 'underscore'

import { GENERIC_MAIN_ITEM_PROPERTIES, get_spec_group_and_view } from '../model/main_items.js'
import { SPEC_ID } from '../constants/main_items_state_params.js'
import { ALL_FAMILIES_ID, get_new_spec_id } from '../model/spec_ids.js'
import { USER_CUSTOM_CHART_SETS, USER_DEFAULT_MAIN_ITEMS } from '../model/user_settings.js'
import { spec_requires_assignee_links } from './spec_utils.js'
import {
  CIPHER_CHART_SET_BY_NAME,
  CLASSIFIER_EVALUATION_EXTRAS,
  MULTI_ORG_MULTI_TECH_PRESET,
  MULTI_ORG_SINGLE_TECH_PRESET,
  SINGLE_ORG_MULTI_TECH_PRESET,
  SINGLE_ORG_SINGLE_TECH_PRESET
} from '../model/chart_sets.js'
import { concat_unique } from './item_utils.js'
import {
  CIPHER_DEFAULT,
  CUSTOM_SET,
  CUSTOM_SET_CIPHER,
  CUSTOM_SET_SHARED
} from '../constants/default_chart_selection.js'
import { ORDERED_SPEC_IDS_BY_GROUP } from '../model/spec_groups.js'
import { get_spec_refs } from './spec_group_utils.js'
import { get_as_map } from './utils.js'

export const SELECTED_ITEMS_CIPHER_ORDER = 'cipher'
export const SELECTED_ITEMS_SELECTION_ORDER = 'selection'

export function get_default_custom_set({default_chart_selection, user_settings, group_settings}) {
  const splitter = default_chart_selection.indexOf(':')
  const chart_set_name = default_chart_selection.substr(splitter+1)
  if (default_chart_selection.startsWith(CUSTOM_SET_CIPHER)) {
    return  CIPHER_CHART_SET_BY_NAME[chart_set_name] || {}
  } else {

    const check_for_shared_set = default_chart_selection.startsWith(CUSTOM_SET_SHARED)
    const chart_sets = get_custom_chart_sets(user_settings, group_settings)

    const chart_set = chart_sets.filter(item => {
      const { name, is_shared_set } = item

      return (name === chart_set_name) && (check_for_shared_set ? is_shared_set : !is_shared_set)
    })

    return chart_set[0] || {}
  }
}

export function get_custom_chart_sets(user_settings, group_settings) {
  const user_sets = (user_settings || {})[USER_CUSTOM_CHART_SETS] || []
  const {group_custom_chart_sets=[]} = group_settings || {}

  const user_sets_clean = [...user_sets, ...group_custom_chart_sets.map(item => ({...item, is_shared_set: true}))].map(set => ({...set, items: get_valid_main_items(set.items)}))
  const user_sets_filtered = user_sets_clean.filter(set => (set.items.length !== 0)) //include only sets with non-empty items lists
  return user_sets_filtered
}

function get_default_preset({portfolios, techs}) {
  if (!(portfolios && techs)) return null

  const portfolios_count = portfolios.length
  const tech_count = techs.length

  if (portfolios_count === 1 && tech_count === 1) return SINGLE_ORG_SINGLE_TECH_PRESET
  if (portfolios_count === 1 && tech_count > 1) return SINGLE_ORG_MULTI_TECH_PRESET
  if (portfolios_count > 1 && tech_count === 1) return MULTI_ORG_SINGLE_TECH_PRESET

  return MULTI_ORG_MULTI_TECH_PRESET
}

export function get_selected_items({user_state, user_settings, group_settings, is_eval_report, ref_data, report_has_scores, has_missing_assignee_links, schema_version, user}) {

  const { main_items: user_state_main_items } = user_state || {} // report-level user_state ( db)

  const items = user_state_main_items || get_preferred_items({user_settings, group_settings, is_eval_report, ref_data, report_has_scores}) || []

  const only_valid_items = get_valid_main_items(
    items.map(item => {return {...item, spec_id: get_new_spec_id(item.spec_id)}})
  )

  let items_to_return = only_valid_items

  if (has_missing_assignee_links) {
    items_to_return =  only_valid_items.filter(item => !spec_requires_assignee_links(item.spec_id))
  }

  items_to_return = items_to_return.filter(item => {
    const {check_if_available} = item
    const {group} = get_spec_group_and_view(item)
    const {check_if_available: check_if_group_available} = group || {} //can only happen if spec id is ALL_FAMILIES_ID,

    const is_available_handler = check_if_available || check_if_group_available

    if (is_available_handler) return is_available_handler({report_has_scores, schema_version, user})
    return true
  })

  return items_to_return
}

export function get_preferred_items({user_settings, group_settings, is_eval_report, ref_data, report_has_scores}) {
  const { user_default_chart_selection } = user_settings || {}

  if (user_default_chart_selection === CIPHER_DEFAULT) {
    return get_default_selected_items({ref_data, is_eval_report, report_has_scores})
  }

  if (user_default_chart_selection == null) {
    return []
  }

  if (user_default_chart_selection.startsWith(CUSTOM_SET)) {
    const chart_set = get_default_custom_set({default_chart_selection: user_default_chart_selection, user_settings, group_settings})

    const {items} = chart_set
    return items
  }
}

export function get_default_selected_items({ref_data, is_eval_report, report_has_scores}) {
  const { portfolios, techs } = ref_data || {}

  if (!(portfolios && techs)) return null

  const preset = get_default_preset({portfolios, techs})

  const default_main_items = preset.main_items    // otherwise fall back to default

  if (is_eval_report && report_has_scores) {
    const default_main_items_with_score_sets = concat_unique(default_main_items, CLASSIFIER_EVALUATION_EXTRAS)
    return default_main_items_with_score_sets
  }

  return default_main_items
}

export function get_valid_main_items(main_items) {
  return main_items.filter(main_item => {
    //temporary fix to keep the list view in dataset selection
    if (main_item[SPEC_ID] === ALL_FAMILIES_ID) return true

    const { spec, group, view } = get_spec_group_and_view(main_item)

    return (spec && group && view) && !spec.is_deprecated // all of these must be found for the item to be valid
  })
}

export function get_generic_main_items(main_items) {
  // Only return spec_id and view_id (as other properties are data-specific)
  return main_items.map(item => _.pick(item, GENERIC_MAIN_ITEM_PROPERTIES))
}

function sort_by_group(spec_1, spec_2) {
  return ORDERED_SPEC_IDS_BY_GROUP.indexOf(spec_1.spec_id) - ORDERED_SPEC_IDS_BY_GROUP.indexOf(spec_2.spec_id)
}

function sort_items_by_group_order(specs=[]) {
  return specs.sort(sort_by_group)
}

export function get_only_saved_items(items=[]) {
  return items.filter(item => !item.is_temp && item.spec_id !== ALL_FAMILIES_ID)
}

export function get_selected_items_for_display(selected_items=[], selected_charts_display_order_preference) {
  const spec_id_to_item = get_as_map(selected_items, 'spec_id')

  const saved_only = get_only_saved_items(selected_items) //skip is_temp=true items
  const ordered_items = (selected_charts_display_order_preference === SELECTED_ITEMS_SELECTION_ORDER) ? saved_only : sort_items_by_group_order(saved_only)
  return get_spec_refs(ordered_items).map(item => {
    const {spec_id} = item

    return {...item, ...spec_id_to_item[spec_id]}
  })
}

export function remove_invalid_default_main_items_from_user_settings(user_settings) {
  if (!user_settings) {
    return user_settings
  }

  const main_items = user_settings[USER_DEFAULT_MAIN_ITEMS]

  if (!main_items) {
    return user_settings
  }

  // Remove unknown specs
  const valid_main_items = get_valid_main_items(main_items)

  // Remove data-specific properties
  const generic_main_items = get_generic_main_items(valid_main_items)

  return {
    ...user_settings,
    [USER_DEFAULT_MAIN_ITEMS]: generic_main_items
  }
}
