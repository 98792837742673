import { get_as_map } from '../utils/utils.js'

export const SELECTED_ONLY   = 'SELECTED_ONLY'
export const UNSELECTED_ONLY = 'UNSELECTED_ONLY'
export const ALL             = 'ALL'

export const SELECTION_VIEWS = [
  { id: ALL,             name: 'all',        title: 'show all' },
  { id: SELECTED_ONLY,   name: 'selected',   title: 'show selected only' },
  { id: UNSELECTED_ONLY, name: 'unselected', title: 'show unselected only' }
]

export const ID_TO_SELECTION_VIEW = get_as_map(SELECTION_VIEWS, 'id')
