import React from 'react'
import cn from 'classnames'

import { PrimaryButton, ProminentButton } from '../widgets/Button.js'

import s from './ProgressPrompt.module.scss'

const ProgressPrompt = ({on_click_prev, on_click_next, on_click_finish, can_continue, on_click_use_cached_report, on_click_use_running_report, className}) => {
  return (
    <div className={cn('d-flex justify-content-between', className)}>
      <div>
        {on_click_prev &&
          <PrimaryButton className={s.btn} onClick={on_click_prev}>Previous</PrimaryButton>
        }
      </div>
      <div>
        {(on_click_next || on_click_finish) &&
          <>
            {on_click_next &&
              <PrimaryButton className={s.btn} onClick={can_continue ? on_click_next : null} disabled={!can_continue}>
                <span>Next</span>
              </PrimaryButton>
            }
            {on_click_finish && !on_click_use_cached_report && !on_click_use_running_report &&
              <ProminentButton className={s.btn} onClick={can_continue ? on_click_finish : null} disabled={!can_continue}>Create report</ProminentButton>
            }

            {on_click_finish && (on_click_use_cached_report || on_click_use_running_report) &&
              <div className='d-flex'>
                <PrimaryButton className={cn('me-2 my-auto', s.btn)} onClick={can_continue ? on_click_finish : null} disabled={!can_continue} outline>Create new report</PrimaryButton>
                {on_click_use_running_report && !on_click_use_cached_report &&
                  <ProminentButton className={s.btn} onClick={can_continue ? on_click_use_running_report : null} disabled={!can_continue}>View running report</ProminentButton>
                }
                {on_click_use_cached_report &&
                  <ProminentButton className={s.btn} onClick={can_continue ? on_click_use_cached_report : null} disabled={!can_continue}>Use cached report</ProminentButton>
                }
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

export default ProgressPrompt