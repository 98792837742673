import { IGNORE, NEGATIVE, POSITIVE, TEST_NEGATIVE, TEST_POSITIVE } from '../constants/labels'

import cs from '../../cipher_styles.module.scss'

export function get_colour_bg_class_by_label(user_class) {
  switch (user_class) {
    case POSITIVE:
      return cs.classifier_positive_bg
    case TEST_POSITIVE:
      return cs.classifier_test_positive_bg
    case NEGATIVE:
      return cs.classifier_negative_bg
    case TEST_NEGATIVE:
      return cs.classifier_test_negative_bg
    case IGNORE:
      return cs.classifier_ignore_bg
    default:
      return ''
  }
}