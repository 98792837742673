import React, { Component } from 'react'
import * as d3 from 'd3'

import { BASE_COLOUR } from '../utils/executive_summary_constants.js'

export const BAR_HEIGHT = 11
export const BAR_MARGIN = 3

class MiniBarChart extends Component {

  constructor(props) {
    super(props)
    this.container_ref = React.createRef()
  }

  componentDidMount() {
    const containerWidth = this.props.width || 100,
          base_colour    = this.props.base_colour || BASE_COLOUR,
          show_percent   = this.props.show_percent

    let values = this.props.values

    // Convert data
    // For now, assume values is a flat array (single series)

    // Get max value
    const max_value = d3.max(values)

    if (show_percent) {
      // convert values to 'percent' (proportion of total - i.e. between 0 and 1)
      const total = d3.sum(values)
      values = values.map(function (d) {
        return total ? d / total : 0
      })
    }

    // Render chart
    const margin = {top: 0, right: 0, bottom: 0, left: 0},
      width = containerWidth - margin.left - margin.right
    // height = containerHeight - margin.top - margin.bottom;

    const x = d3.scaleLinear()
      .range([0, width])
      .domain([0, show_percent ? 1 : max_value * 1.1])

    // Get colour scale
    const colour = d3.scaleLinear()
      .domain([0, 100])
      .range(['#ffffff', base_colour])

    const container_node = this.container_ref.current
    const canvas = d3.select(container_node)

    const bar = canvas.append('g')
      .selectAll('rect')
      .data(values)
      .enter()

    bar.append('rect')
      .attr('height', BAR_HEIGHT)
      .attr('x', 0)
      .attr('y', function (d, i) {
        return (BAR_HEIGHT + BAR_MARGIN) * i
      })
      .attr('width', function (d) {
        return x(d)
      })
      .style('fill', base_colour)

    bar.append('rect')
      .attr('height', BAR_MARGIN)
      .attr('x', 0)
      .attr('y', function (d, i) {
        return (BAR_HEIGHT + BAR_MARGIN) * (i + 1) - BAR_MARGIN
      })
      .attr('width', width)
      .style('fill', colour(10))
  }

  render() {
    return (
      <g ref={this.container_ref} className='mini-bar-chart'/>
    )
  }
}

MiniBarChart.BAR_HEIGHT = BAR_HEIGHT
MiniBarChart.BAR_MARGIN = BAR_MARGIN

export default MiniBarChart
