import { CLASSIFIERS } from './paths'
import { CLASSIFIER } from '../components/classifiers_editor/constants/classifier_paths'

  // CDN UI domains (no protocol)
export const CDN_DOMAIN                = 'app.cipher.ai'
export const APP3_CDN_DOMAIN           = 'app3.cipher.ai'
export const APP4_CDN_DOMAIN           = 'app4.cipher.ai'
export const TECH_DISCOVERY_CDN_DOMAIN = 'td.lexisnexisip.com' // TODO: check sentry_utils.js "is_prod" - that should check for techdiscovery CDN!
//export const TECH_DISCOVERY_CDN_DOMAIN__TEST = 'techdiscovery.cipher.ai' // TODO:
export const TECH_DISCOVERY_CDN_DOMAIN__TEST = 'techdiscovery.lexisnexisip.com' // TODO: ???????

  // API hosts (includes protocol)
export const CDN_API_HOST            = 'https://api.aws.cipher.ai'
export const APP3_API_HOST           = 'https://api3.aws.cipher.ai'
export const APP4_API_HOST           = 'https://api4.aws.cipher.ai'
export const TECH_DISCOVERY_API_HOST = 'https://api-techdiscovery.aws.cipher.ai'

export const API_GATEWAY_NOAUTH_BASE_URL   = '/api/gw_pub'
export const BENCHMARKING_BUILDER_BASE_URL = '/api/benchmarking_builder'
export const C2_TO_C3_JOB_BASE_URL         = '/api/c2_to_c3_job'
export const CPC_BASE_URL                  = '/api/cpc'
export const CHOREO_BASE_URL               = '/api/choreo'

export const COMPANY_LISTS_BASE_URL           = '/api/company_list'
export const COMPARABLES_BASE_URL             = '/api/comparables'
export const CONFIG_BASE_URL                  = '/api/config'
export const DATA_EXPORT_SERVICE_BASE_URL     = '/api/data_export'
export const DOMAIN_SERVICE_BASE_URL          = '/api/domain'
export const DOMAIN_SERVICE_NOAUTH_BASE_URL   = '/api/domain_pub'
export const FEEDBACK_BASE_URL                = '/api/feedback'
export const HYPERSCRIPTS_BASE_URL            = '/api/hyperscripts'
export const HYPERSCRIPTS_NOAUTH_BASE_URL     = '/api/hyperscripts_pub'
export const OCYPOD_BASE_URL                  = '/api/ocypod'
export const REPORT_CREATED_BASE_URL          = '/api/report_created'
export const REPORT_HISTORY_BASE_URL          = '/api/report_history'
export const REPORT_SAVED_BASE_URL            = '/api/report_saved'
export const REPORT_SHARED_BASE_URL           = '/api/report_shared'
export const REPORT_READER_BASE_URL           = '/api/report_reader'
export const STATIC_DATA_BASE_URL             = '/api/static_data'
export const TEXT_SEARCH_BASE_URL             = '/api/search'
export const ORGANISATION_BASE_URL            = '/api/org_search'
export const PATFAM_SAMPLE_BASE_URL           = '/api/patfam_sample'
export const SUGGESTIONS_BASE_URL             = '/api/suggestions'
export const ALERTS_BASE_URL                  = '/api/alerts'
export const ALERT_REPORTS_BASE_URL           = '/api/alert_reports'
export const FAMILY_TAGS_BASE_URL             = '/api/family_tags'
export const USER_SETTINGS_BASE_URL           = '/api/user_settings'
export const USER_GROUP_SETTINGS_BASE_URL     = '/api/user_group_settings'
export const USER_GROUP_BASE_URL              = '/api/user_group'
export const USER_MANAGEMENT_BASE_URL         = '/api/user_management'
export const USER_INFO_BASE_URL               = '/api/user_info'
export const RESOURCE_ACCESS_BASE_URL         = '/api/resource_access'
export const DOWNLOAD_BASE_URL                = '/api/download'
export const TRAINING_ACTIVITY_BASE_URL       = '/api/training_activity'
export const TRANSLATE_URL                    = '/api/translate'
export const SERVER_API_ERROR_TEST_URL        = '/api/errorTest'
export const SYNCH_PATENT_MATCHING_BASE_URL   = '/api/patent_matching/synch'
export const ASYNCH_PATENT_MATCHING_BASE_URL  = '/api/patent_matching/asynch'
export const CUSTOM_CLUSTERED_MATCHING_URL    = '/api/patent_matching/clustered'
export const SERVER_VERSION_BASE_URL          = '/version'
export const REPORT_INPUT_BASE_URL            = '/api/report_input'
export const TRAINING_SET_SERVICE_BASE_URL    = '/api/training_set'
export const TRENDSPOTTER_BASE_URL            = '/api/trend_spotter'
export const FAMILY_CLASS_SUGGESTION_BASE_URL = '/api/family_class_suggestion'
export const REPORT_DECK_URL                  = '/api/report_deck'
export const KNN_SEARCH_BASE_URL              = '/api/knn_search'
export const SIMILAR_FAMILIES_BASE_URL        = '/api/similar_families_search'
export const REPORT_STREAM_BASE_URL           = '/api/report_stream'
export const PROJECT_BASE_URL                 = '/api/project'
export const WAM_TECH_DISCOVERY_BASE_URL      = '/api/wam_tech_discovery'

export const MATCH_PATENT_URL         = `${SYNCH_PATENT_MATCHING_BASE_URL}/patent/match`
export const SYNCH_MATCH_PATENTS_URL  = `${SYNCH_PATENT_MATCHING_BASE_URL}/patents/match`
export const ASYNCH_MATCH_PATENTS_URL = `${ASYNCH_PATENT_MATCHING_BASE_URL}/patents/match`
export const CHECK_MATCHING_JOB_URL   = ASYNCH_MATCH_PATENTS_URL                            //TODO: the be probably would need to change the URI for this

export const PATENT_FAMILY_SEARCH_BY_PHRASE_URL = TEXT_SEARCH_BASE_URL + '/patent_family_fields/by_search_term'
export const PATENT_FAMILY_SEARCH_BY_QUERY_URL  = '/api/family_search/patent_family_fields/by_report_reader_query'
export const PATENT_FAMILY_SEARCH_BY_PATFAM_IDS = TEXT_SEARCH_BASE_URL + '/patent_family_fields/by_pat_fam_ids'

export const ORG_SEARCH_BY_NAME_URL          = ORGANISATION_BASE_URL + '/organisation/search?return_children=false&q='
export const ORG_SEARCH_BY_EXACT_NAME_URL    = ORGANISATION_BASE_URL + '/organisation/search_exact_name?q='
export const ORG_SEARCH_BY_NAME_MULTIPLE_URL = ORGANISATION_BASE_URL + '/organisation/search_multiple'
export const ORG_SEARCH_BY_TAG_URL           = ORGANISATION_BASE_URL + '/organisation/search_tags?q='
export const ORG_SEARCH_NPES_URL             = ORGANISATION_BASE_URL + '/organisation/non_practicing_entities'

export const ORG_SUGGESTIONS_BY_ASSIGNEE_IDS_URL = COMPARABLES_BASE_URL + '/by_assignee_id'
export const ORG_SUGGESTIONS_BY_ORG_IDS_URL      = COMPARABLES_BASE_URL + '/by_organisation_id'
export const ORG_SUGGESTIONS_BY_PAT_FAM_IDS_URL  = COMPARABLES_BASE_URL + '/by_patent_family_id'

export const SAMPLES_BASE_URL = DOMAIN_SERVICE_BASE_URL +'/samples'

export const SUBSCRIPTIONS_URL = ALERTS_BASE_URL + '/subscriptions'

export const DISPUTE_ATTACHMENT_DOWNLOAD_URL = DOWNLOAD_BASE_URL + '/dispute-attachment'

export const PUBLICATION_XML_URL    = HYPERSCRIPTS_NOAUTH_BASE_URL + '/publication_xml'
export const PUBLICATION_IMAGES_URL = API_GATEWAY_NOAUTH_BASE_URL + '/ifi_claims/images'

export const CUSTOM_CLASSIFIERS_UI_BASE_URL = CLASSIFIERS
export const CUSTOM_CLASSIFIER_UI_BASE_URL  = `${CLASSIFIERS}/${CLASSIFIER}/`

export const USER_INFO_BY_IDS_URL = USER_INFO_BASE_URL + '/by_ids'

export const REPORT_STATE_URL = '/api/report_state'

export const SUBSET_REPORT_SAVE_INPUT_URL   = REPORT_INPUT_BASE_URL + '/save'
export const SUBSET_REPORT_CREATE_INPUT_URL = REPORT_INPUT_BASE_URL + '/create'
export const SUBSET_REPORT_GET_INPUT_URL    = REPORT_INPUT_BASE_URL

export const FAMILY_CLASS_SUGGESTION_SAVE_URL  = FAMILY_CLASS_SUGGESTION_BASE_URL + '/save'
export const FAMILY_CLASS_SUGGESTION_STATS_URL = FAMILY_CLASS_SUGGESTION_BASE_URL + '/stats'
export const FAMILY_CLASS_SUGGESTION_FETCH_URL = FAMILY_CLASS_SUGGESTION_BASE_URL

export const KNN_TEXT_TO_QUERY_URL = KNN_SEARCH_BASE_URL + '/text_to_query'

export const KNN_SEARCH_URL = KNN_SEARCH_BASE_URL + '/search'

export const SENTRY_CLIENT_PROXY_URL = '/api/error/send'

export const WAM_TECHDISCOVERY_USER_DETAILS_URL = WAM_TECH_DISCOVERY_BASE_URL + '/user_details'