import React, { useEffect, useState } from 'react'

import OrgSuggestionsSearch from './OrgSuggestionsSearch.js'
import { track_report_builder_event } from '../../utils/tracking_utils.js'
import { withReportBasket } from '../builder/ReportBasketContext.js'
import {
  BY_PAT_ASSIGNEES_PORTFOLIO,
  BY_PAT_FAMS_PORTFOLIO,
  get_all_assignees,
  is_agglomeration,
  is_org_type
} from '../../utils/organisation_utils.js'
import { COMPARABLES_BY_LARGEST_PORTFOLIO } from '../../model/organisation.js'

//TODO: maybe further refactoring to this component can be done to show result of similar portfolios for both patfam based and assignee based selections in the basket

interface OnSuggestionClickParams {
  org: any,
  add: boolean,
  with_special_key: any
}

interface SimilarPortfoliosDisplayProps {
  base_name: string,
  base_items: Array<any>,
  on_reset_suggestion: Function,
  on_click_suggestion: Function,
  update_group_handler: Function,
  tracking_context: string,
  portfolio_basket: any,
  org_group: any,
}

const OrgSuggestionsByFamiliesDisplay = (props: SimilarPortfoliosDisplayProps) => {
  const [portfolio_items_for_suggestions, set_portfolio_items_for_suggestions] = useState<Array<any>>(props.base_items)
  const [portfolio_name, set_portfolio_name] = useState<string>(props.base_name)
  const [portfolio_type, set_portfolio_type] = useState<string>(BY_PAT_FAMS_PORTFOLIO)

  useEffect(()=>{
      set_portfolio_items_for_suggestions(props.base_items)
      set_portfolio_name(props.base_name)
      set_portfolio_type(BY_PAT_FAMS_PORTFOLIO)
  },[props.base_name, props.base_items])

  const is_in_grouping_mode = props.org_group && props.org_group.length > 0

  return (
    <OrgSuggestionsSearch
      base_name={portfolio_name}
      base_items={portfolio_items_for_suggestions}
      reset_handler={reset_suggestions}
      on_result_select={on_suggestion_click}
      selected_organisations={get_selected_orgs()}
      on_show_similar_organisations={show_similar_organisations}
      base_items_type={portfolio_type}
      suggestions_mode={COMPARABLES_BY_LARGEST_PORTFOLIO}
    />)


  function add_to_basket(org: any) {
    props.on_click_suggestion({add: [org]})
  }

  function reset_suggestions() {
    track_report_builder_event(`action="close" obj="org_suggestions" context="${props.tracking_context}"`)
    props.on_reset_suggestion()
  }

  function on_suggestion_click(params: OnSuggestionClickParams) {
    params.add ? on_click_add_suggestion(params.org, params.with_special_key) : on_click_remove_suggestion(params.org)
  }

  function on_click_add_suggestion(org: any, with_special_key: any) {
    const should_add_to_group = with_special_key || is_in_grouping_mode

    if (should_add_to_group) {
      track_report_builder_event(`action="add_to_org_group" obj="org" context="${props.tracking_context}"`)
      add_to_grouping(org)
    } else {
      track_report_builder_event(`action="add_to_basket" obj="org" context="${props.tracking_context}"`)
      add_to_basket(org)
    }
  }

  function on_click_remove_suggestion(org: any) {
    if (is_in_grouping_mode) {
      track_report_builder_event(`action="remove_from_org_group" obj="org" context="${props.tracking_context}"`)
      remove_from_grouping(org)
    } else {
      track_report_builder_event(`action="remove_from_basket" obj="org" context="${props.tracking_context}"`)
      remove_from_basket(org)
    }
  }

  function remove_from_basket(org: any) {
    props.on_click_suggestion({remove: [org]})
  }

  function get_selected_orgs() {
    return (is_in_grouping_mode) ? props.org_group : props.portfolio_basket.filter((item: any) => (is_org_type(item)))
  }

  function add_to_grouping(org: any) {
    props.update_group_handler({add: [org]})
  }

  function remove_from_grouping(org: any) {
    props.update_group_handler({remove: [org]})
  }

  function show_similar_organisations(org: any) {
    track_report_builder_event(`obj="similar_orgs" context="${props.tracking_context}" action="show"`)
    const items = (!is_agglomeration(org)) ? [org] : get_all_assignees(org)
    set_portfolio_items_for_suggestions(items)
    set_portfolio_name(org.name)
    set_portfolio_type(BY_PAT_ASSIGNEES_PORTFOLIO)
  }

}

export default withReportBasket(OrgSuggestionsByFamiliesDisplay)