import React, { useState, useEffect } from 'react'

import {
  DISPUTE_DETAILS_VIEWS_BY_ID,
  fetch_dispute_attachments, fetch_dispute_details,
  fetch_dispute_parties,
  fetch_dispute_patents,
  format_route
} from '../../utils/disputes_utils.js'
import ErrorBody from '../ErrorBody.js'
import { withUser } from '../UserContext.js'
import Spinner from '../widgets/Spinner.js'
import { Dispute } from './Dispute.js'

const DisputeDetails = ({dispute_id, internal_report_id, view, outcome, dispute_role, user}) => {
  const [show_spinner, set_show_spinner] = useState(true)
  const [dispute_details, set_dispute_details] = useState({})
  const [fetch_error, set_fetch_error] = useState(null)

  useEffect(() => {
    Promise.all([
      fetch_dispute_details(internal_report_id, dispute_id),
      fetch_dispute_parties(internal_report_id, dispute_id),
      fetch_dispute_patents(internal_report_id, dispute_id),
      fetch_dispute_attachments(internal_report_id, dispute_id)
    ])
      .then(([details, parties, patents, attachments]) => {
        set_dispute_details({details, patents, parties, attachments})
        set_show_spinner(false)
      })
      .catch(error => {
        set_fetch_error(error)
        set_show_spinner(false)
      })
  }, [dispute_id, internal_report_id])


  if (fetch_error) {
    return (
      <ErrorBody
        error={fetch_error}
        context='fetching dispute details'
      />
    )
  }

  const { details, patents=[], parties=[], attachments=[] } = dispute_details

  const { title, docket_number, start_date, end_date, jurisdiction, route } = details || {}

  return(
    <div>

      {show_spinner &&
        <div className='text-center'>
          <Spinner/>
          <p>Fetching data...</p>
        </div>
      }

      {!show_spinner && dispute_details &&
        <Dispute
          is_page_view={view === DISPUTE_DETAILS_VIEWS_BY_ID.page.id}
          user={user}
          title={title}
          docket_number={docket_number}
          jurisdiction={jurisdiction}
          route={format_route(route)}
          start_date={start_date}
          end_date={end_date}
          parties={parties}
          patents={patents}
          attachments={attachments}
          dispute_role={dispute_role}
          outcome={outcome}
        />
      }

    </div>
  )
}

export default withUser(DisputeDetails)