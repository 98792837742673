import React from 'react'
import Grid from '../widgets/Grid.js'
import GridElement from '../widgets/GridElement.js'
import { PaneHeader } from '../widgets/PaneHeader.js'
import { AttachmentPanel } from './AttachmentPanel.js'

export const AttachmentsPanel = ({title, attachments, show_document_link}) => {
  return (
    <div>
      <PaneHeader text={title}/>
      <Grid className='mt-3'>
        {attachments.map((attachment, i) => (
          <GridElement key={i}>
            <AttachmentPanel attachment={attachment} show_document_link={show_document_link}/>
          </GridElement>
        ))}
      </Grid>
    </div>
  )
}