import { TECH_ID } from './technologies.js'

export const TECHS_SPEC = {
  get_query: () => {
    return {
      value: ["T.technology_id", "T.name", "T.source_type", "T.source_value"]
    }
  },
  column_names: [TECH_ID, 'name', 'source_type', 'source_value']
}

