import React from 'react'
import cn from 'classnames'

import { format_integer_with_comma } from '../../utils/utils.js'

import s from './WorldMapTooltip.module.scss'

export const WorldMapTooltip = ({ tooltip }) => {
  if (!tooltip) return

  const { content, position } = tooltip
  const { top, left } = position
  const { name, size, percent_value } = content

  return (
    <div className={cn('p-1',s.tooltip)} style={{left: left+'px', top: top+'px'}}>
      {name}
      {size && `: ${format_integer_with_comma(size)} (${percent_value})`}
    </div>
  )
}