export const TRAINING_SET_STATUS_NONE = 'None'
export const TRAINING_SET_STATUS_IN_PROGRESS = 'In Progress'
export const TRAINING_SET_STATUS_IN_REVIEW = 'In Review'
export const TRAINING_SET_STATUS_DELIVERED = 'Delivered'
export const TRAINING_SET_STATUS_ABANDONED = 'Abandoned'

export const TRAINING_SET_STATUSES = [
  { id: TRAINING_SET_STATUS_NONE, value: null, label: TRAINING_SET_STATUS_NONE },
  { id: TRAINING_SET_STATUS_IN_PROGRESS, value: TRAINING_SET_STATUS_IN_PROGRESS, label: TRAINING_SET_STATUS_IN_PROGRESS },
  { id: TRAINING_SET_STATUS_IN_REVIEW, value: TRAINING_SET_STATUS_IN_REVIEW, label: TRAINING_SET_STATUS_IN_REVIEW },
  { id: TRAINING_SET_STATUS_DELIVERED, value: TRAINING_SET_STATUS_DELIVERED, label: TRAINING_SET_STATUS_DELIVERED },
  { id: TRAINING_SET_STATUS_ABANDONED, value: TRAINING_SET_STATUS_ABANDONED, label: TRAINING_SET_STATUS_ABANDONED }
]

export const TRAINING_SET_STATUSES_NON_NULL = TRAINING_SET_STATUSES.filter(status => status.value)